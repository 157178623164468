import React from 'react';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { ButtonMediumPrimaryText } from '../../../../../../../../../components/Buttons';
import { AddIcon } from '../../../../../../../../../assets/Icons/PanelsList';
import { DesigneeItem } from '../DesigneeItem';
import { UserAutocomplete } from '../UserAutocomplete';
import { KeyboardArrowDown } from '@material-ui/icons';

interface AssignDesigneeProps {
  formFields: any;
  selectedItems: any;
  isClickedAdd: boolean;
  setIsClickedAdd: (input: boolean) => void;
  openOptions: boolean;
  setOpenOptions: (input: boolean) => void;
  careTeamSelectInputValue: string;
  setCareTeamSelectInputValue: (input: string) => void;
  options: any[];
  changeOptionBaseOnValue: (value: string, type: string) => void;
  matchSelectedCareTeam: (value: any) => void;
}

export const AssignDesignee: React.FC<AssignDesigneeProps> = ({
  formFields,
  selectedItems,
  isClickedAdd,
  setIsClickedAdd,
  openOptions,
  setOpenOptions,
  careTeamSelectInputValue,
  setCareTeamSelectInputValue,
  options,
  changeOptionBaseOnValue,
  matchSelectedCareTeam,
  ...props
}) => {
  const classes = useStyles();

  if (selectedItems.length === 0) {
    // case to select site designee
    return (
      <>
        {/* add designee button */}
        {!isClickedAdd && (
          <div
            className={`${classes.addAssigneeContainerDefault} ${classes.addAssigneeContainer}`}
          >
            <ButtonMediumPrimaryText
              onClick={() => setIsClickedAdd(!isClickedAdd)}
              className={classes.addIconBtn}
              startIcon={<AddIcon className={classes.addIcon} />}
            />
            <Typography
              variant="body1"
              className={classes.addAssigneeText}
              onClick={() => setIsClickedAdd(!isClickedAdd)}
            >
              Select Site Designee
            </Typography>
          </div>
        )}

        {/* add designee autocomplete */}
        {isClickedAdd && (
          <UserSelect
            formFields={formFields}
            selectedItems={selectedItems}
            isClickedAdd={isClickedAdd}
            setIsClickedAdd={setIsClickedAdd}
            openOptions={openOptions}
            setOpenOptions={setOpenOptions}
            careTeamSelectInputValue={careTeamSelectInputValue}
            setCareTeamSelectInputValue={setCareTeamSelectInputValue}
            options={options}
            changeOptionBaseOnValue={changeOptionBaseOnValue}
            matchSelectedCareTeam={matchSelectedCareTeam}
          />
        )}
      </>
    );
  } else {
    // case to show selected / default site designee
    formFields.onChange(selectedItems[0]);

    if (isClickedAdd) {
      return (
        <UserSelect
          formFields={formFields}
          selectedItems={selectedItems}
          isClickedAdd={isClickedAdd}
          setIsClickedAdd={setIsClickedAdd}
          openOptions={openOptions}
          setOpenOptions={setOpenOptions}
          careTeamSelectInputValue={careTeamSelectInputValue}
          setCareTeamSelectInputValue={setCareTeamSelectInputValue}
          options={options}
          changeOptionBaseOnValue={changeOptionBaseOnValue}
          matchSelectedCareTeam={matchSelectedCareTeam}
        />
      );
    }

    return (
      <div className={classes.designeeContainer}>
        {/* selected assignee */}
        {selectedItems.map((item: any, idx: number) => {
          return (
            <DesigneeItem
              item={item}
              handleClick={(event: React.MouseEvent<HTMLDivElement>) =>
                setIsClickedAdd(true)
              }
            />
          );
        })}
      </div>
    );
  }
};

const UserSelect: React.FC<AssignDesigneeProps> = ({
  formFields,
  selectedItems,
  isClickedAdd,
  setIsClickedAdd,
  openOptions,
  setOpenOptions,
  careTeamSelectInputValue,
  setCareTeamSelectInputValue,
  options,
  changeOptionBaseOnValue,
  matchSelectedCareTeam,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classes.addAssigneeContainer}>
      <UserAutocomplete
        location={'PatientFaceSheet'}
        selectedItems={selectedItems}
        openOptions={openOptions}
        onOpenOptions={() => setOpenOptions(true)}
        onCloseOptions={() => {
          setOpenOptions(false);
          setIsClickedAdd(false);
        }}
        userInputValue={careTeamSelectInputValue}
        onUserInputChange={(event: object, value: string, reason: string) => {
          if (reason === 'input') {
            changeOptionBaseOnValue(value, 'lineOfBusiness');
          }
        }}
        multiple={false}
        options={options}
        getOptionLabel={(option) => option?.firstName + option?.lastName ?? ''}
        getOptionSelected={(option, value) => option.id === value.id}
        // value={selectedItems[0]}
        value={formFields.value}
        onValueChange={(e, v) => {
          setOpenOptions(true);
          matchSelectedCareTeam(v);
          formFields.onChange(v);
        }}
        popupIcon={<KeyboardArrowDown />}
        closeIcon={false}
        inputPlaceholder={'Search by Name'}
        noOptionsText="No matching providers"
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addAssigneeContainerDefault: {
    padding: theme.spacing(1, 2),
  },
  addAssigneeContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 12,
    boxSizing: 'border-box',

    '& > button.MuiButtonBase-root': {
      width: 24,
      height: 24,
      padding: 0,
      // borderRadius: '50%',
    },
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    width: 12,
    height: 12,
    textAlign: 'center',
  },
  addIconBtn: {
    width: 24,
    height: 24,
    minWidth: 24,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    padding: 0,

    '& span.MuiButton-startIcon': {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  addAssigneeText: {
    cursor: 'pointer',
    display: 'inline-block',
    width: 'fit-content',
    color: '#434343',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
  },
  designeeContainer: {
    width: '100%',
    height: 56,
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
  },
}));
