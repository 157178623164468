import React, { useState } from 'react';
import {
  TextareaAutosize,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { MAX_TEXT_LENGTH } from '../../../PatientInfo/components/EditNotes';
import { useOverLimit } from '../../../PatientInfo/hooks/useOverLimit';
import { useTextAreaBorder } from '../../../PatientInfo/hooks/useTextAreaBorder';
import {
  SiteFollowUpUser,
  User,
} from '../../../../../../../../../app.types.generated';
import { AssignDesignee } from '../AssgineDesignee';
import { InitStateType } from './Container';
import { LoadingSpinner } from '../../../../../../../../../components/LoadingSpinner';
import { SideBarProfileItem } from '../../../../../../../../../components/SideBarProfileItem';
import { EmptyPlaceHolder } from '../../../../../../../../../components/EmptyPlaceHolder';

interface SiteFollowUpFormViewProps {
  control: any;
  register: any;
  errors: any;
  handleSubmit: any;
  submitForm: (input?: any) => void;
  initState: InitStateType;
  dialogId: string;
}

export const SiteFollowUpFormView: React.FC<SiteFollowUpFormViewProps> = ({
  control,
  register,
  errors,
  handleSubmit,
  submitForm,
  initState,
  dialogId,
}) => {
  const classes = useStyles();

  const [isClickedAdd, setIsClickedAdd] = useState(false);
  const [openOptions, setOpenOptions] = useState(true);
  const [careTeamSelectInputValue, setCareTeamSelectInputValue] = useState('');
  const { textLength, overLimit } = useOverLimit({ control });
  const [selectedItems, setSelectedItems] = useState<SiteFollowUpUser[]>([]);
  const [siteFollowUpUserOptions, setSiteFollowUpUserOptions] = useState<
    SiteFollowUpUser[]
  >([]);

  // console.log('selectedItems', selectedItems);
  useTextAreaBorder({ overLimit, errors });

  const getErrorMessageElement = () => {
    if (overLimit.isOverLimit) {
      return (
        <Typography className={classes.errorMsg}>
          {`${overLimit.overLimitMsg} (${textLength.length}/${MAX_TEXT_LENGTH})`}
        </Typography>
      );
    } else if (errors.notes?.type !== 'maxLength') {
      return (
        <>
          {errors.notes && (
            <Typography className={classes.errorMsg}>
              {errors.notes.message}
            </Typography>
          )}
        </>
      );
    }
  };
  const changeOptionBaseOnValue = (newValue: string, type: string) => {
    setCareTeamSelectInputValue(newValue);
  };
  const matchSelectedCareTeam = (values: any) => {
    if (!values) return;
    let fullNameList = Array.isArray(values) ? values : [values];

    setSelectedItems(fullNameList);

    setCareTeamSelectInputValue('');
  };

  React.useEffect(() => {
    if (initState && initState.loading === false) {
      initState.defaultDesignee
        ? setSelectedItems([initState.defaultDesignee])
        : setSelectedItems([]);

      const designeeOptions = initState.designeeOptions as SiteFollowUpUser[];
      setSiteFollowUpUserOptions(designeeOptions);
    }
  }, [initState]);

  const getTextAreaTittleAndClassName = () => {
    switch (dialogId) {
      case 'Completed Site Follow Up': {
        return {
          title: 'Notes (Optional)',
          className: classes.textareaTitleOptional,
        };
      }
      default: {
        return {
          title: 'Notes',
          className: classes.textareaTitle,
        };
      }
    }
  };

  const getAssigneeTittleAndClassName = () => {
    switch (dialogId) {
      case 'Completed Site Follow Up': {
        return {
          title:
            'Patient will be assign back to the Previous Care Coordinator below',
          className: classes.assigneeTitleCompletion,
        };
      }
      default: {
        return {
          title: 'Assign to',
          className: classes.assigneeTitle,
        };
      }
    }
  };

  return (
    <div className={classes.root}>
      {initState.loading ? (
        <LoadingSpinner />
      ) : (
        <form
          onSubmit={handleSubmit(submitForm)}
          className={classes.siteFollowUpForm}
        >
          <div className={classes.noteSection}>
            <div className={classes.titleSectionContainer}>
              <Typography className={getTextAreaTittleAndClassName().className}>
                {getTextAreaTittleAndClassName().title}
              </Typography>
              <Typography className={classes.textTitleMemo}>
                This Note cannot be edited once added
              </Typography>
            </div>
            <Controller
              name="notes"
              control={control}
              render={({ onChange, value, name }) => (
                <TextareaAutosize
                  autoFocus
                  ref={register({
                    required: {
                      value:
                        dialogId === 'Completed Site Follow Up' ? false : true,
                      message: 'Please type some notes.',
                    },
                    maxLength: {
                      value: MAX_TEXT_LENGTH,
                      message: `The notes cannot exceed ${MAX_TEXT_LENGTH} characters.`,
                    },
                    pattern: {
                      value: /^(?!\s*$).+/g,
                      message: 'The notes cannot contain only white spacing.',
                    },
                  })}
                  name={name}
                  defaultValue={value}
                  placeholder="Type notes"
                  rowsMax={5}
                  style={{ width: '100%', height: 112, overflow: 'auto' }}
                  className={
                    errors.notes
                      ? classes.textarea + ' ' + classes.textareaError
                      : classes.textarea + ' ' + classes.textareaNormal
                  }
                  onChange={onChange}
                />
              )}
            />
            {getErrorMessageElement()}
          </div>
          <div className={classes.assigneeSection}>
            {/* <div className={classes.comingSoon}> */}
            <Typography className={getAssigneeTittleAndClassName().className}>
              {getAssigneeTittleAndClassName().title}
            </Typography>
            {dialogId === 'Completed Site Follow Up' &&
              (initState.defaultDesignee ? (
                <SideBarProfileItem
                  firstName={initState.defaultDesignee?.firstName || 'No'}
                  lastName={initState.defaultDesignee?.lastName || 'Data'}
                  title={initState.defaultDesignee?.roleName || 'No Data'}
                  avatarUrl={''}
                />
              ) : (
                <EmptyPlaceHolder text={'No Data'} />
              ))}
            {dialogId === 'Site Follow Up' && (
              <Controller
                name="designee"
                control={control}
                render={({ onChange, value, name }) => {
                  return (
                    <AssignDesignee
                      formFields={{ onChange, value, name }}
                      selectedItems={selectedItems}
                      isClickedAdd={isClickedAdd}
                      setIsClickedAdd={setIsClickedAdd}
                      openOptions={openOptions}
                      setOpenOptions={setOpenOptions}
                      careTeamSelectInputValue={careTeamSelectInputValue}
                      setCareTeamSelectInputValue={setCareTeamSelectInputValue}
                      options={siteFollowUpUserOptions}
                      matchSelectedCareTeam={matchSelectedCareTeam}
                      changeOptionBaseOnValue={changeOptionBaseOnValue}
                    />
                  );
                }}
              />
            )}

            {/* <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: '20px',
                        marginTop: -10,
                      }}
                    >
                      (New Feature Coming Soon)
                    </Typography> */}
            {/* </div> */}
          </div>
        </form>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
  },
  siteFollowUpForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 24,

    width: '100%',
  },
  noteSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 4,

    width: '100%',
  },
  textareaTitle: {
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',

    '&::after': {
      content: "' *'",
      color: '#E7434C',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
    },
  },
  textareaTitleOptional: {
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  textarea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 0, 2),
    resize: 'none',

    '&:focus': {
      outline: 'none !important',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textareaNormal: {
    outline: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  textareaError: {
    outline: 'none !important',
    border: `1px solid ${theme.palette.error.main}`,
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
  actionBtnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assigneeSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 4,

    width: '100%',
  },
  assigneeTitleCompletion: {
    color: '#656565',
    fontFamily: 'Manrope',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  assigneeTitle: {
    color: theme.palette.grey[700],
    fontFamily: 'Manrope',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  comingSoon: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    opacity: 0.4,
    cursor: 'default',
    userSelect: 'none',
    '& *': {
      userSelect: 'none!important',
      cursor: 'default!important',
    },
  },
  titleSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
  },
  textTitleMemo: {
    fontFamily: 'Manrope',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
}));
