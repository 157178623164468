import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { EngagementLogView } from './EngagementLogView';
import {EngagementLogStatus} from "../EngagementBatchesTable";

export const EligibilityEngagementLogContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {batchId, status } = useParams();
  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
                       skip,
                       limit,
                     }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const name = "Eligibility"
  const breadCrumbHeader =  {
    isTitle: true,
    title: `${name} Engagement Log`,
    rootLink: `/eligibility`,
    rootName: `${name}`,
    childrenLink: `/eligibility/patients/engagement`,
    childrenName: 'Engagements',
    childrenChildrenName: 'Send to',
  }
  let engagementLogStatus = EngagementLogStatus.sending
  if (status && Object.values<string>(EngagementLogStatus).includes(status)) {
    engagementLogStatus = status as EngagementLogStatus
  }

  return (<EngagementLogView
    engagementLogStatus={engagementLogStatus}
    limit={limit ?? 10}
    skip={skip ?? 0}
    setLimit={onSetLimit}
    setSkip={onSetSkip}
    batchId={batchId}
    breadCrumbHeader={breadCrumbHeader}
  />);
};
