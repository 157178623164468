import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetEligibilitySummaryInPatientQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEligibilitySummaryInPatientQuery = (
  { __typename: 'Query' }
  & { getEligibilitySummary: (
    { __typename: 'EligibilitySummaryGraphQLResponse' }
    & { summaryInfo: (
      { __typename: 'EligibilitySummaryInfo' }
      & Pick<Types.EligibilitySummaryInfo, 'activeExternal' | 'activeInternal' | 'inactive' | 'rejected' | 'unavailable' | 'updatedDate' | 'nextScheduledDate' | 'smsBatchCount'>
    ) }
  ) }
);

export type GetEligibilityPatientListQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.EligibilityPatientListGraphQlRequest>;
}>;


export type GetEligibilityPatientListQuery = (
  { __typename: 'Query' }
  & { getEligibilityPatientList: (
    { __typename: 'EligibilityPatientListGraphQLResponse' }
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'EligibilityPagingInfo' }
      & Pick<Types.EligibilityPagingInfo, 'count' | 'limit' | 'skip'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'PatientEligibility' }
      & Pick<Types.PatientEligibility, 'dob' | 'gender' | 'id' | 'lineOfBusiness' | 'patientName' | 'pcp' | 'rejectedReason'>
    )>> }
  ) }
);

export type GetEligibilityRejectedReasonCategoryListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEligibilityRejectedReasonCategoryListQuery = (
  { __typename: 'Query' }
  & { getEligibilityRejectedReasonCategoryList: (
    { __typename: 'EligibilityRejectedReasonCategoryListGraphQLResponse' }
    & { results?: Types.Maybe<Array<(
      { __typename: 'EligibilityRejectedReasonCategory' }
      & Pick<Types.EligibilityRejectedReasonCategory, 'rejectedReasonCategory'>
    )>> }
  ) }
);


export const GetEligibilitySummaryInPatientDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilitySummaryInPatient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilitySummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"summaryInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeExternal"}},{"kind":"Field","name":{"kind":"Name","value":"activeInternal"}},{"kind":"Field","name":{"kind":"Name","value":"inactive"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"unavailable"}},{"kind":"Field","name":{"kind":"Name","value":"updatedDate"}},{"kind":"Field","name":{"kind":"Name","value":"nextScheduledDate"}},{"kind":"Field","name":{"kind":"Name","value":"smsBatchCount"}}]}}]}}]}}]};

/**
 * __useGetEligibilitySummaryInPatientQuery__
 *
 * To run a query within a React component, call `useGetEligibilitySummaryInPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilitySummaryInPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilitySummaryInPatientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEligibilitySummaryInPatientQuery(baseOptions?: Apollo.QueryHookOptions<GetEligibilitySummaryInPatientQuery, GetEligibilitySummaryInPatientQueryVariables>) {
        return Apollo.useQuery<GetEligibilitySummaryInPatientQuery, GetEligibilitySummaryInPatientQueryVariables>(GetEligibilitySummaryInPatientDocument, baseOptions);
      }
export function useGetEligibilitySummaryInPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilitySummaryInPatientQuery, GetEligibilitySummaryInPatientQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilitySummaryInPatientQuery, GetEligibilitySummaryInPatientQueryVariables>(GetEligibilitySummaryInPatientDocument, baseOptions);
        }
export type GetEligibilitySummaryInPatientQueryHookResult = ReturnType<typeof useGetEligibilitySummaryInPatientQuery>;
export type GetEligibilitySummaryInPatientLazyQueryHookResult = ReturnType<typeof useGetEligibilitySummaryInPatientLazyQuery>;
export type GetEligibilitySummaryInPatientQueryResult = Apollo.QueryResult<GetEligibilitySummaryInPatientQuery, GetEligibilitySummaryInPatientQueryVariables>;
export function refetchGetEligibilitySummaryInPatientQuery(variables?: GetEligibilitySummaryInPatientQueryVariables) {
      return { query: GetEligibilitySummaryInPatientDocument, variables: variables }
    }
export const GetEligibilityPatientListDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilityPatientList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilityPatientListGraphQLRequest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilityPatientList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}}]}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dob"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lineOfBusiness"}},{"kind":"Field","name":{"kind":"Name","value":"patientName"}},{"kind":"Field","name":{"kind":"Name","value":"pcp"}},{"kind":"Field","name":{"kind":"Name","value":"rejectedReason"}}]}}]}}]}}]};

/**
 * __useGetEligibilityPatientListQuery__
 *
 * To run a query within a React component, call `useGetEligibilityPatientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityPatientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityPatientListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEligibilityPatientListQuery(baseOptions?: Apollo.QueryHookOptions<GetEligibilityPatientListQuery, GetEligibilityPatientListQueryVariables>) {
        return Apollo.useQuery<GetEligibilityPatientListQuery, GetEligibilityPatientListQueryVariables>(GetEligibilityPatientListDocument, baseOptions);
      }
export function useGetEligibilityPatientListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityPatientListQuery, GetEligibilityPatientListQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilityPatientListQuery, GetEligibilityPatientListQueryVariables>(GetEligibilityPatientListDocument, baseOptions);
        }
export type GetEligibilityPatientListQueryHookResult = ReturnType<typeof useGetEligibilityPatientListQuery>;
export type GetEligibilityPatientListLazyQueryHookResult = ReturnType<typeof useGetEligibilityPatientListLazyQuery>;
export type GetEligibilityPatientListQueryResult = Apollo.QueryResult<GetEligibilityPatientListQuery, GetEligibilityPatientListQueryVariables>;
export function refetchGetEligibilityPatientListQuery(variables?: GetEligibilityPatientListQueryVariables) {
      return { query: GetEligibilityPatientListDocument, variables: variables }
    }
export const GetEligibilityRejectedReasonCategoryListDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilityRejectedReasonCategoryList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilityRejectedReasonCategoryList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rejectedReasonCategory"}}]}}]}}]}}]};

/**
 * __useGetEligibilityRejectedReasonCategoryListQuery__
 *
 * To run a query within a React component, call `useGetEligibilityRejectedReasonCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityRejectedReasonCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityRejectedReasonCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEligibilityRejectedReasonCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<GetEligibilityRejectedReasonCategoryListQuery, GetEligibilityRejectedReasonCategoryListQueryVariables>) {
        return Apollo.useQuery<GetEligibilityRejectedReasonCategoryListQuery, GetEligibilityRejectedReasonCategoryListQueryVariables>(GetEligibilityRejectedReasonCategoryListDocument, baseOptions);
      }
export function useGetEligibilityRejectedReasonCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityRejectedReasonCategoryListQuery, GetEligibilityRejectedReasonCategoryListQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilityRejectedReasonCategoryListQuery, GetEligibilityRejectedReasonCategoryListQueryVariables>(GetEligibilityRejectedReasonCategoryListDocument, baseOptions);
        }
export type GetEligibilityRejectedReasonCategoryListQueryHookResult = ReturnType<typeof useGetEligibilityRejectedReasonCategoryListQuery>;
export type GetEligibilityRejectedReasonCategoryListLazyQueryHookResult = ReturnType<typeof useGetEligibilityRejectedReasonCategoryListLazyQuery>;
export type GetEligibilityRejectedReasonCategoryListQueryResult = Apollo.QueryResult<GetEligibilityRejectedReasonCategoryListQuery, GetEligibilityRejectedReasonCategoryListQueryVariables>;
export function refetchGetEligibilityRejectedReasonCategoryListQuery(variables?: GetEligibilityRejectedReasonCategoryListQueryVariables) {
      return { query: GetEligibilityRejectedReasonCategoryListDocument, variables: variables }
    }