import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { Typography } from '@material-ui/core';
import { CalenderIcon } from '../../assets/Icons/CareGaps/Calender';
import { CustomDatePickerToolbar } from './CustomDatePickerToolbar';
import moment from 'moment';
import { useLocation } from 'react-router';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const datePickerTheme = createMuiTheme({
  typography: {
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 'bold',
  },
  palette: {
    primary: {
      main: '#0C77D8',
      light: '#EDF6FF',
      dark: '#0761B3',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#E5E5E5',
      light: '#EFEFEF',
      dark: '#656565',
      contrastText: '#656565',
    },
    text: {
      primary: 'rgba(67, 67, 67, 100)',
      secondary: 'rgba(101, 101, 101, 100)',
      disabled: 'rgba(209, 209, 209, 100)',
      hint: 'rgba(136, 136, 136, 100)',
    },
    grey: {
      '50': '#F8F8F8',
      '100': '#EFEFEF',
      '200': '#E5E5E5',
      '300': '#D1D1D1',
      '400': '#ABABAB',
      '500': '#888888',
      '600': '#656565',
      '700': '#434343',
    },
    info: {
      light: '#EDF6FF',
      main: '#0C77D8',
      dark: '#0761B3',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#E8F6F2',
      main: '#008479',
      dark: '#105751',
      contrastText: '#fff',
    },
    warning: {
      light: '#FCF3E5',
      main: '#D46829',
      dark: '#913C00',
      contrastText: '#fff',
    },
    error: {
      light: '#FFEAEB',
      main: '#B7373E',
      dark: '#A02C32',
      contrastText: '#fff',
    },
    avatars: {
      yellow: '#E1A230',
      voilet: '#BC62CD',
      teal: '#49BFA9',
      red: '#E66556',
      purple: '#8061D7',
      pink: '#D26EAA',
      orange: '#E4814B',
      green: '#8AC55C',
      cyan: '#41C3E8',
      blue: '#586EFF',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0C77D8',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFF',
        fontSize: 20,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        fontSize: '30px!important',
        color: '#0C77D8!important',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#0C77D8',
      },
    },
    MuiTextField: {
      root: {
        marginTop: 0,
        borderRadius: 6,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    MuiInputBase: {
      input: {
        height: 3,
        fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
  } as any,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',

    '& .MuiInputBase-root': {
      height: 40,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  dateNotSelected: {
    fontSize: 16,
    color: theme.palette.grey['400'],
    borderRadius: 6,
  },
  dateSelected: {
    fontSize: 16,
    color: theme.palette.grey['600'],
    borderRadius: 6,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
}));

interface DateInputProps {
  width?: number;
  inputTitle: string;
  type: any;
  emptyLabel: string;
  state: Date | null;
  setState: any;
  minDate?: Date | null;
  maxDate?: Date | null;
}

export default function DateRangeCustomInput(props: DateInputProps) {
  // The first commit of Material-UI
  const classes = useStyles();
  const today = new Date();

  const handleDateChange = (date: Date | null) => {
    props.setState(props.type, date);
  };

  const { pathname } = useLocation();
  return (
    <div
      className={classes.selectContainer}
      style={{ width: props.width || 203 }}
    >
      <Typography
        id={`${props.inputTitle}`}
        variant="h5"
        className={classes.inputTitle}
      >
        {props.inputTitle}
      </Typography>
      <MuiThemeProvider theme={datePickerTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={
              pathname.includes('editPanel')
                ? props.state
                  ? moment.utc(props.state).format('YYYY/MM/DD')
                  : props.state
                : props.state
            }
            maxDate={props.maxDate || today}
            minDate={props.minDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              className: !props.state
                ? classes.dateNotSelected
                : classes.dateSelected,
            }}
            emptyLabel={props.emptyLabel}
            className={classes.root}
            keyboardIcon={<CalenderIcon />}
            ToolbarComponent={CustomDatePickerToolbar}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
}
