import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Button, InputBase, makeStyles, Theme } from '@material-ui/core';
import { SearchIcon } from '../../../../assets/Icons/Eligibility/SearchIcon';
import { FiltersIcon } from '../../../../assets/Icons/Eligibility/FiltersIcon';

import { dateFormatPipe } from '../../../../sharePipe/dateFormatPipe';

import { theme } from '../../../../themes/theme';

import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import {
  tableUseStyles,
  tableUseToolbarStyles,
} from '../../../Insurances/InsuranceListingPage/InsuranceListTable/TableStyle';

function descendingComparator(a: any, b: any, orderBy: keyof any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  align: 'left' | 'right';
}

const headCells: HeadCell[] = [
  {
    id: 'payerId',
    align: 'left',
    disablePadding: false,
    label: 'Payer ID',
  },
  {
    id: 'payerName',
    align: 'left',
    disablePadding: false,
    label: 'Payer Name',
  },
  {
    id: 'file',
    align: 'left',
    disablePadding: false,
    label: 'Most Recent File',
  },
  {
    id: 'lastUpdate',
    align: 'left',
    disablePadding: false,
    label: 'Last Update',
  },
];

const nonsortableHeads = ['file'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the more-options column */}
        {/* <TableCell></TableCell> */}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  tableTitle?: string;
  //searchbar
  enableSearch?: boolean;
  searchPlaceHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  searchEnableFilter?: boolean;
  renderButton?: any;
}

interface SearchBannerProps {
  placeHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  enableFilter?: boolean;
}

const SearchBanner = (props: SearchBannerProps) => {
  const classes = tableUseToolbarStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={props.placeHolder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
      {props.enableFilter && (
        <Button className={classes.filtersBtn}>
          <FiltersIcon className={classes.filtersIcon} />
          <Typography variant="body1" className={classes.filtersText}>
            Filters
          </Typography>
        </Button>
      )}
    </div>
  );
};

interface ItemListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  results: any;
  count: number;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
}

export const PayerListingTableView = (props: ItemListTableViewProps) => {
  const classes = tableUseStyles();
  const altClasses = useStyles();
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.limit ?? 10);

  const [isSorting, setIsSorting] = React.useState(false);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          request: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  React.useEffect(() => {
    setFilteredRows(props.results);

    setIsSorting(false);
  }, [props.results]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /*
      to check if current file is in bold :
      - must be unread file and
        - status === complete and having new patients
        - status === error no mater the new patient count
      */
  function isBold(file: any) {
    return file.isRead === false;
  }

  /*
      to perform action according to the file status, e.g: visiting new patients
      - to set isRead to true and go to new patient listing page
      */
  function navigateToFiles(event: any, row: any) {
    event.stopPropagation();
    event.preventDefault();
    if (row.mostRecentFileId) {
      navigate(`/payerID/${row.payerId}/${row.mostRecentFileId}`, {
        state: { name: row.payerName },
      });
    }
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [exportOption, setExportOption] = React.useState('');
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // @ts-ignore
  return (
    <div className={`${classes.root} ${altClasses.root}`}>
      <Paper className={`${classes.paper} ${altClasses.paper}`} elevation={1}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredRows &&
                  stableSort(filteredRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={`${row.payerId}`}
                          onClick={() => {
                            navigate(`/payerID/${row.payerId}`, {
                              state: { payerName: row.payerName },
                            });
                          }}
                          className={classes.hoverRow}
                        >
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {row.payerId}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            <span
                              style={{
                                maxWidth: 260,
                                display: 'flex',
                              }}
                            >
                              <span
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                }}
                              >
                                {row.payerName}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span
                              style={{
                                color: '#0C77D8',
                                fontWeight: 600,
                                fontSize: 12,
                                borderBottom: '2px solid #0C77D8',
                              }}
                              onClick={(event) => {
                                navigateToFiles(event, row);
                              }}
                            >
                              {row.mostRecentFileName ?? ''}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {dateFormatPipe(row.lastUpdate, '-')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: any;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = tableUseStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
