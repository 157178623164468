/* 
Return the difference of two objects if they're both valid objects, otherwise return undefined
*/
export const getDiffsAsObject = (obj1: any, obj2: any) => {
  if (obj1 && obj2) {
    const diffs = Object.entries(obj1).reduce((acc: any, [key, value]) => {
      if (!Object.hasOwn(obj2, key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

    if (Object.keys(diffs).length === 0) {
      return null;
    }

    return diffs;
  } else {
    return obj1 ? obj1 : obj2 ? obj2 : undefined;
  }
};
