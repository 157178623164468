import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MainRegion } from '../../../components/MainRegion';
import { Grid, Typography } from '@material-ui/core';
import { GlobalContext } from '../../../components/GlobalContext';
import Auth from '@aws-amplify/auth';
import CryptoJS from 'crypto-js';
import * as crypto from 'crypto';
import { config } from '../../../config';
import LogoWithAppNameColored from '../../../assets/Login/LogoWithAppNameColored.svg';
import MdlandLogoColored from '../../../assets/Login/MdlandLogoColored.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: 280,
    flexGrow: 1,
    justifyContent: 'center',
  },
  footerText: {
    fontSize: 14,
    paddingRight: theme.spacing(1),
    color: '#434343',
    fontWeight: 400,
    lineHeight: '19px',
  },
  footer: {
    position: 'absolute',
    bottom: 56,
    display: 'flex',
    alignItems: 'center',
  },
}));

export function IClinicLogin() {
  const classes = useStyles();
  const backendUrl = config.iClinic.backendUrl;
  const iClinicUserUrl = config.iClinic.iClinicUserUrl;
  const { loggedInUser, loading } = useContext(GlobalContext);
  const [user, SetUser] = React.useState<any>(null);
  const [userInfoFromToken, setUserInfoFromToken] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);
  const [firstLoading, setFirstLoading] = React.useState(true);
  const navigate = useNavigate();
  const [origin, setOrigin] = React.useState('');
  const [token, setToken] = React.useState('');
  const [targetUser, setTargetUser] = React.useState('');
  const [backendApiKey, setBackendApiKey] = React.useState<string | undefined>(
    undefined
  );
  const [randomAESKey, setRandomAESKey] = React.useState('');
  const [randomAESIv, setRandomAESIv] = React.useState('');
  const [customError, setCustomError] = React.useState('');

  React.useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        // Do we trust the sender of this message?  (might be
        // different from what we originally opened, for example).
        setOrigin(event.origin);
        try {
          const json = JSON.parse(event.data);
          setToken(json.token);
          setTargetUser(json.user);
        } catch (e: any) {
          // console.log(e);
        }
      },
      false
    );
  });

  React.useEffect(() => {
    getBackendApiKey();
    setRandomAESKey(randomString());
    setRandomAESIv(randomString());
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (token == '' && targetUser == '') {
        setError('Message not received after 30s of launch, please try again');
      }
    }, 30000);
  });

  function getBackendApiKey() {
    let requestOptions: any = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(backendUrl!, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBackendApiKey(atob(result?.data ?? ''));
      })
      .catch(() => {
        //
      });
  }

  function randomString(keyLen = 32) {
    const charTable =
      '!#$%&()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~';
    let tableLen = charTable.length;
    let result = '';
    for (let i = 0; i < keyLen; i++) {
      result += charTable.charAt(Math.floor(Math.random() * tableLen));
    }
    return result;
  }

  function decrypt(word: string) {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);

    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    let decrypt = CryptoJS.AES.decrypt(
      srcs,
      CryptoJS.enc.Utf8.parse(randomAESKey)!,
      {
        iv: CryptoJS.enc.Utf8.parse(randomAESIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

    return decryptedStr.toString();
  }

  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  React.useEffect(() => {
    if (token && targetUser && backendApiKey) {
      // const text = JSON.stringify({"ky": randomAESKey, "iv": randomAESIv});
      const text = JSON.stringify({ ky: randomAESKey, iv: randomAESIv });
      const enc = crypto
        .publicEncrypt(backendApiKey, Buffer.from(text))
        .toString('hex');
      (async function fetchAsync() {
        let raw = JSON.stringify({
          authToken: token,
          userInternalTargetID: targetUser,
          extraData: enc,
        });

        let requestOptions: any = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch(iClinicUserUrl!, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            try {
              const obj: any = result;
              if (obj?.isSuccess == false && obj?.failReason) {
                setCustomError(obj.failReason);
              }
              const data = JSON.parse(decrypt(obj?.data));
              setUserInfoFromToken({
                username: data?.username ?? '',
                password: data?.password ?? '',
              });
            } catch (err) {
              setError('error');
            }
          })
          .catch(() => {
            setError('TypeError: Failed to fetch');
          });
        setFirstLoading(false);
      })();
    }
    if (loggedInUser !== undefined) {
      Auth.signOut();
    }
  }, [token, targetUser, backendApiKey]);
  React.useEffect(() => {
    try {
      const fetchData = async () => {
        await Auth.signIn(
          userInfoFromToken?.username,
          userInfoFromToken?.password
        ).then((user) => {
          SetUser(user);
        });
      };
      if (userInfoFromToken != null) {
        fetchData();
      }
    } catch (error: any) {
      //
    }
  }, [userInfoFromToken]);

  React.useEffect(() => {
    // redirect after auth.signIn is finish and set in global context
    if (user && loggedInUser) {
      navigate('/dashboard');
    }
  }, [user, loggedInUser]);

  return (
    <MainRegion>
      <Grid container className={classes.grid}>
        <img src={LogoWithAppNameColored} alt="iPopHealth" />
        {(firstLoading || loading) && (
          <LoadingSpinner
            customText={'Login from iClinic...'}
            style={{ width: '100%', paddingTop: 121.89 }}
          />
        )}
        {customError && (
          <div style={{ color: '#B7373E' }}>
            customError: <span>{customError}</span>
          </div>
        )}
        {error != null ? (
          <div style={{ color: '#B7373E' }}>{error}! Please try again.</div>
        ) : null}
        <div className={classes.footer}>
          <Typography className={classes.footerText}>Powered by</Typography>{' '}
          <img src={MdlandLogoColored} alt="MDLand" />
        </div>
      </Grid>
    </MainRegion>
  );
}
