export function getCallAttemptPipe(number: any) {
  switch (number) {
    case 0:
      return 'First';
    case 1:
      return '2nd';
    case 2:
      return '3rd';
    default:
      return number + 1 + 'th';
  }
}
