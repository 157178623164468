import React from 'react';
import { useLocation } from 'react-router-dom';
import { PanelPatient } from '../app.types.generated';

export const QUICK_ACTION = {
  START_COORDINATION_WITHIN_PANEL: 0,
};

interface Props {
  checkedPatient: PanelPatient;
  results: PanelPatient[];
  setCheckedPatient: (value: React.SetStateAction<PanelPatient>) => void;
  setShowDrawer: (value: React.SetStateAction<boolean>) => void;
}

export const useStartCoordination = (props: Props) => {
  const { checkedPatient, results, setCheckedPatient, setShowDrawer } = props;

  const location = useLocation();
  const passingData = location.state;
  React.useEffect(() => {
    if (
      passingData &&
      passingData.action === QUICK_ACTION.START_COORDINATION_WITHIN_PANEL &&
      results
    ) {
      const firstPatient = results[0];
      setCheckedPatient(firstPatient);
    }
  }, [passingData, results, checkedPatient]);
  React.useEffect(() => {
    if (checkedPatient && checkedPatient.id) {
      setShowDrawer(true);
      delete location.state;
    } else {
      setShowDrawer(false);
    }
  }, [checkedPatient]);
};
