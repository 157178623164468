import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useEmptyTabContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 155,
    },
    mainContainer: {
      gap: theme.spacing(4),
    },
    hintContainer: {
      width: "fit-content",
      height: 168,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      '& h4[role="hintTitle"]': {
        fontSize: 32,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
        marginBottom: theme.spacing(1.25),
      },

      '& p[role="hintDesc"]': {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        textAlign: 'center',
      },
    },
    hintIcon: {
      width: 60,
      height: 60,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2.125),
    },
    smallPrimarybtn: {
      color: theme.palette.primary.contrastText,
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      background: theme.palette.primary.main,
      borderRadius: '8px',
      height: 48,
      lineHeight: '24px',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },

      '& .MuiButton-label .MuiSvgIcon-root': {
        fontSize: '14px',
        padding: theme.spacing(0),
        marginLeft: theme.spacing(-1),
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: '0px',
        textTransform: 'capitalize',
        marginLeft: theme.spacing(0.41625),
      },
    },
  })
);

export const useEngagementContentTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    control: {
      padding: theme.spacing(2),
    },

    li: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      listStyle: 'none',
    },

    tableTitleContainer: {
      backgroundColor: theme.palette.grey['50'],
      width: '100%',
      height: 42,
      borderRadius: '0px',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      borderBottom: '1px solid #d1d1d1',
    },
    tabsContainer: {
      display: 'inline-block',
      margin: theme.spacing(1.5, 3, 1.5, 'auto'),
      height: 32,
      borderRadius: 6,
      border: '1px solid rgba(0, 0, 0, 0.12)',

      '& .MuiTabs-root': {
        borderBottom: 'none',
      },

      '& .MuiTabs-flexContainer': {
        height: 32,
      },

      '& .MuiTab-root': {
        fontSize: 14,
        minHeight: 'fit-content',
        lineHeight: 'normal',
        textTransform: 'none',
      },

      '& button.MuiButtonBase-root': {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(0.5, 1.5),
      },

      '& .MuiTab-labelIcon': {
        paddingTop: theme.spacing(0.75),
      },

      '& span.MuiTab-wrapper': {
        flexDirection: 'row',
      },

      '& span.MuiTab-wrapper > *:first-child': {
        marginRight: theme.spacing(0.5),
        marginBottom: 0,
      },
    },

    tableTitle: {
      fontSize: '20px',
      fontWeight: theme.typography.fontWeightBold,
      // lineHeight: '28px',
      textAlign: 'left',
      // height: '24px',
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      backgroundColor: '#ededed',
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '16px',
      width: '100%',
      height: '40px',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    tableHeaderCell: {
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      lineHeight: '16px',
    },
    tableBodyRow: {
      width: '100%',
      height: '56px',
      borderRadius: '0px',
      backgroundColor: theme.palette.primary.contrastText,
    },

    tableBodyRowCell: {
      height: '56px',
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '24px',
    },

    insuranceText: {
      display: 'block',
      color: theme.palette.primary.main,
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '0px',
      lineHeight: '20px',
      marginLeft: theme.spacing(1.125),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: 7,
      paddingRight: 7,
      whiteSpace: 'nowrap',
      width: 'fit-content',
      height: '20px',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '6px',
      textAlign: 'center',
    },

    pcp: {
      borderBottom: '2px solid rgba(67, 67, 67, 0.3)',
    },
    initiateAppt: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    sentChip: {
      background: theme.palette.grey['100'],
      color: theme.palette.grey['500'],
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    sendingIcon: {
      color: '#0C77D8',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    sendingChip: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    failChip: {
      background: '#FCF3E5!important',
      color: '#C05314',
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    failAllChip: {
      background: '#FFEAEB',
      color: '#B7373E',
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    openGap: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    warningIcon: {
      color: '#C05314',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    failIcon: {
      color: '#B7373E',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    tableContainer: {
      border: 0,
      borderRadius: '0px',
      padding: 0,
      '& .MuiTableHead-root': {
        boxShadow:
          '0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 3px 6px 0px rgb(0 0 0 / 8%)',
        background: theme.palette.grey[50],
      },

      '& > table > thead > tr > th': {
        paddingLeft: theme.spacing(2),
        padding: 0,
        height: 32,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: 'none',
      },

      '& > table > thead > tr': {
        height: 56,
      },

      '& > table > tbody > tr': {
        height: 56,
        backgroundColor: theme.palette.primary.contrastText,
      },

      '& > table > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > table > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },

      '& > table > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
      },

      '& > table > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },

    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
        {
          order: -1,
          marginRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightBold,
        },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    // table head
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    moreHoriz: {
      color: theme.palette.grey[500],
    },
    tabIconsNormal: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.grey[500],
    },
    tabIconsActive: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.primary.main,
    },
    tabTextActive: {
      fontWeight: theme.typography.fontWeightBold,
      color: '#000',
    },
    tabTextNormal: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[500],
    },
    button: {
      padding: theme.spacing(0, 0, 0, 0),
      color: theme.palette.grey[400],
      width: 24,
      height: 24,
      marginRight: 11,
    },
    searchRow: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 24,
      height: 24,
      display: 'flex',
    },
    inputText: {
      fontSize: theme.typography.fontSize,
    },
    dobButton: {
      backgroundColor: 'transparent',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0,
      color: theme.palette.secondary.dark,
      height: 24,
      '& .svg': {
        color: '#888888',
      },
    },
    linkButton: {
      borderBottom: '2px solid rgb(67,67,67,0.3)',
      lineHeight: '20px',
      fontSize: 12,
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    hoverTableCell: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);
