import React, { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  Menu,
  MenuProps,
  styled,
  TablePagination,
  fade,
  MenuItem,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { HeadCell } from '../../../../sharePipe/createHeaderCellPipe';
import { RoleType } from '../../../../enum/Users';
import { CareTeamMember } from '../../../../app.types.generated';
import { formatNumberPipe } from '../../../../sharePipe/formatNumberPipe';
import { BulletPlot } from '../../../../components/Charts/BulletPlot';
import { MoreHorizIcon } from '../../../../assets/Icons/Eligibility/MoreHorizIcon';
import { theme } from '../../../../themes/theme';
import { ActivateUserIcon } from '../../../../assets/Icons/MsoAdmin';
import { GlobalContext } from '../../../../components/GlobalContext';
import { useStickyTableStyle } from '../../../../components/TabBar/Styles';
import { dateFormatPipe } from '../../../../sharePipe/dateFormatPipe';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ManageCoordinatorDialog } from '../ManageCoordinatorDialog/Container';
import { EditPanelIcon } from '../../../../assets/Icons/PanelsList/EditPanelIcon';
import { ArchiveDialogBox } from '../ArchivePanelDialog';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1.5), // -6.5 1.5
    width: 224,
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1, 2),
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      lineHeight: '24px',
      display: 'inline-block',
      height: 24,
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  tableType: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    headCells,
    tableType,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <StyledCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            checkedAll={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all patients' }}
            className={classes.checkbox}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* placeholder for the more-options column for MSO onlye */}
        <TableCell></TableCell>
        {tableType === RoleType.MSO_ADMIN ? <TableCell></TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableRowProps {
  row: any;
  tableType: string;
  setManageAssignCoordinatorDialog?: (input: any) => any;
  setCheckedPanelId: (input: any) => any;
  selectedArchivePanel: any;
  setSelectedArchivePanel: any;
}

function EnhancedTableRow(props: EnhancedTableRowProps) {
  const { row, tableType, setCheckedPanelId } = props;
  let navigate = useNavigate();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionEdit = () => {
    setAnchorEl(null);
    navigate(`/panels/${row.id}/editPanel`);
  };

  const handleOptionMange = () => {
    setAnchorEl(null);
    if (props.setManageAssignCoordinatorDialog)
      props.setManageAssignCoordinatorDialog(true);
  };

  return (
    <TableRow
      hover
      onClick={() => {
        navigate(`/panels/${row.id}/summary`);
      }}
      className={classes.tableRow}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      {/* panel name */}
      <TableCell align="left">
        {/* <Link to={`/panels/${row.id}/summary`} className={classes.aLink}> */}
        <span className={classes.tableBodyRowCell}>{row.name}</span>
        {/* </Link> */}
      </TableCell>

      {tableType === RoleType.MSO_ADMIN && (
        <TableCell align="center">
          {row.careTeamUsers
            ? formatNumberPipe(row.careTeamUsers.length, '0')
            : 0}
        </TableCell>
      )}

      <TableCell align="left">
        <div className={classes.newPatientText}>
          {formatNumberPipe(row.patientCount, '0')}
        </div>
      </TableCell>
      <TableCell align="left">
        {formatNumberPipe(row.openCareGapCount, '0')}
      </TableCell>
      <TableCell align="left">
        <BulletPlot
          code={''}
          numEligible={100}
          numComplaint={row.complianceRate}
          numNonComplaint={100 - row.complianceRate}
          progress={row.complianceRate}
          // numExclusive={row.exclusive}
        />
      </TableCell>
      <TableCell align="left" colSpan={2}>
        {dateFormatPipe(row.createdAt)}
      </TableCell>
      {tableType === RoleType.MSO_ADMIN ? (
        <TableCell align="right" onClick={(e) => e.stopPropagation()}>
          <Button
            id={`more-option-button-${row.id}`}
            aria-controls={`more-option-menu-${row.id}`}
            aria-haspopup="true"
            variant="contained"
            disableElevation
            style={{
              border: 'none',
              background: 'inherit',
              padding: 0,
              height: 'auto',
            }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setCheckedPanelId(row.id);
            }}
          >
            <MoreHorizIcon style={{ color: theme.palette.grey[500] }} />
          </Button>

          <StyledMenu
            id={`more-option-menu-${row.id}`}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            className={classes.menu}
          >
            <MenuItem
              onClick={() => handleOptionEdit()}
              className={classes.menuItem}
              disableRipple
              disabled={false}
            >
              <span>Edit</span>
              <EditPanelIcon className={classes.iconStyleMenu} />
            </MenuItem>
            <MenuItem
              onClick={handleOptionMange}
              className={classes.menuItem}
              disableRipple
            >
              <span>Manage Coordinator</span>
              <ActivateUserIcon className={classes.iconStyleMenu} />
            </MenuItem>
            {/*<MenuItem onClick={() => handleOptionArchive(row)} disableRipple>*/}
            {/*  <span>Archive</span>*/}
            {/*  <ArchivePanelIcon className={classes.iconStyleMenu} />*/}
            {/*</MenuItem>*/}
          </StyledMenu>
        </TableCell>
      ) : null}
    </TableRow>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    paper: {
      width: '100%',
      // marginBottom: theme.spacing(2),
    },
    aLink: {
      textDecoration: 'none',
    },
    newPatientText: {
      width: 'fit-content',
      // color: `${theme.palette.primary.main}`,
      fontWeight: 600,
      fontSize: 12,
      cursor: 'pointer',
    },
    tableBodyRowCell: {
      height: '24px',
      lineHeight: '24px',
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
    },
    table: {
      minWidth: 750,
      border: 'none',

      '& .MuiTableHead-root': {
        // boxShadow: 'none',
        background: theme.palette.grey[50],
      },

      '& > thead > tr > th': {
        paddingLeft: theme.spacing(3),
        padding: 0,
        height: 32,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
      },

      '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },

      '& > tbody > tr': {
        height: 56,
      },

      '& > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        paddingTop: theme.spacing(1.875),
        paddingBottom: theme.spacing(1.875),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        lineHeight: '24px',
        height: 24,
      },
      // Role column
      '& > tbody > tr > td:nth-child(4)': {
        width: 120,
      },
      // Status column
      '& > tbody > tr > td:nth-child(5)': {
        width: 130,
      },
      '& > tbody > tr > td:last-child': {
        width: 65,
      },
      '& > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },
    tableRow: {
      '&.MuiTableRow-hover:hover': {
        cursor: 'pointer',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    selectAll: {
      '& > .checkedIcon:before': {
        backgroundColor: 'red',
      },
    },
    roleChip: {
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    roleChipMSOAdmin: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    roleChipAdmin: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    roleChipUser: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,
    },
    iconWraper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 44,
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: theme.spacing(1),
    },
    iconStyleMenu: {
      height: 24,
      width: 24,
    },

    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    menu: {
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
        marginTop: 0,
        // height: 122,
        width: 200,
      },
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
        // height: 112,
        padding: theme.spacing(0.5, 1),
        position: 'relative',
      },

      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after': {
        content: '""',
        position: 'absolute',
        bottom: 43,
        left: 0,
        display: 'block',
        width: 200,
        height: 1,
        background: theme.palette.grey[300],
      },

      '& li[role="menuitem"]': {
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 35,
        borderRadius: 8,
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 4,
      },

      '& li[role="menuitem"]:first-child': {
        marginTop: 8,
      },
      '& li[role="menuitem"]:last-child': {
        marginBottom: 8,
      },
    },
    menuItem: {
      // marginTop: theme.spacing(1, 2),
    },
  })
);

interface PanelsListTableViewProps {
  panels: any;
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  headCells: HeadCell[];
  tableType: string;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
}

export const PanelsListTableView = (props: PanelsListTableViewProps) => {
  const { setIsOpenToast, toastMessage, setToastMessage } = React.useContext(
    GlobalContext
  );
  const [isSorting, setIsSorting] = React.useState(false);

  const classes = useStyles();
  const stickyTableClasses = useStickyTableStyle();
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [archivePanel, setArchivePanel] = React.useState<any>(null);

  const [
    showManageCoordinatorDialog,
    setShowManageCoordinatorDialog,
  ] = React.useState(false);

  React.useEffect(() => {
    setFilteredRows(props.panels);

    setIsSorting(false);
  }, [props.panels]);

  const handleArchivePanel = () => {
    setArchivePanel(null);
    setIsOpenToast(true);
    setToastMessage({
      isOpen: true,
      severity: 'success',
      snackbarMsg: 'Panel is Archived.',
    });
    setIsOpenToast(true);

    // setToastMessage({
    //   isOpen: true,
    //   severity: 'error',
    //   snackbarMsg: 'Fail to Archive Panel. Please try again.'
    // })
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [checkedPanelId, setCheckedPanelId] = React.useState('');

  const [isUpdated, setIsUpdated] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean | undefined>(true);

  React.useEffect(() => {
    if (loading === false) {
      setIsOpenToast(true);
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            ...props.refetchDataFunc.params,
          },
        });
      }
    }
  }, [loading, toastMessage]);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}

          {/* users list table */}
          <TableContainer
            classes={{ root: stickyTableClasses.customTableContainer }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              {/* table header */}
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                //   rowCount={filteredRows.length}
                headCells={props.headCells}
                tableType={props.tableType}
              />
              {/* tables rows */}
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * props.limit, page * props.limit + props.limit)
                    .map((row: any, index: number) => {
                      return (
                        <EnhancedTableRow
                          key={row.id}
                          row={row}
                          tableType={props.tableType}
                          setCheckedPanelId={setCheckedPanelId}
                          setManageAssignCoordinatorDialog={
                            setShowManageCoordinatorDialog
                          }
                          selectedArchivePanel={archivePanel}
                          setSelectedArchivePanel={setArchivePanel}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>

      {props.tableType === RoleType.MSO_ADMIN && showManageCoordinatorDialog && (
        <ManageCoordinatorDialog
          panelSummary={{
            isSkipped: false,
            setIsUpdated: () => {},
            panelId: checkedPanelId,
            assignedCoordinators: [],
            unassignedPatientCount: 0,
            coordinatorOptions: [],
          }}
          setShowManageCoordinatorDialog={setShowManageCoordinatorDialog}
          loading={loading}
          setLoading={setLoading}
          source="PanelsListTable"
        />
      )}
      {archivePanel && (
        <ArchiveDialogBox
          showDialog={true}
          selectedPanel={archivePanel}
          setSelectedPanel={setArchivePanel}
          handleArchivePanel={handleArchivePanel}
        />
      )}
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { limit, skip, count } = props;

  const classes = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
