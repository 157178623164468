import React, { useContext } from 'react';
import { PanelsHome } from '../PanelsHome';
import { GlobalContext } from '../../../components/GlobalContext';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../components/CheckIsAdmin';
import { CCPanelsHomeView } from '../PanelsHome/CCView';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { usePanelPatientCount } from './hooks/usePanelPatientCount';
import { useAllPatientCountCc } from './hooks/useAllPatientCountCc';

export const PanelsListLayoutBuilder = () => {
  const { loggedInUser } = useContext(GlobalContext);

  const {
    fetching: fetchingAll,
    numOfPanels: numOfAllPanels,
    setNumOfPanels: setNumOfAllPanels,
    numOfPatients: numOfAllPatients,
    setNumOfPatients: setNumOfAllPatients,
  } = usePanelPatientCount('');
  const {
    fetching: fetchingMy,
    numOfPanels: numOfMyPanels,
    setNumOfPanels: setNumOfMyPanels,
    numOfPatients: numOfMyPatients,
    setNumOfPatients: setNumOfMyPatients,
  } = usePanelPatientCount('YES');
  const {
    fetching: fetchingALCc,
    numOfPatients: numOfAllPatientsCc,
    numOfPanels: numOfMyPanelsCc,
    setNumOfPanels: setNumOfMyPanelsCc,
  } = useAllPatientCountCc();

  if (fetchingAll || fetchingMy || fetchingALCc) {
    return <LoadingSpinner />;
  }

  if (CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)) {

    return (
      <PanelsHome
        name={'Patient Panels'}
        selectedTab={0}
        numOfMyPanels={numOfMyPanels}
        numOfMyPatients={numOfMyPatients}
        numOfAll={numOfAllPanels}
        numOfPatients={numOfAllPatients}
        setNumOfMyPatients={setNumOfMyPatients}
        setNumOfMyPanels={setNumOfMyPanels}
        setNumOfAllPatients={setNumOfAllPatients}
        setNumOfAllPanels={setNumOfAllPanels}
      />
    );
  } else {

    return (
      <CCPanelsHomeView
        name={'My Patient Panels'}
        selectedTab={0}
        numOfMyPanels={numOfMyPanelsCc}
        numOfMyPatients={numOfMyPatients}
        numOfPatients={numOfAllPatientsCc}
        setNumOfPanels={setNumOfMyPanelsCc}
        setNumOfMyPatients={setNumOfMyPatients}
      />
    );
  }
};
