import React from 'react';
import { UpdateUserInput } from '../../../../Users/MsoAdmin/EditUsers/Container';
import {
  GetRolesByAccountIdQuery,
  useGetRolesByAccountIdQuery,
  useUpdateUserMutation,
} from '../../../../Users/MsoAdmin/EditUsers/index.generated';
import { EditProfileFormView } from './View';


export const EditProfileFormContainer = () => {
  const [editMyProfileMutation] = useUpdateUserMutation();
  const roles = useGetRolesByAccountIdQuery({ variables: {} });

  const getRoles = (queryResult: GetRolesByAccountIdQuery | undefined) => {
    return queryResult?.getRolesByAccountId;
  };

  const roleOptions = React.useMemo(() => getRoles(roles.data), [roles.data]);

  async function editMyProfile({
    userId,
    roleId,
    firstName,
    lastName,
    phone,
  }: UpdateUserInput) {
    await editMyProfileMutation({
      variables: {
        input: {
          id: userId,
          role: roleId,
          lastName: lastName,
          firstName: firstName,
          phone: phone,
        },
      },
    });
  }

  return (
    <EditProfileFormView
      editMyProfile={editMyProfile}
      roleOptions={roleOptions || []}
    />
  );
};
