import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetTeamMembersBySelectedPatientsQueryVariables = Types.Exact<{
  input: Types.GetTeamMembersBySelectedPatientsRequest;
}>;


export type GetTeamMembersBySelectedPatientsQuery = (
  { __typename: 'Query' }
  & { getTeamMembersBySelectedPatients: (
    { __typename: 'GetTeamMembersBySelectedPatientsResponse' }
    & { results: Array<(
      { __typename: 'CareTeamMember' }
      & Pick<Types.CareTeamMember, 'count'>
      & { user: (
        { __typename: 'User' }
        & Pick<Types.User, 'id' | 'firstName' | 'lastName' | 'roleName'>
      ) }
    )> }
  ) }
);

export type ReassignPatientsMutationVariables = Types.Exact<{
  input: Types.ReassignPatientsInput;
}>;


export type ReassignPatientsMutation = (
  { __typename: 'Mutation' }
  & { reassignPatients: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);


export const GetTeamMembersBySelectedPatientsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTeamMembersBySelectedPatients"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetTeamMembersBySelectedPatientsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTeamMembersBySelectedPatients"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"roleName"}}]}}]}}]}}]}}]};

/**
 * __useGetTeamMembersBySelectedPatientsQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersBySelectedPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersBySelectedPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersBySelectedPatientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTeamMembersBySelectedPatientsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamMembersBySelectedPatientsQuery, GetTeamMembersBySelectedPatientsQueryVariables>) {
        return Apollo.useQuery<GetTeamMembersBySelectedPatientsQuery, GetTeamMembersBySelectedPatientsQueryVariables>(GetTeamMembersBySelectedPatientsDocument, baseOptions);
      }
export function useGetTeamMembersBySelectedPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersBySelectedPatientsQuery, GetTeamMembersBySelectedPatientsQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMembersBySelectedPatientsQuery, GetTeamMembersBySelectedPatientsQueryVariables>(GetTeamMembersBySelectedPatientsDocument, baseOptions);
        }
export type GetTeamMembersBySelectedPatientsQueryHookResult = ReturnType<typeof useGetTeamMembersBySelectedPatientsQuery>;
export type GetTeamMembersBySelectedPatientsLazyQueryHookResult = ReturnType<typeof useGetTeamMembersBySelectedPatientsLazyQuery>;
export type GetTeamMembersBySelectedPatientsQueryResult = Apollo.QueryResult<GetTeamMembersBySelectedPatientsQuery, GetTeamMembersBySelectedPatientsQueryVariables>;
export function refetchGetTeamMembersBySelectedPatientsQuery(variables?: GetTeamMembersBySelectedPatientsQueryVariables) {
      return { query: GetTeamMembersBySelectedPatientsDocument, variables: variables }
    }
export const ReassignPatientsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"reassignPatients"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReassignPatientsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reassignPatients"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type ReassignPatientsMutationFn = Apollo.MutationFunction<ReassignPatientsMutation, ReassignPatientsMutationVariables>;

/**
 * __useReassignPatientsMutation__
 *
 * To run a mutation, you first call `useReassignPatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignPatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignPatientsMutation, { data, loading, error }] = useReassignPatientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReassignPatientsMutation(baseOptions?: Apollo.MutationHookOptions<ReassignPatientsMutation, ReassignPatientsMutationVariables>) {
        return Apollo.useMutation<ReassignPatientsMutation, ReassignPatientsMutationVariables>(ReassignPatientsDocument, baseOptions);
      }
export type ReassignPatientsMutationHookResult = ReturnType<typeof useReassignPatientsMutation>;
export type ReassignPatientsMutationResult = Apollo.MutationResult<ReassignPatientsMutation>;
export type ReassignPatientsMutationOptions = Apollo.BaseMutationOptions<ReassignPatientsMutation, ReassignPatientsMutationVariables>;