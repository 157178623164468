import { Theme, lighten } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useProviderPatientTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    pcp: {
      borderBottom: '2px solid rgba(67, 67, 67, 0.3)',
    },
    openGap: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },

      '&:hover': { background: 'rgba(183, 55, 62, 0.12)' },
      '&:focus': {
        background: theme.palette.error.light,
      },
    },
    closedGap: {
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,
      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&:hover': { background: theme.palette.grey[200] },
      '&:focus': {
        background: theme.palette.grey[100],
      },
    },
    warningIcon: {
      color: '#B7373E',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    closedDrawer: {
      display: 'none',
      transition: 'all 1s',
    },
    openedDrawer: {
      display: 'block',
      transition: 'all 3s',
    },
    root: {
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(1),
    },
    rounded: {
      borderRadius: 0,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    tableToolBarContainer: {
      backgroundColor: theme.palette.grey[50],
      width: '100%',
      border: 'none',
    },
    header: {
      height: 56,
      borderRadius: '0px',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      position: 'relative',
      borderBottom: '1px solid #D1D1D1',
    },
    title: {
      display: 'inline-block',
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.4,
      height: 24,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(3),
    },
    chip: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0, 0.5),
      height: 20,
      background: theme.palette.grey[100],

      '& .MuiChip-label': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        fontWeight: 'bold',
        fontSize: 12,
        color: theme.palette.grey[500],
      },
    },
    eligibilityTabsContainer: {
      display: 'inline-block',
      margin: theme.spacing(1.5, 3, 1.5, 'auto'),
      height: 32,
      borderRadius: 6,
      border: '1px solid rgba(0, 0, 0, 0.12)',

      '& .MuiTabs-root': {
        borderBottom: 'none',
      },

      '& .MuiTabs-flexContainer': {
        height: 32,
      },

      '& .MuiTab-root': {
        fontSize: 14,
        minHeight: 'fit-content',
        lineHeight: 'normal',
        textTransform: 'none',
      },

      '& button.MuiButtonBase-root': {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },

      '& .MuiTab-labelIcon': {
        paddingTop: theme.spacing(0.75),
      },

      '& span.MuiTab-wrapper': {
        flexDirection: 'row',
      },

      '& span.MuiTab-wrapper > *:first-child': {
        marginRight: theme.spacing(0.5),
        marginBottom: 0,
      },
    },
    headerButton: {
      position: 'absolute',
      right: 24,
    },
    searchBanner: {
      height: 56,
      display: 'flex',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',

      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2, 0, 0),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.grey[400],
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      fontSize: 16,
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
    filtersBtn: {
      height: 'fit-content',
      paddingTop: 0,
      paddingBottom: 0,
      border: 'none',
      background: 'inherit',
      display: 'flex',
      margin: theme.spacing(1.5, 2, 1.5, 'auto'),
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        background: 'inherit',
      },
    },
    filtersIcon: {
      color: theme.palette.grey[500],
      marginRight: theme.spacing(0.5),
      fontSize: 14,
    },
    filtersText: {
      fontSize: 14,
    },
    updatedTime: {
      padding: theme.spacing(0, 0, 2.25, 3),
    },
    tabIconsNormal: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.grey[500],
    },
    tabIconsActive: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.primary.main,
    },
    tabTextActive: {
      fontWeight: theme.typography.fontWeightBold,
      color: '#000',
    },
    tabTextNormal: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[500],
    },
    lightBackground: {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
    table: {
      minWidth: 750,
      border: 'none',

      '& .MuiTableHead-root': {
        // boxShadow: 'none',
        background: theme.palette.grey[50],
        // borderTop: `1px solid ${theme.palette.grey[300]}`,
      },

      '& > thead > tr > th': {
        padding: 0,
        paddingLeft: theme.spacing(3),
        height: 40,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
      },

      '& > tbody > tr': {
        height: 56,
        cursor: 'pointer',
      },

      '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },

      '& > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        paddingTop: theme.spacing(1.875),
        paddingBottom: theme.spacing(1.875),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        lineHeight: '24px',
        height: 24,
      },

      '& > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },
    tableCellName: {
      maxWidth: 239,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    selectAll: {
      '& > .checkedIcon:before': {
        backgroundColor: 'red',
      },
    },
    commercialLine: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,

      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    otherLines: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,

      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    scheduledChips: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,

      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,
      borderRadius: 6,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    processingChips: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,
      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,
      borderRadius: 6,
      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    newPatientsChips: {
      background: theme.palette.warning.light,
      color: '#C05314',
      cursor: 'pointer',
      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,
      borderRadius: 6,
      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    completeChips: {
      background: theme.palette.secondary.light,
      color: theme.palette.text.hint,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    exportIcon: {
      width: 20,
      height: 20,
    },
    fileSaveAsIcon: {
      width: 24,
      height: 24,
    },
    processingIcon: {
      color: '#0C77D8',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    scheduledIcon: {
      color: '#008479',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    newPatientsIcon: {
      color: '#C05314',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    iconWraper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 44,
    },
    icon: {
      height: 20,
      width: 20,
    },
    fileIcon: {
      width: 24,
      height: 24,
      // marginBottom: '-3px',
      color: theme.palette.grey[300],
      '&:hover': {
        color: theme.palette.grey[500],
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
        {
          order: -1,
          marginRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightBold,
        },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    uploadFileButton: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: 8,
      width: 145,
      height: 32,
      textAlign: 'center',
      padding: '0',
      fontSize: 14,
      letterSpacing: 0,

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    exportPatientListButton: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: 8,
      width: 162,
      height: 32,
      textAlign: 'center',
      padding: '0',
      fontSize: 14,
      letterSpacing: 0,

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    newPatientText: {
      '&:hover': {
        borderBottom: `2px solid rgba(12, 119, 216, 0.5)`,
        color: `${theme.palette.primary.dark}`,
        cursor: 'pointer',
      },
      width: 'fit-content',
      borderBottom: `2px solid rgba(12, 119, 216, 0.3)`,
      color: `${theme.palette.primary.main}`,
      fontWeight: 600,
      fontSize: 12,
      cursor: 'pointer',
    },
    control: {
      padding: theme.spacing(2),
    },

    li: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      listStyle: 'none',
    },

    tableTitleContainer: {
      backgroundColor: theme.palette.secondary.light,
      width: '100%',
      height: '56px',
      borderRadius: '0px',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      display: 'flex',
      alignItems: 'center',
    },

    tableTitle: {
      fontSize: '20px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
      textAlign: 'left',
      height: '24px',
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(3),
    },

    tableHeader: {
      backgroundColor: `${theme.palette.grey[50]}`,
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '16px',
      width: '100%',
      height: '40px',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    tableHeaderCell: {
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      lineHeight: '16px',
    },
    tableBodyRow: {
      width: '100%',
      height: '56px',
      borderRadius: '0px',
      backgroundColor: theme.palette.primary.contrastText,
    },

    tableBodyRowCell: {
      height: '56px',
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '24px',
    },

    insuranceText: {
      display: 'block',
      color: theme.palette.primary.main,
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '0px',
      lineHeight: '20px',
      marginLeft: theme.spacing(1.125),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),

      height: '20px',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '6px',
      textAlign: 'center',
    },

    tableContainer: {
      border: 0,
      borderRadius: '0px',
      padding: 0,
    },
    moreHoriz: {
      color: theme.palette.grey[500],
    },
  })
);
