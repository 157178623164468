import React from 'react';
import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { SubMainRegion } from '../../../../../../components/SubMainRegion';
import { AllPatientHeaderContainer } from '../../../../PanelsHome/components/AllPatient/components/AllPatientHeader';
import { DeListPatientsTabContainerProps } from './Container';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { DeListedPatientsTable } from './components/DeListedPatientsTable';
import { useMoveDeListedPatients } from '../../hooks/useMoveDeListedPatients';
import { PanelSummaryContext } from '../../../PanelSummary/components/PanelSummaryContext/PanelSummaryContext';

export type SearchTextAndFiltersType = {
  andName: string;
  andDob: string;
};

interface DeListPatientsTabViewProps extends DeListPatientsTabContainerProps {}

export const DeListPatientsTabView: React.FC<DeListPatientsTabViewProps> = ({
  limit,
  skip,
  setLimit,
  setSkip,
  panelId,
  title,
  availableActions,
  selected,
  setSelected,
  actionsFooter,
  setActionsFooter,
  allSelected,
  setAllSelected,
  excluded,
  setExcluded,
  hardFresh,
}) => {
  const classes = useStyles();

  const [enableTable, setEnableTable] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchTextAndFilters, setSearchTextAndFilters] = React.useState<
    SearchTextAndFiltersType | undefined
  >(undefined);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (skip !== undefined) {
      setSkip(0);
    }
  }, [enableTable, limit, searchTextAndFilters]);

  const [
    enableChangeCoordinatorDialog,
    setEnableChangeCoordinatorDialog,
  ] = React.useState(false);

  const { polling, assignCoordinator, setPolling } = useMoveDeListedPatients(
    panelId,
    allSelected,
    excluded,
    actionsFooter,
    // hardFresh,
    setEnableChangeCoordinatorDialog,
    setLoading,
    searchTextAndFilters
  );

  const { setNeedToRefetch } = React.useContext(PanelSummaryContext);

  React.useEffect(() => {
    if (polling === false) {
      // hardFresh();
      // background refetch the panel data as patients have been moved to assigned patient list
      setNeedToRefetch(true);
      setSelected([]);
      setPolling(undefined);
    }
  }, [polling]);

  return (
    <div id="DeListPatientsTabView" className={classes.root}>
      <SubMainRegion
        footer={actionsFooter.length > 0}
        header={
          <>
            <div className={classes.lightBackground}>
              <AllPatientHeaderContainer
                openFilter={false}
                setOpenFilter={() => {}}
                setIsSearching={setIsSearching}
                setEnableTable={setEnableTable}
                isSearching={isSearching}
                setSearchTextAndFilters={setSearchTextAndFilters}
                hideFilterButton={true}
              />
            </div>
            <Paper
              className={`${classes.control} ${classes.tableTitleContainer}`}
            >
              <Typography variant="h4" className={classes.tableTitle}>
                {title}
              </Typography>

              {/* 
                <div className={classes.tableTitleRowButtons}>
                  <ExportMenu
                    rows={resultRows}
                    panelId={props.panelId}
                    title={'Export'}
                  />
                </div> 
                */}
            </Paper>
          </>
        }
      >
        {polling ? (
          <LoadingSpinner />
        ) : (
          <DeListedPatientsTable
            limit={limit}
            skip={skip}
            setLimit={setLimit}
            setSkip={setSkip}
            panelId={panelId}
            isSearching={isSearching}
            availableActions={availableActions}
            selected={selected}
            setSelected={setSelected}
            actionsFooter={actionsFooter}
            setActionsFooter={setActionsFooter}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            excluded={excluded}
            setExcluded={setExcluded}
            hardFresh={hardFresh}
            enableTable={enableTable}
            setSearchTextAndFilters={setSearchTextAndFilters}
            assignCoordinator={assignCoordinator}
            enableChangeCoordinatorDialog={enableChangeCoordinatorDialog}
            setEnableChangeCoordinatorDialog={setEnableChangeCoordinatorDialog}
          />
        )}
      </SubMainRegion>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  lightBackground: {
    backgroundColor: `${theme.palette.grey[50]}`,
  },
  control: {
    padding: theme.spacing(2),
  },
  tableTitleContainer: {
    position: 'relative',
    backgroundColor: theme.palette.grey['50'],
    width: '100%',
    height: '56px',
    borderRadius: '0px',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tableTitle: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));
