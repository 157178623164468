import React from 'react';
import { NameValue, QmFilterOptions } from '../app.types.generated';

export const useLanguageFilter = (
  openCareGapOptions: QmFilterOptions | null | undefined
) => {
  const [languages, setLanguages] = React.useState<NameValue[]>([]);

  React.useEffect(() => {
    if (openCareGapOptions) {
      const tmp: NameValue[] = [] as NameValue[];
      openCareGapOptions.Languages?.forEach((item) => {
        tmp.push(item);
      });

      setLanguages(tmp);
    }
  }, [openCareGapOptions?.Languages]);

  return { languages };
};
