import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 452,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      paddingTop: 20,
      paddingBottom: 11,
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
  },

  editBtn: {
    width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 219,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '8px 24px',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
  },
  subLabel: {
    fontSize: 16,
    color: '#656565',
    fontWeight: 500,
    paddingTop: theme.spacing(0.5),
    lineHeight: '24px',
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    height: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholderTextSelected: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface DialogProps {
  onClose: any;
}

export const SendSmsDialog = (props: DialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {
        props.onClose(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'User Account Deactivated'}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography className={classes.subLabel}>
            Your user account has been deactivated by admin.
            <br />
            Contact the organization admin for more information.
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.createNowBtn}
            autoFocus
          >
            Acknowledged & Logout
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
