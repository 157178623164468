import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetEligibilitySmsBatchItemsQueryVariables = Types.Exact<{
  input: Types.EligibilityGetSmsBatchItemsRequest;
}>;


export type GetEligibilitySmsBatchItemsQuery = (
  { __typename: 'Query' }
  & { getEligibilitySmsBatchItems: (
    { __typename: 'EligibilityGetSmsBatchItemsGraphQLResponse' }
    & Pick<Types.EligibilityGetSmsBatchItemsGraphQlResponse, 'failCount' | 'messageTemplateId' | 'messageTemplateName' | 'sentBy'>
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'EligibilityPagingInfo' }
      & Pick<Types.EligibilityPagingInfo, 'limit' | 'skip' | 'count'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'EligibilitySmsItemInfo' }
      & Pick<Types.EligibilitySmsItemInfo, 'dob' | 'gender' | 'id' | 'lastUpdate' | 'patientName' | 'pcp' | 'sendStatus'>
    )>> }
  ) }
);

export type GetEligibilitySmsBatchesQueryVariables = Types.Exact<{
  input: Types.EligibilityGetSmsBatchesRequest;
}>;


export type GetEligibilitySmsBatchesQuery = (
  { __typename: 'Query' }
  & { getEligibilitySmsBatches: (
    { __typename: 'EligibilityGetSmsBatchesGraphQLResponse' }
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'EligibilityPagingInfo' }
      & Pick<Types.EligibilityPagingInfo, 'limit' | 'skip' | 'count'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'EligibilitySmsBatchesInfo' }
      & Pick<Types.EligibilitySmsBatchesInfo, 'batchId' | 'category' | 'failureCount' | 'messageTemplateId' | 'messageTemplateName' | 'sendStatus' | 'sendStatusDesc' | 'sentBy' | 'sentCount' | 'sentDate' | 'updateTime'>
    )>> }
  ) }
);


export const GetEligibilitySmsBatchItemsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilitySmsBatchItems"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilityGetSmsBatchItemsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilitySmsBatchItems"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}},{"kind":"Field","name":{"kind":"Name","value":"failCount"}},{"kind":"Field","name":{"kind":"Name","value":"messageTemplateId"}},{"kind":"Field","name":{"kind":"Name","value":"messageTemplateName"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dob"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastUpdate"}},{"kind":"Field","name":{"kind":"Name","value":"patientName"}},{"kind":"Field","name":{"kind":"Name","value":"pcp"}},{"kind":"Field","name":{"kind":"Name","value":"sendStatus"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sentBy"}}]}}]}}]};

/**
 * __useGetEligibilitySmsBatchItemsQuery__
 *
 * To run a query within a React component, call `useGetEligibilitySmsBatchItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilitySmsBatchItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilitySmsBatchItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEligibilitySmsBatchItemsQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilitySmsBatchItemsQuery, GetEligibilitySmsBatchItemsQueryVariables>) {
        return Apollo.useQuery<GetEligibilitySmsBatchItemsQuery, GetEligibilitySmsBatchItemsQueryVariables>(GetEligibilitySmsBatchItemsDocument, baseOptions);
      }
export function useGetEligibilitySmsBatchItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilitySmsBatchItemsQuery, GetEligibilitySmsBatchItemsQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilitySmsBatchItemsQuery, GetEligibilitySmsBatchItemsQueryVariables>(GetEligibilitySmsBatchItemsDocument, baseOptions);
        }
export type GetEligibilitySmsBatchItemsQueryHookResult = ReturnType<typeof useGetEligibilitySmsBatchItemsQuery>;
export type GetEligibilitySmsBatchItemsLazyQueryHookResult = ReturnType<typeof useGetEligibilitySmsBatchItemsLazyQuery>;
export type GetEligibilitySmsBatchItemsQueryResult = Apollo.QueryResult<GetEligibilitySmsBatchItemsQuery, GetEligibilitySmsBatchItemsQueryVariables>;
export function refetchGetEligibilitySmsBatchItemsQuery(variables?: GetEligibilitySmsBatchItemsQueryVariables) {
      return { query: GetEligibilitySmsBatchItemsDocument, variables: variables }
    }
export const GetEligibilitySmsBatchesDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilitySmsBatches"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilityGetSmsBatchesRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilitySmsBatches"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"batchId"}},{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"failureCount"}},{"kind":"Field","name":{"kind":"Name","value":"messageTemplateId"}},{"kind":"Field","name":{"kind":"Name","value":"messageTemplateName"}},{"kind":"Field","name":{"kind":"Name","value":"sendStatus"}},{"kind":"Field","name":{"kind":"Name","value":"sendStatusDesc"}},{"kind":"Field","name":{"kind":"Name","value":"sentBy"}},{"kind":"Field","name":{"kind":"Name","value":"sentCount"}},{"kind":"Field","name":{"kind":"Name","value":"sentDate"}},{"kind":"Field","name":{"kind":"Name","value":"updateTime"}}]}}]}}]}}]};

/**
 * __useGetEligibilitySmsBatchesQuery__
 *
 * To run a query within a React component, call `useGetEligibilitySmsBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilitySmsBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilitySmsBatchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEligibilitySmsBatchesQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilitySmsBatchesQuery, GetEligibilitySmsBatchesQueryVariables>) {
        return Apollo.useQuery<GetEligibilitySmsBatchesQuery, GetEligibilitySmsBatchesQueryVariables>(GetEligibilitySmsBatchesDocument, baseOptions);
      }
export function useGetEligibilitySmsBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilitySmsBatchesQuery, GetEligibilitySmsBatchesQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilitySmsBatchesQuery, GetEligibilitySmsBatchesQueryVariables>(GetEligibilitySmsBatchesDocument, baseOptions);
        }
export type GetEligibilitySmsBatchesQueryHookResult = ReturnType<typeof useGetEligibilitySmsBatchesQuery>;
export type GetEligibilitySmsBatchesLazyQueryHookResult = ReturnType<typeof useGetEligibilitySmsBatchesLazyQuery>;
export type GetEligibilitySmsBatchesQueryResult = Apollo.QueryResult<GetEligibilitySmsBatchesQuery, GetEligibilitySmsBatchesQueryVariables>;
export function refetchGetEligibilitySmsBatchesQuery(variables?: GetEligibilitySmsBatchesQueryVariables) {
      return { query: GetEligibilitySmsBatchesDocument, variables: variables }
    }