import React from 'react';
import { useCopyLabelStyles } from './Styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CopyButton } from '../CopyButton';

interface CopyLabelProps {
  label: string;
  customClasses?: ClassNameMap<'label' | 'icon' | 'root'>;
}

export const LabelWithCopy = (props: CopyLabelProps) => {
  const { label, customClasses } = props;
  const classes = useCopyLabelStyles();

  const copyText = () => {
    // patient id: 11111111, ecw id: 3333333
    if (label) {
      return label.split(':')[1].trim();
    }
    return '';
  };
  return (
    <div className={customClasses?.root || classes.root}>
      <div className={customClasses?.label || classes.label}>
        <span id="label">{label}</span>
      </div>
      <div className={customClasses?.icon || classes.icon}>
        <CopyButton textToCopy={copyText()} />
      </div>
    </div>
  );
};
