import { Button, ButtonProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  smallSecondaryTextDefault: {
    backgroundColor: theme.palette.grey[200],
    height: 32,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    borderRadius: 8,
    cursor: 'pointer',
    color: '#656565',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  smallPrimaryTextDefault: {
    backgroundColor: theme.palette.primary.main,
    height: 32,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    borderRadius: 8,
    cursor: 'pointer',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
  },
  mediumPrimaryTextDefault: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    borderRadius: 8,
    cursor: 'pointer',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
  },
  mediumSecondaryIconPressed: {
    position: 'relative',
    backgroundColor: theme.palette.grey['50'],
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
  },
  mediumSecondaryTextDefault: {
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    height: 40,
    width: 85,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  mediumGhostIconDefault: {
    position: 'relative',
    backgroundColor: 'transparent',
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const ButtonMediumPrimaryText = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.mediumPrimaryTextDefault} {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonMediumSecondaryIcon = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.mediumSecondaryIconPressed} {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonMediumSecondaryText = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.mediumSecondaryTextDefault} {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonMediumGhostIcon = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.mediumGhostIconDefault} {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonSmallPrimaryText = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.smallPrimaryTextDefault} {...props}>
      {props.children}
    </Button>
  );
};
export const ButtonSmallSecondaryText = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.smallSecondaryTextDefault} {...props}>
      {props.children}
    </Button>
  );
};
