import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FilterState {
  [key: string]: any;
}

export interface PatientFiltersState {
  [componentId: string]: FilterState;
}

export const initialFilterState: FilterState = {
  gender: [],
  age: [],
  ldosFrom: null,
  ldosTo: null,
  openGaps: [],
  hasEngagement: '',
  hasFutureAppointment: '',
  npis: [],
  dueDateFrom: null,
  dueDateTo: null,
  coordinationStatus: [],
  divisionId: [],
  clinicId: [],
  userIds: [],
  languages: [],
  eligibilityStatuses: [],
  careGapStatus: [],
  lineOfBusiness: [],
  payerIds: [],
  callAttemptCount: [],
  newPatientOnly: false,
};

const patientFilter = createSlice({
  name: 'patientFilter',
  initialState: {} as PatientFiltersState,
  reducers: {
    initPatientFilter: (
      state,
      action: PayloadAction<{ componentId: string }>
    ) => {
      const { componentId } = action.payload;
      if (!state[componentId]) {
        // initialize filter state if it doesn't exist
        state[componentId] = { ...initialFilterState };
      }
    },
    resetPatientFilter: (
      state,
      action: PayloadAction<{ componentId: string }>
    ) => {
      const { componentId } = action.payload;
      state[componentId] = { ...initialFilterState };
    },
    onChange: (
      state,
      action: PayloadAction<{
        componentId: string;
        type: keyof FilterState;
        value: any;
      }>
    ) => {
      const { componentId, type, value } = action.payload;
      if (state[componentId]) {
        state[componentId][type] = value;
      }
    },
    applyPatientFilter: (
      state,
      action: PayloadAction<{ componentId: string; filters: FilterState }>
    ) => {
      const { componentId, filters } = action.payload;
      if (state[componentId]) {
        state[componentId] = filters;
      }
    },
  },
});

export const {
  onChange,
  initPatientFilter,
  resetPatientFilter,
  applyPatientFilter,
} = patientFilter.actions;
export default patientFilter.reducer;
