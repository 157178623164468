import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router';
import EligibilityTable from "./components/PatientTable/EligibilityPatientTable";
import {EligibilityCategory} from "../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import {PatientListTab} from "./components/PatientListTab";
import {Grid} from "@material-ui/core";
import {ReasonFilter} from "./components/ReasonFilter";
import {
  useGetEligibilityRejectedReasonCategoryListQuery,
  useGetEligibilitySummaryInPatientQuery
} from './index.generated';
import EngagementBatchesTable from "./components/PatientEngagement/EngagementBatchesTable";
import useEligibility from "./hooks/useEligibility";
import {SubMainRegion} from "../../../components/SubMainRegion";
import TableToolbar from "./components/TableHeaderToolbar/View";


interface EligibilitySummaryInfo {
  activeExternal: number
  activeInternal: number
  inactive: number
  nextScheduledDate: string
  rejected: number
  unavailable: number
  smsBatchCount: number
  updatedDate: string
}

export default function PatientListViewSubContainer () {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const [reasonFilter, setReasonFilter] = useState<Array<any>>([])
  const [displayCount, setDisplayCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState<EligibilitySummaryInfo>({
    activeExternal: 0,
    activeInternal: 0,
    inactive: 0,
    nextScheduledDate: '',
    rejected: 0,
    unavailable: 0,
    smsBatchCount: 0,
    updatedDate: '',
  })
  const [refresher, setRefresher] = useState(0)
  const {info} = useEligibility()
  useEffect(() => {
    setRefresher(info.refresher)
  }, [info.refresher])

  const getReasonCategoryQuery = useGetEligibilityRejectedReasonCategoryListQuery()
  let reasonsOption: Array<any> = []
  if (getReasonCategoryQuery.called &&
    getReasonCategoryQuery.data
  ) {
    reasonsOption = getReasonCategoryQuery!.data!.getEligibilityRejectedReasonCategoryList.results!.map((_item) => {
      let name = ""
      if (_item.rejectedReasonCategory) {
        const number = _item.rejectedReasonCategory.split(' ')[0]
        if (!!Number(number)) {
          const reason = _item.rejectedReasonCategory.replace(`${number} `, "")
          name = `Code${number} - ${reason}`
        } else {
          name = _item.rejectedReasonCategory
        }
      }
      return {
        name: name,
        value: _item.rejectedReasonCategory,
        __typename: _item.rejectedReasonCategory
      }
    })
  }

  const getEligibilitySummaryQuery = useGetEligibilitySummaryInPatientQuery()
  useEffect(() => {
    if (getEligibilitySummaryQuery.called && !getEligibilitySummaryQuery.loading) {
      const sm = getEligibilitySummaryQuery.data!.getEligibilitySummary!.summaryInfo
      setSummary(sm)
    }
  }, [getEligibilitySummaryQuery])

  useEffect(() => {
    getEligibilitySummaryQuery.refetch()
  }, [refresher])

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({skip, limit,}: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`);
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const type = useMemo(() => {
    if (params.type) {
      const _type = params.type.toString()
      let newType = _type as EligibilityCategory
      if (newType === EligibilityCategory.rejected) {
        newType = EligibilityCategory.unverified
      }
      if (Object.values(EligibilityCategory).includes(newType)) {
        return (newType)
      }
    }
    return (EligibilityCategory.internalActive)
  }, [params])

  return (
    <SubMainRegion
      header={<>
        <PatientListTab activeInternal={summary.activeInternal} activeExternal={summary.activeExternal}
         rejected={summary.rejected} inactive={summary.inactive} unavailable={summary.unavailable}
        selectedType={type} batchSmsCount={summary.smsBatchCount} onSelect={(_type) => {
          if (_type === EligibilityCategory.unverified) {
            _type = EligibilityCategory.rejected
          }
          navigate(`/eligibility/patients/${_type}`)
          setReasonFilter([])
        }}/>
        {type === EligibilityCategory.unverified ? (
          <ReasonFilter displayCount={displayCount} isLoading={loading} reasonsOption={reasonsOption} onChangeFilter={(reason) => {
            setReasonFilter(reason.includes('All') ? [] : reason)
          }}/>
        ) : null}
        {type !== EligibilityCategory.engagement ? (
          <TableToolbar category={type} patientCount={displayCount ?? 0} rejectedReasonList={reasonFilter && reasonFilter.length ? reasonFilter : undefined} />
        ) : null}
      </>}
      headerChange={type === EligibilityCategory.engagement}
    >
      <Grid container className={classes.content}>
      {type === EligibilityCategory.engagement ? <EngagementBatchesTable skip={skip ?? 0} limit={limit ?? 10} setSkip={onSetSkip} setLimit={onSetLimit}/> :
        <EligibilityTable onComplete={(totalCount, isLoading) => {
          setDisplayCount(totalCount)
          setLoading(isLoading)
        }} rejectedReasonCategoryList={type === EligibilityCategory.unverified ? reasonFilter : []} skip={skip ?? 0} limit={limit ?? 10} setSkip={onSetSkip} setLimit={onSetLimit} category={type} />}
      </Grid>
  </SubMainRegion>
);
};


const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    flexGrow: 1
  },
  updatedDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updatedDateTitle: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    marginRight: 8,
    fontWeight: 500
  },
  nextScheduleDateTitle: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#FFF',
    fontWeight: 600,
    margin: '8px 8px 12px 16px'
  },
  icon: {
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'text',
    },
  },
}));
