import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useCopyLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      width: 'fit-content',
      height: 24,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      boxSizing: 'border-box',
    },
    label: {
      '& span': {
        color: '#26ACE2',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    icon: {
      '& svg': {
        color: '#26ACE2',
        fontSize: 16,
      },
    },
  })
);
