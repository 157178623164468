import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../../components/CheckIsAdmin';
import { GlobalContext } from '../../../../components/GlobalContext';
import { TabBar } from '../../../../components/TabBar';
import { TabBarHeader } from '../../../../components/TabBarHeader';
import { RoutedPanelPatients } from '../PanelPatients/Routed';
import { SummaryView } from './components/SummaryView';
import { MainRegion } from '../../../../components/MainRegion';
import { ManageCoordinatorDialog } from '../../components/ManageCoordinatorDialog/Container';
import { useGetPayersOptionsQuery } from './index.generated';
import { PanelSummaryContext } from './components/PanelSummaryContext/PanelSummaryContext';

type Props = {
  panel: any;
  type?: number;
  hardRefresh: any;
  sourceInfo: {
    sidebarMenu: string;
    rootLink: string;
    rootName: string;
    childLink: string;
    childName: string;
  };
  tabObjects: {
    tabIndex: number;
    tabLabel: string;
    tabUrl: string;
    tabBadgeCount: number | undefined | null;
    disabled: boolean;
  }[];
  notEngagedCount: number;
  nofutureApptCount: number;
  unassignedPatientCount: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& div[role="tabBar"]': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
    },
  },
  header: {
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    fontSize: 32,
    height: 40,
    paddingTop: theme.spacing(2),
  },
  description: {
    fontSize: 16,
    height: 24,
    color: theme.palette.grey['400'],
    paddingTop: theme.spacing(2),
  },
  backButton: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  backTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
  },
  arrowIcon: {
    fontSize: 12,
    color: theme.palette.grey['300'],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  rightContainer: {
    position: 'absolute',
    top: 64,
    right: 24,
  },
  editButton: {
    position: 'relative',
    backgroundColor: theme.palette.grey['50'],
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: 8,
  },
  careTeamAvatarContainer: {
    position: 'relative',
    right: 110,
    top: 20,
  },
  moreIcon: {
    fontSize: 16,
  },
  navLink: {
    textUnderlineOffset: '4px',
    textDecorationThickness: 2,
    textDecorationColor: theme.palette.grey['700'],
  },
  navTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
    paddingLeft: theme.spacing(1),
    userSelect: 'none',
  },
}));

export const PanelSummaryView = (props: Props) => {
  const classes = useStyles(props);
  const { description, id, name, careTeamUsers, patientCount } = props.panel;

  const [value, setValue] = React.useState(props.type || 0);
  const [loading, setLoading] = React.useState<boolean | undefined>(true);

  const { loggedInUser, toastMessage, setIsOpenToast } = useContext(
    GlobalContext
  );

  const PayerOptions = useGetPayersOptionsQuery();

  const [
    showManageCoordinatorDialog,
    setShowManageCoordinatorDialog,
  ] = React.useState(false);

  const count = patientCount || 0;

  const switchTab = (event: any, newValue: number) => {
    setValue((value) => {
      value = newValue;
      return value;
    });
  };

  React.useEffect(() => {
    setValue(props.type ?? 0);
  }, [props.type]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOption = (event: any) => {
    setAnchorEl(null);
    if (event) setShowManageCoordinatorDialog(true);
  };

  const { setNeedToRefetch, setRefetchPatientTable } = React.useContext(
    PanelSummaryContext
  );

  React.useEffect(() => {
    if (loading === false) {
      // background refetch the panel data as patients have been moved to assigned patient list
      setNeedToRefetch(true);

      setIsOpenToast(true);

      setLoading(undefined);

      setRefetchPatientTable(true);
    }
  }, [loading]);

  return (
    <MainRegion
      header={
        <TabBarHeader
          name={name}
          description={description}
          careTeamUsers={careTeamUsers}
          showEditButton={
            CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)
          }
          sourceInfo={props.sourceInfo}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          openMenu={openMenu}
          handleClose={handleClose}
          handleOption={handleOption}
          handleSelectArchivePanel={() => {
            //
          }}
          assignedPatientCount={props.panel.assignedPatientCount || 0}
        />
      }
      overFlowHidden={true}
    >
      <div className={classes.root}>
        <TabBar
          sticky={true}
          activeTabIndex={value}
          switchTab={switchTab}
          tabObjects={props.tabObjects}
        >
          {value === 0 && (
            <SummaryView
              panel={props.panel}
              patientCount={count}
              payersOption={
                PayerOptions?.data?.getCareGapFilterOptions?.Payers || []
              }
            />
          )}
          {value === 1 && (
            <RoutedPanelPatients
              hardRefresh={props.hardRefresh}
              notEngagedCount={props.notEngagedCount}
              nofutureApptCount={props.nofutureApptCount}
              careTeamUsers={careTeamUsers}
              assignedPatientCount={props.panel.assignedPatientCount || 0}
            />
          )}
          {value === 2 && (
            <RoutedPanelPatients
              hardRefresh={props.hardRefresh}
              unassignedPatientCount={props.unassignedPatientCount}
              setShowManageCoordinatorDialog={setShowManageCoordinatorDialog}
              careTeamUsers={careTeamUsers}
            />
          )}
          {value === 3 && (
            // de list patient tab
            <RoutedPanelPatients
              hardRefresh={props.hardRefresh}
              careTeamUsers={careTeamUsers}
            />
          )}
          {value === 4 && (
            <RoutedPanelPatients
              hardRefresh={props.hardRefresh}
              careTeamUsers={careTeamUsers}
            />
          )}
        </TabBar>

        {(CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)) &&
          showManageCoordinatorDialog && (
            <ManageCoordinatorDialog
              panelSummary={{
                isSkipped: false,
                setIsUpdated: () => {},
                panelId: id,
                assignedCoordinators: [],
                unassignedPatientCount: 0,
                coordinatorOptions: [],
              }}
              setShowManageCoordinatorDialog={setShowManageCoordinatorDialog}
              loading={loading}
              setLoading={setLoading}
              source="PanelSummary"
            />
          )}
      </div>
    </MainRegion>
  );
};
