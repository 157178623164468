import React from 'react';
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { CheckIcon } from '../../../../assets/Icons/CreatePanel/CheckIcon';

const useStyles = makeStyles((theme: Theme) => ({
  numberCircle: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    fontSize: 14,
  },
  // reduce classes by using props in theme (master form state)
  numberCircleActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  numberCirclePassed: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  stepsRow: {
    display: 'flex',
    alignItems: 'center',
  },
  stepText: {
    fontSize: 16,
    paddingLeft: 16,
    fontWeight: 'bold',
    color: theme.palette.text.hint,
  },
  stepTextActive: {
    fontSize: 16,
    paddingLeft: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  stepsConnector: {
    width: 2,
    height: 32,
    backgroundColor: theme.palette.secondary.main,
    marginLeft: 16,
  },
}));

type Props = {
  step: number;
};

export const StepIndicator = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12} className={classes.stepsRow}>
        <div
          className={
            classes.numberCircle +
            ' ' +
            ((props.step === 1 && classes.numberCircleActive) ||
              (props.step > 1 && classes.numberCirclePassed))
          }
        >
          {props.step <= 1 ? (
            '1'
          ) : (
            <CheckIcon style={{ width: 15, height: 15 }} />
          )}
        </div>
        <Typography
          className={
            props.step === 1 ? classes.stepTextActive : classes.stepText
          }
        >
          Panel Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.stepsConnector} />
      </Grid>
      <Grid item xs={12} className={classes.stepsRow}>
        <div
          className={
            classes.numberCircle +
            ' ' +
            ((props.step === 2 && classes.numberCircleActive) ||
              (props.step > 2 && classes.numberCirclePassed))
          }
        >
          {props.step <= 2 ? (
            '2'
          ) : (
            <CheckIcon style={{ width: 15, height: 15 }} />
          )}
        </div>
        <Typography
          className={
            props.step === 2 ? classes.stepTextActive : classes.stepText
          }
        >
          Patient Filters
        </Typography>
      </Grid>
    </div>
  );
};
