import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';
import {
  useGetPatientCareGapInfoQuery,
  useGetPatientCareGapStatusChangeLogsQuery,
} from './index.generated';
import { CareGapsGroupView } from './View';
import React from 'react';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';

interface CareGapsGroupContainerProps {
  patientId: string;
  selectedYear: number;
  setSelectedYear: any;
  hardRefresh: () => void;
  patientInfo: PatientFaceSheet;
  setOpenCareGapCount: any;
}

export const CareGapsGroupContainer = (props: CareGapsGroupContainerProps) => {
  const { patientId, hardRefresh } = props;
  const [filterCareGap, setFilterCareGap] = React.useState<any[]>([]);
  const patientCareGaps = useGetPatientCareGapInfoQuery({
    variables: {
      mpatientId: `${patientId}`,
    },
  });
  const careGapsChangeLog = useGetPatientCareGapStatusChangeLogsQuery({
    variables: {
      input: {
        mpatientId: `${patientId}`,
      },
    },
  });

  React.useEffect(() => {
    // console.log(props.selectedYear);
    if (
      patientCareGaps.data?.searchPatientCareGapsInfo &&
      careGapsChangeLog.data?.getPatientCareGapStatusChangeLogs
    ) {
      const careGaps = patientCareGaps.data?.searchPatientCareGapsInfo;
      const careGapsChangeLogs =
        careGapsChangeLog.data?.getPatientCareGapStatusChangeLogs.logs;
      careGaps?.forEach((eachCareGap: any) => {
        if (
          careGaps.filter(
            (careGap) => careGap.measureName == eachCareGap.measureName
          ).length <= 1
        ) {
          eachCareGap.nullEventDate = null;
        } else {
          eachCareGap.nullEventDate = eachCareGap.eventDate;
        }
        const careGapChangeLog = careGapsChangeLogs.filter(
          (careGapChangeLog: any) =>
            careGapChangeLog.measureStatus == 'Open' &&
            careGapChangeLog.measure == eachCareGap.measureCode
        );
        const sum = careGapChangeLog.reduce(
          (a: any, b: any) => a + b.callAttempt,
          0
        );
        eachCareGap.callAttempt = sum;
        eachCareGap.notes = (careGapChangeLog as any)
          ? careGapChangeLog[0]?.notes || ''
          : '';
      });
      setFilterCareGap(careGaps);
    }
  }, [
    JSON.stringify(patientCareGaps?.data?.searchPatientCareGapsInfo),
    JSON.stringify(careGapsChangeLog?.data?.getPatientCareGapStatusChangeLogs),
  ]);

  if (
    !patientCareGaps.called ||
    patientCareGaps.loading ||
    !careGapsChangeLog.called ||
    careGapsChangeLog.loading
  ) {
    return <LoadingSpinner style={{ height: 'calc(100vh - 245px)' }} />;
  }

  return (
    <CareGapsGroupView
      hardRefresh={hardRefresh}
      careGaps={filterCareGap || []}
      mpid={patientId ?? ''}
      refetchDataFunc={{
        func: patientCareGaps.refetch,
        params: { mpatientId: `${patientId}` },
      }}
      refetchDataFunc2={{
        func: careGapsChangeLog.refetch,
        params: {
          mpatientId: `${patientId}`,
        },
      }}
      selectedYear={props.selectedYear}
      setSelectedYear={props.setSelectedYear}
      panelId={
        (props.patientInfo?.panels?.length ?? 0 > 0
          ? props.patientInfo!.panels![0]!.panelId
          : '') ?? ''
      }
     setOpenCareGapCount={props.setOpenCareGapCount}
    />
  );
};
