import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../components/GlobalContext';
import Auth from '@aws-amplify/auth';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import {MixPanelEvents} from "../../mixpanel/events";

type PropTypes = {
  isLoading: boolean;
  user?: User | null;
};

export function View(props: PropTypes) {
  const { isLoading, user } = props;

  if (isLoading || !user) {
    return <LoadingSpinner style={{ width: '100%' }} />;
  }

  return (
    <div>
      <div>{user!.firstName}</div>
      <div>{user!.lastName}</div>

      <button onClick={() => { MixPanelEvents.reset(); Auth.signOut(); }}>Logout</button>

      <div style={{ marginTop: '50px' }}>
        <Link to={`/home`}>Back to home page</Link>
      </div>
    </div>
  );
}
