import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const ExportIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M11.2727 1L7.63636 4.63636H10.3636V12.8182H12.1818V4.63636H14.9091L11.2727 1ZM16.7273 21H5.81818C4.80909 21 4 20.1818 4 19.1818V8.27273C4 7.79052 4.19156 7.32805 4.53253 6.98708C4.87351 6.6461 5.33597 6.45455 5.81818 6.45455H8.54545V8.27273H5.81818V19.1818H16.7273V8.27273H14V6.45455H16.7273C17.2095 6.45455 17.6719 6.6461 18.0129 6.98708C18.3539 7.32805 18.5455 7.79052 18.5455 8.27273V19.1818C18.5455 19.664 18.3539 20.1265 18.0129 20.4675C17.6719 20.8084 17.2095 21 16.7273 21Z"
    />
  );
};
