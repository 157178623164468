import React from 'react';
import {
  Dialog,
  makeStyles,
  styled,
  DialogContent,
  Typography,
  Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { AccountOptionType, User } from '../../../../../enum/Users';
import { GlobalContext } from '../../../../../components/GlobalContext';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    // width: '100%',
    padding: theme.spacing(0, 2.5),
    marginBottom: theme.spacing(2.5),
  },
  cancelBtn: {
    width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 157,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  smallPrimaryBtn: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '83px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '& .MuiButton-label .MuiSvgIcon-root': {
      fontSize: '14px',
      padding: theme.spacing(0),
      marginLeft: theme.spacing(-1),
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
    },
  },

  dialogTitle: {
    margin: theme.spacing(0),
    padding: theme.spacing(2.5, 3),
    height: 'fit-content',
    lineHeight: '32px',

    '& > h2': {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0,
    },
  },

  dialogContentRegion: {
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3.5),
    },

    '& .MuiTypography-root': {
      height: '24px',
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: '24px',
      fontSize: '16px',
    },

    '& .MuiTypography-root span': {
      display: 'block',
      height: '40px',
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '40px',
      marginBottom: theme.spacing(2.25),
    },

    '& img': {
      height: '62px',
      width: '96px',
      marginTop: theme.spacing(10),
    },

    '& a': {
      display: 'block',
      width: '140px',
      height: '48px',
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      letterSpacing: 0,
      lineHeight: '48px',
      background: theme.palette.primary.main,
      borderRadius: '5px',
      marginTop: theme.spacing(3.9375),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 'fit-content',
    height: 'fit-content',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: 8,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0, 3, 4.5, 3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle {...other} className={classes.dialogTitle}>
      {children}
    </MuiDialogTitle>
  );
};

interface ToggleAccountStatusDialogViewProps {
  optionCode: AccountOptionType;
  isOpenDialog: boolean;
  toBeUpdatedUsers: User[];
  disableUsers: (input: User[]) => Promise<void>;
  enableUsers: (input: User[]) => Promise<void>;
  setIsOpenDialog: (input: boolean) => void;
}

export const ToggleAccountStatusDialogView = (
  props: ToggleAccountStatusDialogViewProps
) => {
  const { loggedInUser } = React.useContext(GlobalContext);
  const classes = useStyles();

  let dialogTitle: string = '';
  let dialogContent: string = '';
  let confirmBtnText: string = '';
  let cancelBtnText: string = '';

  // to trigger different operation
  switch (props.optionCode) {
    case AccountOptionType.DEACTIVATE:
      //   deactivate
      // for supporting multi-selection
      if (props.toBeUpdatedUsers.length === 1) {
        // one user => show details
        const user = props.toBeUpdatedUsers[0];
        dialogTitle = `Deactivate ${user.firstName + ' ' + user.lastName}?`;
        dialogContent = `Are you sure you want to DEACTIVATE user ${
          user.firstName + ' ' + user.lastName
        } (${user.email})?`;
        confirmBtnText = `Yes, Deactivate`;
        cancelBtnText = 'Cancel';
      } else if (props.toBeUpdatedUsers.length >= 2) {
        if (
          props.toBeUpdatedUsers.find(
            (user) => user.userId === loggedInUser?.id
          ) !== undefined
        ) {
          // having myself
          dialogTitle = `You can't deactivate yourself!`;
          dialogContent = `Please uncheck your account from the list.`;
          confirmBtnText = ``;
          cancelBtnText = 'OK';
        } else {
          dialogTitle = `Deactivate ${props.toBeUpdatedUsers.length} users?`;
          dialogContent = `Are you sure you want to DEACTIVATE ${props.toBeUpdatedUsers.length} users?`;
          confirmBtnText = `Yes, Deactivate`;
          cancelBtnText = 'Cancel';
        }
      }
      break;
    case AccountOptionType.ACTIVATE:
      // activate
      if (props.toBeUpdatedUsers.length === 1) {
        // one user => show details
        const user = props.toBeUpdatedUsers[0];
        dialogTitle = `Activate ${user.firstName + ' ' + user.lastName}?`;
        dialogContent = `Are you sure you want to ACTIVATE user ${
          user.firstName + ' ' + user.lastName
        } (${user.email})?`;
        confirmBtnText = `Yes, Activate`;
        cancelBtnText = 'Cancel';
      } else if (props.toBeUpdatedUsers.length >= 2) {
        dialogTitle = `Activate ${props.toBeUpdatedUsers.length} users?`;
        dialogContent = `Are you sure you want to ACTIVATE ${props.toBeUpdatedUsers.length} users?`;
        confirmBtnText = `Yes, Activate`;
        cancelBtnText = 'Cancel';
      }
      break;
  }

  const handleClose = (event?: any) => {
    switch (event.target.innerText) {
      case 'Yes, Deactivate':
        // call disable api
        props.disableUsers(props.toBeUpdatedUsers);
        break;
      case 'Yes, Activate':
        // call enable api
        props.enableUsers(props.toBeUpdatedUsers);
        break;
    }

    props.setIsOpenDialog(false);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpenDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {dialogTitle}
        </BootstrapDialogTitle>

        <div>
          <DialogContent>
            <Typography
              style={{ width: '100%', fontSize: 16, wordWrap: 'break-word' }}
            >
              {dialogContent}
            </Typography>
          </DialogContent>
          <div className={classes.buttonRow}>
            <Button
              autoFocus
              onClick={handleClose}
              className={classes.cancelBtn}
            >
              {cancelBtnText}
            </Button>
            {confirmBtnText && (
              <Button
                onClick={handleClose}
                className={classes.createNowBtn}
                autoFocus
              >
                {confirmBtnText}
              </Button>
            )}
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};
