import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type BeginExportPatientByCategoryQueryVariables = Types.Exact<{
  input: Types.EligibilityPatientListExportGraphQlRequest;
}>;


export type BeginExportPatientByCategoryQuery = (
  { __typename: 'Query' }
  & { beginExportPatientByCategory: (
    { __typename: 'EligibilityBeginExportPatientByCategoryResponse' }
    & Pick<Types.EligibilityBeginExportPatientByCategoryResponse, 'taskId'>
  ) }
);

export type GetExportTaskStatusQueryVariables = Types.Exact<{
  input: Types.TaskStatusQueryInput;
}>;


export type GetExportTaskStatusQuery = (
  { __typename: 'Query' }
  & { getExportTaskStatus: (
    { __typename: 'ExportTaskStatusResponse' }
    & Pick<Types.ExportTaskStatusResponse, 'taskId' | 'url'>
  ) }
);


export const BeginExportPatientByCategoryDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BeginExportPatientByCategory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilityPatientListExportGraphQLRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"beginExportPatientByCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};

/**
 * __useBeginExportPatientByCategoryQuery__
 *
 * To run a query within a React component, call `useBeginExportPatientByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeginExportPatientByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeginExportPatientByCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBeginExportPatientByCategoryQuery(baseOptions: Apollo.QueryHookOptions<BeginExportPatientByCategoryQuery, BeginExportPatientByCategoryQueryVariables>) {
        return Apollo.useQuery<BeginExportPatientByCategoryQuery, BeginExportPatientByCategoryQueryVariables>(BeginExportPatientByCategoryDocument, baseOptions);
      }
export function useBeginExportPatientByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeginExportPatientByCategoryQuery, BeginExportPatientByCategoryQueryVariables>) {
          return Apollo.useLazyQuery<BeginExportPatientByCategoryQuery, BeginExportPatientByCategoryQueryVariables>(BeginExportPatientByCategoryDocument, baseOptions);
        }
export type BeginExportPatientByCategoryQueryHookResult = ReturnType<typeof useBeginExportPatientByCategoryQuery>;
export type BeginExportPatientByCategoryLazyQueryHookResult = ReturnType<typeof useBeginExportPatientByCategoryLazyQuery>;
export type BeginExportPatientByCategoryQueryResult = Apollo.QueryResult<BeginExportPatientByCategoryQuery, BeginExportPatientByCategoryQueryVariables>;
export function refetchBeginExportPatientByCategoryQuery(variables?: BeginExportPatientByCategoryQueryVariables) {
      return { query: BeginExportPatientByCategoryDocument, variables: variables }
    }
export const GetExportTaskStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getExportTaskStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TaskStatusQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getExportTaskStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"taskId"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]};

/**
 * __useGetExportTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetExportTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportTaskStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExportTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>) {
        return Apollo.useQuery<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>(GetExportTaskStatusDocument, baseOptions);
      }
export function useGetExportTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>(GetExportTaskStatusDocument, baseOptions);
        }
export type GetExportTaskStatusQueryHookResult = ReturnType<typeof useGetExportTaskStatusQuery>;
export type GetExportTaskStatusLazyQueryHookResult = ReturnType<typeof useGetExportTaskStatusLazyQuery>;
export type GetExportTaskStatusQueryResult = Apollo.QueryResult<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>;
export function refetchGetExportTaskStatusQuery(variables?: GetExportTaskStatusQueryVariables) {
      return { query: GetExportTaskStatusDocument, variables: variables }
    }