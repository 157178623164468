import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { EditNodesForm, MAX_TEXT_LENGTH } from '../components/EditNotes';

export type OverLimitType = {
  isOverLimit: boolean;
  overLimitMsg: string;
};

interface useOverLimitProps {
  control: Control<EditNodesForm>;
}

export const useOverLimit = (props: useOverLimitProps) => {
  const { control } = props;

  const [overLimit, setOverLimit] = React.useState({} as OverLimitType);

  const textLength: string = useWatch({ control, name: 'notes' }) || '';
  React.useEffect(() => {
    if (textLength && textLength.length > MAX_TEXT_LENGTH) {
      setOverLimit({
        isOverLimit: true,
        overLimitMsg: `The notes cannot exceed ${MAX_TEXT_LENGTH} characters.`,
      });
    } else {
      setOverLimit({} as OverLimitType);
    }
  }, [textLength]);

  return {
    textLength,
    overLimit,
  };
};
