import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from '../../../../../../../assets/Icons/Eligibility/SearchIcon';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '40px',
    color: '#434343',
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    textAlign: 'center',
    paddingTop: 10,
  },
  icon: {
    color: '#C05314',
    width: 50,
    height: 50,
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 11,
    paddingTop: 125,
  },
}));

interface CareGapPatientNotFoundProps {
  isSearching: boolean;
  noMatchTextForSearching?: string;
}

export const CareGapPatientNotFound = (props: CareGapPatientNotFoundProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <SearchIcon className={classes.icon} />
      </div>

      <div className={classes.title}>No Match Records</div>
      {props.isSearching ? (
        <div className={classes.subTitle}>
          {props.noMatchTextForSearching ? (
            props.noMatchTextForSearching
          ) : (
            <>
              We are not able to find matching records, you can search by
              patient First name and Last name,
              <br />
              recommended DOB format: mm/dd/yyyy, subscribe ID needs to be exact
              matched.
            </>
          )}
        </div>
      ) : (
        <div className={classes.subTitle}>
          We are not able to find matching records
        </div>
      )}
    </div>
  );
};
