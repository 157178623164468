import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useMyProfileTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.dark,

      '& .MuiButton-label': {},
    },
    root: {
      flexGrow: 1,
    },
    mainGrid: {
      margin: theme.spacing(0),
      width: '100%',
      height: '100%',
    },
    headerMainRow: {
      height: 88,
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2.335, 3),

      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontSize: '32px',
        fontWeight: 'bold',
        height: '40px',
        letterSpacing: '0',
        lineHeight: '40px',
        margin: '24px',
        display: 'inline-block',
        textTransform: 'capitalize',
      },

      '& #last-update-container': {
        marginLeft: 'auto',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'row',
      },

      '& #creation-button': {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        lineHeight: '24px',
        background: theme.palette.primary.main,
        borderRadius: 8,
        width: 125,
        height: 40,
        textAlign: 'center',
        padding: '0',

        '&:hover': {
          backgroundColor: '#1b8cf3',
        },
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0',
        textTransform: 'capitalize',
        marginLeft: '3.33px',
      },
    },
    card: {
      width: '100%',
      display: 'flex',
      borderRadius: 8,
    },
    cardHeader: {
      height: 72,
      paddingLeft: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    cardContent: {
      width: '100%',
      padding: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
    cardMidSection: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      width: '100%',
      padding: theme.spacing(3),
    },
    cardFooter: {
      height: 72,
      padding: theme.spacing(2, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(1),
    },
    subLabel: {
      fontSize: 12,
      color: theme.palette.text.hint,
      fontWeight: 600,
      paddingTop: theme.spacing(0.5),
    },
    nameInput: {
      height: 48,
      fontSize: 16,
      width: '100%',
      borderRadius: 8,
      '&.MuiInputBase-root.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[400],
      },
    },
    nextButton: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    radioLabel: {
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.secondary,
        fontSize: 16,
        paddingLeft: theme.spacing(1),
      },
    },
    deleteIcon: {
      color: theme.palette.grey[500],
      fontSize: 24,
    },
    modifierTitle: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
    },
    modifierSpacing: {
      height: theme.spacing(4),
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    modifierDetails: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
  })
);
