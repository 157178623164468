import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useEditProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    mainGrid: {
      margin: theme.spacing(0),
      width: '100%',
    },
    headerMainRow: {
      height: 88,
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2.335, 3),

      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontSize: '32px',
        fontWeight: 'bold',
        height: '40px',
        letterSpacing: '0',
        lineHeight: '40px',
        margin: '24px',
        display: 'inline-block',
        textTransform: 'capitalize',
      },

      '& #last-update-container': {
        marginLeft: 'auto',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'row',
      },

      '& #creation-button': {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        lineHeight: '24px',
        background: theme.palette.primary.main,
        borderRadius: 8,
        width: 125,
        height: 40,
        textAlign: 'center',
        padding: '0',

        '&:hover': {
          backgroundColor: '#1b8cf3',
        },
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0',
        textTransform: 'capitalize',
        marginLeft: '3.33px',
      },
    },
    card: {
      width: '100%',
      display: 'flex',
      borderRadius: 8,
    },
    cardHeader: {
      height: 72,
      paddingLeft: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    cardContent: {
      width: '100%',
      padding: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
    cardMidSection: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      width: '100%',
      padding: theme.spacing(3),
    },
    cardFooter: {
      height: 72,
      padding: theme.spacing(2, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(1),
    },
    subLabel: {
      fontSize: 12,
      color: theme.palette.text.hint,
      fontWeight: 600,
      paddingTop: theme.spacing(0.5),
    },
    subLabelError: {
      fontSize: 12,
      fontWeight: 600,
      paddingTop: theme.spacing(0.5),
      color: theme.palette.error.main,
    },
    nameInput: {
      height: 48,
      fontSize: 16,
      width: '100%',
      borderRadius: 8,
      '&.MuiInputBase-root.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[400],
      },
    },
    cancelButton: {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.dark,
    },
    nextButton: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },

      '&.MuiButton-contained.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: theme.palette.primary.main,
      },
    },
    radioLabel: {
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.secondary,
        fontSize: 16,
        paddingLeft: theme.spacing(1),
      },
    },
    deleteIcon: {
      color: theme.palette.grey[500],
      fontSize: 24,
    },
    modifierTitle: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
    },
    modifierSpacing: {
      height: theme.spacing(4),
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    modifierDetails: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
    selectMenuContent: {
      display: 'block',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    multipleSelect: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'top',
      width: '100%',
    },
    formControl: {
      width: '100%',
      '& .MuiInputBase-root': {
        height: 48,
        borderRadius: 6,
        boxShadow:
          '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      },
    },
    inputTitle: {
      height: 24,
      color: theme.palette.grey[700],
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.7,
      marginBottom: theme.spacing(1),
    },
    inputHelper: {
      height: 20,
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.67,
      marginTop: theme.spacing(0.5),
    },
    placeholderText: {
      color: theme.palette.grey[400],
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.5,
      paddingTop: theme.spacing(0.875),
      paddingBottom: theme.spacing(1),
    },
    helperText: {
      color: theme.palette.grey['500'],
    },
    // insurance select styles
    insuranceSelect: {
      width: '100%',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        border: 'none',
        backgroundColor: theme.palette.grey[100],
        paddingTop: 0,
        paddingBottom: 0,
        '& div': {
          color: theme.palette.grey[400],
        },
        '& fieldset': {
          border: 'none',
        },
      },
    },
    selected: {
      backgroundColor: '#EDF6FF!important',
      color: '#0C77D8!important',
    },
    insurancePlaceholderTextSelected: {
      color: theme.palette.grey[600],
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.5,
      paddingTop: theme.spacing(0.875),
      paddingBottom: theme.spacing(1),
    },
    insuranceMenuItem: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    insuranceMenuItemText: {
      height: 24,
      color: theme.palette.grey[600],
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.71,
    },
    insuranceList: {
      marginTop: theme.spacing(2),
      width: '100%',
      height: 'auto',
      backgroundColor: theme.palette.background.paper,
      background: theme.palette.primary.contrastText,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 8,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),

      '& .MuiListItem-root': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      '& li .MuiMenuItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    insuranceListItem: {
      borderBottom: '1px solid #d1d1d1',
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      '& .MuiIconButton-root': {
        padding: theme.spacing(0),
      },
    },
    insuranceListItemText: {
      height: 24,
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.5,
    },
    insuranceListitemHelper: {
      height: 16,
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.33,
    },
  })
);
