import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientCountByUserIdQueryVariables = Types.Exact<{
  input: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetPatientCountByUserIdQuery = (
  { __typename: 'Query' }
  & { getPatientByCareTeamUserId: (
    { __typename: 'GetPatientByCareTeamUserIdResponse' }
    & Pick<Types.GetPatientByCareTeamUserIdResponse, 'count'>
  ) }
);

export type GetPanelsListQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.GetPanelsInput>;
}>;


export type GetPanelsListQuery = (
  { __typename: 'Query' }
  & { getPanels: (
    { __typename: 'PanelLIstResponse' }
    & Pick<Types.PanelLIstResponse, 'count'>
  ) }
);

export type GetPatientCountByUserIdCcQueryVariables = Types.Exact<{
  input: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetPatientCountByUserIdCcQuery = (
  { __typename: 'Query' }
  & { getAllPatients: (
    { __typename: 'GetPatientByCareTeamUserIdResponse' }
    & Pick<Types.GetPatientByCareTeamUserIdResponse, 'count'>
  ) }
);


export const GetPatientCountByUserIdDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCountByUserId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientByCareTeamUserId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPatientCountByUserIdQuery__
 *
 * To run a query within a React component, call `useGetPatientCountByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCountByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCountByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientCountByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>) {
        return Apollo.useQuery<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>(GetPatientCountByUserIdDocument, baseOptions);
      }
export function useGetPatientCountByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>(GetPatientCountByUserIdDocument, baseOptions);
        }
export type GetPatientCountByUserIdQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdQuery>;
export type GetPatientCountByUserIdLazyQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdLazyQuery>;
export type GetPatientCountByUserIdQueryResult = Apollo.QueryResult<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>;
export function refetchGetPatientCountByUserIdQuery(variables?: GetPatientCountByUserIdQueryVariables) {
      return { query: GetPatientCountByUserIdDocument, variables: variables }
    }
export const GetPanelsListDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPanelsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPanelsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPanels"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPanelsListQuery__
 *
 * To run a query within a React component, call `useGetPanelsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPanelsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanelsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPanelsListQuery(baseOptions?: Apollo.QueryHookOptions<GetPanelsListQuery, GetPanelsListQueryVariables>) {
        return Apollo.useQuery<GetPanelsListQuery, GetPanelsListQueryVariables>(GetPanelsListDocument, baseOptions);
      }
export function useGetPanelsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPanelsListQuery, GetPanelsListQueryVariables>) {
          return Apollo.useLazyQuery<GetPanelsListQuery, GetPanelsListQueryVariables>(GetPanelsListDocument, baseOptions);
        }
export type GetPanelsListQueryHookResult = ReturnType<typeof useGetPanelsListQuery>;
export type GetPanelsListLazyQueryHookResult = ReturnType<typeof useGetPanelsListLazyQuery>;
export type GetPanelsListQueryResult = Apollo.QueryResult<GetPanelsListQuery, GetPanelsListQueryVariables>;
export function refetchGetPanelsListQuery(variables?: GetPanelsListQueryVariables) {
      return { query: GetPanelsListDocument, variables: variables }
    }
export const GetPatientCountByUserIdCcDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCountByUserIdCc"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllPatients"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPatientCountByUserIdCcQuery__
 *
 * To run a query within a React component, call `useGetPatientCountByUserIdCcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCountByUserIdCcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCountByUserIdCcQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientCountByUserIdCcQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCountByUserIdCcQuery, GetPatientCountByUserIdCcQueryVariables>) {
        return Apollo.useQuery<GetPatientCountByUserIdCcQuery, GetPatientCountByUserIdCcQueryVariables>(GetPatientCountByUserIdCcDocument, baseOptions);
      }
export function useGetPatientCountByUserIdCcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCountByUserIdCcQuery, GetPatientCountByUserIdCcQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCountByUserIdCcQuery, GetPatientCountByUserIdCcQueryVariables>(GetPatientCountByUserIdCcDocument, baseOptions);
        }
export type GetPatientCountByUserIdCcQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdCcQuery>;
export type GetPatientCountByUserIdCcLazyQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdCcLazyQuery>;
export type GetPatientCountByUserIdCcQueryResult = Apollo.QueryResult<GetPatientCountByUserIdCcQuery, GetPatientCountByUserIdCcQueryVariables>;
export function refetchGetPatientCountByUserIdCcQuery(variables?: GetPatientCountByUserIdCcQueryVariables) {
      return { query: GetPatientCountByUserIdCcDocument, variables: variables }
    }