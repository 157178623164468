export type ColorType = {
  color: string;
  backgroundColor: string;
};

const STATUS_COLOR = {
  'Unavailable Eligibility': {
    today: { color: '#707070', backgroundColor: '#EFEFEF' },
    moreThan1Day: { color: '#A0A0A0', backgroundColor: '#F8F8F8' },
  },
  'Inactive Eligibility': {
    today: { color: '#E7434C', backgroundColor: '#FFEAEB' },
    moreThan1Day: { color: '#D99789', backgroundColor: '#F8F8F8' },
  },
  'Unverified Eligibility': {
    today: { color: '#3B89C9', backgroundColor: '#EDF6FF' },
    moreThan1Day: { color: '#85A2BA', backgroundColor: '#F8F8F8' },
  },
  'Active (External) Eligibility': {
    today: {
      color: '#49BFA9',
      backgroundColor: '#F0FDFF',
    },
    moreThan1Day: { color: '#49BFA9', backgroundColor: '#F8F8F8' },
  },
  'Active (Internal) Eligibility': {
    today: {
      color: '#519548',
      backgroundColor: '#E8F6F2',
    },
    moreThan1Day: { color: '#889986', backgroundColor: '#F8F8F8' },
  },
};

export const getEligibilityBadgeChipColor = (
  rawStatus: string,
  updateTimeExt: string = 'today'
) => {
  switch (rawStatus) {
    case 'Active (Internal) Eligibility':
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Active (Internal) Eligibility'].today;
      else return STATUS_COLOR['Active (Internal) Eligibility'].moreThan1Day;
    case 'Active (External) Eligibility':
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Active (External) Eligibility'].today;
      else return STATUS_COLOR['Active (External) Eligibility'].moreThan1Day;
    case 'Unverified Eligibility':
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Unverified Eligibility'].today;
      else return STATUS_COLOR['Unverified Eligibility'].moreThan1Day;
    case 'Inactive Eligibility':
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Inactive Eligibility'].today;
      else return STATUS_COLOR['Inactive Eligibility'].moreThan1Day;
    case 'Unavailable Eligibility':
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Unavailable Eligibility'].today;
      else return STATUS_COLOR['Unavailable Eligibility'].moreThan1Day;
    default:
      if (updateTimeExt.toLocaleLowerCase() === 'today')
        return STATUS_COLOR['Unavailable Eligibility'].today;
      else return STATUS_COLOR['Unavailable Eligibility'].moreThan1Day;
  }
};

export const getStatusText = (rawStatus: string) => {
  // 'Active (External) Eligibility' => 'Active External'
  // 'Unavailable Eligibility' => 'Unavailable'
  if (!rawStatus) return '';
  return rawStatus.replace(' Eligibility', '').replace(/[()]/g, '');
};
