import React from 'react';
import { MixPanelEvents } from '../mixpanel/events';
import { PatientFaceSheet } from '../scenes/CareGaps/Home/tabs/Population/components/CareGapsDrawer/Container';

export type EligibilityCheckStatusType = {
  checkStatus: boolean;
  counter: number;
};
export const useEligibilityCheckStatus = (
  called: boolean,
  loading: boolean,
  data: any,
  hardRefresh: () => void,
  patientInfo: PatientFaceSheet
) => {
  const [eligibilityCheckStatus, setEligibilityCheckStatus] = React.useState<
    EligibilityCheckStatusType | undefined
  >(undefined);
  const [pcp, setPcp] = React.useState('No Data');

  React.useEffect(() => {
    if (called) {
      if (!loading && data) {
        if (
          !data.getPatientEligibilityRealTime ||
          !data.getPatientEligibilityRealTime.isSuccess
        ) {
          // failed
          setEligibilityCheckStatus({
            checkStatus: false,
            counter: Number(eligibilityCheckStatus?.counter) + 1,
          });
        } else {
          if (data.getPatientEligibilityRealTime.isSuccess) {
            // success
            let tmpPCP = data?.getPatientEligibilityRealTime?.pcpExternal?.trim();
            setPcp(tmpPCP || 'No Data');
            // stop no matter currentRetryTime
            setEligibilityCheckStatus({
              checkStatus: true,
              counter: 0,
            });
            MixPanelEvents.track(MixPanelEvents.userActivityAndJourneyMapping.checkEligibilityProcessTime.name, {
              patientID: patientInfo.id,
              counter: Number(eligibilityCheckStatus?.counter)
            }); // track success
            // Track Eligibility Check if it is the first time
            if (Number(eligibilityCheckStatus?.counter) === 0) {
              MixPanelEvents.track(MixPanelEvents.userActivityAndJourneyMapping.firstEligibilityCheckSuccess.name, {
                patientID: patientInfo.id,
              });
            }
            hardRefresh();
          }
        }
      } else {
        if (!loading && !data) {
          // failed
          setEligibilityCheckStatus({
            checkStatus: false,
            counter: Number(eligibilityCheckStatus?.counter) + 1,
          });
        }
      }
    }
  }, [data, loading, called]);

  return { eligibilityCheckStatus, pcp };
};
