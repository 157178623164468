import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const ReportIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M17.4444 16H3V3H4.44444V14.5556H5.88889V8.05556H8.77778V14.5556H10.2222V5.16667H13.1111V14.5556H14.5556V10.9444H17.4444V16Z"
  />
);
