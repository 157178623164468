import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  snippet: {
    width: 64,
    height: '100%',
  },
  stage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@keyframes dotFlashing': {
    '0%': {
      backgroundColor: theme.palette.primary.main,
    },
    '50%': {},
    '100%': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  flashingDots: {
    position: 'relative',
    width: 8,
    height: 8,
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    animation: `$dotFlashing 1s infinite linear alternate`,
    animationDelay: '.5s',

    '&::before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: '-15px',
      width: 8,
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      animation: '$dotFlashing 1s infinite alternate',
      animationDelay: '0s',
    },

    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: '15px',
      width: 8,
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      animation: '$dotFlashing 1s infinite alternate',
      animationDelay: '1s',
    },
  },
}));

export const FlashingDot = () => {
  const classes = useStyles();

  return (
    <div className={classes.snippet}>
      <div className={classes.stage}>
        <div className={classes.flashingDots}></div>
      </div>
    </div>
  );
};
