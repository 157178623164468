import * as Types from '../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SetUserCredMutationVariables = Types.Exact<{
  input: Types.SetUserCredInput;
}>;


export type SetUserCredMutation = (
  { __typename: 'Mutation' }
  & { setUserCred: (
    { __typename: 'SetUserCredResponse' }
    & Pick<Types.SetUserCredResponse, 'failReason' | 'isSuccess'>
  ) }
);


export const SetUserCredDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setUserCred"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetUserCredInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setUserCred"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userCred"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"failReason"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type SetUserCredMutationFn = Apollo.MutationFunction<SetUserCredMutation, SetUserCredMutationVariables>;

/**
 * __useSetUserCredMutation__
 *
 * To run a mutation, you first call `useSetUserCredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserCredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserCredMutation, { data, loading, error }] = useSetUserCredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserCredMutation(baseOptions?: Apollo.MutationHookOptions<SetUserCredMutation, SetUserCredMutationVariables>) {
        return Apollo.useMutation<SetUserCredMutation, SetUserCredMutationVariables>(SetUserCredDocument, baseOptions);
      }
export type SetUserCredMutationHookResult = ReturnType<typeof useSetUserCredMutation>;
export type SetUserCredMutationResult = Apollo.MutationResult<SetUserCredMutation>;
export type SetUserCredMutationOptions = Apollo.BaseMutationOptions<SetUserCredMutation, SetUserCredMutationVariables>;