import React from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { useFetchTableData } from '../../../../hooks/useFetchTableData';
import { PayerFileListingTableView } from './PayerFileListingTable';
import { useGetPayerFilesQuery } from './PayerList/index.generated';
import {FileSearchBar} from "../components/FileSearchBar/FileSearchBar";

type ItemListTableContainerProps = {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
};
export const PayerFileListingView = (props: ItemListTableContainerProps) => {
  const [sortBy, setSortBy] = React.useState({
    field: 'processedTime',
    method: 'desc' as const,
  });
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchText, setSearchText] = React.useState('')
  const resp = useGetPayerFilesQuery({
    variables: {
      request: {
        fileName: searchText,
        skip: props.skip ?? 0,
        limit: props.limit ?? 10,
        sortBy: [{ ...sortBy }],
      },
    },
  });
  React.useEffect(()=>{
    props.setSkip(0);
    resp.refetch({
      request: {
        fileName: searchText,
        skip: props.skip ?? 0,
        limit: props.limit ?? 10,
        sortBy: [{ ...sortBy }],
      },
    });
  },[isSearching]);

  const queryResult = useFetchTableData(resp);

  if (!resp.called || resp.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
  }

  return (
    <div style={{ width: '100%' }}>
      <FileSearchBar isSearching={isSearching} setIsSearching={setIsSearching} searchText={searchText} setSearchText={setSearchText}/>
      <PayerFileListingTableView
        refetchDataFunc={{
          func: resp.refetch,
          params: {
            fileName: searchText,
            skip: props.skip ?? 0,
            limit: props.limit ?? 10,
            sortBy: [{ ...sortBy }],
          },
        }}
        sortBy={{ ...sortBy }}
        setSortBy={setSortBy}
        results={queryResult ? queryResult.getPayerFiles?.results : []}
        count={queryResult ? queryResult.getPayerFiles?.count : 0}
        limit={props.limit ?? 10}
        skip={props.skip ?? 0}
        setLimit={props.setLimit}
        setSkip={props.setSkip}
      />
    </div>
  );
};
