import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import { useCoordinationLogStyles } from './Styles';
import { Avatar, Chip, Typography } from '@material-ui/core';
import { ProfileItem } from '../../../../../../../components/ProfileItem';
import {
  CallIcon,
  NotesIcon,
  SnoozedIcon,
  QMIcon,
} from '../../../../../../../assets/Icons/CareGaps';
import { CoordinationLogSelect } from './components/CoordinationLogSelect';
import { NorthIcon } from '../../../../../../../assets/Icons/CareGaps/NorthIcon';
import { dateFormatPipe } from '../../../../../../../sharePipe/dateFormatPipe';
import { LogTypeEnum } from './Container';
import { CoordinationLog } from '../../../../../../../app.types.generated';
import { convertLogType2LogTypeEnum } from '../../../../../../../sharePipe/convertLogType2LogTypeEnum';
import { EmptyPlaceHolder } from '../../../../../../../components/EmptyPlaceHolder';
import { MeIcon } from '../../../../../../../assets/Icons/PanelsList/MeIcon';

interface CoordinationLogItemListProps {
  classes: ClassNameMap;
  coordinationLogData: any;
  selectedSectionName: string;
}

const CoordinationLogItemList = (props: CoordinationLogItemListProps) => {
  const { classes, coordinationLogData, selectedSectionName } = props;

  const getLogByForStatusChange = (item: CoordinationLog) => {
    if (!item.logBy) {
      return <EmptyPlaceHolder text="No Provider" />;
    } else {
      if (item.logBy.fullName?.toLowerCase() === 'system') {
        return (
          <Typography className={classes.operatorText}>{'System'}</Typography>
        );
      } else {
        return (
          <ProfileItem
            firstName={item.logBy.firstName}
            lastName={item.logBy.lastName}
          />
        );
      }
    }
  };

  const getItemIcon = (sectionName: string) => {
    switch (convertLogType2LogTypeEnum(sectionName)) {
      case LogTypeEnum.Notes:
        return <NotesIcon />;
      case LogTypeEnum.StatusChange:
        return <NorthIcon style={{ rotate: '90deg' }} />;
      case LogTypeEnum.CoordinationActions:
        return <CallIcon />;
      // case LogTypeEnum.BatchSMS:
      //   return <SMSIcon />;
      case LogTypeEnum.PatientReassigned:
        return <MeIcon />;
      default:
        return <QMIcon />;
    }
  };

  const getOperator = (item: CoordinationLog) => {
    switch (convertLogType2LogTypeEnum(item.logType || '')) {
      case LogTypeEnum.StatusChange:
        return <>{getLogByForStatusChange(item)}</>;
      default:
        return (
          <>
            {item.logBy ? (
              <ProfileItem
                firstName={item.logBy.firstName}
                lastName={item.logBy.lastName}
              />
            ) : (
              <EmptyPlaceHolder text="No Provider" />
            )}
          </>
        );
    }
  };

  const getChangedToStatusClassName = (statusText: string) => {
    if (!statusText) return classes.otherLabel;
    else if (statusText.toLowerCase().includes('snoozed')) {
      return classes.snoozedLabel;
    } else if (
      statusText.toLowerCase().includes('closed reason') ||
      statusText.toLowerCase().includes('outreach closed') ||
      statusText.toLowerCase().includes('deceased')
    ) {
      return classes.greyStatusOtherLabel;
    } else {
      return classes.otherLabel;
    }
  };
  const hardCodeStatusText = (text: string) => {
    if (text == 'Closed Reason: Patient Deceased') {
      return 'Deceased';
    } else if (text.includes('Closed Reason: ')) {
      return text.replace('Closed Reason: ', 'Outreach Closed (') + ')';
    }
    return text;
  };

  const getContent = (item: CoordinationLog) => {
    switch (convertLogType2LogTypeEnum(item.logType || '')) {
      case LogTypeEnum.StatusChange:
        return (
          <div className={classes.statusChangeFooterContainer}>
            <div className={classes.statusChangeFooterChangeToContainer}>
              <Chip
                icon={
                  item?.previousCareCoordinationStatusText
                    ?.toLowerCase()
                    .includes('snoozed') ? (
                    <SnoozedIcon />
                  ) : undefined
                }
                label={hardCodeStatusText(
                  item?.previousCareCoordinationStatusText || 'Unknown'
                )}
                classes={{root:getChangedToStatusClassName(
                      item?.previousCareCoordinationStatusText || 'No Status'
                  )}}
              />
              <Typography className={classes.mainContentStatusChange}>
                Changed to
              </Typography>
              <Chip
                icon={
                  item?.careCoordinationStatusText
                    ?.toLowerCase()
                    .includes('snoozed') ? (
                    <SnoozedIcon />
                  ) : undefined
                }
                label={hardCodeStatusText(
                  item?.careCoordinationStatusText || 'Unknown'
                )}
                classes={{root:getChangedToStatusClassName(
                      item?.careCoordinationStatusText || 'No Status'
                  )}}
              />
            </div>
            {item.logTitle === 'Status Change (Manual)' && item.extraInfo && (
              <div className={classes.statusChangeFooterNoteContainer}>
                <Typography
                  className={classes.statusChangeFooterNote}
                >{`Notes: ${item.extraInfo}`}</Typography>
              </div>
            )}
          </div>
        );
      // case LogTypeEnum.BatchSMS:
      //   return (
      //     <Typography className={classes.mainContentSMS}>
      //       {item.content}
      //     </Typography>
      //   );
      case LogTypeEnum.PatientReassigned:
        // logContent: "Reassigned to ace vincent"
        const tmpFullName = item.logContent
          ?.toLowerCase()
          .replace('reassigned to ', '')
          .split(' ') || ['No', 'Provider'];
        return (
          <div className={classes.mainContentReassignedContainer}>
            <Typography className={classes.mainContentReassigned}>
              Reassigned to{' '}
            </Typography>
            <ProfileItem firstName={tmpFullName[0]} lastName={tmpFullName[1]} />
          </div>
        );
      default:
        return (
          <Typography className={classes.mainContentAttempts}>
            {item.logContent}
          </Typography>
        );
    }
  };

  const getItem = (item: CoordinationLog) => {
    return (
      <div className={classes.itemContainer}>
        <div className={classes.item}>
          <div className={classes.itemHeader}>
            <div className={classes.generalInfo}>
              <Avatar className={classes.avatarIcon}>
                {getItemIcon(item.logType || '')}
              </Avatar>
              <div className={classes.avatarText}>
                <Typography className={classes.textTitle}>
                  {item.logTitle}
                </Typography>
                <Typography className={classes.textTime}>
                  {dateFormatPipe(item.logDate, '', false)}
                </Typography>
              </div>
            </div>
            <div>{getOperator(item)}</div>
          </div>
          {item.logContent ? (
            <div className={classes.itemBody}>{getContent(item)}</div>
          ) : null}
        </div>
      </div>
    );
  };

  const ItemListBySelectedSectionName = () => {
    if (selectedSectionName === LogTypeEnum.All) {
      return (
        <>
          {coordinationLogData.map((item: CoordinationLog, idx: number) => {
            return (
              <div key={idx + 'ItemListBySelectedSectionName1'}>
                {getItem(item)}
              </div>
            );
          })}
        </>
      );
    } else {
      const filteredItems = coordinationLogData.filter(
        (item: CoordinationLog) =>
          convertLogType2LogTypeEnum(item.logType || '') === selectedSectionName
      );
      return (
        <>
          {!filteredItems || filteredItems.length === 0 ? (
            <EmptyContent {...props} />
          ) : (
            filteredItems.map((item: any, idx: number) => {
              return (
                <div key={idx + 'ItemListBySelectedSectionName3'}>
                  {getItem(item)}
                </div>
              );
            })
          )}
        </>
      );
    }
  };

  return <ItemListBySelectedSectionName />;
};

interface CoordinationLogViewProps {
  coordinationLogData: any;
  coordinationLogOptions: any;
}

export const CoordinationLogView = (props: CoordinationLogViewProps) => {
  const { coordinationLogData, coordinationLogOptions } = props;
  const classes = useCoordinationLogStyles();
  const [selectedOptionName, setSelectedOptionName] = React.useState(
      (coordinationLogOptions === undefined ||
                coordinationLogOptions === null ||
                coordinationLogOptions.length === 0) ? '' : coordinationLogOptions[0].name
  );
  const onChange = (type: string, newValue: string) => {
    setSelectedOptionName(newValue);
  };

  const ContentHeader = () => {
    return (
      <>
        {/* Section Name and Option Select */}
        <div className={classes.sectionNameAndOptionSelectContainer}>
          <Typography className={classes.sectionName}>
            {selectedOptionName??''}
          </Typography>
          {/* options select */}
          <div className={classes.optionSelect}>
            <CoordinationLogSelect
              state={selectedOptionName}
              type={'CoordinationLogSectionName'}
              setState={onChange}
              isMultiple={false}
              items={coordinationLogOptions}
            />
          </div>
        </div>
      </>
    );
  };

  const ContentBody = () => {
    return (
      <>
        {/* Items */}
        <CoordinationLogItemList
          selectedSectionName={selectedOptionName}
          coordinationLogData={coordinationLogData}
          classes={classes}
        />
      </>
    );
  };

  return (
    <div className={classes.root}>
      {coordinationLogData?.length > 0 ?(
        <>
          <ContentHeader />
          <ContentBody />
        </>
      ):
          (
              <EmptyContent
                  selectedSectionName={'Coordination Log'}
                  coordinationLogData={coordinationLogData}
                  classes={classes}
              />
          ) }
    </div>
  );
};

export const EmptyContent = (props: CoordinationLogItemListProps) => {
  const { classes, selectedSectionName } = props;

  return (
    <Typography className={classes.emptyContentText}>
      {`No ${selectedSectionName}`}
    </Typography>
  );
};
