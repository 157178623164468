import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const ActivateUserIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M15.0818 14.6C13.5545 13.8182 11.5727 13.1818 9.27273 13.1818C6.97273 13.1818 4.99091 13.8182 3.46364 14.6C2.55455 15.0636 2 16 2 17.0182V19.5455H16.5455V17.0182C16.5455 16 15.9909 15.0636 15.0818 14.6ZM9.27273 12.2727C11.2818 12.2727 12.9091 10.6455 12.9091 8.63636C12.9091 6.62727 11.2818 5 9.27273 5C7.26364 5 5.63636 6.62727 5.63636 8.63636C5.63636 10.6455 7.26364 12.2727 9.27273 12.2727ZM19.2727 9.54545V7.72727C19.2727 7.22727 18.8636 6.81818 18.3636 6.81818C17.8636 6.81818 17.4545 7.22727 17.4545 7.72727V9.54545H15.6364C15.1364 9.54545 14.7273 9.95455 14.7273 10.4545C14.7273 10.9545 15.1364 11.3636 15.6364 11.3636H17.4545V13.1818C17.4545 13.6818 17.8636 14.0909 18.3636 14.0909C18.8636 14.0909 19.2727 13.6818 19.2727 13.1818V11.3636H21.0909C21.5909 11.3636 22 10.9545 22 10.4545C22 9.95455 21.5909 9.54545 21.0909 9.54545H19.2727Z"
  />
);
