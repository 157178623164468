import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetEligibilitySummaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEligibilitySummaryQuery = (
  { __typename: 'Query' }
  & { getEligibilitySummary: (
    { __typename: 'EligibilitySummaryGraphQLResponse' }
    & { summaryInfo: (
      { __typename: 'EligibilitySummaryInfo' }
      & Pick<Types.EligibilitySummaryInfo, 'activeExternal' | 'activeInternal' | 'inactive' | 'rejected' | 'updatedDate' | 'unavailable' | 'nextScheduledDate' | 'smsBatchCount'>
    ) }
  ) }
);

export type GetEligibilityCheckLogsQueryVariables = Types.Exact<{
  input: Types.EligibilityCheckLogGraphQlRequest;
}>;


export type GetEligibilityCheckLogsQuery = (
  { __typename: 'Query' }
  & { getEligibilityCheckLogs: (
    { __typename: 'EligibilityCheckLogGraphQLResponse' }
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'EligibilityPagingInfo' }
      & Pick<Types.EligibilityPagingInfo, 'count' | 'limit' | 'skip'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'EligibilityCheckLog' }
      & Pick<Types.EligibilityCheckLog, 'logType' | 'activeExternal' | 'activeInternal' | 'checkDate' | 'inactive' | 'logId' | 'rejected' | 'unavailable' | 'status'>
    )>> }
  ) }
);

export type GetEligibilitySummaryHistoryQueryVariables = Types.Exact<{
  input: Types.EligibilitySummaryHistoryGraphQlRequest;
}>;


export type GetEligibilitySummaryHistoryQuery = (
  { __typename: 'Query' }
  & { getEligibilitySummaryHistory: (
    { __typename: 'EligibilitySummaryHistoryGraphQLResponse' }
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'EligibilityPagingInfo' }
      & Pick<Types.EligibilityPagingInfo, 'count' | 'limit' | 'skip'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'EligibilitySummaryInfo' }
      & Pick<Types.EligibilitySummaryInfo, 'activeExternal' | 'activeInternal' | 'inactive' | 'rejected' | 'unavailable' | 'updatedDate'>
    )>> }
  ) }
);


export const GetEligibilitySummaryDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilitySummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilitySummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"summaryInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeExternal"}},{"kind":"Field","name":{"kind":"Name","value":"activeInternal"}},{"kind":"Field","name":{"kind":"Name","value":"inactive"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"updatedDate"}},{"kind":"Field","name":{"kind":"Name","value":"unavailable"}},{"kind":"Field","name":{"kind":"Name","value":"nextScheduledDate"}},{"kind":"Field","name":{"kind":"Name","value":"smsBatchCount"}}]}}]}}]}}]};

/**
 * __useGetEligibilitySummaryQuery__
 *
 * To run a query within a React component, call `useGetEligibilitySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilitySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilitySummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEligibilitySummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetEligibilitySummaryQuery, GetEligibilitySummaryQueryVariables>) {
        return Apollo.useQuery<GetEligibilitySummaryQuery, GetEligibilitySummaryQueryVariables>(GetEligibilitySummaryDocument, baseOptions);
      }
export function useGetEligibilitySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilitySummaryQuery, GetEligibilitySummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilitySummaryQuery, GetEligibilitySummaryQueryVariables>(GetEligibilitySummaryDocument, baseOptions);
        }
export type GetEligibilitySummaryQueryHookResult = ReturnType<typeof useGetEligibilitySummaryQuery>;
export type GetEligibilitySummaryLazyQueryHookResult = ReturnType<typeof useGetEligibilitySummaryLazyQuery>;
export type GetEligibilitySummaryQueryResult = Apollo.QueryResult<GetEligibilitySummaryQuery, GetEligibilitySummaryQueryVariables>;
export function refetchGetEligibilitySummaryQuery(variables?: GetEligibilitySummaryQueryVariables) {
      return { query: GetEligibilitySummaryDocument, variables: variables }
    }
export const GetEligibilityCheckLogsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilityCheckLogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilityCheckLogGraphQLRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilityCheckLogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}}]}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logType"}},{"kind":"Field","name":{"kind":"Name","value":"activeExternal"}},{"kind":"Field","name":{"kind":"Name","value":"activeInternal"}},{"kind":"Field","name":{"kind":"Name","value":"checkDate"}},{"kind":"Field","name":{"kind":"Name","value":"inactive"}},{"kind":"Field","name":{"kind":"Name","value":"logId"}},{"kind":"Field","name":{"kind":"Name","value":"logType"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"unavailable"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]};

/**
 * __useGetEligibilityCheckLogsQuery__
 *
 * To run a query within a React component, call `useGetEligibilityCheckLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityCheckLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityCheckLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEligibilityCheckLogsQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilityCheckLogsQuery, GetEligibilityCheckLogsQueryVariables>) {
        return Apollo.useQuery<GetEligibilityCheckLogsQuery, GetEligibilityCheckLogsQueryVariables>(GetEligibilityCheckLogsDocument, baseOptions);
      }
export function useGetEligibilityCheckLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityCheckLogsQuery, GetEligibilityCheckLogsQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilityCheckLogsQuery, GetEligibilityCheckLogsQueryVariables>(GetEligibilityCheckLogsDocument, baseOptions);
        }
export type GetEligibilityCheckLogsQueryHookResult = ReturnType<typeof useGetEligibilityCheckLogsQuery>;
export type GetEligibilityCheckLogsLazyQueryHookResult = ReturnType<typeof useGetEligibilityCheckLogsLazyQuery>;
export type GetEligibilityCheckLogsQueryResult = Apollo.QueryResult<GetEligibilityCheckLogsQuery, GetEligibilityCheckLogsQueryVariables>;
export function refetchGetEligibilityCheckLogsQuery(variables?: GetEligibilityCheckLogsQueryVariables) {
      return { query: GetEligibilityCheckLogsDocument, variables: variables }
    }
export const GetEligibilitySummaryHistoryDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEligibilitySummaryHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilitySummaryHistoryGraphQLRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getEligibilitySummaryHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}}]}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeExternal"}},{"kind":"Field","name":{"kind":"Name","value":"activeInternal"}},{"kind":"Field","name":{"kind":"Name","value":"inactive"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"unavailable"}},{"kind":"Field","name":{"kind":"Name","value":"updatedDate"}}]}}]}}]}}]};

/**
 * __useGetEligibilitySummaryHistoryQuery__
 *
 * To run a query within a React component, call `useGetEligibilitySummaryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilitySummaryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilitySummaryHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEligibilitySummaryHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilitySummaryHistoryQuery, GetEligibilitySummaryHistoryQueryVariables>) {
        return Apollo.useQuery<GetEligibilitySummaryHistoryQuery, GetEligibilitySummaryHistoryQueryVariables>(GetEligibilitySummaryHistoryDocument, baseOptions);
      }
export function useGetEligibilitySummaryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilitySummaryHistoryQuery, GetEligibilitySummaryHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetEligibilitySummaryHistoryQuery, GetEligibilitySummaryHistoryQueryVariables>(GetEligibilitySummaryHistoryDocument, baseOptions);
        }
export type GetEligibilitySummaryHistoryQueryHookResult = ReturnType<typeof useGetEligibilitySummaryHistoryQuery>;
export type GetEligibilitySummaryHistoryLazyQueryHookResult = ReturnType<typeof useGetEligibilitySummaryHistoryLazyQuery>;
export type GetEligibilitySummaryHistoryQueryResult = Apollo.QueryResult<GetEligibilitySummaryHistoryQuery, GetEligibilitySummaryHistoryQueryVariables>;
export function refetchGetEligibilitySummaryHistoryQuery(variables?: GetEligibilitySummaryHistoryQueryVariables) {
      return { query: GetEligibilitySummaryHistoryDocument, variables: variables }
    }