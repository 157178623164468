import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { theme } from '../../../themes/theme';
import { AddIcon } from '../../../assets/Icons/PanelsList';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckPrivilegesForDisplay } from '../../../components/CheckPrivilegesForDisplay';
import { MainRegion } from '../../../components/MainRegion';
import { CareGapProvider } from '../../CareGaps/Home/tabs/Population/context';
import { AllPatientContainer } from './components/AllPatient/Container';
import { TabBar } from '../../../components/TabBar';
import { CCPanelsList } from './components/CCPanelsList';
import { useTabLabel } from './hooks/useTabLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: 'none',
    borderBottom: '1px solid rgb(209, 209, 209)',
    '& .MuiTabs-root': {
      borderBottom: 'none',
    },
  },

  deactiveTab: {
    color: theme.palette.grey[600],
  },
  activeTab: {
    color: theme.palette.info.main,
  },
  tabText: {
    height: '24px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Manrope',
    textTransform: 'capitalize',
  },
  tabNum: {
    display: 'inline-block',
    borderRadius: '6px',
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0, 0.625),
  },
  activeNum: {
    color: '#FFF!important',
    backgroundColor: theme.palette.info.main,
  },
  deactivateNum: {
    color: theme.palette.grey[500]!,
    backgroundColor: '#efefef',
  },
  headerMainRow: {
    height: 88,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: theme.typography.fontWeightBold,
      height: '40px',
      letterSpacing: '0',
      lineHeight: '40px',
      // margin: theme.spacing(2.335, 3),
      margin: '24px',
      display: 'inline-block',
      textTransform: 'capitalize',
    },

    '& #create-button-container': {
      marginLeft: 'auto',
      marginRight: theme.spacing(3),
    },

    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: '125px',
      height: '40px',
      textAlign: 'center',
      padding: theme.spacing(0),

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
    },
  },

  icon: {
    width: '24px',
    height: '24px',
    color: theme.palette.text.disabled,
  },
}));

interface PanelsHomeViewProps {
  name: string;
  selectedTab: number;
  numOfMyPanels: number;
  numOfMyPatients: number;
  numOfPatients: number;
  setNumOfPanels: any;
  setNumOfMyPatients: any;
}

export const CCPanelsHomeView = (props: PanelsHomeViewProps) => {
  const [value, setValue] = React.useState(props.selectedTab);
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;

  const classes = useStyles();

  const switchTab = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleCreationClick = () => {
    navigate('/panels/create');
  };

  //
  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  function getTabObjects() {
    const tabObjects = [
      {
        tabIndex: 0,
        tabLabel: 'All Patients',
        tabUrl: '',
        tabBadgeCount: props.numOfPatients,
        disabled: false,
      },
    ];

    if (props.numOfMyPatients !== 0) {
      tabObjects.unshift({
        tabIndex: 0,
        tabLabel: 'My Patients',
        tabUrl: '',
        tabBadgeCount: props.numOfMyPatients,
        disabled: false,
      });
    }
    if (props.numOfMyPanels !== 0) {
      tabObjects.unshift({
        tabIndex: 0,
        tabLabel: 'My Panels',
        tabUrl: '',
        tabBadgeCount: props.numOfMyPanels,
        disabled: false,
      });
    }

    tabObjects.forEach((tabObject, index) => {
      tabObject.tabIndex = index;
    });

    return tabObjects;
  }

  function getTabBarElement() {
    const tabObjects = getTabObjects();
    const tabBars: JSX.Element[] = [];

    tabObjects.forEach((tabObject) => {
      if (tabObject.tabLabel === 'My Panels') {
        tabBars.push(
          <Grid item xs={12}>
            <CCPanelsList
              skip={skip || 0}
              limit={limit || 10}
              setSkip={onSetSkip}
              setLimit={onSetLimit}
              showMyOwn={'YES'}
              setNumOfPanels={props.setNumOfPanels}
            />
          </Grid>
        );
      }
      if (tabObject.tabLabel === 'My Patients') {
        tabBars.push(
          <Grid item xs={12}>
            <CareGapProvider>
              <AllPatientContainer
                showMyOwn={'YES'}
                isGetAllPatients={false}
                setNumOfPatients={props.setNumOfMyPatients}
              />
            </CareGapProvider>
          </Grid>
        );
      }

      if (tabObject.tabLabel === 'All Patients') {
        tabBars.push(
          <Grid item xs={12}>
            <CareGapProvider>
              <AllPatientContainer showMyOwn={''} isGetAllPatients={true} />
            </CareGapProvider>
          </Grid>
        );
      }
    });

    return tabBars;
  }

  //

  useTabLabel(getTabObjects(), value);

  return (
    <MainRegion
      header={
        <div
          style={{
            background: theme.palette.primary.contrastText,
            width: '100%',
          }}
        >
          <Grid item xs={12} className={classes.headerMainRow}>
            <Typography variant={'h2'}>{props.name}</Typography>
            <CheckPrivilegesForDisplay
              neededPrivileges={'panels.create'}
              element={
                <div id="create-button-container">
                  <Button
                    id="creation-button"
                    variant="contained"
                    disableElevation
                    onClick={handleCreationClick}
                  >
                    <AddIcon />

                    <span>Create New</span>
                  </Button>
                </div>
              }
            />
          </Grid>
        </div>
      }
      overFlowHidden={true}
    >
      <Grid container className={classes.root}>
        <TabBar
          sticky={true}
          activeTabIndex={value}
          switchTab={switchTab}
          tabObjects={getTabObjects()}
        >
          {getTabBarElement().map((element, index) => {
            return value === index && element;
          })}
        </TabBar>
      </Grid>
    </MainRegion>
  );
};
