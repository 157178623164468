import React, { useContext } from 'react';
import { SideBarView } from './View';
import { GlobalContext } from '../../../../components/GlobalContext';

type Props = {
  width: number;
};

export const SideBarViewContainer = (props: Props) => {
  const { loggedInUser, loading } = useContext(GlobalContext);

  if (loading) {
    return <SideBarView width={props.width} />;
  }

  return <SideBarView width={props.width} user={loggedInUser} />;
};
