import Rollbar from 'rollbar';

import { config } from './config';

const rollbarConfig = {
  accessToken: config.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    !!config.rollbar.accessToken &&
    (config.debug.nodeEnv === 'production' || config.rollbar.devMode),
  payload: {
    environment: config.rollbar.environment,
    client: {
      javascript: {
        code_version: config.application.version,
        source_map_enabled: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;

function setUser(user?: { userId: string }) {
  rollbar.configure({
    payload: {
      person: {
        id: user?.userId,
      },
    },
  });
}

export { setUser };
