import { RoleType } from './roleType';

export interface UserRole {
  roleId: string;
  roleName: RoleType | string;
}

export function createUserRole(
  roleId: string,
  roleName: RoleType | string
): UserRole {
  return { roleId, roleName };
}
