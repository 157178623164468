import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const QMIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M8.8672 13.055L4.3342 11.764C3.3332 11.479 2.7692 10.421 3.0902 9.431C3.4192 8.419 4.5362 7.897 5.5232 8.295L10.0022 10.102L9.7072 4.94C9.6472 3.887 10.4852 3 11.5402 3H11.6272C12.6812 3 13.5192 3.886 13.4602 4.939L13.1632 10.245L17.4702 8.49C18.4602 8.087 19.5842 8.611 19.9112 9.629L19.9182 9.65C20.2362 10.642 19.6672 11.7 18.6642 11.98L14.0152 13.278L17.0852 17.186C17.7322 18.009 17.5602 19.205 16.7092 19.814L16.6442 19.86C15.7812 20.477 14.5752 20.236 14.0162 19.334L11.3602 15.051L8.7292 19.194C8.1732 20.07 7.0012 20.311 6.1442 19.726L6.1142 19.706C5.2352 19.106 5.0492 17.886 5.7092 17.051L8.8672 13.055Z"
    />
  );
};
