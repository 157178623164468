import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useUserInfoMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    userInfoButton: {
      width: 234,
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 6,

      '&:active': {
        borderWidth: 2,
        //   borderColor: `${theme.palette.primary.main}`,
      },
      '&:focus': {
        borderWidth: 2,
        borderColor: `${theme.palette.primary.main}`,
      },
      '&:hover': {
        borderWidth: 2,
        borderColor: `${theme.palette.primary.main}`,
      },
    },
    navigateNext: {
      width: 24,
      height: 24,
      color: theme.palette.grey[500],
      transform: `rotate(90deg)`,
    },
    rotateNavigateNext: {
      transform: `rotate(270deg)`,
    },
    menu: {
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
        marginTop: theme.spacing(0.5),
        width: 234,
      },
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
        // width: 184,
        // height: 112,
        padding: theme.spacing(0.5, 1),
        position: 'relative',
      },

      // '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after':
      //   {
      //     content: '""',
      //     position: 'absolute',
      //     bottom: 50,
      //     left: 0,
      //     display: 'block',
      //     width: '100%',
      //     height: 1,
      //     background: theme.palette.grey[300],
      //   },

      '& li[role="menuitem"]': {
        // width: 184,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        borderRadius: 8,
        // margin: theme.spacing(0.5, 0),
      },

      '& li[role="menuitem"]:first-child': {
        marginBottom: theme.spacing(0.5),
      },
      '& li[role="menuitem"]:nth-type-of(2)': {
        marginBottom: theme.spacing(0.25),
      },
      '& li[role="menuitem"]:last-child': {
        margin: theme.spacing(0.5, 0),
      },
    },
    menuItem: {
      color: theme.palette.grey[600],
      '&:hover': {
        color: theme.palette.grey[700],
      },
      '& > svg': {
        color: theme.palette.grey[500],
      },
      '& > svg:hover': {
        color: theme.palette.grey[600],
      },
    },
  })
);
