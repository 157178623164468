import React, { useMemo } from 'react';
import {
  GetDashboardQuery,
  useGetDailyCoordinationProgressDataQuery,
  useGetDashboardQuery,
} from './index.generated';
import { DashBoardTabView } from './View';
import { useGetEligibilitySummaryHistoryQuery } from '../../../../Eligibility/Dashboard/index.generated';
import { EligibilityBarchartDataType } from '../../../../../components/Charts/StackedBarCharts';
import { useDailyCoordinationData } from '../../../../../hooks/useDailyCoordinationData';
import moment from 'moment'

export const DashBoardContainer = () => {
  const dashboardQuery = useGetDashboardQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getDashboardData = (query: GetDashboardQuery | undefined) => {
    return query?.dashboard;
  };

  const getEligibilitySummaryHistoryQuery = useGetEligibilitySummaryHistoryQuery(
      {
        variables: {
          input: {
            skip: 1,
            limit: 12,
          },
        },
      }
  );

  const eligibilityBoardData = useMemo(() => {
    const xMonth: Array<string> = []
    for (let i = 0; i < 12; i ++) {
      const n = moment().subtract(i + 1, 'months').format('MMM')
      xMonth.push(n)
    }
    const data: EligibilityBarchartDataType = {
      activeInternal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      activeExternal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      rejected: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inactive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      unavailable: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      xMonth: xMonth.reverse()
    };
    if (
      getEligibilitySummaryHistoryQuery.error ||
      !getEligibilitySummaryHistoryQuery.called ||
      getEligibilitySummaryHistoryQuery.loading
    ) {
      return data;
    }
    const results = getEligibilitySummaryHistoryQuery!.data!.getEligibilitySummaryHistory!.results!
    if (!results.length) {
      return data
    }
    results.sort((x: any, y: any) => {
      const xd = new Date(x.updatedDate).getTime()
      const yd = new Date(y.updatedDate).getTime()
      return yd - xd
    })

    results.forEach(
        (item) => {
          const { activeInternal, activeExternal, rejected, inactive, updatedDate, unavailable } = item;
          const idx = data.xMonth.findIndex((xm: string) => {
            const mmm = moment(new Date(updatedDate)).format("MMM")
            return xm === mmm
          })
          if (idx >= 0) {
            data.activeInternal[idx] = activeInternal;
            data.activeExternal[idx] = activeExternal;
            data.rejected[idx] = rejected;
            data.inactive[idx] = inactive;
            data.unavailable[idx] = unavailable;
          }

        }
    );
    return data
  }, [getEligibilitySummaryHistoryQuery]);

  const { dailyCoordinationProgressData } = useDailyCoordinationData(undefined);

  const dashboardData = React.useMemo(() => {
    if (dashboardQuery.error) {
      return {
        patientCount: 0,
        averageCompliance: 0,
        panelCount: 0,
        pcps: {
          averagePerformance: 0,
          pcps: [],
        },
        last10Days: {
          closedGaps: [],
          dates: [],
          engagedPatients: [],
        },
        disease: [],
      };
    }
    return getDashboardData(dashboardQuery.data);
  }, [dashboardQuery]);

  return (
      <div>
        <DashBoardTabView
            numOfPatients={dashboardData?.patientCount}
            numOfAverageCompliance={dashboardData?.averageCompliance}
            numOfPanels={dashboardData?.panelCount}
            providerAvePerformance={dashboardData?.pcps?.averagePerformance}
            providers={dashboardData?.pcps?.pcps}
            dailyEngagedPatientsAndClosedGaps={dashboardData?.last10Days}
            diseaseAndTagsCount={dashboardData?.disease}
            eligibilityData={eligibilityBoardData}
            dailyCoordinationProgressData={dailyCoordinationProgressData}
        />
      </div>
  );
};

