import React from 'react';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { ButtonSmallPrimaryText } from '../../../../../../components/Buttons';

interface NotificationCenterHeaderProps {
  items: any[];
  clearNotificationsHandler: (itemIds: string[]) => void;
}

export const NotificationCenterHeader: React.FC<NotificationCenterHeaderProps> = ({
  items,
  clearNotificationsHandler,
}) => {
  const classes = useStyles();
  return (
    <div id="NotificationCenterHeader" className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>
          Notification Center
        </Typography>
        <ButtonSmallPrimaryText
          className={classes.clearAllButton}
          disabled={items.length === 0}
          onClick={() =>
            clearNotificationsHandler(items.map((item) => item.id))
          }
        >
          Clear All
        </ButtonSmallPrimaryText>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  headerText: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px',
    color: theme.palette.grey[700],
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(3, 3, 4, 3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  clearAllButton: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
}));
