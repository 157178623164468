import React from 'react';
import {
  useGetTaskStatusQuery,
  useUpdateDelistedPatientToCareTeamUsersMutation,
} from '../../components/PanelPatientsListActionsFooter/index.generated';
import { GlobalContext } from '../../../../../components/GlobalContext';

export const useMoveDeListedPatients = (
  panelId: any,
  allSelected: any,
  excluded: any,
  selectedItems: any,
  // hardFresh: any,
  setEnableChangeCoordinatorDialog: any,
  setLoading: any,
  careGapFilters: any
) => {
  const [
    updateDelistedPatientToCareTeamUsersMutation,
  ] = useUpdateDelistedPatientToCareTeamUsersMutation();

  const [polling, setPolling] = React.useState<any>(undefined);
  const [taskId, setTaskId] = React.useState('');
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  // console.log('useMoveDeListedPatients careGapFilters =>', careGapFilters);
  const assignCoordinator = async (fromUsers: any, toUsers: any) => {
    const filter = {
      andName: careGapFilters.andName,
      andDob: careGapFilters.andDob,
    };
    const input = {
      panelId: panelId,
      filter: filter,
      excludePatientIds: allSelected
        ? excluded.map((patient: any) => patient.id)
        : [],
      selectedPatientIds: allSelected
        ? []
        : selectedItems.map((patient: any) => patient.id),
      toUserId: toUsers.map((item: any) => {
        return {
          count: item.assignedPatients,
          userId: item.id,
        };
      }),
    };

    try {
      const {
        data,
        errors,
      } = await updateDelistedPatientToCareTeamUsersMutation({
        variables: {
          input: {
            ...input,
          },
        },
      });
      if (
        data &&
        data.updateDelistedPatientToCareTeamUsers.entityId &&
        data.updateDelistedPatientToCareTeamUsers.taskId
      ) {
        setTaskId(data.updateDelistedPatientToCareTeamUsers.taskId);
        setToastMessage({
          snackbarMsg: `Patient status is updated.`,
          severity: 'success',
          isOpen: true,
        });
        setIsOpenToast(true);
      } else if (errors) {
        // error response
        setToastMessage({
          snackbarMsg: `Something Went Wrong, Please Try Again Later.`,
          severity: 'error',
          isOpen: true,
        });
        setIsOpenToast(true);
        setEnableChangeCoordinatorDialog(false);
        // hardFresh();
      }
    } catch (error) {
      setToastMessage({
        snackbarMsg: `Something Went Wrong, Please Try Again Later.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
      setEnableChangeCoordinatorDialog(false);
      // hardFresh();
    }
  };

  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId, // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (taskId) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        queryTask.stopPolling();
        setLoading(false);
        setPolling(false);
      } else {
        setLoading(true);
        queryTask.startPolling(1000);
        setPolling(true);
      }
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  return { polling, assignCoordinator, setPolling };
};
