import React from 'react';
import { EligibilityCheckStatusType } from './useEligibilityCheckStatus';

export const MAX_RETRY_TIME = 5;

export type AutoRetryType = {
  retry: boolean;
  currentRetryTime: number;
};

export const useAutoRetry = (
  eligibilityCheckStatus: EligibilityCheckStatusType | undefined
) => {
  const [autoRetry, setAutoRetry] = React.useState<AutoRetryType>({
    retry: false,
    currentRetryTime: 0,
  });

  React.useEffect(() => {
    if (eligibilityCheckStatus) {
      switch (eligibilityCheckStatus.checkStatus) {
        case true:
          // console.log('SUCCESS');
          setAutoRetry({ currentRetryTime: 0, retry: false });
          break;
        case false:
          // console.log('FAILED');
          if (autoRetry.currentRetryTime < MAX_RETRY_TIME) {
            setAutoRetry((prev) => {
              const newRetryTime = prev.currentRetryTime + 1;
              return { currentRetryTime: newRetryTime, retry: true };
            });
          } else {
            setAutoRetry({ currentRetryTime: 0, retry: false });
          }
          break;
        default:
          break;
      }
    }
  }, [eligibilityCheckStatus]);

  if (autoRetry.retry && autoRetry.currentRetryTime === MAX_RETRY_TIME) {
    setAutoRetry({ currentRetryTime: 0, retry: false });
  }

  return { autoRetry };
};
