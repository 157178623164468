import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetProviderPerformanceFilterOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProviderPerformanceFilterOptionsQuery = (
  { __typename: 'Query' }
  & { getCareGapFilterOptions?: Types.Maybe<(
    { __typename: 'QMFilterOptions' }
    & { Performance?: Types.Maybe<Array<(
      { __typename: 'NameValue' }
      & Pick<Types.NameValue, 'name' | 'value'>
    )>> }
  )> }
);


export const GetProviderPerformanceFilterOptionsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getProviderPerformanceFilterOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCareGapFilterOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Performance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]};

/**
 * __useGetProviderPerformanceFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetProviderPerformanceFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderPerformanceFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderPerformanceFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProviderPerformanceFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetProviderPerformanceFilterOptionsQuery, GetProviderPerformanceFilterOptionsQueryVariables>) {
        return Apollo.useQuery<GetProviderPerformanceFilterOptionsQuery, GetProviderPerformanceFilterOptionsQueryVariables>(GetProviderPerformanceFilterOptionsDocument, baseOptions);
      }
export function useGetProviderPerformanceFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderPerformanceFilterOptionsQuery, GetProviderPerformanceFilterOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetProviderPerformanceFilterOptionsQuery, GetProviderPerformanceFilterOptionsQueryVariables>(GetProviderPerformanceFilterOptionsDocument, baseOptions);
        }
export type GetProviderPerformanceFilterOptionsQueryHookResult = ReturnType<typeof useGetProviderPerformanceFilterOptionsQuery>;
export type GetProviderPerformanceFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetProviderPerformanceFilterOptionsLazyQuery>;
export type GetProviderPerformanceFilterOptionsQueryResult = Apollo.QueryResult<GetProviderPerformanceFilterOptionsQuery, GetProviderPerformanceFilterOptionsQueryVariables>;
export function refetchGetProviderPerformanceFilterOptionsQuery(variables?: GetProviderPerformanceFilterOptionsQueryVariables) {
      return { query: GetProviderPerformanceFilterOptionsDocument, variables: variables }
    }