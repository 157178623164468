import * as Types from '../../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientCareGapsQueryVariables = Types.Exact<{
  mpatientId: Types.Scalars['String'];
}>;


export type GetPatientCareGapsQuery = (
  { __typename: 'Query' }
  & { getPatientCareGapsInfo?: Types.Maybe<Array<(
    { __typename: 'PatientCareGapInfo' }
    & Pick<Types.PatientCareGapInfo, 'measureCode' | 'measureName' | 'measureType' | 'measureStatus' | 'measureDescription' | 'dueDate' | 'closedDate' | 'eventDate'>
  )>> }
);

export type GetPatientCareGapInfoQueryVariables = Types.Exact<{
  mpatientId: Types.Scalars['String'];
}>;


export type GetPatientCareGapInfoQuery = (
  { __typename: 'Query' }
  & { searchPatientCareGapsInfo?: Types.Maybe<Array<(
    { __typename: 'PatientCareGapInfo' }
    & Pick<Types.PatientCareGapInfo, 'measureCode' | 'measureName' | 'measureType' | 'measureStatus' | 'measureDescription' | 'dueDate' | 'closedDate' | 'eventDate' | 'measureExtendedStatus' | 'measureStatusCategory' | 'measureStatusDisplay' | 'measureStatusMutable' | 'measureNote' | 'closedBy' | 'measureYear'>
  )>> }
);

export type GetPatientCareGapStatusChangeLogsQueryVariables = Types.Exact<{
  input: Types.GetPatientCareGapStatusLogsRequest;
}>;


export type GetPatientCareGapStatusChangeLogsQuery = (
  { __typename: 'Query' }
  & { getPatientCareGapStatusChangeLogs?: Types.Maybe<(
    { __typename: 'GetPatientCareGapStatusLogsResponse' }
    & Pick<Types.GetPatientCareGapStatusLogsResponse, 'errorMessage' | 'isSuccess'>
    & { logs: Array<(
      { __typename: 'MeasureStatusChangeLog' }
      & Pick<Types.MeasureStatusChangeLog, 'createTime' | 'measure' | 'measureEventDate' | 'measureExtendedStatus' | 'measureStatus' | 'measureStatusFacesheetDisplay' | 'previousExtendedStatus' | 'previousMeasureStatusFacesheetDisplay' | 'userName' | 'callAttempt' | 'notes'>
    )> }
  )> }
);

export type SetPatientCareGapExtendedStatusMutationVariables = Types.Exact<{
  input: Types.SetPatientCareGapExtendedStatusRequest;
}>;


export type SetPatientCareGapExtendedStatusMutation = (
  { __typename: 'Mutation' }
  & { setPatientCareGapExtendedStatus: (
    { __typename: 'SetPatientCareGapExtendedStatusResponse' }
    & Pick<Types.SetPatientCareGapExtendedStatusResponse, 'errorMessage' | 'isSuccess'>
  ) }
);


export const GetPatientCareGapsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCareGaps"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientCareGapsInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mpatientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measureCode"}},{"kind":"Field","name":{"kind":"Name","value":"measureName"}},{"kind":"Field","name":{"kind":"Name","value":"measureType"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatus"}},{"kind":"Field","name":{"kind":"Name","value":"measureDescription"}},{"kind":"Field","name":{"kind":"Name","value":"dueDate"}},{"kind":"Field","name":{"kind":"Name","value":"closedDate"}},{"kind":"Field","name":{"kind":"Name","value":"eventDate"}}]}}]}}]};

/**
 * __useGetPatientCareGapsQuery__
 *
 * To run a query within a React component, call `useGetPatientCareGapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCareGapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCareGapsQuery({
 *   variables: {
 *      mpatientId: // value for 'mpatientId'
 *   },
 * });
 */
export function useGetPatientCareGapsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCareGapsQuery, GetPatientCareGapsQueryVariables>) {
        return Apollo.useQuery<GetPatientCareGapsQuery, GetPatientCareGapsQueryVariables>(GetPatientCareGapsDocument, baseOptions);
      }
export function useGetPatientCareGapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCareGapsQuery, GetPatientCareGapsQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCareGapsQuery, GetPatientCareGapsQueryVariables>(GetPatientCareGapsDocument, baseOptions);
        }
export type GetPatientCareGapsQueryHookResult = ReturnType<typeof useGetPatientCareGapsQuery>;
export type GetPatientCareGapsLazyQueryHookResult = ReturnType<typeof useGetPatientCareGapsLazyQuery>;
export type GetPatientCareGapsQueryResult = Apollo.QueryResult<GetPatientCareGapsQuery, GetPatientCareGapsQueryVariables>;
export function refetchGetPatientCareGapsQuery(variables?: GetPatientCareGapsQueryVariables) {
      return { query: GetPatientCareGapsDocument, variables: variables }
    }
export const GetPatientCareGapInfoDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCareGapInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchPatientCareGapsInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mpatientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measureCode"}},{"kind":"Field","name":{"kind":"Name","value":"measureName"}},{"kind":"Field","name":{"kind":"Name","value":"measureType"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatus"}},{"kind":"Field","name":{"kind":"Name","value":"measureDescription"}},{"kind":"Field","name":{"kind":"Name","value":"dueDate"}},{"kind":"Field","name":{"kind":"Name","value":"closedDate"}},{"kind":"Field","name":{"kind":"Name","value":"eventDate"}},{"kind":"Field","name":{"kind":"Name","value":"measureExtendedStatus"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatusCategory"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatusDisplay"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatusMutable"}},{"kind":"Field","name":{"kind":"Name","value":"measureNote"}},{"kind":"Field","name":{"kind":"Name","value":"closedBy"}},{"kind":"Field","name":{"kind":"Name","value":"measureYear"}}]}}]}}]};

/**
 * __useGetPatientCareGapInfoQuery__
 *
 * To run a query within a React component, call `useGetPatientCareGapInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCareGapInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCareGapInfoQuery({
 *   variables: {
 *      mpatientId: // value for 'mpatientId'
 *   },
 * });
 */
export function useGetPatientCareGapInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCareGapInfoQuery, GetPatientCareGapInfoQueryVariables>) {
        return Apollo.useQuery<GetPatientCareGapInfoQuery, GetPatientCareGapInfoQueryVariables>(GetPatientCareGapInfoDocument, baseOptions);
      }
export function useGetPatientCareGapInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCareGapInfoQuery, GetPatientCareGapInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCareGapInfoQuery, GetPatientCareGapInfoQueryVariables>(GetPatientCareGapInfoDocument, baseOptions);
        }
export type GetPatientCareGapInfoQueryHookResult = ReturnType<typeof useGetPatientCareGapInfoQuery>;
export type GetPatientCareGapInfoLazyQueryHookResult = ReturnType<typeof useGetPatientCareGapInfoLazyQuery>;
export type GetPatientCareGapInfoQueryResult = Apollo.QueryResult<GetPatientCareGapInfoQuery, GetPatientCareGapInfoQueryVariables>;
export function refetchGetPatientCareGapInfoQuery(variables?: GetPatientCareGapInfoQueryVariables) {
      return { query: GetPatientCareGapInfoDocument, variables: variables }
    }
export const GetPatientCareGapStatusChangeLogsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCareGapStatusChangeLogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientCareGapStatusLogsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientCareGapStatusChangeLogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"logs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTime"}},{"kind":"Field","name":{"kind":"Name","value":"measure"}},{"kind":"Field","name":{"kind":"Name","value":"measureEventDate"}},{"kind":"Field","name":{"kind":"Name","value":"measureExtendedStatus"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatus"}},{"kind":"Field","name":{"kind":"Name","value":"measureStatusFacesheetDisplay"}},{"kind":"Field","name":{"kind":"Name","value":"previousExtendedStatus"}},{"kind":"Field","name":{"kind":"Name","value":"previousMeasureStatusFacesheetDisplay"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"callAttempt"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}}]}}]}}]};

/**
 * __useGetPatientCareGapStatusChangeLogsQuery__
 *
 * To run a query within a React component, call `useGetPatientCareGapStatusChangeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCareGapStatusChangeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCareGapStatusChangeLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientCareGapStatusChangeLogsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCareGapStatusChangeLogsQuery, GetPatientCareGapStatusChangeLogsQueryVariables>) {
        return Apollo.useQuery<GetPatientCareGapStatusChangeLogsQuery, GetPatientCareGapStatusChangeLogsQueryVariables>(GetPatientCareGapStatusChangeLogsDocument, baseOptions);
      }
export function useGetPatientCareGapStatusChangeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCareGapStatusChangeLogsQuery, GetPatientCareGapStatusChangeLogsQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCareGapStatusChangeLogsQuery, GetPatientCareGapStatusChangeLogsQueryVariables>(GetPatientCareGapStatusChangeLogsDocument, baseOptions);
        }
export type GetPatientCareGapStatusChangeLogsQueryHookResult = ReturnType<typeof useGetPatientCareGapStatusChangeLogsQuery>;
export type GetPatientCareGapStatusChangeLogsLazyQueryHookResult = ReturnType<typeof useGetPatientCareGapStatusChangeLogsLazyQuery>;
export type GetPatientCareGapStatusChangeLogsQueryResult = Apollo.QueryResult<GetPatientCareGapStatusChangeLogsQuery, GetPatientCareGapStatusChangeLogsQueryVariables>;
export function refetchGetPatientCareGapStatusChangeLogsQuery(variables?: GetPatientCareGapStatusChangeLogsQueryVariables) {
      return { query: GetPatientCareGapStatusChangeLogsDocument, variables: variables }
    }
export const SetPatientCareGapExtendedStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setPatientCareGapExtendedStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetPatientCareGapExtendedStatusRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setPatientCareGapExtendedStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type SetPatientCareGapExtendedStatusMutationFn = Apollo.MutationFunction<SetPatientCareGapExtendedStatusMutation, SetPatientCareGapExtendedStatusMutationVariables>;

/**
 * __useSetPatientCareGapExtendedStatusMutation__
 *
 * To run a mutation, you first call `useSetPatientCareGapExtendedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientCareGapExtendedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientCareGapExtendedStatusMutation, { data, loading, error }] = useSetPatientCareGapExtendedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPatientCareGapExtendedStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPatientCareGapExtendedStatusMutation, SetPatientCareGapExtendedStatusMutationVariables>) {
        return Apollo.useMutation<SetPatientCareGapExtendedStatusMutation, SetPatientCareGapExtendedStatusMutationVariables>(SetPatientCareGapExtendedStatusDocument, baseOptions);
      }
export type SetPatientCareGapExtendedStatusMutationHookResult = ReturnType<typeof useSetPatientCareGapExtendedStatusMutation>;
export type SetPatientCareGapExtendedStatusMutationResult = Apollo.MutationResult<SetPatientCareGapExtendedStatusMutation>;
export type SetPatientCareGapExtendedStatusMutationOptions = Apollo.BaseMutationOptions<SetPatientCareGapExtendedStatusMutation, SetPatientCareGapExtendedStatusMutationVariables>;