import { makeStyles, Typography } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { theme } from '../../../../../themes/theme';

const AVATAR_BG = Object.values(theme.palette.avatars);

type RoleOptionItemProps = {
  firstName?: string;
  lastName?: string;
  title?: string;
  avatarUrl?: Maybe<string> | undefined;
  selected: boolean;
  hasAvatar?: boolean;
  item?: any;
};

const useStyles = makeStyles({
  selectMenuContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  selectMenuContainerSelected: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,

    '& > span': {
      color: theme.palette.primary.main,
    },
  },
  selectMenuAvatarStyles: {
    backgroundColor: AVATAR_BG[AVATAR_BG.length - 1],
    marginRight: theme.spacing(1.5),
    width: 40,
    height: 40,
  },
  selectMenuAvatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export function RoleOptionItem(props: RoleOptionItemProps) {
  const { selected, hasAvatar } = props;

  const classes = useStyles();

  return (
    <div
      className={
        selected
          ? classes.selectMenuContainerSelected
          : classes.selectMenuContainer
      }
      style={hasAvatar ? { height: 'auto' } : { height: '20px' }}
    >
      {
        <Typography variant="body1" className={classes.selectMenuContent}>
          {props.item && props.item.roleName}
        </Typography>
      }
      {selected && (
        <CheckIcon
          style={{ height: 25, position: 'absolute', right: '30px' }}
        />
      )}
    </div>
  );
}
