import React, {useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { SearchIcon } from '../../assets/Icons/PanelsList/SearchIcon';
import { FiltersIcon } from '../../assets/Icons/Eligibility/FiltersIcon';
import { CloseIcon } from '../../assets/Icons/ExportPatients';
import {useLocation} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    width: '100%',
    height: 40,
    position: 'relative',
  },
  searchRow: {
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    borderBottom: '1px solid #d1d1d1',
    alignItems: 'center',
    justifyContent: 'center',
    height: 39,
  },
  formRoot: {
    width: '100%',
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  dobButton: {
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    color: theme.palette.secondary.dark,
    height: 32,
  },
  savedFilterButton: {
    background: 'transparent!important',
    marginLeft: 12,
    marginRight: 12,
  },
  filterButtonText: {
    fontSize: 14,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    padding: theme.spacing(0, 0, 0, 0),
    color: theme.palette.grey[400],
    width: 24,
    height: 24,
    marginRight: 11,
  },
  row: {
    display: 'flex',
    marginLeft: 30,
    marginRight: 30,
    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: 102,
      height: 32,
      marginTop: 20,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',
      marginRight: 24,
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    '& #manage-button': {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.secondary.main,
      borderRadius: '8px',
      width: 165,
      height: 32,
      marginTop: 20,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
      },
    },
  },
  selectContainer: {
    width: 203,
    paddingLeft: 26,
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  divider: {
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['500'],
    lineHeight: '16px',
    fontSize: 12,
    paddingTop: 30,
  },
  clearFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 106,
    paddingTop: 40,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  filterText: {
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['600'],
    fontSize: 14,
  },
  closeIcon: {
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  disabledNextButton: {
    height: 32,
    width: 72,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    marginRight: 17,
  },
  cancelSearchButton: {
    background: '#EFEFEF',
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'relative',
    right: 10
  },
  disabledText: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    position: 'absolute',
    right: '-210px',
    cursor: 'text',
    userSelect: 'text',
  },
}));

interface SearchAndFilterBarProps {
  placeHolder: string;
  searchConstriction?: any;
  searchFunction?: any;
  filterHandler?: (event: any) => void;
  hideSearch?: boolean;
  setIsSearching?: any;
  isSearching?: any;
  openFilter?: any;
  setOpenFilter?: any;
  isSearchingCheck?: boolean | undefined;
  setIsSearchingCheck?: (input: boolean | undefined) => void;
  hideFilter?: boolean;
}

export const SearchAndFilterBar = (props: SearchAndFilterBarProps) => {
  const {
    placeHolder,
    filterHandler,
    isSearchingCheck,
    setIsSearchingCheck,
  } = props;

  const [searchInput, setSearchInput] = React.useState('');
  const [lastSearchInput, setLastSearchInput] = React.useState('');
  const [page, setPage] = React.useState<any>(undefined);
  const [inputWidth, setInputWidth] = React.useState('100%');
  const classes = useStyles();

  const location = useLocation();
  const { search } = location;
  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
        params.has('skip') && params.get('skip') !== ''
            ? parseInt(params.get('skip')!)
            : 0;
    const limit =
        params.has('limit') && params.get('limit') !== ''
            ? parseInt(params.get('limit')!)
            : 10;
    return [skip, limit];
  }, [search]);
  React.useEffect(()=>{
    if(page==undefined){
      setPage(skip+limit)
    }
    else{
      if(page!=skip+limit){
        setPage(skip+limit)
        setSearchInput(lastSearchInput);
      }
    }
  },[skip,limit])

  const onClickSearch = () => {
    if (props.searchFunction && searchInput.length > 2) {
      props.searchFunction(searchInput);
      props.setIsSearching(true);
      setLastSearchInput(searchInput)
    }
  };

  const onClickCancelSearch = () => {
    setIsSearchingCheck && setIsSearchingCheck(undefined);
    props.setIsSearching(false);
    setSearchInput('');
  };

  const handleChange = (e: any) => {
      setSearchInput(e.target.value);
  };

  React.useEffect(() => {
    if (searchInput?.length == 0) {
      setInputWidth('100%');
    } else if (searchInput.length * 16 + 125 > 200) {
      setInputWidth((searchInput.length + 1) * 16 + 125 + 'px');
    } else {
      setInputWidth('200px');
    }
  }, [searchInput]);
  const [clickSearch, setClickSearch] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.searchRow}>
        {props.hideSearch ? (
          <></>
        ) : (
          <TextField
            variant="standard"
            id="standard"
            placeholder={`${placeHolder ? placeHolder : 'Search'}`}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onClickSearch();
                ev.preventDefault();
              }
            }}
            onChange={(e) => handleChange(e)}
            value={searchInput}
            classes={{ root: classes.formRoot }}
            InputProps={{
              classes: {
                input: classes.inputText,
              },
              style: { width: inputWidth, maxWidth: '100%' },
              disableUnderline: true,
              startAdornment: (
                <IconButton className={classes.button}>
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: props.hideSearch ? (<></>) : <> {props.isSearching && (
                <IconButton
                  disableRipple={!clickSearch}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    let target = e.target as HTMLElement;
                    if (
                      target.textContent ===
                      'You have entered an invalid search'
                    ) {
                      e.stopPropagation();
                      setClickSearch(false);
                    } else {
                      setClickSearch(true);
                      onClickCancelSearch();
                    }
                  }}
                  className={classes.cancelSearchButton}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              ) }
                <Button
                  className={classes.disabledNextButton}
                  style={searchInput?.length > 0 ? {} : { display: 'none' }}
                  onClick={() => {
                    onClickSearch();
                  }}
                >
                  <span
                    className={classes.disabledText}
                    style={{ opacity: searchInput.length <= 2 ? 0.5 : 1 }}
                  >
                    Search
                  </span>
                </Button>
              </>
            }}
          />
        )}
        {props.hideFilter ? null : (
          <Button
            style={{ marginLeft: 'auto' }}
            startIcon={<FiltersIcon />}
            className={classes.dobButton}
            onClick={filterHandler}
          >
            Filters
          </Button>
        )}
      </div>
    </div>
  );
};
