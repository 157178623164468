import { Container, styled } from '@material-ui/core';
import React, { useLayoutEffect } from 'react';
import { GlobalContext } from '../GlobalContext';

const alertBarHeight = 32;

const Main = styled(Container)(({  }) => ({
  margin: 0,
  padding: 0,
  width: 'none',
  maxWidth: 'none',

  '@media (minWidth: 600px)': {
    maxWidth: 'fit-content',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const MainRegion = (props: {
  children:
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
  header?: any;
  overFlowHidden?: boolean;
  footer?: boolean;
}) => {
  const { isChrome } = React.useContext(GlobalContext);
  useLayoutEffect(() => {
    const alertBar = document.getElementById('alertBar');
    const header = document.getElementById('header');
    const content = document.getElementById('fixed-main-content');
    if (header && content) {
      if (alertBar) {
        content.style.top = header.offsetHeight + alertBarHeight + 'px';
      } else {
        content.style.top = header.offsetHeight + 'px';
      }
    }
    const header2 = document.getElementById('sub-main-header');
    const content2 = document.getElementById('sub-fixed-main-content');
    if (!header2 && !content2 && content) {
      content.style.bottom = '20px';
    }
  }, [isChrome]);

  React.useEffect(() => {
    const content = document.getElementById('fixed-main-content');
    if (content) {
      if (props?.footer) {
        content.style.bottom = '84px';
      } else {
        content.style.bottom = '20px';
      }
    }
  }, [props.footer]);

  return (
    <Main>
      {props.header && (
        <div
          id="header"
          style={{
            position: 'fixed',
            maxWidth: '100%',
            right: 0,
            left: 250,
          }}
        >
          {props.header}
        </div>
      )}
      {props.header ? (
        <div
          id="fixed-main-content"
          style={{
            position: 'fixed',
            maxWidth: '100%',
            bottom: 0,
            overflowY: props.overFlowHidden ? 'hidden' : 'auto',
            right: 0,
            left: 250,
          }}
        >
          {props.children}
        </div>
      ) : (
        <div>{props.children}</div>
      )}
    </Main>
  );
};
