import React, { createContext, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import {
  GlobalContextQuery,
  useGlobalContextLazyQuery,
} from './globalContext.generated';
import { useLocation } from 'react-router';
import { setUser as setRollbarUser } from '../../rollbar';
import { useLatest } from 'react-delta';
import { ToastMessageType } from '../Toaster/Type';
import { useSetUserCredMutation } from '../../scenes/Iclinic/index.generated';
import crypto from 'crypto';
import { config } from '../../config';
import { MixPanelEvents } from '../../mixpanel/events';
import { NotificationAlertMessageType } from '../NotificationAlertMessage';
import { useGetNotificationsLazyQuery } from '../../layouts/MainLayout/components/NotificationCenter/index.generated';

export type User = GlobalContextQuery['me'];
const backendUrl = config.iClinic.backendUrl;
type GlobalContextType = {
  loggedInUser?: User;
  loading: boolean;
  reloadUser: () => void;
  isOpenToast: boolean;
  setIsOpenToast: (input: boolean) => any;
  toastMessage: ToastMessageType;
  setToastMessage: (input: ToastMessageType) => void;
  showNotificationDot: boolean;
  setShowNotificationDot: (input: boolean) => void;
  isSticky: boolean;
  setIsSticky: (input: boolean) => void;
  setUserCredential: (newPassword: string, user?: any) => void;
  isChrome: boolean;
  setIsChrome: (input: boolean) => void;
  openIntegration: boolean;
  setOpenIntegration: (input: boolean) => void;
  openConfiguration: boolean;
  setOpenConfiguration: (input: boolean) => void;
  openNotificationCenter: boolean;
  handleNotificationCenter: (
    event: React.KeyboardEvent | React.MouseEvent
  ) => void;
  isOpenNotificationAlertMessage: boolean;
  setIsOpenNotificationAlertMessage: (input: boolean) => void;
  notificationAlertMessage: NotificationAlertMessageType;
  setNotificationAlertMessage: (input: NotificationAlertMessageType) => void;
};

// global context for the entire app
// starts as empty since it will be initialized with hooks below
export const GlobalContext = createContext<GlobalContextType>(
  {} as GlobalContextType
);

const GlobalContextContainer: React.FC = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [isChrome, setIsChrome] = useState(true);
  const [loadGlobalContext, globalContextResponse] = useGlobalContextLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const location = useLocation();
  const locationRef = useLatest(location);

  const [openIntegration, setOpenIntegration] = React.useState(false);
  const [openConfiguration, setOpenConfiguration] = React.useState(false);

  // for insurance
  const [showNotificationDot, setShowNotificationDot] = React.useState(false);

  // global toaster
  const [isOpenToast, setIsOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState(
    {} as ToastMessageType
  );

  // notification
  const [openNotificationCenter, setOpenNotificationCenter] = React.useState(
    false
  );
  const handleNotificationCenter = (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    // setState({ ...state, [anchor]: open });
    event.stopPropagation();
    setOpenNotificationCenter(!openNotificationCenter);
    setShowNotificationDot(false);
  };
  const [
    isOpenNotificationAlertMessage,
    setIsOpenNotificationAlertMessage,
  ] = React.useState(false);
  const [
    notificationAlertMessage,
    setNotificationAlertMessage,
  ] = React.useState({} as NotificationAlertMessageType);
  const [notificationMessages, setNotificationMessages] = useState<any>([]);
  const [
    notificationQuery,
    notificationQueryResult,
  ] = useGetNotificationsLazyQuery();

  React.useEffect(() => {
    if (
      notificationQueryResult.data &&
      notificationQueryResult.data.getNotifications
    ) {
      const newData = notificationQueryResult.data.getNotifications!.result;
      setNotificationMessages((prev: any) => {
        let key = '';
        const oldItemSet = new Set(
          prev.map((item: any) => JSON.stringify(item))
        );
        const newItemSet = new Set(
          newData.map((item: any) => JSON.stringify(item))
        );
        let differenceItems = new Set(
          [...newItemSet].filter((x) => !oldItemSet.has(x))
        );
        if (differenceItems.size > 0) {
          setShowNotificationDot(true);
          setIsOpenNotificationAlertMessage(true);
          // get each new item and set the notification message
          const groupedItems: any = {};
          let accumulatedPatientCount: any = {};

          newData.forEach((item: any) => {
            const panelId = item.content.panelId;
            const assigneeId = item.assignee.userId;
            const createdAt = item.createdAt.split('T')[0];

            key = `${panelId}-${assigneeId}-${createdAt}`;

            if (!groupedItems[key]) {
              groupedItems[key] = {
                panelId,
                assigneeId,
                createdAt,
                patientCount: 0,
              };
            }

            groupedItems[key].patientCount += item.content.patientCount;
          });

          setNotificationAlertMessage({
            isOpen: true,
            notificationType: 'Panel',
            msgContent: {
              notificationId: newData[0].id,
              panelId: groupedItems[key].panelId,
              patientCount: groupedItems[key].patientCount,
            },
          });
        }

        return newData;
      });
    } else if (notificationQueryResult.error) {
      setNotificationMessages([]);
    }
  }, [notificationQueryResult]);

  React.useEffect(() => {
    // polling
    const interval = setInterval(() => {
      if (loggedInUser && loggedInUser.id) {
        notificationQuery({ variables: { input: { readStatus: 'all' } } });
      }
    }, 1000 * 60 * 1); // 1 minute

    return () => clearInterval(interval);
  }, [loggedInUser, loggedInUser?.id]);

  React.useEffect(() => {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else {
      browserName = 'No browser detection';
    }
    if (browserName !== 'chrome') {
      setIsChrome(false);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const pathName = location.pathname.toLocaleLowerCase();
    if (pathName.indexOf('/assigned') !== -1) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userActivityAndJourneyMapping.assignedPatientPageTime
          .name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.assignedPatientPageTime
          .name
      );
    }
    if (pathName.indexOf('/dashboard') !== -1) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userBehavior.dashboardPageTime.name
      );
    } else {
      MixPanelEvents.track(MixPanelEvents.userBehavior.dashboardPageTime.name);
    }
    if (pathName.indexOf('/summary') !== -1) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userBehavior.panelSummaryPageTime.name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userBehavior.panelSummaryPageTime.name
      );
    }
  }, [location]);

  React.useEffect(() => {
    return Hub.listen('auth', (event) => {
      if (event.payload.event === 'signIn') {
        loadGlobalContext();
      } else if (event.payload.event === 'signOut') {
        setLoggedInUser(undefined);
      }
    });
  }, [loadGlobalContext, locationRef]);

  React.useEffect(() => {
    const loadUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        loadGlobalContext();
      } catch (e) {
        setLoading(false);
        setLoggedInUser(undefined);
        //Auth.signOut();
      }
    };
    loadUser();
  }, [loadGlobalContext]);

  React.useEffect(() => {
    if (globalContextResponse.data) {
      setLoggedInUser(globalContextResponse.data!.me);
      setLoading(false);
    } else if (globalContextResponse.error) {
      setLoggedInUser(undefined);
      setLoading(false);
      //Auth.signOut();
    }
  }, [globalContextResponse]);

  React.useEffect(() => {
    setRollbarUser(loggedInUser?.id ? { userId: loggedInUser.id } : undefined);
    MixPanelEvents.identify(loggedInUser?.email);
    // Register super properties for all events
    MixPanelEvents.register({ user: loggedInUser });
    // Add sign in event
    MixPanelEvents.track(MixPanelEvents.userAdoption.signIn.name);
  }, [loggedInUser]);

  const reloadUser = async () => {
    return globalContextResponse?.refetch && globalContextResponse.refetch();
  };

  const [isSticky, setIsSticky] = React.useState(true);

  const [backendApiKey, setBackendApiKey] = React.useState<string | undefined>(
    undefined
  );
  const [setUserCred] = useSetUserCredMutation();

  const setUserCredential = async (newPassword: string, user?: any) => {
    if (user) {
      let success = await sendNewPwToIClinic(newPassword, user);
      while (!success) {
        success = await sendNewPwToIClinic(newPassword, user);
      }
    } else {
      let success = await sendNewPwToIClinic(newPassword);
      while (!success) {
        success = await sendNewPwToIClinic(newPassword);
      }
    }
  };

  const sendNewPwToIClinic = async (newPassword: string, user?: any) => {
    try {
      const requestOptions: any = {
        method: 'GET',
        redirect: 'follow',
      };
      const response = await fetch(backendUrl!, requestOptions);
      const result = await response.json();
      if (result) {
        setBackendApiKey(atob(result?.data ?? ''));
        const text = JSON.stringify({
          username: user
            ? user?.signInUserSession?.idToken?.payload?.email
            : loggedInUser?.email,
          password: newPassword,
        });
        const enc = crypto
          .publicEncrypt(
            backendApiKey ?? atob(result?.data ?? ''),
            Buffer.from(text)
          )
          .toString('hex');
        const userInternalTargetID =
          'user#' +
          (user
            ? user?.signInUserSession?.idToken?.payload['custom:mdlandUserId']
            : loggedInUser?.id);
        await setUserCred({
          variables: {
            input: {
              userCredential: enc,
              userInternalTargetID: userInternalTargetID,
            },
          },
        });
      }
    } catch (err) {
      return false;
    }
    return true;
  };

  return (
    <GlobalContext.Provider
      value={{
        loggedInUser,
        loading,
        reloadUser: reloadUser,
        isOpenToast,
        setIsOpenToast,
        toastMessage,
        setToastMessage,
        showNotificationDot,
        setShowNotificationDot,
        isSticky,
        setIsSticky,
        isChrome,
        setIsChrome,
        setUserCredential: setUserCredential,
        openIntegration,
        setOpenIntegration,
        openConfiguration,
        setOpenConfiguration,
        openNotificationCenter,
        handleNotificationCenter,
        isOpenNotificationAlertMessage,
        setIsOpenNotificationAlertMessage,
        notificationAlertMessage,
        setNotificationAlertMessage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextContainer;
