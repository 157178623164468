import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { theme } from '../../../../../themes/theme';

const AVATAR_BG = Object.values(theme.palette.avatars);

export const useChangeCoordinatorDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    largeNumberToolTip: {
      position: 'absolute',
      right: theme.spacing(3),
      bottom: 63,
      width: 389,
      height: 'fit-content',
      background: theme.palette.grey[700],
      borderRadius: theme.spacing(1),

      opacity: 0.8,
    },
    largeNumberToolTipText: {
      margin: theme.spacing(1, 1.5, 1, 1.5),
      color: '#FFF',
      fontSize: 12,
      textTransform: 'none',
    },
    root: {
      flexGrow: 1,
    },
    cancelButton: {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.dark,
    },
    mainGrid: {
      margin: theme.spacing(0),
      width: '100%',
      padding: theme.spacing(1.5),
    },
    // reduce classes by using props in theme (master form state)
    stepsContainer: {
      height: '100%',
    },
    paperContainer: {
      boxShadow:
        '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
    },
    cardHeader: {
      height: 72,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      justifyContent: 'space-between',
    },
    cardContent: {
      width: '100%',
      padding: theme.spacing(4, 3),
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    sectionHeader: {
      height: 28,
      borderBottom: '1px solid #d1d1d1',
      color: theme.palette.grey[700],
      fontSize: 20,

      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.4,
      textTransform: 'capitalize',
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.625),
      paddingLeft: theme.spacing(3),
    },
    multipleSelectProviderType: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
      width: '100%',
      // marginTop: theme.spacing(4),
      // marginBottom: theme.spacing(4),
    },
    multipleSelect: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'start',

      // minHeight: 154,
      width: '100%',

      // marginTop: theme.spacing(4),
      // marginBottom: theme.spacing(4),
    },
    selected: {
      backgroundColor: '#EDF6FF!important',
      color: '#0C77D8!important',
    },
    formControl: {
      width: '100%',

      '& .MuiInputBase-root': {
        height: 40,
        borderRadius: 6,
        boxShadow:
          '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      },
    },
    inputTitle: {
      height: 24,
      color: theme.palette.grey[700],
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.7,
      textTransform: 'capitalize',
      marginBottom: theme.spacing(1),
    },
    addMember: {
      height: 24,
      color: theme.palette.grey[700],
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.7,
    },
    assignedCoordinatorContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),

      '& .MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },

      '& li:last-child > div': {
        borderBottom: 'none',
      },
    },
    manageCoordinatorContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
      marginTop: theme.spacing(2),

      '& .MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },

      '& li:last-child > div': {
        borderBottom: 'none',
      },
    },
    assignedCoordinatorContainerEmpty: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    placeholderText: {
      color: theme.palette.grey[400],
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.5,
      paddingTop: theme.spacing(0.875),
      paddingBottom: theme.spacing(1),
    },
    // multiple select with chips of provider tyle
    inputLabel: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(1),
    },
    autoComplete: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: theme.spacing(0.25, 0, 0.25, 1),
        minHeight: 40,
        fontSize: 16,
        borderRadius: 6,
        marginBottom: 24,
        boxShadow:
          '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      },
      '& ::placeholder': {
        color: theme.palette.grey[400],
        opacity: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.secondary.light,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: 'blue',
      },
    },
    chip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 100,
      height: 32,
      marginRight: theme.spacing(0.5),
      '& .MuiChip-deleteIcon': {
        color: 'white',
        height: 20,
        width: 20,
        opacity: 0.5,
      },
    },
    acOption: {
      padding: theme.spacing(0, 1, 0.5, 1),
      backgroundColor: 'white!important',
      '&[aria-selected="true"]': {
        backgroundColor: 'white',
      },
      '& ::hover': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'transparent',
      },
    },
    noAcOption: {
      fontSize: 14,
      fontWeight: 600,
    },
    acMenuRoot: {
      fontSize: 14,
      fontWeight: 600,
      width: '100%',
      height: 56,
      borderRadius: 8,
      position: 'relative',
    },
    // reuse insurance select styles
    insuranceSelect: {
      width: '100%',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    insurancePlaceholderTextSelected: {
      color: theme.palette.grey[600],
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.5,
      paddingTop: theme.spacing(0.875),
      paddingBottom: theme.spacing(1),
    },
    insuranceMenuItem: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    insuranceMenuItemText: {
      height: 24,
      color: theme.palette.grey[600],
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.71,
    },
    selectMenuContent: {
      display: 'block',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    insuranceList: {
      marginTop: theme.spacing(2),
      width: '100%',
      height: 'auto',
      backgroundColor: theme.palette.background.paper,
      background: theme.palette.primary.contrastText,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 8,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),

      '& .MuiListItem-root': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      '& li .MuiMenuItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    insuranceListItem: {
      borderBottom: '1px solid #d1d1d1',
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      '& .MuiIconButton-root': {
        padding: theme.spacing(0),
      },
    },
    insuranceListItemText: {
      height: 24,
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.5,
    },
    insuranceListitemHelper: {
      height: 16,
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    select: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    helperText: {
      color: theme.palette.grey['500'],
    },
    userMenuItem: {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    userList: {
      margin: theme.spacing(4, 'auto'),
      width: '100%',
      height: 'auto',
      backgroundColor: theme.palette.background.paper,
      background: theme.palette.primary.contrastText,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 8,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),

      '& .MuiListItem-root': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      '& li .MuiMenuItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    userListItem: {
      borderBottom: '1px solid #d1d1d1',
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .MuiIconButton-root': {
        padding: theme.spacing(0),
      },
    },
    sectionFooter: {
      position: 'relative',
      height: 72,
      textTransform: 'capitalize',
      borderTop: '1px solid #d1d1d1',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    backBtn: {
      // width: 71,
      height: 40,
      background: theme.palette.grey[200],

      color: theme.palette.grey[600],
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.5,

      textTransform: 'capitalize',

      margin: theme.spacing(1.875, 'auto', 2, 3),

      '&:hover': {
        color: theme.palette.grey[700],
        background: theme.palette.grey[100],
      },
    },
    updateBtn: {
      textTransform: 'capitalize',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 16,
      lineHeight: 1.5,

      background: theme.palette.primary.main,
      borderRadius: 8,
      width: 89,
      height: 40,
      textAlign: 'center',
      padding: theme.spacing(0),
      marginRight: theme.spacing(2.875),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },

      '&:disabled': {
        color: 'rgba(255, 255, 255, 0.6)',
        background: theme.palette.primary.main,
      },
    },
    createBtn: {
      textTransform: 'capitalize',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 16,
      lineHeight: 1.5,

      background: theme.palette.primary.main,
      borderRadius: 8,
      width: 125,
      height: 40,
      textAlign: 'center',
      padding: theme.spacing(0),
      marginRight: theme.spacing(2.875),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },

      '&:disabled': {
        color: 'rgba(255, 255, 255, 0.6)',
        background: theme.palette.primary.main,
      },
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatarStyles: {
      backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
      marginRight: `12px !important`,
      width: `40px !important`,
      height: `40px !important`,
    },
    avatarContent: {
      fontSize: 16,
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    mainContent: {
      display: 'block',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
    subContent: {
      display: 'block',
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.text.hint,
    },
    // care team select
    unassignedRow: {
      marginTop: theme.spacing(0.625),
      // marginBottom: theme.spacing(2.85),
      width: '100%',
      height: 56,
      background: theme.palette.grey[100],
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
    },
    unassignedCircle: {
      width: 40,
      height: 40,
      lineHeight: '40px',
      borderRadius: '50%',
      background: theme.palette.grey[50],
      textAlign: 'center',
      margin: theme.spacing(1, 1.5, 1, 2),

      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
    },
    unassignedText: {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    numPatients: {
      marginRight: theme.spacing(2.5),
      display: 'inline-block',
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 16,
    },
    patientsText: {
      display: 'inline-block',
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    addCareTeamProvider: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: theme.spacing(1.5, 0, 1, 0),

      '& > button.MuiButtonBase-root': {
        width: 24,
        height: 24,
        padding: 0,
        // borderRadius: '50%',
      },
    },
    addCareTeamProviderBtn: {
      marginRight: theme.spacing(1.25),

      '& > .MuiButton-label': {
        fontSize: 18,
        color: theme.palette.primary.contrastText,
      },

      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    addCareTeamProviderText: {
      cursor: 'pointer',
      display: 'inline-block',
      width: 'fit-content',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 14,
    },
    addCareProviderText: {
      cursor: 'pointer',
      display: 'inline-block',
      width: 'fit-content',
      color: '#434343',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '24px',
      paddingLeft: 12,
    },
    errorMessage: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.error.main,
      marginLeft: theme.spacing(3),
    },
    patientOverFlowErrorMessage: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.error.main,
      lineHeight: '20px',
    },
  })
);
