import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const ProcessingIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M7.99862 3.00002C7.95017 3.00107 7.90187 3.00564 7.85409 3.0137H6.01424C5.65361 3.0086 5.31816 3.19807 5.13635 3.50957C4.95455 3.82107 4.95455 4.20633 5.13635 4.51782C5.31816 4.82932 5.65361 5.0188 6.01424 5.0137H7.01424V6.35745C7.01424 7.68298 7.54048 8.95525 8.47909 9.8926L10.6002 12.0137L8.47909 14.1348C7.54048 15.0721 7.01424 16.3444 7.01424 17.6699V19.0137H6.01424C5.65361 19.0086 5.31816 19.1981 5.13635 19.5096C4.95455 19.8211 4.95455 20.2063 5.13635 20.5178C5.31816 20.8293 5.65361 21.0188 6.01424 21.0137H7.84627C7.95427 21.0315 8.06445 21.0315 8.17245 21.0137H15.8463C15.9543 21.0315 16.0645 21.0315 16.1724 21.0137H18.0142C18.3749 21.0188 18.7103 20.8293 18.8921 20.5178C19.0739 20.2063 19.0739 19.8211 18.8921 19.5096C18.7103 19.1981 18.3749 19.0086 18.0142 19.0137H17.0142V17.6699C17.0142 16.3445 16.4867 15.0721 15.5494 14.1348L13.4283 12.0137L15.5494 9.8926C16.4875 8.95455 17.0142 7.68298 17.0142 6.35745V5.0137H18.0142C18.3749 5.0188 18.7103 4.82932 18.8921 4.51782C19.0739 4.20633 19.0739 3.82107 18.8921 3.50957C18.7103 3.19807 18.3749 3.0086 18.0142 3.0137H16.1822C16.0742 2.99585 15.964 2.99585 15.856 3.0137H8.16854C8.11239 3.00431 8.05554 2.99974 7.99862 3.00002ZM9.01424 5.0137H15.0142V6.35745C15.0142 6.95182 14.8376 7.52607 14.5142 8.0137H9.51424C9.19031 7.52649 9.01424 6.95241 9.01424 6.35745V5.0137ZM12.0142 13.4278L14.1353 15.5489C14.698 16.1115 15.0142 16.8754 15.0142 17.6699V19.0137H9.01424V17.6699C9.01424 16.8735 9.32975 16.1135 9.89315 15.5508L12.0142 13.4278Z"
    />
  );
};
