import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 450,
      maxWidth: '80%',
      borderRadius: 8,
    },
    '& .MuiTypography-body1': {
      color: '#656565!important',
      fontSize: 16,
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
    },
  },
  panelDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  panelDetailRow: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    flexDirection: 'row',
    color: '#656565',
    marginBottom: 2,
    fontSize: 14,
    lineHeight: '24px',
    padding: theme.spacing(1),
    borderRadius: 6,
  },
  panelDetailLeft: {
    width: '40%',
    marginLeft: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
  },
  panelDetailRight: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightBold,
    color: '#434343',
    width: '50%',
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
  },
  subText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
  },
  editBtn: {
    width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 125,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(2),
  },
}));

interface CreatePanelConfirmPopUpProps {
  onConfirm: any;
  onClose: any;
  panelName: string;
  totalPatients: number;
  careTeamNumbers: number;
  assignedPatients: number;
  unAssignedPatients: number;
}

export const CreatePanelConfirmPopUp = (
  props: CreatePanelConfirmPopUpProps
) => {
  const [disableEdit, setDisableEdit] = React.useState(false);
  const onClickOnClose = () => {
    if (!disableEdit) {
      props.onClose(false);
    }
  };
  const handleClick = (event: any) => {
    if (event.currentTarget.disabled == false) {
      props.onConfirm();
      setDisableEdit(true);
    }
    event.currentTarget.disabled = true;
  };
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {
        onClickOnClose();
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Create Panel Now?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please confirm</DialogContentText>
        <div className={classes.panelDetailContainer}>
          <div className={classes.panelDetailRow}>
            <div className={classes.panelDetailLeft}>Panel Name</div>
            <div className={classes.panelDetailRight}>{props.panelName}</div>
          </div>
          <div className={classes.panelDetailRow}>
            <div className={classes.panelDetailLeft}>Total Patients</div>
            <div className={classes.panelDetailRight}>
              {props.totalPatients} Patients
            </div>
          </div>
        </div>
      </DialogContent>
      <div className={classes.divider} />
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            autoFocus
            disabled={disableEdit}
            onClick={() => {
              onClickOnClose();
            }}
            className={classes.editBtn}
          >
            Edit
          </Button>
          <Button
            onClick={handleClick}
            className={classes.createNowBtn}
            autoFocus
          >
            Create Now
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
