import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Typography } from '@material-ui/core';
import { GroupAvatar } from '../../../../../../../components/Avatar';
import { matchFilterValuePipe } from './FilterConstant';
import { useFeature } from '../../../../../../../components/FeatureFlag';

interface Props {
  filters: any;
  icd: any;
  cpt: any;
  coverProvider: any;
  payerOptions: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  container: {
    overflowY: 'scroll',
    height: 416,

    '& > div:first-child': {
      paddingTop: theme.spacing(1),
    },
    '& > div:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  filterText: {
    fontWeight: 700,
    fontSize: 12,
    color: theme.palette.grey[500],
    lineHeight: '20px',
  },
  filtersContainer: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 16,
    columnGap: 6,
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 6,
    height: 24,
    '& .MuiChip-label': {
      padding: theme.spacing(0.25, 0.875),
    },
  },
  insuChip: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
  },
  text: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  supportingText: {
    width: 14,
    height: 16,
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    color: '#888888',
    marginRight: 5,
  },
  filterSection: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    width: '95%',
  },
}));

function getPayerName(payerIds: string[], payers: any[]) {
  if (payers.length < 1) {
    return payerIds;
  }
  return payerIds.map(
    (payerid: string) =>
      `${payerid}-${payers.find((payer: any) => payer.value == payerid)?.name}`
  );
}
function compareAgeRanges(a: any, b: any) {
  try {
    const aValue = a.split(',')[0];
    const bValue = b.split(',')[0];
    const diff = aValue - bValue;
    if (diff < 0) {
      return -1;
    }
    if (diff > 0) {
      return 1;
    }
    return 0;
  } catch (e) {
    return 0;
  }
}

export const FilterDetailsCard = (props: Props) => {
  const classes = useStyles();
  // const fakeInsu = [{id:0, name:'4 ever life insurance company'},{id:1, name:'4 ever life insurance company'}];
  const hasFeature = useFeature('coordination');
  const useFeatureDivision = useFeature('division');

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/* Clinic ID */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>Clinic ID</Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.clinicId?.length ? (
              props.filters.patientRequirements.clinicId.map(
                (clinicId: any, idx: number) => (
                  <Chip
                    className={classes.chip}
                    label={clinicId}
                    key={`${clinicId}-${idx}`}
                  />
                )
              )
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        {/* Division */}
        {useFeatureDivision ? (
          <>
            <div className={classes.divider} />
            <div className={classes.filterSection}>
              <Typography className={classes.filterText}>Division</Typography>
              <div className={classes.filtersContainer}>
                {props.filters.patientRequirements.division?.length ? (
                  props.filters.patientRequirements.division.map(
                    (division: any, idx: number) => (
                      <Chip
                        className={classes.chip}
                        label={division}
                        key={`${division}-${idx}`}
                      />
                    )
                  )
                ) : (
                  <Chip className={classes.chip} label={'All'} />
                )}
              </div>
            </div>
          </>
        ) : null}

        <div className={classes.divider} />
        {/* Open Care Gaps */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>Open Care Gaps</Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.openMeasures?.length ? (
              props.filters.patientRequirements.openMeasures.map(
                (gap: any, idx: number) => (
                  <Chip
                    className={classes.chip}
                    label={gap == 'ALL' ? 'All' : gap}
                    key={`${gap}-${idx}`}
                  />
                )
              )
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* Payers */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>Payers </Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.payerIds?.length ? (
              getPayerName(
                props.filters.patientRequirements.payerIds,
                props.payerOptions
              ).map((payerId: any, idx: number) => (
                <Chip
                  className={classes.chip}
                  label={payerId}
                  key={`${payerId}-${idx}`}
                />
              ))
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* Line of Businesses */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>
            Line of Businesses
          </Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.lineOfBusiness?.length ? (
              props.filters.patientRequirements.lineOfBusiness.map(
                (lob: any, idx: number) => (
                  <Chip
                    className={classes.chip}
                    label={lob}
                    key={`${lob}-${idx}`}
                  />
                )
              )
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* Diseases */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>Diseases</Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.chronicDisease?.length ? (
              props.filters.patientRequirements.chronicDisease.map(
                (disease: any, idx: number) => (
                  <Chip
                    className={classes.chip}
                    label={disease}
                    key={`${disease}-${idx}`}
                  />
                )
              )
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* ICD */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>ICD</Typography>
          <div className={classes.filtersContainer}>
            {props.icd?.length ? (
              props.icd.map((icds: any, idx: number) => (
                <Chip
                  className={classes.chip}
                  label={icds}
                  key={`${icds}-${idx}`}
                />
              ))
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* CPT */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>CPT</Typography>
          <div className={classes.filtersContainer}>
            {props.cpt?.length ? (
              props.cpt.map((cpts: any, idx: number) => (
                <Chip
                  className={classes.chip}
                  label={cpts}
                  key={`${cpts}-${idx}`}
                />
              ))
            ) : (
              <Chip className={classes.chip} label={'All'} />
            )}
          </div>
        </div>

        {/* Covering Providers */}
        {hasFeature ? (
          <>
            <div className={classes.divider} />
            <div className={classes.filterSection}>
              <Typography className={classes.filterText}>
                Covering Providers
              </Typography>
              <div className={classes.filtersContainer}>
                {props.coverProvider?.length ? (
                  // <div style={{ paddingTop: 15 }}>
                  <GroupAvatar data={props.coverProvider ?? []} maxNum={3} />
                ) : (
                  // </div>
                  <Chip className={classes.chip} label={'None'} />
                )}
              </div>
            </div>
          </>
        ) : null}

        <div className={classes.divider} />
        {/* Age & Gender */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>Age & Gender</Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.ageRanges?.length
              ? props.filters.patientRequirements.ageRanges
                  .sort(compareAgeRanges)
                  .map((age: any, idx: number) => {
                    return (
                      <Chip
                        className={classes.chip}
                        label={matchFilterValuePipe(age, 'Age')}
                        key={`${age}-${idx}`}
                      />
                    );
                  })
              : null}
            {props.filters.patientRequirements.gender?.length
              ? props.filters.patientRequirements.gender.map(
                  (gender: any, idx: number) => (
                    <Chip
                      className={classes.chip}
                      label={gender}
                      key={`${gender}-${idx}`}
                    />
                  )
                )
              : null}
            {!props.filters.patientRequirements.gender?.length &&
              !props.filters.patientRequirements.ageRanges?.length && (
                <Chip className={classes.chip} label={'All'} />
              )}
          </div>
        </div>

        <div className={classes.divider} />
        {/* Last Day of Service */}
        <div className={classes.filterSection}>
          <Typography className={classes.filterText}>
            Last Day of Service
          </Typography>
          <div className={classes.filtersContainer}>
            {props.filters.patientRequirements.lastEncounterFrom == '' ? (
              <Chip className={classes.chip} label={'None'} />
            ) : (
              <Chip
                className={classes.chip}
                label={props.filters.patientRequirements.lastEncounterFrom}
              />
            )}
            <span className={classes.supportingText}>To</span>
            {props.filters.patientRequirements.lastEncounterTo == '' ? (
              <Chip className={classes.chip} label={'None'} />
            ) : (
              <Chip
                className={classes.chip}
                label={props.filters.patientRequirements.lastEncounterTo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
