import React from 'react';
import { PanelPatient } from '../../../../../app.types.generated';

export const useCurrentPagePatients = (results: PanelPatient[]) => {
  const [
    currentPageFirstAndLastPatient,
    setCurrentPageFirstAndLastPatient,
  ] = React.useState<PanelPatient[]>([]);

  React.useEffect(() => {
    if (!results || results.length === 0) {
      return;
    }
    setCurrentPageFirstAndLastPatient([
      results[0],
      results[results.length - 1],
    ]);
  }, [results]);

  return { currentPageFirstAndLastPatient };
};
