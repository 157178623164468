import React, { useContext } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { PatientsIcon } from '../../../../../../assets/Icons/Dashboard/PatientsIcon';
import { EligibilityIcon } from '../../../../../../assets/Icons/Dashboard/EligibilityIcon';
import { CardChildren } from '../../../../../../components/CardContainer';
import { CardRatio } from '../../../../../DashBoard/components/CardRatio';
import { GlobalContext } from '../../../../../../components/GlobalContext';
import { CardPCPList } from '../../../../../DashBoard/components/CardPCPList';
import { FilterDetailsCard } from './components/FilterDetailsCard';
import { formatNumberPipe } from '../../../../../../sharePipe/formatNumberPipe';
import { RoleType } from '../../../../../../enum/Users';
import { ResendIcon } from '../../../../../../assets/Icons/PanelSummary';
import { CoordinationProgressChart } from '../../../../../../components/Charts/StackedBarCharts/CoordinatorProgressChart';
import { useNavigate, useParams } from 'react-router';
import { theme } from '../../../../../../themes/theme';
import { useDailyCoordinationData } from '../../../../../../hooks/useDailyCoordinationData';
import { useFeature } from '../../../../../../components/FeatureFlag';

interface Props {
  panel: any;
  patientCount: any;
  payersOption: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
  },
  subClass: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  updatedTimeAndIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  updatedIcon: { width: 20, height: 20, color: theme.palette.grey[300] },
}));
const useNewStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[50],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: theme.palette.grey[700],
    height: 40,
  },
}));

const barChartHeaderStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  headerText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    color: theme.palette.grey[700],
    paddingLeft: 24,
  },
}));

export const SummaryView = (props: Props) => {
  const classes = useStyles();
  const classesNew = useNewStyles();
  const { loggedInUser } = useContext(GlobalContext);

  const { updatedAt, filters } = props.panel;
  const hasFeature = useFeature('coordination');
  const calculatePercentageOfAssignedPatients = () => {
    if (
      props.panel.assignedPatientCount !== undefined &&
      props.panel.patientCount !== undefined
    ) {
      if (props.panel.patientCount !== 0) {
        return `${Math.round(
          (props.panel.assignedPatientCount / props.panel.patientCount) * 100
        )}%`;
      }
    } else {
      return '-';
    }
  };

  const [containerWidth, setContainerWidth] = React.useState(0);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (anchorRef.current) {
      setContainerWidth(anchorRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    function handleWindowResize() {
      if (anchorRef.current) {
        setContainerWidth(anchorRef.current.offsetWidth);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const barChartHeaderClasses = barChartHeaderStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { dailyCoordinationProgressData } = useDailyCoordinationData(id!);
  const memorizedAdminCards = React.useMemo(() => {
    return (
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <CardChildren
          headerStyle={classesNew}
          title={'Patient Filters'}
          leftFooter={<div>Updated</div>}
          rightFooter={
            <div className={classes.updatedTimeAndIconContainer}>
              {moment(updatedAt).fromNow()}{' '}
              <ResendIcon className={classes.updatedIcon} />
            </div>
          }
          children={
            <FilterDetailsCard
              filters={filters}
              icd={
                props.panel?.filters?.patientRequirements?.hasAnyIcdCode ?? []
              }
              cpt={
                props.panel?.filters?.patientRequirements?.hasAnyCptCode ?? []
              }
              coverProvider={props.panel?.pcps ?? []}
              payerOptions={props.payersOption}
            />
          }
          height={512}
          width={4}
        />
        {hasFeature ? (
          <CardChildren
            backdrop={false}
            backdropContent={'New Feature Coming Soon!'}
            title={'Panel Coordination Progress'}
            showHistory={false}
            headerStyle={barChartHeaderClasses}
            children={
              <CoordinationProgressChart
                height={430}
                data={dailyCoordinationProgressData}
              />
            }
            height={512}
            width={8}
          />
        ) : null}
        <CardChildren
          title={'Panel Compliance Rate'}
          backdrop={hasFeature != true}
          children={
            <div
              ref={anchorRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 512,
              }}
            >
              <CardRatio
                type="percentage"
                topPadding
                icon={EligibilityIcon}
                currentPct={props.panel.complianceRate}
                subtitle={``}
                stayHeight={true}
              />
              <CardPCPList
                containerWidth={containerWidth}
                listType="PCP"
                list={props.panel.pcps || []}
              />
            </div>
          }
          height={512}
          width={4}
        />
        <Grid item container xs={4}>
          {loggedInUser!.role?.roleName === RoleType.CARE_COORDINATOR ? null : (
            <>
              <CardChildren
                title={'Assigned Care Coordinators'}
                showHistory={false}
                children={
                  <div
                    ref={anchorRef}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: 412,
                    }}
                  >
                    <CardRatio
                      type="ratio"
                      topPadding
                      icon={PatientsIcon}
                      numeratorText={formatNumberPipe(
                        props.panel.assignedPatientCount,
                        '0'
                      )}
                      denominatorText={formatNumberPipe(
                        props.panel.patientCount,
                        '0'
                      )}
                      subtitle={`${calculatePercentageOfAssignedPatients()} Patients Assigned`}
                    />
                    <CardPCPList
                      containerWidth={containerWidth}
                      listType="CARE_TEAM"
                      list={props.panel.careTeamUsers || []}
                    />
                  </div>
                }
                height={512}
                width={12}
                style={{ marginBottom: 24 }}
                rightFooter={
                  <div
                    style={{
                      cursor: 'pointer',
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                    onClick={() => {
                      navigate(`/panels/${id}/assigned`);
                    }}
                  >
                    View All
                  </div>
                }
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  }, [loggedInUser, dailyCoordinationProgressData]);

  return (
    <div className={classes.root}>
      <div className={classes.subClass}>{memorizedAdminCards}</div>
    </div>
  );
};
