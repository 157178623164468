import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDisabledOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import {
  ChineseSimplifiedDefaultMessage,
  ChineseSimplifiedDefaultPlaceholder,
  ChineseTraditionalDefaultMessage,
  ChineseTraditionalDefaultPlaceholder,
  EnglishDefaultMessage,
  EnglishDefaultPlaceholder,
} from '../../../Panels/ViewPanels/components/EngagementDialog/SmsDefaultMessageConstant';
import {CustomSmsTextField, getSmsMessageLength, SmsMaxLength} from '../../../../components/CustomSmsTextField';
import { CustomSelectWithChips } from '../../../../components/CustomSelectWithChips';
import { SmsLanguage } from '../../../Panels/ViewPanels/components/EngagementDialog/SmsOptions';
import { sortArrayBaseOnObjectArrayPipe } from '../../../../sharePipe/sortArrayBaseOnObjectArrayPipe';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 549,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D1D1D1',
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
  },

  editBtn: {
    width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 125,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  previewNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 160,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  conFirmAndSendBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 171,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
  },
  errorLabel: {
    fontSize: 12,
    color: '#B7373E',
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    maxHeight: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    // color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholderTextSelected: {
    // color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  previewTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#FFFFFF',
    borderRadius: '8px',
    border: ' 1px solid #C4C4C4',
  },
  onPreviewTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#FFFFFF',
    borderRadius: '8px',
    border: ' 1px solid #C4C4C4',
  },
  dummyMessage: {
    position: 'absolute',
    top: 30,
    color: '#656565',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    width: 450,
    left: 12,
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
  },
  messageContainer: {
    position: 'relative',
    width: '100%',
  },
}));

interface DialogProps {
  onClose: any;
  onSubmit: any;
  errorMessage: string;
  cacheFilter: any;
}

export const CreateTemplateDialog = (props: DialogProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useDisabledOutlinedInputStyles();
  const [selectedLanguages, setSelectLanguages] = React.useState(['English']);
  const [customEngMessage, setCustomEngMessage] = React.useState('');
  const [customChnSmpMessage, setCustomChnSmpMessage] = React.useState('');
  const [customChnTradMessage, setCustomChnTradMessage] = React.useState('');
  const selectedSmsType = 'Custom Message';
  const [templateName, setTemplateName] = React.useState('');
  const onPreview = false;

  React.useEffect(() => {
    if (props.cacheFilter != null) {
      setTemplateName(props.cacheFilter.name);
      const temp: any = [];
      props.cacheFilter?.contents?.forEach((lan: any) => {
        if (lan?.language == 'en') {
          temp.push('English');
          setCustomEngMessage(decodeURIComponent(atob(lan?.content)));
        }
        if (lan?.language == 'cns') {
          temp.push('Chinese (S)');
          setCustomChnSmpMessage(decodeURIComponent(atob(lan?.content)));
        }
        if (lan?.language == 'cnt') {
          temp.push('Chinese (T)');
          setCustomChnTradMessage(decodeURIComponent(atob(lan?.content)));
        }
      });
      setSelectLanguages(temp);
    }
  }, []);

  function getLangZeroError(): boolean {
    for (const lang of selectedLanguages) {
      switch (lang) {
        case 'English':
          if (customEngMessage.length == 0) {
            return true;
          }
          continue;
        case 'Chinese (S)':
          if (customChnSmpMessage.length == 0) {
            return true;
          }
          continue;
        case 'Chinese (T)':
          if (customChnTradMessage.length == 0) {
            return true;
          }
      }
    }
    return false;
  }

  function getOverFlowError(): boolean {
    for (const lang of selectedLanguages) {
      switch (lang) {
        case 'English':
          if (getSmsMessageLength(customEngMessage) > SmsMaxLength ) {
            return true
          }
          continue;
        case 'Chinese (S)':
          if (getSmsMessageLength(customChnSmpMessage) > SmsMaxLength ) {
            return true
          }
          continue;
        case 'Chinese (T)':
          if (getSmsMessageLength(customChnTradMessage) > SmsMaxLength ) {
            return true
          }
      }
    }
    return false;
  }

  const handleChange = (event: any) => {
    setTemplateName(event.target.value);
  };

  const onSubmit = async () => {
    props.onSubmit(
      templateName,
      selectedLanguages,
      customEngMessage,
      customChnSmpMessage,
      customChnTradMessage
    );
  };

  const getCustomTextField = (language: any, isLast: boolean) => {
    switch (language) {
      case 'English':
        return (
          <CustomSmsTextField
            title={'English-Default:'}
            defaultText={EnglishDefaultMessage}
            rawText={customEngMessage}
            setRawText={setCustomEngMessage}
            id={'engTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={EnglishDefaultPlaceholder}
            customPlaceHolder={'Place make an appointment with your provider.'}
            onPreview={onPreview}
            languages={selectedLanguages}
          />
        );
      case 'Chinese (S)':
        return (
          <CustomSmsTextField
            title={'Chinese(Simplified):'}
            defaultText={ChineseSimplifiedDefaultMessage}
            rawText={customChnSmpMessage}
            setRawText={setCustomChnSmpMessage}
            id={'chnSmpTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={ChineseSimplifiedDefaultPlaceholder}
            customPlaceHolder={'编辑信息内容'}
            onPreview={onPreview}
            languages={selectedLanguages}
          />
        );
      case 'Chinese (T)':
        return (
          <CustomSmsTextField
            title={'Chinese(Traditional):'}
            defaultText={ChineseTraditionalDefaultMessage}
            rawText={customChnTradMessage}
            setRawText={setCustomChnTradMessage}
            id={'chnTradTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={ChineseTraditionalDefaultPlaceholder}
            customPlaceHolder={'編輯信息內容'}
            onPreview={onPreview}
            languages={selectedLanguages}
          />
        );
    }
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {
        props.onClose(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Create SMS Template'}
        <span></span>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography className={classes.inputLabel}>Template Name</Typography>
          <OutlinedInput
            fullWidth
            autoFocus
            value={templateName}
            onChange={handleChange}
            className={classes.nameInput}
            placeholder="Add template name"
            classes={outlinedInputClasses}
            error={props.errorMessage != ''}
          />
          {props.errorMessage != '' ? (
            <Typography className={classes.errorLabel}>
              {props.errorMessage}
            </Typography>
          ) : null}
          <CustomSelectWithChips
            state={selectedLanguages}
            setState={(languages: string[]) => {
              const diffLanguages = selectedLanguages.filter((x) => languages.indexOf(x) === -1)
              if (diffLanguages && diffLanguages.length) { // reduce
                diffLanguages.forEach((l) => {
                  if (l === 'English') {
                    setCustomEngMessage("")
                  }
                  if (l === 'Chinese (S)') {
                    setCustomChnSmpMessage("")
                  }
                  if (l === 'Chinese (T)') {
                    setCustomChnTradMessage("")
                  }
                })
              }
              setSelectLanguages(languages)
            }}
            isMultiple={true}
            inputTitle={'Add Languages'}
            placeholder={'All'}
            fullWidth={true}
            items={SmsLanguage}
            isObject={true}
          />
          <Typography className={classes.subLabel}>
            SMS will be sent in the patient's preferred languages or default in
            English.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.inputLabel}>
            Template Content
          </Typography>
          <Typography className={classes.subLabel}>
            Use <mark>#</mark> to insert names or links.
          </Typography>
          <div className={classes.previewTextAreaContainer}>
            {sortArrayBaseOnObjectArrayPipe(
              selectedLanguages,
              SmsLanguage,
              'asc'
            ).map((item, idx) =>
              getCustomTextField(item, idx == selectedLanguages.length - 1)
            )}
          </div>
          {getLangZeroError() && (
            <Typography className={classes.errorLabel}>
              Please add message content for all languages you selected.
            </Typography>
          )}
          {getOverFlowError() && (
            <Typography className={classes.errorLabel}>
              {`SMS Message cannot exceed ${SmsMaxLength} characters(4 SMS).`}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <div className={classes.divider} />
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            autoFocus
            onClick={() => {
              props.onClose(false);
            }}
            className={classes.editBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            className={classes.previewNowBtn}
            autoFocus
            disabled={getOverFlowError() || getLangZeroError()}
          >
            Create Template
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
