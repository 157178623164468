import {
  Menu,
  MenuItem,
  MenuProps,
  fade,
  makeStyles,
  styled,
} from '@material-ui/core';
import { theme } from '../../../../../../../../../themes/theme';
import { SnoozeIcon } from '../../../../../../../../../assets/Icons/CareGaps/SnoozeIcon';
import { CareCoordinationOptionType } from '../../../../hooks/useCareCoordinationOption';

interface CareCoordinationMenuProps {
  anchorEl: HTMLElement | null;
  openMenu: boolean;
  handleClose: () => void;
  handleOption: (event: any) => void;
  CareCoordinationOptions: CareCoordinationOptionType[];
}

export const CareCoordinationMenu = (props: CareCoordinationMenuProps) => {
  const {
    anchorEl,
    openMenu,
    handleClose,
    handleOption,
    CareCoordinationOptions,
  } = props;
  const classes = useStyles();
  const disabledItems: string[] = [];
  const iconItems = [
    { id: 'Snooze', icon: <SnoozeIcon /> },
    { id: 'Edit Snooze', icon: <SnoozeIcon /> },
  ];
  return (
    <div>
      <StyledMenu
        id={`care-coordinator-option-menu`}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        className={classes.menu}
      >
        {CareCoordinationOptions.map((option, index) => {
          return (
            <MenuItem
              key={`${option.name}-${index}`}
              onClick={handleOption}
              disableRipple
              disabled={disabledItems.includes(option.value)}
              style={{
                borderBottom:
                  index === CareCoordinationOptions.length - 2
                    ? '1px solid #D1D1D1'
                    : 'none',
              }}
            >
              <span
                style={{
                  opacity: disabledItems.includes(option.value) ? 0.5 : 1,
                }}
              >
                {option.name}
              </span>
              {iconItems.find((item) => item.id === option.value) ? (
                <span className={classes.itemIcon}>
                  {iconItems.find((item) => item.id === option.value)!.icon}
                </span>
              ) : null}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    PaperProps={{
      style: {
        transform: 'translateY(9px)',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    width: 'fit-content',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 20px',
      width: '100%',
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0px',
      lineHeight: '24px',
    },
  },
}));

const useStyles = makeStyles({
  menu: {
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: 4,

      padding: `${theme.spacing(1, 0)}!important`,
    },
    '& li': {
      justifyContent: 'space-between',
    },
    '& li:last-child button': {
      margin: 0,
      padding: 0,
      width: 24,
      height: 24,

      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
  },
  itemIcon: {
    width: 24,
    height: 24,
    color: theme.palette.grey[500],
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
});
