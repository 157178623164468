import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorView } from './View';
import rollbar from '../../rollbar';

const errorHandler = (error: Error, info: { componentStack: string }) => {
  if (process.env.NODE_ENV !== 'production') {
    rollbar.error(error);
  }
};

export const ErrorBoundary: React.FC = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorView} onError={errorHandler}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
