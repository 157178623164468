import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BreadCrumbHeader } from '../../../components/BreadCrumbHeader';
import { MainRegion } from '../../../components/MainRegion';
import { preUrlHandler } from '../../../sharePipe/getPreviousUrlPip';
import { ProfileOptionTabs } from '../components/ProfileOptionTabs';
import { useMyProfileTabsStyles } from './Styles';
import { ChangePasswordForm } from './tabs/ChangePassword';
import { EditProfileForm } from './tabs/EditProfile';

interface MyProfileHomeViewProps {
  selectedTab: number;
}

export const MyProfileHomeView = (props: MyProfileHomeViewProps) => {
  const { selectedTab } = props;
  const classes = useMyProfileTabsStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const passingData = location.state as any;

  const [value, setValue] = React.useState(selectedTab);

  React.useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    const text = (event.currentTarget as HTMLElement).textContent?.toLowerCase();
    if (text === 'edit profile') {
      navigate('/profile/edit', {
        state: { preUrl: preUrlHandler(location.pathname) },
      });
    }
    if (text === 'change password') {
      navigate('/profile/change_password', {
        state: { preUrl: preUrlHandler(location.pathname) },
      });
    }
    // setValue(newValue);
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `profile-option-tabs-${index}`,
    };
  }

  return (
    <MainRegion
      header={
        <Grid container className={classes.root}>
          <BreadCrumbHeader
            rootLink={(passingData && passingData.preUrl) || '/dashboard'}
            rootName={'Back'}
            childrenName={'Profile Settings'}
            isTitle={true}
            title={'Profile Settings'}
            isButton={true}
            renderButton={
              <Button
                onClick={() =>
                  navigate((passingData && passingData.preUrl) || '/dashboard')
                }
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            }
          />
        </Grid>
      }
    >
      <Grid item xs={12}>
        <Grid container spacing={8} className={classes.mainGrid}>
          {/* profile option tabs */}
          <Grid item xs={2} container>
            <ProfileOptionTabs
              value={value}
              handleTabChange={handleTabChange}
              a11yProps={a11yProps}
            />
          </Grid>
          {/* spacing */}
          <Grid item xs={1} />

          {/* forms */}
          <Grid item xs={9}>
            {value === 0 && <EditProfileForm />}
            {value === 1 && <ChangePasswordForm />}
          </Grid>
        </Grid>
      </Grid>
    </MainRegion>
  );
};
