import PatientEngagementTableView from "./View";
import {LoadingSpinner} from "../../../../../../components/LoadingSpinner";
import React from "react";
import moment from 'moment'
import {EmptyTabContent} from "../../../../components/EmptyTabContent";
import {ChartIcon} from "../../../../../../assets/Icons/PanelsList";
import {CheckIsAdmin, CheckIsCareManager} from "../../../../../../components/CheckIsAdmin";
import {GlobalContext} from "../../../../../../components/GlobalContext";
import {makeStyles, Typography} from "@material-ui/core";
import {useGetPatientEngagementBatchsQuery} from "./index.generated";

export enum EngagementLogStatus {
  sending = "Sending",
  failed = "Failed",
  partialDelivered = "PartialDelivered",
  delivered = "Delivered",
}

export interface PatientEngagement {
  batchId: string,
  name: string,
  type: string,
  templateName: string,
  patientCount: number,
  sendBy: string,
  shortName: string,
  status: EngagementLogStatus,
  statusDescription: string,
  lastUpdated: string
}

export interface IPatientEngagementTableProps {
  limit?: number
  skip?: number
  setLimit: (input: number) => void
  setSkip: (input: number) => void
  panelId: string
}

export function PatientEngagementTableContainer(props: IPatientEngagementTableProps) {
  const {panelId, setLimit, setSkip, limit, skip,} = props
  const { loggedInUser } = React.useContext(GlobalContext);
  const classes = useStyles()

  const engagementBatchs = useGetPatientEngagementBatchsQuery({
    variables: {
      input: {
        limit: limit ?? 10,
        skip: skip ?? 0,
        panelId: panelId,
      },
    },
  });

  if (!engagementBatchs.called || engagementBatchs.loading) {
    return <LoadingSpinner style={{ height: 'calc(100vh - 235px' }} />
  }

  const getInitials = (input: string) => {
    if (!input.length) {
      return ""
    }
    let names = input.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const parseResultToViewModel = () => {
    let result
    if (!engagementBatchs.error && engagementBatchs.data) {
      result = engagementBatchs.data!.getPatientEngagementBatchs!.results
    } else {
      result = engagementBatchs.previousData!.getPatientEngagementBatchs!.results
    }
    const data: Array<PatientEngagement> = result!.map((item: any) => {
      const {
        id,
        updated_date,
        message_title,
        patients_count,
        send_by,
        engagement_status,
        engagement_status_desc,
        message_type,
        message_template_name
      } = item

      const lastUpdated = moment(new Date(updated_date)).format("MMMM DD, hh:mm A")
      const shortName = getInitials(send_by)
      const templateName  = message_type === 'Template' ? (message_template_name && message_template_name.length ? message_template_name : "Template") : "Custom Message"
      const d: PatientEngagement = {
        batchId: id,
        lastUpdated: lastUpdated,
        name: message_title,
        patientCount: patients_count,
        sendBy: send_by,
        shortName: shortName,
        status: engagement_status,
        statusDescription: engagement_status_desc,
        type: message_type,
        templateName: templateName,
      }
      return d
    })

    return data
  }

  const results = engagementBatchs.data!.getPatientEngagementBatchs!.results
  const pageInfo = engagementBatchs.data!.getPatientEngagementBatchs!.pagingInfo
  const infoLimit = results ? (pageInfo!.limit ?? 0) : 0
  const infoSkip = results ? (pageInfo!.skip ?? 0) : 0
  const infoCount = results ? (pageInfo!.count ?? 0) : 0
  const dataSource = parseResultToViewModel()

  if (!dataSource.length) {
    return (
      <EmptyTabContent
        hintIcon={<ChartIcon className={classes.hintIcon} />}
        hintTitle={'No Batch Record Yet'}
        hintDesc={
          <div>
            <Typography>
              There is no Batch SMS Log for this panel yet.
            </Typography>
            <Typography>
              Click the button below to view patients and start engagement
            </Typography>
          </div>
        }
        btnUrl={`/panels/${panelId}/assigned`}
        btnText={
          CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)
            ? 'View Assigned Patients'
            : 'View My Patients'
        }
      />
    );
  }
  return (
    <PatientEngagementTableView
      panelId={panelId}
      count={infoCount}
      setSkip={setSkip}
      setLimit={setLimit}
      skip={infoSkip}
      limit={infoLimit}
      dataSource={dataSource}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  hintIcon: {
    width: 60,
    height: 60,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2.125),
  },
}))
