import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../themes/theme';
import { CardChildren } from '../../../../../components/CardContainer';
import { useNavigate } from 'react-router';
import { CardRatio } from '../../../components/CardRatio';
import { PatientsIcon } from '../../../../../assets/Icons/Dashboard/PatientsIcon';
import { EligibilityIcon } from '../../../../../assets/Icons/Dashboard/EligibilityIcon';
import { CardPCPList } from '../../../components/CardPCPList';
import { PanelsIcon } from '../../../../../assets/Icons/SideNavigation';
import { Maybe } from '../../../../../app.types.generated';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { Grid } from '@material-ui/core';
import { formatNumberPipe } from '../../../../../sharePipe/formatNumberPipe';
import { CoordinationProgressChart } from '../../../../../components/Charts/StackedBarCharts/CoordinatorProgressChart';
import { useFeature } from '../../../../../components/FeatureFlag';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.light,
  },
  subClass: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      padding: theme.spacing(1.5),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  customGrid: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 16px 1fr 16px 1fr',
    gridTemplateRows: 'repeat(2, 288px)',
    paddingLeft: 8,
    PaddingRight: 8,
  },
  largeGridItem: {
    gridColumnStart: 1,
    gridColumnEnd: 4,
  },
}));

const barChartHeaderStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  headerText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    color: theme.palette.grey[700],
    paddingLeft: 24,
  },
}));

interface DashBoardTabViewProps {
  numOfPatients: Maybe<number> | undefined;
  numOfAverageCompliance: Maybe<number> | undefined;
  numOfPanels: Maybe<number> | undefined;
  providerAvePerformance: Maybe<number> | undefined;
  providers: any;
  dailyEngagedPatientsAndClosedGaps: any;
  dailyCoordinationProgressData: any;
}

export const CCDashBoardTabView = (props: DashBoardTabViewProps) => {
  const {
    numOfPatients,
    numOfAverageCompliance,
    numOfPanels: numOfPanels,
    providerAvePerformance,
    providers,
    dailyCoordinationProgressData,
  } = props;

  const classes = useStyles();
  const barChartHeaderClasses = barChartHeaderStyles();
  const navigate = useNavigate();

  const [containerWidth, setContainerWidth] = React.useState(0);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  // const hasFeature = useFeature('eligibilityURL');
  const useFeatureCoveringProvider = useFeature('coordination');

  React.useLayoutEffect(() => {
    if (anchorRef.current) {
      setContainerWidth(anchorRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    function handleWindowResize() {
      if (anchorRef.current) {
        setContainerWidth(anchorRef.current.offsetWidth);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.subClass}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <CardChildren
            title={'My Patients'}
            showHistory={false}
            children={
              numOfPatients !== null && numOfPatients !== undefined ? (
                <CardRatio
                  type="ratio"
                  topPadding
                  icon={PatientsIcon}
                  numeratorText={formatNumberPipe(numOfPatients, 'Loading')}
                  denominatorText={''}
                  subtitle={``}
                  ratioDisable={true}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            height={244}
            width={4}
          />
          <CardChildren
            title={'My Average Gap Compliance'}
            showHistory={false}
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/care_gaps');
                }}
              >
                View All
              </div>
            }
            children={
              numOfAverageCompliance !== null &&
              numOfAverageCompliance !== undefined ? (
                <CardRatio
                  type="percentage"
                  topPadding
                  icon={EligibilityIcon}
                  averageCompliance={
                    formatNumberPipe(numOfAverageCompliance, 'Loading') ===
                    'Loading'
                      ? 'Loading'
                      : `${formatNumberPipe(numOfAverageCompliance, '0')}%`
                  }
                  subtitle={``}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            height={244}
            width={4}
          />

          <CardChildren
            title={'My Patient Panels'}
            showHistory={false}
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/panels');
                }}
              >
                View All
              </div>
            }
            children={
              numOfPanels !== null && numOfPanels != undefined ? (
                <CardRatio
                  type="icon"
                  topPadding
                  icon={PanelsIcon}
                  iconText={formatNumberPipe(numOfPanels, 'Loading')}
                  subtitle={''}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            height={244}
            width={4}
          />
          {/* <div /> */}
          <CardChildren
            title={'My Provider Performance'}
            backdrop={useFeatureCoveringProvider != true}
            showHistory={false}
            leftFooter={''}
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/pcp');
                }}
              >
                View All
              </div>
            }
            children={
              <div
                ref={anchorRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 336,
                }}
              >
                {providers ? (
                  <>
                    <CardRatio
                      type="percentage"
                      icon={EligibilityIcon}
                      currentPct={providerAvePerformance ?? 0}
                      subtitle={`Average of ${providers.length ?? 0} Providers`}
                    />
                    <CardPCPList
                      containerWidth={containerWidth}
                      listType="PCP"
                      list={providers}
                    />
                  </>
                ) : (
                  <>
                    <LoadingSpinner style={{ width: '100%' }} />
                  </>
                )}
              </div>
            }
            height={440}
            width={4}
          />

          <CardChildren
            backdrop={false}
            backdropContent={'New Feature Coming Soon!'}
            title={'Daily Coordination Progress Across Organization'}
            showHistory={false}
            headerStyle={barChartHeaderClasses}
            children={
              <CoordinationProgressChart
                height={360}
                data={dailyCoordinationProgressData}
              />
            }
            height={440}
            width={8}
          />
        </Grid>
      </div>
    </div>
  );
};
