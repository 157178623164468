import React, { ChangeEvent } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { useProviderListTableStyles } from './Styles';
import { formatNumberPipe } from '../../../../../sharePipe/formatNumberPipe';
import { HeadCell } from '../../../../../sharePipe/createHeaderCellPipe';
import { RoleType } from '../../../../../enum/Users';
import { useStickyTableStyle } from '../../../../../components/TabBar/Styles';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

const nonsortableHeads = ['email', 'workPhone'];

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  // rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = useProviderListTableStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};

type Order = 'asc' | 'desc';

interface ProviderListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  providerRows: any;
  count: number;
  setSelectedPerformanceOptions: (input: string[]) => void;
  selectedPerformanceOptions: string[];
  headerCells: HeadCell[];
  tableType: string;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
}

export const ProviderListTableView = (props: ProviderListTableViewProps) => {
  const [isSorting, setIsSorting] = React.useState(false);
  const classes = useProviderListTableStyles();
  const stickyTableClasses = useStickyTableStyle();
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setFilteredRows(props.providerRows);

    setIsSorting(false);
  }, [props.providerRows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            limit: props.refetchDataFunc.params.limit,
            skip: 0,
            performance: props.refetchDataFunc.params.performance,
            sortBy: [
              { field: orderBy, method: order },
              { field: 'fullName', method: 'asc' },
            ],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} ${classes.rounded}`}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer
            classes={{ root: stickyTableClasses.customTableContainer }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={props.headerCells}
                // rowCount={props.data.count}
              />
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * props.limit, page * props.limit + props.limit)
                    .map((row: any, index) => {
                      return (
                        <TableRow tabIndex={-1} key={`${index}-${row.id}`}>
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                          >
                            {`${row.fullName}`}
                          </TableCell>
                          <TableCell align="left">{row.title || '-'}</TableCell>
                          <TableCell align="left">{row.email || '-'}</TableCell>
                          <TableCell align="left">
                            {row.workPhone || '-'}
                          </TableCell>

                          {props.tableType === RoleType.MSO_ADMIN ? (
                            <TableCell align="left">
                              <span
                                className={classes.newPatientText}
                                onClick={() => {
                                  navigate(`/pcp/${row.id}`, {
                                    state: {
                                      providerName: `${row.fullName}`,
                                      patientCount: row.coveringCount,
                                    },
                                  });
                                }}
                              >
                                {formatNumberPipe(row.coveringCount, '0')}
                              </span>
                            </TableCell>
                          ) : null}
                          <TableCell align="left">
                            {row.coveringPerformance}%
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};
