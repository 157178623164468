import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover $notchedOutline': {
      borderColor: '#0C77D8',
      borderWidth: 2,
    },
    '&$focused $notchedOutline': {
      borderColor: '#0C77D8',
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const useDisabledOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-disabled': {
      color: '#ABABAB',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
    '& $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    // '&:hover $notchedOutline': {
    //   borderColor: '#0C77D8',
    //   borderWidth: 2,
    // },
    '&$focused $notchedOutline': {
      borderColor: '#0C77D8',
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const useDisabledTextFieldStyles = makeStyles((theme) => ({
  root: {
    color: '#656565',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    backgroundColor: 'transparent',
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .Mui-disabled': {
      color: '#656565',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
    '& .MuiFilledInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: '#434343',
      fontWeight: 700,
      fontSize: 14,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#B7373E',
      fontWeight: 700,
      fontSize: 14,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '& $notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    // '&:hover $notchedOutline': {
    //   borderColor: '#0C77D8',
    //   borderWidth: 2,
    // },
    '&$focused $notchedOutline': {
      borderColor: '#0C77D8',
    },
  },
  focused: {},
  notchedOutline: {},
}));
