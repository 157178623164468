export const FakeDataAdmin = {
  avatarUrl: '',
  firstName: '',
  id: '',
  lastName: '',
  title: '',
  privileges: {
    webURLs: ['panels', 'eligibility', 'users', 'users/new', 'users/edit'],
    dataCategories: [],
    dataOrganizations: [],
  },
};

export const FakeDataPanelOnly = {
  avatarUrl: '',
  firstName: '',
  id: '',
  lastName: '',
  title: '',
  privileges: {
    webURLs: ['panels'],
    dataCategories: [],
    dataOrganizations: [],
  },
};

export const FakeDataEligibilityOnly = {
  avatarUrl: '',
  firstName: '',
  id: '',
  lastName: '',
  title: '',
  privileges: {
    webURLs: ['eligibility'],
    dataCategories: [],
    dataOrganizations: [],
  },
};
