import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  ListItem,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { LabelAvatar } from '../../../../../components/Avatar';
import { CloseIcon } from '../../../../../assets/Icons/ExportPatients';
import { theme } from '../../../../../themes/theme';
import { useOutlinedInputStyles } from '../../../../../styles/outlineInputStyle';
import { RoleType } from '../../../../../enum/Users';

const AVATAR_BG = Object.values(theme.palette.avatars);

interface CareTeamSelectItemProps {
  careTeamMember: any;
  removeSelectedCareTeam: any;
  maxNumPatients: number;
  isBorder: boolean;
  setAssignedPatientToMember: any;
  disableInput?: boolean;
  disableSuffixText?: boolean;
  setIsEditChange?: any;
  source?: string;
  section?: string;
}

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  listItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.hint,
  },
  outlineInputNew: {
    color: theme.palette.secondary.dark,
    fontSize: 16,
    lineHeight: 0,
    width: 170,
    paddingRight: theme.spacing(1.5),
  },
  outlineInput: {
    maxWidth: 150,
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(2),
    fontSize: 16,
  },
  rightContainer: {
    display: 'flex',
    fontSize: 12,
    alignItems: 'center',
  },
  preSetNumberNew: {
    fontSize: 16,
    color: theme.palette.grey[600],
    fontWeight: 500,
    marginRight: `${theme.spacing(5.375)}px !important`,
  },
  preSetNumber: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[600],
    marginRight: theme.spacing(2),
  },
  preSetText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
    marginRight: theme.spacing(2),
  },
  preSetTextNew: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1.5),
  },
  assignedTextNew: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    margin: theme.spacing(0, 4, 0, 1),
    color: theme.palette.grey[600],
  },
  assignedText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    paddingRight: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
}));

export const CareTeamSelectItem = (props: CareTeamSelectItemProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [assignedPatients, setAssignedPatients] = useState(0);
  const [error, setError] = useState(false);
  const inputChanges = (event: React.ChangeEvent<{ value: any }>) => {
    let onlyNum = event.target.value.replace(/[^\d-]/g, '');
    if (onlyNum.length === 0 || onlyNum < 0) {
      onlyNum = 0;
    }
    onlyNum = parseInt(onlyNum);
    if (onlyNum > props.maxNumPatients || onlyNum <= 0) {
      setError(true);
    } else {
      setError(false);
    }
    setAssignedPatients(onlyNum);
    if (props.setIsEditChange) {
      props.setIsEditChange(true);
    }
  };

  React.useEffect(() => {
    setAssignedPatients(props.careTeamMember?.assignedPatients);

    if (props.source === 'PatientFaceSheet') {
      setAssignedPatients(1);
    }
  }, [props.careTeamMember]);

  React.useEffect(() => {
    props.setAssignedPatientToMember(props.careTeamMember, assignedPatients);

    if (props.source === 'PatientFaceSheet') {
      props.setAssignedPatientToMember(props.careTeamMember, 1);
    }
  }, [assignedPatients]);

  const getDefaultItem = () => {
    return (
      <div className={classes.rightContainer}>
        {props.disableInput ? (
          <div style={{ marginRight: theme.spacing(19.5) }}>
            <Typography
              variant="body1"
              display="inline"
              className={classes.preSetNumber}
            >
              +{props.maxNumPatients}
            </Typography>
            <Typography
              variant="body1"
              display="inline"
              className={classes.preSetText}
            >
              Patient(s)
            </Typography>
          </div>
        ) : (
          <FormControl>
            <OutlinedInput
              endAdornment={
                <div style={{ color: theme.palette.grey[500] }}>Patient(s)</div>
              }
              error={error || assignedPatients == 0}
              required
              type="text"
              value={assignedPatients}
              onChange={inputChanges}
              className={classes.outlineInput}
              classes={outlinedInputClasses}
            />
          </FormControl>
        )}

        {props.disableSuffixText ? null : (
          <div className={classes.assignedText}>Assigned</div>
        )}
        {
          <IconButton
            aria-label="close"
            onClick={() => {
              props.removeSelectedCareTeam(props.careTeamMember);
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      </div>
    );
  };

  const getChangeToItems = () => {
    switch (props.source) {
      case 'PatientFaceSheet':
        return (
          <div className={classes.rightContainer}>
            <div style={{ marginRight: theme.spacing(15.5) }}>
              <Typography
                variant="body1"
                display="inline"
                className={classes.preSetNumberNew}
              >
                +{props.maxNumPatients}
              </Typography>
              <Typography
                variant="body1"
                display="inline"
                className={classes.preSetText}
              >
                Patient(s)
              </Typography>
            </div>

            <IconButton
              aria-label="close"
              onClick={() => {
                props.removeSelectedCareTeam(props.careTeamMember);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        );
      case 'PanelPatientActionFooter':
        return (
          <div className={classes.rightContainer}>
            <FormControl style={{ width: 172 }}>
              <OutlinedInput
                endAdornment={
                  <div
                    style={{
                      color: theme.palette.grey[500],
                      fontSize: 12,
                      fontWeight: 700,
                      paddingRight: theme.spacing(4),
                    }}
                  >
                    Patient(s)
                  </div>
                }
                error={error || assignedPatients == 0}
                required
                type="text"
                value={assignedPatients}
                onChange={inputChanges}
                className={classes.outlineInputNew}
                classes={outlinedInputClasses}
              />
            </FormControl>

            <div className={classes.assignedTextNew}>Assigned</div>

            <IconButton
              aria-label="close"
              onClick={() => {
                props.removeSelectedCareTeam(props.careTeamMember);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        );
      default:
        return getDefaultItem();
    }
  };

  const getCurrentlyAssignedItems = () => {
    switch (props.source) {
      case 'PatientFaceSheet':
        // From PatientFaceSheet only show the above
        return null;
      case 'PanelPatientActionFooter':
        // From PanelPatientActionFooter show the count of selected patients and text
        return (
          <div className={classes.rightContainer}>
            <div style={{ marginRight: theme.spacing(13.5) }}>
              <Typography
                variant="body1"
                display="inline"
                className={classes.preSetNumberNew}
              >
                {props.maxNumPatients}
              </Typography>
              <Typography
                variant="body1"
                display="inline"
                className={classes.preSetTextNew}
              >
                Patient(s) Selected
              </Typography>
            </div>
          </div>
        );
      default:
        return getDefaultItem();
    }
  };

  const getAddCareCoordinatorsItems = () => {
    switch (props.source) {
      case 'PanelSummary':
      case 'PanelsListTable':
        return (
          <div className={classes.rightContainer}>
            <FormControl style={{ width: 172 }}>
              <OutlinedInput
                endAdornment={
                  <div
                    style={{
                      color: theme.palette.grey[500],
                      fontSize: 12,
                      fontWeight: 700,
                      paddingRight: theme.spacing(4),
                    }}
                  >
                    Patient(s)
                  </div>
                }
                error={error || assignedPatients == 0}
                required
                type="text"
                value={assignedPatients}
                onChange={inputChanges}
                className={classes.outlineInputNew}
                classes={outlinedInputClasses}
              />
            </FormControl>

            <div className={classes.assignedTextNew}>Assigned</div>

            <IconButton
              aria-label="close"
              onClick={() => {
                props.removeSelectedCareTeam(props.careTeamMember);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        );
      default:
        return null;
    }
  };

  const getItems = () => {
    switch (props.section) {
      case 'CurrentlyAssigned':
        return getCurrentlyAssignedItems();
      case 'ChangeTo':
        return getChangeToItems();
      case 'AddCareCoordinators':
        return getAddCareCoordinatorsItems();
      default:
        return getDefaultItem();
    }
  };

  return (
    <ListItem>
      <div
        className={classes.listItem}
        style={props.isBorder ? {} : { borderBottom: 'none' }}
      >
        <LabelAvatar
          firstName={props.careTeamMember.firstName}
          lastName={props.careTeamMember.lastName}
          title={
            props.careTeamMember?.role?.roleName === RoleType.ADMIN
              ? RoleType.CARE_MANAGER
              : props.careTeamMember?.role?.roleName ||
                props.careTeamMember?.title
          }
          styles={classes}
        />
        {getItems()}
      </div>
    </ListItem>
  );
};
