import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useUnassignedPatientViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 155,
    },
    mainContainer: {
      gap: theme.spacing(4),
    },
    hintContainer: {
      width: 460,
      height: 168,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      '& h4[role="hintTitle"]': {
        fontSize: 32,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
        marginBottom: theme.spacing(1.25),
      },

      '& p[role="hintDesc"]': {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        textAlign: 'center',
      },
    },
    hintIcon: {
      width: 60,
      height: 60,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2.125),
    },
    smallPrimarybtn: {
      color: theme.palette.primary.contrastText,
      padding: 0,
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: 164,
      height: 48,
      lineHeight: '24px',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },

      '& .MuiButton-label .MuiSvgIcon-root': {
        fontSize: '14px',
        padding: theme.spacing(0),
        marginLeft: theme.spacing(-1),
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: '0px',
        textTransform: 'capitalize',
        marginLeft: theme.spacing(0.41625),
      },
    },
  })
);
