import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  Paper,
  Theme,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { theme } from '../../../../../../../../../themes/theme';
import { NoteItem } from '../NoteItem';
import { NoteForm } from '../NoteForm';
import { useForm } from 'react-hook-form';
import { NoteOption } from '../../Container';
import { GlobalContext } from '../../../../../../../../../components/GlobalContext';
import {
  useDeleteCareNotesMutation,
  useSaveCareNotesMutation,
  useUpdateCareNotesMutation,
} from '../../../CareGapsDrawer/index.generated';
import { useParams } from 'react-router';

interface EditableNoteDialogProps {
  dialogId: string;
  handleClose: () => void;
  item?: any;
  handleItemClick?: (event: any, item?: any) => void;
  patientId: string;
  hardRefresh: () => void;
}

export const EditableNoteDialog: React.FC<EditableNoteDialogProps> = ({
  dialogId,
  handleClose,
  item,
  handleItemClick,
  patientId,
  hardRefresh,
}) => {
  const { id } = useParams();
  const [saveCareNotesMutation] = useSaveCareNotesMutation();
  const [updateCareNotesMutation] = useUpdateCareNotesMutation();
  const [deleteCareNotesMutation] = useDeleteCareNotesMutation();

  const submitForm = async (input: any) => {
    // call api to submit notes
    handleClose();

    switch (dialogId) {
      case NoteOption.ADD:
        const {
          data: dataAdd,
          errors: errorsAdd,
        } = await saveCareNotesMutation({
          variables: {
            input: {
              createdAt: new Date(),
              patientId: patientId,
              notes: input.notes.trim(),
              panelId: id,
            },
          },
        });
        if (
          errorsAdd ||
          !dataAdd ||
          dataAdd?.saveCareNotes.isSuccess === false
        ) {
          setToastMessage({
            snackbarMsg: 'Something Went Wrong, Please Try Again Later.',
            severity: 'error',
            isOpen: true,
          });
        }

        if (dataAdd?.saveCareNotes.isSuccess) {
          setToastMessage({
            snackbarMsg: getSuccessMsgByDialogId(),
            severity: 'success',
            isOpen: true,
          });
        }
        setIsOpenToast(true);
        hardRefresh();
        break;
      case NoteOption.EDIT:
        const {
          data: dataEdit,
          errors: errorsEdit,
        } = await updateCareNotesMutation({
          variables: {
            input: {
              createdAt: item.createdAt,
              patientId: patientId,
              notes: input.notes.trim(),
              panelId: id,
            },
          },
        });
        if (
          errorsEdit ||
          !dataEdit ||
          dataEdit?.updateCareNotes.isSuccess === false
        ) {
          setToastMessage({
            snackbarMsg: 'Something Went Wrong, Please Try Again Later.',
            severity: 'error',
            isOpen: true,
          });
        }

        if (dataEdit?.updateCareNotes.isSuccess) {
          setToastMessage({
            snackbarMsg: getSuccessMsgByDialogId(),
            severity: 'success',
            isOpen: true,
          });
        }
        setIsOpenToast(true);
        hardRefresh();
        break;
      case NoteOption.DELETE:
        const {
          data: dataDelete,
          errors: errorsDelete,
        } = await deleteCareNotesMutation({
          variables: {
            input: {
              createdAt: item.createdAt,
              patientId: patientId,
              notes: '',
              panelId: id,
            },
          },
        });
        if (
          errorsDelete ||
          !dataDelete ||
          dataDelete?.deleteCareNotes.isSuccess === false
        ) {
          setToastMessage({
            snackbarMsg: 'Something Went Wrong, Please Try Again Later.',
            severity: 'error',
            isOpen: true,
          });
        }

        if (dataDelete?.deleteCareNotes.isSuccess) {
          setToastMessage({
            snackbarMsg: getSuccessMsgByDialogId(),
            severity: 'success',
            isOpen: true,
          });
        }
        setIsOpenToast(true);
        hardRefresh();
        break;
      default:
        break;
    }
  };

  const { setIsOpenToast, setToastMessage } = React.useContext(GlobalContext);

  const classes = useStyles({ dialogId });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notes: dialogId === NoteOption.EDIT ? item?.content : '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getDialogTitleByDialogId = () => {
    switch (dialogId) {
      case NoteOption.ADD:
        return 'Add Notes';
      case NoteOption.EDIT:
        return 'Edit Notes';
      case NoteOption.DELETE:
        return 'Are you sure?';
      default:
        return '';
    }
  };
  const getDialogContentByDialogId = () => {
    switch (dialogId) {
      case NoteOption.ADD:
      case NoteOption.EDIT:
        return (
          <NoteForm
            title="Content"
            control={control}
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            submitForm={submitForm}
          />
        );
      case NoteOption.DELETE:
        return (
          <div className={classes.deleteContentContainer}>
            <Typography className={classes.deleteHintText}>
              Deleted note can't be recovered, are you sure you want to delete this note?  Deleted note will still be available in iClinic.
            </Typography>
            {item && handleItemClick && (
              <NoteItem
                item={item}
                key={item.id}
                handleItemClick={handleItemClick}
                inDialog={true}
              />
            )}
          </div>
        );
      default:
        break;
    }
  };
  const getDialogFooterConfirmBtnTextByDialogId = () => {
    switch (dialogId) {
      case NoteOption.ADD:
        return 'Add';
      case NoteOption.EDIT:
        return 'Save Changes';
      case NoteOption.DELETE:
        return 'Confirm Delete';
      default:
        return 'Confirm';
    }
  };
  const getSuccessMsgByDialogId = () => {
    switch (dialogId) {
      case NoteOption.ADD:
        return 'New Note Added Successfully.';
      case NoteOption.EDIT:
        return 'Note Has Been Updated.';
      case NoteOption.DELETE:
        return 'Note Has Been Deleted.';
      default:
        return 'Updated Successfully.';
    }
  };

  return (
    <div id="EditableNoteDialog">
      <BootstrapDialog
        aria-labelledby="EditNoteDialog"
        open={true}
        {...{
          dialogId,
          handleClose,
          item,
          handleItemClick,
          patientId,
          hardRefresh,
        }}
      >
        <div role="EditableNoteDialog-container">
          <Paper className={classes.paperContainer}>
            <Grid container>
              {/* section header */}
              <Grid item xs={12} className={classes.cardHeader}>
                {/* title */}
                <Typography className={classes.cardTitle}>
                  {getDialogTitleByDialogId()}
                </Typography>
              </Grid>

              {/* main region */}
              <Grid item xs={12} className={classes.cardContent}>
                <Grid item xs={12}>
                  {getDialogContentByDialogId()}
                </Grid>
              </Grid>

              {/* section footer */}
              <Grid item xs={12} className={classes.sectionFooter}>
                <Button
                  id="cancel-btn"
                  variant="contained"
                  className={classes.backBtn}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  id="confirm-button"
                  variant="contained"
                  disableElevation
                  className={classes.updateBtn}
                  disabled={errors.notes != undefined}
                  type={dialogId === NoteOption.DELETE ? 'button' : 'submit'}
                  onClick={
                    dialogId === NoteOption.DELETE
                      ? submitForm
                      : handleSubmit(submitForm)
                  }
                >
                  {getDialogFooterConfirmBtnTextByDialogId()}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </BootstrapDialog>
    </div>
  );
};

const BootstrapDialog = styled(Dialog)(
  ({ theme, ...props }: { theme: Theme } & EditableNoteDialogProps) => ({
    '& .MuiPaper-root': {
      width: props.dialogId === NoteOption.DELETE ? 700 : 640,
      height: 'auto',
      background: theme.palette.primary.contrastText,
      boxShadow:
        '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
      borderRadius: '8px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  })
);

const useStyles = makeStyles({
  root: {},
  paperContainer: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    padding: theme.spacing(2.5, 0),
  },
  sectionHeader: {
    height: 28,
    borderBottom: '1px solid #d1d1d1',
    color: theme.palette.grey[700],
    fontSize: 20,

    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.4,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.625),
    paddingLeft: theme.spacing(3),
  },
  sectionFooter: {
    position: 'relative',
    height: 72,
    textTransform: 'capitalize',
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backBtn: {
    width: 'fit-content',
    height: 40,
    background: theme.palette.grey[200],
    padding: theme.spacing(1, 2),

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    margin: theme.spacing(2, 'auto', 2, 3),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  updateBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: (props: any) =>
      props.dialogId === NoteOption.DELETE
        ? theme.palette.error.main
        : theme.palette.primary.main,
    borderRadius: 8,
    width: 'fit-content',
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: (props: any) =>
        props.dialogId === NoteOption.DELETE
          ? theme.palette.error.main
          : '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  deleteContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 8,

    width: '100%',
    height: 'fit-content',
  },
  deleteHintText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.5,
    color: '#656565',
  },
});
