import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { LabelAvatar } from '../../../../components/Avatar';

const useStyles = makeStyles((theme) => ({
  largeNumberToolTip: {
    position: 'absolute',
    right: theme.spacing(1.5),
    bottom: 63,
    width: 389,
    height: 'fit-content',
    background: theme.palette.grey[700],
    borderRadius: theme.spacing(1),

    opacity: 0.8,
  },
  largeNumberToolTipText: {
    margin: theme.spacing(1, 1.5, 1, 1.5),
    color: '#FFF',
    fontSize: 12,
    textTransform: 'none',
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 413,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      // borderBottom: '1px solid #D1D1D1',
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'visible',
      paddingBottom: 24,
    },
  },
  editBtn: {
    width: 'fit-content',
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 156,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
    paddingTop: 16,
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(2),
  },
  divider12: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(1.5),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    height: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    // color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholderTextSelected: {
    // color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleButton: {
    lineHeight: '24px',
    height: 40,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: '#656565',
  },
  trashIcon: {
    color: '#656565',
    width: 24,
    height: 24,
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  description: {
    paddingBottom: 24,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
  },
  patientRow: {
    display: 'flex',
    height: 40,
    background: '#F8F8F8',
    borderRadius: 8,
    alignItems: 'center',
  },
  nameColumn: {
    width: '60%',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 16,
    paddingRight: 5,
  },
  countColumn: {
    width: '40%',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#434343',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface DialogProps {
  onClose: any;
  onSubmit: any;
  selectedItems: any;
  unAssignedCount: any;
  loading: boolean | undefined;
  clicked: boolean;
  handleViewOtherPanel: (input: any) => any;
}

export const ManageCoordinatorConfirmSubmitDialog = (props: DialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {}}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Update Care Coordinator(s)'}
      </DialogTitle>
      <DialogContent>
        <div className={classes.description}>
          Are you sure to update care coordinator(s) assignment as below?
        </div>
        <div className={classes.patientRow}>
          <div className={classes.nameColumn}>Unassigned</div>
          <div className={classes.countColumn}>
            {props.unAssignedCount} Patients
          </div>
        </div>
        {props.selectedItems.length > 0 &&
          props.selectedItems.map((cc: any, index: number) => {
            return (
              <div style={{ paddingTop: 4 }} key={index}>
                <div className={classes.patientRow}>
                  <div className={classes.nameColumn}>
                    <LabelAvatar
                      firstName={cc.firstName}
                      lastName={cc.lastName}
                    />
                  </div>
                  <div className={classes.countColumn}>
                    {cc.assignedPatients ?? 0} Patients
                  </div>
                </div>
              </div>
            );
          })}
      </DialogContent>
      {/*<div className={classes.divider} />*/}
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            autoFocus
            onClick={
              props.clicked && props.loading
                ? props.handleViewOtherPanel
                : props.onClose
            }
            className={classes.editBtn}
          >
            {props.clicked && props.loading ? 'View Other Panels' : 'Edit'}
          </Button>
          {/* tooltip for large number */}
          {props.clicked && props.loading && (
            <div className={classes.largeNumberToolTip}>
              <Typography className={classes.largeNumberToolTipText}>
                Assigning large number of patients will take longer than usual.
                Please wait for the process to be done or View other panels and
                come back later.
              </Typography>
            </div>
          )}
          <Button
            onClick={() => {
              props.onSubmit();
            }}
            className={classes.createNowBtn}
            autoFocus
            disabled={props.clicked && props.loading}
          >
            {props.clicked && props.loading && (
              <div
                className="loader"
                style={{
                  width: 18,
                  height: 18,
                  border: '2px solid #f3f3f3',
                  borderTop: '2px solid #3498db',
                }}
              />
            )}

            {props.clicked && props.loading ? 'Updating' : 'Confirm Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
