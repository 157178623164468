import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const ClosedEyeIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M14.725 11.15L13.625 10.05C14.0583 8.86667 13.8333 7.88333 12.95 7.1C12.0667 6.31667 11.1083 6.11667 10.075 6.5L8.975 5.4C9.25833 5.21667 9.575 5.08333 9.925 5C10.275 4.91667 10.6333 4.875 11 4.875C12.1833 4.875 13.1875 5.2875 14.0125 6.1125C14.8375 6.9375 15.25 7.94167 15.25 9.125C15.25 9.49167 15.2042 9.85417 15.1125 10.2125C15.0208 10.5708 14.8917 10.8833 14.725 11.15ZM17.95 14.375L16.95 13.375C17.7667 12.775 18.4792 12.1042 19.0875 11.3625C19.6958 10.6208 20.1417 9.875 20.425 9.125C19.5917 7.275 18.3417 5.8125 16.675 4.7375C15.0083 3.6625 13.2 3.125 11.25 3.125C10.55 3.125 9.83333 3.19167 9.1 3.325C8.36667 3.45833 7.79167 3.61667 7.375 3.8L6.225 2.625C6.80833 2.35833 7.55417 2.125 8.4625 1.925C9.37083 1.725 10.2583 1.625 11.125 1.625C13.5083 1.625 15.6875 2.30417 17.6625 3.6625C19.6375 5.02083 21.0833 6.84167 22 9.125C21.5667 10.1917 21.0083 11.1667 20.325 12.05C19.6417 12.9333 18.85 13.7083 17.95 14.375ZM19.4 20.025L15.2 15.9C14.6167 16.1333 13.9583 16.3125 13.225 16.4375C12.4917 16.5625 11.75 16.625 11 16.625C8.56667 16.625 6.35833 15.9458 4.375 14.5875C2.39167 13.2292 0.933333 11.4083 0 9.125C0.333333 8.25833 0.795833 7.4125 1.3875 6.5875C1.97917 5.7625 2.7 4.975 3.55 4.225L0.4 1.075L1.45 0L20.375 18.925L19.4 20.025ZM4.575 5.275C3.95833 5.725 3.3625 6.31667 2.7875 7.05C2.2125 7.78333 1.8 8.475 1.55 9.125C2.4 10.975 3.67917 12.4375 5.3875 13.5125C7.09583 14.5875 9.03333 15.125 11.2 15.125C11.75 15.125 12.2917 15.0917 12.825 15.025C13.3583 14.9583 13.7583 14.8583 14.025 14.725L12.425 13.125C12.2417 13.2083 12.0167 13.2708 11.75 13.3125C11.4833 13.3542 11.2333 13.375 11 13.375C9.83333 13.375 8.83333 12.9667 8 12.15C7.16667 11.3333 6.75 10.325 6.75 9.125C6.75 8.875 6.77083 8.625 6.8125 8.375C6.85417 8.125 6.91667 7.9 7 7.7L4.575 5.275Z"
  />
);
