import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useProviderPatientListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    lightBackground: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    closedDrawer: {
      display: 'none',
      transition: 'all 1s',
    },
    openedDrawer: {
      display: 'block',
      transition: 'all 3s',
    },
  })
);
