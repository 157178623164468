import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { formatNumberPipe } from '../../sharePipe/formatNumberPipe';
import {
  useStickyTabBarStyles,
  useStickyTabsStyles,
  useTabBarStyles,
  useTabsStyles,
  useTabStyles,
} from './Styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: string;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

interface TabBarProps {
  tabObjects: {
    tabIndex: number;
    tabLabel: string;
    tabUrl: string;
    tabBadgeCount: number | undefined | null;
    disabled: boolean;
  }[];
  activeTabIndex: number;
  children: React.ReactNode | React.ReactNode[];
  switchTab: (event: any, newValue: number) => any;
  sticky?: boolean;
}

export const TabBar = (props: TabBarProps) => {
  const classes = useTabBarStyles();
  const stickyClasses = useStickyTabBarStyles();
  const tabsClasses = useTabsStyles();
  const stickyTabsClasses = useStickyTabsStyles();
  const tabClasses = useTabStyles();

  return (
    <div className={props.sticky ? stickyClasses.root : classes.root}>
      <div
        className={props.sticky ? stickyClasses.tabBar : classes.tabBar}
        role="tabBar"
      >
        <Tabs
          value={props.activeTabIndex}
          classes={props.sticky ? stickyTabsClasses : tabsClasses}
          onChange={props.switchTab}
        >
          {props.tabObjects.map(
            (
              tab: {
                tabIndex: number;
                tabLabel: string;
                tabUrl: string;
                tabBadgeCount: number | undefined | null;
                disabled: boolean;
              },
              index: number
            ) => {
              return (
                <Tab
                  value={tab.tabIndex}
                  key={`${tab.tabLabel}-${index}`}
                  disabled={tab.disabled}
                  label={
                    <span
                      className={
                        props.activeTabIndex === tab.tabIndex
                          ? `${classes.activeTab} ${classes.tabText}`
                          : `${classes.deactiveTab} ${classes.tabText}`
                      }
                    >
                      {tab.tabLabel}
                      {tab.tabBadgeCount != null && tab.tabBadgeCount > 0 && (
                        <span
                          className={
                            props.activeTabIndex === tab.tabIndex
                              ? `${classes.activeNum} ${classes.tabNum}`
                              : `${classes.deactiveNum} ${classes.tabNum}`
                          }
                        >
                          {formatNumberPipe(tab.tabBadgeCount, '0')}
                        </span>
                      )}
                    </span>
                  }
                  to={tab.tabUrl}
                  component={NavLink}
                  disableRipple
                  classes={{
                    ...tabClasses,
                    wrapper: `${tabClasses.wrapper} MuiTab-label`,
                  }}
                />
              );
            }
          )}
        </Tabs>
      </div>

      {props.tabObjects.map((tab) => {
        return (
          <TabPanel
            value={props.activeTabIndex}
            index={tab.tabIndex}
            className={props.sticky ? stickyClasses.tabPanel : classes.tabPanel}
            key={`${tab.tabLabel}-${tab.tabIndex}-panel`}
          >
            {props.activeTabIndex === tab.tabIndex ? props.children : <div />}
          </TabPanel>
        );
      })}
    </div>
  );
};
