import React from 'react';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { usePanelsListQuery } from '../PanelsList/index.generated';
import { CCPanelsListView } from './View';

interface CCPanelsListContainerProps {
  limit: number;
  skip: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  showMyOwn: string;
  setNumOfPanels: any;
}

export const CCPanelsListContainer = (props: CCPanelsListContainerProps) => {
  const [sortBy, setSortBy] = React.useState({
    field: 'createdAt',
    method: 'desc' as const,
  });

  const panels = usePanelsListQuery({
    variables: {
      input: {
        limit: props.limit ?? 10,
        skip: props.skip ?? 0,
        sortBy: [{ ...sortBy }],
        showMyOwn: props.showMyOwn,
      },
    },
  });

  const queryResult = useFetchTableData(panels);

  React.useEffect(() => {
    if (queryResult) {
      props.setNumOfPanels(queryResult.getPanels?.count);
    }
  }, [queryResult]);

  if (!panels.called || panels.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 235px)` }} />;
  }

  return (
    <CCPanelsListView
      query={panels}
      sortBy={{ ...sortBy }}
      setSortBy={setSortBy}
      panels={queryResult ? queryResult.getPanels.result : []}
      skip={props.skip}
      limit={props.limit}
      setSkip={props.setSkip}
      setLimit={props.setLimit}
      count={queryResult ? queryResult.getPanels?.count : 0}
      showMyOwn={props.showMyOwn}
    />
  );
};
