import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Permission } from '../../../../../enum/Users';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { CloseIcon } from '../../../../../assets/Icons/ExportPatients';
import { useStickyTableStyle } from '../../../../../components/TabBar/Styles';
import { GlobalContext } from '../../../../../components/GlobalContext';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Permission;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Permissions' },
  { id: 'msoAdmin', numeric: false, disablePadding: false, label: 'MSO Admin' },
  {
    id: 'careManager',
    numeric: false,
    disablePadding: false,
    label: 'Care Manager',
  },
  {
    id: 'careCoordinator',
    numeric: false,
    disablePadding: false,
    label: 'Care Coordinator',
  },
  {
    id: 'clinician',
    numeric: false,
    disablePadding: false,
    label: 'Clinician',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      border: 'none',

      '& .MuiTableHead-root': {
        // boxShadow: 'none',
        background: theme.palette.grey[50],
      },

      '& thead > tr > .MuiTableCell-head': {
        lineHeight: 1,
      },

      '& > thead > tr > th': {
        padding: theme.spacing(1.5, 3),
        width: 0,
        height: 16,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
      },

      '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },
      '& .MuiTableCell-body': { padding: theme.spacing(1, 3) },

      '& > tbody > tr': {
        height: 40,
      },

      '& > tbody > tr > *': {
        height: 20,
        lineHeight: 1,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
      },
      // Role column
      '& > tbody > tr > td:nth-child(4)': {
        width: 120,
      },
      // Status column
      '& > tbody > tr > td:nth-child(5)': {
        width: 130,
      },
      '& > tbody > tr > td:last-child': {
        width: 65,
      },
      '& > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },
    tableRow: {
      // '&.MuiTableRow-hover:hover': {
      // background: `${theme.palette.primary.light}`,
      // transition: 'all 2s ease-in-out',
      // },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    selectAll: {
      '& > .checkedIcon:before': {
        backgroundColor: 'red',
      },
    },
    roleChip: {
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    roleChipMSOAdmin: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    roleChipAdmin: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    roleChipUser: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 44,
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: theme.spacing(1),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    hasPermission: {
      width: 20,
      height: 20,
      color: theme.palette.success.main,
    },
    noPermission: {
      width: 20,
      height: 20,
      color: theme.palette.grey[300],
    },
  })
);

interface RolesAndPermissionsListViewProps {
  permissionRow: Permission[];
}

export const RolesAndPermissionsListView = (
  props: RolesAndPermissionsListViewProps
) => {
  const { loggedInUser } = React.useContext(GlobalContext);
  const isHudsonAccount = loggedInUser?.accountId === '9001';
  const filteredRows = props.permissionRow;
  const classes = useStyles();
  const stickyTableClasses = useStickyTableStyle();
  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* users list table */}
        <TableContainer
          classes={{ root: stickyTableClasses.customTableContainer }}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            {/* table header */}
            <TableHead>
              <TableRow>
                {headCells
                  .filter((headCell) => {
                    if (isHudsonAccount) {
                      return headCell;
                    } else {
                      return headCell.id !== 'clinician';
                    }
                  })
                  .map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="left"
                      padding="default"
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            {/* tables rows */}
            <TableBody>
              {filteredRows &&
                filteredRows.map((row, index) => {
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      //   role="checkbox"
                      tabIndex={-1}
                      key={row.id + row.name + index}
                    >
                      <TableCell align="left">
                        <span>{row.name}</span>
                      </TableCell>
                      <TableCell align="left">
                        {row.msoAdmin ? (
                          <CheckIcon className={classes.hasPermission} />
                        ) : (
                          <CloseIcon className={classes.noPermission} />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.careManager ? (
                          <CheckIcon className={classes.hasPermission} />
                        ) : (
                          <CloseIcon className={classes.noPermission} />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.careCoordinator ? (
                          <CheckIcon className={classes.hasPermission} />
                        ) : (
                          <CloseIcon className={classes.noPermission} />
                        )}
                      </TableCell>
                      {isHudsonAccount && (
                        <TableCell align="left">
                          {row.clinician ? (
                            <CheckIcon className={classes.hasPermission} />
                          ) : (
                            <CloseIcon className={classes.noPermission} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
