import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type UpdateUserClinicAndDivisionGroupMutationVariables = Types.Exact<{
  input: Types.UpdateUserClinicAndDivisionGroupRequest;
}>;


export type UpdateUserClinicAndDivisionGroupMutation = (
  { __typename: 'Mutation' }
  & { updateUserClinicAndDivisionGroup: (
    { __typename: 'UpdateUserClinicAndDivisionGroupResponse' }
    & Pick<Types.UpdateUserClinicAndDivisionGroupResponse, 'isSuccess'>
  ) }
);

export type GetAllClinicAndDivisionPairsByMsoIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllClinicAndDivisionPairsByMsoIdQuery = (
  { __typename: 'Query' }
  & { getAllClinicAndDivisionPairsByMsoID?: Types.Maybe<Array<(
    { __typename: 'GetUserClinicAndDivisionResponse' }
    & Pick<Types.GetUserClinicAndDivisionResponse, 'clinicID'>
    & { divisions: Array<(
      { __typename: 'ClinicAndDivisionGroupOutPut' }
      & Pick<Types.ClinicAndDivisionGroupOutPut, 'msoID' | 'clinicID' | 'divisionID' | 'divisionName' | 'address'>
      & { pairs: Array<(
        { __typename: 'ClinicAndDivisionPairOutPut' }
        & Pick<Types.ClinicAndDivisionPairOutPut, 'msoID' | 'clinicID' | 'address' | 'divisionID' | 'divisionName' | 'userID' | 'email' | 'roleID' | 'firstName' | 'lastName' | 'isSiteDesignee'>
      )> }
    )> }
  )>> }
);


export const UpdateUserClinicAndDivisionGroupDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateUserClinicAndDivisionGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserClinicAndDivisionGroupRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserClinicAndDivisionGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type UpdateUserClinicAndDivisionGroupMutationFn = Apollo.MutationFunction<UpdateUserClinicAndDivisionGroupMutation, UpdateUserClinicAndDivisionGroupMutationVariables>;

/**
 * __useUpdateUserClinicAndDivisionGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserClinicAndDivisionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserClinicAndDivisionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserClinicAndDivisionGroupMutation, { data, loading, error }] = useUpdateUserClinicAndDivisionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserClinicAndDivisionGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserClinicAndDivisionGroupMutation, UpdateUserClinicAndDivisionGroupMutationVariables>) {
        return Apollo.useMutation<UpdateUserClinicAndDivisionGroupMutation, UpdateUserClinicAndDivisionGroupMutationVariables>(UpdateUserClinicAndDivisionGroupDocument, baseOptions);
      }
export type UpdateUserClinicAndDivisionGroupMutationHookResult = ReturnType<typeof useUpdateUserClinicAndDivisionGroupMutation>;
export type UpdateUserClinicAndDivisionGroupMutationResult = Apollo.MutationResult<UpdateUserClinicAndDivisionGroupMutation>;
export type UpdateUserClinicAndDivisionGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUserClinicAndDivisionGroupMutation, UpdateUserClinicAndDivisionGroupMutationVariables>;
export const GetAllClinicAndDivisionPairsByMsoIdDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllClinicAndDivisionPairsByMsoID"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllClinicAndDivisionPairsByMsoID"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clinicID"}},{"kind":"Field","name":{"kind":"Name","value":"divisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"msoID"}},{"kind":"Field","name":{"kind":"Name","value":"clinicID"}},{"kind":"Field","name":{"kind":"Name","value":"divisionID"}},{"kind":"Field","name":{"kind":"Name","value":"divisionName"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"pairs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"msoID"}},{"kind":"Field","name":{"kind":"Name","value":"clinicID"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"divisionID"}},{"kind":"Field","name":{"kind":"Name","value":"divisionName"}},{"kind":"Field","name":{"kind":"Name","value":"userID"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"roleID"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"isSiteDesignee"}}]}}]}}]}}]}}]};

/**
 * __useGetAllClinicAndDivisionPairsByMsoIdQuery__
 *
 * To run a query within a React component, call `useGetAllClinicAndDivisionPairsByMsoIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClinicAndDivisionPairsByMsoIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClinicAndDivisionPairsByMsoIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllClinicAndDivisionPairsByMsoIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllClinicAndDivisionPairsByMsoIdQuery, GetAllClinicAndDivisionPairsByMsoIdQueryVariables>) {
        return Apollo.useQuery<GetAllClinicAndDivisionPairsByMsoIdQuery, GetAllClinicAndDivisionPairsByMsoIdQueryVariables>(GetAllClinicAndDivisionPairsByMsoIdDocument, baseOptions);
      }
export function useGetAllClinicAndDivisionPairsByMsoIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClinicAndDivisionPairsByMsoIdQuery, GetAllClinicAndDivisionPairsByMsoIdQueryVariables>) {
          return Apollo.useLazyQuery<GetAllClinicAndDivisionPairsByMsoIdQuery, GetAllClinicAndDivisionPairsByMsoIdQueryVariables>(GetAllClinicAndDivisionPairsByMsoIdDocument, baseOptions);
        }
export type GetAllClinicAndDivisionPairsByMsoIdQueryHookResult = ReturnType<typeof useGetAllClinicAndDivisionPairsByMsoIdQuery>;
export type GetAllClinicAndDivisionPairsByMsoIdLazyQueryHookResult = ReturnType<typeof useGetAllClinicAndDivisionPairsByMsoIdLazyQuery>;
export type GetAllClinicAndDivisionPairsByMsoIdQueryResult = Apollo.QueryResult<GetAllClinicAndDivisionPairsByMsoIdQuery, GetAllClinicAndDivisionPairsByMsoIdQueryVariables>;
export function refetchGetAllClinicAndDivisionPairsByMsoIdQuery(variables?: GetAllClinicAndDivisionPairsByMsoIdQueryVariables) {
      return { query: GetAllClinicAndDivisionPairsByMsoIdDocument, variables: variables }
    }