import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const UsersIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M12,13 C13.5707839,13 14.9300963,13.9054171 15.5845149,15.2228289 C16.1285542,14.4814256 17.0080556,14 18,14 C19.6568542,14 21,15.3431458 21,17 L21,19 L3,19 L3,17 C3,15.3431458 4.34314575,14 6,14 C6.99194437,14 7.87144576,14.4814256 8.41759748,15.22337 C9.06990369,13.9054171 10.4292161,13 12,13 Z M18,9 C19.1045695,9 20,9.8954305 20,11 C20,12.1045695 19.1045695,13 18,13 C16.8954305,13 16,12.1045695 16,11 C16,9.8954305 16.8954305,9 18,9 Z M6,9 C7.1045695,9 8,9.8954305 8,11 C8,12.1045695 7.1045695,13 6,13 C4.8954305,13 4,12.1045695 4,11 C4,9.8954305 4.8954305,9 6,9 Z M12,6 C13.6568542,6 15,7.34314575 15,9 C15,10.6568542 13.6568542,12 12,12 C10.3431458,12 9,10.6568542 9,9 C9,7.34314575 10.3431458,6 12,6 Z"
  />
);
