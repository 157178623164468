import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { GlobalContext } from '../../../../components/GlobalContext';
import { PanelPatientsContainer } from './Container';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../../components/CheckIsAdmin';

type Props = {
  hardRefresh: any;
  unassignedPatientCount?: number;
  setAssignedPatientCount?: (input: any) => any;
  setMyAssignedPatientCount?: (input: any) => any;
  notEngagedCount?: number;
  nofutureApptCount?: number;
  setShowAssignCoordinatorDialog?: (input: boolean) => void;
  setShowManageCoordinatorDialog?: (input: any) => void;
  careTeamUsers?: any;
  assignedPatientCount?: number;
};

export const RoutedPanelPatients = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [selected, setSelected] = React.useState<any[]>([]);
  const [excluded, setExcluded] = React.useState<any[]>([]);
  const [allSelected, setAllSelected] = React.useState(false);

  const { loggedInUser } = React.useContext(GlobalContext);

  let title = 'Assigned Patients';
  let tabIndex = 1;
  const availableActions = [];

  switch (type) {
    case 'summary':
      tabIndex = 0;
      break;
    case 'assigned': {
      title =
        CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)
          ? 'Assigned Patients'
          : 'My Patients';
      availableActions.push('Batch SMS');
      availableActions.push('Change Coordinator');
      tabIndex = 1;
      break;
    }
    case 'unassigned': {
      title = 'Unassigned Patients';
      availableActions.push('Assign Coordinator');
      tabIndex = 2;
      break;
    }
    case 'deListed': {
      title = 'De-Listed Patients';
      availableActions.push('Assign Coordinator');
      tabIndex = 3;
      break;
    }
    case 'engagement': {
      title = 'Engagement Patients';
      tabIndex = 4;
      break;
    }
  }

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  return (
    <PanelPatientsContainer
      limit={limit}
      skip={skip}
      panelId={id || ''}
      setLimit={onSetLimit}
      setSkip={onSetSkip}
      title={title}
      tabIndex={tabIndex}
      availableActions={availableActions}
      selected={selected}
      setSelected={setSelected}
      allSelected={allSelected}
      setAllSelected={setAllSelected}
      excluded={excluded}
      setExcluded={setExcluded}
      hardRefresh={props.hardRefresh}
      unassignedPatientCount={props.unassignedPatientCount}
      setAssignedPatientCount={props.setAssignedPatientCount}
      setMyAssignedPatientCount={props.setMyAssignedPatientCount}
      notEngagedCount={props.notEngagedCount}
      nofutureApptCount={props.nofutureApptCount}
      setShowAssignCoordinatorDialog={props.setShowAssignCoordinatorDialog}
      setShowManageCoordinatorDialog={props.setShowManageCoordinatorDialog}
      careTeamUsers={props.careTeamUsers}
      assignedPatientCount={props.assignedPatientCount}
    />
  );
};
