import React from 'react';
import { CareGapNoteFormView } from './View';

interface CareGapNoteFormContainerProps {
  control: any;
  register: any;
  errors: any;
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  dialogId: string;
}

export const CareGapNoteFormContainer: React.FC<CareGapNoteFormContainerProps> = ({
  control,
  register,
  errors,
  onSubmit,
  dialogId,
}) => {
  const [initState, setInitState] = React.useState(
    {} as CareGapNoteFormStateType
  );

  return (
    <div id="CareGapNoteFormContainer">
      <CareGapNoteFormView
        initState={initState}
        control={control}
        register={register}
        errors={errors}
        onSubmit={onSubmit}
        dialogId={dialogId}
      />
    </div>
  );
};
