import {createContext, useState} from "react";
import {useGetEligibilitySummaryQuery} from "../../Dashboard/index.generated";

export interface EligibilityInfo {
  refresher: number
}

const defaultInfo: EligibilityInfo = {
  refresher: 0
}

export interface EligibilityContextType {
  info: EligibilityInfo,
  hardRefresh: () => void,
}

export const EligibilityContext = createContext<EligibilityContextType>({
  info: defaultInfo,
  hardRefresh: () => null
})

export default function EligibilityProvider({children}: any) {
  const [info, setInfo] = useState<EligibilityInfo>(defaultInfo)

  const hardRefresh = () => {
    setInfo({...defaultInfo, refresher: info.refresher + 1})
  }

  const value: EligibilityContextType = {info, hardRefresh}
  return (
    <EligibilityContext.Provider value={value}>
      {children}
    </EligibilityContext.Provider>
  )
}
