interface Props {
  height: number;
  width: number;
  fill: string;
}

export const ErrorScreenIcon = (props: Props) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#a)">
        <path
          d="M227.762 151.896c9.001 0 16.297-7.296 16.297-16.297 0-9.001-7.296-16.297-16.297-16.297-9.001 0-16.297 7.296-16.297 16.297 0 9.001 7.296 16.297 16.297 16.297Z"
          fill="#E6E6E6"
        />
        <path
          d="m267.704 156.542-17.031-17.031a18.033 18.033 0 0 0 2.395-13.848 18.048 18.048 0 0 0-8.269-11.364 18.1 18.1 0 0 0-23.985 4.983 18.037 18.037 0 0 0 24.441 25.647l17.031 17.031a3.828 3.828 0 0 0 5.416-.002 3.83 3.83 0 0 0 .002-5.416Zm-23.085-17.667a12.899 12.899 0 1 1-15.202-20.497 12.9 12.9 0 0 1 18.98 11.376 12.857 12.857 0 0 1-3.778 9.121Z"
          fill="#3F3D56"
        />
        <path
          d="M391.159 181.664c43.555 48.167-82.703 117.582-184.722 117.582-102.02 0-172.602-53.785-184.723-117.582C-7.677 26.976 272.13-71.343 206.437 64.081c-95.756 197.397 150.422 79.65 184.722 117.583Z"
          fill="#E6E6E6"
        />
        <path
          d="M371.617 193.063c43.555 48.167-82.703 117.583-184.723 117.583-102.02 0-172.6-53.785-184.722-117.583C-27.22 38.376 252.588-59.943 186.894 75.481c-95.756 197.396 150.423 79.65 184.723 117.582Z"
          fill="#3F3D56"
        />
        <path
          d="M60.904 80.133C30.342 78.788 6.645 72.195 6.957 65.125c.186-4.24 8.836-7.648 23.73-9.352a.409.409 0 0 1 .453.358.407.407 0 0 1-.36.451c-14.03 1.605-22.847 4.893-23.01 8.579-.281 6.393 24.067 12.877 53.17 14.159 29.103 1.281 53.927-3.038 54.208-9.431.163-3.702-8.385-7.763-22.31-10.597a.406.406 0 0 1-.143-.74.407.407 0 0 1 .306-.058c14.779 3.009 23.148 7.175 22.961 11.43-.311 7.07-24.496 11.555-55.058 10.21ZM33.76 81.318a31.342 31.342 0 0 0 54.163 2.248 493.428 493.428 0 0 1-54.163-2.248Z"
          fill="#6C63FF"
        />
        <path
          d="M91.304 75.002a31.347 31.347 0 1 0-60.153-2.323 232.973 232.973 0 0 0 60.153 2.323Z"
          fill="#6C63FF"
        />
        <path
          d="M68.737 53.986a3.664 3.664 0 1 0 0-7.329 3.664 3.664 0 0 0 0 7.329ZM49.879 68.231a6.107 6.107 0 1 0 0-12.213 6.107 6.107 0 0 0 0 12.213Z"
          fill="#fff"
        />
        <path
          d="M142.05 62.25a4.071 4.071 0 1 0 0-8.143 4.071 4.071 0 0 0 0 8.142Z"
          fill="#6C63FF"
        />
        <path
          d="M50.038 186.831a2.443 2.443 0 1 0 0-4.886 2.443 2.443 0 0 0 0 4.886ZM221.033 270.7a2.443 2.443 0 1 0 0-4.887 2.443 2.443 0 0 0 0 4.887ZM61.845 139.604a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM162.813 230.801a1.222 1.222 0 1 0 0-2.443 1.222 1.222 0 0 0 0 2.443ZM346.429 218.994a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM289.024 255.229a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM229.175 211.259a1.222 1.222 0 1 0 0-2.443 1.222 1.222 0 0 0 0 2.443ZM151.821 90.749a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM161.592 30.493a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM155.892 284.135a1.22 1.22 0 1 0 0-2.442 1.22 1.22 0 0 0 0 2.442ZM95.637 258.486a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443ZM286.581 214.516a1.221 1.221 0 1 0 0-2.443 1.221 1.221 0 0 0 0 2.443Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.407 95.186a3.664 3.664 0 1 0 0-7.328 3.664 3.664 0 0 0 0 7.328ZM143.271 147.747c0 10.943-16.92 36.444-21.865 43.663a1.62 1.62 0 0 1-1.341.708 1.619 1.619 0 0 1-1.342-.708c-4.945-7.219-21.865-32.72-21.865-43.663a23.207 23.207 0 1 1 46.413 0Z"
          fill="#fff"
        />
        <path
          d="M120.065 157.925c6.52 0 11.806-5.286 11.806-11.807 0-6.52-5.286-11.806-11.806-11.806-6.521 0-11.807 5.286-11.807 11.806 0 6.521 5.286 11.807 11.807 11.807Z"
          fill="#6C63FF"
        />
        <path
          d="M119.861 204.745c7.533 0 13.639-1.185 13.639-2.647 0-1.461-6.106-2.646-13.639-2.646-7.533 0-13.639 1.185-13.639 2.646 0 1.462 6.106 2.647 13.639 2.647Z"
          fill="#fff"
        />
        <path
          d="M192.589 180.72c2.305-1.478-.498-9.965-6.263-18.957-5.764-8.991-12.305-15.082-14.611-13.604-2.305 1.478.498 9.965 6.262 18.956 5.765 8.992 12.306 15.083 14.612 13.605Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h400v310.646H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
