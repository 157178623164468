import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../../../../../../../../../themes/theme';
import { PatientFaceSheet } from '../../../CareGapsDrawer/Container';
import { useEligibilityBadge } from '../../../../../../../../../hooks/useEligibilityBadge';
import { useGetPatientEligibilityRealTimeLazyQuery } from './index.generated';
import {
  AutoRetryType,
  MAX_RETRY_TIME,
  useAutoRetry,
} from '../../../../../../../../../hooks/useAutoRetry';
import {
  EligibilityCheckButtonStatusType,
  useEligibilityCheckButtonStatus,
} from '../../../../../../../../../hooks/useEligibilityCheckButtonStatus';
import { getEligibilityBadgeChipColor, getStatusText } from './utils';
import { useEligibilityCheckStatus } from '../../../../../../../../../hooks/useEligibilityCheckStatus';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { MixPanelEvents } from '../../../../../../../../../mixpanel/events';
interface CheckEligibilityProps {
  patientInfo: PatientFaceSheet;
  hardRefresh: () => void;
}

export default function CheckEligibility(props: CheckEligibilityProps) {
  const { patientInfo, hardRefresh } = props;
  const { eligibilityBadge } = useEligibilityBadge('', patientInfo);
  const [
    checkEligibilityQuery,
    { data, loading, called },
  ] = useGetPatientEligibilityRealTimeLazyQuery();

  const { eligibilityCheckStatus } = useEligibilityCheckStatus(
    called,
    loading,
    data,
    hardRefresh,
    patientInfo
  );
  const { autoRetry } = useAutoRetry(eligibilityCheckStatus);

  const onCheck = () => {
    MixPanelEvents.timeEvent(
      MixPanelEvents.userActivityAndJourneyMapping.checkEligibilityProcessTime
        .name
    );
    if (!loading) {
      checkEligibilityQuery({
        variables: {
          mpatientId: Number(patientInfo.id),
        },
      });
    }
  };

  React.useEffect(() => {
    if (autoRetry.retry && autoRetry.currentRetryTime < MAX_RETRY_TIME) {
      checkEligibilityQuery({
        variables: {
          mpatientId: Number(patientInfo.id),
        },
      });
    }
  }, [autoRetry]);

  const classes = useStyles({
    isLoading: loading,
  });
  const { eligibilityCheckButtonStatus } = useEligibilityCheckButtonStatus(
    patientInfo,
    autoRetry,
    eligibilityCheckStatus,
    classes
  );

  return (
    <div>
      <Box className={classes.checkEligibilityContainer}>
        {/* Eligibility Check */}
        <EligibilityCheckButton
          loading={loading}
          classes={classes}
          onCheck={onCheck}
          eligibilityCheckButtonStatus={eligibilityCheckButtonStatus}
          autoRetry={autoRetry}
          disabled={true}
        />
        {patientInfo.eligibilityInfo && (
          <>
            {/* Eligibility Last Check Chip */}
            <EligibilityLastCheckChip
              styles={getEligibilityBadgeChipColor(
                eligibilityBadge?.status || '',
                eligibilityBadge?.lastCheck.split('Last Check: ')[1] || ''
              )}
              icon={eligibilityBadge?.icon}
              label={getStatusText(eligibilityBadge?.status || '')}
            />

            {/* Eligibility Check Info */}
            {patientInfo?.eligibilityInfo?.updateTime ? (
              <div className={classes.checkEligibilityDescWrapper}>
                <Typography
                  variant={'body1'}
                  className={classes.checkEligibilityDesc}
                >{`${eligibilityBadge?.lastCheck}, PCP: ${
                  patientInfo.eligibilityInfo.pcpExternal?.trim() || 'No Data'
                }`}</Typography>
              </div>
            ) : null}
          </>
        )}
      </Box>
    </div>
  );
}

const useStyles = makeStyles({
  disabledApplyFilterBtn: {
    color: 'rgba(255,255,255,0.5)!important',
    background: `${theme.palette.primary.main}!important`,
  },
  checkEligibilityContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(3, 0, 0, 3),
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiButton-containedSizeSmall': {
      paddingLeft: 12,
      paddingRight: 12,
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        cursor: (props: any) => (props.isLoading ? 'default' : 'pointer'),
      },
    },
    '& .MuiButton-label': {
      gap: 4,
    },
    '& .MuiButton-startIcon.MuiButton-iconSizeSmall': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  checkEligibilityButton: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
  },
  checkEligibilityButtonLoading: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    opacity: 1,
  },
  checkEligibilityDesc: {
    fontSize: 14,
    color: theme.palette.grey[700],
    lineHeight: '24px',
    fontWeight: 500,
  },
  checkingProcessContainer: {
    width: 'fit-content',
    height: 24,
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(0.5, 1),
    color: theme.palette.primary.main,
  },
  checkingProcessIconWrapper: {
    color: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  checkEligibilityDescWrapper: {
    width: 'fit-content',
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

interface EligibilityCheckButtonProps {
  loading: boolean;
  classes: ClassNameMap;
  onCheck: () => void;
  eligibilityCheckButtonStatus: EligibilityCheckButtonStatusType;
  autoRetry: AutoRetryType;
  disabled?: boolean;
}

const EligibilityCheckButton = (props: EligibilityCheckButtonProps) => {
  const {
    loading,
    classes,
    onCheck,
    eligibilityCheckButtonStatus,
    autoRetry,
  } = props;

  // check + retry button
  const EligibilityCheckRetryButton = () => {
    return (
      <StyledTooltip
        title="5 auto attempts failed, Please try again."
        placement="bottom-start"
        disableHoverListener={
          eligibilityCheckButtonStatus.buttonContent?.text !== 'Re-try'
        }
      >
        <span>
          <Button
            id="apply-button"
            variant="contained"
            onClick={onCheck}
            startIcon={eligibilityCheckButtonStatus.buttonContent?.icon}
            size={'small'}
            disableElevation
            disableRipple
            disabled={props.disabled}
            classes={{
              disabled: classes.disabledApplyFilterBtn,
            }}
          >
            <Typography
              className={eligibilityCheckButtonStatus.buttonContent?.textStyle}
            >
              {eligibilityCheckButtonStatus.buttonContent?.text}
            </Typography>
          </Button>
        </span>
      </StyledTooltip>
    );
  };

  // checking label
  const CheckingLabel = () => {
    return (
      <div className={classes.checkingProcessContainer}>
        <div className={classes.checkingProcessIconWrapper}>
          <CircularProgress size={'1rem'} color={'primary'} />
        </div>
        <Typography className={classes.checkEligibilityButtonLoading}>
          {`Checking Eligibility ${
            autoRetry.currentRetryTime + 1
          }/${MAX_RETRY_TIME}`}
        </Typography>
      </div>
    );
  };

  /*
  show button => true
     => default => Eligibility Check
     => after 5 times retry => Retry

  loading => true
    => show button false
    => show checking label
 */
  return (
    <div>
      {loading ? (
        <CheckingLabel />
      ) : eligibilityCheckButtonStatus.showButton ? (
        <EligibilityCheckRetryButton />
      ) : null}
    </div>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    width: '130px',
    maxWidth: 155,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    padding: '8px 12px 8px 12px',
  },
  popper: {
    top: '-24px !important',
    left: '12px !important',
  },
})(Tooltip);

const useEligibilityLastCheckChipStyles = makeStyles({
  root: {
    width: 'fit-content',
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    width: 'fit-content',
    height: 24,
    boxSizing: 'border-box',
    padding: theme.spacing(0.25, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4,
    borderRadius: 6,
  },
  iconContainer: {
    width: 16,
    height: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelContainer: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
  },
});
interface EligibilityLastCheckChipProps {
  icon: JSX.Element | undefined;
  label: string;
  styles: any;
}
const EligibilityLastCheckChip = (props: EligibilityLastCheckChipProps) => {
  const { icon, label, styles } = props;
  const classes = useEligibilityLastCheckChipStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.container}
        style={{ backgroundColor: styles.backgroundColor }}
      >
        <div className={classes.iconContainer} style={{ color: styles.color }}>
          {icon}
        </div>
        <div className={classes.labelContainer} style={{ color: styles.color }}>
          {label}
        </div>
      </div>
    </div>
  );
};
