import { Grid, Typography } from '@material-ui/core';
import { CheckPrivilegesForDisplay } from '../CheckPrivilegesForDisplay';
import { useHomePageHeaderStyles } from './Styles';
import React from 'react';

interface HomePageHeaderProps {
  headerTitle: string;
  headerActionElement: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  privilege: {
    checkPrivilege: boolean;
    neededPrivileges: string;
  };
}

export const HomePageHeader = (props: HomePageHeaderProps) => {
  const classes = useHomePageHeaderStyles();
  const { headerTitle, headerActionElement, privilege } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.headerMainRow}>
        {/* page title */}
        <Typography variant={'h2'}>{headerTitle}</Typography>

        {/* action element */}
        {privilege.checkPrivilege ? (
          <CheckPrivilegesForDisplay
            neededPrivileges={privilege.neededPrivileges}
            element={headerActionElement}
          />
        ) : (
          headerActionElement
        )}
      </Grid>
    </Grid>
  );
};
