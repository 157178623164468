import * as Types from '../../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type ResendTemporaryPasswordMutationVariables = Types.Exact<{
  input: Types.UserIdsInBatch;
}>;


export type ResendTemporaryPasswordMutation = (
  { __typename: 'Mutation' }
  & { resendTemporaryPassword: (
    { __typename: 'OperationResult' }
    & Pick<Types.OperationResult, 'result'>
  ) }
);


export const ResendTemporaryPasswordDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resendTemporaryPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserIdsInBatch"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendTemporaryPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userIdsInBatch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}}]}}]};
export type ResendTemporaryPasswordMutationFn = Apollo.MutationFunction<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>;

/**
 * __useResendTemporaryPasswordMutation__
 *
 * To run a mutation, you first call `useResendTemporaryPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTemporaryPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTemporaryPasswordMutation, { data, loading, error }] = useResendTemporaryPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendTemporaryPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>) {
        return Apollo.useMutation<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>(ResendTemporaryPasswordDocument, baseOptions);
      }
export type ResendTemporaryPasswordMutationHookResult = ReturnType<typeof useResendTemporaryPasswordMutation>;
export type ResendTemporaryPasswordMutationResult = Apollo.MutationResult<ResendTemporaryPasswordMutation>;
export type ResendTemporaryPasswordMutationOptions = Apollo.BaseMutationOptions<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>;