import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGetPanelSummaryQuery } from '../PanelSummary/index.generated';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { EngagementLog } from './index';
import {EngagementLogStatus} from "../PanelPatients/components/PatientEngagementTable/Container";

interface IProps {
  rootLink: string
}

export const EngagementLogContainer = (props: IProps) => {
  const {rootLink = "panels"} = props
  const location = useLocation();
  const navigate = useNavigate();
  const {providerId, id, batchId, status } = useParams();
  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const panel = useGetPanelSummaryQuery({
    variables: {
      input: {
        id: id!,
      },
    },
  });
  if (!panel.called || panel.loading) {
    return <LoadingSpinner />;
  }
  const { name } = panel.data?.getPanel!;
  const breadCrumbHeader = rootLink === 'panels' ? {
    isTitle: true,
    title: `${name} Engagement Log`,
    rootLink: `/panels/` + id + `/summary`,
    rootName: `${name}`,
    childrenLink: `/panels/` + id + `/engagement`,
    childrenName: 'Engagements',
    childrenChildrenName: 'Send to',
  } : {
    isTitle: true,
    title: `${name} Engagement Log`,
    rootLink: `/pcp/${providerId}/${id}/summary`,
    rootName: `${name}`,
    childrenLink: `/pcp/${providerId}/${id}/engagement`,
    childrenName: 'Engagements',
    childrenChildrenName: 'Send to',
  }
  let engagementLogStatus = EngagementLogStatus.sending
  if (status && Object.values<string>(EngagementLogStatus).includes(status)) {
      engagementLogStatus = status as EngagementLogStatus
  }

  return (<EngagementLog
    engagementLogStatus={engagementLogStatus}
    limit={limit ?? 10}
    skip={skip ?? 0}
    panelId={id}
    panelName={name}
    setLimit={onSetLimit}
    setSkip={onSetSkip}
    batchId={batchId}
    breadCrumbHeader={breadCrumbHeader}
  />);
};
