import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetRolesByAccountIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRolesByAccountIdQuery = (
  { __typename: 'Query' }
  & { getRolesByAccountId?: Types.Maybe<Array<(
    { __typename: 'Role' }
    & Pick<Types.Role, 'id' | 'roleName'>
  )>> }
);

export type ResendTemporaryPasswordMutationVariables = Types.Exact<{
  input: Types.UserIdsInBatch;
}>;


export type ResendTemporaryPasswordMutation = (
  { __typename: 'Mutation' }
  & { resendTemporaryPassword: (
    { __typename: 'OperationResult' }
    & Pick<Types.OperationResult, 'result'>
  ) }
);

export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename: 'Mutation' }
  & { updateUser: (
    { __typename: 'User' }
    & Pick<Types.User, 'id' | 'accountId' | 'email' | 'lastName' | 'firstName' | 'status' | 'createdAt' | 'updatedAt' | 'phone'>
    & { role?: Types.Maybe<(
      { __typename: 'Role' }
      & Pick<Types.Role, 'id' | 'roleName'>
    )>, createdBy?: Types.Maybe<(
      { __typename: 'Operator' }
      & Pick<Types.Operator, 'user' | 'service'>
    )>, updatedBy?: Types.Maybe<(
      { __typename: 'Operator' }
      & Pick<Types.Operator, 'user' | 'service'>
    )> }
  ) }
);

export type GetClinicAndDivisionPairsByUserIdQueryVariables = Types.Exact<{
  input: Types.GetUserClinicAndDivisionByUserIdRequest;
}>;


export type GetClinicAndDivisionPairsByUserIdQuery = (
  { __typename: 'Query' }
  & { getClinicAndDivisionPairsByUserID?: Types.Maybe<Array<(
    { __typename: 'GetClinicAndDivisionPairsByUserIDResponse' }
    & Pick<Types.GetClinicAndDivisionPairsByUserIdResponse, 'clinicID'>
    & { divisions: Array<(
      { __typename: 'ClinicAndDivisionPairOutPut' }
      & Pick<Types.ClinicAndDivisionPairOutPut, 'divisionID' | 'divisionName'>
    )> }
  )>> }
);


export const GetRolesByAccountIdDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getRolesByAccountId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getRolesByAccountId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"roleName"}}]}}]}}]};

/**
 * __useGetRolesByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetRolesByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesByAccountIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesByAccountIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesByAccountIdQuery, GetRolesByAccountIdQueryVariables>) {
        return Apollo.useQuery<GetRolesByAccountIdQuery, GetRolesByAccountIdQueryVariables>(GetRolesByAccountIdDocument, baseOptions);
      }
export function useGetRolesByAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesByAccountIdQuery, GetRolesByAccountIdQueryVariables>) {
          return Apollo.useLazyQuery<GetRolesByAccountIdQuery, GetRolesByAccountIdQueryVariables>(GetRolesByAccountIdDocument, baseOptions);
        }
export type GetRolesByAccountIdQueryHookResult = ReturnType<typeof useGetRolesByAccountIdQuery>;
export type GetRolesByAccountIdLazyQueryHookResult = ReturnType<typeof useGetRolesByAccountIdLazyQuery>;
export type GetRolesByAccountIdQueryResult = Apollo.QueryResult<GetRolesByAccountIdQuery, GetRolesByAccountIdQueryVariables>;
export function refetchGetRolesByAccountIdQuery(variables?: GetRolesByAccountIdQueryVariables) {
      return { query: GetRolesByAccountIdDocument, variables: variables }
    }
export const ResendTemporaryPasswordDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resendTemporaryPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserIdsInBatch"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendTemporaryPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userIdsInBatch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}}]}}]};
export type ResendTemporaryPasswordMutationFn = Apollo.MutationFunction<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>;

/**
 * __useResendTemporaryPasswordMutation__
 *
 * To run a mutation, you first call `useResendTemporaryPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTemporaryPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTemporaryPasswordMutation, { data, loading, error }] = useResendTemporaryPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendTemporaryPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>) {
        return Apollo.useMutation<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>(ResendTemporaryPasswordDocument, baseOptions);
      }
export type ResendTemporaryPasswordMutationHookResult = ReturnType<typeof useResendTemporaryPasswordMutation>;
export type ResendTemporaryPasswordMutationResult = Apollo.MutationResult<ResendTemporaryPasswordMutation>;
export type ResendTemporaryPasswordMutationOptions = Apollo.BaseMutationOptions<ResendTemporaryPasswordMutation, ResendTemporaryPasswordMutationVariables>;
export const UpdateUserDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"accountId"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"roleName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"service"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"service"}}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]}}]};
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetClinicAndDivisionPairsByUserIdDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getClinicAndDivisionPairsByUserID"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetUserClinicAndDivisionByUserIDRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getClinicAndDivisionPairsByUserID"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clinicID"}},{"kind":"Field","name":{"kind":"Name","value":"divisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"divisionID"}},{"kind":"Field","name":{"kind":"Name","value":"divisionName"}}]}}]}}]}}]};

/**
 * __useGetClinicAndDivisionPairsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetClinicAndDivisionPairsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicAndDivisionPairsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicAndDivisionPairsByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClinicAndDivisionPairsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetClinicAndDivisionPairsByUserIdQuery, GetClinicAndDivisionPairsByUserIdQueryVariables>) {
        return Apollo.useQuery<GetClinicAndDivisionPairsByUserIdQuery, GetClinicAndDivisionPairsByUserIdQueryVariables>(GetClinicAndDivisionPairsByUserIdDocument, baseOptions);
      }
export function useGetClinicAndDivisionPairsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicAndDivisionPairsByUserIdQuery, GetClinicAndDivisionPairsByUserIdQueryVariables>) {
          return Apollo.useLazyQuery<GetClinicAndDivisionPairsByUserIdQuery, GetClinicAndDivisionPairsByUserIdQueryVariables>(GetClinicAndDivisionPairsByUserIdDocument, baseOptions);
        }
export type GetClinicAndDivisionPairsByUserIdQueryHookResult = ReturnType<typeof useGetClinicAndDivisionPairsByUserIdQuery>;
export type GetClinicAndDivisionPairsByUserIdLazyQueryHookResult = ReturnType<typeof useGetClinicAndDivisionPairsByUserIdLazyQuery>;
export type GetClinicAndDivisionPairsByUserIdQueryResult = Apollo.QueryResult<GetClinicAndDivisionPairsByUserIdQuery, GetClinicAndDivisionPairsByUserIdQueryVariables>;
export function refetchGetClinicAndDivisionPairsByUserIdQuery(variables?: GetClinicAndDivisionPairsByUserIdQueryVariables) {
      return { query: GetClinicAndDivisionPairsByUserIdDocument, variables: variables }
    }