import React from 'react';
import { GlobalContext } from '../../../../components/GlobalContext';
import {
  GetUsersByAccountIdQuery,
  useGetUsersByAccountIdQuery,
} from '../../../Users/MsoAdmin/Tabs/AllUsers/index.generated';
import {
  GetPanelSummaryQuery,
  useGetPanelSummaryQuery,
} from '../../ViewPanels/PanelSummary/index.generated';
import {
  useGetTaskStatusQuery,
  useUpdateCareCoordinatorMutation,
} from './index.generated';

import { ManageCoordinatorDialogView } from './View';
import { useReassignPatientsMutation } from '../../ViewPanels/components/ChangeCoordinatorDialog/index.generated';
import { CheckIsHudsonClinician } from '../../../../components/CheckIsAdmin';

interface AssignCoordinatorDialogContainerProps {
  setShowManageCoordinatorDialog: (input: any) => void;
  panelSummary: {
    isSkipped: boolean;
    setIsUpdated: (input: boolean) => void;
    panelId: string;
    assignedCoordinators: any[];
    unassignedPatientCount: number;
    coordinatorOptions: any[];
  };
  loading: boolean | undefined;
  setLoading: (input: boolean | undefined) => void;
  source?: string;
}
//http://localhost:3000/panels/01GG7YPDJBMTS08A89KWDSZWSD/summary

export const ManageCoordinatorDialog = (
  props: AssignCoordinatorDialogContainerProps
) => {
  const {
    setShowManageCoordinatorDialog,
    panelSummary,
    loading,
    setLoading,
  } = props;

  const { setToastMessage } = React.useContext(GlobalContext);
  const [taskId, setTaskId] = React.useState('');
  const [clicked, setClicked] = React.useState(false);
  const [assignedPatients, setAssignedPatients] = React.useState(0);
  const [onConfirm, setOnConfirm] = React.useState(false);
  // get assigned coordinator and count
  const panelQuery = useGetPanelSummaryQuery({
    variables: {
      input: {
        id: panelSummary.panelId,
      },
    },
    skip: panelSummary.isSkipped,
  });

  const getAssignedCoordinators = (query: GetPanelSummaryQuery | undefined) => {
    return query?.getPanel?.careTeamUsers;
  };

  const getPatientCount = (query: GetPanelSummaryQuery | undefined) => {
    return query?.getPanel?.patientCount;
  };

  const getUnassignedPatientCount = (
    query: GetPanelSummaryQuery | undefined
  ) => {
    return query?.getPanel?.unassignedPatientCount;
  };

  const assignedCoordinators = React.useMemo(() => {
    if (panelSummary.isSkipped) {
      return panelSummary.assignedCoordinators;
    } else {
      return getAssignedCoordinators(panelQuery.data);
    }
  }, [panelQuery.data]);

  const unassignedPatientCount = React.useMemo(() => {
    if (panelSummary.isSkipped) {
      return panelSummary.unassignedPatientCount;
    } else {
      return getUnassignedPatientCount(panelQuery.data);
    }
  }, [panelQuery.data]);

  const allPatientCount = React.useMemo(() => {
    if (panelSummary.isSkipped) {
      return panelSummary.unassignedPatientCount;
    } else {
      return getPatientCount(panelQuery.data);
    }
  }, [panelQuery.data]);

  // update panel with new coordinators
  const [reassignPatientsMutation, { error }] = useReassignPatientsMutation();

  const editCoordinators = async (newCareTeamUsers: any) => {
    try {
      const { data } = await reassignPatientsMutation({
        variables: {
          input: {
            fromCareTeamUsers:
              assignedCoordinators?.map((item: any) => {
                return {
                  count: item.count,
                  userId: item.user.id,
                };
              }) || [],
            toCareTeamUsers: newCareTeamUsers,
            panelId: panelSummary.panelId,
          },
        },
      });

      if (
        data &&
        data.reassignPatients.entityId &&
        data.reassignPatients.taskId
      ) {
        panelSummary.setIsUpdated(true);
        setTaskId(data.reassignPatients.taskId);
        // setShowManageCoordinatorDialog(false);
        // panelQuery.refetch();
        setToastMessage({
          snackbarMsg: `Care Coordinator assignment updated.`,
          severity: 'success',
          isOpen: true,
        });
        // setIsOpenToast(true);
      } else if (error) {
        // error response
        setToastMessage({
          snackbarMsg: `Fail to assign patients. Please try again.`,
          severity: 'error',
          isOpen: true,
        });
        // setIsOpenToast(true);
        setShowManageCoordinatorDialog(false);
        setOnConfirm(false);
      }
    } catch (err) {
      setToastMessage({
        snackbarMsg: `Fail to assign patients. Please try again.`,
        severity: 'error',
        isOpen: true,
      });
      // setIsOpenToast(true);
      setShowManageCoordinatorDialog(false);
    }
  };

  // get users as coordinators
  const userQuery = useGetUsersByAccountIdQuery({
    variables: {
      getUsersByAccountIdRequest: {
        limit: 0,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
    skip: panelSummary.isSkipped,
  });

  const getUserOptions = (query: GetUsersByAccountIdQuery | undefined) => {
    return query?.getUsersByAccountId?.results.filter(
      (item) => !CheckIsHudsonClinician(item)
    );
  };
  const coordinatorOptions = React.useMemo(() => {
    if (panelSummary.isSkipped) {
      return panelSummary.coordinatorOptions;
    } else {
      return getUserOptions(userQuery.data);
    }
  }, [userQuery.data]);

  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !(clicked && taskId), // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (clicked && taskId) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        queryTask.stopPolling();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setLoading(true);
        queryTask.startPolling(1000);
      }
    }
  }, [clicked, taskId, queryTask?.data?.getTaskStatus.status]);

  return (
    <div>
      <ManageCoordinatorDialogView
        setShowManageCoordinatorDialog={setShowManageCoordinatorDialog}
        firstQueryLoading={panelQuery.loading}
        assignedCoordinators={assignedCoordinators ?? []}
        coordinatorOptions={coordinatorOptions ?? []}
        allPatientCount={allPatientCount ?? 0}
        unassignedPatientCount={unassignedPatientCount ?? 0}
        editCoordinators={editCoordinators}
        loading={loading}
        clicked={clicked}
        setClicked={setClicked}
        assignedPatients={assignedPatients}
        setAssignedPatients={setAssignedPatients}
        onConfirm={onConfirm}
        setOnConfirm={setOnConfirm}
        source={props.source}
        delistedPatientCount={
          panelQuery.data?.getPanel?.delistedPatientCount ?? 0
        }
      />
    </div>
  );
};
