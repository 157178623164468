import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetClinicsAndDivisionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClinicsAndDivisionQuery = (
  { __typename: 'Query' }
  & { getCareGapFilterOptions?: Types.Maybe<(
    { __typename: 'QMFilterOptions' }
    & { Clinics?: Types.Maybe<Array<(
      { __typename: 'NameValue' }
      & Pick<Types.NameValue, 'name' | 'value'>
    )>>, Divisions?: Types.Maybe<Array<(
      { __typename: 'NameValue' }
      & Pick<Types.NameValue, 'name' | 'value'>
    )>> }
  )> }
);

export type CreateNewUserMutationVariables = Types.Exact<{
  input: Types.CreateUserInput;
}>;


export type CreateNewUserMutation = (
  { __typename: 'Mutation' }
  & { createUser: (
    { __typename: 'User' }
    & Pick<Types.User, 'id' | 'accountId' | 'email' | 'firstName' | 'lastName' | 'status' | 'createdAt' | 'phone' | 'errorType' | 'errorMessage'>
    & { role?: Types.Maybe<(
      { __typename: 'Role' }
      & Pick<Types.Role, 'id' | 'roleName'>
    )>, createdBy?: Types.Maybe<(
      { __typename: 'Operator' }
      & Pick<Types.Operator, 'user' | 'firstName' | 'lastName'>
    )> }
  ) }
);


export const GetClinicsAndDivisionDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getClinicsAndDivision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCareGapFilterOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Clinics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"Field","name":{"kind":"Name","value":"Divisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]};

/**
 * __useGetClinicsAndDivisionQuery__
 *
 * To run a query within a React component, call `useGetClinicsAndDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicsAndDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicsAndDivisionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClinicsAndDivisionQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicsAndDivisionQuery, GetClinicsAndDivisionQueryVariables>) {
        return Apollo.useQuery<GetClinicsAndDivisionQuery, GetClinicsAndDivisionQueryVariables>(GetClinicsAndDivisionDocument, baseOptions);
      }
export function useGetClinicsAndDivisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicsAndDivisionQuery, GetClinicsAndDivisionQueryVariables>) {
          return Apollo.useLazyQuery<GetClinicsAndDivisionQuery, GetClinicsAndDivisionQueryVariables>(GetClinicsAndDivisionDocument, baseOptions);
        }
export type GetClinicsAndDivisionQueryHookResult = ReturnType<typeof useGetClinicsAndDivisionQuery>;
export type GetClinicsAndDivisionLazyQueryHookResult = ReturnType<typeof useGetClinicsAndDivisionLazyQuery>;
export type GetClinicsAndDivisionQueryResult = Apollo.QueryResult<GetClinicsAndDivisionQuery, GetClinicsAndDivisionQueryVariables>;
export function refetchGetClinicsAndDivisionQuery(variables?: GetClinicsAndDivisionQueryVariables) {
      return { query: GetClinicsAndDivisionDocument, variables: variables }
    }
export const CreateNewUserDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createNewUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"accountId"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"roleName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"errorType"}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]};
export type CreateNewUserMutationFn = Apollo.MutationFunction<CreateNewUserMutation, CreateNewUserMutationVariables>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewUserMutation, CreateNewUserMutationVariables>) {
        return Apollo.useMutation<CreateNewUserMutation, CreateNewUserMutationVariables>(CreateNewUserDocument, baseOptions);
      }
export type CreateNewUserMutationHookResult = ReturnType<typeof useCreateNewUserMutation>;
export type CreateNewUserMutationResult = Apollo.MutationResult<CreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<CreateNewUserMutation, CreateNewUserMutationVariables>;