import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import { MainRegion } from '../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../components/BreadCrumbHeader';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { useGetNewPatientsQuery } from '../../Files/FileDetailPage/index.generated';
import { useGetPayerFileByIdQuery } from '../../Files/index.generated';
import { ItemListTableView } from '../../../../Insurances/components/InsuranceFileNewPatientListTable/View';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const PayerPayerFileNewPatientPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { payerId, fileId } = useParams();
  const [title, setTitle] = React.useState('');

  const resp = useGetPayerFileByIdQuery({
    variables: {
      request: {
        fileId: fileId ?? '',
      },
    },
  });
  const queryResult = useFetchTableData(resp);

  React.useEffect(() => {
    try {
      setTitle(queryResult?.getPayerFileById?.fileName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [queryResult]);

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, {
      state: { payerId: payerId, name: location?.state.name },
    });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  const [sortBy, setSortBy] = React.useState({
    field: 'fullname',
    method: 'asc' as const,
  });

  const patients = useGetNewPatientsQuery({
    variables: {
      request: {
        fileId: fileId,
        limit: limit ?? 10,
        skip: skip ?? 0,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  const patientsResult = useFetchTableData(patients);

  if (!resp.called || resp.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
  }

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={location.state.name}
          rootLink={`/payerID`}
          rootName={'Payer ID'}
          isTitle={true}
          childrenLink={`/payerID/` + payerId}
          title={title}
          childrenChildrenName={title}
          childrenState={location.state.name}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <ItemListTableView
                refetchDataFunc={{
                  func: patients.refetch,
                  loading: patients.loading,
                  params: {
                    fileId: fileId,
                    limit: limit ?? 10,
                    skip: skip ?? 0,
                    sortBy: [{ ...sortBy }],
                  },
                }}
                setSortBy={setSortBy}
                sortBy={sortBy}
                rows={
                  patientsResult ? patientsResult.getNewPatients?.results : []
                }
                count={
                  patientsResult ? patientsResult.getNewPatients?.count : 0
                }
                fileId={fileId ?? ''}
                limit={limit ?? 10}
                skip={skip ?? 0}
                setLimit={onSetLimit}
                setSkip={onSetSkip}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
