import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { theme } from '../../../themes/theme';
import moment from 'moment'

export interface EligibilityBarchartDataType {
  activeInternal: number[];
  activeExternal: number[];
  rejected: number[];
  inactive: number[];
  unavailable: number[];
  xMonth: string[];
}

interface StackedBarChartsProps {
  height: number;
  data: EligibilityBarchartDataType;
}

export const StackedBarCharts = (props: StackedBarChartsProps) => {
  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: '#434343',
      borderColor: '#434343',
      formatter: function (params: any) {
        return params.data + ' ' + params.seriesName;
      },
      textStyle: {
        color: '#FFFFFF',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '24px',
      },
    },
    title: {
      text: 'Last 12 Months',
      right: '5%',
      textStyle: {
        color: '#656565',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    legend: { icon: "circle", left: 20, itemWidth: 8, itemHeight: 8 },
    grid: {
      left: '24px',
      right: '24px',
      bottom: '60px',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: props.data.xMonth,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Active (Internal)',
        type: 'bar',
        barWidth: 8,
        stack: 'Eligibility',
        emphasis: {
          focus: 'none',
        },
        color: '#519548',
        data: props.data.activeInternal,
      },
      {
        name: 'Active (External)',
        type: 'bar',
        barWidth: 8,
        stack: 'Eligibility',
        emphasis: {
          focus: 'none',
        },
        color: '#41DAAF',
        data: props.data.activeExternal,
      },
      {
        name: 'Inactive',
        type: 'bar',
        barWidth: 8,
        stack: 'Eligibility',
        emphasis: {
          focus: 'none',
        },
        color:  '#FA2A00',
        data: props.data.inactive,
      },
      {
        name: 'Unverified',
        type: 'bar',
        barWidth: 8,
        stack: 'Eligibility',
        emphasis: {
          focus: 'none',
        },
        color: '#3B89C9',
        data: props.data.rejected,
      },
      {
        name: 'Unavailable',
        type: 'bar',
        barWidth: 8,
        stack: 'Eligibility',
        emphasis: {
          focus: 'none',
        },
        color: '#707070',
        data: props.data.unavailable,
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '100%', padding: '12px 0px' }}>
      <ReactEcharts option={option} style={{ height: props.height }} />
    </div>
  );
};
