import React from 'react';
import { PatientFaceInfo } from '../../../../../../app.types.generated';
import { GlobalContext } from '../../../../../../components/GlobalContext';

export const useReadOnly = (patientInfo: PatientFaceInfo) => {
  const { loggedInUser } = React.useContext(GlobalContext);
  const [readOnly, setReadOnly] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleDisclosureTab = () => {
    setHidden(false);
  };

  React.useEffect(() => {
    if (patientInfo.careLockInfo) {
      // if has lock info
      if (patientInfo.careLockInfo.isLocked) {
        // and it's locked
        if (loggedInUser?.id === patientInfo.careLockInfo.updatedBy!.userId) {
          // the person who locks the patient can still work on it
          setReadOnly(false);
          setHidden(false);
        } else {
          // others will be locked
          setReadOnly(true);
          setHidden(true);
        }
      } else {
        // if current it's not locked
        setReadOnly(false);
        setHidden(false);
      }
    } else {
      // no lock info which means it's unlocked
      setReadOnly(false);
      setHidden(false);
    }
  }, [patientInfo, loggedInUser]);

  return { readOnly, hidden, handleDisclosureTab };
};
