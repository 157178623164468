import React from 'react';
import { EmptyPlaceHolder } from '../../../../../../../../../components/EmptyPlaceHolder';
import { Chip, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { usePopoverCellStyles } from './Styles';

interface PopoverCellProps {
  // pass the itemStrings array
  itemTitle: string;
  itemStrings: string[] | null | undefined;
}

export const PopoverCell = (props: PopoverCellProps) => {
  const { itemTitle, itemStrings } = props;
  const classes = usePopoverCellStyles();
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const handlePropagationAndDefault = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const handleClick = (event: React.MouseEvent) => {
    handlePropagationAndDefault(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    handlePropagationAndDefault(event);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'subscriberIDList' : undefined;

  if (!itemStrings || itemStrings.length === 0) {
    // if the item string is nil, show the default
    return <EmptyPlaceHolder text={`No ${itemTitle}`} />;
  } else if (itemStrings.length === 1) {
    // if there is only one item,
    return <span>{itemStrings[0]}</span>;
  } else {
    // if there are multiple ids, show a popup to list all
    const newItemStrings = [`${itemTitle}`, ...itemStrings];
    return (
      <>
        <div className={classes.subscriberIDsItem} onClick={handleClick}>
          <span>{itemStrings[0]}</span>
          <Chip className={classes.chip} label={`+${itemStrings.length - 1}`} />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={2}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClick={handlePropagationAndDefault}
          className={classes.popover}
        >
          <List className={classes.list}>
            {newItemStrings.map((item, index) => {
              return (
                <ListItem
                  key={item}
                  className={
                    index === 0 ? classes.listHeader : classes.listItem
                  }
                >
                  <ListItemText primary={item} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </>
    );
  }
};
