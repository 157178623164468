import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useNavigate, useLocation} from 'react-router';
import {MainRegion} from '../../../components/MainRegion';
import {HomePageHeader} from '../../../components/HomePageHeader';
import EligibilityCategoryList, {EligibilityCategory} from "./components/EligibilityCategory/EligibilityCategoryList";
import EligibilityTable from "./components/EligibilityTable/EligibilityTable";
import {useGetEligibilitySummaryQuery} from "./index.generated";
import {LoadingSpinner} from "../../../components/LoadingSpinner";
import moment from 'moment'
import {Update} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {EligibilityTab} from "./components/EligibilityTable/BatchTab";
import {EligibilityCheckLogContainer} from "./components/EligibilityTable";

export const DashBoardView = (props: any) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const [sortBy, setSortBy] = React.useState({
    field: 'checkDate',
    method: 'desc' as const,
  });
  const [tab, setTab] = useState(EligibilityTab.ipopHealthBatch)

  useEffect(() => {
    onSetSkip(0)
  }, [tab])

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
                       skip,
                       limit,
                     }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`);
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const getEligibilitySummaryQuery = useGetEligibilitySummaryQuery()

  if (!getEligibilitySummaryQuery.called || getEligibilitySummaryQuery.loading) {
    return <LoadingSpinner style={{ width: '100%' }} />
  }

  const handleClickStatusBarItem = (type: EligibilityCategory) => {
    navigate(`/eligibility/patients/${type}`)
  }

  const {activeExternal, activeInternal, inactive, rejected, unavailable, updatedDate, nextScheduledDate} = getEligibilitySummaryQuery.data!.getEligibilitySummary!.summaryInfo
  const fUpdated = moment(new Date(updatedDate)).format("MMMM D, HH:mm A")
  const fNextSchedule = moment(new Date(nextScheduledDate)).format("MMMM Do")

  return (
    <MainRegion
      header={
        <HomePageHeader
          headerTitle={`Eligibility`}
          headerActionElement={
            // removed due to NPH-2268
            // <Tooltip
            //   placement={'bottom-end'}
            //   title={ <span className={classes.nextScheduleDateTitle}>{`Next scheduled for ${fNextSchedule}`}</span>}
            // >
            //   <div className={classes.updatedDateContainer}>
            //     <span className={classes.updatedDateTitle}>{`Last updated ${fUpdated}`}</span>
            //     <Update className={classes.icon}/>
            //   </div>
            // </Tooltip>
            <></>
          }
          privilege={{
            checkPrivilege: false,
            neededPrivileges: '',
          }}
        />
      }
    >
      <Grid container className={classes.root}>
        <Grid item xs={12} style={{padding: 24,}}>
          <EligibilityCategoryList
           internalActiveNumber={activeInternal} externalActiveNumber={activeExternal} inactiveNumber={inactive} rejectedNumber={rejected}
           unavailableNumber={unavailable}
            onClick={handleClickStatusBarItem} />
        </Grid>
        <Grid item xs={12} style={{padding: 24,}}>
          <EligibilityCheckLogContainer tab={tab} setTab={setTab} sortBy={sortBy} setSortBy={setSortBy} skip={skip ?? 0} limit={limit ?? 10} setLimit={onSetLimit} setSkip={onSetSkip} />
        </Grid>
      </Grid>
    </MainRegion>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  statusCard: {
    borderRadius: 8,
    padding: '8px 16px',
    backgroundColor: '#FFF',
  },
  updatedDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updatedDateTitle: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    marginRight: 8,
    fontWeight: 500
  },
  nextScheduleDateTitle: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#FFF',
    fontWeight: 600,
    margin: '8px 8px 12px 16px'
  },
  icon: {
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'text',
    },
  },
}));
