import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  styled,
  Theme,
  Typography,
} from '@material-ui/core';
import { theme } from '../../../../../../../../../../themes/theme';
import { DeListPatientDialogStateType } from '../../../../PatientInfo/View';

export type DialogContent = {
  id: string;
  title: string;
  description: JSX.Element;
};

interface DeListPatientDialogProps {
  deListPatientDialogState: DeListPatientDialogStateType;
  dialogContent: DialogContent;
  handleClose: (input: any) => void;
  onConfirm: (input: any) => void;
}

export const DeListPatientDialog: React.FC<DeListPatientDialogProps> = ({
  deListPatientDialogState,
  dialogContent,
  handleClose,
  onConfirm,
}) => {
  const classes = useStyles();
  return (
    <div id="DeListPatientDialog" className={classes.root}>
      <BootstrapDialog
        aria-labelledby="DeListPatientDialog"
        open={deListPatientDialogState.open}
      >
        <div role="DeListPatientDialog-container">
          <Paper className={classes.paperContainer}>
            <Grid container>
              {/* section header */}
              <Grid item xs={12} className={classes.cardHeader}>
                {/* title */}
                <Typography className={classes.cardTitle}>
                  {dialogContent.title}
                </Typography>
              </Grid>

              {/* main region */}
              <Grid item xs={12} className={classes.cardContent}>
                <Grid item xs={12}>
                  {dialogContent.description}
                </Grid>
              </Grid>

              {/* section footer */}
              <Grid item xs={12} className={classes.sectionFooter}>
                <Button
                  id="cancel-btn"
                  variant="contained"
                  style={{ width: 71 }}
                  className={classes.backBtn}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  id="confirm-button"
                  variant="contained"
                  disableElevation
                  className={classes.updateBtn}
                  disabled={deListPatientDialogState.onConfirmClicked}
                  type="button"
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </BootstrapDialog>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paperContainer: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3, 3, 4, 3),
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    padding: theme.spacing(2.5, 0),
  },
  sectionHeader: {
    height: 28,
    borderBottom: '1px solid #d1d1d1',
    color: theme.palette.grey[700],
    fontSize: 20,

    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.4,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.625),
    paddingLeft: theme.spacing(3),
  },
  sectionFooter: {
    position: 'relative',
    height: 72,
    textTransform: 'capitalize',
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backBtn: {
    // width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    margin: theme.spacing(1.875, 'auto', 2, 3),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  updateBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 89,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),
    marginRight: theme.spacing(2.875),
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
}));

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    width: 450,
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));
