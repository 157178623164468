import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { EditableSnoozeDatePicker } from './EditableSnoozeDatePicker';

const useStyles = makeStyles((theme) => ({
  smallPrimarybtn: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '83px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '& .MuiButton-label .MuiSvgIcon-root': {
      fontSize: '14px',
      padding: theme.spacing(0),
      marginLeft: theme.spacing(-1),
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
    },
  },

  dialogTitle: {
    color: '#434343',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
  },
  formLabel: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#434343',
  },
  radioGroupContainer: {
    marginLeft: 6,
  },
  formControlLabel: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#656565',
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px 24px',
    borderTop: '1px solid #D1D1D1',
  },
  doneBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#0C77D8',
    borderRadius: 8,
    width: 'fit-content',
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(1, 3),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  nextBtn: {
    textTransform: 'capitalize',
    color: '#656565',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#E5E5E5',
    borderRadius: 8,
    width: 73,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },

    '&:disabled': {
      color: theme.palette.grey[600],
      background: theme.palette.grey[200],
      opacity: 0.5,
    },
  },
  dimissBtn: {
    textTransform: 'capitalize',
    color: '#656565',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#E5E5E5',
    borderRadius: 8,
    width: 86,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  radio: {
    padding: 4,
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
    '&$checked': {
      color: '#0C77D8',
    },
  },
  checked: {},
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 512,
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogTitle-root': {
    padding: '20px 24px',
  },
  '& .MuiDialogContent-root': {
    padding: '0 25px',
    paddingBottom: 24,
  },
  '& .MuiDialogActions-root': {
    padding: 0,
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));

export enum OutComeOfCallsActionType {
  LEFT_MESSAGE = 'Left Message',
  NO_RESPONSE = 'No Response',
  APPOINTMENT_BOOKED = 'Appointment Booked',
  PLAN_TO_WALKIN = 'Plan to Walk In',
  CLOSE_OUTREACH = 'Close Outreach',
}

interface OutComeOfCallsDialogProps {
  dialogTitle: string;
  selectedAction: any;
  setSelectedAction: any;
  manualCareCoordinationStatus: any;
  handleClose: () => void;
  onConfirm: (input: string) => void;
}

export const EditableSnoozeDialog = (props: OutComeOfCallsDialogProps) => {
  const {
    dialogTitle,
    manualCareCoordinationStatus,
    handleClose,
    onConfirm,
  } = props;
  const [snoozeDate, setSnoozeDate] = useState('');
  const [disable, setDisable] = useState(false);

  const classes = useStyles();

  const handleSelectedSnoozeDate = (date: string) => {
    setSnoozeDate(date);
  };

  const getDoneButtonText = () => {
    switch (dialogTitle) {
      case 'Snooze Patient':
        return 'Confirm';
      case 'Edit Snooze':
        return 'Save Changes';
      default:
        return 'Confirm';
    }
  };

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={manualCareCoordinationStatus.open}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>{dialogTitle}</div>
      </DialogTitle>

      <DialogContent style={{ borderTop: '1px solid #D1D1D1' }}>
        <EditableSnoozeDatePicker
          onSelectedDate={handleSelectedSnoozeDate}
          snoozeOnly={true}
          setDisabled={setDisable}
          dialogTitle={dialogTitle}
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button onClick={handleClose} className={classes.dimissBtn} autoFocus>
            Cancel
          </Button>
          <div>
            <Button
              disabled={disable}
              onClick={() => onConfirm(snoozeDate)}
              className={classes.doneBtn}
              autoFocus
            >
              {getDoneButtonText()}
            </Button>
          </div>
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
};
