import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

type Props = {
  title: string;
  renderButton?: () => React.ReactElement;
};

const useStyles = makeStyles((theme) => ({
  header: {
    height: 128,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  breadCrumb: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  backTitle: {
    fontSize: 12,
    color: theme.palette.secondary.dark,
    '&:hover': {
      color: theme.palette.grey['700'],
    },
  },
  arrowIcon: {
    fontSize: 12,
    color: theme.palette.grey['300'],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  navLink: {
    textUnderlineOffset: '4px',
    textDecorationThickness: 2,
    textDecorationColor: '#b3b1b1',
  },
  navTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
    // paddingLeft: theme.spacing(1),
    userSelect: 'none',
  },
  headerTitle: {
    fontSize: 32,
    height: 40,
    paddingTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  headerButton: {
    position: 'absolute',
    top: 60,
    right: 24,
  },
}));

export const PanelsHeader = (props: Props) => {
  const classes = useStyles();
  const { title, renderButton } = props;

  return (
    <div className={classes.header}>
      <div className={classes.breadCrumb}>
        <NavLink className={classes.navLink} to={'/panels'}>
          <Typography className={classes.backTitle}>Patient Panels</Typography>
        </NavLink>
        <ArrowForwardIcon className={classes.arrowIcon} />
        <Typography className={classes.navTitle}>{title}</Typography>
      </div>
      <Typography className={classes.headerTitle}>{title}</Typography>
      <div className={classes.headerButton}>
        {renderButton && renderButton()}
      </div>
    </div>
  );
};
