import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Chip, TablePagination, Typography} from "@material-ui/core";
import {ProcessingIcon} from "../../../../../assets/Icons/FileUpload/ProcessingIcon";
import {NotRegisterIcon} from "../../../../../assets/Icons/FileUpload/NotRegisterIcon";
import {UploadFailIcon} from "../../../../../assets/Icons/FileUpload/UploadFailIcon";
import {theme} from "../../../../../themes/theme";
import {useNavigate} from 'react-router';
import {useGetEligibilitySmsBatchesQuery} from "./index.generated";
import {LoadingSpinner} from "../../../../../components/LoadingSpinner";
import moment from 'moment'
import {ChartIcon} from "../../../../../assets/Icons/PanelsList";
import {EligibilityCategory} from "../../../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import {useStickyTableStyle} from "../../../../../components/TabBar/Styles";

export enum EngagementLogStatus {
  sending = "Sending",
  failed = "Failed",
  partialDelivered = "PartialDelivered",
  delivered = "Delivered",
}

export interface EngagementCategoryLog {
  name: string,
  patientCount: number,
  sendBy: string,
  shortName: string,
  lastUpdated: string
  status: EngagementLogStatus,
  statusDescription: string,
  batchId: any,
}

type ColumnID = 'name' | 'patientCount' | 'sendBy' | 'status' | 'lastUpdated'

interface Column {
  id: ColumnID;
  label: string;
  align?: 'right' | 'left';
}

const columns: Column[] = [
  { id: 'name', label: 'Batch Name', align: 'left'},
  { id: 'patientCount', label: 'Send to',  align: 'left'},
  { id: 'sendBy', label: 'Sent by',  align: 'left'},
  { id: 'status', label: 'Batch SMS Status',  align: 'left'},
  { id: 'lastUpdated', label: 'Last Update',  align: 'left'},
];

interface IProps {
  skip: number,
  limit: number,
  setSkip: (input: number) => void,
  setLimit: (input: number) => void,
}

export default function EngagementBatchesTable(props: IProps) {
  const {skip, limit, setLimit, setSkip} = props
  const navigate = useNavigate();
  const classes = useStyles();
  const stickyTableClasses = useStickyTableStyle();

  const batchesQuery = useGetEligibilitySmsBatchesQuery({
    variables: {
      input: {
        limit: limit,
        skip: skip,
      },
    },
  });

  const getInitials = (input: string) => {
    if (!input.length) {
      return ""
    }
    let names = input.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  if (!batchesQuery.called || batchesQuery.loading) {
    return <LoadingSpinner style={{width: '100%', marginTop: 80}}/>
  }

  const formatCategory = (category: EligibilityCategory) => {
    if (category === EligibilityCategory.internalActive) {
      return "Active (Internal)"
    } else if (category === EligibilityCategory.externalActive) {
      return "Active (External)"
    } else if (category === EligibilityCategory.inactive) {
      return "Inactive"
    } else if (category === EligibilityCategory.unverified) {
      return "Unverified"
    } else {
      return category.toString()
    }
  }

  const patientCount = batchesQuery!.data!.getEligibilitySmsBatches!.pagingInfo!.count
  const dataSource: Array<EngagementCategoryLog> = batchesQuery!.data!.getEligibilitySmsBatches!.results!.map((item) => {
    const {
      batchId,
      category,
      failureCount,
      messageTemplateId,
      messageTemplateName,
      sendStatus,
      sendStatusDesc,
      sentBy,
      sentCount,
      sentDate,
      updateTime,
    } = item

    const batch: EngagementCategoryLog = {
      name: `Batch SMS-${formatCategory(category as EligibilityCategory)}`,
      patientCount: sentCount,
      sendBy: sentBy,
      shortName: getInitials(sentBy),
      status: sendStatus as EngagementLogStatus,
      statusDescription: sendStatusDesc ?? "",
      lastUpdated: moment(new Date(sentDate)).format("MMMM D, HH:mm A"),
      batchId: batchId,
    }
    return batch
  })

  if (!dataSource.length) {
    return (
      <div className={classes.emptyViewRoot}>
        <div className={classes.emptyContainer}>
          <ChartIcon className={classes.hintIcon} />
          <Typography className={classes.emptyTitle}>
            No Batch Record Yet
          </Typography>
          <Typography className={classes.emptySubTitle}>
            There is no Batch SMS Log yet
          </Typography>
          <Typography className={classes.emptySubTitle}>
            You can send Batch SMS from Active, Needs Review, Inactive Tabs.
          </Typography>
        </div>
      </div>
    );
  }

  const getStatusIcon = (status: EngagementLogStatus) => {
    switch (status) {
      case EngagementLogStatus.sending: {
        return <ProcessingIcon className={classes.sendingIcon} />;
      }
      case EngagementLogStatus.partialDelivered: {
        return <NotRegisterIcon className={classes.warningIcon} />;
      }
      case EngagementLogStatus.failed: {
        return <UploadFailIcon className={classes.failIcon} />;
      }
      default: {
        return <></>;
      }
    }
  }

  const getStyleByStatus = (status: EngagementLogStatus) => {
    switch (status) {
      case EngagementLogStatus.sending: {
        return classes.chipStatusSending
      }
      case EngagementLogStatus.partialDelivered: {
        return classes.chipStatusDeliveredWithFailed
      }
      case EngagementLogStatus.failed: {
        return classes.chipStatusFailed
      }
      case EngagementLogStatus.delivered:
        return classes.chipStatusDelivered
      default: {
        return classes.chipStatusSending;
      }
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
  };

  const renderCellContentByColumnId = (cid: ColumnID, log: EngagementCategoryLog) => {
    if (cid === 'name') {
      return <Typography className={classes.cellText}>{log.name}</Typography>
    } else if (cid === 'patientCount') {
      return <Typography className={classes.cellText}>{log.patientCount + (log.patientCount > 1 ? ' Patients' : ' Patient')}</Typography>
    } else if (cid === 'sendBy') {
      return <div className={classes.sendByContainer}>
        <div className={classes.sendByShorNameContainer}>
          <Typography className={classes.sendByShorName}>{log.shortName}</Typography>
        </div>
        <Typography className={classes.cellText}>{log.sendBy}</Typography>
      </div>
    } else if (cid === 'status') {
      const icon = getStatusIcon(log.status)
      const style = getStyleByStatus(log.status)
      return (
        <Chip icon={icon} label={log.statusDescription} className={style}/>
      )
    } else if (cid === 'lastUpdated') {
      return <Typography className={classes.cellText}>{log.lastUpdated}</Typography>
    }
  }

  const currentPage = Math.floor(skip / limit);
  return (
    <Paper className={classes.root}>
      <TableContainer classes={{root: stickyTableClasses.customTableContainer}}>
        <Table aria-labelledby="tableTitle"
               aria-label="enhanced table"
               stickyHeader>
          <TableHead >
            <TableRow className={classes.header}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.headerCell}
                  style={{ minWidth: 170 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((row, idx) => {
              return (
                <TableRow onClick={() => {
                  navigate(`/eligibility/patients/engagement/log/${row.batchId}/${row.status}`)
                }} className={classes.row} hover role="checkbox" tabIndex={-1} key={idx}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align}  style={{ minWidth: 170, }} className={classes.cell}>
                        {renderCellContentByColumnId(column.id, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={patientCount ?? 0}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {

  },
  header: {
    backgroundColor: '#F8F8F8',
  },
  headerCell: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    color: '#888888',
  },
  row: {
    height: 56,
  },
  cell: {
    paddingLeft: 24,
  },
  cellText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500
  },
  sendByContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center'
  },
  sendByShorNameContainer: {
    backgroundColor: '#F5CA32',
    borderRadius: 12,
    height: 24,
    width: 24,
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  sendByShorName: {
    fontSize: 10,
    lineHeight: '14px',
    color: '#FFF',
    padding: 0,
    fontWeight: 700
  },
  chip: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sendingIcon: {
    color: '#0C77D8',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  warningIcon: {
    color: '#C05314',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  failIcon: {
    color: '#B7373E',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  chipStatusSending: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDelivered: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#888888',
    fontWeight: 600,
    backgroundColor: '#EFEFEF',
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDeliveredWithFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.warning.main,
    fontWeight: 600,
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.error.main,
    fontWeight: 600,
    backgroundColor: theme.palette.error.light,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
      {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
    '& p[id]': {
      marginRight: theme.spacing(5.75),
    },
  },
  hintIcon: {
    width: 60,
    height: 60,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2.125),
  },

  emptyViewRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyContainer: {
    marginTop: 155,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    fontSize: 32,
    lineHeight: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  },
  emptySubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center'
  },


});
