import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useNoteTabStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    margin: theme.spacing(4, 3),

    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  tabLabelAndActionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabLabel: {
    fontSize: 20,
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
  },
  emptyContentText: {
    fontSize: 20,
    color: theme.palette.grey[400],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    padding: theme.spacing(26, 15, 0, 15),
    textAlign: 'center',
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 24,

    boxSizing: 'border-box',
    width: '100%',
    height: 'fit-content',
  },
  noteItemRoot: {
    width: '100%',
    height: 'fit-content',
    boxSizing: 'border-box',
  },
  noteItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    //   width: 'inherit',
    height: 'fit-content',
    padding: '8px 8px 8px 8px',
    borderRadius: 8,
  },

  noteItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    // gap: 8,

    width: '100%',
    height: 'fit-content',
    padding: '8px 16px 0 16px',
  },
  noteItemContent: {
    color: '#656565',
    fontFamily: 'Manrope',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  noteItemFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',
    height: 'fit-content',

    '& > div:first-child > p.MuiTypography-root': {
      color: 'var(--Color-Grey-600, #656565)',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
  footerRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  noteCreatedAt: {
    fontFamily: 'Manrope',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
  actionMenuContainer: {},
  editButton: {
    position: 'relative',
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
  },
  moreHorizonIcon: { color: theme.palette.primary.main },
  menu: {
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
      marginTop: 0,
      width: 200,
    },
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
      padding: theme.spacing(0.5, 1),
      position: 'relative',
    },

    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after': {
      content: '""',
      position: 'absolute',
      bottom: 43,
      left: 0,
      display: 'block',
      width: 200,
      height: 1,
      background: theme.palette.grey[300],
    },

    '& li[role="menuitem"]': {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 35,
      borderRadius: 8,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 4,
    },
    '& li[role="menuitem"]:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      '& > svg': {
        color: `${theme.palette.primary.main}!important`,
      },
    },

    '& li[role="menuitem"]:first-child': {
      marginTop: 8,
    },
    '& li[role="menuitem"]:last-child': {
      marginBottom: 8,
    },
  },
  menuItem: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',

    '& > svg.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      margin: `0!important`,
    },
  },
  noteType: {
    width: 'fit-content',
    height: 20,
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    backgroundColor: '#C05314',
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),

    '& span.MuiChip-label': {
      padding: 0,
    },
  },
}));
