import React from 'react';
import {
  FormControl,
  List,
  ListItem,
  IconButton,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RoleOptionItem } from '../RoleOptionItem/RoleOptionItem';
import { LabelAvatar } from '../../../../../components/Avatar';
import { CloseIcon } from '../../../../../assets/Icons/ExportPatients';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../../../components/CheckIsAdmin';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { RoleType } from '../../../../../enum/Users';

function getStyles(name: string, selectedUsers: string[], theme: Theme) {
  return {
    borderRadius: 8,
    paddingRight: 0,
    fontWeight:
      selectedUsers.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface RoleSelectViewProps {
  inputRef?: React.Ref<any>;
  state?: {};
  setState?: any;
  isMultiple: boolean;
  formType: string;
  itemsTypes?: 'Item' | 'Array';
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  droplistAttr: {
    maxHeight: number;
    width?: number | string;
  };
  items: any[];
  classes: {
    multipleSelect: string;
    formControl: string;
    inputTitle: string;
    inputHelper?: string;
    placeholder: string;
    select: string;
    list: string;
    listItem: string;
    itemText?: string;
    itemHelper?: string;
    selected?: string;
    menuItem?: string;
    menuItemText?: string;
    placeholderSelected?: string;
    selectMenuContent?: string;
  };
  hasAvatar: boolean;
  avatarClasses?: ClassNameMap<
    | 'avatarContainer'
    | 'avatarStyles'
    | 'avatarContent'
    | 'mainContent'
    | 'subContent'
  >;
  onChange?: (value: boolean, selectedValue: any[]) => void;
  customVariable?: any;
  setCustomVariable?: any;
  setLineOfBusiness?: any;
  value?: any;
}

export const RoleSelectView = (props: RoleSelectViewProps) => {
  const { loggedInUser } = React.useContext(GlobalContext);
  const theme = useTheme();
  const [selectedItems, setSelectedItems]: any = React.useState<any[]>(
    props.value ? props.value : []
  );
  const [dynamicPlaceholder, setDynamicPlaceholder] = React.useState<string>(
    props.value ? props.value.roleName : props.placeholder
  );

  React.useEffect(() => {
    if (props.value) {
      let tmpRoleName =
        props.value.roleName === RoleType.ADMIN
          ? RoleType.CARE_MANAGER
          : props.value.roleName || props.placeholder;
      setDynamicPlaceholder(tmpRoleName);
    } else {
      let tmpRoleName = props.placeholder;
      setDynamicPlaceholder(tmpRoleName);
    }
  }, [props.value]);

  React.useEffect(() => {
    !props.value && setDynamicPlaceholder(props.placeholder);
    setSelectedItems(props.value ? props.value : []);
  }, [props.items]);

  const setPlaceHolderWithItem = (item: any) => {
    if (item?.value) {
      setDynamicPlaceholder(item.value);
    } else {
      setDynamicPlaceholder('All');
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, Provider and CareTeam
    switch (props.formType) {
      case 'UserRole': {
        setDynamicPlaceholder(`${event.target.value.roleName}`);

        props.setState({
          roleId: event.target.value.id,
          roleName: event.target.value.roleName,
        });
        break;
      }
      case 'LineOfBusiness': {
        setDynamicPlaceholder(`${event.target.value.name}`);
        if (props.setLineOfBusiness) {
          props.setLineOfBusiness(event.target.value);
          props.setState({
            ...props.state,
            lineOfBusiness: event.target.value,
          });
        }
        break;
      }
      case 'Insurance': {
        setDynamicPlaceholder(`${event.target.value.length} selected`);
        props.setState({
          ...props.state,
          insurancePlans: event.target.value,
        });
        break;
      }
      case 'Provider': {
        setDynamicPlaceholder(
          `${event.target.value.firstName} ${event.target.value.lastName}`
        );
        props.setState({
          ...props.state,
          pcp: event.target.value,
        });
        break;
      }
      case 'CareTeam': {
        if (props.onChange) {
          props.onChange(event.target.value.length === 0, event.target.value);
        }
        props.setState({
          ...props.state,
          careTeamUsers: event.target.value,
        });
        break;
      }
      case 'ProviderType': {
        props.setCustomVariable(event.target.value);
        setDynamicPlaceholder(`${event.target.value.name}`);
        break;
      }
      default: {
        if (event.target.value) {
          setDynamicPlaceholder(`${event.target.value.length} selected`);
        }
      }
    }

    if (Array.isArray(event.target.value) && event.target.value.length === 0) {
      setDynamicPlaceholder(props.placeholder);
    }

    setSelectedItems(event.target.value);
  };

  const deleteUser = (deletedItem: string) => {
    const tmpItems = selectedItems.filter((item: any) => item !== deletedItem);
    setSelectedItems(tmpItems);

    // removed selected items according to form type: Insurance, Provider and CareTeam
    switch (props.formType) {
      case 'CareTeam': {
        if (props.onChange) {
          props.onChange(tmpItems.length === 0, tmpItems);
          props.setState({
            ...props.state,
            careTeamUsers: tmpItems,
          });
        }
        break;
      }
      case 'Insurance': {
        if (tmpItems.length === 0) {
          setDynamicPlaceholder(props.placeholder);
        } else {
          setDynamicPlaceholder(`${tmpItems.length} selected`);
        }

        props.setState({
          ...props.state,
          insurancePlan: tmpItems,
        });
        break;
      }
    }
  };

  return (
    <div className={props.classes.multipleSelect}>
      <FormControl className={props.classes.formControl}>
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={props.classes.inputTitle}
        >
          {props.inputTitle}
        </Typography>

        <Select
          disabled={
            !CheckIsAdmin(loggedInUser) && !CheckIsCareManager(loggedInUser)
          }
          multiple={props.isMultiple}
          variant="outlined"
          displayEmpty
          renderValue={() => {
            return (
              <div
                className={
                  (Array.isArray(selectedItems) &&
                    selectedItems.length === 0) ||
                  props.formType === 'CareTeam'
                    ? props.classes.placeholder
                    : props.classes.placeholderSelected
                }
              >{`${dynamicPlaceholder}`}</div>
            );
          }}
          onOpen={() => {
            if (props.formType === 'LineOfBusiness') {
              setDynamicPlaceholder('Select Line of Business');
            }
          }}
          onClose={() => {
            if (props.formType === 'LineOfBusiness') {
              setPlaceHolderWithItem(selectedItems);
            }
          }}
          value={selectedItems}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            elevation: 2,
            getContentAnchorEl: null,
            style: {
              maxHeight: props.droplistAttr.maxHeight,
              width: props.droplistAttr.width,
              maxWidth: '100%',
            },
            PaperProps: {
              style: {
                // reduce space between input and menu
                marginTop: theme.spacing(-1.5),
              },
            },
          }}
          className={props.classes.select}
        >
          {props.items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={item}
              style={
                Array.isArray(selectedItems)
                  ? getStyles(item, selectedItems, theme)
                  : undefined
              }
              classes={{
                root: props.classes.menuItem,
                selected: props.classes.selected,
              }}
            >
              {/* only work for roleOptions, item => {roleId: string, roleName: string} */}
              <RoleOptionItem
                item={
                  props.itemsTypes && props.itemsTypes === 'Item' ? item : null
                }
                selected={
                  (Array.isArray(selectedItems) &&
                    selectedItems.filter((items) => items === item).length >
                      0) ||
                  selectedItems === item
                }
              />
            </MenuItem>
          ))}
        </Select>

        {props.inputHelper && (
          <Typography variant="body1" className={props.classes.inputHelper}>
            {props.inputHelper}
          </Typography>
        )}

        {/* only for multiple select, it shows selected items */}
        {props.isMultiple && selectedItems.length > 0 && (
          <List className={props.classes.list}>
            {selectedItems.map((item: any, idx: any) => (
              <ListItem key={idx}>
                <div
                  className={props.classes.listItem}
                  style={
                    idx === selectedItems.length - 1
                      ? { borderBottom: 'none' }
                      : {}
                  }
                >
                  {props.hasAvatar ? (
                    <LabelAvatar
                      firstName={item.firstName}
                      lastName={item.lastName}
                      title={item.title}
                      styles={props.avatarClasses}
                    />
                  ) : (
                    <div>
                      <Typography
                        variant="body1"
                        className={props.classes.itemText}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={props.classes.itemHelper}
                      >
                        999 patients
                      </Typography>
                    </div>
                  )}
                  <IconButton
                    aria-label="close"
                    onClick={() => deleteUser(item)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </ListItem>
            ))}
          </List>
        )}
      </FormControl>
    </div>
  );
};
