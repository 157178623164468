import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { BreadCrumbHeader } from '../../../components/BreadCrumbHeader';
import { SearchAndFilterBar } from '../../../components/SearchAndFilterBar';
import { theme } from '../../../themes/theme';
import { CareGapsDrawer } from '../../CareGaps/Home/tabs/Population/components/CareGapsDrawer';
import { ProviderPatientFilter } from './components/ProviderPatientFilter';
import { ProviderPatientTable } from './components/ProviderPatientTable';
import { useGetTotalPatientCountLazyQuery } from './index.generated';
import { useProviderPatientListStyles } from './Styles';
import { MainRegion } from '../../../components/MainRegion';
import { PanelPatient } from '../../../app.types.generated';
import { useSwitchPatientWithPagination } from '../../Panels/ViewPanels/PanelPatients/hooks/useSwitchPatientWithPagination';
import { SubMainRegion } from '../../../components/SubMainRegion';
import { useKeyPatients } from '../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { useUnlockPatientClosingPFS } from '../../Panels/ViewPanels/PanelPatients/hooks/useUnlockPatientClosingPFS';
import { MixPanelEvents } from '../../../mixpanel/events';

interface ProviderPatientListViewProps {}

export const ProviderPatientListView = (
  props: ProviderPatientListViewProps
) => {
  const classes = useProviderPatientListStyles();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [searchName, setSearchName] = React.useState('');
  const [searchDob, setSearchDob] = React.useState('');
  const [isSearchingCheck, setIsSearchingCheck] = React.useState<
    boolean | undefined
  >(undefined);
  const [searchInput, setSearchInput] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const filterHandler = () => {
    setOpenFilter(!openFilter);
  };

  const [selectedFilter, setSelectedFilter] = React.useState<any | undefined>({
    info: {
      Gender: [],
      Age: [],
      LastDateOfServiceFrom: null,
      LastDateOfServiceTo: null,
      CareGaps: [],
    },
  });
  const [results, setResults] = React.useState([]);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [checkedPatient, setCheckedPatient] = React.useState<PanelPatient>(
    {} as PanelPatient
  );
  const [value, setValue] = React.useState(0); // 0 patient info, 1 Engagement, 2 Care Gaps
  const drawerHandler = (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    // setState({ ...state, [anchor]: open });
    event.stopPropagation();
    setShowDrawer(!showDrawer);
    if (patient) {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.openPatientFaceSheet.name,
        { patientID: patient.id, panelID: patient.thisPanelId }
      );
      setCheckedPatient(patient);
    } else {
      setCheckedPatient({} as PanelPatient);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    }
  }, [showDrawer]);

  const switchTab = (event: React.MouseEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();
  const passingData = location.state as any;

  const [providerPatientCount, setProviderPatientCount] = React.useState(0);
  const [providerName, setProviderName] = React.useState('');
  const { providerId } = useParams();

  const [getTotalPatientCount, { data }] = useGetTotalPatientCountLazyQuery();

  React.useEffect(() => {
    if (passingData && passingData.providerName) {
      setProviderName(passingData.providerName);
    }

    if (passingData && passingData.patientCount >= 0) {
      setProviderPatientCount(passingData.patientCount);
    }

    if (!passingData) {
      getTotalPatientCount({
        variables: {
          input: {
            limit: 10,
            skip: 0,
            npis: [providerId || ''],
            sortBy: [{ field: 'fullName', method: 'asc' }],
          },
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (providerName === '' && data) {
      const results = data.getPatientByCareTeamUserId.results!;
      setProviderName(`${results[0].coveringPCPFullName}`);
      setProviderPatientCount(data.getPatientByCareTeamUserId.count);
    }
  }, [data]);

  const [applyFilterBtnIsClicked, setApplyFilterBtnIsClicked] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(true);
  const [displayedCount, setDisplayedCount] = React.useState(0);
  const [sortBy, setSortBy] = React.useState({
    field: 'fullName',
    method: 'asc' as const,
  });

  const { search } = location;
  const navigate = useNavigate();

  const [skip, limit] = React.useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  const { currentPage } = useSwitchPatientWithPagination(
    skip || 0,
    limit || 10,
    results,
    setCheckedPatient,
    checkedPatient
  );
  const [refresher, setRefresher] = React.useState(0);
  const { keyPatients, setKeyPatients } = useKeyPatients(checkedPatient);
  const [patientFaceInfo, setPatientFaceInfo] = React.useState<any>();
  useUnlockPatientClosingPFS(showDrawer, patientFaceInfo, '');
  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          rootLink={`/dashboard`}
          rootName={'Dashboard'}
          isTitle={true}
          title={providerName ? `${providerName}'s Patients` : 'Patients'}
          badgeCount={providerPatientCount}
          childrenName={'All Providers'}
          childrenLink={'/pcp'}
          childrenChildrenName={
            providerName ? `${providerName}'s Patients` : 'Patients'
          }
        />
      }
      overFlowHidden={true}
    >
      <div className={classes.root}>
        <SubMainRegion
          header={
            <div
              className={classes.lightBackground}
              style={{
                borderBottom: !openFilter
                  ? 'none'
                  : `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <SearchAndFilterBar
                placeHolder={
                  'Search Patient by Name, DOB, Patient ID or Subscriber ID'
                }
                searchFunction={setSearchInput}
                filterHandler={filterHandler}
                setIsSearching={setIsSearching}
                isSearching={isSearching}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                isSearchingCheck={isSearchingCheck}
                setIsSearchingCheck={setIsSearchingCheck}
              />

              <div
                className={
                  openFilter ? classes.openedDrawer : classes.closedDrawer
                }
              >
                <ProviderPatientFilter
                  setSortBy={setSortBy}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  setIsSearching={setIsSearching}
                  applyFilterBtnIsClicked={applyFilterBtnIsClicked}
                  setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
                  loading={loading}
                  displayedCount={displayedCount}
                />
              </div>
            </div>
          }
          headerChange={openFilter}
        >
          <ProviderPatientTable
            sortBy={sortBy}
            setSortBy={setSortBy}
            drawerHandler={drawerHandler}
            providerNPI={providerId || ''}
            selectedFilter={selectedFilter}
            isSearching={isSearching}
            searchInput={searchInput}
            setLoading={setLoading}
            setDisplayedCount={setDisplayedCount}
            setResults={setResults}
            setValue={setValue}
            limit={limit ?? 10}
            skip={skip ?? 0}
            setLimit={onSetLimit}
            setSkip={onSetSkip}
            applyFilterBtnIsClicked={applyFilterBtnIsClicked}
            searchName={searchName}
            setSearchName={setSearchName}
            searchDob={searchDob}
            setSearchDob={setSearchDob}
            isSearchingCheck={isSearchingCheck}
            setIsSearchingCheck={setIsSearchingCheck}
          />

          {showDrawer && (
            <CareGapsDrawer
              allPatients={results}
              drawerHandler={drawerHandler}
              checkedPatient={checkedPatient}
              tabIndex={value}
              switchTab={switchTab}
              currentPage={currentPage}
              totalCount={displayedCount}
              limit={limit ?? 10}
              skip={skip ?? 0}
              setSkip={onSetSkip}
              refresher={refresher}
              setRefresher={setRefresher}
              setPatientFaceInfo={setPatientFaceInfo}
              keyPatients={keyPatients}
              setKeyPatients={setKeyPatients}
            />
          )}
        </SubMainRegion>
      </div>
    </MainRegion>
  );
};
