import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const PanelsIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M8,13 C9.65685425,13 11,14.3431458 11,16 L11,18 C11,19.6568542 9.65685425,21 8,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,16 C3,14.3431458 4.34314575,13 6,13 L8,13 Z M18,13 C19.6568542,13 21,14.3431458 21,16 L21,18 C21,19.6568542 19.6568542,21 18,21 L16,21 C14.3431458,21 13,19.6568542 13,18 L13,16 C13,14.3431458 14.3431458,13 16,13 L18,13 Z M8,3 C9.65685425,3 11,4.34314575 11,6 L11,8 C11,9.65685425 9.65685425,11 8,11 L6,11 C4.34314575,11 3,9.65685425 3,8 L3,6 C3,4.34314575 4.34314575,3 6,3 L8,3 Z M18,3 C19.6568542,3 21,4.34314575 21,6 L21,8 C21,9.65685425 19.6568542,11 18,11 L16,11 C14.3431458,11 13,9.65685425 13,8 L13,6 C13,4.34314575 14.3431458,3 16,3 L18,3 Z"
  />
);
