import * as React from 'react';
import { config } from '../../config';
import { useAccountId } from '../../hooks/useAccountId';
import { getEnvName } from '../../sharePipe/getEnvName';

type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup;
};

export type FeatureFlags = string[] | FeatureGroup;

const FeatureFlagsContext = React.createContext<FeatureGroup>({});

function transformFlags(features: FeatureFlags) {
  if (!Array.isArray(features)) return features;
  return Object.fromEntries(features.map((feature) => [feature, true]));
}

function mergeFeatures(...features: FeatureGroup[]): FeatureGroup {
  return features.reduce((mergedFeatures, currentFeatures) => {
    return { ...mergedFeatures, ...currentFeatures };
  }, {});
}

export function FlagsProvider({
  features = {},
  children,
}: {
  features?: FeatureFlags;
  children: React.ReactNode;
}) {
  const currentFeatures = useFeatures();

  const accountId = useAccountId();

  function availableFeatures(): any {
    const key = accountId;
    return config.featureListByAccountId[key as keyof Object];
  }

  function availableFeaturesByEnv(): any {
    const key = getEnvName();
    return config.featureListByEnv[key as keyof Object];
  }

  return (
    <FeatureFlagsContext.Provider
      value={mergeFeatures(
        transformFlags(currentFeatures),
        transformFlags(availableFeatures()),
        transformFlags(availableFeaturesByEnv())
      )}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}

// Custom Hook API
export function useFeatures(): FeatureGroup {
  return React.useContext(FeatureFlagsContext);
}

// Custom Hook API
export function useFeature(name: string): boolean | FeatureGroup {
  const features = useFeatures();
  if (Array.isArray(features)) return features.includes(name);
  if (typeof features[name] === 'boolean') return features[name];
  return name
    .split('/')
    .reduce<FeatureGroup | boolean>((featureGroup, featureName: string) => {
      if (typeof featureGroup === 'boolean') return featureGroup;
      if (featureGroup[featureName] === undefined) return false;
      return featureGroup[featureName];
    }, features);
}

// Render Prop API
export function Feature({
  name,
  children,
  render = children,
}: {
  name: string;
  children?:
    | React.ReactNode
    | ((hasFeature: boolean | FeatureGroup) => JSX.Element);
  render?:
    | React.ReactNode
    | ((hasFeature: boolean | FeatureGroup) => JSX.Element);
}) {
  const hasFeature = useFeature(name);
  if (typeof render === 'function') return render(hasFeature);
  if (!hasFeature) return null;
  return <React.Fragment>{render}</React.Fragment>;
}

// High Order Component API
export function withFeature(featureName: string) {
  return (Component: Function) => (props: React.ComponentProps<any>) => {
    return (
      <Feature name={featureName}>
        <Component {...props} />
      </Feature>
    );
  };
}
