import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useAllProvidersStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    icon: {
      height: 20,
      width: 20,
    },
    headerMainRow: {
      height: 88,
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      alignItems: 'center',

      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontSize: '32px',
        fontWeight: 'bold',
        height: '40px',
        letterSpacing: '0',
        lineHeight: '40px',
        // margin: '18.68px 24px',
        margin: '24px',
        display: 'inline-block',
        textTransform: 'capitalize',
      },

      '& #create-button-container': {
        marginLeft: 'auto',
        marginRight: theme.spacing(3),
      },

      '& #creation-button': {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        lineHeight: '24px',
        background: theme.palette.primary.main,
        borderRadius: '8px',
        width: '166px',
        height: '40px',
        textAlign: 'center',
        padding: '0',

        '&:hover': {
          backgroundColor: '#1b8cf3',
        },
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0',
        textTransform: 'capitalize',
        marginLeft: '3.33px',
      },
    },
    closedDrawer: {
      display: 'none',
      transition: 'all 1s',
    },
    openedDrawer: {
      display: 'block',
      transition: 'all 3s',
    },
    lightBackground: {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
  })
);
