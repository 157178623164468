import React from 'react';
import { useNoteTabStyles } from '../../Styles';
import { ProfileItem } from '../../../../../../../../../components/ProfileItem';
import {
  Button,
  Chip,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  fade,
  styled,
} from '@material-ui/core';
import { MoreHorizIcon } from '../../../../../../../../../assets/Icons/Eligibility/MoreHorizIcon';
import { EmptyPlaceHolder } from '../../../../../../../../../components/EmptyPlaceHolder';
import {
  BinIcon,
  EditIcon,
} from '../../../../../../../../../assets/Icons/MsoAdmin';
import { dateFormatPipe } from '../../../../../../../../../sharePipe/dateFormatPipe';
import { theme } from '../../../../../../../../../themes/theme';

export enum NoteType {
  siteFollowUpNotes = 'siteFollowUpNotes',
  siteFollowUp = 'siteFollowUp',
  Site_Follow_Up = 'Site Follow Up',
  SITE_FOLLOW_UP_COMPLETED = 'Site Follow Up Completed',
  OUTREACH = 'outreach',
}

export interface NoteItemProps {
  item: any;
  handleItemClick: (event: any, item?: any) => void;
  inDialog?: boolean;
}

export const NoteItem: React.FC<NoteItemProps> = ({
  item,
  handleItemClick,
  inDialog,
}) => {
  const classes: any = useNoteTabStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOption = (event: any) => {
    setAnchorEl(null);
    handleItemClick(event, item);
  };

  const isNotSiteFollowUpNoteType = () => {
    return (
      item.noteType !== NoteType.SITE_FOLLOW_UP_COMPLETED &&
      item.noteType !== NoteType.Site_Follow_Up &&
      item.noteType !== NoteType.siteFollowUp &&
      item.noteType !== NoteType.siteFollowUpNotes
    );
  };
  const isSiteFollowUpNoteType = () => {
    return (
      item.noteType === NoteType.SITE_FOLLOW_UP_COMPLETED ||
      item.noteType === NoteType.Site_Follow_Up ||
      item.noteType === NoteType.siteFollowUp ||
      item.noteType === NoteType.siteFollowUpNotes
    );
  };
  const getNoteType = (noteType: string) => {
    switch (noteType) {
      case NoteType.Site_Follow_Up:
      case NoteType.siteFollowUp:
      case NoteType.siteFollowUpNotes:
        return 'Site Follow Up';
      case NoteType.SITE_FOLLOW_UP_COMPLETED:
        return 'Site Follow Up Completed';
      default:
        return item.noteType;
    }
  };
  return (
    <div id={`NoteItem_${item.id}`} className={classes.noteItemRoot}>
      <div
        className={classes.noteItemContainer}
        style={{
          backgroundColor: isSiteFollowUpNoteType() ? '#FCF3E5' : '#F8F8F8',
        }}
      >
        <div className={classes.noteItemWrapper}>
          {/* item noteType => site follow up notes */}
          {isSiteFollowUpNoteType() && (
            <Chip
              label={getNoteType(item.noteType)}
              className={classes.noteType}
            />
          )}

          <div className={classes.noteItemContent}>{item.content}</div>
          <div className={classes.noteItemFooter}>
            {/* creator */}
            {item.pcp ? (
              <ProfileItem
                firstName={item.pcp.firstName}
                lastName={item.pcp.lastName}
              />
            ) : (
              <EmptyPlaceHolder text="No Provider" />
            )}
            {/* date and action button */}
            <div className={classes.footerRightContainer}>
              <Typography variant="body1" className={classes.noteCreatedAt}>
                {dateFormatPipe(item.createdAt, '', false, undefined, true)}
              </Typography>

              {!inDialog && isNotSiteFollowUpNoteType() && (
                <div className={classes.actionMenuContainer}>
                  <Button
                    className={classes.editButton}
                    id={`more-option-button-notes-${item.id}}`}
                    aria-haspopup="true"
                    variant="contained"
                    disableElevation
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    style={{
                      backgroundColor: theme.palette.grey['50'],
                    }}
                  >
                    <MoreHorizIcon className={classes.moreHorizonIcon} />
                  </Button>

                  <StyledMenu
                    id={`more-option-menu-notes-${item.id}}`}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    className={classes.menu}
                  >
                    <MenuItem
                      onClick={handleOption}
                      disableRipple
                      className={classes.menuItem}
                    >
                      <span>Edit Note</span>
                      <EditIcon />
                    </MenuItem>

                    <MenuItem
                      onClick={handleOption}
                      disableRipple
                      className={classes.menuItem}
                    >
                      <span>Delete</span>
                      <BinIcon />
                    </MenuItem>
                  </StyledMenu>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      style: {
        transform: 'translateY(1px)',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    width: '200px',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 12px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));
