import React from 'react';
import { OverLimitType } from './useOverLimit';
import { theme } from '../../../../../../../../themes/theme';
import { EditNodesForm } from '../components/EditNotes';
import { DeepMap, FieldError } from 'react-hook-form/dist/types';

interface useTextAreaBorderProps {
  overLimit: OverLimitType;
  errors: DeepMap<EditNodesForm, FieldError>;
}

export const useTextAreaBorder = (props: useTextAreaBorderProps) => {
  const { overLimit, errors } = props;

  const textAreaInputs = document.getElementsByName('notes');
  React.useEffect(() => {
    for (let item of textAreaInputs) {
      if (overLimit.isOverLimit || errors.notes) {
        item.style.outline = 'none !important';
        item.style.border = `1px solid ${theme.palette.error.main}`;
      } else {
        item.style.outline = 'none !important';
        item.style.border = '1px solid rgba(0, 0, 0, 0.12)';
      }
    }
  }, [textAreaInputs, overLimit.isOverLimit, errors.notes]);
};
