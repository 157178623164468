import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const EligibilityIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M12.924618,2.4943764 L19.6115842,4.64207614 C20.438911,4.9077945 21,5.67732169 21,6.54627277 L21,11.1738734 C21,14.4217002 19.2924484,17.4303461 16.5040315,19.0956118 L13.0326095,21.1687739 C12.4014831,21.5456879 11.6144577,21.5459981 10.9830343,21.1695819 L7.50403147,19.0956118 C4.7109483,17.4305454 3,14.4186811 3,11.1669493 L3,6.54707134 C3,5.67773582 3.5615772,4.90796057 4.38940854,4.64255615 L11.0913546,2.49389858 C11.6875226,2.3027659 12.3285497,2.30293298 12.924618,2.4943764 Z M16.2071068,8.79289322 C15.8165825,8.40236893 15.1834175,8.40236893 14.7928932,8.79289322 L14.7928932,8.79289322 L11.333,12.252 L9.70710678,10.6262266 L9.61289944,10.5430379 C9.22060824,10.2380131 8.65337718,10.2657426 8.29289322,10.6262266 C7.90236893,11.0167508 7.90236893,11.6499158 8.29289322,12.0404401 L8.29289322,12.0404401 L11.3333333,15.0808802 L16.2071068,10.2071068 L16.2902954,10.1128994 C16.5953203,9.72060824 16.5675907,9.15337718 16.2071068,8.79289322 Z"
  />
);
