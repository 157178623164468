import React from 'react';
import {
  useGetTaskStatusQuery,
  useMoveUnassignedPatientToCareTeamUsersMutation,
} from '../scenes/Panels/ViewPanels/components/PanelPatientsListActionsFooter/index.generated';
import { GlobalContext } from '../components/GlobalContext';
import moment from 'moment/moment';

export const useMoveUnassignedPatients = (
  panelId: any,
  allSelected: any,
  excluded: any,
  selectedItems: any,
  // hardFresh: any,
  setEnableChangeCoordinatorDialog: any,
  setLoading: any,
  careGapFilters: any
) => {
  // update panel with new coordinators
  const [
    moveUnassignedPatientToCareTeamUsersMutation,
  ] = useMoveUnassignedPatientToCareTeamUsersMutation();
  const [polling, setPolling] = React.useState<any>(undefined);
  const [taskId, setTaskId] = React.useState('');
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  // console.log(careGapFilters);
  const assignCoordinator = async (fromUsers: any, toUsers: any) => {
    const lastCounterFromDayString = careGapFilters.ldosFrom
      ? moment.utc(careGapFilters.ldosFrom).format('YYYY-MM-DD')
      : '';
    const lastCounterToDayString = careGapFilters.ldosTo
      ? moment.utc(careGapFilters.ldosTo).format('YYYY-MM-DD')
      : '';
    // console.log(careGapFilters.userIds);
    const filter = {
      andName: careGapFilters.andName,
      andDob: careGapFilters.andDob,
      gender: careGapFilters.gender?.includes('All')
        ? []
        : careGapFilters.gender,
      age: careGapFilters.age?.includes('All') ? [] : careGapFilters.age,
      ldosFrom: lastCounterFromDayString,
      ldosTo: lastCounterToDayString,
      npis: careGapFilters.npis.map((pcp: any) => pcp.id) ?? [],
      openGaps: careGapFilters.openGaps?.includes('All')
        ? []
        : careGapFilters.openGaps,
      hasEngagement: careGapFilters.hasEngagement,
      hasFutureAppointment: careGapFilters.hasFutureAppointment,
      dueDateFrom: careGapFilters.dueDateFrom,
      dueDateTo: careGapFilters.dueDateTo,
      coordinationStatus: careGapFilters.coordinationStatus,
      divisionId: careGapFilters.divisionId,
      clinicId: careGapFilters.clinicId,
      languages: careGapFilters.languages,
      payerIds: careGapFilters.payerIds,
      lineOfBusiness: careGapFilters.lineOfBusiness,
      eligibilityStatuses: careGapFilters.eligibilityStatuses,
      careGapStatus: careGapFilters.careGapStatus,
      callAttemptCount: careGapFilters.callAttemptCount,
      newPatientOnly: careGapFilters.newPatientOnly,
    };
    const input = {
      panelId: panelId,
      filter: filter,
      excludePatientIds: allSelected
        ? excluded.map((patient: any) => patient.id)
        : [],
      selectedPatientIds: allSelected
        ? []
        : selectedItems.map((patient: any) => patient.id),
      toUserId: toUsers.map((item: any) => {
        return {
          count: item.assignedPatients,
          userId: item.id,
        };
      }),
    };

    try {
      const {
        data,
        errors,
      } = await moveUnassignedPatientToCareTeamUsersMutation({
        variables: {
          input: {
            ...input,
          },
        },
      });
      if (
        data &&
        data.moveUnassignedPatientToCareTeamUsers.entityId &&
        data.moveUnassignedPatientToCareTeamUsers.taskId
      ) {
        setTaskId(data.moveUnassignedPatientToCareTeamUsers.taskId);
        setToastMessage({
          snackbarMsg: `Care Coordinator assignment updated.`,
          severity: 'success',
          isOpen: true,
        });
        setIsOpenToast(true);
      } else if (errors) {
        // error response
        setToastMessage({
          snackbarMsg: `Fail to assign patients. Please try again.`,
          severity: 'error',
          isOpen: true,
        });
        setIsOpenToast(true);
        setEnableChangeCoordinatorDialog(false);
        // hardFresh();
      }
    } catch (error) {
      setToastMessage({
        snackbarMsg: `Fail to assign patients. Please try again.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
      setEnableChangeCoordinatorDialog(false);
      // hardFresh();
    }
  };

  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId, // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (taskId) {
      setLoading(true);
      setPolling(true);
      queryTask.startPolling(1000);
      setTimeout(() => {
        if (queryTask?.data?.getTaskStatus.status == true) {
          setLoading(false);
          setPolling(false);
          queryTask.stopPolling();
        }
      }, 1500);
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  return { polling, assignCoordinator, setPolling };
};
