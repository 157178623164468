import React, { useEffect } from 'react';
import moment from 'moment';
import { PanelPatient } from '../../../../../app.types.generated';
import { MixPanelEvents } from '../../../../../mixpanel/events';

interface CareGapContextType {
  showDrawer: boolean;
  drawerHandler: (event: any, patient: any) => void;
  checkedPatient: any;
  careGapFilters: any;
  setCareGapFilters: (input: any) => any;
  validateSearchInput: (input: string) => boolean;
  searchName: any;
  searchDob: any;
  value: number;
  setValue: (input: any) => void;
  switchDrawerTab: (event: any, newValue: number) => void;
  setCheckedPatient: (value: React.SetStateAction<PanelPatient>) => void;
  setSearchName: any;
  setSearchDob: any;
  deListPatientState: DeListPatientStateType;
  setDeListPatientState: React.Dispatch<
    React.SetStateAction<DeListPatientStateType>
  >;
  switchToNextPatient: boolean;
  setSwitchToNextPatient: React.Dispatch<React.SetStateAction<boolean>>;
  openCareGaps: any[];
  setOpenCareGaps: (input: any[]) => any;
}

const CareGapContext = React.createContext<CareGapContextType>(
  {} as CareGapContextType
);

const formats = [
  'MM-DD-yyyy',
  'MM/DD/yyyy',
  'MM DD yyyy',
  'YYYY-DD-MM',
  'YYYY/DD/MM',
  'YYYY DD MM',
];

const CareGapProvider: React.FC = ({ children }) => {
  const [openCareGaps, setOpenCareGaps] = React.useState<any[]>([]);
  const [value, setValue] = React.useState(0); // 0 patient info, 1 Engagement, 2 Care Gaps
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [checkedPatient, setCheckedPatient] = React.useState<PanelPatient>(
    {} as PanelPatient
  );
  const [searchName, setSearchName] = React.useState('');
  const [searchDob, setSearchDob] = React.useState('');
  const [careGapFilters, setCareGapFilters] = React.useState({
    gender: [],
    age: [],
    ldosFrom: '',
    ldosTo: '',
    openGaps: [],
    hasEngagement: '',
    hasFutureAppointment: '',
    npis: [],
    dueDateFrom: '',
    dueDateTo: '',
    coordinationStatus: [],
    divisionId: [],
    clinicId: [],
    userIds: [],
    languages: [],
    lob: [],
    healthPlan: [],
    eligibilityStatuses: [],
    careGapStatus: [],
    lineOfBusiness: [],
    payerIds: [],
    callAttemptCount: [],
  });

  const validateSearchInput: (input: string) => boolean = (
    searchInputString: string
  ) => {
    function isValidDateFormat(dobString: string) {
      // Regular expressions for each supported format
      const formats = [
        /^\d{4} \d{2} \d{2}$/,
        /^\d{4}\/\d{2}\/\d{2}$/,
        /^\d{4}-\d{2}-\d{2}$/,
        /^\d{2} \d{2} \d{4}$/,
        /^\d{2}\/\d{2}\/\d{4}$/,
        /^\d{2}-\d{2}-\d{4}$/,
      ];

      // Check if the date string matches any of the formats
      for (const format of formats) {
        if (format.test(dobString)) {
          return true;
        }
      }

      return false;
    }

    let removeSpace = searchInputString.replaceAll(' ', '');
    let commaCount = (searchInputString.match(/,/g) || []).length;
    let netLength = removeSpace.replaceAll(',', '');
    let searchName = '';
    let searchDob = '';

    if (removeSpace.length == 0 || netLength.length == 0) {
      return false;
    }
    if (commaCount > 2) {
      return false;
    }
    if (commaCount === 2) {
      // first part is andName : AAA, BBB
      // second part is DOB
      let searchItems = searchInputString.split(',');
      searchName = searchItems[0];
      searchDob = searchItems[1].replaceAll(' ', '');
      const isDateValid = moment(searchDob, formats).isValid();
      if (!isDateValid) {
        return false;
      }
      const date = moment(searchDob, formats).format('YYYY-MM-DD');
      setSearchName(searchName);
      setSearchDob(date);
      return true;
    }
    if (commaCount === 1) {
      // the typed string maybe either a string of andName (AAA, BBB) or andName, DOB
      let searchItems = searchInputString.split(',');
      searchName = searchItems[0];
      searchDob = searchItems[1].replaceAll(' ', '');
      const isDateValid = moment(searchDob, formats).isValid();
      if (!isDateValid) {
        // typed string is andName only
        setSearchName(searchInputString);
        return true;
      }
      // typed string is andName, DOB
      const date = moment(searchDob, formats).format('YYYY-MM-DD');
      setSearchName(searchName);
      setSearchDob(date);
      return true;
    }
    if (commaCount == 0) {
      let tmpInputString = searchInputString.trim();
      // DOB string contains: whitespace, dash, slash and numbers and must meets the formats listed above
      // Subscriber ID possible format:
      // - dash with letters and numbers: 5Q16-DV7-DE63
      // - numbers only: 111111111
      // - letter and numbers: 1PY9CD0JY56

      if (isValidDateFormat(tmpInputString)) {
        searchDob = searchInputString.replaceAll(' ', '');
        const isDateValid = moment(searchDob, formats).isValid();
        if (isDateValid) {
          const date = moment(searchDob, formats).format('YYYY-MM-DD');
          setSearchDob(date);
          setSearchName('');
        } else {
          setSearchName(searchInputString);
          setSearchDob('');
        }
      } else {
        setSearchName(searchInputString);
        setSearchDob('');
      }
      return true;
    }

    return false;
  };

  const drawerHandler = (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    // setState({ ...state, [anchor]: open });
    event.stopPropagation();
    setShowDrawer(!showDrawer);
    if (patient) {
      // Track open patient facesheet.
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.openPatientFaceSheet.name,
        { patientID: patient.id, panelID: patient.thisPanelId }
      );
      setCheckedPatient(patient);
    } else {
      setCheckedPatient({} as PanelPatient);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    }
  }, [showDrawer]);

  const switchDrawerTab = (event: React.MouseEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  // de-list patient
  const [
    deListPatientState,
    setDeListPatientState,
  ] = React.useState<DeListPatientStateType>({
    patientsHaveBeenDeListedFromCurrentPanel: false,
    patientDeListedFromCurrentPanel: false,
  });

  // change coordinator in patient face sheet
  const [switchToNextPatient, setSwitchToNextPatient] = React.useState(false);

  return (
    <CareGapContext.Provider
      value={{
        showDrawer,
        drawerHandler,
        checkedPatient,
        careGapFilters,
        setCareGapFilters,
        searchName,
        searchDob,
        validateSearchInput,
        value,
        setValue,
        switchDrawerTab,
        setCheckedPatient,
        setSearchName,
        setSearchDob,
        deListPatientState,
        setDeListPatientState,
        switchToNextPatient,
        setSwitchToNextPatient,
        openCareGaps,
        setOpenCareGaps,
      }}
    >
      {children}
    </CareGapContext.Provider>
  );
};

// custom hook to encapulate the useContext() where it needs
const useCareGapGlobalContext = () => {
  return React.useContext(CareGapContext);
};

export { CareGapContext, CareGapProvider, useCareGapGlobalContext };
