import { LogTypeEnum } from '../scenes/CareGaps/Home/tabs/Population/components/CoordinationLog/Container';

export const convertLogType2LogTypeEnum = (logType: string) => {
  switch (logType) {
    // case 'careAttempt': // ask backend for BatchSMS
    //     return LogTypeEnum.BatchSMS
    case 'careNotes':
      return LogTypeEnum.Notes;
    case 'careAttempt':
      return LogTypeEnum.CoordinationActions;
    case 'careStatusChange':
      return LogTypeEnum.StatusChange;
    case 'carePatientAssignment':
      return LogTypeEnum.PatientReassigned;
    default:
      return LogTypeEnum.All;
  }
};
