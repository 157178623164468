import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const usePopoverCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    // firstItemSpan: {
    //   fontWeight: 700,
    //   fontSize: 14,
    //   color: theme.palette.grey[700],
    // },
    subscriberIDsItem: {
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 3,
    },
    chip: {
      width: 'fit-content',
      height: 24,
      borderRadius: 6,
      backgroundColor: theme.palette.primary.light,
      '& .MuiChip-label': {
        padding: theme.spacing(0.25, 0.875),
        fontWeight: 600,
        fontSize: 12,
        color: theme.palette.primary.main,
      },
    },
    popover: {
      '& div.MuiPopover-paper': {
        borderRadius: 8,
      },
    },
    list: {
      paddingTop: 0,
    },
    listHeader: {
      padding: theme.spacing(1.25, 5, 1.25, 2.5),
      '& span': {
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.grey[500],
      },
      '& div.MuiListItemText-root': {
        marginTop: 1,
        marginBottom: 1,
      },
    },
    listItem: {
      paddingLeft: theme.spacing(2.5),
      textAlign: 'left',
      '& span': {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.grey[600],
      },
      '& div.MuiListItemText-root': {
        marginTop: 1,
        marginBottom: 1,
      },
    },
  })
);
