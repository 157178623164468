import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Button, Typography, Paper, OutlinedInput } from '@material-ui/core';
import { Forms } from '../../../View';

interface Props {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setRenderForm: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  resetPwCard: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(5, 4, 5, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  instructions: {
    fontSize: 16,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 14,
    height: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['700'],
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    height: 48,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 8,
  },
  backToLoginButton: {
    width: 'fit-content',
    padding: 0,
    height: 'fit-content',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderRadius: 0,
  },
  backToLogin: {
    fontSize: 14,
    fontWeight: 600,
    height: 24,
    color: theme.palette.secondary.dark,
    borderBottom: '2px solid #434343',
    paddingBottom: theme.spacing(0.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
}));

type Email = {
  email: string;
};

export const Step1ResetPw = (props: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const { control, handleSubmit, getValues, register, errors } = useForm<Email>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {
        email: '',
      },
    }
  );

  const sendResetEmail = async ({ email }: Email) => {
    setError(null);
    try {
      setLoading(true);
      const data = await Auth.forgotPassword(email);
      if (data) {
        props.setEmail(getValues('email'));
        props.setStep(2);
      }
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(sendResetEmail)} className={classes.root}>
      <Paper className={classes.resetPwCard} elevation={8}>
        <Typography className={classes.instructions}>
          Fill in your email address and we’ll send you an email with a code to
          reset your password.
        </Typography>
        <Typography className={classes.inputLabel}>Email</Typography>
        <Controller
          name="email"
          control={control}
          render={({ onChange, value, name }) => (
            <div className={classes.marginBottom}>
              <OutlinedInput
                fullWidth
                inputRef={register({
                  required: true,
                  minLength: 1,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors.email?.message || !!errors.email?.type}
                type="email"
                className={classes.inputField}
                placeholder="jacksmith@clinicbrand.com"
              />
              {error && (
                <Typography color="error" className={classes.errorMsg}>
                  You have entered an invalid email
                </Typography>
              )}
            </div>
          )}
        />
        <Button type="submit" color="primary" className={classes.submitButton}>
          {loading ? 'Loading...' : 'Send'}
        </Button>
        <Button
          className={classes.backToLoginButton}
          onClick={() => props.setRenderForm(Forms.Login)}
        >
          <span className={classes.backToLogin}>Back to Login</span>
        </Button>
      </Paper>
    </form>
  );
};
