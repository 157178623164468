export interface SendAllSMSInput {
  messageType: string;
  messageText: string;
  selectedPatientIds?: string[];
  excludePatientIds?: string[];
}

export enum SendPanelSmsLanguage {
  english = 'en',
  simpleChinese = 'cns',
  traditionalChinese = 'cnt',
}

export interface SendPanelSmsContent {
  language: SendPanelSmsLanguage;
  content: string;
}

export interface SendPanelSmsForm {
  type: string;
  templateId: string,
  templateName: string,
  messageList: Array<SendPanelSmsContent>;
}
