import React from 'react';
import { Button, Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 549,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D1D1D1',
    },
    '&. .MuiDialogActions-root': {
      padding: 0,
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
  },

  viewDetailBtn: {
    width: 118,
    height: 48,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  doneBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 73,
    height: 48,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 64,
    paddingLeft: 171,
    paddingRight: 171,
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  status: {
    height: 40,
    fontSize: 32,
    lineHeight: '40px',
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 10,
  },
  helpText: {
    marginTop: 10,
    color: theme.palette.secondary.contrastText,
    height: 24,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  circleContainer: {
    marginTop: 36,
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: '#E8F6F2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface DialogProps {
  onClose: any;
  link: string;
  hardFresh: any;
}

export const SendingSmsDialog = (props: DialogProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {
        props.onClose(false);
        props.hardFresh();
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div className={classes.root}>
          <div className={classes.circleContainer}>
            <CheckIcon style={{ width: 24, height: 24, color: '#105751' }} />
          </div>
          <div className={classes.status}>Sending SMS</div>
          <div className={classes.helpText}>
            We are sending SMS to selected patient(s) now.
          </div>
        </div>
      </DialogContent>
      <div className={classes.buttonRow}>
        <Button
          autoFocus
          onClick={() => {
            navigate(props.link);
            props.onClose(false);
          }}
          className={classes.viewDetailBtn}
        >
          View Detail
        </Button>
        <Button
          onClick={() => {
            props.onClose(false);
            props.hardFresh();
          }}
          className={classes.doneBtn}
          autoFocus
        >
          Done
        </Button>
      </div>
    </Dialog>
  );
};
