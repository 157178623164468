export const getPrioritizedSortingHeader = (orderByField: any) => {
  // this is to help Assigned Patient table to apply prioritized sorting rules when clicking the Coordination Status column
  if (orderByField === 'careCoordinationStatusForAssignedPatients') {
    return 'careCoordinationStatus';
  } else {
    return orderByField;
  }
};

export const setPrioritizedSortingHeader = (property: any) => {
  if (property === 'careCoordinationStatus') {
    return 'careCoordinationStatusForAssignedPatients';
  } else {
    return property;
  }
};
