import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useCheckPanelNameQuery } from '../../CreatePanel/index.generated';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { useGetPredictedTeamUsersWithFilterQuery } from '../index.generated';
import { MixPanelEvents } from '../../../../mixpanel/events';

const useStyles = makeStyles((theme) => ({
  largeNumberToolTip: {
    position: 'absolute',
    right: theme.spacing(1.5),
    bottom: 63,
    width: 389,
    height: 'fit-content',
    background: theme.palette.grey[700],
    borderRadius: theme.spacing(1),

    opacity: 0.8,
  },
  largeNumberToolTipText: {
    margin: theme.spacing(1, 1.5, 1, 1.5),
    color: '#FFF',
    fontSize: 12,
    textTransform: 'none',
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 420,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      // borderBottom: '1px solid #D1D1D1',
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'visible',
      paddingBottom: 24,
    },
  },
  editBtn: {
    width: 'fit-content',
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 156,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
    paddingTop: 16,
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(2),
  },
  divider12: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(1.5),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    height: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    // color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholderTextSelected: {
    // color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleButton: {
    lineHeight: '24px',
    height: 40,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: '#656565',
  },
  trashIcon: {
    color: '#656565',
    width: 24,
    height: 24,
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  description: {
    paddingBottom: 24,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
  },
  patientRow: {
    display: 'flex',
    height: 40,
    background: '#F8F8F8',
    borderRadius: 8,
    alignItems: 'center',
  },
  nameColumn: {
    width: '60%',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 16,
    paddingRight: 5,
  },
  countColumn: {
    width: '40%',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#434343',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  panelDetailRow: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    flexDirection: 'row',
    color: '#656565',
    marginBottom: 2,
    fontSize: 14,
    lineHeight: '24px',
    padding: theme.spacing(1),
    borderRadius: 6,
  },
  panelDetailLeft: {
    width: '40%',
    marginLeft: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
  },
  panelDetailRight: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightBold,
    color: '#434343',
    width: '50%',
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
  },
  panelDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
  },
}));

interface DialogProps {
  onClose: any;
  onSubmit: any;
  selectedItems: any;
  unAssignedCount: any;
  loading: boolean | undefined;
  clicked: boolean;
  handleViewOtherPanel: (input: any) => any;
  panelName: string;
  totalPatients: any;
  originPanelName: string;
  refinedInput: any;
  delistedPatientCount: number;
}

export const EditCoordinatorConfirmSubmitDialog = (props: DialogProps) => {
  const [isPanelNameDuplicate, setIsPanelNameDuplicate] = React.useState(true);
  const classes = useStyles();
  const [assignedPatients, setAssignedPatient] = React.useState(0);
  const checkPanelNameQuery = useCheckPanelNameQuery({
    variables: {
      input: {
        name: props.panelName,
      },
    },
  });
  const input = props.refinedInput;
  const getPredictedTeamUsersWithFilter = useGetPredictedTeamUsersWithFilterQuery(
    {
      variables: { input },
    }
  );
  React.useEffect(() => {
    if (props.panelName && !checkPanelNameQuery.loading) {
      if (!checkPanelNameQuery!.data!.checkPanelName.result) {
        setIsPanelNameDuplicate(false);
      } else {
        setIsPanelNameDuplicate(true);
      }
    }
  }, [checkPanelNameQuery]);
  React.useEffect(() => {
    if (
      getPredictedTeamUsersWithFilter?.data?.getPredictedTeamUsersWithFilter
        ?.length
    ) {
      let count = 0;
      getPredictedTeamUsersWithFilter?.data?.getPredictedTeamUsersWithFilter?.forEach(
        (item) => {
          count += item.count;
        }
      );
      setAssignedPatient(count);
    }
  }, [getPredictedTeamUsersWithFilter]);
  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {}}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {!checkPanelNameQuery.loading &&
        isPanelNameDuplicate &&
        props.panelName.toLowerCase() != props.originPanelName.toLowerCase() ? (
          <span style={{ color: '#B7373E' }}>Duplicate Panel Name</span>
        ) : (
          'Update Panel Now?'
        )}
      </DialogTitle>
      {checkPanelNameQuery.loading ||
      getPredictedTeamUsersWithFilter.loading ? (
        <LoadingSpinner />
      ) : (
        <DialogContent>
          <div className={classes.description}>Please confirm</div>
          <div className={classes.panelDetailContainer}>
            <div className={classes.panelDetailRow}>
              <div className={classes.panelDetailLeft}>Panel Name</div>
              <div className={classes.panelDetailRight}>{props.panelName}</div>
            </div>
            <div className={classes.panelDetailRow}>
              <div className={classes.panelDetailLeft}>Total Patients</div>
              <div className={classes.panelDetailRight}>
                {props.totalPatients} Patients
              </div>
            </div>
            <div className={classes.panelDetailRow}>
              <div className={classes.panelDetailLeft}>Care Team</div>
              <div className={classes.panelDetailRight}>
                {getPredictedTeamUsersWithFilter?.data
                  ?.getPredictedTeamUsersWithFilter?.length || 0}{' '}
                Coordinator(s) Assigned
                <span className={classes.subText}>
                  {assignedPatients} Patients
                </span>
              </div>
            </div>
            <div className={classes.panelDetailRow}>
              <div className={classes.panelDetailLeft}>Unassigned</div>
              <div className={classes.panelDetailRight}>
                {props.totalPatients -
                  assignedPatients -
                  props.delistedPatientCount}{' '}
                Patients
              </div>
            </div>
          </div>
        </DialogContent>
      )}
      {/*<div className={classes.divider} />*/}
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            autoFocus
            onClick={
              props.clicked && props.loading
                ? props.handleViewOtherPanel
                : props.onClose
            }
            className={classes.editBtn}
            disabled={props.clicked || props.loading}
          >
            {props.clicked && props.loading ? 'View Panel Summary' : 'Edit'}
          </Button>
          {/* tooltip for large number */}
          {}
          {props.clicked && props.loading && (
            <div className={classes.largeNumberToolTip}>
              <Typography className={classes.largeNumberToolTipText}>
                Assigning large number of patients will take longer than usual.
                Please wait for the process to be done or View other panels and
                come back later.
              </Typography>
            </div>
          )}
          <Button
            onClick={() => {
              !props.clicked && !props.loading && props.onSubmit();
              MixPanelEvents.track(
                MixPanelEvents.userActivityAndJourneyMapping.updatePanel.name
              );
            }}
            disabled={
              isPanelNameDuplicate &&
              props.panelName.toLowerCase() !=
                props.originPanelName.toLowerCase()
            }
            className={classes.createNowBtn}
            autoFocus
          >
            {props.clicked && props.loading && (
              <div
                className="loader"
                style={{
                  width: 18,
                  height: 18,
                  border: '2px solid #f3f3f3',
                  borderTop: '2px solid #3498db',
                  marginRight: 8,
                }}
              />
            )}

            {props.clicked && props.loading ? 'Updating' : 'Update Now'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
