interface Props {
  style?: {};
  customText?: string
}

export const LoadingSpinner = (props: Props) => {
  return (
    <div className="loaderContainer" style={props.style}>
      <div className="loader"></div>
        {props.customText?
            <div className="customLoadingText">{props.customText}</div>:
            <div className="loadingText">Loading...</div>
        }

    </div>
  );
};
