import React from 'react';
import { makeStyles } from '@material-ui/core';

interface CircleAvatarProps {
  avatarDiameter: number;
  avatarUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatarBgColor?: string;
}

const useCircleAvatarStyles = makeStyles((theme) => ({
  circleContainer: (props: CircleAvatarProps) => ({
    display: 'flex',
    width: props.avatarDiameter,
    height: props.avatarDiameter,
    borderRadius: '50%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.avatarBgColor
      ? props.avatarBgColor
      : theme.palette.avatars.yellow,
    backgroundImage: props.avatarUrl ? 'url(' + props.avatarUrl + ')' : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    fontSize: 10,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    flexShrink: 0,
  }),
}));

const nameInitials = (firstName: string, lastName: string) => {
  return firstName.charAt(0) + lastName.charAt(0);
};

export const CircleAvatar: React.FC<CircleAvatarProps> = (props) => {
  const classes = useCircleAvatarStyles(props);
  const { firstName, lastName, avatarUrl } = props;

  if (avatarUrl) return <div className={classes.circleContainer} />;

  return (
    <div className={classes.circleContainer}>
      {firstName && lastName ? nameInitials(firstName, lastName) : ''}
    </div>
  );
};
