import React from 'react';
import { useParams } from 'react-router';
import { useGetPanelSummaryQuery } from '../../Panels/ViewPanels/PanelSummary/index.generated';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
export const PanelSummaryContainer = () => {
  const { id, type } = useParams();


  const panel = useGetPanelSummaryQuery({
    variables: {
      input: {
        id: id!,
      },
    },
  });

  if (
    !panel.called ||
    panel.loading
  ) {
    return <LoadingSpinner />;
  }
  let tabValue = 0;
  switch (type) {
    case 'summary':
      tabValue = 0;
      break;
    case 'assigned':
      tabValue = 1;
      break;
    case 'engagement':
      tabValue = 4;
      break;
    default:
      break;
  }

  return (
    <></>
  );
};
