import React, { useEffect } from 'react';
import {Grid, makeStyles, Paper, Tab, Tabs} from '@material-ui/core';
import {DivisionContainer} from "./DivisionContainer";
import { useGetAllClinicAndDivisionPairsByMsoIdLazyQuery } from './index.generated';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiTabs-root': {
            borderBottom: 'none',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    subClass: {
        flexGrow: 1,
    },
    mainGrid: {
        margin: theme.spacing(0),
        width: '100%',
        height: '100%',
    },
    tabHeaderRow:{
        height: 56,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        background: '#F8F8F8',
        paddingLeft: 29,
        borderRadius: '8px 8px 0 0',
        
    },
    tabHeaderText:{
        fontSize: 20,
        lineHeight: '56px',
        fontWeight: theme.typography.fontWeightBold,
        color: '#434343',

    },
    paper: {
        width: 267,
        borderRadius: 8,
    },
    tabs: {
        width: 267,
        borderBottom: 'none',

        '& .MuiTabs-flexContainer.MuiTabs-flexContainerVertical': {
            padding: theme.spacing(1),
        },
    },
    tab: {
        width: 251,
        height: 40,
        padding: theme.spacing(1, 0),
        borderRadius: 8,
        '&.MuiTab-root': {
            minHeight: 0,
            paddingLeft: 12
        },
        '& > .MuiTab-wrapper': {
            alignItems: 'flex-start',
        },
        '&.MuiTab-textColorPrimary.Mui-selected': {
            background: theme.palette.primary.light,
        },
        '& > button.MuiButtonBase-root': {
            background: theme.palette.primary.light,
        },
    },
    deactiveTab: {
        color: theme.palette.grey[600],
    },
    activeTab: {
        color: theme.palette.primary.main,
    },
    tabText: {
        height: '24px',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 'bold',
        fontFamily: 'Manrope',
        textTransform: 'capitalize',
    },
}));

interface ClinicAndDivisionViewProps {
    data: any[]
}

export const ClinicAndDivisionView = (
    props: ClinicAndDivisionViewProps
) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [clinicAndDivisions, setClinicAndDivisions] = React.useState<any[]>([]);
    const [refresh, setRefresh] = React.useState(0);    
    const handleTabChange = (value: any, newValue: number) =>{
        setValue(newValue);
    };
    const [lazyQuery, lazyResponse] = useGetAllClinicAndDivisionPairsByMsoIdLazyQuery()
    
    useEffect(() => {
        lazyQuery()
    }, [lazyQuery])

    useEffect(() => {
        if (lazyResponse.data?.getAllClinicAndDivisionPairsByMsoID) {
            setClinicAndDivisions(lazyResponse.data.getAllClinicAndDivisionPairsByMsoID.filter((item) => item.clinicID === '9000'))
        }
    }, [lazyResponse])

    const onRefresh = () => {   
        setRefresh(refresh + 1);
        lazyResponse?.refetch && lazyResponse.refetch();
    }

    if (lazyResponse.loading || !lazyResponse.called) {
        return <LoadingSpinner
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          background: '#EFEFEF',
          opacity: 0.7,
          zIndex: 9999,
        }}
      />
    }

    const divisions = clinicAndDivisions[value]?.divisions

    
    return (
        <div className={classes.root}>
            <Grid container spacing={8} className={classes.mainGrid}>
                {/* profile option tabs */}
                <Grid item xs={2} container>
                    <div className={classes.root}>
                        <Paper elevation={1} component="div" className={classes.paper}>
                            <div className={classes.tabHeaderRow}><div className={classes.tabHeaderText}>Clinic ID</div></div>
                            <Tabs
                                orientation="vertical"
                                value={value}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs"
                                className={classes.tabs}
                                indicatorColor="primary"
                                textColor="primary"
                                // centered
                                TabIndicatorProps={{
                                    style: {
                                        height: 0,
                                    },
                                }}
                            >
                                {clinicAndDivisions.map((cd) => {
                                    return <Tab
                                    label={
                                        <span
                                            className={
                                                clinicAndDivisions[value]?.clinicID === cd.clinicID
                                                    ? `${classes.activeTab} ${classes.tabText}`
                                                    : `${classes.deactiveTab} ${classes.tabText}`
                                            }
                                        >
                                            {cd.clinicID}
                                        </span>
                                    }
                                    className={classes.tab}
                                />
                                })}
                            </Tabs>
                        </Paper>
                    </div>
                </Grid>
                {/* spacing */}
                <Grid item xs={1}/>

                {/* forms */}
                <Grid item xs={9}>
                    <DivisionContainer onRefresh={onRefresh} data={divisions??[]}/>
                </Grid>
            </Grid>
        </div>
    );
};
