import { makeStyles, Theme } from '@material-ui/core';

const useRedDotStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: `${theme.palette.primary.main}`,
  },
}));

interface NotificationDotProps {
  style?: any;
}

export const NotificationDot = (props: NotificationDotProps) => {
  const classes = useRedDotStyles();

  return <div className={classes.root} style={props.style}></div>;
};
