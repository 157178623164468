export interface Permission {
  id: string;
  name: string;
  msoAdmin: boolean;
  careManager: boolean;
  careCoordinator: boolean;
  clinician: boolean;
}

function createPermission(
  id: string,
  name: string,
  msoAdmin: boolean,
  careManager: boolean,
  careCoordinator: boolean,
  clinician: boolean
): Permission {
  return { id, name, msoAdmin, careManager, careCoordinator, clinician };
}

export const permissionRow = [
  createPermission('1000', 'Create User', true, true, false, false),
  createPermission('1001', 'Modify User', true, true, false, false),
  createPermission('1002', 'Change User Role', true, true, false, false),
  createPermission('1003', 'Create Panels', true, true, false, false),
  createPermission('1004', 'View All Panels', true, true, false, false),
  createPermission('1005', 'View Assigned Panels', true, true, true, false),
  createPermission('1006', 'Edit Panel', true, true, false, false),
  createPermission(
    '1007',
    'Manage Coordinator of Panel',
    true,
    true,
    false,
    false
  ),
  createPermission('1008', 'All Patient Lookup', true, true, true, false),
  createPermission(
    '1009',
    'Export Patient Panel list',
    true,
    true,
    true,
    false
  ),
  createPermission('1010', 'Send Batch SMS', true, true, true, false),
  createPermission('1011', 'Engagement Log', true, true, true, false),
  createPermission('1012', 'Start Coordination', true, true, true, false),
  createPermission('1013', 'Check Coordination Log', true, true, true, false),
  createPermission('1014', 'File Ingestion', true, false, false, false),
  createPermission(
    '1015',
    'Download Error Record of Ingested Files',
    true,
    false,
    false,
    false
  ),
  createPermission(
    '1016',
    'Create/Modify SMS Template',
    true,
    true,
    false,
    false
  ),
  createPermission('1017', 'View Dashboard', true, true, false, true),
  createPermission('1018', 'Create Dashboard Reports', true, true, false, true),
  createPermission('1019', 'Export Reports', true, true, false, true),
];
