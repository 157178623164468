export const ageOptions = [
  {
    name: '65+',
    value: '65-200',
    __typename: 'NameValue',
  },
  {
    name: '60-64',
    value: '60-64',
    __typename: 'NameValue',
  },
  {
    name: '50-59',
    value: '50-59',
    __typename: 'NameValue',
  },
  {
    name: '40-49',
    value: '40-49',
    __typename: 'NameValue',
  },
  {
    name: '30-39',
    value: '30-39',
    __typename: 'NameValue',
  },
  {
    name: '20-29',
    value: '20-29',
    __typename: 'NameValue',
  },
  {
    name: '10-19',
    value: '10-19',
    __typename: 'NameValue',
  },
  {
    name: '0-9',
    value: '0-9',
    __typename: 'NameValue',
  },
];

export const genderOptions = [
  {
    name: 'Male',
    value: 'M',
    __typename: 'NameValue',
  },
  {
    name: 'Female',
    value: 'F',
    __typename: 'NameValue',
  },
  {
    name: 'Unknown',
    value: 'X',
    __typename: 'NameValue',
  },
];
