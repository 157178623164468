import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../themes/theme';
import { Grid, Typography } from '@material-ui/core';
import { PayerFileListingTableView } from './PayerCareGapTable';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGetPayerFileMeasuresByIDsQuery } from '../index.generated';
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  control: {
    margin: 0,
    padding: 0,
    // boxShadow: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.grey[50],
    '& .MuiTabs-root': {
      borderBottom: 'none',
    },
    // borderRadius: 0,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTop: 'none',

    '& > .MuiTabs-root': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    '& div[role="tabBar"]': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  headerMainRow: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.grey[50],
    // borderBottom: `1.93px solid ${theme.palette.text.disabled}`,
    display: 'flex',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      height: 28,
      lineHeight: '28px',
      margin: theme.spacing(1.75, 3),
      display: 'inline-block',
      textTransform: 'capitalize',
    },

    '& #last-update-container': {
      marginLeft: 'auto',
      marginRight: '24px',
      display: 'flex',
      flexDirection: 'row',
    },

    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: '125px',
      height: '40px',
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0',
      textTransform: 'capitalize',
      marginLeft: '3.33px',
    },
  },
}));

export const CareGapsListContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const payerId = location?.state?.payerId ?? '';
  const { search } = location;
  const { id } = useParams();

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, {
      state: { payerId: location?.state?.payerId, name: location?.state?.name },
    });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const [sortBy, setSortBy] = React.useState({
    field: 'measureAbbr',
    method: 'asc' as const,
  });

  const careGapsListQuery = useGetPayerFileMeasuresByIDsQuery({
    variables: {
      request: {
        fileId: id,
        limit: limit ?? 10,
        payerId: payerId,
        skip: skip ?? 0,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  const fetchCareGapsListData = (queryResult: any | undefined) => {
    return queryResult?.getPayerFileMeasuresByIDs;
  };

  const careGapsList = React.useMemo(() => {
    if (careGapsListQuery.error) {
      return [];
    } else if (careGapsListQuery.data) {
      return fetchCareGapsListData(careGapsListQuery.data);
    }
  }, [careGapsListQuery]);

  return (
    <Grid container className={classes.root}>
      {/* region header */}
      <Grid item xs={12} className={classes.headerMainRow}>
        {/* title */}
        <Typography variant={'h2'}>Care Gaps</Typography>
      </Grid>
      <Grid item xs={12}>
        <PayerFileListingTableView
          refetchDataFunc={{
            func: careGapsListQuery.refetch,
            loading: careGapsListQuery.loading,
            params: {
              fileId: id,
              limit: limit ?? 10,
              payerId: payerId,
              skip: skip ?? 0,
              sortBy: [{ ...sortBy }],
            },
          }}
          setSortBy={setSortBy}
          sortBy={sortBy}
          results={careGapsList?.results ?? []}
          count={careGapsList?.count ?? 0}
          limit={limit ?? 10}
          skip={skip ?? 0}
          setLimit={onSetLimit}
          setSkip={onSetSkip}
        />
      </Grid>
    </Grid>
  );
};
