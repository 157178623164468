import { Drawer, Theme, makeStyles } from '@material-ui/core';
import React from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { NotificationCenterHeader } from './components/NotificationCenterHeader';
import { NotificationCenterContent } from './components/NotificationCenterContent';
import { GlobalContext } from '../../../../components/GlobalContext';
import { EmptyContent } from '../../../../scenes/CareGaps/Home/tabs/Population/components/CoordinationLog/View';

interface NotificationCenterViewProps {
  loading: boolean;
  items: any;
  clearNotificationsHandler: (itemIds: string[]) => void;
}

export const NotificationCenterView: React.FC<NotificationCenterViewProps> = ({
  loading,
  items,
  clearNotificationsHandler,
}) => {
  const { openNotificationCenter, handleNotificationCenter } = React.useContext(
    GlobalContext
  );
  const classes = useStyles();

  return (
    <div id="NotificationCenterView">
      <Drawer
        anchor={'right'}
        open={openNotificationCenter}
        onClose={handleNotificationCenter}
        className={classes.drawer}
      >
        <NotificationCenterHeader
          items={items}
          clearNotificationsHandler={clearNotificationsHandler}
        />
        {loading ? (
          <LoadingSpinner />
        ) : items.length === 0 ? (
          <EmptyContent
            selectedSectionName={'New Notifications'}
            coordinationLogData={[]}
            classes={classes}
          />
        ) : (
          <NotificationCenterContent
            items={items}
            clearNotificationsHandler={clearNotificationsHandler}
          />
        )}
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& > .MuiPaper-root': {
      width: 600,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
    },
  },
  emptyContentText: {
    fontSize: 20,
    color: theme.palette.grey[400],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    padding: theme.spacing(40, 22, 0, 22),
    textAlign: 'center',
  },
}));
