import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const AllAssignedIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M15.2817 11C16.9385 11 18.2817 9.65685 18.2817 8C18.2817 6.34315 16.9385 5 15.2817 5C13.6248 5 12.2817 6.34315 12.2817 8C12.2817 9.65685 13.6248 11 15.2817 11ZM7.78168 11.5C9.16239 11.5 10.2817 10.3807 10.2817 9C10.2817 7.61929 9.16239 6.5 7.78168 6.5C6.40097 6.5 5.28168 7.61929 5.28168 9C5.28168 10.3807 6.40097 11.5 7.78168 11.5ZM9.37228 15.8985C9.50339 15.2429 9.73987 14.6221 10.066 14.0565C9.95749 13.988 9.84513 13.9246 9.72917 13.8666C8.63809 13.3211 7.35384 13.3211 6.26276 13.8666C5.19306 14.4015 4.43021 15.4002 4.19566 16.573L3.73111 18.8957C3.72032 18.9497 3.76158 19 3.8166 19H9.2095C9.07571 18.6938 9.00148 18.3556 9.00148 18C9.00148 17.8354 9.01774 17.6711 9.05003 17.5097L9.37228 15.8985ZM10.848 16.1684C11.1216 14.8003 12.0116 13.635 13.2596 13.011C14.5325 12.3746 16.0308 12.3746 17.3038 13.011C18.5517 13.635 19.4417 14.8003 19.7154 16.1684L20.2573 18.8783C20.2699 18.9413 20.2218 19 20.1576 19H10.4058C10.3416 19 10.2934 18.9413 10.306 18.8783L10.848 16.1684Z"
  />
);
