import {makeStyles, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import {theme} from "../../../../../themes/theme";
import {EligibilityCategory} from "../../../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import BatchSmsButton from "../BatchSmsButton/View";
import ExportFileMenu from "../ExportFilesMenu/View";

interface IProps {
  category: EligibilityCategory
  patientCount: number
  rejectedReasonList?: Array<string>
}

export default function TableToolbar(props: IProps) {
  const {category, patientCount, rejectedReasonList} = props
  const classes = useStyles()

  const tableTitle = useMemo(() => {
    if (category === EligibilityCategory.internalActive) {
      return "Active Patient List (Internal)"
    } else if (category === EligibilityCategory.externalActive) {
      return "Active Patient List (External)"
    } else if (category === EligibilityCategory.unverified) {
      return "Unverified Patient List"
    } else if (category === EligibilityCategory.inactive) {
      return "Inactive Patient List"
    }else if (category === EligibilityCategory.unavailable) {
      return "Unavailable Patient List"
    }
    return "Patient List"
  }, [category])

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.title}>{tableTitle}</Typography>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <span className={classes.buttonBatchContainer}>
          <BatchSmsButton patientCount={patientCount} category={category} rejectedReasonList={rejectedReasonList} />
        </span>
        <span>
            <ExportFileMenu title={'Export'} category={category} rejectedReasonList={rejectedReasonList}/>
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  buttonBatchContainer: {
    marginRight: 12,
  },
  iconExport: {
    height: 20,
    width: 20,
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(0.5),
  },
  disableIcon: {
    height: 20,
    width: 20,
    paddingRight: theme.spacing(0.5),
  },
  option: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
  optionExport: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
  },
  titleContainer: {
    height: 56,
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    fontSize: 20,
    lineHeight: '28px',
    color: '#434343',
    fontWeight: 700,
  },
}))
