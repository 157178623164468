import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const DeactivateUserIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M13.4286 7.80952C13.4286 5.70476 11.7238 4 9.61905 4C7.51429 4 5.80952 5.70476 5.80952 7.80952C5.80952 9.91429 7.51429 11.619 9.61905 11.619C11.7238 11.619 13.4286 9.91429 13.4286 7.80952ZM2 17.3333V18.2857C2 18.8095 2.42857 19.2381 2.95238 19.2381H16.2857C16.8095 19.2381 17.2381 18.8095 17.2381 18.2857V17.3333C17.2381 14.8 12.1619 13.5238 9.61905 13.5238C7.07619 13.5238 2 14.8 2 17.3333ZM17.2381 9.71429H21.0476C21.5714 9.71429 22 10.1429 22 10.6667C22 11.1905 21.5714 11.619 21.0476 11.619H17.2381C16.7143 11.619 16.2857 11.1905 16.2857 10.6667C16.2857 10.1429 16.7143 9.71429 17.2381 9.71429Z"
  />
);
