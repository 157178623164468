import {
  Button,
  Chip,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { PanelsHeader } from '../../../components/PanelsHeader';
import { StepIndicator } from '../StepIndicator';
import { theme } from '../../../../../themes/theme';
import { useNavigate } from 'react-router';
import { PatientCount } from './components/PatientCount';
import { defaultValuesForStep2, ICD_CPT_Type } from './Container';
import {
  useGetIcdcptCodesQuery,
  useGetPcpsByNameQuery,
} from './queryICDCPT.generated';

import { Loading } from '../../../../../components/Loading';

import DateRangeCustomInput from '../../../../../components/DateRangeCustomInput';

import { CustomSelectWithCheckBox } from '../../../../../components/CustomSelectWithCheckBox';
import { ProviderAutoCompleteMulti } from '../../../../../components/ProviderAutoCompleteMulti';
import { MainRegion } from '../../../../../components/MainRegion';
import { CareGapAutoCompleteSelector } from '../../../../../components/CareGapAutoCompleteSelector/CareGapAutoCompleteSelector';
import { PatientGender } from '../../../../../app.types.generated';
import { sortArrayObjectPipe } from '../../../../../sharePipe/sortArrayObjectPipe';
import { StyledCheckbox } from '../../../../../components/StyledCheckbox';
import { CreatePanelConfirmPopUp } from '../ConfirmPopup/CreatePanelConfirmPopUp';
import { FormState } from '../../MasterForm';
import { CustomSelectWithLayer } from '../../../../../components/CustomSelectWithLayer';
import { useFeature } from '../../../../../components/FeatureFlag';
import { GroupByParent } from '../../../../../components/CustomSelectWithLayer/GroupByParent';
import {AutoCompleteMultipleSelect} from "../../../../../components/AutoCompleteMultipleSelect";

const genderOption = [
  { name: 'MALE', value: 'MALE', __typename: 'NameValue' },
  { name: 'FEMALE', value: 'FEMALE', __typename: 'NameValue' },
  { name: 'UNKNOWN', value: 'UNKNOWN', __typename: 'NameValue' },
];

const ageRangeOption = [
  { name: '65+', value: '65,1000', __typename: 'NameValue' },
  { name: '60-64', value: '60,64', __typename: 'NameValue' },
  { name: '50-59', value: '50,59', __typename: 'NameValue' },
  { name: '40-49', value: '40,49', __typename: 'NameValue' },
  { name: '30-39', value: '30,39', __typename: 'NameValue' },
  { name: '20-29', value: '20,29', __typename: 'NameValue' },
  { name: '10-19', value: '10,19', __typename: 'NameValue' },
  { name: '0-9', value: '0,9', __typename: 'NameValue' },
];

const AVATAR_BG = Object.values(theme.palette.avatars);

const useStyles = makeStyles((theme) => ({
  matchedText: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
  },
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',
    padding: theme.spacing(1.5),
    justifyContent: 'space-between',
  },
  stepsContainer: {
    height: '100%',
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
  },
  // SEPARATE into new CARD component
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  cardFooter: {
    height: 72,
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabledNextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabledText: {
    opacity: '0.5',
  },
  backButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
  },

  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
  },

  conditionMultiSelect: {
    width: '100%',
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  // shared select styles
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',
    width: '100%',
  },
  formControl: {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      boxShadow:
        '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(1),
  },
  inputHelper: {
    height: 20,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.67,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 5,
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.grey['500'],
  },
  // insurance select styles
  insuranceSelect: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
  },
  insurancePlaceholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  insuranceMenuItem: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  insuranceMenuItemText: {
    height: 24,
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.71,
  },
  insuranceList: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

    '& .MuiListItem-root': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    '& li .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  insuranceListItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  insuranceListItemText: {
    height: 24,
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  insuranceListitemHelper: {
    height: 16,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
  },
  // provider select styles
  providerPlaceholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  providerSelect: {
    width: '50%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  providerMenuItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  providerList: {
    margin: theme.spacing(4, 'auto'),
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

    '& .MuiListItem-root': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    '& li .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  providerListItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },

  // pcp avatar styles
  avatarContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.hint,
  },

  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(3),
  },
  dividerLineOfBussiness: {
    opacity: 0,
    marginTop: 0,
    marginBottom: theme.spacing(2.25),
  },

  // AUTOCOMPLETE STYLES
  autoComplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.25, 0, 0.25, 1),
      minHeight: 40,
      fontSize: 16,
      borderRadius: 6,
      boxShadow:
        '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    },
    '& ::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  autoCompleteDisabled: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.25, 0, 0.25, 1),
      minHeight: 40,
      fontSize: 16,
      borderRadius: 6,
      border: 'none',
    },
    '& ::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },

    '& fieldset': {
      border: 'none',
    },
  },
  acMenuRoot: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    height: 40,
    borderRadius: 8,
    justifyContent: 'flex-start',
    position: 'relative',
  },
  acMenuRootItemDiv: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  acMenuSelected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    width: '100%',
  },
  acOption: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0.5, 0),
    backgroundColor: 'white',
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
    },
    '& ::hover': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
  },
  codeText: {
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'relative',

    '& > span::after': {
      content: '...',
      display: 'block',
      width: '10px',
      height: '100%',
      fontWeight: theme.typography.fontWeightBold,
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  noAcOption: {
    fontSize: 14,
    fontWeight: 600,
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  manualSelectText: {
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
      fontWeight: 500,
    },
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    columnGap: 26,
  },
  rowStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '@media (maxWidth: 800)': {
      flexDirection: 'column',
    },
  },
  dividerTo: {
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (maxWidth: 800)': {
      width: 200,
    },
  },
  dividerText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['500'],
    lineHeight: '16px',
    fontSize: 12,
    paddingTop: 30,
  },
}));

interface Props {
  onNext: (
    lob: any[],
    clinicId: any[],
    division: any[],
    disease: any[],
    openGap: any[],
    patientRequirements: {
      hasAnyIcdCode: string[];
      hasAnyCptCode: string[];
    },
    patientCount: any,
    pcp: any[],
    age: string[],
    gender: PatientGender[],
    payerIds: string[],
    lastDateOfServiceFrom: Date | null,
    lastDateOfServiceTo: Date | null
  ) => any;
  onBack: () => any;
  step: number;
  step2State: any;
  setStep2State: any;
  options: any;
  providers: any;
  isOpenDialog: any;
  setIsOpenDialog: any;
  submitMasterForm: () => any;
  formState: FormState;
}

export const Step2PatientFiltersView = (props: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [patientCount, setPatientCount] = React.useState(0);
  const [icdInputValue, setIcdInputValue] = React.useState('');
  const [cptInputValue, setCptInputValue] = React.useState('');
  const [icdOpen, setIcdOpen] = React.useState(false);
  const [cptOpen, setCptOpen] = React.useState(false);
  const [icdOptions, setIcdOptions] = React.useState<ICD_CPT_Type[]>([]);
  const [cptOptions, setCptOptions] = React.useState<ICD_CPT_Type[]>([]);
  const [pcpsOptions, setPcpsOptions] = React.useState<any>([]);
  const [pcpsLoading, setPcpsLoading] = React.useState<boolean | undefined>(
    undefined
  );
  const [icdCptLoading, setIcdCptLoading] = React.useState<boolean | undefined>(
    undefined
  );
  const noCoveringProviderOption = {
    id: 'NoCoveringProvider',
  };


  const hasFeature = useFeature('coordination');
  const useFeatureDivision = useFeature('division');
  const onChange = (name: any, value: any) => {
    switch (name) {
      case 'lob':
        props.setStep2State({
          ...props.step2State,
          lob: value,
        });
        break;
      case 'payerIds':
        props.setStep2State({
          ...props.step2State,
          payerIds: value,
        });
        break;
      case 'clinicId':
        props.setStep2State({
          ...props.step2State,
          clinicId: value,
        });
        break;
      case 'division':
        props.setStep2State({
          ...props.step2State,
          division: value,
        });
        break;
      case 'disease':
        props.setStep2State({
          ...props.step2State,
          disease: value,
        });
        break;
      case 'openGap':
        props.setStep2State({
          ...props.step2State,
          openGap: value,
        });
        break;
      case 'gender':
        props.setStep2State({
          ...props.step2State,
          gender: value,
        });
        break;
      case 'age':
        props.setStep2State({
          ...props.step2State,
          age: value,
        });
        break;
      case 'lastDateOfServiceFrom':
        props.setStep2State({
          ...props.step2State,
          lastDateOfServiceFrom: value,
        });
        break;
      case 'lastDateOfServiceTo':
        props.setStep2State({
          ...props.step2State,
          lastDateOfServiceTo: value,
        });
        break;
      case 'pcp':
        props.setStep2State({
          ...props.step2State,
          pcp: value,
        });
        break;
    }
  };
  const [queryInput, setQueryInput] = React.useState({
    queryType: '',
    queryKeyword: '',
  });

  const queryICDCPT = useGetIcdcptCodesQuery({
    variables: {
      input: {
        type: queryInput.queryType,
        keyword: queryInput.queryKeyword,
        limit: 50,
        skip: 0,
      },
    },
  });

  const queryPcps = useGetPcpsByNameQuery({
    variables: {
      input: {
        limit: 1000,
        skip: 0,
      },
    },
  });

  React.useEffect(() => {
    if (!queryPcps.called || queryPcps.loading) {
      return undefined;
    }
    setPcpsLoading(false);
    const providerOptions = [noCoveringProviderOption]
    const data = queryPcps.data?.pcps?.result ?? [];
    setPcpsOptions(providerOptions.concat(data))
  }, [queryPcps.data]);

  React.useEffect(() => {
    if (!queryICDCPT.called || queryICDCPT.loading) {
      return undefined;
    }
    setIcdCptLoading(false);
    switch (queryInput.queryType) {
      case 'CPT':
        queryInput?.queryKeyword?.length >= 2
          ? setCptOptions(queryICDCPT.data!.getICDCPTCodes.codes!)
          : setCptOptions([]);
        break;
      case 'ICD':
        queryInput?.queryKeyword?.length >= 2
          ? setIcdOptions(queryICDCPT.data!.getICDCPTCodes.codes!)
          : setIcdOptions([]);
        break;
    }
  }, [icdInputValue, cptInputValue, queryInput, queryICDCPT.data]);

  const changeOptionBaseOnValue = (newValue: string, type: string) => {
    // type must be either ICD or CPT.
    setIcdCptLoading(true);
    switch (type) {
      case 'CPT':
        setCptInputValue(newValue);
        break;
      case 'ICD':
        setIcdInputValue(newValue);
        break;
    }

    setQueryInput({
      queryType: type,
      queryKeyword: newValue,
    });
  };

  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: any,
    target: string
  ) => {
    // console.log(target, value);
    // put ICD_CPT_Type obj into the array
    props.setStep2State({
      ...props.step2State,
      [target]: value,
    });

    // switch (target) {
    //   case 'cpt':
    //     setCptInputValue('');
    //     break;
    //   case 'icd':
    //     setIcdInputValue('');
    //     break;
    // }
  };

  const updateMasterForm = () => {
    const {
      lob,
      clinicId,
      division,
      disease,
      openGap,
      icd,
      cpt,
      pcp,
      age,
      gender,
      payerIds,
      lastDateOfServiceFrom,
      lastDateOfServiceTo,
    } = props.step2State;

    props.onNext(
      lob,
      clinicId,
      division,
      disease,
      openGap,
      {
        hasAnyIcdCode: icd.map((item: ICD_CPT_Type) => item.code.trim()),
        hasAnyCptCode: cpt.map((item: ICD_CPT_Type) => item.code.trim()),
      },
      patientCount,
      pcp,
      age,
      gender,
      payerIds,
      lastDateOfServiceFrom,
      lastDateOfServiceTo
    );
  };

  const filterICDOptions = (options: any, state: any) => {
    options.sort(function (a: any, b: any) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    const injectOptions: any[] = [];
    injectOptions.push(...props.step2State.icd);
    for (const option of options) {
      if (!props.step2State.icd.find((item: any) => item.code == option.code)) {
        injectOptions.push(option);
      }
    }

    return injectOptions;
  };

  const filterCPTOptions = (options: any, state: any) => {
    options.sort(function (a: any, b: any) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    const injectOptions: any[] = [];
    injectOptions.push(...props.step2State.cpt);
    for (const option of options) {
      if (!props.step2State.cpt.find((item: any) => item.code == option.code)) {
        injectOptions.push(option);
      }
    }

    return injectOptions;
  };

  const displayEllipsis = (
    containerSelector: string,
    ellipsisSelector: string,
    textSelector: string
  ) => {
    const queryResultDivs = document.querySelectorAll(
      // 'div[data-role="query-result-div"]'
      containerSelector
    );
    const overflowDivs = document.querySelectorAll(
      // 'div[data-role="overflow-style"]'
      ellipsisSelector
    );
    const queryResultSpans = document.querySelectorAll(
      // 'span[data-role="query-result-span"]'
      textSelector
    );

    for (let i = 0; i < (queryResultDivs ? queryResultDivs.length : 0); i++) {
      if (queryResultSpans && queryResultDivs && overflowDivs) {
        if (
          queryResultDivs[i].getBoundingClientRect().width <
          queryResultSpans[i].getBoundingClientRect().width
        ) {
          // to show ... if the text of code,name and desc is longer thant the div's length
          (overflowDivs[i] as HTMLElement).style.display = 'inline-block';
        } else {
          (overflowDivs[i] as HTMLElement).style.display = 'none';
        }
      }
    }
  };

  displayEllipsis(
    'div[data-role="query-result-div"]',
    'div[data-role="overflow-style"]',
    'span[data-role="query-result-span"]'
  );

  return (
    <MainRegion
      header={
        <PanelsHeader
          title="New Panel"
          renderButton={() => (
            <PatientCount
              lob={props.step2State.lob}
              payerIds={props.step2State.payerIds}
              clinicId={props.step2State.clinicId}
              division={props.step2State.division}
              diseases={props.step2State.disease}
              openGap={props.step2State.openGap}
              age={props.step2State.age}
              gender={props.step2State.gender}
              lastDateOfServiceFrom={props.step2State.lastDateOfServiceFrom}
              lastDateOfServiceTo={props.step2State.lastDateOfServiceTo}
              icd={props.step2State.icd.map((item: ICD_CPT_Type) =>
                item.code.trim()
              )}
              cpt={props.step2State.cpt.map((item: ICD_CPT_Type) =>
                item.code.trim()
              )}
              setPatientCount={setPatientCount}
              pcp={props.step2State.pcp}
            />
          )}
        />
      }
    >
      <Grid container spacing={3} className={classes.mainGrid}>
        {/* step indicator */}
        <Grid item xs={2} container className={classes.stepsContainer}>
          <StepIndicator step={props.step} />
        </Grid>

        {/* spacing */}
        <Grid item xs={1} />

        {/* input card */}
        {/*{icdCptLoading == undefined && <Loading />}*/}
        <Grid item xs={9}>
          <Paper className={classes.card} elevation={1}>
            {(
              <Grid container>
                <Grid item xs={12} className={classes.cardHeader}>
                  <Typography className={classes.cardTitle}>
                    Patient Filters
                  </Typography>

                  <Button
                    onClick={() => navigate('/panels')}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={12} className={classes.cardContent}>
                  <CustomSelectWithCheckBox
                    showValue={true}
                    state={props.step2State.clinicId}
                    type={'clinicId'}
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Clinic ID'}
                    placeholder={'Select Clinic ID(s)'}
                    width={387}
                    items={sortArrayObjectPipe(
                      props.options.Clinics || [],
                      'asc',
                      'name'
                    )}
                    fullWidth={true}
                    isObject={true}
                  />
                  {useFeatureDivision ? (
                    <CustomSelectWithCheckBox
                      state={props.step2State.division}
                      type={'division'}
                      setState={onChange}
                      isMultiple={true}
                      inputTitle={'Division'}
                      placeholder={'Select Division(s)'}
                      width={387}
                      items={sortArrayObjectPipe(
                        props.options.Divisions || [],
                        'asc',
                        'name'
                      )}
                      fullWidth={true}
                      isObject={true}
                    />
                  ) : null}
                  <div className={classes.divider} />
                  <CareGapAutoCompleteSelector
                    state={props.step2State.openGap}
                    type={'openGap'}
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Open Care Gaps'}
                    placeholder={'Search and Select Care Gap(s)'}
                    items={props.options.CareGap || []}
                  />
                  <CustomSelectWithCheckBox
                    state={props.step2State.payerIds}
                    type={'payerIds'}
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Payer'}
                    placeholder={'Select Payer(s)'}
                    width={387}
                    items={sortArrayObjectPipe(
                      props.options?.Payers ?? [],
                      'asc',
                      'name'
                    )}
                    fullWidth={true}
                    isObject={true}
                    showSelectedCount={true}
                    showValue={true}
                  />
                  <CustomSelectWithLayer
                    state={props.step2State.lob}
                    type={'lob'}
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Line of Businesses'}
                    placeholder={'Select Line of Business(es)'}
                    items={GroupByParent(
                      sortArrayObjectPipe(
                        props.options?.LineOfBusiness || [],
                        'asc',
                        'name'
                      ) || []
                    )}
                    isObject={true}
                    showSelectedCount={true}
                    fullWidth={true}
                  />
                  <CustomSelectWithCheckBox
                    state={props.step2State.disease}
                    type={'disease'}
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Diseases'}
                    placeholder={'Select Disease(s)'}
                    width={387}
                    items={props.options.DiseaseAndTags || []}
                    fullWidth={true}
                  />
                  <div className={classes.divider} />
                  <Typography className={classes.inputLabel}>ICD</Typography>
                  <Autocomplete
                    filterOptions={filterICDOptions}
                    inputValue={icdInputValue}
                    className={classes.autoComplete}
                    size="small"
                    multiple
                    open={icdOpen}
                    onOpen={() => {
                      setIcdOpen(true);
                    }}
                    onClose={() => {
                      setIcdOpen(false);
                      setIcdInputValue('');
                    }}
                    disableCloseOnSelect={true}
                    value={props.step2State.icd}
                    onChange={(e, v) => handleAutocompleteChange(e, v, 'icd')}
                    options={icdInputValue?.length >= 2 ? icdOptions : []}
                    renderTags={(value: ICD_CPT_Type[], getTagProps) =>
                      value.map((option: ICD_CPT_Type, index: number) => (
                        <Chip label={option.code} {...getTagProps({ index })} />
                      ))
                    }
                    popupIcon={<KeyboardArrowDown />}
                    closeIcon={false}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === 'input') {
                        changeOptionBaseOnValue(value, 'ICD');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          props.step2State.icd.length
                            ? ''
                            : 'Search ICD code(s)'
                        }
                      />
                    )}
                    noOptionsText={
                      icdInputValue?.length >= 2
                        ? icdCptLoading
                          ? 'Loading...'
                          : 'No matching ICD codes'
                        : 'Type ICD code or name to search'
                    }
                    classes={{
                      tagSizeSmall: classes.chip,
                      option: classes.acOption,
                      noOptions: classes.noAcOption,
                    }}
                    renderOption={(option: ICD_CPT_Type, { selected }) => {
                      const code_part = option.code;
                      const name_part = option.name;
                      const desc_part = option.desc;

                      return (
                        <MenuItem
                          selected={props.step2State.icd.includes(option)}
                          classes={{
                            root: classes.acMenuRoot,
                            selected: classes.selected,
                          }}
                        >
                          <div
                            className={classes.acMenuRootItemDiv}
                            data-role="query-result-div"
                          >
                            <div
                              data-role="overflow-style"
                              style={{
                                display: 'none',
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                position: 'absolute',
                                right: 16,
                                height: 20,
                              }}
                            >
                              ...
                            </div>
                            <span data-role="query-result-span">
                              <span
                                style={{
                                  marginRight: theme.spacing(1),
                                  fontWeight: theme.typography.fontWeightBold,
                                }}
                              >
                                {code_part}
                              </span>

                              <span
                                style={{
                                  marginRight: theme.spacing(1),
                                  fontWeight: theme.typography.fontWeightBold,
                                }}
                              >
                                {name_part}
                              </span>
                              <span
                                style={{
                                  fontWeight:
                                    theme.typography.fontWeightRegular,
                                }}
                              >
                                {desc_part}
                              </span>
                            </span>
                          </div>
                          <StyledCheckbox
                            checked={props.step2State.icd.includes(option)}
                            checkedAll={true}
                            className={classes.checkBoxPosition}
                          />
                        </MenuItem>
                      );
                    }}
                  />

                  <Typography className={classes.inputLabel}>CPT</Typography>
                  <Autocomplete
                    // freeSolo
                    filterOptions={filterCPTOptions}
                    inputValue={cptInputValue}
                    className={classes.autoComplete}
                    size="small"
                    multiple
                    open={cptOpen}
                    onOpen={() => {
                      setCptOpen(true);
                    }}
                    onClose={() => {
                      setCptOpen(false);
                      setCptInputValue('');
                    }}
                    disableCloseOnSelect={true}
                    value={props.step2State.cpt}
                    onChange={(e, v) => handleAutocompleteChange(e, v, 'cpt')}
                    options={cptInputValue?.length >= 2 ? cptOptions : []}
                    renderTags={(value: ICD_CPT_Type[], getTagProps) =>
                      value.map((option: ICD_CPT_Type, index: number) => (
                        <Chip label={option.code} {...getTagProps({ index })} />
                      ))
                    }
                    popupIcon={<KeyboardArrowDown />}
                    closeIcon={false}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === 'input') {
                        changeOptionBaseOnValue(value, 'CPT');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          props.step2State.cpt.length
                            ? ''
                            : 'Search CPT code(s)'
                        }
                      />
                    )}
                    noOptionsText={
                      cptInputValue?.length >= 2
                        ? icdCptLoading
                          ? 'Loading...'
                          : 'No matching CPT codes'
                        : 'Type CPT code or name to search'
                    }
                    classes={{
                      tagSizeSmall: classes.chip,
                      option: classes.acOption,
                      noOptions: classes.noAcOption,
                    }}
                    renderOption={(option: ICD_CPT_Type, { selected }) => {
                      const code_part = option.code;
                      const name_part = option.name;
                      let desc_part = option.desc;

                      return (
                        <MenuItem
                          selected={props.step2State.cpt.includes(option)}
                          classes={{
                            root: classes.acMenuRoot,
                            selected: classes.selected,
                          }}
                        >
                          <div
                            className={classes.acMenuRootItemDiv}
                            data-role="query-result-div"
                          >
                            <div
                              data-role="overflow-style"
                              style={{
                                display: 'none',
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                position: 'absolute',
                                right: 16,
                                height: 20,
                              }}
                            >
                              ...
                            </div>
                            <span data-role="query-result-span">
                              <span
                                style={{
                                  marginRight: theme.spacing(1),
                                  fontWeight: theme.typography.fontWeightBold,
                                }}
                              >
                                {code_part}
                              </span>
                              <span
                                style={{
                                  marginRight: theme.spacing(1),
                                  fontWeight: theme.typography.fontWeightBold,
                                }}
                              >
                                {name_part}
                              </span>
                              <span
                                style={{
                                  fontWeight:
                                    theme.typography.fontWeightRegular,
                                }}
                              >
                                {desc_part}
                              </span>
                            </span>
                          </div>
                          <StyledCheckbox
                            checked={props.step2State.cpt.includes(option)}
                            checkedAll={true}
                            className={classes.checkBoxPosition}
                          />
                        </MenuItem>
                      );
                    }}
                  />

                  <div className={classes.divider} />
                  {hasFeature ? (
                    <div className={classes.row}>
                      <AutoCompleteMultipleSelect
                          type={'pcp'}
                          options={pcpsOptions}
                          selectedOptions={props.step2State.pcp}
                          title={"Covering Providers"}
                          inputPlaceholder={"Search or Select"}
                          noOptionsText={pcpsLoading ? "Loading..." : "No matching providers"}
                          onChangedOptions={onChange}
                      />
                    </div>
                  ) : null}
                  <div className={classes.row}>
                    <CustomSelectWithCheckBox
                      state={props.step2State.age}
                      type={'age'}
                      setState={onChange}
                      isMultiple={true}
                      inputTitle={'Age'}
                      placeholder={'Select Age(s)'}
                      width={387}
                      items={ageRangeOption}
                      isObject={true}
                    />
                    <CustomSelectWithCheckBox
                      state={props.step2State.gender}
                      type={'gender'}
                      setState={onChange}
                      isMultiple={true}
                      inputTitle={'Gender'}
                      placeholder={'Select Gender(s)'}
                      width={387}
                      // items={props.options.Gender || []}
                      items={genderOption}
                      isObject={true}
                    />
                  </div>
                  <div className={classes.rowStart}>
                    <DateRangeCustomInput
                      inputTitle={'Last Date of Service'}
                      emptyLabel={'From'}
                      type={'lastDateOfServiceFrom'}
                      state={props.step2State.lastDateOfServiceFrom}
                      setState={onChange}
                      maxDate={props.step2State.lastDateOfServiceTo}
                      width={387}
                    />
                    <div className={classes.dividerTo}>
                      <span className={classes.dividerText}>To</span>
                    </div>
                    <DateRangeCustomInput
                      inputTitle={''}
                      emptyLabel={'Today'}
                      type={'lastDateOfServiceTo'}
                      state={props.step2State.lastDateOfServiceTo}
                      setState={onChange}
                      minDate={props.step2State.lastDateOfServiceFrom}
                      width={387}
                    />
                  </div>
                </Grid>
                <Grid item container xs={12} className={classes.cardFooter}>
                  <Button className={classes.backButton} onClick={props.onBack}>
                    Back
                  </Button>
                  {JSON.stringify(props.step2State) ===
                  JSON.stringify(defaultValuesForStep2) ? (
                    <Tooltip title="Please add at least 1 filter to go to next step">
                      <Button
                        className={classes.disabledNextButton}
                        onClick={() => {}}
                      >
                        <span className={classes.disabledText}>
                          Create Panel
                        </span>
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="No matching patients found, please update the filter you selected"
                      disableHoverListener={
                        !(
                          JSON.stringify(props.step2State) ===
                            JSON.stringify(defaultValuesForStep2) ||
                          patientCount == 0
                        )
                      }
                    >
                      <span>
                        <Button
                          disabled={
                            JSON.stringify(props.step2State) ===
                              JSON.stringify(defaultValuesForStep2) ||
                            patientCount == 0
                          }
                          className={classes.nextButton}
                          onClick={() => {
                            updateMasterForm();
                            props.setIsOpenDialog(!props.isOpenDialog);
                          }}
                        >
                          Create Panel
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {props.isOpenDialog && (
                    <CreatePanelConfirmPopUp
                      onClose={props.setIsOpenDialog}
                      onConfirm={props.submitMasterForm}
                      panelName={props.formState.name}
                      totalPatients={props.formState.patientCount}
                      careTeamNumbers={0}
                      assignedPatients={0}
                      unAssignedPatients={0}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </MainRegion>
  );
};
