import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useGetPatientCountForCriteriaQuery } from './index.generated';
import { Typography, Chip } from '@material-ui/core';
import { FlashingDot } from '../../../../../../components/FlashingDot';
import moment from 'moment';

interface Props {
  //new
  lob?: any[];
  payerIds?: any[];
  clinicId?: any[];
  division: any[];
  diseases: any[];
  openGap: any[];
  age?: any[];
  gender?: any[];
  lastDateOfServiceFrom?: any;
  lastDateOfServiceTo?: any;
  //new
  pcpId?: string;
  cpt?: string[];
  icd?: string[];
  setPatientCount?: (input: number) => void;
  pcp?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  chip: {
    height: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),

      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  },
}));
//todo new patientOptions

export const PatientCount = (props: Props) => {
  const classes = useStyles();
  const lastCounterFromDayString = props.lastDateOfServiceFrom
    ? moment.utc(props.lastDateOfServiceFrom).format('YYYY-MM-DD')
    : '';
  const lastCounterToDayString = props.lastDateOfServiceTo
    ? moment.utc(props.lastDateOfServiceTo).format('YYYY-MM-DD')
    : '';
  const ptCount = useGetPatientCountForCriteriaQuery({
    variables: {
      input: {
        pcpId: props.pcp?.map((item: any) => item.id),
        patientRequirements: {
          lineOfBusiness: props.lob?.includes('All')
              ? []
              : props.lob?.filter((l: string)=>!l.startsWith('parent,')),
          ageRanges: props.age?.includes('All') ? [] : props.age,
          lastEncounterFrom: lastCounterFromDayString, //yyyy-MM-dd
          lastEncounterTo: lastCounterToDayString, //yyyy-MM-dd
          gender: props.gender?.includes('All') ? [] : props.gender,
          chronicDisease: props.diseases?.includes('All') ? [] : props.diseases,
          hasAnyCptCode: props.cpt,
          hasAnyIcdCode: props.icd,
          openMeasures: props.openGap?.find(
            (item: any) => (item?.value || item) == 'All'
          )
            ? ['ALL']
            : props.openGap.map((item: any) => item.value ?? item),
          payerIds: props.payerIds?.includes('All') ? [] : props.payerIds,
          division: props.division?.includes('All') ? [] : props.division,
          clinicId: props.clinicId?.includes('All') ? [] : props.clinicId,
        },
      },
    },
  });

  const displayCount = ptCount.data?.getPatientCountForCriteria!.count;

  React.useEffect(() => {
    if (ptCount.data && props.setPatientCount)
      props.setPatientCount(ptCount.data!.getPatientCountForCriteria!.count);
  }, [ptCount]);

  return (
    <div className={classes.root}>
      <Typography className={classes.text} component="p">
        Matching
      </Typography>
      {!ptCount.called || ptCount.loading ? (
        <FlashingDot />
      ) : (
        <Chip className={classes.chip} label={displayCount} />
      )}
      <Typography className={classes.text} component="p">
        patients
      </Typography>
    </div>
  );
};
