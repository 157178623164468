import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Avatar, Chip, Typography} from "@material-ui/core";
import {theme} from "../../../../themes/theme";
import {ProcessingIcon} from "../../../../assets/Icons/FileUpload/ProcessingIcon";
import {NotRegisterIcon} from "../../../../assets/Icons/FileUpload/NotRegisterIcon";
import {UploadFailIcon} from "../../../../assets/Icons/FileUpload/UploadFailIcon";
import {EngagementLogStatus} from "../PanelPatients/components/PatientEngagementTable/Container";
import {PatientEngagementLog} from "./View";

type ColumnID = 'name' | 'type' | 'patientCount' | 'sendBy' | 'status' | 'lastUpdated' | 'dob' | 'gender'

interface Column {
  id: ColumnID;
  label: string;
  align?: 'right' | 'left';
}

const columns: Column[] = [
  { id: 'name', label: 'Name', align: 'left'},
  { id: 'dob', label: 'DOB', align: 'left'},
  { id: 'gender', label: 'Gender', align: 'left'},
  { id: 'type', label: 'Type',  align: 'left'},
  { id: 'sendBy', label: 'Sent by',  align: 'left'},
  { id: 'lastUpdated', label: 'Last Update',  align: 'left'},
  { id: 'status', label: 'Batch SMS Status',  align: 'left'},
];

interface IProps  {
  title: string,
  limit: number
  skip: number
  count: number,
  setLimit: (input: number) => void
  setSkip: (input: number) => void
  dataSource: Array<PatientEngagementLog>
}

export default function EngagementLogTable(props: IProps) {
  const {setLimit, setSkip, limit, skip, count, title, dataSource} = props
  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(+event.target.value);
  };

  const getStatusIcon = (status: EngagementLogStatus) => {
    switch (status) {
      case EngagementLogStatus.sending: {
        return <ProcessingIcon className={classes.sendingIcon} />;
      }
      case EngagementLogStatus.partialDelivered: {
        return <NotRegisterIcon className={classes.warningIcon} />;
      }
      case EngagementLogStatus.failed: {
        return <UploadFailIcon className={classes.failIcon} />;
      }
      default: {
        return <></>;
      }
    }
  }

  const getStyleByStatus = (status: EngagementLogStatus) => {
    switch (status) {
      case EngagementLogStatus.sending: {
        return classes.chipStatusSending
      }
      case EngagementLogStatus.partialDelivered: {
        return classes.chipStatusDeliveredWithFailed
      }
      case EngagementLogStatus.failed: {
        return classes.chipStatusFailed
      }
      case EngagementLogStatus.delivered:
        return classes.chipStatusDelivered
      default: {
        return classes.chipStatusSending;
      }
    }
  }

  const renderCellContentByColumnId = (cid: ColumnID, log: PatientEngagementLog) => {
    if (cid === 'name') {
      return <Typography className={classes.cellText}>{log.name}</Typography>
    } else if (cid === 'type') {
      return (
        <Chip label={log.type} className={classes.chip}/>
      )
    } else if (cid === 'dob') {
      return <Typography className={classes.cellText}>{log.dob}</Typography>
    }  else if (cid === 'gender') {
      return <Typography className={classes.cellText}>{log.gender}</Typography>
    } else if (cid === 'sendBy') {
      return <div className={classes.sendByContainer}>
        <div className={classes.sendByShorNameContainer}>
          <Typography className={classes.sendByShorName}>{log.shortName}</Typography>
        </div>
        <Typography className={classes.cellText}>{log.sendBy}</Typography>
      </div>
    } else if (cid === 'status') {
      const icon = getStatusIcon(log.status)
      const style = getStyleByStatus(log.status)
      return (
        <Chip icon={icon} label={log.statusDescription} className={style}/>
      )
    } else if (cid === 'lastUpdated') {
      return <Typography className={classes.cellText}>{log.lastUpdated}</Typography>
    }
  }

  const page = limit ? Math.floor(skip / limit) : 0
  return (
    <Paper className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <TableContainer className={classes.container}>
        <Table aria-label="sticky table">
          <TableHead >
            <TableRow className={classes.header}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.headerCell}
                  style={{ minWidth: 170 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((row, idx) => {
              return (
                <TableRow className={classes.row} hover role="checkbox" tabIndex={-1} key={idx}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align}  style={{ minWidth: 170, }} className={classes.cell}>
                        {renderCellContentByColumnId(column.id, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {

  },
  titleContainer: {
    paddingLeft: 24,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #D1D1D1',
  },
  title: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 700,
    color: '#434343'
  },
  header: {
    backgroundColor: '#F8F8F8',
  },
  headerCell: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    color: '#888888',
  },
  row: {
    height: 56,
  },
  cell: {
    paddingLeft: 24,
  },
  cellText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500
  },
  sendByContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center'
  },
  sendByShorNameContainer: {
    backgroundColor: '#F5CA32',
    borderRadius: 12,
    height: 24,
    width: 24,
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  sendByShorName: {
    fontSize: 10,
    lineHeight: '14px',
    color: '#FFF',
    padding: 0,
    fontWeight: 700
  },
  chip: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sendingIcon: {
    color: '#0C77D8',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  warningIcon: {
    color: '#C05314',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  failIcon: {
    color: '#B7373E',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  chipStatusSending: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDelivered: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#888888',
    fontWeight: 600,
    backgroundColor: '#EFEFEF',
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDeliveredWithFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.warning.main,
    fontWeight: 600,
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.error.main,
    fontWeight: 600,
    backgroundColor: theme.palette.error.light,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
      {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
    '& p[id]': {
      marginRight: theme.spacing(5.75),
    },
  },
});
