export const ageOptions = [
  {
    name: '65+',
    value: '65-1000',
    __typename: 'NameValue',
  },
  {
    name: '60-64',
    value: '60-64',
    __typename: 'NameValue',
  },
  {
    name: '50-59',
    value: '50-59',
    __typename: 'NameValue',
  },
  {
    name: '40-49',
    value: '40-49',
    __typename: 'NameValue',
  },
  {
    name: '30-39',
    value: '30-39',
    __typename: 'NameValue',
  },
  {
    name: '20-29',
    value: '20-29',
    __typename: 'NameValue',
  },
  {
    name: '10-19',
    value: '10-19',
    __typename: 'NameValue',
  },
  {
    name: '0-9',
    value: '0-9',
    __typename: 'NameValue',
  },
];

export const genderOptions = [
  {
    name: 'Male',
    value: 'M',
    __typename: 'NameValue',
  },
  {
    name: 'Female',
    value: 'F',
    __typename: 'NameValue',
  },
  {
    name: 'Unknown',
    value: 'X',
    __typename: 'NameValue',
  },
];

export const engagementStatusOptions = [
  {
    name: 'SMS Sent',
    value: 'yes',
    __typename: 'NameValue',
  },
];

export const futureAppointmentOptions = [
  {
    name: 'Has Future Appt.',
    value: 'yes',
    __typename: 'NameValue',
  },
  {
    name: 'No Future Appt.',
    value: 'no',
    __typename: 'NameValue',
  },
];

export const careGapStatusOptions = [
  {
    name: 'Open Care gap',
    value: 'Open',
    __typename: 'NameValue',
  },
  {
    name: 'Get Supporting Document',
    value: 'Get_Supporting_Documentation',
    __typename: 'NameValue',
  },
  {
    name: 'Submit Supplemental',
    value: 'Submit_Submittal',
    __typename: 'NameValue',
  },
  {
    name: 'Assessed & Not Applicable',
    value: 'Assessed_And_Not_Applicable',
    __typename: 'NameValue',
  },
  {
    name: 'Closed Care Gap',
    value: 'Close',
    __typename: 'NameValue',
  },
];
