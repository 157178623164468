import React, { useState } from 'react';
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
  List,
} from '@material-ui/core';
import { StaticPatientCount } from '../../CreatePanel/components/Step2/components/StaticPatientCount';
import { CareTeamMember } from '../../../../app.types.generated';
import { useNavigate } from 'react-router';
import { useAssignCoordinatorDialogViewStyles } from '../AssignCoordinatorDialog/Styles';
import { CareTeamSelectItem } from '../../CreatePanel/components/CareTeamSelect/CareTeamSelectItem';
import { KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelAvatar } from '../../../../components/Avatar';
import { theme } from '../../../../themes/theme';
import { LinkIcon } from '../../../../assets/Icons/CreatePanel/LinkIcon';
import { DeleteCoordinatorConfirmDialog } from '../DeleteCoordinatorConfirmDialog';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ManageCoordinatorConfirmSubmitDialog } from './ManageCoordinatorConfirmSubmitDialog';
import { StyledCheckbox } from '../../../../components/StyledCheckbox';
import { RoleType } from '../../../../enum/Users/roleType';
import { ButtonMediumPrimaryText } from '../../../../components/Buttons';
import { AddIcon } from '../../../../assets/Icons/PanelsList';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '851px',
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));

interface AssignCoordinatorDialogViewProps {
  setShowManageCoordinatorDialog: (input: any) => void;
  assignedCoordinators: any[];
  coordinatorOptions: any[];
  allPatientCount: number;
  unassignedPatientCount: number;
  editCoordinators: (newCareTeamUsers: any) => any;
  loading: boolean | undefined;
  clicked: boolean;
  setClicked: (input: boolean) => void;
  assignedPatients: number;
  setAssignedPatients: (input: number) => void;
  firstQueryLoading: boolean | undefined;
  onConfirm: boolean;
  setOnConfirm: (input: boolean) => void;
  source?: string;
  delistedPatientCount: number;
}

export const ManageCoordinatorDialogView = (
  props: AssignCoordinatorDialogViewProps
) => {
  const {
    setShowManageCoordinatorDialog,
    assignedCoordinators,
    coordinatorOptions,
    assignedPatients,
    setAssignedPatients,
    allPatientCount,
    onConfirm,
    setOnConfirm,
    delistedPatientCount,
  } = props;
  const classes = useAssignCoordinatorDialogViewStyles();
  const [careTeamOptions, setCareTeamOptions] = React.useState<any>([]);
  const [selectedItems, setSelectedItems]: any[] = useState([]);
  const [disabled, setDisabled] = React.useState(true);
  const [isClickedAdd, setIsClickedAdd] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(
    false
  );
  const [onDeleteUser, setOnDeleteUser] = React.useState<any>(null);
  // const [onConfirm, setOnConfirm] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    // close dialog
    setShowManageCoordinatorDialog(false);
  };

  const handleViewOtherPanel = () => {
    // close dialog
    setShowManageCoordinatorDialog(false);
    navigate('/panels');
  };

  // remove the coordinators in assigned coordinators
  React.useEffect(() => {
    let temp = [] as any[];
    assignedCoordinators.forEach((assigned: CareTeamMember) => {
      let tempUser = { ...assigned.user, assignedPatients: assigned.count };
      let me = coordinatorOptions.find(
        (option) => option.id === assigned.user.id
      );
      if (me) {
        tempUser.role = me.role;
      }
      temp.push(tempUser);
    });

    setSelectedItems(temp);
  }, [assignedCoordinators, coordinatorOptions]);

  React.useEffect(() => {
    if (coordinatorOptions && coordinatorOptions.length > 0) {
      const tmpCoordinatorOption = [];

      for (let coordinatorOption of coordinatorOptions) {
        if (coordinatorOption.role.roleName === RoleType.ADMIN) {
          let tmpRole = { ...coordinatorOption.role };
          let newRoleName = RoleType.CARE_MANAGER;
          tmpCoordinatorOption.push({
            ...coordinatorOption,
            role: {
              ...tmpRole,
              roleName: newRoleName,
            },
          });
        } else {
          tmpCoordinatorOption.push(coordinatorOption);
        }
      }

      setCareTeamOptions([...tmpCoordinatorOption]);
    }
  }, [coordinatorOptions]);

  const checkAndAssignPatient = (careTeamMember: any, count: any) => {
    let temp = selectedItems;
    let totalCount = 0;
    temp.forEach((item: any) => {
      if (item.id === careTeamMember.id) {
        item.assignedPatients = count ?? 0;
      }
      totalCount += item.assignedPatients ?? 0;
      //
      let check =
        temp.filter(
          (selectedItem: any) =>
            selectedItem.assignedPatients === undefined ||
            selectedItem.assignedPatients === 0 ||
            selectedItem.assignedPatients === '0'
        ).length !== 0;
      if (disabled != check) setDisabled(check);
    });
    setSelectedItems(temp);
    setAssignedPatients(totalCount);
  };

  const updateAssignedCoordinatorHandler = async () => {
    props.setClicked(true);
    // call update api
    await props.editCoordinators(
      selectedItems.map((item: any) => {
        return {
          count: parseInt(item.assignedPatients),
          userId: item.id,
        };
      })
    );
  };

  React.useEffect(() => {
    if (props.clicked && props.loading === false) {
      // close dialog
      setShowManageCoordinatorDialog(false);
    }
  }, [props.loading, props.clicked, setShowManageCoordinatorDialog]);

  const [
    careTeamSelectInputValue,
    setCareTeamSelectInputValue,
  ] = React.useState('');
  const changeOptionBaseOnValue = (newValue: string, type: string) => {
    setCareTeamSelectInputValue(newValue);
  };

  const removeSelectedCareTeam = () => {
    const targetToRemove = onDeleteUser;
    let newCount = assignedPatients - targetToRemove.assignedPatients ?? 0;
    setAssignedPatients(newCount);
    if (targetToRemove) {
      const newSelectedItem = selectedItems.filter(
        (item: any) => item?.id !== targetToRemove?.id
      );

      setSelectedItems(newSelectedItem);
      setOnDeleteUser(null);

      //
      let check =
        newSelectedItem.filter(
          (selectedItem: any) =>
            selectedItem.assignedPatients === undefined ||
            selectedItem.assignedPatients === 0 ||
            selectedItem.assignedPatients === '0'
        ).length !== 0;
      if (disabled != check) setDisabled(check);
    }
  };

  const matchSelectedCareTeam = (fullNameList: any[]) => {
    // check is any member is removed from select
    if (fullNameList.length < selectedItems.length) {
      let removed: any = selectedItems.filter(function (objOne: any) {
        return !fullNameList.some(function (objTwo) {
          return objOne.id === objTwo.id;
        });
      });
      removed = removed.length > 0 ? removed[0] : removed;
      const count =
        removed.assignedPatients === undefined ? 0 : removed.assignedPatients;
      if (count !== 0) {
        props.setAssignedPatients(props.assignedPatients - count);
      }
    }
    fullNameList.forEach((item) => {
      const match = selectedItems.find((user: any) => user.id === item.id);
      item.assignedPatients =
        match?.assignedPatients === undefined ? 0 : match.assignedPatients;
    });
    setSelectedItems(fullNameList);
    // props.updateMasterForm(fullNameList);

    setCareTeamSelectInputValue('');
  };

  const filterOptions = (options: any, state: any) => {
    const injectOptions: any[] = [];
    const isSearch = careTeamSelectInputValue.length > 0;
    const inputValueLower = careTeamSelectInputValue.toLowerCase();
    for (const option of options) {
      if (!selectedItems.find((item: any) => item.id == option.id)) {
        if (isSearch) {
          if (
            (option?.firstName + ' ' + option?.lastName)
              .toLowerCase()
              .includes(inputValueLower)
          ) {
            injectOptions.push(option);
          }
        } else {
          injectOptions.push(option);
        }
      }
    }
    return injectOptions;
  };

  const [openOptions, setOpenOptions] = React.useState(true);

  return (
    <div className={classes.root}>
      {onConfirm ? (
        <ManageCoordinatorConfirmSubmitDialog
          onSubmit={() => updateAssignedCoordinatorHandler()}
          onClose={() => setOnConfirm(false)}
          selectedItems={selectedItems}
          unAssignedCount={
            allPatientCount - assignedPatients - delistedPatientCount
          }
          clicked={props.clicked}
          loading={props.loading}
          handleViewOtherPanel={handleViewOtherPanel}
        />
      ) : (
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={true}
        >
          <FormControl
            role="assign-coordinator-dialog-form"
            className={classes.formControl}
          >
            <Paper className={classes.paperContainer}>
              <Grid container>
                {/* section header */}
                <Grid item xs={12} className={classes.cardHeader}>
                  {/* title */}
                  <Typography className={classes.cardTitle}>
                    Manage Care Coordinator(s)
                  </Typography>
                  {/* patient count */}
                  <StaticPatientCount
                    patientCount={allPatientCount}
                    noChipStyle={false}
                  />
                </Grid>

                {/* main region */}
                <Grid item xs={12} className={classes.cardContent}>
                  {/*Assigned Care Coordinator(s) */}
                  {/* care team select */}
                  {/*Add Care Coordinator(s)*/}
                  <Grid item xs={12}>
                    <Typography
                      id={`Add Care Coordinator`}
                      variant="h5"
                      className={classes.inputTitle}
                    >
                      Add Care Coordinator(s)
                    </Typography>
                    <div className={classes.unassignedRow}>
                      {/* unassigned circle */}
                      <div className={classes.unassignedCircle}>
                        <LinkIcon
                          style={{
                            width: 24,
                            height: 24,
                            color: theme.palette.grey[400],
                            alignSelf: 'center',
                          }}
                        />
                      </div>
                      <Typography
                        variant="body1"
                        className={classes.unassignedText}
                      >
                        Unassigned
                      </Typography>
                      <div style={{ flexGrow: 1, width: 1, height: 1 }}></div>
                      {/* patients number and text */}
                      <div style={{ marginRight: theme.spacing(23.75) }}>
                        <Typography
                          variant="body1"
                          className={classes.numPatients}
                        >
                          {allPatientCount -
                            assignedPatients -
                            delistedPatientCount}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.patientsText}
                        >
                          Patient(s)
                        </Typography>
                      </div>
                    </div>
                    {selectedItems.length > 0 && (
                      <List className={classes.list}>
                        {selectedItems.map((item: any, idx: any) => {
                          return (
                            <CareTeamSelectItem
                              careTeamMember={item}
                              removeSelectedCareTeam={() => {
                                setShowDeleteConfirmDialog(true);
                                setOnDeleteUser(item);
                              }}
                              maxNumPatients={allPatientCount}
                              isBorder={idx !== selectedItems.length - 1}
                              setAssignedPatientToMember={(
                                careTeamMember: any,
                                count: any
                              ) => {
                                checkAndAssignPatient(careTeamMember, count);
                              }}
                              key={idx}
                              source={props.source}
                              section={'AddCareCoordinators'}
                            />
                          );
                        })}
                      </List>
                    )}
                    {showDeleteConfirmDialog && (
                      <DeleteCoordinatorConfirmDialog
                        onClose={setShowDeleteConfirmDialog}
                        onSubmit={() => {
                          removeSelectedCareTeam();
                        }}
                        cc={onDeleteUser}
                      />
                    )}
                    {careTeamOptions.length > 0 && isClickedAdd && (
                      <div className={classes.addCareTeamProvider}>
                        <Autocomplete
                          disableCloseOnSelect
                          open={openOptions}
                          onOpen={() => setOpenOptions(true)}
                          onClose={() => {
                            setOpenOptions(false);
                            setIsClickedAdd(false);
                          }}
                          inputValue={careTeamSelectInputValue}
                          onInputChange={(
                            event: object,
                            value: string,
                            reason: string
                          ) => {
                            if (reason === 'input') {
                              changeOptionBaseOnValue(value, 'lineOfBusiness');
                            }
                          }}
                          className={classes.autoComplete}
                          size="small"
                          multiple
                          style={{ width: '100%' }}
                          options={careTeamOptions}
                          getOptionSelected={(option, value) =>
                            option.id == value?.id
                          }
                          getOptionLabel={(option) =>
                            option.firstName + option.lastName
                          }
                          value={selectedItems}
                          onChange={(e, v) => {
                            setOpenOptions(true);
                            matchSelectedCareTeam(v);
                          }}
                          popupIcon={<KeyboardArrowDown />}
                          closeIcon={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              autoFocus
                              onFocus={() => setOpenOptions(true)}
                              variant="outlined"
                              placeholder={'Search by Name'}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <ButtonMediumPrimaryText
                                    disableRipple
                                    className={classes.addIconBtn}
                                    startIcon={
                                      <AddIcon className={classes.addIcon} />
                                    }
                                  />
                                ),
                              }}
                            />
                          )}
                          noOptionsText="No Care Coordinator Available."
                          classes={{
                            tagSizeSmall: classes.chip,
                            option: classes.acOption,
                            noOptions: classes.noAcOption,
                          }}
                          renderOption={(option, { selected }) => (
                            <MenuItem
                              component="div"
                              selected={
                                selectedItems.find(
                                  (item: any) => item.id === option.id
                                ) !== undefined
                              }
                              classes={{
                                root: classes.acMenuRoot,
                                selected: classes.selected,
                              }}
                            >
                              <LabelAvatar
                                firstName={option.firstName}
                                lastName={option.lastName}
                                title={
                                  option?.role?.roleName === 'Admin'
                                    ? RoleType.CARE_MANAGER
                                    : option?.role?.roleName ?? option.title
                                }
                                styles={classes}
                                careTeamSelectInputValue={
                                  careTeamSelectInputValue
                                }
                              />
                              <StyledCheckbox
                                checked={selected}
                                checkedAll={true}
                                style={{
                                  height: 25,
                                  position: 'absolute',
                                  right: '30px',
                                }}
                                // className={classes.checkBoxPosition}
                              />
                            </MenuItem>
                          )}
                        />
                      </div>
                    )}

                    {props.firstQueryLoading && (
                      <div
                        className={classes.assignedCoordinatorContainerEmpty}
                      >
                        <LoadingSpinner />
                      </div>
                    )}
                    {allPatientCount - assignedPatients < 0 && (
                      <div className={classes.patientOverFlowErrorMessage}>
                        You can only assign {allPatientCount} patients in total.
                      </div>
                    )}

                    {!isClickedAdd && !props.firstQueryLoading && (
                      <div className={classes.addCareTeamProvider}>
                        <ButtonMediumPrimaryText
                          onClick={() => setIsClickedAdd(!isClickedAdd)}
                          className={classes.addIconBtn}
                          startIcon={<AddIcon className={classes.addIcon} />}
                        />
                        <Typography
                          variant="body1"
                          className={classes.addCareTeamProviderText}
                          onClick={() => setIsClickedAdd(!isClickedAdd)}
                        >
                          Add Care Coordinators and Assign Patients
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {/* section footer */}
                <Grid item xs={12} className={classes.sectionFooter}>
                  <Button
                    id="back"
                    variant="contained"
                    style={{ width: 71 }}
                    className={classes.backBtn}
                    onClick={handleClose}
                  >
                    {'Back'}
                  </Button>

                  <Button
                    id="creation-button"
                    variant="contained"
                    disableElevation
                    className={classes.updateBtn}
                    onClick={() => setOnConfirm(true)}
                    disabled={
                      disabled ||
                      (props.clicked && props.loading) ||
                      allPatientCount - assignedPatients < 0
                    }
                  >
                    {'Update'}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </FormControl>
        </BootstrapDialog>
      )}
    </div>
  );
};
