import { AccountStatus } from './accountStatus';
import { Operator } from './operator';
import { UserRole } from './userRole';
import { ClinicIdAndDivisionIdPairs } from '../../scenes/Users/MsoAdmin/NewUsers/View';

export interface UserDivision {
  address: string;
  divisionID: string;
  divisionName: string;
}
export interface ClinicAndDivisionPairOutPut {
  address: string;
  clinicID: string;
  divisionID: string;
  divisionName: string;
  email: string;
  firstName: string;
  isSiteDesignee: boolean;
  lastName: string;
  msoID: string;
  roleName: string;
  userID: string;
}
export interface User {
  userId: string;
  cognitoId: string;
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  role: UserRole;
  status: AccountStatus | string;
  createdAt: string;
  createdBy: Operator;
  updatedAt: string;
  updatedBy: Operator;
  phone: string;
  clinics: string[];
  divisions?: UserDivision[];
  clinicAndDivisionPairs: ClinicAndDivisionPairOutPut[];
}

export function createUser(
  userId: string,
  cognitoId: string,
  firstName: string,
  lastName: string,
  email: string,
  roleName: string,
  role: UserRole,
  status: AccountStatus | string,
  createdAt: string,
  createdBy: Operator,
  updatedAt: string,
  updatedBy: Operator,
  phone: string,
  clinics: string[],
  divisions: UserDivision[],
  clinicAndDivisionPairs: ClinicAndDivisionPairOutPut[]
): User {
  return {
    userId,
    cognitoId,
    firstName,
    lastName,
    email,
    roleName,
    role,
    status,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    phone,
    clinics,
    divisions,
    clinicAndDivisionPairs,
  };
}
