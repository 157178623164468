'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.theme = void 0;
let styles_1 = require('@material-ui/core/styles');
let theme = styles_1.createMuiTheme({
  typography: {
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 'bold',
  },
  palette: {
    primary: {
      main: '#0C77D8',
      light: '#EDF6FF',
      dark: '#0761B3',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#E5E5E5',
      light: '#EFEFEF',
      dark: '#656565',
      contrastText: '#656565',
    },
    text: {
      primary: 'rgba(67, 67, 67, 100)',
      secondary: 'rgba(101, 101, 101, 100)',
      disabled: 'rgba(209, 209, 209, 100)',
      hint: 'rgba(136, 136, 136, 100)',
    },
    grey: {
      50: '#F8F8F8',
      100: '#EFEFEF',
      200: '#E5E5E5',
      300: '#D1D1D1',
      400: '#ABABAB',
      500: '#888888',
      600: '#656565',
      700: '#434343',
    },
    info: {
      light: '#EDF6FF',
      main: '#0C77D8',
      dark: '#0761B3',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#E8F6F2',
      main: '#008479',
      dark: '#105751',
      contrastText: '#fff',
    },
    warning: {
      light: '#FCF3E5',
      main: '#D46829',
      dark: '#913C00',
      contrastText: '#fff',
    },
    error: {
      light: '#FFEAEB',
      main: '#B7373E',
      dark: '#A02C32',
      contrastText: '#fff',
    },
    avatars: {
      yellow: '#F5CA32',
      voilet: '#BC62CD',
      teal: '#49BFA9',
      red: '#E66556',
      purple: '#8061D7',
      pink: '#D26EAA',
      orange: '#E4814B',
      green: '#8AC55C',
      cyan: '#41C3E8',
      blue: '#586EFF',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
  },
  // shadows: [
  //   'none',
  //   '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
  //   '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
  //   '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
  //   ...Array(20).fill('none')
  // ],
  overrides: {
    MuiSelect: {
      select: {
        '& :focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      },
      elevation2: {
        boxShadow:
          '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
      },
      elevation3: {
        boxShadow:
          '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
      },
    },
    MuiTab: {
      root: {
        padding: '6px 24px',
        '@media (min-width: 600px)': {
          minWidth: 'auto',
        },
      },
      textColorInherit: {
        opacity: '1',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid rgb(209, 209, 209)',
      },
    },
    MuiTableHead: {
      root: {
        boxShadow:
          '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
      },
    },
    MuiTableRow: {
      root: {
        height: '40px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '12.5px 0 12.5px 24px',
        borderBottom: '1px solid #d8d8d8',
      },
      body: {
        padding: '6px 16px 6px 16px',
      },
      sizeSmall: {
        padding: '6px 16px 6px 24px',
      },
    },
    MuiAvatar: {
      root: {
        width: 24,
        height: 24,
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        height: 40,
        textTransform: 'none',
        borderRadius: 8,
      },
      sizeSmall: {
        height: 32,
      },
      sizeLarge: {
        height: 64,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
      disableElevation: true,
    },
  },
});
exports.theme = theme;
