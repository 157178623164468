import mixpanel from 'mixpanel-browser';
import {config} from "../config";
export interface Dict {
  [key: string]: any;
}
export const MixPanelEvents = {
  userAdoption: {
      signIn: {
        name: "Sign In",
        description: ""
      }
  },
  userActivityAndJourneyMapping: {
    assignedPatientPageTime: {
      name: "Assigned Patients Page Time",
      description: "How much time user spend on Assigned Patient Page?",
    },
    openPatientFaceSheet: {
      name: "Open Patient Face Sheet",
      description: "How many users open patient face sheet",
    },
    patientFaceSheetPageTime: {
      name: "Patient Face Sheet Page Time",
      description: "How much time user spend on Patient Face sheet?",
    },
    captureCallOutCome: {
      name: "Capture Call OutCome",
      description: "How many Call Outcome user captures in a day?",
    },
    checkEligibilityProcessTime: {
      name: "Check Eligibility Process Time",
      description: "How many time user runs eligibility from Patient Face Sheet?",
    },
    addNotesInPatientFaceSheet: {
      name: "Add Notes In Patient FaceSheet",
      description: "How many notes are created by users in a given time?",
    },
    SendOutBatchSmsProcessTime: {
      name: "Send Out Batch Sms Process Time",
      description: "How many time User sends out Batch SMS from Panel?",
    },
    firstEligibilityCheckSuccess: {
      name: "First Eligibility Check Success",
      description: "How many successful First eligibility check from Patient Face sheet?",
    },
    reassignCoordinators: {
      name: "Reassign Coordinators",
      description: "How many times user reassign coordinators?",
    },
    updatePanel: {
      name: "Update Panel",
      description: "How many times user update panel?",
    }
  },
  userBehavior: {
    dashboardPageTime: {
      name: "Dashboard Page Time",
      description: "After user launch the app, how long do they stay on the dashboard before clicking away?",
    },
    panelSummaryPageTime: {
      name: "Panel Summary Page Time",
      description: "After user opened a panel, how long do they stay at the panel summary page before clicking away?",
    },
    startToCoordinationPatient: {
      name: "Start To Coordination Patient",
      description: "How many clicks of Start Coordination button on the panel summary page compare with click on the the Assigned Patient Tab?",
    }
  },
  init: () => {
    // Setup mixpanel for analysis
    if (!config.mixPanel.enable) {
      return
    }
    mixpanel.init(
      config.mixPanel.token as string,
      {
        debug: false,
        track_pageview: false,
        persistence: 'localStorage',
        api_host: config.mixPanel.proxy,
      });
  },

  track: (eventName: string, properties?: Dict) => {
    if (!config.mixPanel.enable) {
      return
    }
    try {
      mixpanel.track(eventName, properties)
    } catch (e) {
      //
    }
  },
  timeEvent: (eventName: string) => {
    if (!config.mixPanel.enable) {
      return
    }
    try {
      mixpanel.time_event(eventName)
    } catch (e) {
      //
    }
  },
  register: (properties: any) => {
    if (!config.mixPanel.enable) {
      return
    }
    try {
      mixpanel.register(properties)
    } catch (e) {
      //
    }
  },
  identify: (id: any) => {
    if (!config.mixPanel.enable) {
      return
    }
    try {
      mixpanel.identify(id)
    } catch (e) {
      //
    }
  },
  reset: () => {
    if (!config.mixPanel.enable) {
      return
    }
    try {
      mixpanel.reset()
    } catch (e) {
      //
    }
  }
}
