import React, { ChangeEvent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  fade,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  TableContainer,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { SearchIcon } from '../../../../assets/Icons/Eligibility/SearchIcon';
import { FiltersIcon } from '../../../../assets/Icons/Eligibility/FiltersIcon';
import {
  tableUseStyles,
  tableUseToolbarStyles,
} from '../../InsuranceListingPage/InsuranceListTable/TableStyle';
import { dateFormatPipe } from '../../../../sharePipe/dateFormatPipe';
import { ExportIcon } from '../../../../assets/Icons/InsurancesExportPatinets/ExportIcon';
import { FileDownloadIcon } from '../../../../assets/Icons/ExportPatients';
import { ExportInsurancesPatientsDialog } from '../ExportPatientsListDialog/ExportNewPatients';
import { GlobalContext } from '../../../../components/GlobalContext';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { hardcodingDateFormat } from '../../../../sharePipe/dobFormatPipe';
import { SubscriberIDCell } from '../../../CareGaps/Home/tabs/Population/components/CareGapPatientTable/components/SubscriberIDCell';

interface HeadCell {
  id: string;
  label: string;
  align: 'left' | 'right';
  disablePadding: boolean;
}

function createHeadCell(
  id: string,
  label: string,
  align: 'left' | 'right',
  disablePadding: boolean
): HeadCell {
  return { id, label, align, disablePadding };
}

const headCells: HeadCell[] = [
  createHeadCell('fullname', 'Patient Name', 'left', false),
  createHeadCell('gender', 'Gender', 'left', false),
  createHeadCell('dob', 'DOB', 'left', false),
  createHeadCell('subscriberIDs', 'Subscriber ID', 'left', false),
  createHeadCell('clinicId', 'Clinic ID', 'left', false),
  createHeadCell('lastUpdate', 'Upload Date', 'left', false),
];

const nonsortableHeads = ['gender', 'dob', 'lastUpdate','subscriberIDs','clinicId'];

type Order = 'asc' | 'desc';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  // rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the more-options column */}
        {/* <TableCell></TableCell> */}
      </TableRow>
    </TableHead>
  );
}

interface ItemListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  rows: any;
  count: number;
  fileId: string;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  refetchDataFunc?: any;
}

export const ItemListTableView = (props: ItemListTableViewProps) => {
  const classes = tableUseStyles();
  const altClasses = useStyles();
  const { limit, skip, setLimit, setSkip, rows, count, fileId } = props;
  const [isSorting, setIsSorting] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);
  const [searchInput, setSearchInput] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [exportOption, setExportOption] = React.useState('');

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openExportDialog = (format: string) => {
    setExportOption(format);
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { reloadUser } = React.useContext(GlobalContext);

  React.useEffect(() => {
    reloadUser();
  }, []);

  React.useEffect(() => {
    setFilteredRows(rows);
    setIsSorting(false);
  }, [rows]);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          request: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  // @ts-ignore
  return (
    <div className={`${classes.root} ${altClasses.root}`}>
      <Paper className={`${classes.paper} ${altClasses.rounded}`} elevation={1}>
        <div
          className={`${classes.lightBackground} ${altClasses.lightBackground}`}
        >
          <EnhancedTableToolbar
            tableTitle={`${count} New Patients`}
            //search bar
            enableSearch={false}
            searchPlaceHolder={'Search'}
            searchFunction={setSearchInput}
            renderButton={
              <Button
                aria-controls="export-patients-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                className={classes.exportPatientListButton}
                id={'exportPatientListButton'}
                disabled={!rows.length}
              >
                <ExportIcon className={classes.exportIcon} />
                <span>Export Patient List</span>
              </Button>
            }
          />
        </div>
        <ExportInsurancesPatientsDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formatText={exportOption}
          fileId={fileId}
          dialogTitle={'Export Patients'}
        />
        <StyledMenu
          id="export-patients-menu"
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'export-patients-menu',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              openExportDialog('xls');
            }}
            disableRipple
          >
            <span>Save as .xls</span>
            <FileDownloadIcon />
          </MenuItem>

          <MenuItem
            onClick={() => {
              openExportDialog('csv');
            }}
            disableRipple
          >
            <span>Save as .csv</span>
            <FileDownloadIcon />
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              openExportDialog('email');
            }}
            disableRipple
          >
            <span>Send Email</span>
            <SendEmailIcon />
          </MenuItem> */}

          {/* <MenuItem onClick={() => {}} disableRipple>
            <span>Save to Cloud</span>
            <SaveToCloudIcon />
          </MenuItem> */}
        </StyledMenu>

        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={data.count}
              />
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          key={`${index}-${row.fullname}`}
                          className={classes.tableBodyRow}
                        >
                          {/* <TableCell align="left">
                        <span className={classes.tableBodyRowCell}>
                            {row.mpatientId}
                        </span>
                        </TableCell> */}
                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {/* {`${row.firstName} ${row.lastName}`} */}
                              {row.fullname ?? '-'}
                            </span>
                          </TableCell>

                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {/* {genderShortFormPipe(row.gender)} */}
                              {row.gender ?? '-'}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {hardcodingDateFormat(row.dob || '', 'DOB')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <SubscriberIDCell
                              subscriberIDs={row.subscriberIDs}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {/* {genderShortFormPipe(row.gender)} */}
                              {row.clinicId ?? '-'}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {dateFormatPipe(row.lastUpdate, '-', true, true)}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {!props.refetchDataFunc.loading && !filteredRows.length ? (
                  <TableRow>
                    <TableCell align="left">
                      <span className={classes.noMoreDataDescription}>
                        No New Patient Found
                      </span>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(0.5), // -6.5 1.5
    width: 164,
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      borderRadius: 8,
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root:hover svg': {
      color: theme.palette.grey[600],
    },
    '& .MuiListItem-root:hover span': {
      color: theme.palette.grey[700],
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      width: '87px',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

interface EnhancedTableToolbarProps {
  tableTitle?: string;
  //search bar
  enableSearch?: boolean;
  searchPlaceHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  searchEnableFilter?: boolean;
  renderButton?: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = tableUseToolbarStyles();
  const altClasses = useStyles();
  return (
    <Paper
      className={`${classes.tableToolBarContainer} ${altClasses.tableToolBarContainer}`}
      elevation={0}
    >
      {props.tableTitle && (
        <div className={`${classes.header} ${altClasses.header}`}>
          {/* title */}
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {props.tableTitle}
          </Typography>
          {props.renderButton && (
            <div className={classes.headerButton}>{props.renderButton}</div>
          )}
        </div>
      )}
      {/* SearchBanner */}
      {props.enableSearch && (
        <div className={classes.searchBanner}>
          <SearchBanner
            placeHolder={props.searchPlaceHolder}
            searchFunction={props.searchFunction}
          />
        </div>
      )}
    </Paper>
  );
};

interface SearchBannerProps {
  placeHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  enableFilter?: boolean;
}

const SearchBanner = (props: SearchBannerProps) => {
  const classes = tableUseToolbarStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={props.placeHolder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
      {props.enableFilter && (
        <Button className={classes.filtersBtn}>
          <FiltersIcon className={classes.filtersIcon} />
          <Typography variant="body1" className={classes.filtersText}>
            Filters
          </Typography>
        </Button>
      )}
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: any;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = tableUseStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
