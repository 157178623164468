import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { Auth } from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import { onError } from '@apollo/client/link/error';
import { config } from '../config';
import rollbar from '../rollbar';
import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';

const uri = config.api.url;

type SignableRequest = {
  url: string;
  method: string;
  headers: HeadersInit;
  data?: BodyInit | null;
};

async function getAuthedRequestHeaders(
  request: SignableRequest
): Promise<HeadersInit> {
  if (!request.url.startsWith('https://')) {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      return {
        Authorization: token,
      };
    } catch (err) {}
  }

  const {
    accessKeyId,
    secretAccessKey,
    sessionToken,
  } = await Auth.currentUserCredentials();

  const authConfiguration = Auth.configure(null);

  const { headers } = Signer.sign(
    request,
    {
      access_key: accessKeyId,
      secret_key: secretAccessKey,
      session_token: sessionToken,
    },
    { region: authConfiguration.region, service: 'execute-api' }
  );

  return headers;
}

async function authedFetch(
  url: string,
  request?: RequestInit
): Promise<Response> {
  const data = request?.body ?? null;
  const headers = request?.headers ?? {};
  const method = request?.method ?? 'GET';

  const authedHeaders = await getAuthedRequestHeaders({
    url,
    data,
    headers,
    method,
  });
  try {
    return fetch(url, {
      ...(request ?? {}),
      headers: {
        ...headers,
        ...authedHeaders,
      },
    });
  } catch (err) {
    return err as any;
  }
}

const httpLink = new BatchHttpLink({
  uri,
  fetch: authedFetch,
});

export function MyApolloProvider({ showError, children }: any) {
  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          onError(({ graphQLErrors, networkError, operation, response }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach((error) => {
                // This is probably over logging but more > less
                rollbar.error(`GraphQL Error - ${error.message}`, {
                  error,
                  operation,
                });
              });
            }
            if (networkError) {
              const errors = (networkError as any)?.result?.errors;
              if (
                errors &&
                errors.find(
                  (e: any) =>
                    e?.message ==
                    'Context creation failed: Please be adviced that current user is disabled.'
                )
              ) {
                showError('DISABLED_USER');
              }
            }
          }),
          httpLink,
        ]),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          mutate: {
            errorPolicy: 'all',
          },
        },
      }),
    [showError]
  );
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
