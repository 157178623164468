import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useProfileOptionTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 267,
      // height: 100,
    },
    paper: {
      width: 267,
      // height: 100,
      borderRadius: 8,
    },
    tabs: {
      width: 267,
      // height: 100,
      borderBottom: 'none',

      '& .MuiTabs-flexContainer.MuiTabs-flexContainerVertical': {
        padding: theme.spacing(1),
      },
    },
    tab: {
      width: 251,
      height: 40,
      padding: theme.spacing(1, 1.5),
      borderRadius: 8,
      '&.MuiTab-root': {
        minHeight: 0,
      },
      '& > .MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
      '&.MuiTab-textColorPrimary.Mui-selected': {
        background: theme.palette.primary.light,
      },
      // '&$selected': {
      //   backgroundColor: 'red',
      // },
      // '&.MuiButtonBase-root': {
      //   backgroundColor: 'blue',
      // },

      // '& > button.MuiButtonBase-root:hover': {
      //   backgroundColor: theme.palette.grey[50],

      //   fontWeight: theme.typography.fontWeightBold,

      //   '&:hover $MuiTab-wrapper': {
      //     color: theme.palette.grey[700],
      //   },
      // },
      // '&.MuiListItem-root.Mui-selected:hover': {
      //   backgroundColor: theme.palette.primary.light,
      // },
      // '&.MuiListItem-root.Mui-selected': {
      //   backgroundColor: theme.palette.primary.light,
      // },
      '& > button.MuiButtonBase-root': {
        background: theme.palette.primary.light,
      },
    },
    deactiveTab: {
      color: theme.palette.grey[600],
    },
    activeTab: {
      color: theme.palette.primary.main,
    },
    tabText: {
      height: '24px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'bold',
      fontFamily: 'Manrope',
      textTransform: 'capitalize',
    },
  })
);
