import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import {Close} from '@material-ui/icons';
import MuiAlert, {AlertProps, Color} from '@material-ui/lab/Alert';
import moment from 'moment'
import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import {GetReportResponseItem} from "../../app.types.generated";
import {CustomSelectWithCheckBox} from "../../components/CustomSelectWithCheckBox";
import DateRangeCustomInput from "../../components/DateRangeCustomInput";
import {FlashingDot} from "../../components/FlashingDot";
import {GlobalContext} from "../../components/GlobalContext";
import {UserDefinedRole} from "../../enum/role";
import {
    useGetUsersByAccountIdQuery
} from "../Panels/ViewPanels/PanelPatients/components/AssignedPatientsTable/index.generated";
import {
    useGetReportByPageLazyQuery,
    useGetReportFileUrlLazyQuery,
    useSaveReportByFilterMutation
} from "./index.generated";
import styles from './page.module.css'
import ReportTable from "./reportTable";
import {Format} from "./types";

interface User {
    userId: string,
    userName: string
}

export type Modules = "Patient review" | "Call attempt" | "Call outcomes" | "User Activity"

interface Filter {
    userIds: string[],
    startDate: string,
    endDate: string,
    modules: string[]
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ReportContainer() {
    const {loggedInUser} = useContext(GlobalContext);
    const [tab, setTab] = useState(0);
    const [total, setTotal] = useState(0)

    const [loadingForGenerating, setLoadingForGenerating] = useState(false)
    const [filter, setFilter] = useState<Filter>({
        userIds: ['All'],
        startDate: moment().subtract(90, 'days').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        modules: ['All']
    })
    const [alertOpt, setAlertOpt] = useState({
        open: false,
        message: '',
        severity: 'success'
    })

    const [generateDataSources, setGenerateDataSources] = useState<GetReportResponseItem[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [downloadingId, setDownloadingId] = useState<string | null>(null)

    const tableContainerRef = useRef<any>(null)

    const getUsersByAccountIdQuery = useGetUsersByAccountIdQuery({
        variables: {
            getUsersByAccountIdRequest: {
                limit: 0,
                skip: 0,
                sortBy: [{field: 'fullName', method: 'asc'}],
            },
        },
    });

    const userOptions: {
        name: string,
        value: string
    }[] = (getUsersByAccountIdQuery.data?.getUsersByAccountId?.results || []).filter((user) => {
        return user.role?.roleName === UserDefinedRole.CARE_MANAGER
            || user.role?.roleName === UserDefinedRole.ADMIN
            || user.role?.roleName === UserDefinedRole.MSO_ADMIN
            || user.role?.roleName === UserDefinedRole.HUDSON_CLINICIAN
            || user.role?.roleName === UserDefinedRole.COORDINATOR
    }).map((user) => {
        return {
            name: `${user.firstName} ${user.lastName}`,
            value: user.id
        }
    });

    const [getReportUrlQuery, getReportUrlQueryResult] = useGetReportFileUrlLazyQuery();

    const [getReportByPageQuery, getReportByPageQueryResult] = useGetReportByPageLazyQuery()

    const getSelectedUsers = (selectedUsers: string[], options: any[], isAll: boolean): User[] => {
        const opts = isAll ? options : options.filter((user) => {
            return selectedUsers.includes(user.value)
        })

        return opts.map((opt) => {
            return {
                userName: opt.name,
                userId: opt.value
            }
        })
    }

    const getSelectedModules = (selectedModules: string[], options: any[], isAll: boolean) => {
        if (isAll) {
            return options.map((opt) => {
                return opt.value
            })
        } else {
            return selectedModules
        }
    }

    const [saveReportByFilterMutation] = useSaveReportByFilterMutation()

    useEffect(() => {
        getReportByPageQuery({
            variables: {
                input: {
                    limit: pageSize,
                    page: 1,
                }
            }
        })
    }, [])

    useEffect(() => {
        if (tab === 1) {
            getReportByPageQuery({
                variables: {
                    input: {
                        limit: pageSize,
                        page: page,
                    }
                }
            })
        }
    }, [tab, page, pageSize])

    useEffect(() => {
        if (tab === 0) {
            setPage(1)
            setPageSize(10)
        }
    }, [tab])

    useEffect(() => {
        if (!getReportUrlQueryResult.called || getReportUrlQueryResult.loading || !getReportUrlQueryResult.data) {
            return
        }
        setDownloadingId(null)
        if (getReportUrlQueryResult.data?.getReportFileUrl.success) {
            const url = getReportUrlQueryResult.data?.getReportFileUrl.data ?? ""
            if (!url.length) {
                onOpenAlert('Failed to get report file url', 'error')
                return
            }
            window.open(url, '_blank')
        } else {
            onOpenAlert('Failed to get report file url', 'error')
            return
        }
    }, [getReportUrlQueryResult])

    useEffect(() => {
        if (getReportByPageQueryResult.loading || !getReportByPageQueryResult.called) {
            return
        }
        setTotal(getReportByPageQueryResult.data?.getReportByPage?.data?.total ?? 0)
    }, [getReportByPageQueryResult])

    if (!loggedInUser) {
        return <div>loading...</div>
    }

    const $header = (
        <div className={styles.header}>
            <Typography className={styles.headerTitle}>Reports</Typography>
        </div>
    )

    const modulesOptions: {name: string, value: Modules | string}[] = [
        {name: "Patient review", value: "Patient review"},
        {name: "Call attempt", value: "Call attempt"},
        {name: "Call outcomes", value: "Call outcomes"},
        {name: "User Activity", value: "User Activity"},
    ]

    const onChangeFilter = (type: string, value: string) => {
        if (type === 'Modules') {
            setFilter({
                ...filter,
                modules: [...value]
            })
        } else if (type === 'Users') {
            setFilter({
                ...filter,
                userIds: [...value]
            })
        }
    }

    const onChangedDate = (type: string, value: Date) => {
        if (type === 'fromDate') {
            setFilter({
                ...filter,
                startDate: moment(value).format("YYYY-MM-DD")
            })
        } else if (type === 'toDate') {
            setFilter({
                ...filter,
                endDate: moment(value).format("YYYY-MM-DD")
            })
        }
    }

    const startDateMin = moment(filter.endDate).subtract(90, 'days').toDate()
    const startDateMax = moment(filter.startDate).add(90, 'days').isAfter(moment()) ? (new Date()) : moment(filter.startDate).add(90, 'days').toDate()
    const endDateMin = new Date(startDateMin)
    const endDateMax = new Date(startDateMax)

    const $filter = (
        <div className={styles.filterContainer}>
            <div className={styles.selectContainer}>
                <CustomSelectWithCheckBox
                    state={filter.userIds}
                    type={'Users'}
                    setState={onChangeFilter}
                    isMultiple={true}
                    inputTitle={'Users'}
                    placeholder={'Select'}
                    items={userOptions}
                    isObject={true}
                    width={205}
                    showSelectedCount={true}
                />
            </div>

            <div className={styles.daysSelect}>
                <div className={styles.daysSelectContainer}>
                    <DateRangeCustomInput
                        inputTitle={'Start and End Date'}
                        emptyLabel={'Select date'}
                        type={'fromDate'}
                        state={moment(filter.startDate, "YYYY-MM-DD").toDate()}
                        setState={onChangedDate}
                        maxDate={startDateMax}
                        minDate={startDateMin}
                        width={205}
                    />
                    <span className={styles.daysSelectContainerTitle}>To</span>
                    <DateRangeCustomInput
                        inputTitle={''}
                        emptyLabel={'Today'}
                        type={'toDate'}
                        state={moment(filter.endDate, "YYYY-MM-DD").toDate()}
                        setState={onChangedDate}
                        maxDate={endDateMax}
                        minDate={endDateMin}
                        width={205}
                    />
                </div>

                <p className={styles.daysSelectContainerDescription}>
                    Report date range should be less than 90 days
                </p>
            </div>

            <div className={styles.selectContainer}>
                <CustomSelectWithCheckBox
                    state={filter.modules}
                    type={'Modules'}
                    setState={onChangeFilter}
                    isMultiple={true}
                    inputTitle={'Modules'}
                    placeholder={'Select'}
                    items={modulesOptions}
                    isObject={true}
                    width={205}
                    showSelectedCount={true}
                />
            </div>
        </div>
    )

    const onGenerateReport = async () => {
        setGenerateDataSources([])
        if (loadingForGenerating) {
            return
        }
        // check filter fields
        if (filter.userIds.length === 0) {
            onOpenAlert('Please select users', 'error')
            return
        }

        if (filter.modules.length === 0) {
            onOpenAlert('Please select modules', 'error')
            return
        }

        // Report date range should be less than 90 days
        const startDate = moment(filter.startDate)
        const endDate = moment(filter.endDate)
        const diff = endDate.diff(startDate, 'days')
        if (diff > 90) {
            onOpenAlert('Report date range should be less than 90 days', 'error')
            return
        }

        const users = getSelectedUsers(filter.userIds, userOptions, filter.userIds.includes('All'))
        const modules = getSelectedModules(filter.modules, modulesOptions, filter.modules.includes('All'))

        const input = {
            filter: {
                endDate: filter.endDate,
                startDate: filter.startDate,
                modules: modules,
                users: users
            },
            requestById: loggedInUser.id,
            requestByName: `${loggedInUser.firstName} ${loggedInUser.lastName}`
        }

        setLoadingForGenerating(true)
        const mu = await saveReportByFilterMutation({
            variables: {
                input: input
            }
        })
        setLoadingForGenerating(false)

        if (mu.data?.saveReportByFilter.success) {
            onOpenAlert('Report generated successfully', 'success')
            const newGenerateItem = mu.data?.saveReportByFilter.data as GetReportResponseItem
            setGenerateDataSources([newGenerateItem])

            // refresh badge number
            getReportByPageQuery({
                variables: {
                    input: {
                        limit: pageSize,
                        page: 1,
                    }
                }
            })
        } else {
            onOpenAlert('Failed to generate report', 'error')
        }
    }

    const onClearFilter = () => {
        setFilter({
            userIds: [],
            startDate: moment().subtract(90, 'days').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            modules: []
        })
    }

    const $clearFilterButton = filter.userIds.length || filter.modules.length || filter.startDate !== moment().subtract(90, 'days').format("YYYY-MM-DD") || filter.endDate !== moment().format("YYYY-MM-DD") ? (
        <Button className={styles.clearText} onClick={onClearFilter} variant={'text'} disabled={false} color="default">
            {/*{loadingForGenerating && <CircularProgress size={24} color={'primary'} />}*/}
            <Close className={styles.clearIcon}/>
            {'Clear Filter'}
        </Button>
    ) : null

    const $loadingDot = loadingForGenerating || getReportByPageQueryResult.loading ? (<FlashingDot/>) : null
    const $generateButton = (
        <div className={styles.generateContainer}>
            <div className={styles.generateButtonContainer}>
                <Button onClick={onGenerateReport} disabled={loadingForGenerating} variant="contained" color="primary">
                    {'Generate Report'}
                </Button>

                {$clearFilterButton}
            </div>
            <div>
                {$loadingDot}
            </div>
        </div>
    )

    const onOpenAlert = (message: string, severity: Color) => {
        setAlertOpt({
            open: true,
            message: message,
            severity: severity
        })
    };

    const onCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpt({
            ...alertOpt,
            open: false
        })

        setTimeout(() => {
            setAlertOpt({
                open: false,
                message: "",
                severity: "error"
            })
        }, 500)
    };

    const $alert = (
        <Snackbar open={alertOpt.open} autoHideDuration={6000} onClose={onCloseAlert}>
            <Alert onClose={onCloseAlert} severity={alertOpt.severity as Color}>
                {alertOpt.message}
            </Alert>
        </Snackbar>
    )

    const onGetFileUrl = (item: GetReportResponseItem, format: Format) => {
        if (getReportUrlQueryResult.called && getReportUrlQueryResult.loading) {
            return
        }
        setDownloadingId(item.requestDate)
        const input = {
            endDate: item.filter.endDate,
            format: format,
            modules: item.filter.modules,
            requestDate: item.requestDate,
            startDate: item.filter.startDate,
            users: item.filter.users.map((u) => {
                return {
                    userId: u.userId,
                    userName: u.userName
                }
            })
        }
        getReportUrlQuery({
            variables: {
                input: input
            }
        })
    }

    const dataSource = tab === 0 ? [] : (getReportByPageQueryResult.data?.getReportByPage?.data?.data as GetReportResponseItem[] ?? [])

    const $badge = total ? (
        <div className={tab === 1 ? styles.chip : styles.chipDisabled}>
            <Typography className={tab === 1 ? styles.chipText : styles.chipTextDisabled}>{total}</Typography>
        </div>
    ) : null
    const $tab = (
        <div className={styles.tab}>
            <div onClick={() => {
                setTab(0)
            }} className={`${tab === 0 ? styles.tabItemSelected : styles.tabItem}`}>
                <Typography className={`${tab === 0 ? styles.tabItemTitleSelected : styles.tabItemTitle}`}>Generate
                    Report</Typography>
            </div>
            <div onClick={() => {
                setTab(1)
            }} className={`${tab === 1 ? styles.tabItemSelected : styles.tabItem}`}>
                <Typography
                    className={`${tab === 1 ? styles.tabItemTitleSelected : styles.tabItemTitle}`}>Report
                    Log</Typography>
                {$badge}
            </div>
        </div>
    )

    const $table = (
        <div ref={tableContainerRef} className={styles.tableContainer}>
            <ReportTable
                loading={tab === 1 ? getReportByPageQueryResult.loading : false}
                containerHeight={tableContainerRef.current?.clientHeight ?? 0}
                dataSource={tab === 1 ? dataSource : generateDataSources}
                page={page}
                rowsPerPage={pageSize}
                total={tab === 1 ? total : 1}
                downloadingId={downloadingId}
                onHandleChangePage={(p) => {
                    setPage(p + 1)
                }}
                onHandleChangeRowsPerPage={(ps) => {
                    setPage(1)
                    setPageSize(ps)
                }}
                onGetFileUrl={onGetFileUrl}
            />
        </div>
    )

    const $headerForHistory = (
        <div className={styles.historyHeader}>
            <Typography className={styles.historyHeaderTitle}>Generated Reports</Typography>
            <Typography className={styles.historyHeaderSubTitle}>Generated reports will be kept for 6
                months</Typography>
        </div>
    )

    const $headerForGenerate = (
        <>
            {$filter}
            {$generateButton}
        </>
    )

    const $subHeader = tab === 0 ? $headerForGenerate : $headerForHistory

    return (
        <div className={styles.container}>
            {$header}
            {$tab}
            {$subHeader}
            {$table}
            {$alert}
        </div>
    )
}
