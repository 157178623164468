import { makeStyles } from '@material-ui/core/styles';
import { u as useUtils } from '@material-ui/pickers/esm/useUtils-cfb96ac9';
import React, { createElement, useMemo } from 'react';
import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import clsx from 'clsx';
import {
  b as isYearAndMonthViews,
  g as getFormatByViews,
  i as isYearOnlyView,
} from '@material-ui/pickers/esm/Calendar-11ae61f6';
import {
  P as PickerToolbar,
  T as ToolbarButton,
} from '@material-ui/pickers/esm/makePickerWithState-5a79cb8a';
import { d as datePickerDefaultProps } from '@material-ui/pickers/esm/Picker-ccd9ba90';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(
    {
      toolbar: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      toolbarLandscape: {
        padding: 16,
      },
      dateLandscape: {
        marginRight: 16,
      },
    },
    {
      name: 'MuiPickersDatePickerRoot',
    }
);
export const CustomDatePickerToolbar = function DatePickerToolbar(_ref) {
  let date = _ref.date,
    views = _ref.views,
    setOpenView = _ref.setOpenView,
    isLandscape = _ref.isLandscape,
    openView = _ref.openView;
  let utils = useUtils();
  let classes = useStyles();
  let isYearOnly = useMemo(
    function () {
      return isYearOnlyView(views);
    },
    [views]
  );
  let isYearAndMonth = useMemo(
    function () {
      return isYearAndMonthViews(views);
    },
    [views]
  );
  return createElement(
    PickerToolbar,
    {
      isLandscape: isLandscape,
      className: clsx(
        !isYearOnly && classes.toolbar,
        isLandscape && classes.toolbarLandscape
      ),
    },
    createElement(ToolbarButton, {
      variant: isYearOnly ? 'h3' : 'subtitle1',
      onClick: function onClick() {
        return setOpenView('year');
      },
      selected: openView === 'year',
      label: utils.getYearText(date),
      endIcon: <KeyboardArrowDown style={{ color: '#FFFFFF' }} />,
    }),
    !isYearOnly &&
      !isYearAndMonth &&
      createElement(ToolbarButton, {
        variant: 'h4',
        selected: openView === 'date',
        onClick: function onClick() {
          return setOpenView('date');
        },
        align: isLandscape ? 'left' : 'center',
        label: utils.getDatePickerHeaderText(date),
        className: clsx(isLandscape && classes.dateLandscape),
      }),
    isYearAndMonth &&
      createElement(ToolbarButton, {
        variant: 'h4',
        onClick: function onClick() {
          return setOpenView('month');
        },
        selected: openView === 'month',
        label: utils.getMonthText(date),
      })
  );
};

function ownKeys(object, enumerableOnly) {
  let keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    let symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }
  return keys;
}

function _objectSpread(target) {
  for (let i = 1; i < arguments.length; i++) {
    let source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(
          target,
          key,
          Object.getOwnPropertyDescriptor(source, key)
        );
      });
    }
  }
  return target;
}

const defaultProps = _objectSpread({}, datePickerDefaultProps, {
  openTo: 'date',
  views: ['year', 'date'],
});

function useOptions(props) {
  let utils = useUtils();
  return {
    getDefaultFormat: function getDefaultFormat() {
      return getFormatByViews(props.views, utils);
    },
  };
}
