import React, {useState} from "react";
import {CloseIcon} from "../../../../../assets/Icons/ExportPatients";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {CustomSelectWithCheckBox} from "../../../../../components/CustomSelectWithCheckBox";
import {FiltersIcon} from "../../../../../assets/Icons/Eligibility/FiltersIcon";
import {DynamicBadge} from "../../../../../components/DynamicBadge";
import {CustomSelectWithToolTips} from "./CustomSelectWithToolTips";

interface IProps {
  displayCount: number,
  isLoading: boolean,
  reasonsOption: Array<any>
  onChangeFilter: (reason: Array<any>) => void
}

export function ReasonFilterView(props: IProps) {
  const { onChangeFilter, reasonsOption, isLoading, displayCount} = props
  const classes = useStyles()
  const [reason, setReason] = useState<Array<any>>([])

  const onChangeSelect = (name: any, value: any) => {
    setReason(value)
  };

  const onApply = () => {
    onChangeFilter(reason)
  }

  const onClear = () => {
    setReason([])
    onChangeFilter([])
  }

  return (
    <div className={classes.container}>
        <div className={classes.filterContainer}>
          <div>
            <div className={classes.selectContainer}>
              <CustomSelectWithToolTips
                state={reason}
                type={'reason'}
                setState={onChangeSelect}
                isMultiple={true}
                inputTitle={'Unverified Reason'}
                placeholder={'Select'}
                items={reasonsOption}
                showSelectedCount={true}
                isObject={true}
              />
            </div>
            <div className={classes.selectButtonContainer}>
              <Button
                disabled={!reason.length}
                className={classes.applyFilterBtn}
                classes={{
                  disabled: classes.disabledApplyFilterBtn,
                }}
                variant="contained"
                color="primary"
                onClick={onApply}
              >
                <span>Apply Filters</span>
              </Button>

              <Button
                variant={'text'}
                startIcon={<CloseIcon className={classes.closeIcon} />}
                className={classes.clearFilterBtn}
                onClick={onClear}
              >
                <span>Clear Filters</span>
              </Button>
            </div>
          </div>

          <DynamicBadge
            loading={isLoading}
            displayedCount={displayCount}
            prefixText=""
            suffixText="Patient(s) Filtered"
          />
        </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  filterContainer: {
    padding: '12px 30px 12px 30px',
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  selectButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 18,
    gap: 12,
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 4,
    paddingBottom: 4,
    borderBottom: '1px solid #d1d1d1',
    paddingRight: 0,
    backgroundColor: '#FFF',
  },
  filterButton: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    color: theme.palette.secondary.dark,
    marginRight: 24,
    height: 32,
  },
  filterButtonText: {
    fontSize: 14,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  selectRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    rowGap: 12,
  },
  ButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
    width: '100%',
  },
  applyFilterBtn: {
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: 14,
    height: 32,
  },
  disabledApplyFilterBtn: {
    color: 'rgba(255,255,255,0.5)!important',
    background: `${theme.palette.primary.main}!important`,
  },
  applyFilterBtnAndClearFilterBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 24,
    flexGrow: 1,
  },
  divider: {
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['500'],
    lineHeight: '16px',
    fontSize: 12,
    paddingTop: 30,
  },
  clearFilterBtn: {
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['600'],
    fontSize: 14,
    height: 32,
  },
  closeIcon: {
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  selectContainer: {
    '& > div': {
      marginTop: '0',
    },
    '& div[class*="MuiFormControl-root"] > div:first-child': {
      marginBottom: '0',
    },
  },
}));
