import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Grid, Paper, Typography } from '@material-ui/core';
import { AddIcon } from '../../../assets/Icons/PanelsList';
import { TemplateListTableView } from '../components/TemplateListTable/TemplateListTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { createHeaderCell } from '../../../sharePipe/createHeaderCellPipe';
import { CreateTemplateDialog } from '../components/CreateTemplateDialog/CreateTemplateDialog';
import { EditTemplateDialog } from '../components/EditTemplateDialog/EditTemplateDialog';
import { GlobalContext } from '../../../components/GlobalContext';
import {
  useGetTemplatesQuery,
  useUpsertTemplateMutation,
} from './index.generated';
import { LoadingSpinner } from '../../../components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,

    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  cardHeader: {
    height: 63,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: '#F8F8F8',
  },
  cardSubHeader: {
    height: 40,
    paddingLeft: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: '#F8F8F8',
    // paddingBottom: 8,

    '& > span:first-child': {
      padding: '10px 10px 10px 14px',
    },
  },
  cardTitle: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(0),
  },
  cardMidSection: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    padding: theme.spacing(3),
  },
  cardFooter: {
    height: 72,
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  createButton: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '148px',
    height: '32px',
    fontSize: 14,
    textAlign: 'center',
    padding: '4px 0',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 6,
    width: 24,
    height: 24,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.08),0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08);',
    backgroundColor: '#fff',
    'input:hover ~ &': {
      transform: 'scale(1.05)',
    },
    'input:checked ~ &': {
      boxShadow: 'none',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.grey['200'],
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23ABABAB'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  checkedAllIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
  },
  addIconClasses: {
    fontSize: 16,
  },
}));

export const SMSTemplateView = () => {
  const classes = useStyles();
  const location = useLocation();
  const [sortBy, setSortBy] = React.useState({
    field: 'updatedAt',
    method: 'desc' as const,
  });
  const [onCreateTemplate, setOnCreateTemplate] = React.useState(false);
  const [onEditTemplate, setOnEditTemplate] = React.useState<any>(null);
  const [showDeactivated, setShowDeactivated] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [cacheFilter, setCacheFilter] = React.useState<any>(null);

  let navigate = useNavigate();
  const { search } = location;
  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  // Change limit size and then reload list from index 0
  function onSetLimit(limit: number) {
    updateQps({ limit: limit, skip: 0 });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  React.useEffect(() => {
    onSetSkip(0);
  }, [showDeactivated]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDeactivated(event.target.checked);
  };

  const changeStatus = async (templateId: any, templateStatus: string) => {
    const newStatus = templateStatus == 'active' ? 'inactive' : 'active';
    const newTemplate = {
      templateStatus: newStatus,
      id: templateId,
    };
    try {
      setLoading(true);
      const data = await upsertTemplate({
        variables: { input: newTemplate },
      });
      if (data?.data) {
        setOnCreateTemplate(false);
        const msg =
          'Template has been ' +
          (newStatus == 'active' ? 'activated' : 'deactivated');
        setToastMessage({
          snackbarMsg: msg,
          isOpen: true,
          severity: 'success' as const,
        });
        setIsOpenToast(true);
      } else if (data?.errors) {
        //
      }
    } catch (e: any) {
      //
    } finally {
      setLoading(false);
    }
  };

  const mapInputToContent = (language: string, text: string) => {
    return {
      content: btoa(encodeURIComponent(text)),
      language: language,
    };
  };
  const [upsertTemplate] = useUpsertTemplateMutation();

  const EditTemplate = async (
    templateName: any,
    selectedLanguages: any,
    customEngMessage: any,
    customChnSmpMessage: any,
    customChnTradMessage: any,
    id: any
  ) => {
    const newTemplate = {
      id: id,
      name: templateName.trim(),
      templateStatus: 'active',
      type: 'SMS',
      contents: selectedLanguages.map((item: any, idx: any) => {
        switch (item) {
          case 'English':
            return mapInputToContent('en', customEngMessage);
          case 'Chinese (S)':
            return mapInputToContent('cns', customChnSmpMessage);
          case 'Chinese (T)':
            return mapInputToContent('cnt', customChnTradMessage);
        }
      }),
    };
    try {
      setLoading(true);
      setCacheFilter(null);
      const data = await upsertTemplate({
        variables: { input: newTemplate },
      });

      if (data?.data) {
        setOnEditTemplate(null);
        setToastMessage({
          snackbarMsg: `Template has been updated`,
          isOpen: true,
          severity: 'success' as const,
        });
        setIsOpenToast(true);
      } else if (data.errors) {
        setCacheFilter(newTemplate);
        setErrorMessage(
          'This template name is existed, Please change to another name.'
        );
      }
    } catch (e: any) {
      setErrorMessage(
        'This template name is existed, Please change to another name.'
      );
    } finally {
      setLoading(false);
    }
  };

  const CreateTemplate = async (
    templateName: any,
    selectedLanguages: any,
    customEngMessage: any,
    customChnSmpMessage: any,
    customChnTradMessage: any
  ) => {
    const newTemplate = {
      name: templateName.trim(),
      templateStatus: 'active',
      type: 'SMS',
      contents: selectedLanguages.map((item: any, idx: any) => {
        switch (item) {
          case 'English':
            return mapInputToContent('en', customEngMessage);
          case 'Chinese (S)':
            return mapInputToContent('cns', customChnSmpMessage);
          case 'Chinese (T)':
            return mapInputToContent('cnt', customChnTradMessage);
        }
      }),
    };
    try {
      setLoading(true);
      const data = await upsertTemplate({
        variables: { input: newTemplate },
      });

      if (data?.data) {
        setOnCreateTemplate(false);
        setToastMessage({
          snackbarMsg: `Template has been created`,
          isOpen: true,
          severity: 'success' as const,
        });
        setIsOpenToast(true);
      } else if (data.errors) {
        setCacheFilter(newTemplate);
        setErrorMessage(
          'This template name is existed, Please change to another name.'
        );
      }
    } catch (e: any) {
      setErrorMessage(
        'This template name is existed, Please change to another name.'
      );
    } finally {
      setLoading(false);
    }
  };

  const getTemplatesQuery = useGetTemplatesQuery({
    variables: {
      input: {
        status: showDeactivated ? 'all' : 'active',
        limit: limit ?? 10,
        skip: skip ?? 0,
        sortBy: [{ ...sortBy }],
        type: 'SMS',
      },
    },
  });

  React.useEffect(() => {
    setData(getTemplatesQuery?.data?.getTemplates?.result ?? []);
  }, [getTemplatesQuery]);

  React.useEffect(() => {
    if (!loading) {
      getTemplatesQuery.refetch();
    }
  }, [loading]);

  React.useEffect(() => {
    if (onEditTemplate == null) {
      setCacheFilter(null);
      setErrorMessage('');
    }
  }, [onEditTemplate]);

  React.useEffect(() => {
    if (!onCreateTemplate) {
      setErrorMessage('');
      setCacheFilter(null);
    }
  }, [onCreateTemplate]);

  if (!getTemplatesQuery.called || getTemplatesQuery.loading || loading) {
    return <LoadingSpinner />;
  }

  return (
    <Paper className={classes.card} elevation={1}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.cardHeader}
          style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
        >
          <Typography className={classes.cardTitle}>
            Active SMS Templates
          </Typography>
          <div style={{ marginRight: 18 }}>
            <Button
              onClick={() => {
                setOnCreateTemplate(true);
              }}
              className={classes.createButton}
              id={'createTemplateButton'}
            >
              <AddIcon className={classes.addIconClasses} />
              <span>Create Template</span>
            </Button>
          </div>
        </Grid>
        {onCreateTemplate && (
          <CreateTemplateDialog
            onClose={() => setOnCreateTemplate(false)}
            onSubmit={CreateTemplate}
            errorMessage={errorMessage}
            cacheFilter={cacheFilter}
          />
        )}
        {onEditTemplate !== null && (
          <EditTemplateDialog
            onClose={() => setOnEditTemplate(null)}
            onSubmit={EditTemplate}
            selectedFilter={onEditTemplate}
            errorMessage={errorMessage}
            cacheFilter={cacheFilter}
          />
        )}
        <Grid item xs={12} className={classes.cardSubHeader}>
          <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={
              <span className={classes.icon + ' ' + classes.checkedAllIcon} />
            }
            checked={showDeactivated}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            onChange={handleChange}
          />
          <span className={classes.subTitle}>Deactivated Templates</span>
        </Grid>
        <TemplateListTableView
          list={data}
          skip={skip || 0}
          limit={limit || 10}
          setSkip={onSetSkip}
          setLimit={onSetLimit}
          count={getTemplatesQuery?.data?.getTemplates?.count ?? 0}
          headCells={[
            {
              id: 'name',
              align: 'left' as const,
              disablePadding: false,
              label: 'Templates Name',
            },
            {
              id: 'updatedBy',
              align: 'left' as const,
              disablePadding: false,
              label: 'Updated By',
            },
            {
              id: 'updatedAt',
              align: 'left' as const,
              disablePadding: false,
              label: 'Last Update',
            },
            {
              id: 'templateStatus',
              align: 'left' as const,
              disablePadding: false,
              label: 'Status',
            },
          ].map((item) =>
            createHeaderCell(
              item.id,
              item.label,
              item.disablePadding,
              item.align
            )
          )}
          unSortOrderBy={['updatedBy']}
          sortBy={{ ...sortBy }}
          setSortBy={setSortBy}
          setOnEditTemplate={setOnEditTemplate}
          setOnCreateTemplate={setOnCreateTemplate}
          changeStatus={changeStatus}
        />
      </Grid>
    </Paper>
  );
};
