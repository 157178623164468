import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useAllProvidersStyles } from './Styles';
import { useNavigate } from 'react-router';
import { ProviderListTable } from './components/ProviderListTable';
import { BreadCrumbHeader } from '../../../components/BreadCrumbHeader';
import { MainRegion } from '../../../components/MainRegion';
import { SearchAndFilterBar } from '../../../components/SearchAndFilterBar';
import { ProviderPerformanceFilter } from './components/ProviderPerformanceFilter';
import { theme } from '../../../themes/theme';
import { RoleType } from '../../../enum/Users';
import {
  GetProviderPerformanceListsQuery,
  useGetProviderPerformanceListsQuery,
} from './components/ProviderListTable/index.generated';
import { SubMainRegion } from '../../../components/SubMainRegion';

export const AllProvidersView = () => {
  const classes = useAllProvidersStyles();
  const navigate = useNavigate();

  const providersQuery = useGetProviderPerformanceListsQuery({
    variables: {
      input: {
        limit: 10,
        skip: 0,
        performance: [],
        sortBy: [{ field: 'coveringPerformance', method: 'desc' }],
      },
    },
  });

  const getTotalProviderCount = (
    queryResult: GetProviderPerformanceListsQuery | undefined
  ) => {
    return queryResult?.pcps.count ?? 0;
  };

  const badge = React.useMemo(
    () => getTotalProviderCount(providersQuery.data),
    [providersQuery.data]
  );

  const [openFilter, setOpenFilter] = React.useState(false);
  const filterHandler = () => {
    setOpenFilter(!openFilter);
  };
  const [searchInput, setSearchInput] = React.useState('');

  const [
    selectedPerformanceOptions,
    setSelectedPerformanceOptions,
  ] = React.useState<string[]>([]);

  const [applyFilterBtnIsClicked, setApplyFilterBtnIsClicked] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(true);
  const [displayedCount, setDisplayedCount] = React.useState(0);

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={'All Providers'}
          rootLink={`/dashboard`}
          rootName={'Dashboard'}
          badgeCount={badge}
          isTitle={true}
          isButton={true}
          renderButton={
            <Button
              onClick={() => navigate('create')}
              disabled={true}
              // className={classes.cancelButton}
            >
              Add Provider
            </Button>
          }
        />
      }
      overFlowHidden={true}
    >
      <Grid container className={classes.root}>
        <SubMainRegion
          header={
            <Grid item xs={12}>
              <div
                className={classes.lightBackground}
                style={{
                  borderBottom: !openFilter
                    ? 'none'
                    : `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                <SearchAndFilterBar
                  placeHolder={'Search Provider Name'}
                  searchFunction={setSearchInput}
                  filterHandler={filterHandler}
                  hideSearch={true}
                />

                <div
                  className={
                    openFilter ? classes.openedDrawer : classes.closedDrawer
                  }
                >
                  <ProviderPerformanceFilter
                    selectedPerformanceOptions={selectedPerformanceOptions}
                    setSelectedPerformanceOptions={
                      setSelectedPerformanceOptions
                    }
                    applyFilterBtnIsClicked={applyFilterBtnIsClicked}
                    setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
                    loading={loading}
                    displayedCount={displayedCount}
                  />
                </div>
              </div>
            </Grid>
          }
          headerChange={openFilter}
        >
          <Grid item xs={12}>
            <ProviderListTable
              setSelectedPerformanceOptions={setSelectedPerformanceOptions}
              selectedPerformanceOptions={selectedPerformanceOptions}
              tableType={RoleType.MSO_ADMIN}
              setLoading={setLoading}
              setDisplayedCount={setDisplayedCount}
            />
          </Grid>
        </SubMainRegion>
      </Grid>
    </MainRegion>
  );
};
