import React from 'react';
import {
  useGetTaskStatusQuery,
  useMovePatientBetweenCareTeamUsersMutation,
} from '../scenes/Panels/ViewPanels/components/PanelPatientsListActionsFooter/index.generated';
import { GlobalContext } from '../components/GlobalContext';
import moment from 'moment/moment';
import mixpanel from 'mixpanel-browser';
import { MixPanelEvents } from '../mixpanel/events';
import { useReassignPatientsMutation } from '../scenes/Panels/ViewPanels/components/ChangeCoordinatorDialog/index.generated';

export const useMovePatients = (
  panelId: any,
  allSelected: any,
  excluded: any,
  selectedItems: any,
  // hardFresh: any,
  setEnableChangeCoordinatorDialog: any,
  setLoading: any,
  careGapFilters: any,
  notRuffle?: boolean,
  fromCareTeamUsers?: any
) => {
  // update panel with new coordinators
  const [
    movePatientBetweenCareTeamUsersMutation,
  ] = useMovePatientBetweenCareTeamUsersMutation();
  const [reassignPatientsMutation, { error }] = useReassignPatientsMutation();
  const [polling, setPolling] = React.useState<any>(undefined);
  const [taskId, setTaskId] = React.useState('');
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const changeCoordinator = async (fromUsers: any, toUsers: any) => {
    if (notRuffle) {
      const input = {
        panelId: panelId,
        fromCareTeamUsers: fromUsers.map((item: any) => {
          return {
            userId: item.user.id,
            count: item.count,
          };
        }),
        toCareTeamUsers: toUsers.map((item: any) => {
          return {
            count: item.assignedPatients,
            userId: item.id,
          };
        }),
      };

      try {
        const { data, errors } = await reassignPatientsMutation({
          variables: {
            input: {
              ...input,
            },
          },
        });
        if (
          data &&
          data.reassignPatients.entityId &&
          data.reassignPatients.taskId
        ) {
          setTaskId(data.reassignPatients.taskId);
          setToastMessage({
            snackbarMsg: `Care Coordinator assignment updated.`,
            severity: 'success',
            isOpen: true,
          });
          setIsOpenToast(true);
        } else if (errors) {
          // error response
          setToastMessage({
            snackbarMsg: `Fail to assign patients. Please try again.`,
            severity: 'error',
            isOpen: true,
          });
          setIsOpenToast(true);
          setEnableChangeCoordinatorDialog(false);
          // hardFresh();
        }
      } catch (error) {
        setToastMessage({
          snackbarMsg: `Fail to assign patients. Please try again.`,
          severity: 'error',
          isOpen: true,
        });
        setIsOpenToast(true);
        setEnableChangeCoordinatorDialog(false);
        // hardFresh();
      }
    }

    // else
    else {
      const lastCounterFromDayString = careGapFilters.ldosFrom
        ? moment.utc(careGapFilters.ldosFrom).format('YYYY-MM-DD')
        : '';
      const lastCounterToDayString = careGapFilters.ldosTo
        ? moment.utc(careGapFilters.ldosTo).format('YYYY-MM-DD')
        : '';
      // console.log(careGapFilters.userIds);
      const filter = {
        andName: careGapFilters.andName,
        andDob: careGapFilters.andDob,
        gender: careGapFilters.gender?.includes('All')
          ? []
          : careGapFilters.gender,
        age: careGapFilters.age?.includes('All') ? [] : careGapFilters.age,
        ldosFrom: lastCounterFromDayString,
        ldosTo: lastCounterToDayString,
        npis: careGapFilters.npis.map((pcp: any) => pcp.id) ?? [],
        openGaps: careGapFilters.openGaps?.includes('All')
          ? []
          : careGapFilters.openGaps,
        hasEngagement: careGapFilters.hasEngagement,
        hasFutureAppointment: careGapFilters.hasFutureAppointment,
        dueDateFrom: careGapFilters.dueDateFrom,
        dueDateTo: careGapFilters.dueDateTo,
        coordinationStatus: careGapFilters.coordinationStatus,
        divisionId: careGapFilters.divisionId,
        clinicId: careGapFilters.clinicId,
        languages: careGapFilters.languages,
        payerIds: careGapFilters.payerIds,
        lineOfBusiness: careGapFilters.lineOfBusiness,
        eligibilityStatuses: careGapFilters.eligibilityStatuses,
        careGapStatus: careGapFilters.careGapStatus,
        callAttemptCount: careGapFilters.callAttemptCount?.includes('All')
          ? []
          : careGapFilters.callAttemptCount,
        newPatientOnly: careGapFilters.newPatientOnly,
      };
      const input = {
        panelId: panelId,
        filter: filter,
        excludePatientIds: allSelected
          ? excluded.map((patient: any) => patient.id)
          : [],
        selectedPatientIds: allSelected
          ? []
          : selectedItems.map((patient: any) => patient.id),
        fromUserId: fromUsers.map((item: any) => item.user.id),
        toUserId: toUsers.map((item: any) => {
          return {
            count: item.assignedPatients,
            userId: item.id,
          };
        }),
      };

      try {
        const { data, errors } = await movePatientBetweenCareTeamUsersMutation({
          variables: {
            input: {
              ...input,
            },
          },
        });
        if (
          data &&
          data.movePatientBetweenCareTeamUsers.entityId &&
          data.movePatientBetweenCareTeamUsers.taskId
        ) {
          setTaskId(data.movePatientBetweenCareTeamUsers.taskId);
          setToastMessage({
            snackbarMsg: `Care Coordinator assignment updated.`,
            severity: 'success',
            isOpen: true,
          });
          setIsOpenToast(true);
        } else if (errors) {
          // error response
          setToastMessage({
            snackbarMsg: `Fail to assign patients. Please try again.`,
            severity: 'error',
            isOpen: true,
          });
          setIsOpenToast(true);
          setEnableChangeCoordinatorDialog(false);
          // hardFresh();
        }
      } catch (error) {
        setToastMessage({
          snackbarMsg: `Fail to assign patients. Please try again.`,
          severity: 'error',
          isOpen: true,
        });
        setIsOpenToast(true);
        setEnableChangeCoordinatorDialog(false);
        // hardFresh();
      }
    }
  };

  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId, // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (taskId) {
      setLoading(true);
      setPolling(true);
      queryTask.startPolling(1000);
      setTimeout(() => {
        if (queryTask?.data?.getTaskStatus.status == true) {
          setLoading(false);
          setPolling(false);
          queryTask.stopPolling();
        }
      }, 1500);
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  return { polling, changeCoordinator, setPolling };
};
