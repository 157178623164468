import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const LinkIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M7.09090909,8 C4.83190909,8 3,9.83190909 3,12.0909091 C3,14.3499091 4.83190909,16.1818182 7.09090909,16.1818182 L10.3636364,16.1818182 L10.3636364,14.5454545 L7.09090909,14.5454545 C5.73518182,14.5454545 4.63636364,13.4466364 4.63636364,12.0909091 C4.63636364,10.7351818 5.73518182,9.63636364 7.09090909,9.63636364 L10.3636364,9.63636364 L10.3636364,8 L7.09090909,8 Z M13.6363636,8 L13.6363636,9.63636364 L16.9090909,9.63636364 C18.2648182,9.63636364 19.3636364,10.7351818 19.3636364,12.0909091 C19.3636364,13.4466364 18.2648182,14.5454545 16.9090909,14.5454545 L13.6363636,14.5454545 L13.6363636,16.1818182 L16.9090909,16.1818182 C19.1680909,16.1818182 21,14.3499091 21,12.0909091 C21,9.83190909 19.1680909,8 16.9090909,8 L13.6363636,8 Z M7.90909091,11.2727273 L7.90909091,12.9090909 L16.0909091,12.9090909 L16.0909091,11.2727273 L7.90909091,11.2727273 Z"
    />
  );
};
