import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const CopyIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M4.5 21.975C4.1 21.975 3.75 21.825 3.45 21.525C3.15 21.225 3 20.875 3 20.475V5.40001H4.5V20.475H16.35V21.975H4.5ZM7.5 18.975C7.1 18.975 6.75 18.825 6.45 18.525C6.15 18.225 6 17.875 6 17.475V3.47501C6 3.07501 6.15 2.72501 6.45 2.42501C6.75 2.12501 7.1 1.97501 7.5 1.97501H18.5C18.9 1.97501 19.25 2.12501 19.55 2.42501C19.85 2.72501 20 3.07501 20 3.47501V17.475C20 17.875 19.85 18.225 19.55 18.525C19.25 18.825 18.9 18.975 18.5 18.975H7.5ZM7.5 17.475H18.5V3.47501H7.5V17.475Z"
    />
  );
};
