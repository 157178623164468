import React from 'react';
import { makeStyles } from '@material-ui/core';
import { UsersListTable } from '../../components/UsersListTable';
import { UsersListActionsFooter } from '../../components/UsersListActionsFooter';
import { ToggleAccountStatusDialog } from '../../components/ToggleAccountStatus';
import { CheckPrivilegesForDisplay } from '../../../../../components/CheckPrivilegesForDisplay';
import { AccountOptionType, User } from '../../../../../enum/Users';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { useGetTaskStatusQuery } from '../../components/ToggleAccountStatus/deactivateUsers.generated';

export type ToggleStatusType = {
  operationCode: AccountOptionType.ACTIVATE | AccountOptionType.DEACTIVATE;
  result: boolean | undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  subClass: {
    flexGrow: 1,
  },
}));

interface AllUsersViewProps {
  skip: number;
  limit: number;
  count?: number;
  userRows: User[];
  setSkip: (input: number) => any;
  setLimit: (input: number) => any;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  actionsFooter: any[];
  setActionsFooter: any;
}

export const AllUsersView = (props: AllUsersViewProps) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [optionCode, setOptionCode] = React.useState<AccountOptionType>();
  const [toBeUpdatedUsers, setToBeUpdatedUsers] = React.useState<User[]>([]);
  const [userRows, setUserRows] = React.useState<User[]>(props.userRows);
  const [updatedUsers, setUpdatedUsers] = React.useState<User[]>([]);
  const [selected, setSelected] = React.useState<User[]>([]);

  React.useEffect(() => {
    setUserRows(props.userRows);
  }, [props.userRows]);

  React.useEffect(() => {
    const newUserRows = userRows.map((user) => {
      const result = updatedUsers.find(
        (updatedUser) => updatedUser.userId === user.userId
      );
      if (result !== undefined) {
        return { ...result };
      } else {
        return user;
      }
    });

    setUserRows(newUserRows);
  }, [updatedUsers]);

  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [toggleStatus, setToggleStatus] = React.useState<
    undefined | ToggleStatusType
  >(undefined);
  const [querying, setQuerying] = React.useState<undefined | boolean>(
    undefined
  );
  const [taskId, setTaskId] = React.useState('');
  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId,
  });

  React.useEffect(() => {
    if (taskId) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        queryTask.stopPolling();
        setTimeout(() => {
          setQuerying(false);
        }, 1000);
      } else {
        setQuerying(true);
        queryTask.startPolling(1000);
      }
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  React.useEffect(() => {
    const toastMessageContent = (
      resultStatus: boolean,
      hintKeywords: string[],
      users: User[]
    ) => {
      if (users.length === 1) {
        // one user to show details
        setToastMessage({
          isOpen: true,
          severity: resultStatus === true ? 'success' : 'error',
          snackbarMsg:
            resultStatus === true
              ? `${users[0].roleName} ${
                  users[0].firstName + ' ' + users[0].lastName
                } has been ${hintKeywords[0]}!`
              : `Unable to ${hintKeywords[1]} ${users[0].roleName} ${
                  users[0].firstName + ' ' + users[0].lastName
                }, please try again`,
        });
      } else {
        // more than 2 users just showing count
        setToastMessage({
          isOpen: true,
          severity: resultStatus === true ? 'success' : 'error',
          snackbarMsg:
            resultStatus === true
              ? `${users.length} users have been ${hintKeywords[0]}!`
              : `Unable to ${hintKeywords[1]} selected ${users.length} users, please try again`,
        });
      }
      const updatedUsersInfo = users.map((user) => {
        return { ...user, status: `${hintKeywords[2]}` };
      });
      setUpdatedUsers([...updatedUsersInfo]);
      setIsOpenToast(true);
      setSelected([]);
    };

    if (toggleStatus) {
      if (toggleStatus?.operationCode === AccountOptionType.DEACTIVATE) {
        const hintKeywords = ['Deactivated', 'deactivating', 'Deactivated'];
        if (querying === false || toggleStatus !== undefined) {
          toastMessageContent(
            toggleStatus?.result!,
            hintKeywords,
            toBeUpdatedUsers
          );
        }
      }
      if (toggleStatus?.operationCode === AccountOptionType.ACTIVATE) {
        const hintKeywords = ['Activated', 'activating', 'Active'];
        toastMessageContent(
          toggleStatus?.result!,
          hintKeywords,
          toBeUpdatedUsers
        );
      }
    }
  }, [querying, toggleStatus]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.subClass}>
        <UsersListTable
          refetchDataFunc={{ ...props.refetchDataFunc }}
          sortBy={{ ...props.sortBy }}
          setSortBy={props.setSortBy}
          setSelected={setSelected}
          selected={selected}
          setActionsFooter={props.setActionsFooter}
          updatedUsers={updatedUsers}
          setUpdatedUsers={setUpdatedUsers}
          setUserRows={setUserRows}
          setToBeUpdatedUsers={setToBeUpdatedUsers}
          userRows={userRows}
          limit={props.limit ?? 10}
          skip={props.skip ?? 0}
          setLimit={props.setLimit}
          setSkip={props.setSkip}
          count={props.count ?? 0}
          setIsOpenDialog={setIsOpenDialog}
          setOptionCode={setOptionCode}
        />
        {props.actionsFooter.length !== 0 && (
          <CheckPrivilegesForDisplay
            neededPrivileges={'users.update'}
            element={
              <UsersListActionsFooter
                setSelected={setSelected}
                itemName={'Users'}
                selectedItems={props.actionsFooter}
                setToBeUpdatedUsers={setToBeUpdatedUsers}
                setUpdatedUsers={setUpdatedUsers}
                setIsOpenDialog={setIsOpenDialog}
                setOptionCode={setOptionCode}
              />
            }
          />
        )}
        {isOpenDialog && (
          <ToggleAccountStatusDialog
            optionCode={optionCode!}
            setSelected={setSelected}
            isOpenDialog={isOpenDialog}
            setIsOpenDialog={setIsOpenDialog}
            setUpdatedUsers={setUpdatedUsers}
            toBeUpdatedUsers={toBeUpdatedUsers}
            setTaskId={setTaskId}
            querying={querying}
            setToggleStatus={setToggleStatus}
          />
        )}
      </div>
    </div>
  );
};
