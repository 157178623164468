import React from 'react';
import { UserIdsInBatch } from '../../../../../../../app.types.generated';
import { GlobalContext } from '../../../../../../../components/GlobalContext';
import { User } from '../../../../../../../enum/Users';
import { useResendTemporaryPasswordMutation } from './index.generated';
import { MenuItemResendPasswordView } from './View';

interface Props {
  currentUser: User;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setToBeUpdatedUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

export const MenuItemResendPasswordContainer = (props: Props) => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [resendTemporaryPasswordMutation] =
    useResendTemporaryPasswordMutation();

  async function resendTemporaryPassword(userIds: UserIdsInBatch) {
    try {
      const { data } = await resendTemporaryPasswordMutation({
        variables: {
          input: userIds,
        },
      });

      setToastMessage({
        isOpen: true,
        severity:
          data?.resendTemporaryPassword.result === true ? 'success' : 'error',
        snackbarMsg:
          data?.resendTemporaryPassword.result === true
            ? `Temporary Password has been sent to ${props.currentUser.email}`
            : `Temporary Password fails to send to ${props.currentUser.email}, please try again.`,
      });

      setIsOpenToast(true);
    } catch (err: any) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: `Exceeded daily email limit. Temporary Password fails to send to ${props.currentUser.email}, please try again. `,
      });

      setIsOpenToast(true);
    }
  }

  return (
    <MenuItemResendPasswordView
      resendTemporaryPassword={resendTemporaryPassword}
      currentUser={props.currentUser}
      setAnchorEl={props.setAnchorEl}
      setToBeUpdatedUsers={props.setToBeUpdatedUsers}
    />
  );
};
