import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { StyledCheckbox } from '../../../../../../../components/StyledCheckbox';

interface PerformanceSelectWitCheckBoxProps {
  state: string[];
  type: string;
  setState: any;
  isMultiple: boolean;
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  showValue?: boolean;
}

const useStyles = makeStyles((theme) => ({
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  selectMenuContentValue: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    width: 50,
    paddingRight: 8,
  },
  selectMenuContentName: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: 269,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  placeholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',

    // minHeight: 154,
    width: '100%',
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 20,
  },
  min40Height: {
    minHeight: 40,
  },
  minWidthAnd40height: {
    minWidth: 416,
    minHeight: 40,
  },
}));

export const PerformanceSelectWitCheckBox = (
  props: PerformanceSelectWitCheckBoxProps
) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.state.length == 0) {
      const newValue = ['All'];
      props.setState(props.type, newValue);
    }
  }, [props.state]);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, ProviderType and CareTeam
    let newValue = event.target.value;
    if (event.target.value.includes('All') && !props.state.includes('All')) {
      newValue = ['All'];
    } else if (props.state.includes('All')) {
      newValue = newValue.filter((value: any) => value != 'All');
    }
    props.setState(props.type, newValue);
  };

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.width || 203 }}
    >
      <FormControl className={classes.formControl}>
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={classes.inputTitle}
        >
          {props.inputTitle}
        </Typography>

        <Select
          multiple={props.isMultiple}
          variant="outlined"
          displayEmpty
          value={props.state}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          renderValue={(selected) => {
            if (isOpen) {
              return <div className={classes.placeholderText}>Select</div>;
            } else if (
              (selected as string[]).length === 0 ||
              (selected as string[]).includes('All')
            ) {
              return (
                <div className={classes.placeholderText}>
                  {props.placeholder}
                </div>
              );
            }

            return (
              <div className={classes.placeholderTextSelected}>
                {(selected as string[]).join(', ')}
              </div>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            elevation: 2,
            getContentAnchorEl: null,
            style: {
              maxHeight: 68 * 5 + 8,
              width: props.showValue ? 416 : 250,
              // maxWidth: '100%',
            },
            PaperProps: {
              style: {
                // reduce space between input and menu
                minWidth: props.width ? props.width - 10 : 192,
                marginTop: -9,
                paddingLeft: 8,
                paddingRight: 7,
                maxWidth: '100%',
              },
            },
          }}
          className={classes.select}
        >
          <MenuItem
            value="All"
            classes={{
              selected: classes.selected,
            }}
            className={
              props.showValue
                ? classes.minWidthAnd40height
                : classes.min40Height
            }
          >
            <Typography variant="body1" className={classes.selectMenuContent}>
              All
            </Typography>
            <StyledCheckbox
              checked={props.state.includes('All')}
              checkedAll={true}
              className={classes.checkBoxPosition}
            />
          </MenuItem>
          {props.items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={item.value}
              classes={{
                selected: classes.selected,
              }}
              className={
                props.showValue
                  ? classes.minWidthAnd40height
                  : classes.min40Height
              }
            >
              {props.showValue && (
                <Typography
                  variant="body1"
                  className={classes.selectMenuContentValue}
                >
                  {item.value}
                </Typography>
              )}
              <Typography
                variant="body1"
                className={
                  props.showValue
                    ? classes.selectMenuContentName
                    : classes.selectMenuContent
                }
              >
                {item.name}
              </Typography>
              <StyledCheckbox
                checked={props.state.includes(item.value)}
                checkedAll={true}
                className={classes.checkBoxPosition}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
