import * as Types from '../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientCountForCriteriaQueryVariables = Types.Exact<{
  input: Types.GetPatientCountForCriteriaInput;
}>;


export type GetPatientCountForCriteriaQuery = (
  { __typename: 'Query' }
  & { getPatientCountForCriteria: (
    { __typename: 'GetPatientCountForCriteriaResponse' }
    & Pick<Types.GetPatientCountForCriteriaResponse, 'count'>
  ) }
);


export const GetPatientCountForCriteriaDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCountForCriteria"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientCountForCriteriaInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientCountForCriteria"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPatientCountForCriteriaQuery__
 *
 * To run a query within a React component, call `useGetPatientCountForCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCountForCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCountForCriteriaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientCountForCriteriaQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCountForCriteriaQuery, GetPatientCountForCriteriaQueryVariables>) {
        return Apollo.useQuery<GetPatientCountForCriteriaQuery, GetPatientCountForCriteriaQueryVariables>(GetPatientCountForCriteriaDocument, baseOptions);
      }
export function useGetPatientCountForCriteriaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCountForCriteriaQuery, GetPatientCountForCriteriaQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCountForCriteriaQuery, GetPatientCountForCriteriaQueryVariables>(GetPatientCountForCriteriaDocument, baseOptions);
        }
export type GetPatientCountForCriteriaQueryHookResult = ReturnType<typeof useGetPatientCountForCriteriaQuery>;
export type GetPatientCountForCriteriaLazyQueryHookResult = ReturnType<typeof useGetPatientCountForCriteriaLazyQuery>;
export type GetPatientCountForCriteriaQueryResult = Apollo.QueryResult<GetPatientCountForCriteriaQuery, GetPatientCountForCriteriaQueryVariables>;
export function refetchGetPatientCountForCriteriaQuery(variables?: GetPatientCountForCriteriaQueryVariables) {
      return { query: GetPatientCountForCriteriaDocument, variables: variables }
    }