import * as React from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export function useConfirmExit(
  confirmExit: () => boolean,
  when = true,
  setLastLocation: any
): void {
  const { navigator } = React.useContext(UNSAFE_NavigationContext);
  React.useEffect(() => {
    if (!when) {
      return;
    }
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      for (const para of args) {
        if (para?.pathname) {
          setLastLocation(para?.pathname);
        }
      }
      if (result) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}
