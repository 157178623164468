import EligibilityTable from "./EligibilityTable";
import React from "react";
import {BatchTab, EligibilityTab} from "./BatchTab";
import {Card, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface IProps {
  skip: number,
  limit: number,
  tab: EligibilityTab,
  setTab: (tab: EligibilityTab) => void,
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSkip: (input: number) => void,
  setLimit: (input: number) => void,
  setSortBy: (input: any) => void;
}

export function CheckLogContainer(props: IProps) {
  const classes = useStyles()
  const {skip, limit, setLimit, setSkip, sortBy, setSortBy, tab, setTab} = props
  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{'Eligibility Check Log'}</Typography>
        </div>
        <BatchTab selectedTab={tab} onSelect={setTab} />
        <EligibilityTable tab={tab} setTab={setTab} sortBy={sortBy} setSortBy={setSortBy} skip={skip ?? 0} limit={limit ?? 10} setLimit={setLimit} setSkip={setSkip} />
      </Card>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    flexGrow: 1,
    borderRadius: 8,
    minWidth: 1152,
  },
  titleContainer: {
    backgroundColor: '#F8F8F8',
    paddingLeft: 24,
    height: 56,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 700,
    color: '#434343'
  },
})
