import React from 'react';
import { DeListPatientsTabView } from './View';
import {
  CareTeamMember,
  PanelPatient,
} from '../../../../../../app.types.generated';

export interface DeListPatientsTabContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: PanelPatient[];
  setSelected: React.Dispatch<React.SetStateAction<PanelPatient[]>>;
  actionsFooter: PanelPatient[];
  setActionsFooter: React.Dispatch<React.SetStateAction<PanelPatient[]>>;
  allSelected: boolean;
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
  excluded: PanelPatient[];
  setExcluded: React.Dispatch<React.SetStateAction<PanelPatient[]>>;
  hardFresh: () => void;
  careTeamUsers: CareTeamMember[];
}

export const DeListPatientsTabContainer: React.FC<DeListPatientsTabContainerProps> = (
  props: DeListPatientsTabContainerProps
) => {
  return (
    <div id="DeListPatientsTabContainer">
      <DeListPatientsTabView {...props} />
    </div>
  );
};
