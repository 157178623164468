import React from 'react';
import { Step2PatientFiltersView } from './View';
import {
  CreatePanelInput,
  PatientGender,
} from '../../../../../app.types.generated';
import { FormState } from '../../MasterForm';
import moment from 'moment';

export interface ICD_CPT_Type {
  code: string;
  name: string;
  desc: string;
}

interface Props {
  onNext: (
    lob: any[],
    clinicId: any[],
    division: any[],
    disease: any[],
    openGap: any[],
    patientRequirements: {
      hasAnyIcdCode: string[];
      hasAnyCptCode: string[];
    },
    patientCount: number,
    pcp: any[],
    age: string[],
    gender: PatientGender[],
    payerIds: any[],
    lastDateOfServiceFrom: Date | null,
    lastDateOfServiceTo: Date | null,
  ) => any;
  onBack: () => any;
  step: number;
  options: any;
  formState: FormState;
  onCreate: (input: CreatePanelInput) => any;
  onSubmitForm: () => any;
}

export const defaultValuesForStep2 = {
  lob: [],
  clinicId: [],
  division: [],
  disease: [],
  openGap: [],
  icd: [],
  cpt: [],
  patientCount: 0,
  pcp: [],
  age: [],
  gender: [],
  payerIds: [],
  lastDateOfServiceFrom: null,
  lastDateOfServiceTo: null,
};

export const Step2PatientFiltersContainer = (props: Props) => {
  const [step2State, setStep2State] = React.useState(defaultValuesForStep2);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const submitMasterForm = async () => {
    const careCoordinators: any[] = [];

    const lastCounterFromDayString = props.formState.lastDateOfServiceFrom
      ? moment.utc(props.formState.lastDateOfServiceFrom).format('YYYY-MM-DD')
      : '';
    const lastCounterToDayString = props.formState.lastDateOfServiceTo
      ? moment.utc(props.formState.lastDateOfServiceTo).format('YYYY-MM-DD')
      : '';

    const result = await props.onCreate({
      name: props.formState.name,
      description: props.formState.description,
      criteria: {
        insuranceIds: [], //todo
        insuranceTypes: [], //todo
        patientRequirements: {
          lineOfBusiness: props.formState.lob?.includes('All')
              ? []
              : props.formState.lob.filter((l: string)=>!l.startsWith('parent,')),
          division: props.formState.division?.includes('All')
            ? []
            : props.formState.division,
          clinicId: props.formState.clinicId?.includes('All')
            ? []
            : props.formState.clinicId,
          ageRanges: props.formState.age?.includes('All')
            ? []
            : props.formState.age,
          chronicDisease: props.formState.disease?.includes('All')
            ? []
            : props.formState.disease,
          closeMeasures: [],
          gender: (props.formState.gender as any)?.includes('All')
            ? []
            : props.formState.gender,
          payerIds:  (props.formState.payerIds as any)?.includes('All')
              ? []
              : props.formState.payerIds,
          hasAnyCptCode: props.formState.patientRequirements.hasAnyCptCode,
          hasAnyIcdCode: props.formState.patientRequirements.hasAnyIcdCode,
          lastEncounterFrom: lastCounterFromDayString,
          lastEncounterTo: lastCounterToDayString,
          openMeasures: props.formState.openGap?.find(
            (item: any) => item.value == 'All'
          )
            ? ['ALL']
            : props.formState.openGap.map((item: any) => item.value),
        },
        pcpId: props.formState.pcp.length
          ? props.formState.pcp.map((pcp) => pcp.id)
          : [],
      },
      careTeamUsers: careCoordinators,
    });
    if (result) {
      props.onSubmitForm();
    } else {
      //
    }
  };
  return (
    <Step2PatientFiltersView
      onNext={props.onNext}
      onBack={props.onBack}
      step={props.step}
      step2State={step2State}
      setStep2State={setStep2State}
      options={props.options}
      providers={[]}
      isOpenDialog={isOpenDialog}
      setIsOpenDialog={setIsOpenDialog}
      submitMasterForm={submitMasterForm}
      formState={props.formState}
    />
  );
};
