import * as Types from '../../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationVariables = Types.Exact<{
  input: Types.SetPatientCareGapExtendedStatusRequest;
  careAttemptInput: Types.CareAttemptInput;
}>;


export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation = (
  { __typename: 'Mutation' }
  & { setPatientCareGapExtendedStatus: (
    { __typename: 'SetPatientCareGapExtendedStatusResponse' }
    & Pick<Types.SetPatientCareGapExtendedStatusResponse, 'errorMessage' | 'isSuccess'>
  ), saveCareAttempt: (
    { __typename: 'CareResponse' }
    & Pick<Types.CareResponse, 'isSuccess' | 'message'>
  ) }
);


export const SetPatientCareGapExtendedStatusAndSaveCareAttemptDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setPatientCareGapExtendedStatusAndSaveCareAttempt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetPatientCareGapExtendedStatusRequest"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"careAttemptInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CareAttemptInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setPatientCareGapExtendedStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}},{"kind":"Field","name":{"kind":"Name","value":"saveCareAttempt"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"careAttemptInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]};
export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationFn = Apollo.MutationFunction<SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation, SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationVariables>;

/**
 * __useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation__
 *
 * To run a mutation, you first call `useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientCareGapExtendedStatusAndSaveCareAttemptMutation, { data, loading, error }] = useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      careAttemptInput: // value for 'careAttemptInput'
 *   },
 * });
 */
export function useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation(baseOptions?: Apollo.MutationHookOptions<SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation, SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationVariables>) {
        return Apollo.useMutation<SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation, SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationVariables>(SetPatientCareGapExtendedStatusAndSaveCareAttemptDocument, baseOptions);
      }
export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationHookResult = ReturnType<typeof useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation>;
export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationResult = Apollo.MutationResult<SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation>;
export type SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationOptions = Apollo.BaseMutationOptions<SetPatientCareGapExtendedStatusAndSaveCareAttemptMutation, SetPatientCareGapExtendedStatusAndSaveCareAttemptMutationVariables>;