import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { theme } from '../../../themes/theme';
import { formatNumberPipe } from '../../../sharePipe/formatNumberPipe';
import { Maybe } from '../../../app.types.generated';
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

interface DiseaseAndTagsDataType {
  yAxis: (Maybe<string> | undefined)[];
  tags: (Maybe<number> | undefined)[];
  chronicPatients: (Maybe<number> | undefined)[];
  percent: (Maybe<string> | undefined)[];
  counts: {
    totalPatients: number;
    patients: number;
  };
}

interface StackedHorizontalBarChartsProps {
  height: number;
  data: DiseaseAndTagsDataType;
}

export const StackedHorizontalBarCharts = (
  props: StackedHorizontalBarChartsProps
) => {
  const option = {
    title: {
      position: 'flex',
      show: false,
      z: 1000,
      text: `${formatNumberPipe(
        props.data.counts.totalPatients
      )} Total Patients`,
      right: '3%',
      width: 200,
      // right: '-25%',
      textStyle: {
        color: '#656565',
        fontSize: 14,
        fontWeight: 700,
      },
      textAlign: 'center',
      subtext: `${formatNumberPipe(props.data.counts.patients)} Patients`,
      subtextStyle: {
        color: '#656565',
        fontSize: 12,
        fontWeight: 500,
        align: 'right',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        shadowStyle: {
          color: `${theme.palette.grey[50]}`,
          shadowColor: `${theme.palette.grey[50]}`,
          shadowOffsetX: -90,
        },
      },
      borderRadius: 8,
      borderColor: '#434343',
      backgroundColor: 'rgba(67, 67, 67, 0.8)',
      formatter: function (params: any) {
        if (
          params[0].axisValueLabel === 'Chronic' ||
          params[0].seriesName === 'Chronic'
        ) {
          return `<div style="color: #FFF;">
            ${
              formatNumberPipe(props.data.chronicPatients[0]) +
              ' Chronic Patients'
            }
            </div>`;
        } else {
          if (params.length > 1) {
            return `<div style="color: #FFF;">
            ${props.data.yAxis[params[0].dataIndex]} ${
              props.data.percent[params[0].dataIndex]
            } <br> ${formatNumberPipe(
              props.data.tags[params[0].dataIndex]
            )} of ${
              formatNumberPipe(params[1].value) + ' ' + params[1].seriesName
            }
            </div>`;
          }
          if (params.length === 1) {
            return `<div style="color: #FFF;">
            ${
              formatNumberPipe(params[0].value) + ' ' + params[0].axisValueLabel
            }
            </div>`;
          }
        }
      },
      position: function (
        point: any,
        params: any,
        dom: any,
        rect: any,
        size: any
      ) {
        // new position
        let newX = 0;
        let newY = 0;
        // cursor coordinates
        let pointX = point[0];
        let pointY = point[1];
        // canvas size
        let viewWidth = size.viewSize[0];
        let viewHeight = size.viewSize[1];
        // tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        if (pointX < boxWidth) {
          newX = 5;
        } else if (pointX + boxWidth <= viewWidth) {
          newX = pointX + 10;
        } else {
          newX = pointX - (pointX + boxWidth - viewWidth);
        }

        if (pointY + boxHeight + 10 <= viewHeight) {
          newY = pointY + 10;
        } else {
          // newY = pointY - (pointY + boxHeight + 10 - viewHeight) - 20;
          newY = pointY - boxHeight;
        }

        return [newX, newY];
      },
    },
    legend: {
      icon: 'circle',
      itemWidth: 14,
      itemHeight: 7,
      itemGap: 24,
      left: 24,
      top: 10,
      textStyle: {
        color: '#656565',
        fontWeight: `${theme.typography.fontWeightMedium}`,
        fontFamily: `${theme.typography.fontFamily}`,
      },
    },
    grid: {
      left: -60,
      right: 0,
      bottom: 20,
      width: '118%',
      height: '80%',
      containLabel: true,
    },
    xAxis: {
      show: false,
      type: 'value',
    },
    yAxis: {
      show: false,
      type: 'category',
      data: props.data.yAxis || [],
    },
    series: [
      {
        z: 1000,
        name: 'Tags',
        type: 'bar',
        // stack: 'total',
        label: {
          formatter: function (d: any) {
            if (d.dataIndex === props.data.yAxis.length - 1) {
              return `${props.data.yAxis[d.dataIndex]}`;
            } else {
              return `${props.data.yAxis[d.dataIndex]} ${
                props.data.percent[d.dataIndex]
              }`;
            }
          },
          show: true,
          position: ['0%', '-200%'],
          color: '#656565',
          opacity: 1,
          fontSize: 12,
          fontWeight: 500,
        },
        data: props.data.tags || [],
        barWidth: 8,
        itemStyle: {
          normal: {
            color: '#41DAAF',
          },
        },
        barGap: '-100%',
      },
      {
        z: 900,
        name: 'Chronic Patients',
        type: 'bar',
        // stack: 'total',
        label: {
          formatter: function (d: any) {
            return ``;
          },
          show: true,
          position: ['70%', '-200%'],
        },
        data: props.data.chronicPatients || [],
        barGap: '-100%',
        barWidth: 8,
        itemStyle: {
          normal: {
            color: '#0761B3',
            // color: `${theme.palette.grey[50]}`,
          },
        },
      },
    ],
  };

  const classes = useStyles()
  return (
    <div style={{ width: '100%', height: '100%', padding: '12px 0px', position: 'relative' }}>
      <ReactEcharts option={option} style={{ height: props.height }} />
      <div className={classes.titleView}>
        <div className={classes.title}>{`${formatNumberPipe(
          props.data.counts.totalPatients
        )} Total Patients`}</div>
        <div className={classes.subTitle}>{`${formatNumberPipe(props.data.counts.patients)} Patients`}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  titleView: {
    position: 'absolute',
    top: 12,
    right: 24,
  },
  title: {
    color: '#656565',
    fontSize: 14,
    lineHeight: '24px',
    textAlign: 'right',
    fontWeight: 700
  },
  subTitle: {
    color: '#656565',
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'right',
    fontWeight: 500
  },
}));
