import React, { useCallback } from 'react';
import {
  ClickAwayListener,
  fade,
  Fade,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  styled,
} from '@material-ui/core';
import { SmsTagConstant } from '../../scenes/Panels/ViewPanels/components/EngagementDialog/SmsDefaultMessageConstant';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
      marginTop: theme.spacing(0.5),
      width: 157,
    },
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
      padding: theme.spacing(0.5, 1),
      position: 'relative',
    },
    '& li[role="menuitem"]': {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 40,
      borderRadius: 8,
    },

    '& li[role="menuitem"]:first-child': {
      marginBottom: theme.spacing(0.5),
    },
    '& li[role="menuitem"]:nth-type-of(2)': {
      marginBottom: theme.spacing(0.25),
    },
    '& li[role="menuitem"]:last-child': {
      margin: theme.spacing(0.5, 0),
    },
  },
  menuItem: {
    color: theme.palette.grey[600],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    '&:hover': {
      color: theme.palette.grey[700],
    },
    '& > svg': {
      color: theme.palette.grey[500],
    },
    '& > svg:hover': {
      color: theme.palette.grey[600],
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      style: {
        transform: 'translateX(-8px)',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      lineHeight: '24px',
      display: 'inline-block',

      height: 24,

      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

interface SuggestionsProps {
  field: React.RefObject<HTMLInputElement>;
  anchorEl: any;
  setAnchorEl: any;
  callBackOnSuggestion: any;
  hasSuggestions: boolean;
  setHasSuggestions: any;
}

export const SuggestionPopUpSelect = (props: SuggestionsProps) => {
  const { hasSuggestions, setHasSuggestions } = props;
  const openMenu = Boolean(props.anchorEl);
  const classes = useStyles();

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    handleClickAwayOutside();
    event.stopPropagation();
  }

  const handleClickAwayOutside = () => {
    setHasSuggestions(false);
    props.callBackOnSuggestion('');
  };

  const handleClickAway = useCallback(() => {
    setHasSuggestions(false);
    props.field.current?.focus();
  }, [props.field, setHasSuggestions]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Fade in={hasSuggestions}>
        <StyledMenu
          id="suggestion-menu"
          MenuListProps={{
            'aria-labelledby': 'suggestion-menu-options',
          }}
          anchorEl={props.anchorEl}
          open={openMenu}
          onClose={handleClose}
          elevation={2}
          className={classes.menu}
        >
          {SmsTagConstant.map((tag, index) => {
            return (
              <MenuItem
                className={classes.menuItem}
                key={index}
                disableRipple
                button
                onClick={() => {
                  setHasSuggestions(false);
                  props.callBackOnSuggestion(tag.value);
                }}
              >
                {tag.name}
              </MenuItem>
            );
          })}
        </StyledMenu>
      </Fade>
    </ClickAwayListener>
  );
};
