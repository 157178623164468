import { Button, Chip, makeStyles } from '@material-ui/core';
import { theme } from '../../themes/theme';
import { SnoozedIcon } from '../../assets/Icons/CareGaps';
import React, { useEffect, useState } from 'react';
import { PatientFaceInfo } from '../../app.types.generated';

type CoordinationStatusType = {
  icon: React.ReactElement | undefined;
  label: string;
};

const useStyles = makeStyles((props) => ({
  coordinationStatusPT: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    padding: theme.spacing(0.25, 0.8725),
    borderRadius: 6,
    height: 24,
    width: 'fit-content',
    boxSizing: 'border-box',

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  coordinationStatusPFS: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    padding: theme.spacing(1, 1, 1, 2),
    borderRadius: 8,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:hover': {
      cursor: (props: any) => (props.endIcon ? 'pointer' : 'text'),
      userSelect: (props: any) => (props.endIcon ? 'pointer' : 'text'),
    },
    '& div.MuiChip-deleteIcon': {
      margin: 0,
      height: 24,
      width: 24,
    },
  },
  coordinationStatusBlue: {
    color: `${theme.palette.primary.main}!important`,
    background: `${theme.palette.primary.light}!important`,
  },
  coordinationStatusGreen: {
    color: `${theme.palette.success.main}!important`,
    background: `${theme.palette.success.light}!important`,
  },
  coordinationStatusGrey: {
    color: `${theme.palette.grey[500]}!important`,
    background: `${theme.palette.secondary.light}!important`,
  },
  snoozedStatus: {
    width: 'fit-content',
    height: 40,
    fontSize: 14,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    fontWeight: 700,
    lineHeight: '24px',
    padding: theme.spacing(1, 1.5, 1, 2),
    borderRadius: 6,
    gap: 12,

    '&:hover': {
      cursor: (props: any) => (props.endIcon ? 'pointer' : 'text'),
      userSelect: (props: any) => (props.endIcon ? 'pointer' : 'text'),
    },
    '& span.MuiChip-label': {
      padding: 0,
    },
    '& svg': {
      width: 24,
      height: 24,
      margin: 0,
      color: theme.palette.warning.main,
    },
    '& g path, rect': {
      fill: theme.palette.warning.main,
    },
    '& div.MuiChip-deleteIcon': {
      padding: 0,
    },
  },
}));

interface CoordinationStatusChipProps {
  patientInfo: PatientFaceInfo;
  location: 'PatientTable' | 'PatientFaceSheet';
  endIcon?: React.ReactElement;
  onChangeCC?: (event: any) => void;
}

export const CoordinationStatusChip: React.FC<CoordinationStatusChipProps> = ({
  patientInfo,
  location,
  endIcon,
  onChangeCC,
}) => {
  const [coordinationStatusDetail, setCoordinationStatusDetail] = useState<
    CoordinationStatusType
  >({
    icon: undefined,
    label: 'No Status',
  });
  const classes = useStyles({ endIcon });

  const getClassNameByStatus = () => {
    switch (patientInfo.careCoordinationStatus) {
      case 'Appt Needed':
      case 'No Show':
      case 'Appt Canceled':
      case 'Site Follow Up':
        return classes.coordinationStatusBlue;
      case 'Snoozed':
        if (location === 'PatientTable') return classes.coordinationStatusBlue;
        if (location === 'PatientFaceSheet') return classes.snoozedStatus;
        break;

      case 'Planned to Walk-in':
      case 'Appt Booked':
        return classes.coordinationStatusGreen;

      case 'Visit Completed':
      case 'No Status':
      case 'Outreach Closed':
      default:
        return classes.coordinationStatusGrey;
    }
  };

  const getClassNameByLocation = () => {
    switch (location) {
      case 'PatientTable':
        return classes.coordinationStatusPT;
      case 'PatientFaceSheet':
        return classes.coordinationStatusPFS;
      default:
        return classes.coordinationStatusPT;
    }
  };

  useEffect(() => {
    if (patientInfo.careCoordinationStatus === 'Snoozed') {
      if (location === 'PatientTable') {
        setCoordinationStatusDetail({
          icon: undefined,
          label: patientInfo.careCoordinationStatus,
        });
      }
      if (location === 'PatientFaceSheet') {
        setCoordinationStatusDetail({
          icon: <SnoozedIcon />,
          label: patientInfo.careCoordinationStatusText ?? 'Snoozed',
        });
      }
    } else {
      setCoordinationStatusDetail({
        icon: undefined,
        label: patientInfo.careCoordinationStatus ?? 'No Status',
      });
    }
  }, [patientInfo, location]);

  const componentSwitcher = () => {
    switch (location) {
      case 'PatientTable':
        return (
          <Chip
            label={coordinationStatusDetail.label}
            className={`${getClassNameByLocation()} ${getClassNameByStatus()}`}
          />
        );
      case 'PatientFaceSheet':
        return (
          <>
            {!['Outreach Closed', 'Deceased'].includes(
              coordinationStatusDetail.label
            ) ? (
              <ChipButton
                handleClick={onChangeCC ?? (() => {})}
                startIcon={coordinationStatusDetail.icon ?? undefined}
                endIcon={endIcon ?? undefined}
                label={coordinationStatusDetail.label}
                className={
                  coordinationStatusDetail.label.includes('Snooze')
                    ? classes.snoozedStatus
                    : `${getClassNameByLocation()} ${getClassNameByStatus()}`
                }
              />
            ) : (
              <Chip
                label={coordinationStatusDetail.label}
                className={`${getClassNameByLocation()} ${getClassNameByStatus()}`}
              />
            )}
          </>
        );
      default:
        break;
    }
  };

  return <>{componentSwitcher()}</>;
};

type ChipButtonProps = {
  handleClick: (event: any) => void;
  startIcon: React.ReactElement | undefined;
  endIcon: React.ReactElement | undefined;
  label: string;
  className: any;
};

const ChipButton: React.FC<ChipButtonProps> = ({
  handleClick,
  startIcon,
  endIcon,
  label,
  className,
}) => {
  return (
    <div>
      {endIcon ? (
        <Button
          className={className}
          variant="contained"
          onClick={handleClick}
          startIcon={startIcon}
          endIcon={endIcon}
          disableElevation
          disableRipple
        >
          {label}
        </Button>
      ) : (
        <Chip label={label} className={className} icon={startIcon} />
      )}
    </div>
  );
};
