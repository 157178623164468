import React from 'react';
import { useNoteTabStyles } from './Styles';
import { Typography } from '@material-ui/core';
import { AddNewNoteButton } from '../PatientInfo/components/AddNewNoteButton';
import { EmptyContent } from '../CoordinationLog/View';
import { NoteItem } from './components/NoteItem';
import { EditableNoteDialog } from './components/Dialog';
import { NoteOption } from './Container';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';

interface NotesTabViewProps {
  tabLabel: string;
  patientInfo: PatientFaceSheet;
  hardRefresh: () => void;
  notesData: any;
}

export const NotesTabView: React.FC<NotesTabViewProps> = ({
  tabLabel,
  patientInfo,
  hardRefresh,
  notesData,
}) => {
  const classes = useNoteTabStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [checkedItem, setCheckedItem] = React.useState<any>(undefined); // item to be deleted / edited

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOption('');
  };
  const handleItemClick = (event: any, item?: any) => {
    setOpenDialog(true);
    setSelectedOption(event.currentTarget.textContent);

    if (
      event.currentTarget.textContent === NoteOption.DELETE ||
      event.currentTarget.textContent === NoteOption.EDIT
    ) {
      setCheckedItem(item);
    } else {
      setCheckedItem(undefined);
    }
  };

  return (
    <div className={classes.root} id="NoteTab">
      {/* Header: Tab Name and Action Button */}
      <div className={classes.tabLabelAndActionContainer}>
        <Typography className={classes.tabLabel}>{tabLabel}</Typography>
        {/* options select */}
        <AddNewNoteButton handleClick={handleItemClick} />
      </div>
      {/* Content: notes list items */}
      {!notesData || notesData.length === 0 ? (
        <EmptyContent
          selectedSectionName={tabLabel}
          coordinationLogData={null}
          classes={classes}
        />
      ) : (
        <div className={classes.notesContainer}>
          {/* Notes */}
          {notesData.map((item: any) => {
            return (
              <NoteItem
                item={item}
                key={item.id}
                handleItemClick={handleItemClick}
                inDialog={false}
              />
            );
          })}
        </div>
      )}

      {/* Add new / Edit / Delete note dialog */}
      {openDialog && (
        <EditableNoteDialog
          dialogId={selectedOption}
          handleClose={handleCloseDialog}
          item={checkedItem}
          handleItemClick={checkedItem ? handleItemClick : undefined}
          patientId={patientInfo.id}
          hardRefresh={hardRefresh}
        />
      )}
    </div>
  );
};
