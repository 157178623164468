import React, { useContext } from 'react';
import { GlobalContext } from '../GlobalContext';
import { Loading } from '../Loading';
import { Navigate, useLocation } from 'react-router';
import { FakeDataAdmin } from '../CheckPrivilegesForDisplay/fakePrivileges';
import { CheckIsHudsonClinician } from '../CheckIsAdmin';
interface RouteGuardProps {
  element: React.ReactElement;
  webURL: string;
}
export const RouteGuard = (props: RouteGuardProps) => {
  const location = useLocation();
  const { loggedInUser, loading } = useContext(GlobalContext);
  if (loading) {
    return <Loading />;
  }
  if (!loggedInUser) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }
  if (CheckIsHudsonClinician(loggedInUser)) {
    if (location.pathname === '/dashboard') {
      return props.element;
    }
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }
  // check webURLs with props.webURL for permission
  // 1st backend need to return this in globalContext.me
  //  me {
  //     avatarUrl
  //     firstName
  //     id
  //     lastName
  //     title
  //     role{
  //       roleName
  //       urls{
  //         url
  //         urlName
  //       }
  //     }
  //   }
  // then we can use title or they return more attribute for role and permission
  if (FakeDataAdmin.privileges.webURLs.includes(props.webURL)) {
    return props.element;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/home',
        }}
      />
    );
  }
};
