import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const NorthIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M5.70998 9.7C6.09998 10.09 6.72998 10.09 7.11998 9.7L11 5.83V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V5.83L16.88 9.71C17.27 10.1 17.9 10.1 18.29 9.71C18.68 9.32 18.68 8.69 18.29 8.3L12.7 2.7C12.31 2.31 11.68 2.31 11.29 2.7L5.70998 8.29C5.31998 8.68 5.31998 9.32 5.70998 9.7Z"
    />
  );
};
