export const SmsType = [
  {
    value: 'Custom Message',
    name: 'Custom Message',
  },
];

export const SmsLanguage = [
  {
    value: 'English',
    name: 'English',
    disabled: true,
    index: 0,
  },
  {
    value: 'Chinese (S)',
    name: 'Chinese (Simplified)',
    disabled: false,
    index: 1,
  },
  {
    value: 'Chinese (T)',
    name: 'Chinese (Traditional)',
    disabled: false,
    index: 2,
  },
];
