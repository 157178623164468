import { Box, Grid, Paper } from '@material-ui/core';
import { TabBar } from '../../../../../../../components/TabBar';
import { useCareGapsDrawerTabsStyles } from '../CareGapsDrawer/styles';
import { CareGapsGroup } from '../CareGapsGroup';
import { PatientInfo } from '../PatientInfo';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';
import { CoordinationLog } from '../CoordinationLog';
import { useReadOnly } from '../../hooks/useReadOnly';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import React, { useState } from 'react';
import { NotesTab } from '../NotesTab';
import { PanelPatient } from '../../../../../../../app.types.generated';
import { CareGapContext } from '../../context';

interface CareGapsDrawerTabsViewProps {
  value: number;
  switchTab: (event: any, newValue: number) => void;
  patientInfo: PatientFaceSheet;
  setClickedFamilyRows: (input: boolean) => void;
  originalPatientId: string;
  hardRefresh: () => void;
  refresher: number;
  hasNext: boolean;
  handleSwitchNextPatient: () => void;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
  disabledArrowForwardBtn: boolean;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => void;
}

export const CareGapsDrawerTabsView = (props: CareGapsDrawerTabsViewProps) => {
  const {
    value,
    switchTab,
    patientInfo,
    setClickedFamilyRows,
    originalPatientId,
    hardRefresh,
    refresher,
    setKeyPatients,
    handleSwitchNextPatient,
    disabledArrowForwardBtn,
    drawerHandler,
  } = props;
  const classes = useCareGapsDrawerTabsStyles();
  const [selectedYear, setSelectedYear] = useState(0);
  const { hidden } = useReadOnly(patientInfo);

  const [openCareGapCount, setOpenCareGapCount] = React.useState(
    patientInfo.caregapCount
  );

  return (
    <Grid container className={classes.root}>
      <Box style={{ width: '100%' }}>
        <Paper className={classes.control} elevation={0}>
          <TabBar
            activeTabIndex={value}
            switchTab={switchTab}
            tabObjects={[
              {
                tabIndex: 0,
                tabLabel: 'Patient Info',
                tabUrl: '',
                tabBadgeCount: 0,
                disabled: false,
              },
              {
                tabIndex: 1,
                tabLabel: 'Coordination Log',
                tabUrl: '',
                tabBadgeCount: 0,
                disabled: false,
              },
              {
                tabIndex: 2,
                tabLabel: 'Care Gaps',
                tabUrl: '',
                tabBadgeCount: openCareGapCount,
                disabled: false,
              },
              {
                tabIndex: 3,
                tabLabel: 'Notes',
                tabUrl: '',
                tabBadgeCount: patientInfo.notes?.length, // get notes count from the patientInfo API
                disabled: false,
              },
            ]}
            sticky
          >
            {value === 0 && (
              <>
                <Grid item xs={12}>
                  <PatientInfo
                    patientInfo={patientInfo}
                    setKeyPatients={setKeyPatients}
                    setClickedFamilyRows={setClickedFamilyRows}
                    originalPatientId={originalPatientId}
                    hardRefresh={hardRefresh}
                    showLockIcon={hidden}
                    handleSwitchNextPatient={handleSwitchNextPatient}
                    disabledArrowForwardBtn={disabledArrowForwardBtn}
                    drawerHandler={drawerHandler}
                  />
                </Grid>
              </>
            )}
            {value === 1 && (
              <Grid item xs={12}>
                <CoordinationLog
                  patientId={patientInfo.id}
                  refresher={refresher}
                />
              </Grid>
            )}
            {value === 2 && (
              <Grid item xs={12}>
                <CareGapsGroup
                  hardRefresh={hardRefresh}
                  patientId={patientInfo.id}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                  patientInfo={patientInfo}
                  setOpenCareGapCount={setOpenCareGapCount}
                />
              </Grid>
            )}
            {value === 3 && (
              <Grid item xs={12}>
                <NotesTab
                  tabLabel="Notes"
                  patientInfo={patientInfo}
                  hardRefresh={hardRefresh}
                />
              </Grid>
            )}
          </TabBar>
        </Paper>
      </Box>
    </Grid>
  );
};
