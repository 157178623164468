import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const PCPIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M14,2.5 C15.8685634,2.5 17.3951264,3.9642776 17.4948211,5.8079648 L17.5,6 L17.5,7.5 C19.4329966,7.5 21,9.06700338 21,11 L21,18 C21,19.9329966 19.4329966,21.5 17.5,21.5 L6.5,21.5 C4.56700338,21.5 3,19.9329966 3,18 L3,11 C3,9.06700338 4.56700338,7.5 6.5,7.5 L6.5,6 C6.5,4.1314366 7.9642776,2.60487355 9.8079648,2.50517886 L10,2.5 L14,2.5 Z M15.6363636,11.9545455 C14.8732065,11.1913883 13.6595926,11.1532305 12.8513898,11.8400719 L12.7272727,11.9545455 L12,12.6818182 L11.2727273,11.9545455 C10.469404,11.1512222 9.16695964,11.1512222 8.36363636,11.9545455 C7.60047925,12.7177026 7.5623214,13.9313165 8.2491628,14.7395192 L8.36363636,14.8636364 L10.5857864,17.0857864 C11.3257272,17.8257272 12.5012114,17.8646715 13.2869988,17.2026192 L13.4142136,17.0857864 L15.6363636,14.8636364 C16.4396869,14.0603131 16.4396869,12.7578687 15.6363636,11.9545455 Z M14,4.5 L10,4.5 C9.22030388,4.5 8.57955132,5.09488808 8.50686658,5.85553999 L8.5,6 L8.5,7.5 L15.5,7.5 L15.5,6 C15.5,5.22030388 14.9051119,4.57955132 14.14446,4.50686658 L14,4.5 Z"
  />
);
