import Amplify, { Logger } from '@aws-amplify/core';

import { config } from '../config';
import { Auth } from '@aws-amplify/auth';
import { ClientMetaData, SignInOpts } from '@aws-amplify/auth/lib-esm/types';

Logger.LOG_LEVEL = config.debug.logLevel;

Amplify.configure({
  Analytics: {
    disabled: true,
  },
  Auth: {
    identityPoolId: config.aws.cognito.identityPoolId,
    mandatorySignIn: false,
    region: config.aws.region,
    userPoolId: config.aws.cognito.userPoolId,
    userPoolWebClientId: config.aws.cognito.appClientId,
  },
  Storage: {
    AWSS3: {
      bucket: config.aws.s3.userUploadBucket,
      region: config.aws.region,
      level: 'private',
      serverSideEncryption: 'aws:kms',
      SSEKMSKeyId: config.aws.s3.key,
    },
  },
});

// allow trimming username before logging in....
const originalSignIn = Auth.signIn.bind(Auth);
Auth.signIn = function (
  usernameOrSignInOpts: string | SignInOpts,
  pw: string | undefined,
  clientMetadata: ClientMetaData
) {
  let username: string | SignInOpts;
  if (typeof usernameOrSignInOpts === 'string') {
    username = usernameOrSignInOpts.trim();
  } else {
    username = {
      ...usernameOrSignInOpts,
      username: usernameOrSignInOpts.username.trim(),
    };
  }

  return originalSignIn(username, pw, clientMetadata);
};
