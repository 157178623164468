import { IconButton, makeStyles } from '@material-ui/core';
import { ChromeAlertBarIcon } from '../../assets/Icons/SideNavigation/ChromeAlertBarIcon';
import { CloseIcon } from '../../assets/Icons/ExportPatients';
import { theme } from '../../themes/theme';

const useStyles = makeStyles({
  alertBar: {
    width: '100%',
    height: 32,
    backgroundColor: theme.palette.warning.light,
  },
  alertBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    boxSize: 'border-box',

    padding: theme.spacing(0.5, 4),
  },
  chromeAlertIcon: {
    fontSize: 24,
    color: theme.palette.warning.main,
  },
  iconButton: {
    padding: 0,
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.grey[600],
  },
  message: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: theme.palette.warning.main,
  },
  alertIconAndMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
  },
});

interface ChromeAlertBarProps {
  setChrome: any;
}

export const ChromeAlertBar = (props: ChromeAlertBarProps) => {
  const classes = useStyles();
  return (
    <div id="alertBar" className={classes.alertBar}>
      <div className={classes.alertBarContainer}>
        <section className={classes.alertIconAndMessage}>
          <ChromeAlertBarIcon className={classes.chromeAlertIcon} />
          <div className={classes.message}>
            This browser has limited support. We recommend use Chrome for the
            best experience.
          </div>
        </section>

        <IconButton
          onClick={() => {
            props.setChrome(true);
          }}
          className={classes.iconButton}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    </div>
  );
};
