export function compareObjectsPipe(
  array1: any[],
  array2: any[],
  attribute: any
) {
  const result = [];
  for (const i in array1) {
    for (const j in array2) {
      if (array1[i][attribute] == array2[j][attribute]) {
        result.push(array1[i]);
      }
    }
  }
  return result;
}
