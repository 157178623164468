import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { theme } from '../../../themes/theme';

type CoordinationProgressDateType = {
  value: number;
  date: string;
};

interface BarchartDataType {
  outreachClosed: CoordinationProgressDateType[];
  plannedToWalkIn: CoordinationProgressDateType[];
  apptBooked: CoordinationProgressDateType[];
  snoozed: CoordinationProgressDateType[];
}

interface StackedBarChartsProps {
  height: number;
  data: BarchartDataType;
}

export const CoordinationProgressChart = (props: StackedBarChartsProps) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shadowColor: `${theme.palette.grey[50]}`,
          shadowBlur: 10,
        },
      },
      borderRadius: 8,
      borderColor: '#434343',
      backgroundColor: 'rgba(67, 67, 67, 0.8)',
      formatter: function (params: any) {
        let text = '';
        for (let item of params) {
          let dotDiv = `<span style="display:inline-block;width:7px;height:7px;
          border-radius:50%;background-color:${item.color};margin-right: 4px"></span>`;

          text += `<div style="width:fit-content;height:fit-content">
          ${dotDiv}
          <span>${item.data.value} </span>
          <span>${item.seriesName}</span>
          </div>`;
        }
        return `<div style="padding:4px;display:flex;flex-direction:column;
        align-items:flex-start;justify-content:flex-start;gap:6px">
        ${text}
        </div>`;
      },
      textStyle: {
        color: '#FFFFFF',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    title: {
      text: 'Last 7 Days',
      right: '5%',
      textStyle: {
        color: '#656565',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    legend: {
      icon: 'circle',
      itemWidth: 14,
      itemHeight: 7,
      itemGap: 24,
      left: 36,
      top: 10,
    },
    grid: {
      left: 24,
      right: 24,
      bottom: 60,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: props.data.outreachClosed.map((item) => item.date),
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Outreach Closed',
        type: 'bar',
        barWidth: 36,
        stack: 'total', // same value to stack up
        color: theme.palette.primary.main,
        data: props.data.outreachClosed,
        cursor: 'default',
      },
      {
        name: 'Planned to Walk In',
        type: 'bar',
        barWidth: 36,
        stack: 'total',
        color: theme.palette.avatars.yellow,
        data: props.data.plannedToWalkIn,
        cursor: 'default',
      },
      {
        name: 'Appt Booked',
        type: 'bar',
        barWidth: 36,
        stack: 'total',
        color: '#41DAAF',
        data: props.data.apptBooked,
        cursor: 'default',
      },
      {
        name: 'Snoozed',
        type: 'bar',
        barWidth: 36,
        stack: 'total',
        color: theme.palette.grey[400],
        data: props.data.snoozed,
        cursor: 'default',
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '100%', padding: '12px 0px' }}>
      <ReactEcharts option={option} style={{ height: props.height }} />
    </div>
  );
};
