import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type ExportPatientsQueryVariables = Types.Exact<{
  format: Types.Scalars['String'];
  filters: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type ExportPatientsQuery = (
  { __typename: 'Query' }
  & { exportPatientByCareTeamUserIds: (
    { __typename: 'PatientExportResponse' }
    & Pick<Types.PatientExportResponse, 'link' | 'taskID'>
  ) }
);

export type GetPatientExportLinkUtilityQueryVariables = Types.Exact<{
  format: Types.Scalars['String'];
  filters: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetPatientExportLinkUtilityQuery = (
  { __typename: 'Query' }
  & { getPatientExportLinkUtility: (
    { __typename: 'PatientExportResponse' }
    & Pick<Types.PatientExportResponse, 'taskID'>
  ) }
);

export type GetExportTaskStatusQueryVariables = Types.Exact<{
  input: Types.TaskStatusQueryInput;
}>;


export type GetExportTaskStatusQuery = (
  { __typename: 'Query' }
  & { getExportTaskStatus: (
    { __typename: 'ExportTaskStatusResponse' }
    & Pick<Types.ExportTaskStatusResponse, 'taskId' | 'url'>
  ) }
);


export const ExportPatientsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"exportPatients"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"format"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportPatientByCareTeamUserIds"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"format"},"value":{"kind":"Variable","name":{"kind":"Name","value":"format"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"link"}},{"kind":"Field","name":{"kind":"Name","value":"taskID"}}]}}]}}]};

/**
 * __useExportPatientsQuery__
 *
 * To run a query within a React component, call `useExportPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPatientsQuery({
 *   variables: {
 *      format: // value for 'format'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExportPatientsQuery(baseOptions: Apollo.QueryHookOptions<ExportPatientsQuery, ExportPatientsQueryVariables>) {
        return Apollo.useQuery<ExportPatientsQuery, ExportPatientsQueryVariables>(ExportPatientsDocument, baseOptions);
      }
export function useExportPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPatientsQuery, ExportPatientsQueryVariables>) {
          return Apollo.useLazyQuery<ExportPatientsQuery, ExportPatientsQueryVariables>(ExportPatientsDocument, baseOptions);
        }
export type ExportPatientsQueryHookResult = ReturnType<typeof useExportPatientsQuery>;
export type ExportPatientsLazyQueryHookResult = ReturnType<typeof useExportPatientsLazyQuery>;
export type ExportPatientsQueryResult = Apollo.QueryResult<ExportPatientsQuery, ExportPatientsQueryVariables>;
export function refetchExportPatientsQuery(variables?: ExportPatientsQueryVariables) {
      return { query: ExportPatientsDocument, variables: variables }
    }
export const GetPatientExportLinkUtilityDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientExportLinkUtility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"format"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientExportLinkUtility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"format"},"value":{"kind":"Variable","name":{"kind":"Name","value":"format"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"taskID"}}]}}]}}]};

/**
 * __useGetPatientExportLinkUtilityQuery__
 *
 * To run a query within a React component, call `useGetPatientExportLinkUtilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientExportLinkUtilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientExportLinkUtilityQuery({
 *   variables: {
 *      format: // value for 'format'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPatientExportLinkUtilityQuery(baseOptions: Apollo.QueryHookOptions<GetPatientExportLinkUtilityQuery, GetPatientExportLinkUtilityQueryVariables>) {
        return Apollo.useQuery<GetPatientExportLinkUtilityQuery, GetPatientExportLinkUtilityQueryVariables>(GetPatientExportLinkUtilityDocument, baseOptions);
      }
export function useGetPatientExportLinkUtilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientExportLinkUtilityQuery, GetPatientExportLinkUtilityQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientExportLinkUtilityQuery, GetPatientExportLinkUtilityQueryVariables>(GetPatientExportLinkUtilityDocument, baseOptions);
        }
export type GetPatientExportLinkUtilityQueryHookResult = ReturnType<typeof useGetPatientExportLinkUtilityQuery>;
export type GetPatientExportLinkUtilityLazyQueryHookResult = ReturnType<typeof useGetPatientExportLinkUtilityLazyQuery>;
export type GetPatientExportLinkUtilityQueryResult = Apollo.QueryResult<GetPatientExportLinkUtilityQuery, GetPatientExportLinkUtilityQueryVariables>;
export function refetchGetPatientExportLinkUtilityQuery(variables?: GetPatientExportLinkUtilityQueryVariables) {
      return { query: GetPatientExportLinkUtilityDocument, variables: variables }
    }
export const GetExportTaskStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getExportTaskStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TaskStatusQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getExportTaskStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"taskId"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]};

/**
 * __useGetExportTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetExportTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportTaskStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExportTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>) {
        return Apollo.useQuery<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>(GetExportTaskStatusDocument, baseOptions);
      }
export function useGetExportTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>(GetExportTaskStatusDocument, baseOptions);
        }
export type GetExportTaskStatusQueryHookResult = ReturnType<typeof useGetExportTaskStatusQuery>;
export type GetExportTaskStatusLazyQueryHookResult = ReturnType<typeof useGetExportTaskStatusLazyQuery>;
export type GetExportTaskStatusQueryResult = Apollo.QueryResult<GetExportTaskStatusQuery, GetExportTaskStatusQueryVariables>;
export function refetchGetExportTaskStatusQuery(variables?: GetExportTaskStatusQueryVariables) {
      return { query: GetExportTaskStatusDocument, variables: variables }
    }