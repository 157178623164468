import React from 'react';
import {
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { StyledCheckbox } from '../StyledCheckbox';
import { InfoIcon } from '../../assets/Icons/PanelsList/InfoIcon';
import { TooltipSmsMessage } from '../../scenes/Panels/ViewPanels/components/EngagementDialog/SmsDefaultMessageConstant';
import { sortArrayBaseOnObjectArrayPipe } from '../../sharePipe/sortArrayBaseOnObjectArrayPipe';
import { SmsLanguage } from '../../scenes/Panels/ViewPanels/components/EngagementDialog/SmsOptions';

interface CustomSelectProps {
  state: any[];
  setState: any;
  isMultiple: boolean;
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  showValue?: boolean;
  fullWidth?: boolean;
  isObject?: boolean;
}

const useStyles = makeStyles((theme) => ({
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  selectMenuContentValue: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    width: 50,
    paddingRight: 8,
  },
  selectMenuContentName: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: 269,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    marginLeft: 8,
  },
  placeholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',

    width: '100%',

    marginTop: 12,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
    display: 'flex',
    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  select: {
    '& .MuiSelect-outlined': {
      padding: 4,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 20,
  },
  min40Height: {
    minHeight: 40,
  },
  minWidthAnd40height: {
    minWidth: 416,
    minHeight: 40,
  },

  defaultChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  deleteChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    zIndex: 1501,
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  infoIcon: {
    color: '#0C77D8',
    marginLeft: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const CustomSelectWithChips = (props: CustomSelectProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [defaultList, setDefaultList]: any[] = React.useState([]);

  React.useEffect(() => {
    const defaultList = [];
    for (const item of props.items) {
      if (item.disabled == true) {
        defaultList.push(item.value);
      }
    }
    setDefaultList(defaultList);
  }, [props.items]);

  const handleDelete = (value: any) => {
    const newValue = props.state.filter((item) => item != value);
    props.setState(newValue);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, ProviderType and CareTeam
    let newValue = event.target.value;
    if (event.target.value.includes('All')) {
      if (!checkIncludesAll()) {
        newValue = oneClickSelectAll();
      } else {
        newValue = newValue.filter(
          (value: any) =>
            props.items.find((item: any) => item.value == value)?.disabled ==
            true
        );
      }
    }
    defaultList.forEach((item: any) => {
      if (!newValue.includes(item)) {
        newValue.push(item);
      }
    });
    props.setState(newValue);
  };
  const getIsDisabled = (value: string) => {
    return props.items.find((item: any) => item.value == value)?.disabled;
  };

  const checkIncludesAll = () => {
    for (const item of props.items) {
      if (!props.state.includes(item.value)) return false;
    }
    return true;
  };
  const oneClickSelectAll = () => {
    const newValue: any[] = [];
    props.items.forEach((item: any) => {
      newValue.push(item.value);
    });
    return newValue;
  };

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [droplistWidth, setDroplistWidth] = React.useState(0);
  React.useEffect(() => {
    function handleWindowResize() {
      if (anchorRef.current) {
        setDroplistWidth(Math.floor(anchorRef.current.offsetWidth * 0.972));
      }
    }

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.fullWidth ? '100%' : props.width || 203 }}
    >
      <FormControl className={classes.formControl}>
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={classes.inputTitle}
        >
          {props.inputTitle}
          <Tooltip title={TooltipSmsMessage} placement="bottom-start">
            <IconButton>
              <InfoIcon className={classes.infoIcon} />
            </IconButton>
          </Tooltip>
        </Typography>

        <Select
          multiple={props.isMultiple}
          variant="outlined"
          displayEmpty
          value={sortArrayBaseOnObjectArrayPipe(
            props.state,
            SmsLanguage,
            'asc'
          )}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          renderValue={(selected) => {
            if (isOpen) {
              return (
                <div className={classes.placeholderText}>Select Languages</div>
              );
            } else if (
              (selected as string[]).length === 0 ||
              (selected as string[]).includes('All')
            ) {
              return (
                <div className={classes.placeholderText}>
                  {props.placeholder}
                </div>
              );
            }

            return (selected as string[]).map((item) =>
              getIsDisabled(item) ? (
                <Chip className={classes.defaultChip} label={item} />
              ) : (
                <Chip
                  onMouseDown={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  onDelete={(event: any) => {
                    event.stopPropagation();
                    handleDelete(item);
                  }}
                  className={classes.deleteChip}
                  label={item}
                />
              )
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            elevation: 2,
            getContentAnchorEl: null,
            style: props.fullWidth
              ? {
                  maxHeight: 68 * 5 + 8,
                  maxWidth: '100%',
                }
              : {
                  maxHeight: 68 * 5 + 8,
                  width: props.showValue ? 416 : 250,
                },
            PaperProps: {
              style: props.fullWidth
                ? {
                    // reduce space between input and menu
                    marginTop: 1,
                    paddingLeft: 8,
                    paddingRight: 7,
                    maxWidth: '100%',
                    minWidth: droplistWidth,
                  }
                : {
                    // reduce space between input and menu
                    minWidth: props.width ? props.width - 10 : 192,
                    marginTop: 1,
                    paddingLeft: 8,
                    paddingRight: 7,
                    maxWidth: '100%',
                  },
            },
          }}
          className={classes.select}
          ref={anchorRef}
        >
          <MenuItem
            value="All"
            classes={{
              selected: classes.selected,
            }}
            className={
              props.showValue
                ? classes.minWidthAnd40height
                : classes.min40Height
            }
          >
            <Typography variant="body1" className={classes.selectMenuContent}>
              All
            </Typography>
            <StyledCheckbox
              checked={checkIncludesAll()}
              checkedAll={false}
              className={classes.checkBoxPosition}
            />
          </MenuItem>
          {props.items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={typeof item == 'string' ? item : item.value}
              classes={{
                selected: classes.selected,
              }}
              className={
                props.showValue
                  ? classes.minWidthAnd40height
                  : classes.min40Height
              }
            >
              {props.showValue && (
                <Typography
                  variant="body1"
                  className={classes.selectMenuContentValue}
                >
                  {typeof item == 'string' ? item : item.name}
                </Typography>
              )}
              <Typography
                variant="body1"
                className={
                  props.showValue
                    ? classes.selectMenuContentName
                    : classes.selectMenuContent
                }
              >
                {typeof item == 'string' ? item : item.name}
              </Typography>
              {item.disabled ? (
                <StyledCheckbox
                  checked={true}
                  checkedAll={false}
                  className={classes.checkBoxPosition}
                />
              ) : (
                <StyledCheckbox
                  checked={props.state.includes(
                    typeof item == 'string' ? item : item.value
                  )}
                  checkedAll={true}
                  className={classes.checkBoxPosition}
                />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
