import React from 'react';
import {
  Avatar,
  Button,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { PanelsIcon } from '../../../../../../assets/Icons/SideNavigation';
import { ButtonSmallSecondaryText } from '../../../../../../components/Buttons';
import { Link } from 'react-router-dom';
import { useCollapseButtonStyles } from '../../../../../../scenes/CareGaps/Home/tabs/Population/components/CareGapsDrawer/styles';
import { NavigateNextIcon } from '../../../../../../assets/Icons/CareGaps';
import moment from 'moment';
import { GlobalContext } from '../../../../../../components/GlobalContext';

interface NotificationCenterContentProps {
  items: any[];
  clearNotificationsHandler: (itemIds: string[]) => void;
}

export const NotificationCenterContent: React.FC<NotificationCenterContentProps> = ({
  items,
  clearNotificationsHandler,
}) => {
  /* 
  get all items and group by date
  Today
  Yesterday
  Oct 25, 2022
  ...

  GroupTitle   ExpandButton
  Item

  */
  const classes = useStyles();

  const [itemsByDate, setItemsByDate] = React.useState(
    new Map<string, any[]>()
  );
  React.useEffect(() => {
    const newItemsByDate = new Map<string, any[]>();

    items.forEach((item) => {
      const createdAt = new Date(item.createdAt);

      const date = new Date(createdAt).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });

      if (newItemsByDate.has(date)) {
        // Try to find an item in the array for the given date that has the same panelId and userId
        const findOne = newItemsByDate
          .get(date)
          ?.find(
            (prevItem: any) =>
              prevItem.content.panelId === item.content.panelId &&
              prevItem.createdBy.userId === item.createdBy.userId
          );
        if (!findOne) {
          // If no such item is found, add the new item to the array for the given date
          newItemsByDate.set(date, [...newItemsByDate.get(date)!, item]);
        } else {
          // If such an item is found, create a copy of it with an empty ids array
          const tmp = { ...findOne, ids: [] };
          // Merge the patientCount of the new item into the patientCount of the found item
          findOne.content.patientCount += item.content.patientCount;
          if (findOne.ids) {
            findOne.ids.push(item.id);
          } else {
            // If the found item doesn't have an ids array, set the ids array of the copy to contain the ids of the found item and the new item
            // ids will be used in Clear button click to clear the notifications
            tmp.ids = [findOne.id, item.id];
            const tmpItems = newItemsByDate
              .get(date)
              ?.filter((prevItem) => prevItem.id !== findOne.id);
            tmpItems?.push(tmp);
            newItemsByDate.set(date, tmpItems!);
          }
        }
      } else {
        newItemsByDate.set(date, [item]);
      }
      // }
    });

    let sortedKeys = Array.from(newItemsByDate.keys()).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );
    let sortedMap = new Map<string, any>();
    for (let key of sortedKeys) {
      sortedMap.set(key, newItemsByDate.get(key));
    }
    setItemsByDate(sortedMap);
  }, [items]);

  // console.log('itemsByDate =>', itemsByDate);
  // console.log('items =>', items);

  return (
    <div id="NotificationCenterContent" className={classes.root}>
      {[...itemsByDate.entries()].map(([date, items]) => (
        <NotificationCenterItemGroup
          key={date}
          date={date}
          items={items}
          clearNotificationsHandler={clearNotificationsHandler}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 3),
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    overflowY: 'auto',
  },
  groupRoot: {},
  groupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  groupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  groupHeaderText: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 700,
    color: theme.palette.grey[700],
  },
  groupItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  itemRoot: {},
  itemContainer: {
    padding: theme.spacing(1, 1.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0.5),
  },
  itemInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
  },
  itemIconAvatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.light,
  },
  itemIcon: {
    width: 20,
    height: 20,
    color: theme.palette.avatars.cyan,
  },
  itemType: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.grey[700],
  },
  itemCreatedTime: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.grey[500],
  },
  itemAction: {},
  itemContent: {
    padding: theme.spacing(1.5, 2, 1, 2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 6,
  },
  itemContentTextRegular: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[600],
  },
  itemContentTextLink: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

interface NotificationCenterItemGroupProps {
  date: string;
  items: any[];
  clearNotificationsHandler: (itemIds: string[]) => void;
}
const NotificationCenterItemGroup: React.FC<NotificationCenterItemGroupProps> = ({
  date,
  items,
  clearNotificationsHandler,
}) => {
  const classes = useStyles();
  const [expandAll, setExpandAll] = React.useState(true);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setExpandAll(!expandAll);
  };

  const covertDate = (date: string) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (today.toDateString() === new Date(date).toDateString()) {
      return 'Today';
    } else if (yesterday.toDateString() === new Date(date).toDateString()) {
      return 'Yesterday';
    } else {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    }
  };
  return (
    <div className={classes.groupRoot}>
      <div key={date} className={classes.groupWrapper}>
        <div className={classes.groupHeader}>
          <Typography className={classes.groupHeaderText}>
            {covertDate(date)}
          </Typography>
          <CollapseButton expandAll={expandAll} handleClick={handleClick} />
        </div>
        {expandAll && (
          <div className={classes.groupItemContainer}>
            {items.map((item) => (
              <NotificationCenterPanelItem
                key={item.id}
                item={item}
                clearNotificationsHandler={clearNotificationsHandler}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface NotificationCenterPanelItemProps {
  item: any;
  clearNotificationsHandler: (itemIds: string[]) => void;
}
const NotificationCenterPanelItem: React.FC<NotificationCenterPanelItemProps> = ({
  item,
  clearNotificationsHandler,
}) => {
  const { handleNotificationCenter } = React.useContext(GlobalContext);
  const classes = useStyles();

  const getMessageType = (type: string) => {
    switch (type) {
      case 'panel':
        return 'Panel Notification';
      default:
        return 'Notification';
    }
  };

  return (
    <div className={classes.itemRoot}>
      {item.cleared ? null : (
        <div className={classes.itemContainer}>
          <div className={classes.itemHeader}>
            <div className={classes.itemInfo}>
              <Avatar className={classes.itemIconAvatar}>
                <PanelsIcon className={classes.itemIcon} />
              </Avatar>
              <div>
                <Typography className={classes.itemType}>
                  {getMessageType(item.notificationType)}
                </Typography>
                <Typography className={classes.itemCreatedTime}>
                  {moment(item.createdAt).format('MMM DD, YYYY, hh:mm A')}
                </Typography>
              </div>
            </div>
            <div className={classes.itemAction}>
              <ButtonSmallSecondaryText
                onClick={() => {
                  if (item.ids) {
                    // means it's a group of notifications
                    clearNotificationsHandler(item.ids);
                  } else {
                    clearNotificationsHandler([item.id]);
                  }
                }}
              >
                Clear
              </ButtonSmallSecondaryText>{' '}
            </div>
          </div>
          <div className={classes.itemContent}>
            <Typography className={classes.itemContentTextRegular}>
              {item.content.patientCount} patients have been assigned to you in{' '}
              <Link
                className={classes.itemContentTextLink}
                to={`/panels/${item.content.panelId}/assigned`}
                onClick={handleNotificationCenter}
              >
                {item.content.panelName}
              </Link>{' '}
              by {item.createdBy.firstName} {item.createdBy.lastName}. You can
              start care coordination now.
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

// - collapse / expand button
interface CollapseButtonProps {
  expandAll: boolean;
  handleClick: (event: any) => any;
}

const CollapseButton = (props: CollapseButtonProps) => {
  const { expandAll, handleClick } = props;
  const classes = useCollapseButtonStyles();

  return (
    <Button
      disableRipple
      className={classes.root}
      variant="text"
      onClick={handleClick}
      endIcon={
        <NavigateNextIcon
          className={
            expandAll
              ? `${classes.navigateNext} ${classes.rotateNavigateNext}`
              : `${classes.navigateNext}`
          }
        />
      }
    >
      <Typography className={classes.text}>
        {expandAll ? 'Collapse' : 'Expand'}
      </Typography>
    </Button>
  );
};
