// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { GlobalContext } from '../../components/GlobalContext';
import { LoginForm } from './components/LoginForm/LoginForm';
import LoginBackground from '../../assets/Login/LoginBackground.jpg';
import LogoWithAppNameWhite from '../../assets/Login/LogoWithAppNameWhite.svg';
import MdlandLogo from '../../assets/Login/MdlandLogo.svg';
import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';
import { SuccessSnackbar } from './components/SuccessSnackbar/SuccessSnackbar';
import { NewPasswordForm } from './components/NewPasswordForm/NewPasswordForm';
import { config } from '../../config';

export type PropTypes = {};
const versionNumber = config.versionNumber;

const useStyles = makeStyles((theme) => ({
  root: {
    width: document.width,
    height: document.height,
    display: 'flex',
  },
  leftContainer: {
    minHeight: '100vh',
    width: '56.39%',
    backgroundImage: `url(${LoginBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    width: '43.61%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  title: {
    textAlign: 'center',
    fontSize: theme.spacing(6),
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(9.75),
    paddingBottom: theme.spacing(4.25),
  },
  leftFooter: {
    position: 'absolute',
    bottom: 56,
    display: 'flex',
    alignItems: 'center',
  },
  leftFooterText: {
    fontSize: 14,
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  rightFooterText: {
    paddingTop: theme.spacing(4),
    fontSize: 12,
    color: theme.palette.grey[500],
  },
}));

export const Forms = {
  Login: 'Login',
  ResetPw: 'ResetPw',
  NewPw: 'NewPw',
};

export const LoginView: React.FC<PropTypes> = (props: PropTypes) => {
  const classes = useStyles();
  const { loggedInUser } = useContext(GlobalContext);
  const location = useLocation();
  const [renderForm, setRenderForm] = useState<string>(Forms.Login);
  const [cognitoUser, setCognitoUser] = useState<object>({});
  const [snackbar, setSnackbar] = useState<boolean>(false);

  const getYear = () => {
    try{
      return new Date().getFullYear();
    }
    catch(e: any){
      return '2024'
    }
  }

  const { from } = (location?.state as any) || {
    from: { pathname: '/dashboard' },
  };

  if (loggedInUser) {
    return <Navigate to={from} />;
  }

  return (
    <div className={classes.root}>
      <SuccessSnackbar
        open={snackbar}
        setOpen={setSnackbar}
        message="Your password has been updated!"
      />
      <div className={classes.leftContainer}>
        <img src={LogoWithAppNameWhite} alt="iPopHealth" />
        <div className={classes.leftFooter}>
          <Typography className={classes.leftFooterText}>Powered by</Typography>
          <img src={MdlandLogo} alt="MDLand" />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Typography className={classes.title}>
          {renderForm === Forms.Login && 'Sign in'}
          {renderForm === Forms.ResetPw && 'Reset your password'}
          {renderForm === Forms.NewPw && 'Set new password'}
        </Typography>
        {renderForm === Forms.Login && (
          <LoginForm
            setRenderForm={setRenderForm}
            setCognitoUser={setCognitoUser}
          />
        )}
        {renderForm === Forms.ResetPw && (
          <ResetPasswordForm
            setRenderForm={setRenderForm}
            setSnackbar={setSnackbar}
          />
        )}
        {renderForm === Forms.NewPw && (
          <NewPasswordForm
            setRenderForm={setRenderForm}
            cognitoUser={cognitoUser}
          />
        )}
        <Typography className={classes.rightFooterText}>
          Copyright <span>&copy;</span> MDLand {getYear()}
        </Typography>
        <Typography className={classes.rightFooterText}>
          {versionNumber}
        </Typography>
      </div>
    </div>
  );
};
