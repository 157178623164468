import React from 'react';
import { PatientFilterComponentIDs } from '../../../../../../../enum/store';
import { useFetchTableData } from '../../../../../../../hooks/useFetchTableData';
import { usePatientFilterStore } from '../../../../../../../store/features/patientFilter';
import { usePanelPatientsMeQuery } from '../../../../../../Panels/ViewPanels/PanelPatients/index.generated';
import { CareGapPatientNotFound } from '../CareGapPatientTableNotFound';
import { CareGapPatientTableView } from './View';

export interface CareGapFilter {
  patientGender: string | undefined;
  patientAge: string | undefined;
  lastDOS: Date | undefined;
  riskScore: string | undefined;
  provider: string | undefined;
  careGaps: string[] | undefined;
  careGapStatus: string | undefined;
  engagementStatus: string | undefined;
  diseaseAndTags: string | undefined;
  futureAppointment: string | undefined;
}

type Props = {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  availableActions?: string[];
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  setResults: (input: any) => void;
  showMyOwn: string;
};

export const CareGapPatientTableContainer = (props: Props) => {
  const {
    limit,
    skip,
    setLimit,
    setSkip,
    setLoading,
    setDisplayedCount,
    sortBy,
    setSortBy,
    setResults,
  } = props;

  const [selected, setSelected] = React.useState<any[]>([]);

  const patientFilterStore = usePatientFilterStore(
    props.showMyOwn === 'YES'
      ? PatientFilterComponentIDs.MY_PATIENTS
      : PatientFilterComponentIDs.ALL_PATIENTS
  );
  const tmpFilters = patientFilterStore.formattedFiltersForQuery;
  const filters = {
    ...tmpFilters,
    skip: skip,
    limit: limit,
    sortBy: [{ ...sortBy }],
  };

  React.useEffect(() => {
    setSkip(0);
  }, [tmpFilters, limit]);

  const patients = usePanelPatientsMeQuery({
    variables: {
      input: {
        ...filters,
        showMyOwn: props.showMyOwn,
      },
    },
  });
  React.useEffect(() => {
    if (!patients.called || patients.loading) {
      setLoading(true);
    } else {
      setLoading(false);
      setDisplayedCount(patients.data?.getPatientByCareTeamUserId.count ?? 0);
      setResults(patients.data?.getPatientByCareTeamUserId.results || []);
    }
  }, [patients]);
  const queryResult = useFetchTableData(patients);

  if (!patients.called || patients.loading) {
    return <></>;
  }

  return (
    <div style={{ width: '100%' }}>
      {patients?.data?.getPatientByCareTeamUserId!.count == 0 ? (
        <CareGapPatientNotFound isSearching={false} />
      ) : (
        <CareGapPatientTableView
          refetchDataFunc={{
            func: patients.refetch,
            params: {
              ...filters,
              showMyOwn: props.showMyOwn,
            },
          }}
          sortBy={sortBy}
          setSortBy={setSortBy}
          patients={
            queryResult ? queryResult.getPatientByCareTeamUserId.results : []
          }
          limit={limit ?? 10}
          skip={skip ?? 0}
          setLimit={setLimit}
          setSkip={setSkip}
          count={
            queryResult ? queryResult.getPatientByCareTeamUserId!.count : 0
          }
          setSelected={setSelected}
          selected={selected}
        />
      )}
    </div>
  );
};
