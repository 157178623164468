import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../../../../../themes/theme';

const AVATAR_BG = Object.values(theme.palette.avatars);

export const useChangeCoordinatorSelectStyles = makeStyles((theme: Theme) => ({
  patientOverFlowErrorMessage: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main,
    lineHeight: '20px',
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    width: 12,
    height: 12,
    textAlign: 'center',
  },
  addIconBtn: {
    width: 24,
    height: 24,
    minWidth: 24,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    padding: 0,

    '& span.MuiButton-startIcon': {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  currentlyAssigned: {
    marginTop: `${theme.spacing(1.5)}px !important`,
    marginBottom: `${theme.spacing(1.5)}px !important`,

    '& > li > div > div:first-child': {
      height: 'fit-content',
    },
  },
  title: {
    fontSize: 14,
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
  },
  // error message
  errorMessage: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.625),
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  // shared select styles
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',
    width: '100%',
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
  },
  // AUTOCOMPLETE STYLES
  autoComplete: {
    display: 'flex',
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.25, 0, 0.25, 1),
      minHeight: 40,
      fontSize: 16,
      borderRadius: 8,
      marginBottom: 24,
    },
    '& ::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  acMenuRoot: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    height: 56,
    borderRadius: 8,
  },
  acMenuSelected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    width: '100%',
  },
  acOption: {
    height: 54,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0, 1, 0.5, 1),
    backgroundColor: 'white',
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
    },
    '& ::hover': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
  },
  noAcOption: {
    fontSize: 14,
    fontWeight: 600,
  },
  list: {
    margin: theme.spacing(1.5, 0, 1.5, 0),
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

    '& .MuiListItem-root': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    '& li .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },

    '& > li > div > div:first-child': {
      height: 'fit-content',
    },
  },
  listItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
  },
}));
