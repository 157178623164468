import React from 'react';
import { PanelPatient } from '../../../../../../app.types.generated';
import { KeyPatientsType } from '../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';

export const useDisabledSwitchArrowButton = (
  allPatients: PanelPatient[],
  currentPatientId: string,
  currentPage: number,
  skip: number,
  setSkip: (input: number) => void,
  limit: number,
  totalCount: number,
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>
) => {
  const [disabledArrowBackwardBtn, setDisabledArrowBackwardBtn] =
    React.useState(false);
  const [disabledArrowForwardBtn, setDisabledArrowForwardBtn] =
    React.useState(false);

  React.useEffect(() => {
    const currentPatientIndex = allPatients.findIndex(
      (item) => item.id === currentPatientId
    );

    if ((!skip || skip === 0) && currentPatientIndex === 0) {
      // first in all, cannot go to previous page
      setDisabledArrowBackwardBtn(true);
    }
    if (currentPatientIndex === allPatients.length - 1) {
      if (skip + limit >= totalCount) {
        // the last page, cannot go to next page anymore
        setDisabledArrowForwardBtn(true);
      }
    }
  }, [skip, limit, totalCount, currentPatientId, allPatients]);

  const handleSwitchPatient = (event: React.MouseEvent) => {
    const eventAny = event as any;
    const currentPatientIndex = allPatients.findIndex(
      (item) => item.id === currentPatientId
    );

    if (eventAny?.currentTarget?.role === 'prevPatient') {
      if (currentPatientIndex === 0) {
        if (!disabledArrowBackwardBtn) {
          // go to previous page
          setSkip((currentPage - 1) * limit);
        }
      } else {
        // go to previous patient without turning page
        setKeyPatients((oldValue) => {
          return {
            ori: allPatients[currentPatientIndex - 1],
            pre: oldValue.cur,
            cur: allPatients[currentPatientIndex - 1],
          };
        });
      }
    } else if (eventAny?.currentTarget?.role === 'nextPatient') {
      if (currentPatientIndex === allPatients.length - 1) {
        if (!disabledArrowForwardBtn) {
          // go to next page
          setSkip((currentPage + 1) * limit);
        }
      } else {
        // go to next patient without turning page
        setKeyPatients((oldValue) => {
          return {
            ori: allPatients[currentPatientIndex + 1],
            pre: oldValue.cur,
            cur: allPatients[currentPatientIndex + 1],
          };
        });
      }
    }
  };

  const handleSwitchNextPatient = () => {
    const currentPatientIndex = allPatients.findIndex(
      (item) => item.id === currentPatientId
    );
    if (currentPatientIndex === allPatients.length - 1) {
      if (!disabledArrowForwardBtn) {
        // go to next page
        setSkip((currentPage + 1) * limit);
      }
    } else {
      // go to next patient without turning page
      setKeyPatients((oldValue) => {
        return {
          ori: allPatients[currentPatientIndex + 1],
          pre: oldValue.cur,
          cur: allPatients[currentPatientIndex + 1],
        };
      });
    }
  };

  return {
    disabledArrowBackwardBtn,
    disabledArrowForwardBtn,
    handleSwitchNextPatient,
    handleSwitchPatient,
  };
};
