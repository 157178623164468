import React from 'react';
import { MenuItem, Popper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { theme } from '../../themes/theme';
import '../../expanded-theme';
import { Autocomplete } from '@material-ui/lab';
import { StyledCheckbox } from '../StyledCheckbox';
import CustomPopper from './CustomPoper';

const AVATAR_BG = Object.values(theme.palette.avatars);

interface CustomSelectProps {
  state: any;
  type: string;
  setState: any;
  isMultiple: boolean;
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  hasAvatar?: boolean;
  disabled?: boolean;
  source?: string;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      borderRadius: 8,
    },
  },
  selectMenuContentValue: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: 16,
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    margin: 0,
    padding: 0,
    marginRight: 40,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  acMenuRoot: {
    fontSize: 14,
    fontWeight: 600,
    height: 56,
    borderRadius: 8,
    position: 'relative',
    width: '100%',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',

    width: '100%',

    marginTop: 12,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  avatarContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.hint,
  },
  flexContainer: {
    display: 'flex',
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  acOption: {
    padding: theme.spacing(0, 1, 0.5, 1),
    backgroundColor: 'white',
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
    },
    '& ::hover': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
  },
  noAcOption: {
    fontSize: 14,
    fontWeight: 600,
  },
  autoComplete: {
    display: 'flex',
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.25, 0, 0.25, 1),
      minHeight: 40,
      fontSize: 16,
      borderRadius: 8,
      marginBottom: 24,
      '& .MuiAutocomplete-input': {
        paddingRight: 0,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: theme.spacing(1),
      },
    },
    '& ::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 5,
  },
  customTextField: {
    '& input::placeholder': {
      fontWeight: 500,
      color: '#ABABAB',
    },
    '& > div:first-child': {
      paddingRight: `60px!important`,
    },
  },
  selectedCustomTextField: {
    '& input::placeholder': {
      fontWeight: 500,
      color: '#656565',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const CareGapAutoCompleteSelectorNoChips = (
  props: CustomSelectProps
) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const matchSelectedProvider = (value: any) => {
    let newValue = value;
    if (
      value?.find((item: any) => item.value == 'All') &&
      !props.state.find((item: any) => item.value == 'All')
    ) {
      newValue = [{ value: 'All', name: 'All' }];
    } else if (props.state.find((item: any) => item.value == 'All')) {
      if (value.find((item: any) => item.value == 'All')) {
        newValue = [];
        props.items.forEach((selectedItem: any) => {
          if (!value?.find((item: any) => item.value == selectedItem.value)) {
            newValue.push(selectedItem);
          }
        });
      } else {
        newValue = newValue.filter((value: any) => value.value != 'All');
      }
    } else if (value?.length == props.items?.length) {
      newValue = [{ value: 'All', name: 'All' }];
    }
    props.setState(props.type, newValue);
  };

  const changeOptionBaseOnValue = (newValue: string) => {
    setInputValue(newValue);
  };
  const allOption = {
    value: 'All',
    name: 'All',
  };
  const filterOptions = (options: any, state: any) => {
    let isSearch = inputValue.length >= 2;
    let allSelected = props.state.find((item: any) => item.value == 'All');
    if (!isSearch && props.state?.length == 0) {
      return [];
    }
    options.sort(function (a: any, b: any) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    const injectOptions: any[] = [];
    injectOptions.push(allOption);
    const inputValueLower = inputValue.toLowerCase();
    for (const option of options) {
      if (
        allSelected ||
        props.state.find((item: any) => item.value == option.value)
      ) {
        injectOptions.push(option);
      } else if (isSearch) {
        if (
          option?.value?.replace(' ', '')
            .toLowerCase()
            .includes(inputValueLower) ||
          option?.name?.replace(' ', '').toLowerCase().includes(inputValueLower)
        ) {
          injectOptions.push(option);
        }
      }
    }

    return injectOptions;
  };

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.width ?? '100%' }}
    >
      <div className={classes.formControl}>
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={classes.inputTitle}
        >
          {props.inputTitle}
        </Typography>

        <Autocomplete
          options={props.items}
          className={classes.autoComplete}
          getOptionLabel={(option) => option.value ?? ''}
          onInputChange={(event: object, value: string, reason: string) => {
            if (reason === 'input') {
              changeOptionBaseOnValue(value);
            }
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
            setInputValue('');
          }}
          disableCloseOnSelect={true}
          filterOptions={filterOptions}
          value={props.state}
          getOptionSelected={(option, value) => option.value == value?.value}
          popupIcon={<KeyboardArrowDown />}
          closeIcon={false}
          inputValue={inputValue}
          renderTags={() => null}
          onChange={(e, v) => {
            matchSelectedProvider(v);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              onFocus={() => setOpen(true)}
              classes={{
                root:
                  props.state?.length > 0
                    ? classes.selectedCustomTextField
                    : classes.customTextField,
              }}
              placeholder={
                props.state?.length > 0
                  ? props.state.find((item: any) => item.value == 'All')
                    ? 'All'
                    : `${props.state?.length} selected`
                  : props.placeholder ?? 'Search and Select Care Gap(s)'
              }
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          classes={{
            tag: classes.chip,
            option: classes.acOption,
            noOptions: classes.noAcOption,
          }}
          noOptionsText={
            inputValue?.length < 2
              ? 'Type Care Gap Code(s) or Name to Search'
              : 'No matching Care Gaps'
          }
          renderOption={(option, { selected }) => (
            <MenuItem
              key={option}
              value={option}
              selected={
                selected || props.state.find((item: any) => item.value == 'All')
              }
              style={{ width: '100%' }}
              classes={{ root: classes.listItem, selected: classes.selected }}
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <div className={classes.selectMenuContentValue}>
                  {option.value}
                </div>
                {option.value !== 'All' && (
                  <div className={classes.selectMenuContent}>{option.name}</div>
                )}
              </div>
              <StyledCheckbox
                checked={
                  (selected ||
                    props.state.find((item: any) => item.value == 'All')) ??
                  false
                }
                checkedAll={true}
                className={classes.checkBoxPosition}
              />
            </MenuItem>
          )}
          PopperComponent={
            props.source && props.source === 'All Patients Filters'
              ? CustomPopper
              : undefined
          }
        />
      </div>
    </div>
  );
};
