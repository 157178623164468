import { Fade, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import {
  CallIcon,
  LockIcon,
} from '../../../../../../../../../assets/Icons/CareGaps';
import { PatientFaceSheet } from '../../../CareGapsDrawer/Container';
import { usePatientInfoViewStyles } from '../../Styles';
import { Feature } from '../../../../../../../../../components/FeatureFlag';
import { convertRoleName } from '../../../../../../../../../sharePipe/convertRoleNamePipe';

interface PhoneInfoDetailProps {
  showLockIcon: boolean;
  patientData: PatientFaceSheet;
  itemId: string;
  result: any;
}

export const PhoneInfoDetail = (props: PhoneInfoDetailProps) => {
  const { showLockIcon, patientData, itemId, result } = props;
  const classes = usePatientInfoViewStyles();
  const tooltipText = [
    `${convertRoleName(patientData.careLockInfo?.updatedBy!.roleName!)} ${
      patientData.careLockInfo?.updatedBy!.firstName
    } ${
      patientData.careLockInfo?.updatedBy!.lastName
    } is working on this patient at the moment.`,
    'To avoid duplicate outreach, we limited your access.',
  ];
  return (
    <Feature name="accessLockLimitation">
      {(hasFeature) =>
        hasFeature ? (
          <div className={classes.flexCenterEnd5}>
            {showLockIcon ? (
              <StyledTooltip
                placement="bottom-start"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={tooltipText.join('\n')}
              >
                <span>
                  <LockIcon className={classes.readOnlyChipIcon} />
                </span>
              </StyledTooltip>
            ) : (
              <>
                <CallIcon className={classes.callIcon} />
                <Typography className={classes.itemTextRightExceptPanels}>
                  {patientData.hasOwnProperty(itemId) ? result : '-'}
                </Typography>
              </>
            )}
          </div>
        ) : (
          <div className={classes.flexCenterEnd5}>
            <CallIcon className={classes.callIcon} />
            <Typography className={classes.itemTextRightExceptPanels}>
              {patientData.hasOwnProperty(itemId) ? result : '-'}
            </Typography>
          </div>
        )
      }
    </Feature>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 360,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '6px 12px',
    lineHeight: '20px',
  },
  popper: {
    top: '-30px !important',
    left: '-45px !important',
  },
})(Tooltip);
