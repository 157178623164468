import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PanelSummaryView } from './View';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { useGetPatientEngagementBatchsCountQuery } from './index.generated';
import { GlobalContext } from '../../../../components/GlobalContext';
import { RoleType } from '../../../../enum/Users';
import { PanelSummaryContext } from './components/PanelSummaryContext/PanelSummaryContext';

export const PanelSummaryContainer = () => {
  const { id, type } = useParams();
  const [refresh, setRefresh] = React.useState(0);
  // const [hotFixReload, setHotFixReload] = React.useState(0);
  const { loggedInUser } = React.useContext(GlobalContext);

  function hardRefresh() {
    setRefresh(refresh + 1);
  }

  const { panelSummaryState } = React.useContext(PanelSummaryContext);

  const engagementCount = useGetPatientEngagementBatchsCountQuery({
    variables: {
      input: {
        limit: null,
        skip: refresh,
        panelId: id,
      },
    },
  });

  const [engagementTotalCount, setEngagementTotalCount] = useState<number>(0);
  useEffect(() => {
    setEngagementTotalCount(
      engagementCount.data?.getPatientEngagementBatchs?.pagingInfo?.count ?? 0
    );
  }, [engagementCount.data?.getPatientEngagementBatchs?.pagingInfo?.count]);

  if (panelSummaryState.loading) {
    return <LoadingSpinner />;
  }

  let tabValue = 0;
  switch (type) {
    case 'summary':
      tabValue = 0;
      break;
    case 'assigned':
      tabValue = 1;
      break;
    case 'unassigned':
      tabValue = 2;
      break;
    case 'deListed':
      tabValue = 3;
      break;
    case 'engagement':
      tabValue = 4;
      break;
    default:
      break;
  }

  const getSourceInoAndTabObjectsByRoleType = (
    roleName: string | undefined
  ) => {
    switch (roleName) {
      case RoleType.ADMIN:
      case RoleType.CARE_MANAGER:
      case RoleType.MSO_ADMIN:
        return {
          sourceInfo: {
            sidebarMenu: 'panels',
            rootLink: '/panels',
            rootName: 'Patient Panels',
            childLink: `/panels/listing`,
            childName: 'All Panels',
          },
          tabObjects: [
            {
              tabIndex: 0,
              tabLabel: 'Summary',
              tabUrl: `/panels/${id}/summary`,
              tabBadgeCount: null,
              disabled: false,
            },
            {
              tabIndex: 1,
              tabLabel: 'Assigned Patients',
              tabUrl: `/panels/${id}/assigned`,
              tabBadgeCount: panelSummaryState.panelInfo.assignedPatientCount,
              disabled: false,
            },
            {
              tabIndex: 2,
              tabLabel: 'Unassigned',
              tabUrl: `/panels/${id}/unassigned`,
              tabBadgeCount: panelSummaryState.panelInfo.unassignedPatientCount,
              disabled: false,
            },
            {
              tabIndex: 3,
              tabLabel: 'De-Listed',
              tabUrl: `/panels/${id}/deListed`,
              tabBadgeCount: panelSummaryState.panelInfo.delistedPatientCount,
              disabled: false,
            },
            {
              tabIndex: 4,
              tabLabel: 'Batch SMS Log',
              tabUrl: `/panels/${id}/engagement`,
              tabBadgeCount: engagementTotalCount,
              disabled: false,
            },
          ],
        };

      case RoleType.CARE_COORDINATOR:
        return {
          sourceInfo: {
            sidebarMenu: 'panels',
            rootLink: '/panels',
            rootName: 'My Patient Panels',
            childLink: `/panels/listing`,
            childName: 'My Panels',
          },
          tabObjects: [
            {
              tabIndex: 0,
              tabLabel: 'Summary',
              tabUrl: `/panels/${id}/summary`,
              tabBadgeCount: null,
              disabled: false,
            },
            {
              tabIndex: 1,
              tabLabel: 'Assigned Patients',
              tabUrl: `/panels/${id}/assigned`,
              tabBadgeCount: panelSummaryState.panelInfo.patientCount,
              disabled: false,
            },
            {
              tabIndex: 3,
              tabLabel: 'De-Listed',
              tabUrl: `/panels/${id}/deListed`,
              tabBadgeCount: panelSummaryState.panelInfo.delistedPatientCount,
              disabled: false,
            },
            {
              tabIndex: 4,
              tabLabel: 'Batch SMS Log',
              tabUrl: `/panels/${id}/engagement`,
              tabBadgeCount: engagementTotalCount,
              disabled: false,
            },
          ],
        };
    }
  };

  return (
    <PanelSummaryView
      panel={panelSummaryState.panelInfo}
      type={tabValue}
      hardRefresh={hardRefresh}
      // key={hotFixReload}
      sourceInfo={
        getSourceInoAndTabObjectsByRoleType(loggedInUser?.role?.roleName)!
          .sourceInfo
      }
      tabObjects={
        getSourceInoAndTabObjectsByRoleType(loggedInUser?.role?.roleName)!
          .tabObjects
      }
      notEngagedCount={panelSummaryState.panelInfo.notEngagedCount || 0}
      nofutureApptCount={panelSummaryState.panelInfo.nofutureApptCount || 0}
      unassignedPatientCount={
        panelSummaryState.panelInfo.unassignedPatientCount || 0
      }
    />
  );
};
