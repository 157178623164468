import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Card, Chip, Paper, Tooltip, Typography} from "@material-ui/core";
import {useGetEligibilityPatientListQuery} from "../../index.generated";
import {EligibilityCategory} from "../../../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import {LoadingSpinner} from "../../../../../components/LoadingSpinner";
import moment from 'moment'
import TableToolbar from "../TableHeaderToolbar/View";
import {ReasonFilter} from "../ReasonFilter";
import {useStickyTableStyle} from "../../../../../components/TabBar/Styles";

interface EligibilityPatientInfo {
  patientName: string,
  gender: string,
  dob: string,
  lineOfBusiness: string,
  pcp: string,
  pcpShortName: string,
  rejectedReason: string,
}

type ColumnID = 'patientName' | 'gender' | 'dob' | 'lineOfBusiness' | 'pcp' | 'rejectedReason'

interface Column {
  id: ColumnID;
  label: string;
  align?: 'right' | 'left';
}

const columns: Column[] = [
  { id: 'patientName', label: 'Patient Name', align: 'left'},
  { id: 'gender', label: 'Gender', align: 'left'},
  { id: 'dob', label: 'DOB', align: 'left'},
  { id: 'lineOfBusiness', label: 'Line of Business',  align: 'left'},
  // { id: 'pcp', label: 'PCP',  align: 'left'},
];

const columnsRejected: Column[] = [
  { id: 'patientName', label: 'Patient Name', align: 'left'},
  { id: 'gender', label: 'Gender', align: 'left'},
  { id: 'dob', label: 'DOB', align: 'left'},
  { id: 'lineOfBusiness', label: 'Line of Business',  align: 'left'},
  // { id: 'pcp', label: 'PCP',  align: 'left'},
  { id: 'rejectedReason', label: 'Unverified Reason',  align: 'left'},
];

interface IProps {
  rejectedReasonCategoryList?: Array<string>
  category: EligibilityCategory,
  skip: number,
  limit: number,
  setSkip: (input: number) => void,
  setLimit: (input: number) => void,
  onComplete: (totalCount: number, isLoading: boolean) => void
}


export default function EligibilityTable(props: IProps) {
  const {skip, limit, setLimit, setSkip, category, rejectedReasonCategoryList, onComplete} = props
  const classes = useStyles();
  const stickyTableClasses = useStickyTableStyle();

  const getEligibilityPatientListQuery = useGetEligibilityPatientListQuery({
    variables: {
      input: {
        category: category,
        skip: skip,
        rejectedReasonCategoryList: rejectedReasonCategoryList && rejectedReasonCategoryList.length ? rejectedReasonCategoryList : undefined,
        limit: limit
      }
    }
  })

  if (!getEligibilityPatientListQuery.called || getEligibilityPatientListQuery.loading) {
    onComplete && onComplete(0, true)
    return <LoadingSpinner style={{width: '100%', marginTop: 80}}/>
  }

  const getInitials = (input: string) => {
    if (!input.length) {
      return ""
    }
    let names = input.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const patientCount = getEligibilityPatientListQuery!.data!.getEligibilityPatientList!.pagingInfo!.count
  onComplete && onComplete(patientCount ?? 0, false)
  const dataSource: Array<EligibilityPatientInfo> = getEligibilityPatientListQuery!.data!.getEligibilityPatientList!.results!.map((item) => {
    const {
      patientName,
      gender,
      dob,
      lineOfBusiness,
      pcp,
      rejectedReason
    } = item

    const pcpName = pcp ? pcp.trim() : ""
    const fDob = dob ? moment(dob, "YYYY-MM-DD").format('MM/DD/YYYY') : ''
    const pcpShortName = getInitials(pcpName ?? "")

    let formatRejectReason = ""
    if (rejectedReason) {
      const number = rejectedReason.split(' ')[0]
      if (!!Number(number)) {
        const reason = rejectedReason.replace(`${number} `, "")
        formatRejectReason = `Code${number} - ${reason}`
      } else {
        formatRejectReason = rejectedReason
      }
    }
    const infoItem: EligibilityPatientInfo = {
      dob: fDob, gender: gender ?? "", lineOfBusiness: lineOfBusiness ?? "", patientName: patientName ?? "", pcp: pcpName, pcpShortName: pcpShortName,
      rejectedReason: formatRejectReason
    }
    return infoItem
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
  };

  const renderCellContentByColumnId = (cid: ColumnID, log: EligibilityPatientInfo) => {
    const value = log[cid]
    if (cid === 'patientName' || cid === 'gender' || cid === 'dob') {
      return <Typography className={classes.cellText}>{value}</Typography>
    } else if (cid === 'rejectedReason') {
      return value && value.length ?
        <Tooltip
          classes={{ tooltip: classes.toolTips }}
          title={value}
          placement="bottom-end"
        >
          <Chip
            label={value}
            className={classes.reasonCellText}
          />
        </Tooltip>
       : null
    } else if (cid === 'pcp') {
      if (value && value.length) {
        return <div className={classes.cellTextContainer}>
          <div className={classes.pcpContainer}>
            <div className={classes.pcpShortNameContainer}>
              <span className={classes.pcpShortName}>{log.pcpShortName}</span>
            </div>
            <Typography className={classes.cellText}>{value}</Typography>
          </div>
        </div>
      } else {
        return <Typography className={classes.placeholderText}>No PCP</Typography>
      }
    } else if (cid === 'lineOfBusiness') {
      if (value && value.length) {
        return (
          <Chip label={value} className={classes.chip}/>
        )
      } else {
        return <Typography className={classes.placeholderText}>No LOB</Typography>
      }
    }
  }

  const columnsForHeader = category !== EligibilityCategory.unverified ? columns : columnsRejected
  const currentPage = Math.floor(skip / limit);

  return (
    <Paper className={classes.root}>
      <div style={{position: 'relative'}}>
        <TableContainer classes={{root: stickyTableClasses.customTableContainer}}>
          <Table aria-labelledby="tableTitle"
                 aria-label="enhanced table"
                 stickyHeader>
            <TableHead >
              <TableRow className={classes.header}>
                {columnsForHeader.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.headerCell}
                    style={{ minWidth: 170 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource.map((row, idx) => {
                return (
                  <TableRow className={classes.row} hover role="checkbox" tabIndex={-1} key={idx}>
                    {columnsForHeader.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}  style={{ minWidth: 170, }} className={classes.cell}>
                          {renderCellContentByColumnId(column.id, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={patientCount ?? 0}
          rowsPerPage={limit}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="items per page"
          className={classes.pagination}
        />
      </div>
    </Paper>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tableContainer: {
    overflowX: 'initial',
  },
  reasonCellText: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,
    maxWidth: 240,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  header: {
    backgroundColor: '#F8F8F8',
  },
  headerCell: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    color: '#888888',
  },
  row: {
    height: 56,
  },
  cell: {
    paddingLeft: 24,
  },
  cellText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500,
  },
  placeholderText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#ABABAB',
    fontWeight: 500,
  },
  cellNumberText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500,
    textUnderlineOffset: '4px',
    textDecorationThickness: '2px',
    textDecoration: 'underline',
    textDecorationColor: '#b3b1b1',
  },
  cellTextContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  pcpContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pcpShortNameContainer: {
    width: 24,
    height: 24,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
    backgroundColor: '#F5CA32',
  },
  pcpShortName: {
    fontSize: 10,
    lineHeight: '14px',
    color: '#FFF',
    fontWeight: 700,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
      {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
    '& p[id]': {
      marginRight: theme.spacing(5.75),
    },
  },
  toolTips: {
    maxWidth: 200,
  }
}));
