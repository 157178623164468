import { Avatar, makeStyles, Typography } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { theme } from '../../themes/theme';
import { CheckIcon } from '../../assets/Icons/CreatePanel/CheckIcon';
import { StyledCheckbox } from '../StyledCheckbox';

const AVATAR_BG = Object.values(theme.palette.avatars);

type LabelAvatarSelectItemProps = {
  firstName: string;
  lastName: string;
  title?: string;
  avatarUrl?: Maybe<string> | undefined;
  selected: boolean;
  hasAvatar: boolean;
  item?: {
    id: string;
    name: string;
    type: string;
  };
  iconRightPx?: number;
  checkBox?: boolean;
};

const useStyles = makeStyles({
  selectMenuContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    maxWidth: '100%',
  },
  selectMenuContainerSelected: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,

    '& > span': {
      color: theme.palette.primary.main,
    },
    maxWidth: '100%',
  },
  selectMenuAvatarStyles: {
    backgroundColor: AVATAR_BG[AVATAR_BG.length - 1],
    marginRight: theme.spacing(1.5),
    width: 40,
    height: 40,
    maxWidth: '100%',
  },
  selectMenuAvatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 25,
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 5,
  },
});

export function LabelAvatarSelectItem(props: LabelAvatarSelectItemProps) {
  const { firstName, lastName, title, avatarUrl, selected, hasAvatar } = props;

  const classes = useStyles();

  return (
    <div
      className={
        selected
          ? classes.selectMenuContainerSelected
          : classes.selectMenuContainer
      }
      style={hasAvatar ? { height: 'auto' } : { height: '20px' }}
    >
      {hasAvatar && (
        <Avatar
          className={classes.selectMenuAvatarStyles}
          alt={`${firstName} ${lastName}`}
          src={avatarUrl ? avatarUrl : ''}
        >
          <span className={classes.selectMenuAvatarContent}>
            {avatarUrl ? null : `${firstName[0]}${lastName[0]}`}
          </span>
        </Avatar>
      )}

      {hasAvatar && (
        <span className={classes.selectMenuContent}>
          {`${firstName} ${lastName}`}
          {title && <span className={classes.selectMenuContent}>{title}</span>}
        </span>
      )}

      {!hasAvatar && (
        <Typography variant="body1" className={classes.selectMenuContent}>
          {(props.item && props.item.name) || `${firstName} ${lastName}`}
        </Typography>
      )}
      {props.checkBox && (
        <StyledCheckbox
          checked={selected}
          checkedAll={true}
          className={classes.checkBoxPosition}
        />
      )}
      {selected &&
        (props.checkBox ? null : (
          <CheckIcon
            style={{
              height: 25,
              position: 'absolute',
              right: props.iconRightPx ? props.iconRightPx : '30px',
            }}
          />
        ))}
    </div>
  );
}
