import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const PatientsIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M12 13C13.5708 13 14.9301 13.9054 15.5845 15.2228C16.1286 14.4814 17.0081 14 18 14C19.6569 14 21 15.3431 21 17V19H3V17C3 15.3431 4.34315 14 6 14C6.99194 14 7.87145 14.4814 8.4176 15.2234C9.0699 13.9054 10.4292 13 12 13ZM18 9C19.1046 9 20 9.89543 20 11C20 12.1046 19.1046 13 18 13C16.8954 13 16 12.1046 16 11C16 9.89543 16.8954 9 18 9ZM6 9C7.10457 9 8 9.89543 8 11C8 12.1046 7.10457 13 6 13C4.89543 13 4 12.1046 4 11C4 9.89543 4.89543 9 6 9ZM12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6Z"
    />
  );
};
