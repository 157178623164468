import { Card, CardContent, GridSize, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';

type Props = {
  title: string;
  headerStyle?: any;
  children: any;
  showHistory?: boolean;
  leftFooter?: any;
  rightFooter?: any;
  height: number;
  width: GridSize;
  headerLink?: string;
  style?: any;
  overwrittenClassName?: any;
  backdrop?: boolean;
  backdropContent?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    width: '100%',
    height: '100%',
    zIndex: 10000,
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'rgba(248, 248, 248, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  backdropText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
  },
  cardContainer: {
    position: 'relative',
    '&.MuiCardContent-root': {
      padding: 0,
    },
    '& div.MuiPaper-root': {
      borderRadius: 8,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-between',
    padding: 12,
  },
  headerText: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700],
  },
  history: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.secondary.light,
    width: 86,
    height: 20,
    lineHeight: '20px',
    borderRadius: 8,
    textAlign: 'center',
  },
  noPaddingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  iconContainer: {
    width: '100%',
    height: '100%',
  },
  footerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
    zIndex: 999,
    lineHeight: '24px',
    alignItems: 'center',
  },
  numbers: {
    fontSize: 32,
    fontWeight: theme.typography.fontWeightBold,
  },
  suffix: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
  },
}));

export const CardChildren = (props: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid item xs={props.width} style={props.style}>
      <div className={classes.cardContainer}>
        {props.backdrop && (
          <div className={classes.backdrop}>
            {props.backdropContent && (
              <Typography className={classes.backdropText}>
                {props.backdropContent}
              </Typography>
            )}
          </div>
        )}
        <Card variant="elevation">
          <CardContent
            className={classes.noPaddingContent}
            style={{ height: props.height }}
          >
            <div
              className={
                props.headerStyle ? props.headerStyle.header : classes.header
              }
            >
              <div
                className={
                  props.headerStyle
                    ? props.headerStyle.headerText
                    : classes.headerText
                }
                style={{ cursor: props.headerLink ? 'pointer' : 'auto' }}
                onClick={() =>
                  props.headerLink ? navigate(props.headerLink) : undefined
                }
              >
                {props.title}
              </div>
              {props.showHistory && (
                <div className={classes.history}>Last 30 days</div>
              )}
            </div>
            <div className={classes.iconContainer}>{props.children}</div>
            {(props.leftFooter || props.rightFooter) && (
              <div
                className={
                  props.overwrittenClassName
                    ? `${classes.footerTextContainer} ${props.overwrittenClassName.footerTextContainer}`
                    : `${classes.footerTextContainer}`
                }
              >
                <div className={classes.suffix}>
                  {props.leftFooter ? props.leftFooter : ''}
                </div>
                <div className={classes.suffix}>
                  {props.rightFooter ? props.rightFooter : ''}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};
