import * as Types from '../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientEngagementBatchsQueryVariables = Types.Exact<{
  input: Types.GetPatientEngagementBatchsRequest;
}>;


export type GetPatientEngagementBatchsQuery = (
  { __typename: 'Query' }
  & { getPatientEngagementBatchs?: Types.Maybe<(
    { __typename: 'GetPatientEngagementBatchsResponse' }
    & { pagingInfo?: Types.Maybe<(
      { __typename: 'PagingInfo' }
      & Pick<Types.PagingInfo, 'limit' | 'skip' | 'count'>
    )>, results?: Types.Maybe<Array<(
      { __typename: 'PatientEngagementBatch' }
      & Pick<Types.PatientEngagementBatch, 'id' | 'send_type' | 'message_type' | 'message_template_name' | 'message_title' | 'send_date' | 'send_by' | 'engagement_status' | 'engagement_status_desc' | 'updated_date' | 'patients_count'>
    )>> }
  )> }
);


export const GetPatientEngagementBatchsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPatientEngagementBatchs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientEngagementBatchsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientEngagementBatchs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pagingInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"send_type"}},{"kind":"Field","name":{"kind":"Name","value":"message_type"}},{"kind":"Field","name":{"kind":"Name","value":"message_template_name"}},{"kind":"Field","name":{"kind":"Name","value":"message_title"}},{"kind":"Field","name":{"kind":"Name","value":"send_date"}},{"kind":"Field","name":{"kind":"Name","value":"send_by"}},{"kind":"Field","name":{"kind":"Name","value":"engagement_status"}},{"kind":"Field","name":{"kind":"Name","value":"engagement_status_desc"}},{"kind":"Field","name":{"kind":"Name","value":"updated_date"}},{"kind":"Field","name":{"kind":"Name","value":"patients_count"}}]}}]}}]}}]};

/**
 * __useGetPatientEngagementBatchsQuery__
 *
 * To run a query within a React component, call `useGetPatientEngagementBatchsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientEngagementBatchsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientEngagementBatchsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientEngagementBatchsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientEngagementBatchsQuery, GetPatientEngagementBatchsQueryVariables>) {
        return Apollo.useQuery<GetPatientEngagementBatchsQuery, GetPatientEngagementBatchsQueryVariables>(GetPatientEngagementBatchsDocument, baseOptions);
      }
export function useGetPatientEngagementBatchsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientEngagementBatchsQuery, GetPatientEngagementBatchsQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientEngagementBatchsQuery, GetPatientEngagementBatchsQueryVariables>(GetPatientEngagementBatchsDocument, baseOptions);
        }
export type GetPatientEngagementBatchsQueryHookResult = ReturnType<typeof useGetPatientEngagementBatchsQuery>;
export type GetPatientEngagementBatchsLazyQueryHookResult = ReturnType<typeof useGetPatientEngagementBatchsLazyQuery>;
export type GetPatientEngagementBatchsQueryResult = Apollo.QueryResult<GetPatientEngagementBatchsQuery, GetPatientEngagementBatchsQueryVariables>;
export function refetchGetPatientEngagementBatchsQuery(variables?: GetPatientEngagementBatchsQueryVariables) {
      return { query: GetPatientEngagementBatchsDocument, variables: variables }
    }