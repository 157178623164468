import React from 'react';
import { Button, Dialog, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 432,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D1D1D1',
    },
    '&. .MuiDialogActions-root': {
      padding: 0,
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'visible',
      // paddingLeft: 64,
      // paddingRight: 64,
      // paddingBottom: 8,
    },
  },

  viewDetailBtn: {
    width: 86,
    height: 48,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  doneBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 161,
    height: 48,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 32,
    paddingBottom: 15,
    paddingLeft: 24,
    paddingRight: 24,
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  status: {
    height: 32,
    fontSize: 24,
    lineHeight: '32px',
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
  },
  helpText: {
    marginTop: 20,
    color: theme.palette.secondary.contrastText,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  circleContainer: {
    marginTop: 36,
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: '#E8F6F2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface DialogProps {
  onClose: any;
  onSubmit: any;
  cc: any;
  customTitle?: string;
  customText1?: string;
  customText2?: string;
  customText3?: string;
}

export const DeleteCoordinatorConfirmDialog = (props: DialogProps) => {
  const classes = useStyles();
  const onSubmit = async () => {
    props.onSubmit();
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={() => {
        props.onClose(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div className={classes.root}>
          <div className={classes.status}>{props.customTitle??'Remove Care coordinator'}</div>
          <div className={classes.helpText}>
            {props.customText1??'Are you sure to remove this care coordinator?'}
            <br />
            {props.customText2??'Patient(s) assigned to '}
            <b>{`${props.cc?.firstName} ${props.cc?.lastName}`}</b> {props.customText3??'will be unassigned.'}
          </div>
        </div>
      </DialogContent>
      <div className={classes.buttonRow}>
        <Button
          autoFocus
          onClick={() => {
            props.onClose(false);
          }}
          className={classes.viewDetailBtn}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onClose(false);
            props.onSubmit();
          }}
          className={classes.doneBtn}
          autoFocus
        >
          Confirm Remove
        </Button>
      </div>
    </Dialog>
  );
};
