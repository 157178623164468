import React from 'react';
import { Step1PanelDetailsView } from './View';
import { useCheckPanelNameQuery } from '../../index.generated';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

interface Props {
  onNext: (name: string, description: string) => any;
  step: number;
}

export const Step1PanelDetailsContainer = (props: Props) => {
  const [panelName, setPanelName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<String | undefined>(
    undefined
  );

  const checkPanelNameQuery = useCheckPanelNameQuery({
    variables: {
      input: {
        name: panelName,
      },
    },
  });

  React.useEffect(() => {
    if (panelName && !checkPanelNameQuery.loading) {
      if (!checkPanelNameQuery!.data!.checkPanelName.result) {
        props.onNext(panelName, description);
      }
    }
  }, [checkPanelNameQuery]);

  if (!panelName) {
    return (
      <Step1PanelDetailsView
        step={props.step}
        onNext={props.onNext}
        setDescription={setDescription}
        setPanelName={setPanelName}
        errorMessage={errorMessage}
      />
    );
  }
  if (checkPanelNameQuery.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
  }

  if (checkPanelNameQuery!.data!.checkPanelName.result) {
    return (
      <Step1PanelDetailsView
        step={props.step}
        onNext={props.onNext}
        setPanelName={setPanelName}
        setDescription={setDescription}
        errorMessage={
          'This panel name is existed, Please change to another name.'
        }
      />
    );
  }

  return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
};
