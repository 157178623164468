import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type FilteredProviderPatientsQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.CareGapPatientsRequest>;
}>;


export type FilteredProviderPatientsQuery = (
  { __typename: 'Query' }
  & { getCareGapsPopulation?: Types.Maybe<(
    { __typename: 'CareGapPatientsResponse' }
    & Pick<Types.CareGapPatientsResponse, 'count' | 'limit' | 'skip'>
    & { results?: Types.Maybe<Array<(
      { __typename: 'CareGapPatient' }
      & Pick<Types.CareGapPatient, 'coveringProvider' | 'patientId' | 'patientName' | 'patientDOB' | 'patientGender' | 'lastDOS' | 'careGapsCount'>
    )>> }
  )> }
);

export type GetPatientsByProviderIdAndFiltersQueryVariables = Types.Exact<{
  input: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetPatientsByProviderIdAndFiltersQuery = (
  { __typename: 'Query' }
  & { getPatientByCareTeamUserId: (
    { __typename: 'GetPatientByCareTeamUserIdResponse' }
    & Pick<Types.GetPatientByCareTeamUserIdResponse, 'count' | 'limit' | 'skip'>
    & { results: Array<(
      { __typename: 'PanelPatient' }
      & Pick<Types.PanelPatient, 'id' | 'fullName' | 'firstName' | 'lastName' | 'dateOfBirth' | 'gender' | 'lastDos' | 'coveringPCPFullName' | 'coveringPCPFirstName' | 'coveringPCPLastName' | 'caregapCount' | 'measureCount' | 'subscriberIDs'>
    )> }
  ) }
);


export const FilteredProviderPatientsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"filteredProviderPatients"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CareGapPatientsRequest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCareGapsPopulation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coveringProvider"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"patientName"}},{"kind":"Field","name":{"kind":"Name","value":"patientDOB"}},{"kind":"Field","name":{"kind":"Name","value":"patientGender"}},{"kind":"Field","name":{"kind":"Name","value":"lastDOS"}},{"kind":"Field","name":{"kind":"Name","value":"careGapsCount"}}]}}]}}]}}]};

/**
 * __useFilteredProviderPatientsQuery__
 *
 * To run a query within a React component, call `useFilteredProviderPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredProviderPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredProviderPatientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilteredProviderPatientsQuery(baseOptions?: Apollo.QueryHookOptions<FilteredProviderPatientsQuery, FilteredProviderPatientsQueryVariables>) {
        return Apollo.useQuery<FilteredProviderPatientsQuery, FilteredProviderPatientsQueryVariables>(FilteredProviderPatientsDocument, baseOptions);
      }
export function useFilteredProviderPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteredProviderPatientsQuery, FilteredProviderPatientsQueryVariables>) {
          return Apollo.useLazyQuery<FilteredProviderPatientsQuery, FilteredProviderPatientsQueryVariables>(FilteredProviderPatientsDocument, baseOptions);
        }
export type FilteredProviderPatientsQueryHookResult = ReturnType<typeof useFilteredProviderPatientsQuery>;
export type FilteredProviderPatientsLazyQueryHookResult = ReturnType<typeof useFilteredProviderPatientsLazyQuery>;
export type FilteredProviderPatientsQueryResult = Apollo.QueryResult<FilteredProviderPatientsQuery, FilteredProviderPatientsQueryVariables>;
export function refetchFilteredProviderPatientsQuery(variables?: FilteredProviderPatientsQueryVariables) {
      return { query: FilteredProviderPatientsDocument, variables: variables }
    }
export const GetPatientsByProviderIdAndFiltersDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientsByProviderIdAndFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientByCareTeamUserId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"dateOfBirth"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"lastDos"}},{"kind":"Field","name":{"kind":"Name","value":"coveringPCPFullName"}},{"kind":"Field","name":{"kind":"Name","value":"coveringPCPFirstName"}},{"kind":"Field","name":{"kind":"Name","value":"coveringPCPLastName"}},{"kind":"Field","name":{"kind":"Name","value":"caregapCount"}},{"kind":"Field","name":{"kind":"Name","value":"measureCount"}},{"kind":"Field","name":{"kind":"Name","value":"subscriberIDs"}}]}}]}}]}}]};

/**
 * __useGetPatientsByProviderIdAndFiltersQuery__
 *
 * To run a query within a React component, call `useGetPatientsByProviderIdAndFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsByProviderIdAndFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsByProviderIdAndFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientsByProviderIdAndFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetPatientsByProviderIdAndFiltersQuery, GetPatientsByProviderIdAndFiltersQueryVariables>) {
        return Apollo.useQuery<GetPatientsByProviderIdAndFiltersQuery, GetPatientsByProviderIdAndFiltersQueryVariables>(GetPatientsByProviderIdAndFiltersDocument, baseOptions);
      }
export function useGetPatientsByProviderIdAndFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsByProviderIdAndFiltersQuery, GetPatientsByProviderIdAndFiltersQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientsByProviderIdAndFiltersQuery, GetPatientsByProviderIdAndFiltersQueryVariables>(GetPatientsByProviderIdAndFiltersDocument, baseOptions);
        }
export type GetPatientsByProviderIdAndFiltersQueryHookResult = ReturnType<typeof useGetPatientsByProviderIdAndFiltersQuery>;
export type GetPatientsByProviderIdAndFiltersLazyQueryHookResult = ReturnType<typeof useGetPatientsByProviderIdAndFiltersLazyQuery>;
export type GetPatientsByProviderIdAndFiltersQueryResult = Apollo.QueryResult<GetPatientsByProviderIdAndFiltersQuery, GetPatientsByProviderIdAndFiltersQueryVariables>;
export function refetchGetPatientsByProviderIdAndFiltersQuery(variables?: GetPatientsByProviderIdAndFiltersQueryVariables) {
      return { query: GetPatientsByProviderIdAndFiltersDocument, variables: variables }
    }