import React, { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Chip, TablePagination, Tooltip, withStyles } from '@material-ui/core';
import { StyledCheckbox } from '../../../../../components/StyledCheckbox';
import { UserOptionsMenu } from '../UserOptionsMenu/UserOptionsMenu';
import { Loading } from '../../../../../components/Loading';
import { CheckPrivilegesForDisplay } from '../../../../../components/CheckPrivilegesForDisplay';
import { AccountOptionType, RoleType, User } from '../../../../../enum/Users';
import { useStickyTableStyle } from '../../../../../components/TabBar/Styles';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import {
  GetCareGapFilterOptionsQuery,
  useGetCareGapFilterOptionsQuery,
} from '../../../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { useClinicDivisionPairs } from '../../hooks/useClinicDivisionPairs';
import { useFeature } from '../../../../../components/FeatureFlag';

const nonsortableHeads = ['email', 'clinicId', 'division'];

const StyledTooltip = withStyles({
  tooltip: {
    background: '#FFFFFF',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '8px 0px 0px 0px',
    height: 'fit-content',
    color: '#656565',
    width: 151,
  },
  popper: {
    top: '-14px !important',
    left: '4px !important',
  },
})(Tooltip);

const DivisionStyledTooltip = withStyles({
  tooltip: {
    background: '#FFFFFF',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '8px 0px 0px 0px',
    height: 'fit-content',
    color: '#656565',
  },
  popper: {
    top: '-14px !important',
    left: '4px !important',
  },
})(Tooltip);

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Full Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'clinicId', numeric: false, disablePadding: true, label: 'Clinic ID' },
  { id: 'division', numeric: false, disablePadding: true, label: 'Division' },
  { id: 'roleName', numeric: false, disablePadding: false, label: 'Role' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };
  const hasFeature = useFeature('division');
  const transformedHeadCells = React.useMemo(() => {
    if (hasFeature) {
      return headCells;
    } else {
      return headCells.filter((headCell) => headCell.id !== 'division');
    }
  }, [hasFeature]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <StyledCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            checkedAll={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all patients' }}
            className={classes.checkbox}
          />
        </TableCell>
        {transformedHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the more-options column */}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableRowProps {
  row: User;
  labelId: string;
  selected: User[];
  isItemSelected: boolean;
  setSelected: (input: User[]) => void;
  setToBeUpdatedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setIsOpenDialog: (input: boolean) => void;
  setOptionCode: (input: AccountOptionType) => void;
  openCareGapOptions: any;
}

function EnhancedTableRow(props: EnhancedTableRowProps) {
  const {
    row,
    labelId,
    isItemSelected,
    selected,
    setSelected,
    setToBeUpdatedUsers,
    setIsOpenDialog,
    setOptionCode,
    openCareGapOptions,
  } = props;

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<unknown>, row: User) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected: User[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const getToolTipChip = (title: string, items: any[]) => {
    const chipLabel = '+' + (items.length - 1).toString();
    const lists = (
      <div>
        {items.map((item, index) => {
          return (
            <div
              style={{
                height: 40,
                minWidth: 151,
                padding: '1px 20px',
                lineHeight: '40px',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              key={item?.divisionName ?? item + '-' + index}
            >
              {item?.divisionID?.length > 4
                ? item?.divisionID.slice(0, 4) + '-'
                : ''}
              {item?.divisionName ?? item}
            </div>
          );
        })}
      </div>
    );
    const text = (
      <div>
        <div
          style={{
            color: '#888888',
            fontWeight: 700,
            height: 20,
            minWidth: 151,
            padding: '1px 20px',
          }}
        >
          {title}
        </div>
        {lists}
      </div>
    );
    return title == 'Clinic' ? (
      <StyledTooltip title={<span>{text}</span>}>
        <Chip className={classes.numChip} label={chipLabel} />
      </StyledTooltip>
    ) : (
      <DivisionStyledTooltip title={<span>{text}</span>}>
        <Chip className={classes.numChip} label={chipLabel} />
      </DivisionStyledTooltip>
    );
  };

  const getClinicIdColumn = (clinics: string[]) => {
    let string = 'N/A';
    if (clinics.length > 0) {
      const length = clinics.length;
      if (clinics[0]) {
        string = clinics[0];
        return (
          <TableCell align="left">
            {string}
            {length > 1 && getToolTipChip('Clinic', clinics)}
          </TableCell>
        );
      }
    }
    return (
      <TableCell align="left" className={classes.noneText}>
        {string}
      </TableCell>
    );
  };
  const getDivisionColumn = (divisions: any[]) => {
    let string = 'N/A';
    if (divisions.length > 0) {
      const length = divisions.length;
      string = divisions[0].divisionName;
      return (
        <TableCell align="left">
          {string}
          {length > 1 && getToolTipChip('Divisions', divisions)}
        </TableCell>
      );
    }
    return (
      <TableCell align="left" className={classes.noneText}>
        {string}
      </TableCell>
    );
  };

  const getRoleStyles = (roleName: RoleType | string) => {
    switch (roleName) {
      case RoleType.MSO_ADMIN:
        return classes.roleChipMSOAdmin;
      case RoleType.CARE_MANAGER:
        return classes.roleChipAdmin;
      case RoleType.CARE_COORDINATOR:
      case RoleType.CLINICIAN:
        return classes.roleChipUser;
      default:
        return classes.roleChip;
    }
  };

  // important: hard code for now to filter out the 9000 clinic only NPH-2643
  const { pairs } = useClinicDivisionPairs(row.userId);

  const hasFeature = useFeature('division');
  return (
    <TableRow
      hover
      className={classes.tableRow}
      onClick={(event) => handleClick(event, row)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.userId + row.firstName + ' ' + row.lastName}
      selected={isItemSelected}
    >
      {/* checkbox column */}
      <TableCell padding="checkbox">
        <StyledCheckbox
          checkedAll={false}
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          className={classes.checkbox}
        />
      </TableCell>
      {/* fullName column */}
      <TableCell align="left">{row.firstName + ' ' + row.lastName}</TableCell>
      {/* email column */}
      <TableCell align="left">{row.email}</TableCell>
      {/* clinic id column */}
      {getClinicIdColumn(row.clinics ?? [])}
      {/* important: hard code for now to filter out the 9000 clinic only NPH-2643 */}
      {/* division column */}
      {/* important: hard code for now to filter out the 9000 clinic only NPH-2643 */}
      {hasFeature &&
        getDivisionColumn(pairs.map((item: any) => item.divisions)[0] ?? [])}
      {/* role column */}
      <TableCell align="left">
        <Chip
          label={row.roleName}
          className={classes.roleChip + ' ' + getRoleStyles(row.roleName)}
        />
      </TableCell>
      {/* status column */}
      <TableCell align="left">{row.status}</TableCell>
      {/* more options column */}
      <TableCell align="left">
        <CheckPrivilegesForDisplay
          neededPrivileges={'users.update'}
          element={
            <UserOptionsMenu
              currentUser={row}
              setToBeUpdatedUsers={setToBeUpdatedUsers}
              setIsOpenDialog={setIsOpenDialog}
              setOptionCode={setOptionCode}
            />
          }
        />
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(2),
    },
    noneText: {
      color: '#ABABAB',
    },
    table: {
      minWidth: 750,
      border: 'none',

      '& .MuiTableHead-root': {
        // boxShadow: 'none',
        background: theme.palette.grey[50],
      },

      '& > thead > tr > th': {
        paddingLeft: theme.spacing(1.5),
        padding: 0,
        height: 32,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
      },

      '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },

      '& > tbody > tr': {
        height: 56,
      },

      '& > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
      },
      // Role column
      '& > tbody > tr > td:nth-child(4)': {
        width: 120,
      },
      // Status column
      '& > tbody > tr > td:nth-child(5)': {
        width: 130,
      },
      '& > tbody > tr > td:last-child': {
        width: 65,
      },
      '& > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },
    tableRow: {
      // '&.MuiTableRow-hover:hover': {
      // background: `${theme.palette.primary.light}`,
      // transition: 'all 2s linear',
      // },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    selectAll: {
      '& > .checkedIcon:before': {
        backgroundColor: 'red',
      },
    },
    numChip: {
      marginLeft: 4,
      //padding: theme.spacing(0, 0.5),
      borderRadius: 6,
      height: 24,
      background: '#EDF6FF',

      '& .MuiChip-label': {
        paddingLeft: 5,
        paddingRight: 5,
        fontWeight: 'bold',
        fontSize: 12,
        color: '#0C77D8',
        lineHeight: '20px',
      },
    },
    roleChip: {
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    roleChipMSOAdmin: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    roleChipAdmin: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    roleChipUser: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 44,
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: theme.spacing(1),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
  })
);

interface UsersListTableViewProps {
  enableRouting?: boolean;
  status?: string;
  setActionsFooter: React.Dispatch<React.SetStateAction<User[]>>;
  setToBeUpdatedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  currentTab?: string;
  userRows: User[];
  updatedUsers: User[];
  setUpdatedUsers: (input: User[]) => void;
  setUserRows: (input: User[]) => void;
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSelected: (input: User[]) => void;
  selected: User[];
  setIsOpenDialog: (input: boolean) => void;
  setOptionCode: (input: AccountOptionType) => void;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
}

export const UsersListTableView = (props: UsersListTableViewProps) => {
  const hasFeature = useFeature('division');
  const { setActionsFooter, userRows, setSelected, selected } = props;

  const [filteredRows, setFilteredRows] = React.useState<User[]>([]);

  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [isSorting, setIsSorting] = React.useState(false);
  const openCareGapOptionsQuery = useGetCareGapFilterOptionsQuery();
  const getOpenCareGapOptions = (
    query: GetCareGapFilterOptionsQuery | undefined
  ) => {
    return query?.getCareGapFilterOptions;
  };
  const openCareGapOptions = React.useMemo(
    () => getOpenCareGapOptions(openCareGapOptionsQuery?.data),
    [openCareGapOptionsQuery.data]
  );
  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          getUsersByAccountIdRequest: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
            showAll: true,
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  React.useEffect(() => {
    setFilteredRows(props.userRows);

    setIsSorting(false);
  }, [userRows]);

  React.useEffect(() => {
    setActionsFooter(selected);
  }, [selected, setActionsFooter]);

  let transformedRowsByFlag = React.useMemo(() => {
    if (hasFeature) {
      return filteredRows;
    } else {
      return filteredRows.map(({ divisions, ...keepAttrs }) => keepAttrs);
    }
  }, [hasFeature, filteredRows]);
  const classes = useStyles();
  const stickyTableClasses = useStickyTableStyle();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(filteredRows);
      return;
    }
    setSelected([]);
  };

  // const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const isSelected = (id: string) =>
    selected.find((USER) => USER.userId === id);

  // const emptyRows =
  //   props.limit -
  //   Math.min(props.limit, filteredRows.length - page * props.limit);

  if (!filteredRows) {
    return <Loading>Loading table...</Loading>;
  }

  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          {/* users list table */}
          <TableContainer
            classes={{ root: stickyTableClasses.customTableContainer }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              {/* table header */}
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredRows.length}
              />
              {/* tables rows */}
              <TableBody>
                {transformedRowsByFlag &&
                  transformedRowsByFlag
                    .slice(page * props.limit, page * props.limit + props.limit)
                    .map((row, index) => {
                      return (
                        <EnhancedTableRow
                          key={`enhanced-table-row-user-${index}`}
                          row={row}
                          labelId={`enhanced-table-checkbox-${index}`}
                          selected={selected}
                          isItemSelected={!!isSelected(row.userId)}
                          setSelected={setSelected}
                          setToBeUpdatedUsers={props.setToBeUpdatedUsers}
                          setIsOpenDialog={props.setIsOpenDialog}
                          setOptionCode={props.setOptionCode}
                          openCareGapOptions={openCareGapOptions}
                        />
                      );
                    })}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: 56 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { limit, skip, count } = props;

  const classes = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
