import {
  Button,
  Fade,
  fade,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { MoreHorizIcon } from '../../assets/Icons/Eligibility/MoreHorizIcon';
import { ActivateUserIcon } from '../../assets/Icons/MsoAdmin';
import { theme } from '../../themes/theme';
import { EditPanelIcon } from '../../assets/Icons/PanelsList/EditPanelIcon';
import { ArchivePanelIcon } from '../../assets/Icons/PanelsList/ArchivePanelIcon';
import { ButtonMediumPrimaryText } from '../Buttons';
import React from 'react';
import { QUICK_ACTION } from '../../hooks/useStartCoordination';
import {useFeature} from "../FeatureFlag";
import { MixPanelEvents } from '../../mixpanel/events';

type Props = {
  name: string | null;
  description: string | null;
  careTeamUsers?: any[];
  showEditButton?: boolean;
  sourceInfo: {
    sidebarMenu: string;
    rootLink: string;
    rootName: string;
    childLink: string;
    childName: string;
  };
  setAnchorEl: (input: any) => any;
  anchorEl: any;
  openMenu: boolean;
  handleClose: () => any;
  handleOption: (input: any) => any;
  handleSelectArchivePanel: any;
  assignedPatientCount: number;
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    fontSize: 32,
    height: 40,
    paddingTop: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700],
    lineHeight: '40px',
  },
  description: {
    fontSize: 16,
    height: 24,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey['400'],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  backButton: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  backTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
    '&:hover': {
      color: theme.palette.grey['700'],
    },
  },
  arrowIcon: {
    fontSize: 12,
    color: theme.palette.grey['300'],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  rightContainer: {
    position: 'absolute',
    top: 56,
    right: 24,
    width: 'fit-content',
    height: 40,
    display: 'flex',
  },
  startCoordinationBtnText: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  editButton: {
    position: 'relative',
    backgroundColor: theme.palette.grey['50'],
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
  },
  careTeamAvatarContainer: {
    position: 'relative',
    right: 110,
    top: 20,
  },
  moreIcon: {
    fontSize: 16,
  },
  navLink: {
    textUnderlineOffset: '4px',
    textDecorationThickness: 2,
    textDecorationColor: '#b3b1b1',
  },
  navTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
    fontWeight: theme.typography.fontWeightMedium,
    userSelect: 'none',
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  specialtyChip: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.grey[700],
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 100,
    borderColor: 'black',
    height: 32,
    marginRight: theme.spacing(1),
  },
  conditionChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(1),
  },
  iconStyleMenu: {
    height: 24,
    width: 24,
  },
  menu: {
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
      marginTop: 0,
      width: 200,
    },
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
      padding: theme.spacing(0.5, 1),
      position: 'relative',
    },

    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after': {
      content: '""',
      position: 'absolute',
      bottom: 43,
      left: 0,
      display: 'block',
      width: 200,
      height: 1,
      background: theme.palette.grey[300],
    },

    '& li[role="menuitem"]': {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 35,
      borderRadius: 8,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 4,
    },

    '& li[role="menuitem"]:first-child': {
      marginTop: 8,
    },
    '& li[role="menuitem"]:last-child': {
      marginBottom: 8,
    },
  },
  toolTipsWidth: {
    minWidth: 100,
    maxWidth: 500,
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    width: '224px',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

export const TabBarHeader = (props: Props) => {
  const classes = useStyles(props);
  const { id } = useParams();
  const navigate = useNavigate();
  const hasFeature = useFeature('coordination');
  const handleOptionEdit = () => {
    props.setAnchorEl(null);
    navigate(`/panels/${id}/editPanel`);
  };
  const handleOptionArchive = () => {
    props.setAnchorEl(null);
    props.handleSelectArchivePanel();
  };

  const handleStartCoordination = (event: undefined | React.MouseEvent) => {
    MixPanelEvents.track(MixPanelEvents.userBehavior.startToCoordinationPatient.name)
    navigate(`/panels/${id}/assigned`, {
      state: {
        action: QUICK_ACTION.START_COORDINATION_WITHIN_PANEL,
      },
    });
  };

  const tooltipText = [
    'Please view unassigned patient and',
    'assign them to  Coordinator(s) to',
    'enable Coordination workflow.',
  ];

  const getStartCoordinationComponent = () => {
    const StartCoordinationButton = () => {
      return hasFeature?(
        <ButtonMediumPrimaryText
          classes={{
            disabled: classes.disabled,
          }}
          disabled={props.assignedPatientCount === 0}
          style={props.showEditButton ? { right: 4 } : { right: 66 }}
          onClick={handleStartCoordination}
        >
          <Typography
            className={classes.startCoordinationBtnText}
            style={{
              opacity: props.assignedPatientCount === 0 ? 0.5 : 1,
            }}
          >
            Start Coordination
          </Typography>
        </ButtonMediumPrimaryText>
      ):null
    };
    if (
      props.assignedPatientCount === undefined ||
      props.assignedPatientCount === 0
    ) {
      return (
        <StyledTooltip
          placement="bottom-end"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={tooltipText.join('\n')}
        >
          <span>
            <StartCoordinationButton />
          </span>
        </StyledTooltip>
      );
    } else {
      return <StartCoordinationButton />;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.backButton}>
          <NavLink className={classes.navLink} to={props.sourceInfo.rootLink}>
            <Typography className={classes.backTitle}>
              {props.sourceInfo.rootName}
            </Typography>
          </NavLink>
          <ArrowForwardIcon className={classes.arrowIcon} />
          <Typography className={classes.navTitle}>{props.name}</Typography>
        </div>
        <Typography className={classes.title}>{props.name}</Typography>
        <Tooltip
          classes={{ tooltip: classes.toolTipsWidth }}
          title={props.description ? props.description.toString() : ''}
          placement="bottom-start"
        >
          <Typography display="inline" className={classes.description}>
            {props.description && props.description.length > 100
              ? props.description.slice(0, 97) + '...'
              : props.description}
          </Typography>
        </Tooltip>

        <div className={classes.rightContainer}>
          {getStartCoordinationComponent()}
          {props.showEditButton && (
            <>
              <Button
                className={classes.editButton}
                id={`more-option-button-panel-summary`}
                aria-haspopup="true"
                variant="contained"
                disableElevation
                onClick={(e) => {
                  props.setAnchorEl(e.currentTarget);
                }}
              >
                <MoreHorizIcon
                  style={{
                    color: theme.palette.grey[500],
                  }}
                />
              </Button>

              <StyledMenu
                id={`more-option-menu-panel-summary`}
                anchorEl={props.anchorEl}
                open={props.openMenu}
                onClose={props.handleClose}
                className={classes.menu}
              >
                <MenuItem onClick={() => handleOptionEdit()} disableRipple>
                  <span>Edit</span>
                  <EditPanelIcon className={classes.iconStyleMenu} />
                </MenuItem>
                <MenuItem onClick={props.handleOption} disableRipple>
                  <span>Manage Coordinator</span>
                  <ActivateUserIcon className={classes.iconStyleMenu} />
                </MenuItem>
                {/*<MenuItem onClick={() => handleOptionArchive()} disableRipple>*/}
                {/*  <span>Archive</span>*/}
                {/*  <ArchivePanelIcon className={classes.iconStyleMenu} />*/}
                {/*</MenuItem>*/}
              </StyledMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    width: 'fit-content',
    height: 'fit-content',
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '8px 12px',
    whiteSpace: 'pre-wrap',
    lineHeight: '20px',
  },
  popper: {
    top: '-20px !important',
  },
})(Tooltip);
