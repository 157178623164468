import React from 'react';
import {
  useGetPanelsListQuery,
  useGetPatientCountByUserIdCcQuery,
} from './index.generated';

export const useAllPatientCountCc = () => {
  const [fetching, setFetching] = React.useState(true);
  const [numOfPatients, setNumOfPatients] = React.useState(0);
  const [numOfPanels, setNumOfPanels] = React.useState(0);

  const patientCountCcQuery = useGetPatientCountByUserIdCcQuery({
    variables: {
      input: {
        limit: 10,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
  });
  const panelListsCcQuery = useGetPanelsListQuery({
    variables: {
      input: {
        limit: 10,
        skip: 0,
        sortBy: [{ field: 'createdAt', method: 'desc' }],
      },
    },
  });

  React.useEffect(() => {
    if (
      !panelListsCcQuery.called ||
      panelListsCcQuery.loading ||
      !patientCountCcQuery.called ||
      patientCountCcQuery.loading
    ) {
      setFetching(true);
    } else {
      setFetching(false);
      setNumOfPanels(panelListsCcQuery.data?.getPanels.count ?? 0);
      setNumOfPatients(patientCountCcQuery.data?.getAllPatients.count ?? 0);
    }
  }, [panelListsCcQuery, patientCountCcQuery]);

  return {
    fetching,
    numOfPatients,
    setNumOfPatients,
    numOfPanels,
    setNumOfPanels,
  };
};
