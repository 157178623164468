import React from 'react';
import {
  GetTeamMembersBySelectedPatientsQuery,
  useGetTeamMembersBySelectedPatientsQuery,
} from './index.generated';
import { ChangeCoordinatorDialogView } from './View';
import { useParams } from 'react-router';
import { GlobalContext } from '../../../../../components/GlobalContext';

export type currentlyAssignedFromActionFooterType = {
  loading: boolean;
  data: GetTeamMembersBySelectedPatientsQuery | undefined;
  error: any;
};

interface ChangeCoordinatorDialogContainerProps {
  source: string;
  fromCoordinators: any;
  setShowChangeCoordinatorDialog: (input: any) => void;
  coordinatorOptions: any[];
  allPatientCount: number;
  changeCoordinator: (fromUsers: any, toUsers: any) => any;
  loading: boolean | undefined;
  clicked: boolean;
  setClicked: (input: boolean) => void;
  firstQueryLoading: boolean | undefined;
  // filters and allSelected are needed from PanelPatientsListActionsFooter
  filters?: any;
  allSelected?: boolean;
  excluded?: any;
  selectedItems?: any;
  fromUnAssign?: boolean;
}

export const ChangeCoordinatorDialogContainer = (
  props: ChangeCoordinatorDialogContainerProps
) => {
  const { id } = useParams();
  const { loading, data, error } = useGetTeamMembersBySelectedPatientsQuery({
    variables: {
      input: {
        panelId: id,
        excludePatientIds: props.allSelected
          ? props.excluded.map((patient: any) => patient.id)
          : [],
        selectedPatientIds: props.allSelected
          ? []
          : props.source === 'PatientFaceSheet' // the query fired for PanelPatientActionFooter only
          ? []
          : props.selectedItems.map((patient: any) => patient.id),
        filters: props.filters ? props.filters : null,
      },
    },
    skip: props.source === 'PatientFaceSheet', // the query fired for PanelPatientActionFooter only
  });

  const [
    currentlyAssignedFromActionFooter,
    setCurrentlyAssignedFromActionFooter,
  ] = React.useState<currentlyAssignedFromActionFooterType>();

  React.useEffect(() => {
    setCurrentlyAssignedFromActionFooter({
      loading,
      data,
      error,
    });
  }, [loading, data, error]);

  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (!props.fromUnAssign && currentlyAssignedFromActionFooter?.error) {
      props.setShowChangeCoordinatorDialog(false);
      setToastMessage({
        snackbarMsg:
          'Cannot get currently assigned coordinators data, please try later.',
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  }, [currentlyAssignedFromActionFooter?.error]);

  return (
    <div>
      <ChangeCoordinatorDialogView
        {...props}
        currentlyAssignedFromActionFooter={
          // call api to get coordinators info as long as all is selected
          props.allSelected ? currentlyAssignedFromActionFooter : undefined
        }
      />
    </div>
  );
};
