export enum ENV_TYPES {
  DEV = 'dev',
  QA = 'qa',
  UAT = 'uat',
}

export function getEnvName() {
  // dev_202300418_1.0.0
  // qa_20230424_1.0.0
  // uat_1.0.58
  const envDateVersionString = process.env.REACT_APP_VERSION_NUMBER;
  return envDateVersionString ? envDateVersionString.split('_')[0] : 'dev';
}
