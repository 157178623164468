import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MainRegion } from '../../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../../components/BreadCrumbHeader';
import { CareGapsListContainer } from '../../../Files/PayerCareGap/PayerCareGapList';
import { useLocation, useParams } from 'react-router-dom';
import { useGetPayerFileByIdQuery } from '../../../Files/index.generated';
import { useFetchTableData } from '../../../../../../hooks/useFetchTableData';
const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const PayerFileCareGap = () => {
  const classes = useStyles();
  const location = useLocation();
  const { id, payerId } = useParams();
  const [title, setTitle] = React.useState('');
  const resp = useGetPayerFileByIdQuery({
    variables: {
      request: {
        fileId: id ?? '',
      },
    },
  });

  const fileQueryResult = useFetchTableData(resp);

  React.useEffect(() => {
    try {
      setTitle(fileQueryResult?.getPayerFileById?.fileName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [fileQueryResult]);
  const name = location?.state?.name ?? '';
  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={name}
          rootLink={`/payerID`}
          rootName={'Payer ID'}
          isTitle={true}
          childrenLink={`/payerID/` + payerId}
          title={name}
          childrenChildrenName={title}
          childrenState={name}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            <CareGapsListContainer />
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
