import React from "react";
import {EligibleIcon} from "../../../../../assets/Icons/Eligibility/EligibleIcon";
import {theme} from "../../../../../themes/theme";
import {TerminationIcon} from "../../../../../assets/Icons/Eligibility/TerminationIcon";
import Grid from "@material-ui/core/Grid";
import {Card, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { ActiveExternalIcon32, ActiveInternalIcon32, InactiveIcon32, UnavailableIcon32, UnverifiedIcon32 } from "../../../../../assets/Icons/Eligibility/EligibilityCategoryIcons";

interface IProps {
  internalActiveNumber: number,
  externalActiveNumber: number,
  rejectedNumber: number,
  inactiveNumber: number,
  unavailableNumber: number,
  onClick: (type: EligibilityCategory) => void
}

export enum EligibilityCategory {
  internalActive = "activeinternal",
  externalActive = "activeexternal",
  unverified = "rejected",
  rejected = "unverified",
  inactive = "inactive",
  unavailable = "unavailable",
  engagement = "engagement"
}

export default function EligibilityCategoryList(props: IProps) {
  const {inactiveNumber, externalActiveNumber, rejectedNumber, internalActiveNumber, unavailableNumber} = props
  const classes = useStyles()
  const dataForStatus: Array<{
    title: string,
    icon: any,
    iconBackground: string,
    type: EligibilityCategory,
  }> = [
    {
      title: 'Active (Internal PCP)',
      icon: <ActiveInternalIcon32 />,
      iconBackground: '#E8F6F2',
      type: EligibilityCategory.internalActive,
    },
    {
      title: 'Active (External PCP)',
      icon: <ActiveExternalIcon32 />,
      iconBackground: '#EDF6FF',
      type: EligibilityCategory.externalActive,
    },
    {
      title: 'Inactive',
      icon: <InactiveIcon32 />,
      iconBackground: theme.palette.error.light,
      type: EligibilityCategory.inactive,
    },
    {
      title: 'Unverified',
      icon: <UnverifiedIcon32 />,
      iconBackground: '#EDF6FF',
      type: EligibilityCategory.unverified,
    },
    {
      title: 'Unavailable',
      icon: <UnavailableIcon32 />,
      iconBackground: '#EFEFEF',
      type: EligibilityCategory.unavailable,
    }
  ]

  const getNumberByType = (type: EligibilityCategory) => {
    switch (type) {
      case EligibilityCategory.internalActive:
        return internalActiveNumber
      case EligibilityCategory.externalActive:
        return externalActiveNumber
      case EligibilityCategory.unverified:
        return rejectedNumber
      case EligibilityCategory.inactive:
        return inactiveNumber
      case EligibilityCategory.unavailable:
        return unavailableNumber
      default:
        return 0
    }
  }

  return  (
    <div className={classes.root}>
      <div className={classes.mainGrid}>
        {dataForStatus.map(({title, icon, iconBackground, type}, idx) => {
          const number = getNumberByType(type)
          const numberText = number.toLocaleString("en-US")
          return (
            <div key={idx+title}>
              <Card className={classes.statusCard} onClick={() => {
                props.onClick && props.onClick(type)
              }}>
                <Typography className={classes.statusName}>{title}</Typography>
                <div className={classes.statusInfoContainer}>
                  <div className={classes.statusInfoIconContainer} style={{backgroundColor: iconBackground}}>
                    {icon}
                  </div>
                  <div className={classes.statusNumberContainer}>
                    <div className={classes.statusNumber}>{numberText}</div>
                    <div className={classes.statusInfoName}>{number > 1 ? 'Patients' : 'Patient'}</div>
                  </div>
                </div>
              </Card>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  mainGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr ',
    gridTemplateRows: 'repeat(1, 150px)',
    gridGap: '24px',
  },
  statusCard: {
    borderRadius: 8,
    padding: '8px 16px',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(245,245, 245)',
    },
  },
  statusName: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
    color: '#434343',
  },
  statusInfoContainer: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: 40
  },
  statusInfoIconContainer: {
    width: 60,
    height: 60,
    display: 'flex',
    flex: 'none',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40
  },
  statusInfoIcon: {
    width: 32,
    height: 32,
  },
  statusNumberContainer: {
    marginLeft: 24,
    flex: 1,
    flexGrow: 1,
  },
  statusNumber: {
    display: 'inline',
    fontSize: 32,
    lineHeight: '40px',
    color: '#434343',
    fontWeight: 700,
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  statusInfoName: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#ABABAB',
    fontWeight: 500,
    marginTop: 4,
  },
}));
