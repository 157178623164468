import React from 'react';
import {
  useClearNotificationMutation,
  useReadNotificationMutation,
} from '../layouts/MainLayout/components/NotificationCenter/index.generated';
import { GlobalContext } from '../components/GlobalContext';

export const useNotificationMessageStatus = (
  // notifications: any,
  actionString: 'read' | 'clear',
  // isAll: boolean,
  getNotificationQuery?: any
) => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  const [readNotificationMutation] = useReadNotificationMutation();
  const [clearNotificationMutation] = useClearNotificationMutation();

  const handlerMap = new Map<string, any>();
  handlerMap.set('read', readNotificationMutation);
  handlerMap.set('clear', clearNotificationMutation);

  const handler = async (notificationIds: string[]) => {
    if (!notificationIds) {
      return;
    }
    // console.log('notifications =>', notifications);
    // let toBeReadItemIds: string[] = [];
    // if (isAll === true) {
    //   // clear all notifications
    //   toBeReadItemIds = [...notifications.map((item: any) => item.id)];
    // } else if (isAll === false && notifications.length >= 1) {
    // clear selected notifications
    // toBeReadItemIds = [...notifications];
    // }
    // console.log('toBeReadItemIds =>', toBeReadItemIds);

    const { data, errors } = await handlerMap.get(actionString)({
      variables: {
        input: {
          ids: [...notificationIds],
        },
      },
    });

    const dataHandler = new Map<string, any>();
    dataHandler.set('read', data.readNotificationMessages);
    dataHandler.set('clear', data.clearNotificationMessages);
    // data?.readNotificationMessages.isSuccess
    // data?.clearNotificationMessages.isSuccess

    if (errors || !data || dataHandler.get(actionString).isSuccess === false) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something Went Wrong, Please Try Again Later.',
      });
      setIsOpenToast(true);
      return;
    }

    if (actionString === 'clear') {
      setToastMessage({
        isOpen: true,
        severity: 'success',
        snackbarMsg: `Notifications Cleared Successfully.`,
      });
      setIsOpenToast(true);

      if (getNotificationQuery) {
        getNotificationQuery({
          variables: {
            input: {
              readStatus: 'all',
            },
          },
        });
      }
    }
  };

  return { handler };
};
