import React from 'react';
import { CareGapPatientTableContainer } from './Container';
import { CCCareGapPatientSearchingTableContainer } from './SearchingContainerCC';
import { CareGapPatientSearchingTableContainer } from './SearchingContainer';
import { CCCareGapPatientTableContainer } from './ContainerCC';

type Props = {
  isSearching: boolean;
  limit: any;
  skip: any;
  setLimit: any;
  setSkip: any;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  setResults: (input: any) => void;
  showMyOwn: string;
  isGetAllPatients: boolean;
};

export const RoutedCareGapPatientTable = (props: Props) => {
  return props.isSearching ? (
    props.isGetAllPatients ? (
      <CCCareGapPatientSearchingTableContainer
        sortBy={props.sortBy}
        setSortBy={props.setSortBy}
        setLoading={props.setLoading}
        setDisplayedCount={props.setDisplayedCount}
        limit={props.limit}
        skip={props.skip}
        setLimit={props.setLimit}
        setSkip={props.setSkip}
        setResults={props.setResults}
        showMyOwn={props.showMyOwn}
      />
    ) : (
      <CareGapPatientSearchingTableContainer
        sortBy={props.sortBy}
        setSortBy={props.setSortBy}
        setLoading={props.setLoading}
        setDisplayedCount={props.setDisplayedCount}
        limit={props.limit}
        skip={props.skip}
        setLimit={props.setLimit}
        setSkip={props.setSkip}
        setResults={props.setResults}
        showMyOwn={props.showMyOwn}
      />
    )
  ) : props.isGetAllPatients ? (
    <CCCareGapPatientTableContainer
      sortBy={props.sortBy}
      setSortBy={props.setSortBy}
      setLoading={props.setLoading}
      setDisplayedCount={props.setDisplayedCount}
      limit={props.limit}
      skip={props.skip}
      setLimit={props.setLimit}
      setSkip={props.setSkip}
      setResults={props.setResults}
      showMyOwn={props.showMyOwn}
    />
  ) : (
    <CareGapPatientTableContainer
      sortBy={props.sortBy}
      setSortBy={props.setSortBy}
      setLoading={props.setLoading}
      setDisplayedCount={props.setDisplayedCount}
      limit={props.limit}
      skip={props.skip}
      setLimit={props.setLimit}
      setSkip={props.setSkip}
      setResults={props.setResults}
      showMyOwn={props.showMyOwn}
    />
  );
};
