import React from 'react';
import { ProviderPatientTableContainer } from './Container';
import moment from 'moment';

type Props = {
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient: any
  ) => any;
  providerNPI: string;
  selectedFilter: any | undefined;
  isSearching: boolean;
  searchInput: any;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  setResults: (input: any) => void;
  setValue: (input: any) => void;
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  applyFilterBtnIsClicked: boolean;
  searchName: string;
  setSearchName: (input: string) => void;
  searchDob: string;
  setSearchDob: (input: string) => void;
  isSearchingCheck: boolean | undefined;
  setIsSearchingCheck: (input: boolean | undefined) => void;
};

const formats = [
  'MM-DD-yyyy',
  'MM/DD/yyyy',
  'MM DD yyyy',
  'YYYY-DD-MM',
  'YYYY/DD/MM',
  'YYYY DD MM',
];

export const RoutedProvidePatientTable = (props: Props) => {
  const {
    searchName,
    setSearchName,
    searchDob,
    setSearchDob,
    isSearchingCheck,
    setIsSearchingCheck,
  } = props;

  const validateSearchInput: (input: string) => boolean = (
    searchInputString: string
  ) => {
    function isValidDateFormat(dobString: string) {
      // Regular expressions for each supported format
      const formats = [
        /^\d{4} \d{2} \d{2}$/,
        /^\d{4}\/\d{2}\/\d{2}$/,
        /^\d{4}-\d{2}-\d{2}$/,
        /^\d{2} \d{2} \d{4}$/,
        /^\d{2}\/\d{2}\/\d{4}$/,
        /^\d{2}-\d{2}-\d{4}$/,
      ];

      // Check if the date string matches any of the formats
      for (const format of formats) {
        if (format.test(dobString)) {
          return true;
        }
      }

      return false;
    }
    let removeSpace = searchInputString.replaceAll(' ', '');
    let commaCount = (searchInputString.match(/,/g) || []).length;
    let netLength = removeSpace.replaceAll(',', '');
    let searchName = '';
    let searchDob = '';

    if (removeSpace.length == 0 || netLength.length == 0) {
      return false;
    }
    if (commaCount > 2) {
      return false;
    }
    if (commaCount === 2) {
      // first part is andName : AAA, BBB
      // second part is DOB
      let searchItems = searchInputString.split(',');
      searchName = searchItems[0];
      searchDob = searchItems[1].replaceAll(' ', '');
      const isDateValid = moment(searchDob, formats).isValid();
      if (!isDateValid) {
        return false;
      }
      const date = moment(searchDob, formats).format('YYYY-MM-DD');
      setSearchName(searchName);
      setSearchDob(date);
      return true;
    }
    if (commaCount === 1) {
      // the typed string maybe either a string of andName (AAA, BBB) or andName, DOB
      let searchItems = searchInputString.split(',');
      searchName = searchItems[0];
      searchDob = searchItems[1].replaceAll(' ', '');
      const isDateValid = moment(searchDob, formats).isValid();
      if (!isDateValid) {
        // typed string is andName only
        setSearchName(searchInputString);
        return true;
      }
      // typed string is andName, DOB
      const date = moment(searchDob, formats).format('YYYY-MM-DD');
      setSearchName(searchName);
      setSearchDob(date);
      return true;
    }
    if (commaCount == 0) {
      let tmpInputString = searchInputString.trim();
      // DOB string contains: whitespace, dash, slash and numbers and must meets the formats listed above
      // Subscriber ID possible format:
      // - dash with letters and numbers: 5Q16-DV7-DE63
      // - numbers only: 111111111
      // - letter and numbers: 1PY9CD0JY56

      if (isValidDateFormat(tmpInputString)) {
        searchDob = searchInputString.replaceAll(' ', '');
        const isDateValid = moment(searchDob, formats).isValid();
        if (isDateValid) {
          const date = moment(searchDob, formats).format('YYYY-MM-DD');
          setSearchDob(date);
          setSearchName('');
        } else {
          setSearchName(searchInputString);
          setSearchDob('');
        }
      } else {
        setSearchName(searchInputString);
        setSearchDob('');
      }
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    let searchOk = false;
    if (props.isSearching) {
      searchOk = validateSearchInput(props.searchInput);
      setIsSearchingCheck(props.isSearching && searchOk);
      if (searchOk) {
        props.setSkip(0);
      }
    }
  }, [props.searchInput, props.isSearching]);

  return (
    <ProviderPatientTableContainer
      sortBy={props.sortBy}
      setSortBy={props.setSortBy}
      limit={props.limit}
      skip={props.skip}
      setLimit={props.setLimit}
      setSkip={props.setSkip}
      drawerHandler={props.drawerHandler}
      providerNPI={props.providerNPI}
      selectedFilter={props.selectedFilter}
      isSearching={isSearchingCheck === true ? true : false}
      searchName={searchName}
      searchDob={searchDob}
      setLoading={props.setLoading}
      setDisplayedCount={props.setDisplayedCount}
      setResults={props.setResults}
      setValue={props.setValue}
      applyFilterBtnIsClicked={props.applyFilterBtnIsClicked}
    />
  );
};
