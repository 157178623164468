import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const AVATAR_DIAMETER = 32;

interface ProfileItemProps {
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  avatarUrl?: string | null;
  avatarBgColor?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 1, 3, 1),
    height: 40,
    borderRadius: 6,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    paddingLeft: theme.spacing(1.5),
    fontSize: 16,
    userSelect: 'none',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 140,
    textAlign: 'left',
    fontWeight: 700,
    color: '#434343',
  },
  title: {
    paddingLeft: theme.spacing(1.5),
    fontSize: 12,
    userSelect: 'none',
    color: '#888888',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 140,
    textAlign: 'left',
  },
}));

const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    })
    .join(' ');
};

export const SideBarProfileItem: React.FC<ProfileItemProps> = (props) => {
  const classes = useStyles();
  const { firstName, lastName, title, avatarUrl } = props;

  const formatFullName = (
    firstName?: string | null,
    lastName?: string | null,
    title?: string | null
  ) => {
    return (
      titleCase(firstName + ' ' + lastName) +
      (title ? ', ' + title.toUpperCase() : '')
    );
  };

  return (
    <div className={classes.profileContainer}>
      <SideBarCircleAvatar
        avatarDiameter={AVATAR_DIAMETER}
        avatarUrl={avatarUrl}
        firstName={firstName}
        lastName={lastName}
        avatarBgColor={props.avatarBgColor ? props.avatarBgColor : ''}
      />
      <div>
        <Typography className={classes.name}>
          {formatFullName(firstName, lastName)}
        </Typography>
        <Typography className={classes.title}>{title}</Typography>
      </div>
    </div>
  );
};

interface CircleAvatarProps {
  avatarDiameter: number;
  avatarUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatarBgColor?: string;
}

const useCircleAvatarStyles = makeStyles((theme) => ({
  circleContainer: (props: CircleAvatarProps) => ({
    display: 'flex',
    width: props.avatarDiameter,
    height: props.avatarDiameter,
    borderRadius: '50%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.avatarBgColor
      ? props.avatarBgColor
      : theme.palette.avatars.yellow,
    backgroundImage: props.avatarUrl ? 'url(' + props.avatarUrl + ')' : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',

    fontSize: 12,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  }),
}));

const nameInitials = (firstName: string, lastName: string) => {
  return firstName.charAt(0) + lastName.charAt(0);
};

const SideBarCircleAvatar: React.FC<CircleAvatarProps> = (props) => {
  const classes = useCircleAvatarStyles(props);
  const { firstName, lastName, avatarUrl } = props;

  if (avatarUrl) return <div className={classes.circleContainer} />;

  return (
    <div className={classes.circleContainer}>
      {firstName && lastName ? nameInitials(firstName, lastName) : ''}
    </div>
  );
};
