import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useProfileOptionTabsStyles } from '../../../MyProfile/components/ProfileOptionTabs/Styles';

interface ProfileOptionTabsViewProps {
  value: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  a11yProps: (index: number) => any;
}

export const SettingOptionTabs = (props: ProfileOptionTabsViewProps) => {
  const { value, handleTabChange, a11yProps } = props;
  const classes = useProfileOptionTabsStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={1} component="div" className={classes.paper}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          // centered
          TabIndicatorProps={{
            style: {
              height: 0,
            },
          }}
        >
          <Tab
            label={
              <span
                className={
                  value === 0
                    ? `${classes.activeTab} ${classes.tabText}`
                    : `${classes.deactiveTab} ${classes.tabText}`
                }
              >
                SMS Templates
              </span>
            }
            {...a11yProps(value)}
            className={classes.tab}
          />
          <Tab
            disabled={true}
            label={
              <span
                className={
                  value === 1
                    ? `${classes.activeTab} ${classes.tabText}`
                    : `${classes.deactiveTab} ${classes.tabText}`
                }
                style={{ color: '#ABABAB' }}
              >
                Other Settings
              </span>
            }
            {...a11yProps(value)}
            className={classes.tab}
          />
        </Tabs>
      </Paper>
    </div>
  );
};
