import React, {memo} from "react";
import {Popper, PopperProps} from "@material-ui/core";

const CustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{
        minWidth: 203,
        maxWidth: 400,
      }}
      placement="bottom-start"
    />
  );
};

export default memo(CustomPopper)
