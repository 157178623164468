import { makeStyles } from '@material-ui/core/styles';
import { UsersIcon } from '../../assets/Icons/SideNavigation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '40px',
    color: '#434343',
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    textAlign: 'center',
    paddingTop: 10,
  },
  icon: {
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 11,
    paddingTop: 125,
  },
}));

export const EmptyTableView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <UsersIcon className={classes.icon} />
      </div>

      <div className={classes.title}>Search or Filter your patients</div>
      <div className={classes.subTitle}>
        Add 1 or more filters and click Apply Filters to see the results.
      </div>
    </div>
  );
};
