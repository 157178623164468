import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const ResendPasswordIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M21.999 2L19.0576 4.94141C16.8647 2.74368 13.6657 1.55364 10.2119 2.15625C6.18185 2.86025 2.9217 6.07466 2.1767 10.0977C0.999697 16.4437 5.86296 22 11.999 22C17.133 22 21.3775 18.1091 21.9365 13.1211C22.0035 12.5251 21.5365 12 20.9365 12C20.4365 12 20.0061 12.3682 19.9521 12.8652C19.5191 16.8702 16.118 20 11.999 20C7.05796 20 3.14912 15.4989 4.15912 10.3809C4.76712 7.30086 7.23256 4.81078 10.3076 4.17578C13.1698 3.58502 15.831 4.54502 17.6415 6.35742L14.999 9H21.999V2Z"
  />
);
