import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import { useChangeCoordinatorDialogStyles } from './Styles';
import { StaticPatientCount } from '../../../CreatePanel/components/Step2/components/StaticPatientCount';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { ChangeCoordinatorSelect } from '../ChangeCoordinatorSelect';
import { RoleType } from '../../../../../enum/Users';
import { currentlyAssignedFromActionFooterType } from './Container';
import { useLocation } from 'react-router';
import { MixPanelEvents } from '../../../../../mixpanel/events';

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const ITEM_NUM = 5;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '851px',
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));

interface ChangeCoordinatorDialogViewProps {
  source: string;
  fromCoordinators: any;
  setShowChangeCoordinatorDialog: (input: any) => void;
  coordinatorOptions: any[];
  allPatientCount: number;
  changeCoordinator: (fromUsers: any, toUsers: any) => any;
  loading: boolean | undefined;
  clicked: boolean;
  setClicked: (input: boolean) => void;
  firstQueryLoading: boolean | undefined;
  currentlyAssignedFromActionFooter?: currentlyAssignedFromActionFooterType;
  fromUnAssign?: boolean;
}

export const ChangeCoordinatorDialogView = (
  props: ChangeCoordinatorDialogViewProps
) => {
  const {
    setShowChangeCoordinatorDialog,
    coordinatorOptions,
    allPatientCount,
  } = props;

  const classes = useChangeCoordinatorDialogStyles();
  const [careTeamOptions, setCareTeamOptions] = React.useState<any>([]);

  const handleClose = () => {
    // close dialog
    setShowChangeCoordinatorDialog(false);
  };

  React.useEffect(() => {
    if (coordinatorOptions && coordinatorOptions.length > 0) {
      const tmpCoordinatorOption = [];

      for (let coordinatorOption of coordinatorOptions) {
        if (coordinatorOption.role.roleName === RoleType.ADMIN) {
          let tmpRole = { ...coordinatorOption.role };
          let newRoleName = RoleType.CARE_MANAGER;
          tmpCoordinatorOption.push({
            ...coordinatorOption,
            role: {
              ...tmpRole,
              roleName: newRoleName,
            },
          });
        } else {
          tmpCoordinatorOption.push(coordinatorOption);
        }
      }

      setCareTeamOptions([...tmpCoordinatorOption]);
    }
  }, [coordinatorOptions]);

  const [selectedCareTeam, setSelectedCareTeam]: any[] = React.useState([]);

  const [assignedPatients, setAssignedPatients] = React.useState(0);
  const [currentlyAssigned, setCurrentlyAssigned] = React.useState<any>([]);
  const { pathname } = useLocation();
  return (
    <div className={classes.root}>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <div role="assign-coordinator-dialog-form">
          <Paper className={classes.paperContainer}>
            <Grid container>
              {/* section header */}
              <Grid item xs={12} className={classes.cardHeader}>
                {/* title */}
                <Typography className={classes.cardTitle}>
                  {props.fromUnAssign || pathname.endsWith(`/deListed`)
                    ? 'Assign Care Coordinator'
                    : 'Change Care Coordinator'}
                </Typography>
                {/* patient count */}
                <StaticPatientCount
                  patientCount={allPatientCount}
                  noChipStyle={false}
                />
              </Grid>

              {/* main region */}
              <Grid item xs={12} className={classes.cardContent}>
                {/*Care Coordinator(s) Select*/}
                <Grid item xs={12}>
                  <ChangeCoordinatorSelect
                    source={props.source}
                    fromCoordinators={props.fromCoordinators}
                    droplistAttr={{
                      maxHeight: ITEM_HEIGHT * ITEM_NUM + ITEM_PADDING_TOP,
                      width: 250,
                    }}
                    careTeamUsers={careTeamOptions}
                    selectedCareTeam={selectedCareTeam}
                    setSelectedCareTeam={setSelectedCareTeam}
                    assignedPatients={assignedPatients}
                    setAssignedPatients={setAssignedPatients}
                    numPatients={allPatientCount}
                    classes={{
                      multipleSelect: classes.multipleSelect,
                      formControl: classes.formControl,
                      inputTitle: classes.addMember,
                      placeholder: classes.placeholderText,
                      unassignedRow: classes.unassignedRow,
                      unassignedCircle: classes.unassignedCircle,
                      unassignedText: classes.unassignedText,
                      numPatients: classes.numPatients,
                      patientsText: classes.patientsText,
                      addCareTeamProvider: classes.addCareTeamProvider,
                      addCareTeamProviderBtn: classes.addCareTeamProviderBtn,
                      addCareTeamProviderText: classes.addCareTeamProviderText,
                    }}
                    currentlyAssignedFromActionFooter={
                      pathname.endsWith(`/unassigned`) ||
                      pathname.endsWith(`/deListed`)
                        ? undefined
                        : props.currentlyAssignedFromActionFooter
                    }
                    currentlyAssigned={currentlyAssigned}
                    setCurrentlyAssigned={setCurrentlyAssigned}
                    fromUnAssign={props.fromUnAssign}
                  />

                  {props.firstQueryLoading && (
                    <div className={classes.assignedCoordinatorContainerEmpty}>
                      <LoadingSpinner />
                    </div>
                  )}
                </Grid>
              </Grid>

              {/* section footer */}
              <Grid item xs={12} className={classes.sectionFooter}>
                <Button
                  id="back"
                  variant="contained"
                  style={{ width: 71 }}
                  className={classes.backBtn}
                  onClick={handleClose}
                >
                  {'Back'}
                </Button>

                <Button
                  id="creation-button"
                  variant="contained"
                  disableElevation
                  className={classes.updateBtn}
                  onClick={() => {
                    // setOnConfirm(true)
                    setShowChangeCoordinatorDialog(false);
                    props.changeCoordinator(
                      currentlyAssigned,
                      selectedCareTeam
                    );
                    MixPanelEvents.track(
                      MixPanelEvents.userActivityAndJourneyMapping
                        .reassignCoordinators.name
                    );
                  }}
                  // disabled={disabled || (props.clicked && props.loading)}
                  disabled={
                    selectedCareTeam.length === 0 ||
                    assignedPatients !== allPatientCount ||
                    selectedCareTeam.find(
                      (item: any) => item.assignedPatients === 0
                    )
                  }
                >
                  {'Update'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </BootstrapDialog>
    </div>
  );
};
