import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const OpenInNewWindowIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M5 3C3.90694 3 3 3.90694 3 5V19C3 20.0931 3.90694 21 5 21H19C20.0931 21 21 20.0931 21 19V13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13V19H5V5H11C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3H5ZM15 3C14.4477 3 14 3.44772 14 4C14 4.55228 14.4477 5 15 5H17.5859L9 13.5859C8.60952 13.9764 8.60952 14.6095 9 15C9.39048 15.3905 10.0236 15.3905 10.4141 15L19 6.41406V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V3.06931C21 3.03103 20.969 3 20.9307 3H15Z"
    />
  );
};
