import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  OutlinedInput,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { usePasswordValidation } from '../../../../Login/hooks/usePasswordValidation';
import clsx from 'clsx';
import { useOutlinedInputStyles } from '../../../../../styles/outlineInputStyle';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { useCallbackPrompt } from '../../../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../../../components/DialogBox';

interface ChangePasswordFormViewProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      width: '100%',
      display: 'flex',
      borderRadius: 8,
    },
    cardHeader: {
      height: 72,
      paddingLeft: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    newPwCard: {
      boxSizing: 'border-box',
      width: '100%',
      borderRadius: 8,
      padding: theme.spacing(5, 4, 5, 4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    instructions: {
      fontSize: 16,
      color: theme.palette.secondary.dark,
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(3),
    },
    inputLabel: {
      fontSize: 14,
      height: 24,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['700'],
    },
    inputField: {
      height: 48,
      borderRadius: 8,
      fontSize: 16,
    },
    submitButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      height: 48,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: 8,
    },
    backToLoginButton: {
      width: 'fit-content',
      padding: 0,
      height: 'fit-content',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: 0,
    },
    backToLogin: {
      fontSize: 14,
      fontWeight: 600,
      height: 24,
      color: theme.palette.secondary.dark,
      borderBottom: '2px solid #434343',
      paddingBottom: theme.spacing(0.5),
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
    errorMsg: {
      fontSize: 12,
      fontWeight: 600,
      paddingTop: theme.spacing(1.5),
    },
    email: {
      color: theme.palette.text.primary,
    },
    ruleRow: {
      display: 'flex',
      alignItems: 'center',
      height: 26,
    },
    ruleText: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.dark,
      paddingLeft: theme.spacing(1),
    },
    checkIcon: {
      fontSize: 18,
    },
    checkGreen: {
      color: theme.palette.success.main,
    },
    closeIcon: {
      fontSize: 18,
      color: theme.palette.error.main,
    },
    cardContent: {
      width: '100%',
      padding: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
    cardFooter: {
      height: 72,
      padding: theme.spacing(2, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    nextButton: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },

      '&.MuiButton-contained.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

type ChangePasswordFields = {
  user: object;
  currentPassword: string;
  newPassword: string;
  reTypeNewPassword: string;
};

export const ChangePasswordFormView = (props: ChangePasswordFormViewProps) => {
  const { setIsOpenToast, setToastMessage, setUserCredential } =
    React.useContext(GlobalContext);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [currentPassword, setCurrentPassword] = useState<string | undefined>(
    undefined
  );
  const [reTypePassword, setReTypePassword] = useState<string | undefined>(
    undefined
  );
  const [curPasswordError, setCurPasswordError] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordValidation({
      password: password,
    });

  const navigate = useNavigate();

  async function onSubmit() {
    setCurPasswordError(null);
    setShowDialog(false);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword!, password!);
      })
      .then((data) => {
        setUserCredential(password!);
        navigate('/dashboard');

        setToastMessage({
          snackbarMsg: `Your password has been updated`,
          isOpen: true,
          severity: 'success' as const,
        });
        setIsOpenToast(true);
      })
      .catch((err) => {
        if (err.code === 'NotAuthorizedException') {
          setCurPasswordError(err);
          const temp = {
            isOpen: true,
            severity: 'error' as const,
            snackbarMsg: 'Current password is incorrect',
          };
          setToastMessage(temp);
          setIsOpenToast(true);
          setShowDialog(true);
        } else {
          const temp = {
            isOpen: true,
            severity: 'error' as const,
            snackbarMsg: err?.message,
          };
          setToastMessage(temp);
          setIsOpenToast(true);
          setShowDialog(true);
        }
      });
  }

  const { control, handleSubmit, register, formState, errors } =
    useForm<ChangePasswordFields>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
    });

  const onCurrentPasswordChange = (event: any) => {
    setCurrentPassword(event.target.value);
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const onRetypePasswordChange = (event: any) => {
    setReTypePassword(event.target.value);
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const renderValidationRules = () => {
    return (
      <div style={{ marginTop: 12 }}>
        {password ? (
          <div className={classes.ruleRow}>
            {password?.length && !validLength ? (
              <CloseIcon className={classes.closeIcon} />
            ) : (
              <CheckIcon
                className={clsx(classes.checkIcon, {
                  [classes.checkGreen]: password?.length,
                })}
              />
            )}
            <Typography className={classes.ruleText}>
              At least 8 characters long
            </Typography>
          </div>
        ) : null}
        {password ? (
          <div className={classes.ruleRow}>
            {password?.length && !upperCase ? (
              <CloseIcon className={classes.closeIcon} />
            ) : (
              <CheckIcon
                className={clsx(classes.checkIcon, {
                  [classes.checkGreen]: password?.length,
                })}
              />
            )}
            <Typography className={classes.ruleText}>
              One upper-case letter
            </Typography>
          </div>
        ) : null}
        {password ? (
          <div className={classes.ruleRow}>
            {password?.length && !lowerCase ? (
              <CloseIcon className={classes.closeIcon} />
            ) : (
              <CheckIcon
                className={clsx(classes.checkIcon, {
                  [classes.checkGreen]: password?.length,
                })}
              />
            )}
            <Typography className={classes.ruleText}>
              One lower-case letter
            </Typography>
          </div>
        ) : null}
        {password ? (
          <div className={classes.ruleRow}>
            {password?.length && !hasNumber ? (
              <CloseIcon className={classes.closeIcon} />
            ) : (
              <CheckIcon
                className={clsx(classes.checkIcon, {
                  [classes.checkGreen]: password?.length,
                })}
              />
            )}
            <Typography className={classes.ruleText}>One number</Typography>
          </div>
        ) : null}
        {password ? (
          <div className={classes.ruleRow}>
            {password?.length && !specialChar ? (
              <CloseIcon className={classes.closeIcon} />
            ) : (
              <CheckIcon
                className={clsx(classes.checkIcon, {
                  [classes.checkGreen]: password?.length,
                })}
              />
            )}
            <Typography className={classes.ruleText}>
              One special character
            </Typography>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Paper className={classes.card} elevation={1}>
        <LeaveEditDialogBox
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Grid container>
          <Grid item xs={12} className={classes.cardHeader}>
            <Typography className={classes.cardTitle}>
              Change Password
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={3}
            className={classes.cardContent}
          >
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>
                Current Password
              </Typography>
              <Controller
                name="currentPassword"
                control={control}
                render={({ onChange, value, name }) => (
                  <div className={classes.marginBottom}>
                    <OutlinedInput
                      fullWidth
                      inputRef={register({ required: true, minLength: 1 })}
                      name={name}
                      value={currentPassword}
                      onChange={onCurrentPasswordChange}
                      error={
                        !!errors.currentPassword?.message ||
                        !!errors.currentPassword?.type
                      }
                      type="password"
                      className={classes.inputField}
                      placeholder="Enter Current Password"
                      classes={outlinedInputClasses}
                    />
                    {currentPassword == undefined ||
                    currentPassword?.length ? null : (
                      <Typography color="error" className={classes.errorMsg}>
                        Current password can not be empty
                      </Typography>
                    )}
                    {curPasswordError && (
                      <Typography color="error" className={classes.errorMsg}>
                        Current password is incorrect
                      </Typography>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>
                New Password
              </Typography>
              <Controller
                name="newPassword"
                control={control}
                render={({ onChange, value, name }) => (
                  <div className={classes.marginBottom}>
                    <OutlinedInput
                      fullWidth
                      inputRef={register({
                        required: true,
                        validate: {
                          custom: () =>
                            validLength &&
                            hasNumber &&
                            upperCase &&
                            lowerCase &&
                            specialChar,
                        },
                      })}
                      name={name}
                      value={password}
                      onChange={onPasswordChange}
                      error={
                        !!errors.newPassword?.message ||
                        !!errors.newPassword?.type
                      }
                      type="password"
                      className={classes.inputField}
                      placeholder="Enter New Password"
                      classes={outlinedInputClasses}
                    />
                    {renderValidationRules()}
                    {error && (
                      <Typography color="error" className={classes.errorMsg}>
                        {error?.message}
                      </Typography>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>
                Re-type New Password
              </Typography>
              <Controller
                name="newReTypePassword"
                control={control}
                render={({ onChange, value, name }) => (
                  <div className={classes.marginBottom}>
                    <OutlinedInput
                      fullWidth
                      inputRef={register({ required: true, minLength: 1 })}
                      name={name}
                      value={reTypePassword}
                      onChange={onRetypePasswordChange}
                      error={
                        !!errors.reTypeNewPassword?.message ||
                        !!errors.reTypeNewPassword?.type
                      }
                      type="password"
                      className={classes.inputField}
                      placeholder="Re-type New Password"
                      classes={outlinedInputClasses}
                    />
                    {password != reTypePassword && (
                      <Typography color="error" className={classes.errorMsg}>
                        New passwords do not match. Please try again.
                      </Typography>
                    )}
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.cardFooter}>
            <Button
              type="submit"
              className={classes.nextButton}
              disabled={
                password != reTypePassword ||
                currentPassword == undefined ||
                currentPassword.length <= 0 ||
                !validLength ||
                !hasNumber ||
                !upperCase ||
                !lowerCase ||
                !specialChar
              }
            >
              Update Password
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
