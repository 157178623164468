import { QueryResult } from '@apollo/client';
import { RoleType } from '../../../../../enum/Users';
import { createHeaderCell } from '../../../../../sharePipe/createHeaderCellPipe';
import { PanelsListTable } from '../../../components/PanelsListTable';

interface CCPanelsListViewProps {
  panels: any;
  limit: number;
  skip: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  count: number;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  query: QueryResult;
  showMyOwn: string;
}

export const CCPanelsListView = (props: CCPanelsListViewProps) => {
  return (
    <PanelsListTable
      refetchDataFunc={{
        func: props.query.refetch,
        params: {
          limit: props.limit ?? 10,
          skip: props.skip ?? 0,
          sortBy: [{ ...props.sortBy }],
          showMyOwn: props.showMyOwn,
        },
      }}
      sortBy={{ ...props.sortBy }}
      setSortBy={props.setSortBy}
      panels={props.panels}
      skip={props.skip}
      limit={props.limit}
      setSkip={props.setSkip}
      setLimit={props.setLimit}
      count={props.count}
      tableType={RoleType.CARE_COORDINATOR}
      headCells={[
        {
          id: 'name',
          align: 'left' as const,
          disablePadding: false,
          label: 'Name',
        },
        {
          id: 'patientCount',
          align: 'left' as const,
          disablePadding: false,
          label: 'My Patients',
        },
        {
          id: 'openGapCount',
          align: 'left' as const,
          disablePadding: false,
          label: 'Open Gaps',
        },
        {
          id: 'complianceRate',
          align: 'left' as const,
          disablePadding: false,
          label: 'Compliance Rate',
        },
        {
          id: 'createdAt',
          align: 'left' as const,
          disablePadding: false,
          label: 'Date Created',
        },
      ].map((item) =>
        createHeaderCell(item.id, item.label, item.disablePadding, item.align)
      )}
    />
  );
};
