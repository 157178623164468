import React from 'react';
import {
  IconButton,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { StyledCheckbox } from '../StyledCheckbox';
import { theme } from '../../themes/theme';
import { NavigateNextIcon } from '../../assets/Icons/CareGaps';
interface CustomSelectProps {
  state: any[];
  type: string;
  setState: any;
  isMultiple: boolean;
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  showValue?: boolean;
  fullWidth?: boolean;
  isObject?: boolean;
  showSelectedCount?: boolean;
  disable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 8,
    marginTop: 4,
    '&:hover': {
      borderRadius: 8,
    },
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 50,
  },
  selectMenuContentValue: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    width: 50,
    paddingRight: 8,
  },
  selectMenuContentName: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75%',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  placeholderTextSelected: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  disabledPlaceholderTextSelected: {
    color: '#ABABAB',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    width: '100%',

    marginTop: 24,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  disabledFormControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#EFEFEF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  inputTitleNoCap: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    marginBottom: 4,
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
  disabledInputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
    opacity: 0.5,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 20,
  },
  min40Height: {
    minHeight: 40,
  },
  minWidthAnd40height: {
    minWidth: 416,
    minHeight: 40,
  },
  navigateNext: {
    width: 24,
    height: 24,
    // color: theme.palette.grey[500],
    transform: `rotate(90deg)`,
    paddingRight: 2,
  },
  block: {
    width: 26,
  },
  block2: {
    width: 42,
  },
  iconButton: {
    padding: 0,
    paddingRight: 2,
  },
}));

export const CustomSelectWithLayer = (props: CustomSelectProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [openedSector, setOpenedSector] = React.useState<string[]>([]);
  const [render, setRender] = React.useState(0);

  const handleOpen = (
    event: React.ChangeEvent<{ value: any }>,
    value: string
  ) => {
    let temp = openedSector;
    if (!openedSector.includes(value)) {
      temp.push(value);
    } else {
      temp = openedSector.filter((item: string) => item != value);
    }
    setRender(render + 1);
    setOpenedSector(temp);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, ProviderType and CareTeam
    let newValue = event.target.value;
    const newestSelected = newValue[newValue.length - 1] ?? '';
    const unselected = (newValue.length ?? 0) < (props.state.length ?? 0);
    const unselectedValue =
      props.state.find(
        (item: any) => !newValue.includes(item || 'parent,' + item)
      ) ?? '';
    //selected a subheader
    if (newestSelected.includes(',') && !unselected) {
      const parentValue = newestSelected;
      const selectedParent = parentValue.split(',')[1];
      if (event.target.value.includes('All')) {
        newValue = [];
        props.items.forEach((value: any) => {
          if (
            !event.target.value.includes('parent,' + value.value) &&
            value.parent != selectedParent
          ) {
            if (value.parent == '') {
              newValue.push('parent,' + value.value);
            } else {
              newValue.push(value.value);
            }
          }
        });
      } else if (
        event.target.value.includes(parentValue) &&
        !props.state.includes(parentValue)
      ) {
        props.items
          .filter((item: any) => item.parent == selectedParent)
          .forEach((i: any) => {
            if (!newValue.includes(i.value)) {
              newValue.push(i.value);
            }
          });
      }
    } else if (unselected && unselectedValue.includes(',')) {
      const selectedParent = unselectedValue.split(',')[1];
      const removeItems = props.items
        .filter((item: any) => item.parent == selectedParent)
        .map((item: any) => item.value);
      newValue = newValue.filter((item: string) => !removeItems.includes(item));
    } else if (
      event.target.value.includes('All') &&
      !props.state.includes('All')
    ) {
      newValue = ['All'];
    } else if (props.state.includes('All')) {
      if (event.target.value.includes('All')) {
        newValue = [];
        const selectedRemoveItem = props.items.find((i: any) =>
          event.target.value.includes(i.value)
        );
        props.items.forEach((value: any) => {
          if (
            value.value != selectedRemoveItem.value &&
            value.value != selectedRemoveItem.parent
          ) {
            if (value.parent == '') {
              newValue.push('parent,' + value.value);
            } else {
              newValue.push(value.value);
            }
          }
        });
      } else {
        newValue = newValue.filter((value: any) => value != 'All');
      }
    } else if (event.target.value?.length == props.items?.length) {
      newValue = ['All'];
    }
    //check count and see each subheader
    else {
      let hashMap = new Map<string, number>();
      for (let item of props.items) {
        if (item.parent != '') {
          hashMap.set(item.parent, (hashMap.get(item.parent) ?? 0) + 1);
        }
      }
      hashMap.forEach((value: number, key: string) => {
        const arr2 = props.items
          .filter((item: any) => item.parent == key)
          .map((item: any) => item.value);
        const containsAll =
          newValue.filter((item: string) => arr2.includes(item)).length ==
          arr2.length;
        if (containsAll) {
          if (!unselected) {
            if (!newValue.includes('parent,' + key)) {
              newValue.push('parent,' + key);
            }
          }
        } else if (newValue.includes('parent,' + key)) {
          newValue = newValue.filter((item: string) => item != 'parent,' + key);
        }
      });
    }
    setRender(render + 1);
    props.setState(props.type, newValue);
  };

  const getItemName = (value: string) => {
    if (!props.isObject) return value;
    else {
      return props.items.find((item) => item?.value == value)?.name || value;
    }
  };

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.fullWidth ? '100%' : props.width || 203 }}
    >
      <FormControl
        className={
          props.disable ? classes.disabledFormControl : classes.formControl
        }
      >
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={
            props.disable
              ? classes.disabledInputTitle
              : props.inputTitle.includes('of')
              ? classes.inputTitleNoCap
              : classes.inputTitle
          }
        >
          {props.inputTitle}
        </Typography>

        <Select
          disabled={props.disable}
          multiple={props.isMultiple}
          variant="outlined"
          displayEmpty
          value={props.state}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          renderValue={(selected) => {
            if (isOpen) {
              return <div className={classes.placeholderText}>Select</div>;
            } else if (
              (selected as string[]).length === 0 ||
              (selected as string[]).includes('All')
            ) {
              return (selected as string[]).includes('All') ? (
                <div
                  className={
                    props.disable
                      ? classes.disabledPlaceholderTextSelected
                      : classes.placeholderTextSelected
                  }
                  style={{ color: theme.palette.grey[600] }}
                >
                  All
                </div>
              ) : (
                <div
                  className={classes.placeholderText}
                  style={{ color: theme.palette.grey[400] }}
                >
                  {props.placeholder}
                </div>
              );
            }

            return (
              <div className={classes.placeholderTextSelected}>
                {props.showSelectedCount
                  ? `${
                      (selected as string[]).filter(
                        (l: string) => !l.startsWith('parent,')
                      ).length
                    } selected`
                  : (selected as string[])
                      .map((item) => getItemName(item))
                      .join(', ')}
              </div>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            autoFocus: false,
            elevation: 2,
            getContentAnchorEl: null,
            style: props.fullWidth
              ? {
                  maxHeight: 68 * 5 + 8,
                  maxWidth: '100%',
                }
              : {
                  maxHeight: 68 * 5 + 8,
                  width: props.showValue ? 416 : 250,
                },
            PaperProps: {
              style: props.fullWidth
                ? {
                    // reduce space between input and menu
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 7,
                    maxWidth: 400,
                  }
                : {
                    // reduce space between input and menu
                    minWidth: props.width ? props.width - 10 : 192,
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 7,
                    maxWidth: 400,
                  },
            },
          }}
          className={classes.select}
        >
          <MenuItem
            value="All"
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
            className={
              props.showValue
                ? classes.minWidthAnd40height
                : classes.min40Height
            }
          >
            <span className={classes.block} />
            <Typography variant="body1" className={classes.selectMenuContent}>
              All
            </Typography>
            <StyledCheckbox
              checked={props.state.includes('All')}
              checkedAll={true}
              className={classes.checkBoxPosition}
            />
          </MenuItem>
          {props.items.map((item, idx) => {
            return item.parent == '' ? (
              <MenuItem
                key={idx}
                value={
                  'parent,' + (typeof item == 'string' ? item : item.value)
                }
                classes={{
                  root: classes.listItem,
                  selected: classes.selected,
                }}
                className={
                  props.showValue
                    ? classes.minWidthAnd40height
                    : classes.min40Height
                }
                style={{
                  backgroundColor: !openedSector.includes(item.value)
                    ? '#FFFFFF'
                    : '#EFEFEF',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={(event: any) => {
                    handleOpen(event, item.value);
                  }}
                >
                  <NavigateNextIcon
                    className={classes.navigateNext}
                    style={{
                      transform: !openedSector.includes(item.value)
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                    }}
                  />
                </IconButton>
                {props.showValue && (
                  <Typography
                    variant="body1"
                    className={classes.selectMenuContentValue}
                  >
                    {typeof item == 'string' ? item : item.value}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  className={
                    props.showValue
                      ? classes.selectMenuContentName
                      : classes.selectMenuContent
                  }
                >
                  {typeof item == 'string' ? item : item.name}
                </Typography>
                <StyledCheckbox
                  checked={
                    props.state.includes(
                      'parent,' + (typeof item == 'string' ? item : item.value)
                    ) || props.state.includes('All')
                  }
                  checkedAll={true}
                  className={classes.checkBoxPosition}
                />
              </MenuItem>
            ) : !openedSector.includes(item.parent) ? (
              <MenuItem
                key={idx}
                value={typeof item == 'string' ? item : item.value}
                classes={{
                  root: classes.listItem,
                  selected: classes.selected,
                }}
                className={
                  props.showValue
                    ? classes.minWidthAnd40height
                    : classes.min40Height
                }
                style={{ left: 42, width: 'calc(100% - 42px)' }}
              >
                {props.showValue && (
                  <Typography
                    variant="body1"
                    className={classes.selectMenuContentValue}
                  >
                    {typeof item == 'string' ? item : item.value}
                  </Typography>
                )}
                {(typeof item == 'string' ? item : item.name).length > 29 ? (
                  <Tooltip title={typeof item == 'string' ? item : item.name}>
                    <Typography
                      variant="body1"
                      className={
                        props.showValue
                          ? classes.selectMenuContentName
                          : classes.selectMenuContent
                      }
                    >
                      {typeof item == 'string' ? item : item.name}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    variant="body1"
                    className={
                      props.showValue
                        ? classes.selectMenuContentName
                        : classes.selectMenuContent
                    }
                  >
                    {typeof item == 'string' ? item : item.name}
                  </Typography>
                )}
                <StyledCheckbox
                  checked={
                    props.state.includes(
                      typeof item == 'string' ? item : item.value
                    ) || props.state.includes('All')
                  }
                  checkedAll={true}
                  className={classes.checkBoxPosition}
                />
              </MenuItem>
            ) : null;
          })}
        </Select>
      </FormControl>
    </div>
  );
};
