import React from 'react';
import {
  GetDashboardQuery,
  useGetDashboardQuery,
} from '../DashBoard/index.generated';
import { CCDashBoardTabView } from './CCDashboard';
import { useDailyCoordinationData } from '../../../../../hooks/useDailyCoordinationData';

interface DashBoardContainerProps {}

export const DashBoardViewCC = (props: DashBoardContainerProps) => {
  const dashboardQuery = useGetDashboardQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getDashboardData = (query: GetDashboardQuery | undefined) => {
    return query?.dashboard;
  };
  const { dailyCoordinationProgressData } = useDailyCoordinationData(undefined);

  const dashboardData = React.useMemo(() => {
    if (dashboardQuery.error) {
      return {
        patientCount: 0,
        averageCompliance: 0,
        panelCount: 0,
        pcps: {
          averagePerformance: 0,
          pcps: [],
        },
        last10Days: {
          closedGaps: [],
          dates: [],
          engagedPatients: [],
        },
      };
    }
    return getDashboardData(dashboardQuery.data);
  }, [dashboardQuery]);

  return (
    <div>
      <CCDashBoardTabView
        numOfPatients={dashboardData?.patientCount}
        numOfAverageCompliance={dashboardData?.averageCompliance}
        numOfPanels={dashboardData?.panelCount}
        providerAvePerformance={dashboardData?.pcps?.averagePerformance}
        providers={dashboardData?.pcps?.pcps}
        dailyEngagedPatientsAndClosedGaps={dashboardData?.last10Days}
        dailyCoordinationProgressData={dailyCoordinationProgressData}
      />
    </div>
  );
};
