import React, { useContext } from 'react';
import { GlobalContext } from '../../components/GlobalContext';
import { Navigate } from 'react-router';
import { LoginView } from './View';
import { CheckIsAdmin } from '../../components/CheckIsAdmin';

const Login: React.FunctionComponent = () => {
  const { loggedInUser } = useContext(GlobalContext);

  // log in to last user path : location?.state as any
  if (loggedInUser == undefined) return <LoginView />;

  const { from } = {
    from: {
      pathname: CheckIsAdmin(loggedInUser) ? '/dashboard' : '/dashboard',
    },
  };
  return <Navigate to={from} />;
};

export default Login;
