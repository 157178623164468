import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const EngagementIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M19 3.5C20.6569 3.5 22 4.84315 22 6.5V10.5C22 11.3885 21.6138 12.1868 21 12.7361V15.4194C21 16.2579 20.0301 16.7241 19.3753 16.2002L16 13.5H14.5V11.5C14.5 9.01472 12.4853 7 10 7V6.5C10 4.84315 11.3431 3.5 13 3.5H19ZM2 11.5C2 9.84315 3.34315 8.5 5 8.5H10C11.6569 8.5 13 9.84315 13 11.5V15.5C13 17.1569 11.6569 18.5 10 18.5H8L4.6247 21.2002C3.96993 21.7241 3 21.2579 3 20.4194V17.7361C2.38625 17.1868 2 16.3885 2 15.5V11.5Z"
  />
);
