import React from 'react';
import { NotificationCenterView } from './View';
import { useGetNotificationsLazyQuery } from './index.generated';
import { GlobalContext } from '../../../../components/GlobalContext';
import { useNotificationMessageStatus } from '../../../../hooks/useNotificationMessageStatus';

export type InitNotificationStateType = {
  loading: boolean;
  result: any;
};

interface NotificationCenterContainerProps {}

export const NotificationCenterContainer: React.FC<NotificationCenterContainerProps> = () => {
  const { openNotificationCenter } = React.useContext(GlobalContext);
  const [initState, setInitState] = React.useState<InitNotificationStateType>({
    loading: false,
    result: [],
  } as InitNotificationStateType);
  const [
    notificationQuery,
    notificationQueryResult,
  ] = useGetNotificationsLazyQuery();

  React.useEffect(() => {
    if (openNotificationCenter) {
      notificationQuery({
        variables: {
          input: {
            readStatus: 'all',
          },
        },
      });
    }
  }, [openNotificationCenter]);

  React.useEffect(() => {
    if (
      !notificationQueryResult ||
      !notificationQueryResult.called ||
      notificationQueryResult.loading
    ) {
      setInitState({
        loading: true,
        result: undefined,
      });
    } else if (notificationQueryResult.data) {
      setInitState({
        loading: false,
        result: notificationQueryResult.data.getNotifications?.result || [],
      });
    }
  }, [notificationQueryResult]);

  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (initState.result) {
      setItems(initState.result);
    }
  }, [initState.result]);

  const { handler: clearNotificationsHandler } = useNotificationMessageStatus(
    // items,
    'clear',
    // true,
    notificationQuery
  );
  const { handler: readNotificationsHandler } = useNotificationMessageStatus(
    // items,
    'read'
    // true
  );
  React.useEffect(() => {
    if (openNotificationCenter === true) {
      readNotificationsHandler(items.map((item) => item.id));
    }
  }, [openNotificationCenter, items]);

  return (
    <div id="NotificationCenterContainer">
      <NotificationCenterView
        loading={initState?.loading}
        items={items}
        clearNotificationsHandler={clearNotificationsHandler}
      />
    </div>
  );
};
