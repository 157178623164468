import {Tooltip, Typography, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {InsertChart} from '@material-ui/icons';
import moment from 'moment'
import React from 'react';
import {GetReportResponseItem} from "../../app.types.generated";
import styles from './report.table.module.css'
import SimpleMenu from "./simpleMenu";
import {Format} from "./types";
import IconButton from '@material-ui/core/IconButton';

const StyledTooltip = withStyles({
    tooltip: {
        background: '#FFFFFF',
        borderRadius: 8,
        fontSize: 12,
        lineHeight: `16px`,
        fontWeight: 700,
        height: 'fit-content',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    },
    popper: {
        // top: '-14px !important',
        // left: '4px !important',
    },
})(Tooltip);

type ColumnId = `requestedBy` | `requestedDate` | `appliedFilters` | `status` | `download`;

interface Column {
    id: ColumnId;
    label: string;
    minWidth?: number;
    align?: 'left' | 'right' | 'center';
}

const columns: Column[] = [
    {id: "requestedBy", label: 'Requested By', minWidth: 100},
    {id: "requestedDate", label: 'Requested Date', minWidth: 100},
    {
        id: 'appliedFilters',
        label: 'Applied Filters',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
    },
    {
        id: 'download',
        label: 'Download',
        align: 'left',
    },
];

interface IProps {
    containerHeight: 0,
    dataSource: GetReportResponseItem[],
    loading: boolean,
    page: number,
    total: number,
    rowsPerPage: number,
    onHandleChangePage: (newPage: number) => void
    onHandleChangeRowsPerPage: (rowsPerPage: number) => void,
    onGetFileUrl: (item: GetReportResponseItem, format: Format) => void,
    downloadingId: string | null
}

export default function ReportTable(props: IProps) {
    const handleChangePage = (event: unknown, newPage: number) => {
        props.onHandleChangePage(newPage)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onHandleChangeRowsPerPage(+event.target.value)
    };

    const getInitials = (input: string) => {
        let names = input.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const $getNameInitialsItem = (requestByName: string) => {
        const initials = getInitials(requestByName);
        return (
            <div className={styles.contentContainer}>
                <div className={styles.initialNameContainer}>
                    <Typography className={styles.initialName}>{initials}</Typography>
                </div>
                <Typography className={styles.basicContent}>{requestByName}</Typography>
            </div>
        )
    }

    const $getToolTipChip = (type: 'users' | 'modules', title: string, items: any[]) => {
        const chipLabel = '+' + (items.length - 1).toString();
        const $list = (
            <div>
                {items.map((item: any, index) => {
                    return (
                        <div className={styles.toolTipItemTextContainer}>
                            <div
                                className={styles.toolTipItemText}
                                key={index}
                            >
                                {type === 'users' ? (
                                    $getNameInitialsItem(item.userName)
                                ) : item}
                            </div>
                        </div>

                    );
                })}
            </div>
        );
        const $popover = (
            <div className={styles.toolTipContainer}>
                <div className={styles.toolTipItemTitle}>
                    {title}
                </div>
                {$list}
            </div>
        );
        return (
            <StyledTooltip title={$popover}>
                <div className={styles.chip}>
                    <Typography className={styles.chipText}>{chipLabel}</Typography>
                </div>
            </StyledTooltip>
        )
    };

    const $loadingForItem = (
        <IconButton color={'primary'}>
            <CircularProgress size={14} color={'primary'}/>
        </IconButton>
    )

    const $cellContentByColumnId = (columnId: ColumnId, row: GetReportResponseItem) => {
        switch (columnId) {
            case 'requestedBy':
                return $getNameInitialsItem(row.requestByName);
            case 'requestedDate':
                const rd = moment(row.requestDate).format('MM/DD/YYYY');
                return <Typography className={styles.basicContent}>{rd}</Typography>;
            case 'appliedFilters':
                const fromDate = moment(row.filter.startDate).format('MM/DD/YYYY');
                const toDate = moment(row.filter.endDate).format('MM/DD/YYYY');
                const dateRange = `${fromDate} - ${toDate}`;

                return (
                    <div className={styles.contentContainer}>
                        <div className={styles.chipContainer}>
                            <Typography
                                className={styles.basicContent}>{row.filter.users && row.filter.users.length ? row.filter.users[0].userName : ''}</Typography>
                            {row.filter.users && row.filter.users.length > 1 ? (
                                $getToolTipChip('users', 'Care Team Users', row.filter.users)
                            ) : null},
                        </div>
                        <Typography className={styles.basicContent}>{dateRange},</Typography>
                        <div className={styles.chipContainer}>
                            <Typography
                                className={styles.basicContent}>{row.filter.modules && row.filter.modules.length ? row.filter.modules[0] : ''}</Typography>
                            {row.filter.modules && row.filter.modules.length > 1 ? (
                                $getToolTipChip('modules', 'Modules', row.filter.modules)
                            ) : null}
                        </div>
                    </div>
                );
            case 'status':
                return <div>
                    <div className={styles.chipSuccess}>
                        <Typography className={styles.chipTextSuccess}>{row.status}</Typography>
                    </div>
                </div>;
            case 'download':
                return props.downloadingId === row.requestDate ? $loadingForItem :  <SimpleMenu onSelected={(format) => {
                    props.onGetFileUrl(row, format)
                }}/>
            default:
                return null;
        }
    }

    const $loading = (
        <Paper className={styles.loadingContainer}>
            <CircularProgress/>
            <div className={styles.loadingText}>Loading...</div>
        </Paper>
    )

    if (props.loading) {
        return $loading
    }

    const $empty = (
        <div className={styles.emptyContainer}>
            <InsertChart className={styles.emptyContainerIcon}/>
            <Typography className={styles.emptyContainerTitle}>Apply Filtering to Generate Reports</Typography>
            <Typography className={styles.emptyContainerDescription}>Add 1 or more filters and click Generate Report to
                see the results.</Typography>
        </div>
    )

    if (!props.dataSource.length) {
        return $empty
    }

    return (
        <Paper className={styles.container} style={{height: props.containerHeight}}>
            <TableContainer>
                <Table stickyHeader size={'small'} aria-label="sticky table">
                    <TableHead className={styles.header}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={'left'}
                                    style={{minWidth: column.minWidth}}
                                >
                                    <Typography className={styles.headerTitle}>{column.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataSource.map((row, idx) => {
                            return (
                                <TableRow className={styles.cell} hover tabIndex={-1} key={idx}>
                                    {columns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={'left'}
                                                       style={{minWidth: column.minWidth}}>
                                                {$cellContentByColumnId(column.id, row)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Paper>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="items per page"
                />
            </Paper>
        </Paper>
    );
}
