export const normalizePhoneNumberInput = (value: any, previousValue: any) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength <= 3) {
      // 123
      return currentValue;
    }
    if (cvLength <= 7) {
      // 123-456
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    } else {
      // 123-456-7890
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
};

export const handlePhoneNumber = (value: any) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (value.length) {
    if (cvLength <= 3) {
      // 123
      return currentValue;
    }
    if (cvLength < 7) {
      // 123-456
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    } else {
      // 123-456-7890
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
};

export const formatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};
