import { SvgIcon, SvgIconProps } from '@material-ui/core';

interface Props extends SvgIconProps {
  //   className?: string;
  style?: object;
}

export const BellWithDotIcon = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 5.5C21 7.43 19.43 9 17.5 9C15.57 9 14 7.43 14 5.5C14 3.57 15.57 2 17.5 2C19.43 2 21 3.57 21 5.5ZM19 10.79C18.5 10.92 18 11 17.5 11C14.47 11 12 8.53 12 5.5C12 4.03 12.58 2.7 13.5 1.71C13.15 1.28 12.61 1 12 1C10.9 1 10 1.9 10 3V3.29C7.03 4.17 5 6.9 5 10V16L3 18V19H21V18L19 16V10.79ZM12 22C13.11 22 14 21.11 14 20H10C10 21.11 10.9 22 12 22Z"
        fill="#D1D1D1"
      />
      <path
        d="M21 5.5C21 7.43 19.43 9 17.5 9C15.57 9 14 7.43 14 5.5C14 3.57 15.57 2 17.5 2C19.43 2 21 3.57 21 5.5Z"
        fill="#B7373E"
      />
    </SvgIcon>
  );
};
