import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { AutoRetryType } from './useAutoRetry';
import { ResendIcon } from '../assets/Icons/PanelSummary';
import { EligibilityCheckStatusType } from './useEligibilityCheckStatus';

export type EligibilityCheckButtonContentType = {
  icon: JSX.Element | null;
  text: string;
  textStyle: string;
};

export type EligibilityCheckButtonStatusType = {
  showButton: boolean;
  buttonContent: EligibilityCheckButtonContentType | null;
};

export const useEligibilityCheckButtonStatus = (
  patientInfo: any,
  autoRetry: AutoRetryType,
  eligibilityCheckStatus: EligibilityCheckStatusType | undefined,
  classes: ClassNameMap
) => {
  const [
    eligibilityCheckButtonStatus,
    setEligibilityCheckButtonStatus,
  ] = React.useState<EligibilityCheckButtonStatusType>({
    showButton: false,
    buttonContent: null,
  });
  // console.log(
  //   'eligibilityCheckButtonStatus.showButton',
  //   eligibilityCheckButtonStatus
  // );
  // console.log('eligibilityCheckStatus', eligibilityCheckStatus);
  // console.log('autoRetry', autoRetry);
  React.useEffect(() => {
    // set the visibility of the Eligibility Check button
    if (
      patientInfo
      // && patientInfo.eligibilityInfo &&
      // patientInfo.eligibilityInfo.updateTimeExt
    ) {
      if (
        patientInfo.eligibilityInfo?.updateTimeExt?.toLocaleLowerCase() ===
        'today'
      ) {
        // it updateTime is today, not showing the Eligibility Check button
        setEligibilityCheckButtonStatus({
          showButton: false,
          buttonContent: null,
        });
      } else {
        if (eligibilityCheckStatus === undefined) {
          // show the Eligibility Check button by default
          setEligibilityCheckButtonStatus({
            showButton: true,
            buttonContent: {
              icon: null,
              text: 'Eligibility Check',
              textStyle: classes.checkEligibilityButton,
            },
          });
          return;
        } else {
          // retry or in the middle of checking(not showing the button)
          if (
            autoRetry.currentRetryTime === 0 &&
            !eligibilityCheckStatus.checkStatus
          ) {
            // show retry as previous result is failed
            setEligibilityCheckButtonStatus({
              showButton: true,
              buttonContent: {
                icon: <ResendIcon className={classes.retryIcon} />,
                text: 'Re-try',
                textStyle: classes.checkEligibilityButton,
              },
            });
            return;
          } else {
            // in the middle of checking, not showing the button
            setEligibilityCheckButtonStatus({
              showButton: false,
              buttonContent: null,
            });
          }
        }
      }
    }
  }, [patientInfo, eligibilityCheckStatus, autoRetry.currentRetryTime]);

  return { eligibilityCheckButtonStatus };
};
