import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { useEligibilityBadge } from '../../hooks/useEligibilityBadge';
import { Feature } from '../FeatureFlag';

interface EligibilityBadgeProps {
  patientInfo: any;
  pageToDisplay: 'PatientTable' | 'PatientFaceSheet';
}

export const EligibilityBadge = (props: EligibilityBadgeProps) => {
  const { patientInfo, pageToDisplay } = props;
  const { eligibilityBadge } = useEligibilityBadge(pageToDisplay, patientInfo);

  return (
    <Feature name="eligibilityStatusBadgeInPatientFaceTable">
      {(hasFeature) =>
        hasFeature ? (
          <div>
            {/* Eligibility Badge */}
            {eligibilityBadge && (
              <StyledTooltip
                title={
                  <span>
                    {[
                      eligibilityBadge.lastCheck,
                      <br />,
                      eligibilityBadge.status,
                      <br />,
                      eligibilityBadge.rejectedReason ? `Unverified Reason: ${eligibilityBadge.rejectedReason}` : null
                    ]}
                  </span>
                }
                placement="bottom-start"
              >
                <span>{eligibilityBadge.icon}</span>
              </StyledTooltip>
            )}
          </div>
        ) : null
      }
    </Feature>
  );
};

const StyledTooltip = withStyles({
  tooltip: {
    background: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '8px 10px',
    height: 'fit-content',
    color: '#FFFFFF',
    maxWidth: 300,
  },
  popper: {
    top: '-38px !important',
    left: '12px !important',
  },
})(Tooltip);
