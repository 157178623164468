import React from 'react';
import {
    FormControl,
    MenuItem,
    Popper,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { theme } from '../../themes/theme';
import '../../expanded-theme';
import { Autocomplete } from '@material-ui/lab';
import { StyledCheckbox } from '../StyledCheckbox';
import { ProfileItem } from '../ProfileItem'
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import MenuList from '@material-ui/core/MenuList';

const AVATAR_BG = Object.values(theme.palette.avatars);

interface IProps {
    type: string,
    options: any[],
    selectedOptions: any[],
    width?: number,
    title: string,
    inputPlaceholder?: string,
    noOptionsText?: string,
    onChangedOptions: (type: string, value: any[]) => void
}

const LISTBOX_PADDING = 0; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListBoxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 40;

    const getChildSize = (child: React.ReactNode) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const PopperMy = (props: any) => {
    return (
        <Popper
            {...props}
            style={{
                width: 320,
            }}
            placement="bottom-start"
        />
    );
};


export const AutoCompleteMultipleSelect = (props: IProps) => {
    const classes = useStyles();
    const {type, title, options, selectedOptions, inputPlaceholder, noOptionsText,
        onChangedOptions
    } = props

    return (
        <div
            className={classes.multipleSelect}
            style={{ width: props.width ?? '100%' }}
        >
            <FormControl className={classes.formControl}>
                <Typography
                    id={`${title}`}
                    variant="h5"
                    className={classes.inputTitle}
                >
                    {title}
                </Typography>


                <Autocomplete
                    disableCloseOnSelect={true}
                    options={options}
                    value={selectedOptions}
                    className={classes.autoComplete}
                    getOptionLabel={(option) => option.firstName + option.lastName || ''}
                    getOptionSelected={(option, value) => option.id == value?.id}
                    popupIcon={<KeyboardArrowDown />}
                    closeIcon={false}
                    renderTags={() => null}
                    multiple
                    onChange={(event, newValue) => {
                        onChangedOptions(type, newValue);
                    }}
                    ListboxComponent={ListBoxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            classes={{
                                root:
                                    props.selectedOptions.length > 0
                                        ? classes.selectedCustomTextField
                                        : classes.customTextField,
                            }}
                            variant="outlined"
                            placeholder={
                                props.selectedOptions.length > 0
                                    ? `${props.selectedOptions.length} selected`
                                    : inputPlaceholder ??
                                    'Search and Select Covering Provider(s)'
                            }
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    classes={{
                        tag: classes.chip,
                        option: classes.acOption,
                        noOptions: classes.noAcOption,
                    }}
                    renderOption={(option, { selected }) => {
                        const index = options.findIndex((item: any) => item.id === option.id);
                        return option?.id === 'NoCoveringProvider' ? (
                            <MenuItem
                                component="div"
                                selected={selectedOptions.find(
                                    (selectedItem: any) => selectedItem.id === option.id
                                )}
                                classes={{
                                    root: classes.listItem,
                                    selected: classes.selected,
                                }}
                                className={classes.itemRow}
                                key={option}
                            >
                                <div className={classes.avatarRowItem}>
                                    <div>
                                        <Typography
                                            variant="body1"
                                            className={classes.selectMenuContent}
                                            style={{
                                                fontWeight: 600,
                                                color: selected ? 'inherit' : theme.palette.grey[600],
                                            }}
                                        >
                                            No Coverby
                                        </Typography>
                                    </div>
                                    <div className={classes.countAndCheckbox}>
                                        <StyledCheckbox
                                            checked={selected}
                                            checkedAll={true}
                                            className={classes.checkBox}
                                        />
                                    </div>
                                </div>
                            </MenuItem>
                        ) : (
                            <MenuItem
                                component="div"
                                selected={selectedOptions.find(
                                    (item: any) => item?.id === option?.id)}
                                classes={{
                                    root: classes.listItem,
                                    selected: classes.selected,
                                }}
                                key={option?.id??option}
                               className={classes.itemRow}
                            >
                                <div
                                    className={classes.avatarRowItem}
                                    style={{
                                        color: selected ? 'inherit' : theme.palette.grey[600],
                                    }}
                                >
                                    <ProfileItem
                                        avatarBgColor={AVATAR_BG[(index??0) % AVATAR_BG.length]}
                                        firstName={option?.firstName || ''}
                                        lastName={option?.lastName || ''}
                                    />
                                    <div className={classes.countAndCheckbox}>
                                        <StyledCheckbox
                                            checked={selected || selectedOptions?.find(
                                                (item: any) => item?.id === option?.id
                                            )!=undefined}
                                            checkedAll={true}
                                            className={classes.checkBox}
                                        />
                                    </div>
                                </div>
                            </MenuItem>
                        );
                    }}
                     PopperComponent={
                         PopperMy
                     }
                />
            </FormControl>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': {
            borderRadius: 8,
        },
    },
    itemRow: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
    },
    checkBox: {
        padding: 0,
    },
    countAndCheckbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 12,
    },
    avatarRowItem: {
        width: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8,

        '& > div:first-child > p': {
            maxWidth: 194,
            fontWeight: 500,
            lineHeight: '24px',
        },
    },
    selectedCustomTextField: {
        '& input::placeholder': {
            fontWeight: 500,
            color: '#656565',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    customTextField: {
        '& input::placeholder': {
            fontWeight: 500,
            color: '#ABABAB',
        },
        '& > div:first-child': {
            paddingRight: `60px!important`,
        },
    },
    selectMenuContent: {
        display: 'block',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
    },
    multipleSelect: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        width: '100%',

        marginTop: 24,
    },
    selected: {
        backgroundColor: '#EDF6FF!important',
        color: '#0C77D8!important',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        marginBottom: 1,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    formControl: {
        width: '100%',

        '& .MuiInputBase-root': {
            height: 40,
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
        },
    },
    inputTitle: {
        height: 24,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.7,
        textTransform: 'capitalize',
        marginBottom: 4,
    },
    select: {
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 12,
        fontWeight: 600,
        borderRadius: 100,
        height: 32,
        marginRight: theme.spacing(0.5),
        '& .MuiChip-deleteIcon': {
            color: 'white',
            height: 20,
            width: 20,
            opacity: 0.5,
        },
    },
    acOption: {
        padding: theme.spacing(0, 1, 0.5, 1),
        backgroundColor: 'white',
        '&[aria-selected="true"]': {
            backgroundColor: 'white',
        },
        '& ::hover': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: 'transparent',
        },
    },
    noAcOption: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[600],
    },
    autoComplete: {
        display: 'flex',
        width: '100%',
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: theme.spacing(0.25, 0, 0.25, 1),
            minHeight: 40,
            fontSize: 16,
            borderRadius: 8,
            '& .MuiAutocomplete-input': {
                paddingRight: 0,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingLeft: theme.spacing(1),
            },
        },
        '& ::placeholder': {
            color: theme.palette.grey[400],
            opacity: 1,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
}));
