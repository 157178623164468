import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const OpenedEyeIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M11 11.75C12.1833 11.75 13.1875 11.3375 14.0125 10.5125C14.8375 9.6875 15.25 8.68333 15.25 7.5C15.25 6.31667 14.8375 5.3125 14.0125 4.4875C13.1875 3.6625 12.1833 3.25 11 3.25C9.81667 3.25 8.8125 3.6625 7.9875 4.4875C7.1625 5.3125 6.75 6.31667 6.75 7.5C6.75 8.68333 7.1625 9.6875 7.9875 10.5125C8.8125 11.3375 9.81667 11.75 11 11.75ZM11 10.3C10.2167 10.3 9.55417 10.0292 9.0125 9.4875C8.47083 8.94583 8.2 8.28333 8.2 7.5C8.2 6.71667 8.47083 6.05417 9.0125 5.5125C9.55417 4.97083 10.2167 4.7 11 4.7C11.7833 4.7 12.4458 4.97083 12.9875 5.5125C13.5292 6.05417 13.8 6.71667 13.8 7.5C13.8 8.28333 13.5292 8.94583 12.9875 9.4875C12.4458 10.0292 11.7833 10.3 11 10.3ZM11 15C8.56667 15 6.36667 14.3083 4.4 12.925C2.43333 11.5417 0.966667 9.73333 0 7.5C0.966667 5.26667 2.43333 3.45833 4.4 2.075C6.36667 0.691666 8.56667 0 11 0C13.4333 0 15.6333 0.691666 17.6 2.075C19.5667 3.45833 21.0333 5.26667 22 7.5C21.0333 9.73333 19.5667 11.5417 17.6 12.925C15.6333 14.3083 13.4333 15 11 15ZM11 13.5C13.0167 13.5 14.8708 12.9542 16.5625 11.8625C18.2542 10.7708 19.5417 9.31667 20.425 7.5C19.5417 5.68333 18.2542 4.22917 16.5625 3.1375C14.8708 2.04583 13.0167 1.5 11 1.5C8.98333 1.5 7.12917 2.04583 5.4375 3.1375C3.74583 4.22917 2.45 5.68333 1.55 7.5C2.45 9.31667 3.74583 10.7708 5.4375 11.8625C7.12917 12.9542 8.98333 13.5 11 13.5Z"
  />
);
