export function capitalizeStringPipe(string: string) {
  if (!string) {
    return '';
  }
  try {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } catch (e: any) {
    return '';
  }
}
