import {Button, Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {EligibilityCategory} from "../../../Dashboard/components/EligibilityCategory/EligibilityCategoryList";

interface IProps {
  activeInternal: number | undefined,
  activeExternal: number | undefined,
  rejected: number | undefined,
  inactive: number | undefined,
  batchSmsCount: number | undefined,
  unavailable: number | undefined,
  selectedType: EligibilityCategory,
  onSelect: (type: EligibilityCategory) => void,
}

export function PatientListTabView(props: IProps) {
  const {activeExternal, activeInternal, inactive, rejected, unavailable, batchSmsCount, selectedType, onSelect} = props
  const classes = useStyles()
  const renderTabItem = (title: string, valueNumber: number | undefined, type: EligibilityCategory, idx: number) => {
    const isSelected = type === selectedType
    return <Button key={idx} onClick={() => {
      onSelect && onSelect(type)
    }} variant={'text'} className={classes.itemContainer}>
      <div className={isSelected ? classes.itemSelected : classes.item}>
        <div className={isSelected ? classes.titleSelected : classes.title}>
          {title}
        </div>
        <Chip className={isSelected ? classes.chipSelected : classes.chip} label={valueNumber ? valueNumber.toLocaleString('en-US') : "0"} />
      </div>
    </Button>
  }

  return (
    <div className={classes.root}>
      {renderTabItem("Active (Internal PCP) ", activeInternal, EligibilityCategory.internalActive, 0)}
      {renderTabItem("Active (External PCP) ", activeExternal, EligibilityCategory.externalActive,1)}
      {renderTabItem("Inactive", inactive, EligibilityCategory.inactive, 2)}
      {renderTabItem("Unverified", rejected, EligibilityCategory.unverified,3)}
      {renderTabItem("Unavailable", unavailable, EligibilityCategory.unavailable, 4)}
      {renderTabItem("Batch SMS Log", batchSmsCount, EligibilityCategory.engagement, 100)}
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #d1d1d1',
    backgroundColor: '#FFF',
    paddingLeft: 16,
    height: 48,
  },
  itemContainer: {
    padding: '0px 8px',
    marginRight: 16,
    borderRadius: 0,
    maxWidth: 'content',
    height: 48,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '3px solid #0C77D800',
    height: 48,
  },
  itemSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '3px solid #0C77D8',
    height: 48,
  },
  title: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    color: '#656565',
  },
  titleSelected: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  chip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    height: 20,
    background: theme.palette.grey[100],

    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.grey[500],
    },
  },
  chipSelected: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    height: 20,
    background: theme.palette.primary.main,

    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      fontWeight: 'bold',
      fontSize: 12,
      color: '#FFF',
    },
  },
}));
