import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useProviderPatientFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.grey[50],
    },
    container: {
      padding: theme.spacing(1.5, 3.75),
    },
    selectAndButton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      gap: 12,
    },
    selectRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'flex-start',
      rowGap: 12,
    },
    selectContainer: {
      width: 229,
      '& > div': {
        marginTop: '0',
      },
    },
    daysSelectContainer: {
      width: 458,
      display: 'flex',
      '& > div': {
        marginTop: '0',
      },
      '& h5 + div': { marginBottom: 0 },
    },
    divider: {
      width: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dividerText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['500'],
      lineHeight: '16px',
      fontSize: 12,
      paddingTop: 30,
    },
    ButtonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 24,
      flexGrow: 1,
      width: '100%',
    },
    applyFilterBtnAndClearFilterBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 24,
      flexGrow: 1,
    },
    applyFilterBtn: {
      color: '#FFF',
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: 102,
      height: 32,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    disabledApplyFilterBtn: {
      color: 'rgba(255,255,255,0.5)!important',
      background: `${theme.palette.primary.main}!important`,
    },
    clearFilterContainer: {
      display: 'flex',
      alignItems: 'center',
      width: 106,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    filterText: {
      lineHeight: '24px',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['600'],
      fontSize: 14,
    },
    closeIcon: {
      fontSize: 18,
      color: theme.palette.secondary.dark,
    },
  })
);
