import React from 'react';
import { PanelPatient } from '../../../../../../../../app.types.generated';
import {
  CareGapContext,
  useCareGapGlobalContext,
} from '../../../../../../../CareGaps/Home/tabs/Population/context';
import { useGetUsersByAccountIdQuery } from '../../../AssignedPatientsTable/index.generated';
import { useSwitchPatientWithPagination } from '../../../../hooks/useSwitchPatientWithPagination';
import { useKeyPatients } from '../../../../hooks/useKeyPatients';
import { useUnlockPatientClosingPFS } from '../../../../hooks/useUnlockPatientClosingPFS';
import { CareGapPatientNotFound } from '../../../../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTableNotFound';
import { DeListedPatientsTableView } from './View';
import { PanelPatientsListActionsFooter } from '../../../../../components/PanelPatientsListActionsFooter/PanelPatientsListActionsFooter';
import { getDiffsAsObject } from '../../../../../../../../sharePipe/getDiffsAsObject';
import { CareGapsDrawer } from '../../../../../../../CareGaps/Home/tabs/Population/components/CareGapsDrawer';
import { useDeListedPatients } from '../../../../hooks/useDeListedPatients';
import { CheckIsHudsonClinician } from '../../../../../../../../components/CheckIsAdmin';

export type QuerySortByType = {
  field: string;
  method: 'desc' | 'asc';
};

interface DeListedPatientsTableContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  actionsFooter: any[];
  setActionsFooter: any;
  hardFresh: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  enableTable: boolean;
  setSearchTextAndFilters: (input: any) => void;
  isSearching: boolean;
  assignCoordinator: any;
  enableChangeCoordinatorDialog: any;
  setEnableChangeCoordinatorDialog: any;
}

export const DeListedPatientsTableContainer = (
  props: DeListedPatientsTableContainerProps
) => {
  const [results, setResults] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [checkedPatient, setCheckedPatient] = React.useState<PanelPatient>(
    {} as PanelPatient
  );
  const [value, setValue] = React.useState(0); // 0 patient info, 1 Engagement, 2 Care Gaps
  const drawerHandler = (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    event.stopPropagation();
    setShowDrawer(!showDrawer);
    if (patient) {
      setCheckedPatient(patient);
    } else {
      setCheckedPatient({} as PanelPatient);
    }
  };

  const switchTab = (event: any, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const { searchName, searchDob, careGapFilters } = useCareGapGlobalContext();

  const [querySort, setQuerySort] = React.useState({
    field: 'fullName',
    method: 'asc',
  } as QuerySortByType);
  const defaultFilters = {
    // by default
    panelId: props.panelId,
    limit: props.limit ?? 10,
    skip: props.skip ?? 0,
    sortBy: [querySort],
  };

  const enableTableFilters = {
    // filter by search and filters
    panelId: props.panelId,
    limit: props.limit ?? 10,
    skip: props.skip,
    andName: searchName,
    andDob: searchDob,
    sortBy: [querySort],
  };

  const { deListedPatientQueryState } = useDeListedPatients(
    props.enableTable ? enableTableFilters : defaultFilters
  );
  const userQuery = useGetUsersByAccountIdQuery({
    variables: {
      getUsersByAccountIdRequest: {
        limit: 0,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
  });

  const coordinatorOptions = React.useMemo(() => {
    return userQuery.data?.getUsersByAccountId?.results.filter(
      (item) => !CheckIsHudsonClinician(item)
    );
  }, [userQuery.data]);

  React.useEffect(() => {
    props.setSearchTextAndFilters({
      andName: searchName,
      andDob: searchDob,
    });
  }, [searchName, searchDob]);

  React.useEffect(() => {
    if (props.skip !== undefined) {
      props.setSkip(0);
    }
  }, [props.limit]);

  const { currentPage } = useSwitchPatientWithPagination(
    props.skip || 0,
    props.limit || 10,
    results,
    setCheckedPatient,
    checkedPatient
  );
  const [refresher, setRefresher] = React.useState(0);
  const { keyPatients, setKeyPatients } = useKeyPatients(checkedPatient);
  const [patientFaceInfo, setPatientFaceInfo] = React.useState<any>();
  const { pfsOpenAndClosed } = useUnlockPatientClosingPFS(
    showDrawer,
    patientFaceInfo,
    props.panelId
  );

  const { openCareGaps, setOpenCareGaps } = React.useContext(CareGapContext);
  const prevCareGapCount = React.useRef(openCareGaps.length);
  React.useEffect(() => {
    if (prevCareGapCount.current !== openCareGaps.length) {
      deListedPatientQueryState.refetch();
      prevCareGapCount.current = openCareGaps.length;
    }
  }, [openCareGaps]);
  React.useEffect(() => {
    if (pfsOpenAndClosed) {
      // reset the care gap count as the patient is released
      setOpenCareGaps([]);
    }
  }, [pfsOpenAndClosed]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {props.enableTable && deListedPatientQueryState.count == 0 ? (
        <CareGapPatientNotFound isSearching={props.isSearching} />
      ) : (
        <>
          <DeListedPatientsTableView
            queryState={deListedPatientQueryState}
            sortBy={querySort}
            setSortBy={setQuerySort}
            panelId={props.panelId}
            patients={deListedPatientQueryState.results}
            limit={props.limit ?? 10}
            skip={props.skip ?? 0}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            setSelected={props.setSelected}
            selected={props.selected}
            setActionsFooter={props.setActionsFooter}
            allSelected={props.allSelected}
            setAllSelected={props.setAllSelected}
            excluded={props.excluded}
            setExcluded={props.setExcluded}
            drawerHandler={drawerHandler}
            patientCount={deListedPatientQueryState.count || 0}
            setResults={setResults}
            setValue={setValue}
          />
          {props.actionsFooter.length > 0 && (
            <PanelPatientsListActionsFooter
              itemName={'Patients'}
              selectedItems={props.actionsFooter}
              setSelected={props.setSelected}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              tabs={props.availableActions}
              panelId={props.panelId}
              count={deListedPatientQueryState.count || 0}
              hardFresh={props.hardFresh}
              coordinatorOptions={coordinatorOptions ?? []}
              enableChangeCoordinatorDialog={
                props.enableChangeCoordinatorDialog
              }
              setEnableChangeCoordinatorDialog={
                props.setEnableChangeCoordinatorDialog
              }
              loading={loading}
              setLoading={setLoading}
              clicked={clicked}
              setClicked={setClicked}
              filters={
                props.enableTable
                  ? getDiffsAsObject(enableTableFilters, defaultFilters)
                  : null
              }
              changeCoordinator={props.assignCoordinator}
            />
          )}
          {showDrawer && (
            <CareGapsDrawer
              allPatients={results}
              drawerHandler={drawerHandler}
              checkedPatient={checkedPatient}
              tabIndex={value}
              switchTab={switchTab}
              currentPage={currentPage}
              totalCount={deListedPatientQueryState.count || 0}
              limit={props.limit ?? 10}
              skip={props.skip ?? 0}
              setSkip={props.setSkip}
              refresher={refresher}
              setRefresher={setRefresher}
              setPatientFaceInfo={setPatientFaceInfo}
              keyPatients={keyPatients}
              setKeyPatients={setKeyPatients}
            />
          )}
        </>
      )}
    </div>
  );
};
