import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MainRegion } from '../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../components/BreadCrumbHeader';
import { CareGapsListContainer } from './PayerCareGapList';
import { useGetPayerFileByIdQuery } from '../index.generated';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { useLocation, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const PayerCareGapView = () => {
  const classes = useStyles();
  const [title, setTitle] = React.useState('');
  const { id } = useParams();
  const location = useLocation();
  const resp = useGetPayerFileByIdQuery({
    variables: {
      request: {
        fileId: id ?? '',
      },
    },
  });
  const name = location?.state?.name ?? '';
  const queryResult = useFetchTableData(resp);
  React.useEffect(() => {
    try {
      setTitle(queryResult?.getPayerFileById?.fileName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [queryResult]);
  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={title}
          rootLink={`/payerFiles`}
          rootName={'Payer Files'}
          isTitle={true}
          childrenLink={`/payerFiles/` + id}
          title={name}
          childrenChildrenName={name}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            <CareGapsListContainer />
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
