import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SendPanelSmsMutationVariables = Types.Exact<{
  input: Types.SendPanelSmsRequest;
}>;


export type SendPanelSmsMutation = (
  { __typename: 'Mutation' }
  & { sendPanelSms: (
    { __typename: 'SendSmsResponse' }
    & Pick<Types.SendSmsResponse, 'isSuccess' | 'message' | 'batchId'>
  ) }
);

export type SendAllSmsMutationVariables = Types.Exact<{
  input: Types.SendPanelSmsRequest;
}>;


export type SendAllSmsMutation = (
  { __typename: 'Mutation' }
  & { sendPanelSms: (
    { __typename: 'SendSmsResponse' }
    & Pick<Types.SendSmsResponse, 'isSuccess' | 'message' | 'batchId'>
  ) }
);

export type MovePatientBetweenCareTeamUsersMutationVariables = Types.Exact<{
  input: Types.MovePatientBetweenCareTeamUsersInput;
}>;


export type MovePatientBetweenCareTeamUsersMutation = (
  { __typename: 'Mutation' }
  & { movePatientBetweenCareTeamUsers: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);

export type MoveUnassignedPatientToCareTeamUsersMutationVariables = Types.Exact<{
  input: Types.MoveUnassignedPatientToCareTeamUsersInput;
}>;


export type MoveUnassignedPatientToCareTeamUsersMutation = (
  { __typename: 'Mutation' }
  & { moveUnassignedPatientToCareTeamUsers: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);

export type GetTaskStatusQueryVariables = Types.Exact<{
  input: Types.TaskStatusQueryInput;
}>;


export type GetTaskStatusQuery = (
  { __typename: 'Query' }
  & { getTaskStatus: (
    { __typename: 'TaskStatusResponse' }
    & Pick<Types.TaskStatusResponse, 'status'>
  ) }
);

export type UpdateDelistedPatientToCareTeamUsersMutationVariables = Types.Exact<{
  input: Types.UpdateDelistedPatientToCareTeamUsersInput;
}>;


export type UpdateDelistedPatientToCareTeamUsersMutation = (
  { __typename: 'Mutation' }
  & { updateDelistedPatientToCareTeamUsers: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId' | 'errorMessage'>
  ) }
);


export const SendPanelSmsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendPanelSms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SendPanelSmsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendPanelSms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"batchId"}}]}}]}}]};
export type SendPanelSmsMutationFn = Apollo.MutationFunction<SendPanelSmsMutation, SendPanelSmsMutationVariables>;

/**
 * __useSendPanelSmsMutation__
 *
 * To run a mutation, you first call `useSendPanelSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPanelSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPanelSmsMutation, { data, loading, error }] = useSendPanelSmsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPanelSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendPanelSmsMutation, SendPanelSmsMutationVariables>) {
        return Apollo.useMutation<SendPanelSmsMutation, SendPanelSmsMutationVariables>(SendPanelSmsDocument, baseOptions);
      }
export type SendPanelSmsMutationHookResult = ReturnType<typeof useSendPanelSmsMutation>;
export type SendPanelSmsMutationResult = Apollo.MutationResult<SendPanelSmsMutation>;
export type SendPanelSmsMutationOptions = Apollo.BaseMutationOptions<SendPanelSmsMutation, SendPanelSmsMutationVariables>;
export const SendAllSmsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendAllSms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SendPanelSmsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendPanelSms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"batchId"}}]}}]}}]};
export type SendAllSmsMutationFn = Apollo.MutationFunction<SendAllSmsMutation, SendAllSmsMutationVariables>;

/**
 * __useSendAllSmsMutation__
 *
 * To run a mutation, you first call `useSendAllSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAllSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAllSmsMutation, { data, loading, error }] = useSendAllSmsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAllSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendAllSmsMutation, SendAllSmsMutationVariables>) {
        return Apollo.useMutation<SendAllSmsMutation, SendAllSmsMutationVariables>(SendAllSmsDocument, baseOptions);
      }
export type SendAllSmsMutationHookResult = ReturnType<typeof useSendAllSmsMutation>;
export type SendAllSmsMutationResult = Apollo.MutationResult<SendAllSmsMutation>;
export type SendAllSmsMutationOptions = Apollo.BaseMutationOptions<SendAllSmsMutation, SendAllSmsMutationVariables>;
export const MovePatientBetweenCareTeamUsersDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"movePatientBetweenCareTeamUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MovePatientBetweenCareTeamUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"movePatientBetweenCareTeamUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type MovePatientBetweenCareTeamUsersMutationFn = Apollo.MutationFunction<MovePatientBetweenCareTeamUsersMutation, MovePatientBetweenCareTeamUsersMutationVariables>;

/**
 * __useMovePatientBetweenCareTeamUsersMutation__
 *
 * To run a mutation, you first call `useMovePatientBetweenCareTeamUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePatientBetweenCareTeamUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePatientBetweenCareTeamUsersMutation, { data, loading, error }] = useMovePatientBetweenCareTeamUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePatientBetweenCareTeamUsersMutation(baseOptions?: Apollo.MutationHookOptions<MovePatientBetweenCareTeamUsersMutation, MovePatientBetweenCareTeamUsersMutationVariables>) {
        return Apollo.useMutation<MovePatientBetweenCareTeamUsersMutation, MovePatientBetweenCareTeamUsersMutationVariables>(MovePatientBetweenCareTeamUsersDocument, baseOptions);
      }
export type MovePatientBetweenCareTeamUsersMutationHookResult = ReturnType<typeof useMovePatientBetweenCareTeamUsersMutation>;
export type MovePatientBetweenCareTeamUsersMutationResult = Apollo.MutationResult<MovePatientBetweenCareTeamUsersMutation>;
export type MovePatientBetweenCareTeamUsersMutationOptions = Apollo.BaseMutationOptions<MovePatientBetweenCareTeamUsersMutation, MovePatientBetweenCareTeamUsersMutationVariables>;
export const MoveUnassignedPatientToCareTeamUsersDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"moveUnassignedPatientToCareTeamUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MoveUnassignedPatientToCareTeamUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveUnassignedPatientToCareTeamUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type MoveUnassignedPatientToCareTeamUsersMutationFn = Apollo.MutationFunction<MoveUnassignedPatientToCareTeamUsersMutation, MoveUnassignedPatientToCareTeamUsersMutationVariables>;

/**
 * __useMoveUnassignedPatientToCareTeamUsersMutation__
 *
 * To run a mutation, you first call `useMoveUnassignedPatientToCareTeamUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveUnassignedPatientToCareTeamUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveUnassignedPatientToCareTeamUsersMutation, { data, loading, error }] = useMoveUnassignedPatientToCareTeamUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveUnassignedPatientToCareTeamUsersMutation(baseOptions?: Apollo.MutationHookOptions<MoveUnassignedPatientToCareTeamUsersMutation, MoveUnassignedPatientToCareTeamUsersMutationVariables>) {
        return Apollo.useMutation<MoveUnassignedPatientToCareTeamUsersMutation, MoveUnassignedPatientToCareTeamUsersMutationVariables>(MoveUnassignedPatientToCareTeamUsersDocument, baseOptions);
      }
export type MoveUnassignedPatientToCareTeamUsersMutationHookResult = ReturnType<typeof useMoveUnassignedPatientToCareTeamUsersMutation>;
export type MoveUnassignedPatientToCareTeamUsersMutationResult = Apollo.MutationResult<MoveUnassignedPatientToCareTeamUsersMutation>;
export type MoveUnassignedPatientToCareTeamUsersMutationOptions = Apollo.BaseMutationOptions<MoveUnassignedPatientToCareTeamUsersMutation, MoveUnassignedPatientToCareTeamUsersMutationVariables>;
export const GetTaskStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTaskStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TaskStatusQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTaskStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]};

/**
 * __useGetTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
        return Apollo.useQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
      }
export function useGetTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
        }
export type GetTaskStatusQueryHookResult = ReturnType<typeof useGetTaskStatusQuery>;
export type GetTaskStatusLazyQueryHookResult = ReturnType<typeof useGetTaskStatusLazyQuery>;
export type GetTaskStatusQueryResult = Apollo.QueryResult<GetTaskStatusQuery, GetTaskStatusQueryVariables>;
export function refetchGetTaskStatusQuery(variables?: GetTaskStatusQueryVariables) {
      return { query: GetTaskStatusDocument, variables: variables }
    }
export const UpdateDelistedPatientToCareTeamUsersDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateDelistedPatientToCareTeamUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateDelistedPatientToCareTeamUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateDelistedPatientToCareTeamUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]};
export type UpdateDelistedPatientToCareTeamUsersMutationFn = Apollo.MutationFunction<UpdateDelistedPatientToCareTeamUsersMutation, UpdateDelistedPatientToCareTeamUsersMutationVariables>;

/**
 * __useUpdateDelistedPatientToCareTeamUsersMutation__
 *
 * To run a mutation, you first call `useUpdateDelistedPatientToCareTeamUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDelistedPatientToCareTeamUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDelistedPatientToCareTeamUsersMutation, { data, loading, error }] = useUpdateDelistedPatientToCareTeamUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDelistedPatientToCareTeamUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDelistedPatientToCareTeamUsersMutation, UpdateDelistedPatientToCareTeamUsersMutationVariables>) {
        return Apollo.useMutation<UpdateDelistedPatientToCareTeamUsersMutation, UpdateDelistedPatientToCareTeamUsersMutationVariables>(UpdateDelistedPatientToCareTeamUsersDocument, baseOptions);
      }
export type UpdateDelistedPatientToCareTeamUsersMutationHookResult = ReturnType<typeof useUpdateDelistedPatientToCareTeamUsersMutation>;
export type UpdateDelistedPatientToCareTeamUsersMutationResult = Apollo.MutationResult<UpdateDelistedPatientToCareTeamUsersMutation>;
export type UpdateDelistedPatientToCareTeamUsersMutationOptions = Apollo.BaseMutationOptions<UpdateDelistedPatientToCareTeamUsersMutation, UpdateDelistedPatientToCareTeamUsersMutationVariables>;