import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type UpdateCareCoordinatorMutationVariables = Types.Exact<{
  input: Types.UpdatePanelCareTeamUsersInput;
}>;


export type UpdateCareCoordinatorMutation = (
  { __typename: 'Mutation' }
  & { updatePanelCareTeamUsers: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);

export type ReassignPatientsMutationVariables = Types.Exact<{
  input: Types.ReassignPatientsInput;
}>;


export type ReassignPatientsMutation = (
  { __typename: 'Mutation' }
  & { reassignPatients: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);

export type GetTaskStatusQueryVariables = Types.Exact<{
  input: Types.TaskStatusQueryInput;
}>;


export type GetTaskStatusQuery = (
  { __typename: 'Query' }
  & { getTaskStatus: (
    { __typename: 'TaskStatusResponse' }
    & Pick<Types.TaskStatusResponse, 'status'>
  ) }
);


export const UpdateCareCoordinatorDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateCareCoordinator"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePanelCareTeamUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePanelCareTeamUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type UpdateCareCoordinatorMutationFn = Apollo.MutationFunction<UpdateCareCoordinatorMutation, UpdateCareCoordinatorMutationVariables>;

/**
 * __useUpdateCareCoordinatorMutation__
 *
 * To run a mutation, you first call `useUpdateCareCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareCoordinatorMutation, { data, loading, error }] = useUpdateCareCoordinatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareCoordinatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCareCoordinatorMutation, UpdateCareCoordinatorMutationVariables>) {
        return Apollo.useMutation<UpdateCareCoordinatorMutation, UpdateCareCoordinatorMutationVariables>(UpdateCareCoordinatorDocument, baseOptions);
      }
export type UpdateCareCoordinatorMutationHookResult = ReturnType<typeof useUpdateCareCoordinatorMutation>;
export type UpdateCareCoordinatorMutationResult = Apollo.MutationResult<UpdateCareCoordinatorMutation>;
export type UpdateCareCoordinatorMutationOptions = Apollo.BaseMutationOptions<UpdateCareCoordinatorMutation, UpdateCareCoordinatorMutationVariables>;
export const ReassignPatientsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"reassignPatients"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReassignPatientsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reassignPatients"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type ReassignPatientsMutationFn = Apollo.MutationFunction<ReassignPatientsMutation, ReassignPatientsMutationVariables>;

/**
 * __useReassignPatientsMutation__
 *
 * To run a mutation, you first call `useReassignPatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignPatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignPatientsMutation, { data, loading, error }] = useReassignPatientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReassignPatientsMutation(baseOptions?: Apollo.MutationHookOptions<ReassignPatientsMutation, ReassignPatientsMutationVariables>) {
        return Apollo.useMutation<ReassignPatientsMutation, ReassignPatientsMutationVariables>(ReassignPatientsDocument, baseOptions);
      }
export type ReassignPatientsMutationHookResult = ReturnType<typeof useReassignPatientsMutation>;
export type ReassignPatientsMutationResult = Apollo.MutationResult<ReassignPatientsMutation>;
export type ReassignPatientsMutationOptions = Apollo.BaseMutationOptions<ReassignPatientsMutation, ReassignPatientsMutationVariables>;
export const GetTaskStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTaskStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TaskStatusQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTaskStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]};

/**
 * __useGetTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
        return Apollo.useQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
      }
export function useGetTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
        }
export type GetTaskStatusQueryHookResult = ReturnType<typeof useGetTaskStatusQuery>;
export type GetTaskStatusLazyQueryHookResult = ReturnType<typeof useGetTaskStatusLazyQuery>;
export type GetTaskStatusQueryResult = Apollo.QueryResult<GetTaskStatusQuery, GetTaskStatusQueryVariables>;
export function refetchGetTaskStatusQuery(variables?: GetTaskStatusQueryVariables) {
      return { query: GetTaskStatusDocument, variables: variables }
    }