import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { User } from '../../../../../../../../../app.types.generated';
import { MenuItem, TextField, Theme, makeStyles } from '@material-ui/core';
import { ButtonMediumPrimaryText } from '../../../../../../../../../components/Buttons';
import { AddIcon } from '../../../../../../../../../assets/Icons/PanelsList';
import { LabelAvatar } from '../../../../../../../../../components/Avatar';
import { CheckIcon } from '../../../../../../../../../assets/Icons/CreatePanel/CheckIcon';
import { StyledCheckbox } from '../../../../../../../../../components/StyledCheckbox';

interface UserAutocompleteProps {
  location: string;
  //   selectedItems: any;
  selectedItems: any;
  openOptions: boolean;
  onOpenOptions: (e: any) => void;
  onCloseOptions: () => void;
  userInputValue: string;
  onUserInputChange: (event: object, value: string, reason: string) => void;
  multiple: boolean;
  //   options: User[];
  options: any;
  getOptionLabel: (option: User) => string;
  getOptionSelected: (option: User, value: User) => boolean;
  //   value: User[];
  value: any;
  onValueChange: (e: React.ChangeEvent<{}>, v: User | null) => void;
  popupIcon: React.ReactNode;
  closeIcon: boolean;
  inputPlaceholder: string;
  noOptionsText: string;
}

export const UserAutocomplete: React.FC<UserAutocompleteProps> = ({
  location,
  selectedItems,
  openOptions,
  onOpenOptions,
  onCloseOptions,
  userInputValue,
  onUserInputChange,
  multiple,
  options,
  getOptionLabel,
  getOptionSelected,
  value,
  onValueChange,
  popupIcon,
  closeIcon,
  inputPlaceholder,
  noOptionsText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      className={classes.autoComplete}
      disableCloseOnSelect={false}
      open={openOptions}
      onOpen={onOpenOptions}
      onClose={onCloseOptions}
      inputValue={userInputValue}
      onInputChange={onUserInputChange}
      size="small"
      multiple={false}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={value}
      onChange={onValueChange}
      // filterOptions={filterOptions}
      popupIcon={popupIcon}
      closeIcon={false}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          autoFocus
          onFocus={onOpenOptions}
          variant="outlined"
          placeholder={inputPlaceholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <ButtonMediumPrimaryText
                disableRipple
                className={classes.addIconBtn}
                startIcon={<AddIcon className={classes.addIcon} />}
              />
            ),
          }}
        />
      )}
      noOptionsText={noOptionsText}
      classes={{
        tagSizeSmall: classes.chip,
        option: classes.acOption,
        noOptions: classes.noAcOption,
      }}
      renderOption={(option, { selected }) => (
        <MenuItem
          component="div"
          selected={
            selectedItems.find((item: any) => item.id === option.id) !==
            undefined
          }
          classes={{
            root: classes.acMenuRoot,
            selected: classes.selected,
          }}
        >
          <LabelAvatar
            firstName={option.firstName}
            lastName={option.lastName}
            title={
              option.role?.roleName ||
              option.title ||
              option.roleName ||
              'Unknown'
            }
            styles={classes}
            careTeamSelectInputValue={userInputValue}
          />
          {!multiple ? (
            <>
              {selectedItems.find((item: any) => item.id === option.id) && (
                <CheckIcon className={classes.checkIcon} />
              )}
            </>
          ) : (
            <StyledCheckbox
              className={classes.checkBox}
              checkedAll={true}
              checked={selected}
              color="primary"
            />
          )}
        </MenuItem>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  autoComplete: {
    display: 'flex',
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      width: '100%',
      padding: theme.spacing(1, 0, 1, 2),
      minHeight: 40,
      fontSize: 16,
      borderRadius: 8,
      marginBottom: 24,
    },
    '& ::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  addIconBtn: {
    width: 24,
    height: 24,
    minWidth: 24,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    padding: 0,

    '& span.MuiButton-startIcon': {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    width: 12,
    height: 12,
    textAlign: 'center',
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    height: 32,
    marginRight: theme.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: 'white',
      height: 20,
      width: 20,
      opacity: 0.5,
    },
  },
  acOption: {
    height: 54,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0, 1, 0.5, 1),
    backgroundColor: 'white',
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
    },
    '& ::hover': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
  },
  noAcOption: {
    fontSize: 14,
    fontWeight: 600,
  },
  acMenuRoot: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    height: 56,
    borderRadius: 8,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${theme.palette.avatars.blue} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
  },
  checkIcon: {
    height: 25,
    position: 'absolute',
    right: '15px',
    color: '#0C77D8',
  },
  checkBox: {
    height: 25,
    position: 'absolute',
    right: '30px',
  },
}));
