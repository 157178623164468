import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

interface ProfileOptionTabsViewProps {
  value: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  a11yProps: (index: number) => any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 267,
    height: 96,
    position: 'fixed',
    overflow: 'hidden',
  },
  paper: {
    width: 267,
    height: 96,
    borderRadius: 8,
  },
  tabs: {
    width: 267,
    height: 96,
    borderBottom: 'none',

    '& .MuiTabs-flexContainer.MuiTabs-flexContainerVertical': {
      padding: theme.spacing(1),
    },
  },
  tab: {
    width: 251,
    height: 40,
    padding: theme.spacing(1, 1.5),
    borderRadius: 8,
    '&.MuiTab-root': {
      minHeight: 0,
    },
    '& > .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
    '&.MuiTab-textColorPrimary.Mui-selected': {
      background: theme.palette.primary.light,
    },
    '& > button.MuiButtonBase-root': {
      background: theme.palette.primary.light,
    },
  },
  deactiveTab: {
    color: theme.palette.grey[600],
  },
  activeTab: {
    color: theme.palette.primary.main,
  },
  tabText: {
    height: '24px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    fontFamily: 'Manrope',
    textTransform: 'capitalize',
  },
}));

export const EditPanelOptionTabs = (props: ProfileOptionTabsViewProps) => {
  const { value, handleTabChange, a11yProps } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={1} component="div" className={classes.paper}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          // centered
          TabIndicatorProps={{
            style: {
              height: 0,
            },
          }}
        >
          <Tab
            value={0}
            label={
              <span
                className={
                  value === 0
                    ? `${classes.activeTab} ${classes.tabText}`
                    : `${classes.deactiveTab} ${classes.tabText}`
                }
              >
                Panel Details
              </span>
            }
            {...a11yProps(value)}
            className={classes.tab}
          />
          <Tab
            value={1}
            label={
              <span
                className={
                  value === 1
                    ? `${classes.activeTab} ${classes.tabText}`
                    : `${classes.deactiveTab} ${classes.tabText}`
                }
              >
                Patient Filters
              </span>
            }
            {...a11yProps(value)}
            className={classes.tab}
          />
        </Tabs>
      </Paper>
    </div>
  );
};
