import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { config } from './config';

// Setup mixpanel for analysis
// mixpanel.init(
//   config.mixPanel.token as string,
//   {
//     debug: true,
//     track_pageview: true,
//     persistence: 'localStorage',
//     api_host: config.mixPanel.proxy,
//   });

if (
  config.noLogEnv.env.find((env: string) => env == process.env.REACT_APP_ENV)
) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
