import { usePanelsListQuery } from './index.generated';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { createHeaderCell } from '../../../../../sharePipe/createHeaderCellPipe';
import { PanelsListTable } from '../../../components/PanelsListTable';
import { RoleType } from '../../../../../enum/Users';
import React from 'react';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';

type Props = {
  limit: number;
  skip: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  showMyOwn: string;
  setNumOfPanels: any;
};

export function PanelsListContainer(props: Props) {
  const [sortBy, setSortBy] = React.useState({
    field: 'createdAt',
    method: 'desc' as const,
  });

  const panels = usePanelsListQuery({
    variables: {
      input: {
        limit: props.limit ?? 10,
        skip: props.skip ?? 0,
        sortBy: [{ ...sortBy }],
        showMyOwn: props.showMyOwn,
      },
    },
  });

  const queryResult = useFetchTableData(panels);

  React.useEffect(() => {
    if (queryResult) {
      props.setNumOfPanels(queryResult.getPanels?.count);
    }
  }, [queryResult]);

  if (!panels.called || panels.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 235px)` }} />;
  }

  return (
    <PanelsListTable
      refetchDataFunc={{
        func: panels.refetch,
        params: {
          showMyOwn: props.showMyOwn,
          limit: props.limit ?? 10,
          skip: props.skip ?? 0,
          sortBy: [{ ...sortBy }],
        },
      }}
      sortBy={{ ...sortBy }}
      setSortBy={setSortBy}
      panels={queryResult ? queryResult.getPanels.result : []}
      skip={props.skip ?? 0}
      limit={props.limit ?? 0}
      setSkip={props.setSkip}
      setLimit={props.setLimit}
      count={queryResult ? queryResult.getPanels?.count : 0}
      tableType={RoleType.MSO_ADMIN}
      headCells={[
        {
          id: 'name',
          align: 'left' as const,
          disablePadding: false,
          label: 'Name',
        },
        {
          id: 'careCoordinatorCount',
          align: 'center' as const,
          disablePadding: false,
          label: 'Care Coordinators',
        },
        {
          id: 'patientCount',
          align: 'left' as const,
          disablePadding: false,
          label: 'Patients',
        },
        {
          id: 'openGapCount',
          align: 'left' as const,
          disablePadding: false,
          label: 'Open Gaps',
        },
        {
          id: 'complianceRate',
          align: 'left' as const,
          disablePadding: false,
          label: 'Compliance Rate',
        },
        {
          id: 'createdAt',
          align: 'left' as const,
          disablePadding: false,
          label: 'Date Created',
        },
      ].map((item) =>
        createHeaderCell(item.id, item.label, item.disablePadding, item.align)
      )}
    />
  );
}
