import { AllProviders } from './AllProviders';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../components/CheckIsAdmin';
import React from 'react';
import { GlobalContext } from '../../components/GlobalContext';
import { MyProviders } from './MyProviders';

export const PCPRouted = () => {
  const { loggedInUser } = React.useContext(GlobalContext);

  return CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser) ? (
    <AllProviders />
  ) : (
    <MyProviders />
  );
};
