import React, {useState} from 'react';
import {
    Button,
    Dialog,
    Grid, MenuItem,
    Paper,
    styled, TextField,
    Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import {useAssignCoordinatorDialogViewStyles} from "../../../../Panels/components/AssignCoordinatorDialog/Styles";
import {AssignedUserRow} from "./AssignedUserRow";
import {useGetUsersByAccountIdQuery} from "../AllUsers/index.generated";
import {AddCircle, KeyboardArrowDown} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {theme} from "../../../../../themes/theme";
import {LabelAvatar} from "../../../../../components/Avatar";
import {RoleType} from "../../../../../enum/Users";
import {StyledCheckbox} from "../../../../../components/StyledCheckbox";
import {DeleteCoordinatorConfirmDialog} from "../../../../Panels/components/DeleteCoordinatorConfirmDialog";
import {LoadingSpinner} from "../../../../../components/LoadingSpinner";
import {useUpdateUserClinicAndDivisionGroupMutation} from "./index.generated";
import {GlobalContext} from "../../../../../components/GlobalContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: '851px',
        height: 'auto',
        background: theme.palette.primary.contrastText,
        boxShadow:
            '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
    },
}));

interface AssignDesigneeDialogViewProps {
    setShowAssignDesigneeDialog: (input: any) => void;
    assignedDesignee: any[];
    assignDesignee: (newCareTeamUsers: any) => any;
    loading: boolean | undefined;
    selectedDivision: any;
}

export const AssignDesigneeDialogView = (
    props: AssignDesigneeDialogViewProps
) => {
    const [careTeamOptions, setCareTeamOptions] = React.useState<any>([]);
    const userQuery = useGetUsersByAccountIdQuery({
        variables: {
            getUsersByAccountIdRequest: {
                limit: 0,
                skip: 0,
                sortBy: [{ field: 'fullName', method: 'asc' }],
            },
        },
    });
    const [updateUserClinicAndDivisionGroup] = useUpdateUserClinicAndDivisionGroupMutation();
    const classes = useAssignCoordinatorDialogViewStyles();
    const [selectedItems, setSelectedItems]: any[] = useState([]);
    const [disabled, setDisabled] = React.useState(true);
    const [isClickedAdd, setIsClickedAdd] = useState(false);
    const navigate = useNavigate();
    const [onDeleteUser, setOnDeleteUser] = React.useState<any>(null);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(
        false
    );
    const [loading, setLoading] = useState(true);
    const [defaultDesignee, setDefaultDesignee] = React.useState<any>(null);
    const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
    const coordinatorOptions = React.useMemo(() => {
        return userQuery.data?.getUsersByAccountId?.results;
    }, [userQuery.data]);
    const mapUserIntoUserClinicAndDivisionRequestPairInput = (user: any) => {
        return {
            address: '',
            clinicID: props.selectedDivision.clinicID,
            divisionID: props.selectedDivision.divisionID,
            divisionName: props.selectedDivision.divisionName,
            email: user?.email??'',
            firstName: user?.firstName??'',
            isSiteDesignee: defaultDesignee.id==user.id,
            lastName: user?.lastName??'',
            roleID: user?.role?.id??'',
            userID: user.id,
        }
    }
    async function submit(){
        setLoading(true)
        const input = {
            clinicAndDivisionPairs: selectedItems.map((item:any)=>mapUserIntoUserClinicAndDivisionRequestPairInput(item)),
            clinicID: props.selectedDivision.clinicID,
            divisionID: props.selectedDivision.divisionID,
        }
        const { data, errors } = await updateUserClinicAndDivisionGroup({variables: {input: input}});
        if (data) {
            if (data.updateUserClinicAndDivisionGroup.isSuccess) {
                setLoading(false);
                props.setShowAssignDesigneeDialog(false)
                setToastMessage({
                    snackbarMsg: `Assigned`,
                    isOpen: true,
                    severity: 'success',
                });
                setIsOpenToast(true);
                // Refresh the page
                props.assignDesignee(selectedItems);
            }
            else{
                setLoading(false);
                setToastMessage({
                    snackbarMsg: `Failed to assign, please try again.`,
                    severity: 'error',
                    isOpen: true,
                });
                setIsOpenToast(true);
            }
        }
    }

    const {
        setShowAssignDesigneeDialog,
        assignedDesignee,
    } = props;

    React.useEffect(() => {
        const selected: any[] = [];
        if(coordinatorOptions) {
            setLoading(false);
            coordinatorOptions.forEach((item: any) => {
                let assigned = assignedDesignee.find((assigned: any) => assigned.userID === item.id);
                if (assigned!==undefined) {
                    selected.push(item);
                    if(assigned.isSiteDesignee){
                        setDefaultDesignee(item)
                    }
                }
            });
            setSelectedItems(selected);
            setCareTeamOptions(coordinatorOptions);
        }
    }, [coordinatorOptions]);

    const handleClose = () => {
        // close dialog
        setShowAssignDesigneeDialog(false);
    };


    const [
        careTeamSelectInputValue,
        setCareTeamSelectInputValue,
    ] = React.useState('');
    const changeOptionBaseOnValue = (newValue: string) => {
        setCareTeamSelectInputValue(newValue);
    };

    const removeSelectedCareTeam = () => {
        const targetToRemove = onDeleteUser;
        if(defaultDesignee?.id==targetToRemove?.id){
            setDefaultDesignee(null)
        }
        if (targetToRemove) {
            const newSelectedItem = selectedItems.filter(
                (item: any) => item?.id !== targetToRemove?.id
            );

            setSelectedItems(newSelectedItem);
            setOnDeleteUser(null);
        }
    };

    // remove the coordinators in assigned coordinators
    React.useEffect(() => {
        if (selectedItems.length > 0) {
            const removeAssignedCoordinators = careTeamOptions.filter((item: any) => {
                let isKept = true;
                selectedItems.forEach((assigned: any) => {
                    if (assigned.id === item.id) {
                        isKept = false;
                    }
                });

                return isKept;
            });
            setCareTeamOptions(removeAssignedCoordinators);
        } else {
            setCareTeamOptions(careTeamOptions);
        }
    }, [selectedItems.length]);

    const filterOptions = (options: any, state: any) => {
        const injectOptions: any[] = [];
        const isSearch = careTeamSelectInputValue.length > 0;
        const inputValueLower = careTeamSelectInputValue.toLowerCase();
        for (const option of options) {
            if (!selectedItems.find((item: any) => item.id == option.id)) {
                if (isSearch) {
                    if (
                        (option?.firstName + ' ' + option?.lastName)
                            .toLowerCase()
                            .includes(inputValueLower)
                    ) {
                        injectOptions.push(option);
                    }
                } else {
                    injectOptions.push(option);
                }
            }
        }
        return injectOptions;
    };

    const matchSelectedCareTeam = (fullNameList: any[]) => {
        // check is any member is removed from select
        setSelectedItems(fullNameList);
        setIsClickedAdd(false);

        setCareTeamSelectInputValue('');
    };

    const mapSelectedCareTeamAssignedPatientsFromOption = (item: any) => {
        return careTeamOptions?.find((user: any) => user.id == item.id) ?? item;
    };

    return (
        <div className={classes.root}>
            <BootstrapDialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                {showDeleteConfirmDialog && (
                    <DeleteCoordinatorConfirmDialog
                        onClose={setShowDeleteConfirmDialog}
                        onSubmit={() => {
                            removeSelectedCareTeam();
                        }}
                        cc={onDeleteUser}
                        customTitle={'Remove User'}
                        customText1={'Are you sure to remove this user?'}
                        customText2={''}
                        customText3={' will be removed.'}
                    />
                )}
                <div role="assign-coordinator-dialog-form">
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            {/* section header */}
                            <Grid item xs={12} className={classes.cardHeader}>
                                {/* title */}
                                <Typography className={classes.cardTitle}>
                                    {props.selectedDivision?.divisionName??''}
                                </Typography>
                                {/* patient count */}
                            </Grid>

                            {/* main region */}
                            {loading? <LoadingSpinner />:
                            <Grid item xs={12} className={classes.cardContent}>
                                {/*Assigned Care Coordinator(s) */}
                                <Typography className={classes.addMember}>
                                    Assigned Users 
                                </Typography>

                                {/* care team select */}
                                {careTeamOptions?.length > 0 && isClickedAdd && (
                                    <div className={classes.addCareTeamProvider}>
                                        <Autocomplete
                                            inputValue={careTeamSelectInputValue}
                                            onInputChange={(
                                                event: object,
                                                value: string,
                                                reason: string
                                            ) => {
                                                if (reason === 'input') {
                                                    changeOptionBaseOnValue(value);
                                                }
                                            }}
                                            filterSelectedOptions={
                                                selectedItems.length === careTeamOptions.length
                                            }
                                            className={classes.autoComplete}
                                            size="small"
                                            multiple
                                            style={{ width: '100%' }}
                                            options={careTeamOptions}
                                            getOptionSelected={(option, value) => option.id == value?.id}
                                            // getOptionLabel={(option) =>
                                            //   option.firstName + option.lastName
                                            // }
                                            value={selectedItems}
                                            onChange={(e, v) => {
                                                if (v.length > selectedItems.length) {
                                                    matchSelectedCareTeam(v);
                                                }
                                            }}
                                            popupIcon={<KeyboardArrowDown />}
                                            closeIcon={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={'Search by Name'}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <AddCircle
                                                                onClick={() => setIsClickedAdd(!isClickedAdd)}
                                                                style={{
                                                                    color: theme.palette.primary.main,
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            )}
                                            noOptionsText="No Care Coordinator Available."
                                            classes={{
                                                tagSizeSmall: classes.chip,
                                                option: classes.acOption,
                                                noOptions: classes.noAcOption,
                                            }}
                                            filterOptions={filterOptions}
                                            renderOption={(option, { selected }) => (
                                                <MenuItem
                                                    component="div"
                                                    selected={selected}
                                                    classes={{
                                                        root: classes.acMenuRoot,
                                                        selected: classes.selected,
                                                    }}
                                                >
                                                    <LabelAvatar
                                                        firstName={option.firstName}
                                                        lastName={option.lastName}
                                                        title={
                                                            option?.role?.roleName === 'Admin'
                                                                ? RoleType.CARE_MANAGER
                                                                : option?.role?.roleName ?? option.title
                                                        }
                                                        styles={classes}
                                                        careTeamSelectInputValue={careTeamSelectInputValue}
                                                    />
                                                    <StyledCheckbox
                                                        checked={selected}
                                                        checkedAll={true}
                                                        style={{
                                                            height: 25,
                                                            position: 'absolute',
                                                            right: '30px',
                                                        }}
                                                        // className={classes.checkBoxPosition}
                                                    />
                                                </MenuItem>
                                            )}
                                        />
                                    </div>
                                )}

                                {selectedItems?.length > 0 && (
                                    <div className={classes.manageCoordinatorContainer}>
                                        {selectedItems.map((item: any, idx: any) => {
                                            return (
                                                <AssignedUserRow
                                                    key={'userselect'+idx}
                                                    assignedCoordinatorMember={mapSelectedCareTeamAssignedPatientsFromOption(
                                                        item
                                                    )}
                                                    siteDesignee={(defaultDesignee?.id==item.id)??false}
                                                    removeSelectedSiteDesignee={
                                                        ()=>{
                                                            setShowDeleteConfirmDialog(true);
                                                            setOnDeleteUser(item);}}
                                                    setDefaultDesignee={setDefaultDesignee}
                                                />
                                            );
                                        })}
                                    </div>
                                )}

                                <Grid item xs={12}>
                                    {!isClickedAdd && (
                                        <div className={classes.addCareTeamProvider}>
                                            <AddCircle
                                                onClick={() => setIsClickedAdd(!isClickedAdd)}
                                                style={{
                                                    color: '#0C77D8',
                                                    cursor: 'pointer',
                                                    marginLeft: 20,
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                className={classes.addCareProviderText}
                                                onClick={() => setIsClickedAdd(!isClickedAdd)}
                                            >
                                                Add User
                                            </Typography>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            }

                            {/* section footer */}
                            <Grid item xs={12} className={classes.sectionFooter}>
                                <Button
                                    id="back"
                                    variant="contained"
                                    className={classes.backBtn}
                                    onClick={ handleClose}
                                    disabled={loading}
                                >
                                    {'Cancel'}
                                </Button>

                                {/* tooltip for large number */}

                                <Button
                                    id="creation-button"
                                    variant="contained"
                                    disableElevation
                                    className={classes.saveBtn}
                                    onClick={()=>{submit()}}
                                    disabled={defaultDesignee==null || loading}
                                >
                                    {'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </BootstrapDialog>
        </div>
    );
};
