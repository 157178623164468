import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { Button, Typography } from '@material-ui/core';
import { SIDEBAR_WIDTH } from '../../../../../layouts/MainLayout';
import {
  PanelsIcon,
  PCPIcon,
} from '../../../../../assets/Icons/SideNavigation';
import { EngagementIcon } from '../../../../../assets/Icons/PanelsList/EngagementIcon';
import { SendSmsDialog } from '../EngagementDialog/SendSmsDialog';
import { SendingSmsDialog } from '../EngagementDialog/SendingSmdDialog';
import { useSendPanelSmsMutation } from './index.generated';
import { SendPanelSmsForm } from './PanelPatientActionsFooterFunctions';
import { AllAssignedIcon } from '../../../../../assets/Icons/PanelSummary';
import { ChangeCoordinatorDialog } from '../ChangeCoordinatorDialog';
import { SendPanelSmsRequest } from '../../../../../app.types.generated';

interface Props {
  itemName: string;
  selectedItems: any[];
  setSelected: (input: any[]) => void;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  tabs: string[];
  panelId: string;
  count: any;
  hardFresh: any;
  enableEngagementDialog?: boolean;
  setEnableEngagementDialog?: (input: boolean) => void;
  coordinatorOptions: any[];
  enableChangeCoordinatorDialog: boolean;
  setEnableChangeCoordinatorDialog: (input: boolean) => void;
  loading: boolean;
  setLoading: (input: boolean) => void;
  clicked: boolean;
  setClicked: (input: boolean) => void;
  fromCoordinators?: any;
  filters: any;
  changeCoordinator: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'fixed',
    bottom: 20,
    left: SIDEBAR_WIDTH,
    right: 0,
    height: 64,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ptCountContainer: {
    position: 'absolute',
    left: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    height: 20,
    backgroundColor: theme.palette.primary.main,
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.primary.contrastText,
    },
  },
  countText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    height: 32,
    '&.MuiButton-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: 8,
    },
  },
  disableButton: {
    backgroundColor: 'transparent!important',
    fontSize: 14,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    height: 32,
    '&.MuiButton-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: 8,
    },

    '& svg': {
      color: theme.palette.grey[400],
    },
  },
  splitter: {
    paddingLeft: 4,
    paddingRight: 4,
    borderRight: '1px solid #D1D1D1',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  lastButtonContainer: {
    paddingLeft: 4,
  },
  icon: {
    height: 20,
    width: 20,
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(0.5),
  },
  disableIcon: {
    height: 20,
    width: 20,
    paddingRight: theme.spacing(0.5),
  },
  option: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 24,
    width: 1,
    backgroundColor: theme.palette.grey[300],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

export const PanelPatientsListActionsFooter = (props: Props) => {
  const classes = useStyles();
  // const [enableEngagementDialog, setEnableEngagementDialog] =
  //   React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [batchId, setBatchId] = React.useState<any>(undefined);
  const [sendPanelSms] = useSendPanelSmsMutation();

  const [patientSelected, setPatientSelected] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.setAllSelected) {
      setPatientSelected([]);
    } else {
      setPatientSelected(props.selectedItems);
    }
  }, [props.allSelected, props.selectedItems]);

  React.useEffect(() => {
    // check selected
  }, [props.selectedItems]);

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const calculatePatientCount = () => {
    if (props.allSelected) return props.count - props.excluded.length;
    else {
      return props.selectedItems.length;
    }
  };

  const removeNBSP = (input: string) => {
    return input.replace(/&nbsp/g, ' ').replace(/\u00A0/g, ' ');
  };

  async function onClickSendSMS(form: SendPanelSmsForm) {
    const { type, messageList, templateName, templateId } = form;

    const input: SendPanelSmsRequest = {
      messageType: type,
      filters: props.filters,
      message_template_id: templateId,
      message_template_name: templateName,
      messageText: '',
      panelId: props.panelId,
      messageList: messageList.map((item) => {
        item.content = removeNBSP(item.content);
        return item;
      }),
      excludePatientIds: props.allSelected
        ? props.excluded.map((patient: any) => patient.id)
        : [],
      selectedPatientIds: props.allSelected
        ? []
        : props.selectedItems.map((patient: any) => patient.id),
    };
    await onSendPanelSMS(input);
  }
  // not selected all

  // on select all
  async function onSendPanelSMS(input: SendPanelSmsRequest) {
    // const { data } = await sendPanelSms({
    //   variables: { input },
    // });
    // console.log(data);
    try {
      const { data } = await sendPanelSms({
        variables: { input },
      });

      if (data!.sendPanelSms!.isSuccess) {
        setBatchId(data!.sendPanelSms!.batchId!);
        if (props.setEnableEngagementDialog) {
          props.setEnableEngagementDialog(false);
        }
        setSending(true);
      }
    } catch (err) {
      //
    }
  }

  return (
    <div className={classes.root} id={'actionFooter'}>
      <div className={classes.ptCountContainer}>
        <Chip
          className={classes.chip}
          label={
            props.allSelected
              ? props.count - props.excluded.length
              : props.selectedItems.length
          }
        />
        <Typography
          className={classes.countText}
        >{`${props.itemName} Selected`}</Typography>
      </div>

      <div className={classes.buttonsContainer}>
        {props.tabs.includes('Assign Care Provider') && (
          <div className={classes.splitter}>
            <Button className={classes.button} onClick={() => {}}>
              <PCPIcon className={classes.icon} />
              <Typography className={classes.option}>
                Assign Care Provider
              </Typography>
            </Button>
          </div>
        )}
        {props.tabs.includes('Batch SMS') && props.setEnableEngagementDialog && (
          <div className={classes.splitter}>
            <Button
              disabled={false}
              className={classes.button}
              classes={{ disabled: classes.disableButton }}
              onClick={() => {
                if (props.setEnableEngagementDialog) {
                  props.setEnableEngagementDialog(
                    !props.enableEngagementDialog
                  );
                }
              }}
            >
              <EngagementIcon className={classes.icon} />
              <Typography className={classes.option}>Send Batch SMS</Typography>
            </Button>
          </div>
        )}
        {props.enableEngagementDialog && (
          <SendSmsDialog
            onClose={props.setEnableEngagementDialog}
            onSubmit={onClickSendSMS}
            patientCount={calculatePatientCount()}
          />
        )}
        {sending && (
          <SendingSmsDialog
            onClose={setSending}
            link={
              '/panels/' + props.panelId + '/engagement/' + batchId + '/Sending'
            }
            hardFresh={props.hardFresh}
          />
        )}
        {props.tabs.includes('Add to Other Panel') && (
          <div className={classes.splitter}>
            <Button
              className={classes.disableButton}
              onClick={() => {}}
              disabled={true}
            >
              <PanelsIcon className={classes.disableIcon} />
              <Typography className={classes.option}>
                Add to Other Panel
              </Typography>
            </Button>
          </div>
        )}
        {props.tabs.includes('Assign Coordinator') && (
          <div className={classes.splitter}>
            <Button
              className={classes.button}
              onClick={() => {
                props.setEnableChangeCoordinatorDialog(true);
              }}
            >
              <AllAssignedIcon className={classes.icon} />
              <Typography className={classes.option}>
                Assign Coordinator
              </Typography>
            </Button>
          </div>
        )}
        {props.tabs.includes('Change Coordinator') && (
          <div className={classes.splitter}>
            <Button
              className={classes.button}
              onClick={() => {
                props.setEnableChangeCoordinatorDialog(true);
              }}
            >
              <AllAssignedIcon className={classes.icon} />
              <Typography className={classes.option}>
                Change Coordinator
              </Typography>
            </Button>
          </div>
        )}
        {/* For multi check box use */}
        {props.enableChangeCoordinatorDialog && (
          <ChangeCoordinatorDialog
            source="PanelPatientActionFooter"
            fromCoordinators={props.fromCoordinators ?? []}
            setShowChangeCoordinatorDialog={
              props.setEnableChangeCoordinatorDialog
            }
            fromUnAssign={props.tabs.includes('Assign Coordinator')} // unassigned and de-listed tabs
            firstQueryLoading={props.coordinatorOptions ? false : true}
            coordinatorOptions={props.coordinatorOptions ?? []}
            allPatientCount={
              props.allSelected
                ? props.count - props.excluded.length
                : props.selectedItems.length
            }
            changeCoordinator={props.changeCoordinator}
            loading={props.loading}
            clicked={props.clicked}
            setClicked={props.setClicked}
            filters={props.filters}
            allSelected={props.allSelected}
            excluded={props.excluded}
            selectedItems={props.selectedItems}
          />
        )}
        {/* {props.tabs.includes('De-List') && (
          <div className={classes.splitter}>
            <Button
              className={classes.disableButton}
              onClick={() => {}}
              disabled={true}
            >
              <DeListIcon className={classes.disableIcon} />
              <Typography className={classes.option}>De-List</Typography>
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};
