import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '../../../../assets/Icons/PanelsList';
import { RolesAndPermissions } from '../Tabs/RolesAndPermissions';
import { AllUsers } from '../Tabs/AllUsers';
import { TabBar } from '../../../../components/TabBar';
import { MainRegion } from '../../../../components/MainRegion';
import { HomePageHeader } from '../../../../components/HomePageHeader';
import { User } from '../../../../enum/Users';
import { SearchAndFilterBar } from '../../../../components/SearchAndFilterBar';
import { ClinicAndDivisionView } from '../Tabs/ClinicAndDivision/View';
import { useGetClinicsAndDivisionQuery } from '../NewUsers/index.generated';
import { useFeature } from '../../../../components/FeatureFlag';

interface DashboardViewProps {}

export const DashboardView = (props: DashboardViewProps) => {
  const [value, setValue] = React.useState(0);
  const [totalNumUsers, setTotalNumUsers] = React.useState(0);
  const [totalNumDivision, setTotalNumDivision] = React.useState(0);
  const [actionsFooter, setActionsFooter] = React.useState<User[]>([]);
  const [refresh, setRefresh] = React.useState(0);
  let navigate = useNavigate();
  const allUsersUrl = '/users/all';
  const rolesInfoUrl = '/users/roles';
  const clinicAndDivisionInfoUrl = '/users/clinicsAndDivisions';
  const clinicsAndDivisionQuery = useGetClinicsAndDivisionQuery({
    variables: {},
  });
  const [divisions, setDivisions] = React.useState<any[]>([]);
  const handleCreationClick = () => {
    navigate('/users/create');
  };

  const switchTab = (event: any, newValue: number) => {
    if (newValue === 0) {
      navigate(allUsersUrl);
    } else if (newValue === 1) {
      navigate(rolesInfoUrl);
    } else {
      navigate(clinicAndDivisionInfoUrl);
    }
    setValue(newValue);
  };
  React.useEffect(() => {
    if (clinicsAndDivisionQuery?.data?.getCareGapFilterOptions?.Divisions) {
      setTotalNumDivision(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions.Divisions?.length
      );
      setDivisions(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions.Divisions ?? []
      );
    }
  }, [clinicsAndDivisionQuery?.data?.getCareGapFilterOptions]);

  const [searchName, setSearchName] = React.useState('');
  const [searchInput, setSearchInput] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [isSearchingCheck, setIsSearchingCheck] = React.useState<
    boolean | undefined
  >(undefined);

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };
  const hasFeature = useFeature('division');
  const tabObjects = [
    {
      tabIndex: 0,
      tabLabel: 'All Users',
      tabUrl: allUsersUrl,
      tabBadgeCount: totalNumUsers,
      disabled: false,
    },
    {
      tabIndex: 1,
      tabLabel: 'Roles and Permissions',
      tabUrl: rolesInfoUrl,
      tabBadgeCount: null,
      disabled: false,
    },
    {
      tabIndex: 2,
      tabLabel: 'Clinics and Divisions',
      tabUrl: clinicAndDivisionInfoUrl,
      tabBadgeCount: totalNumDivision,
      disabled: false,
    },
  ];

  const tabObjectsWithFeature = React.useMemo(() => {
    if (hasFeature) {
      return tabObjects;
    } else {
      return tabObjects.filter((tab) => tab.tabIndex !== 2);
    }
  }, [hasFeature, tabObjects]);

  return (
    <MainRegion
      footer={value === 0 && actionsFooter.length !== 0}
      header={
        <HomePageHeader
          headerTitle={'User & Permission'}
          headerActionElement={
            <Button
              id="creation-button"
              variant="contained"
              disableElevation
              onClick={handleCreationClick}
            >
              <AddIcon />
              <span>new user</span>
            </Button>
          }
          privilege={{
            checkPrivilege: true,
            neededPrivileges: 'users.create',
          }}
        />
      }
      overFlowHidden={true}
    >
      {/* Tabs for showing all users and roles info */}
      <TabBar
        activeTabIndex={value}
        switchTab={switchTab}
        tabObjects={tabObjectsWithFeature}
        sticky={true}
      >
        {value === 0 && (
          <Grid item xs={12}>
            <SearchAndFilterBar
              placeHolder="Search by Full Name"
              hideFilter={true}
              searchFunction={setSearchInput}
              setIsSearching={setIsSearching}
              isSearching={isSearching}
              isSearchingCheck={isSearchingCheck}
              setIsSearchingCheck={setIsSearchingCheck}
            />
            <AllUsers
              setTotalNumUsers={setTotalNumUsers}
              actionsFooter={actionsFooter}
              setActionsFooter={setActionsFooter}
              isSearching={isSearching}
              searchInput={searchInput}
              searchName={searchName}
              setSearchName={setSearchName}
              isSearchingCheck={isSearchingCheck}
              setIsSearchingCheck={setIsSearchingCheck}
            />
          </Grid>
        )}
        {value === 1 && (
          <Grid item xs={12}>
            <RolesAndPermissions />
          </Grid>
        )}
        {hasFeature && value == 2 && (
          <Grid item xs={12}>
            <ClinicAndDivisionView data={divisions} />
          </Grid>
        )}
      </TabBar>
    </MainRegion>
  );
};
