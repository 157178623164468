import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Paper, Theme, OutlinedInput } from '@material-ui/core';
import { PanelsHeader } from '../../../components/PanelsHeader';
import { StepIndicator } from '../StepIndicator';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { useOutlinedInputStyles } from '../../../../../styles/outlineInputStyle';
import { MainRegion } from '../../../../../components/MainRegion';

type DetailProps = {
  onNext: (name: string, description: string) => any;
  step: number;
  setPanelName: any;
  setDescription: any;
  errorMessage: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
  },
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',
    padding: theme.spacing(1.5),
    justifyContent: 'space-between',
  },
  numberCircle: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },
  // reduce classes by using props in theme (master form state)
  stepsContainer: {
    height: '100%',
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
  },
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
  },
  cardFooter: {
    height: 72,
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    '& span': {
      color: '#E7434C',
    },
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
  },
  errorLabel: {
    fontSize: 12,
    color: '#B7373E',
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    height: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabledNextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabledText: {
    opacity: '0.5',
  },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
      fontSize: 16,
      paddingLeft: theme.spacing(1),
    },
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(1.5),
  },
}));

type Step1Form = {
  name: string;
  description: string;
};

export const Step1PanelDetailsView = (props: DetailProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const navigate = useNavigate();
  const [panelName, setPanelName] = useState<string | undefined>(undefined);
  const [panelDescription, setPanelDescription] = useState<string | undefined>(
    undefined
  );
  const [panelNameError, setPanelNameError] = useState<string | undefined>(
    undefined
  );
  const [panelDescriptionError, setPanelDescriptionError] = useState<
    string | undefined
  >(undefined);

  const { control, handleSubmit } = useForm<Step1Form>({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const UpdateMasterForm = async (input: Step1Form) => {
    // check name
    props.setPanelName(input.name);
    props.setDescription(input.description);
    // props.onNext(input.name, input.description);
  };

  useEffect(() => {
    if (panelName !== undefined && !panelName.length) {
      setPanelNameError('Panel name is empty');
    } else {
      setPanelNameError(undefined);
    }
  }, [panelName]);

  useEffect(() => {
    if (panelDescription !== undefined && !panelDescription.length) {
      setPanelDescriptionError('Panel description is empty');
    } else if (
      panelDescription !== undefined &&
      panelDescription.length > 255
    ) {
      setPanelDescriptionError(
        `Panel description cannot exceed 255 characters (${panelDescription.length}/255)`
      );
    } else {
      setPanelDescriptionError(undefined);
    }
  }, [panelDescription]);

  return (
    <MainRegion
      header={<PanelsHeader title="New Panel" renderButton={undefined} />}
    >
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.mainGrid}>
          {/* step indicator */}
          <Grid item xs={2} container className={classes.stepsContainer}>
            <StepIndicator step={props.step} />
          </Grid>
          {/* spacing */}
          <Grid item xs={1} />
          {/* Patient details form */}
          <Grid item xs={9}>
            <form onSubmit={handleSubmit(UpdateMasterForm)}>
              <Paper className={classes.card} elevation={1}>
                <Grid container>
                  <Grid item xs={12} className={classes.cardHeader}>
                    <Typography className={classes.cardTitle}>
                      Panel Details
                    </Typography>

                    <Button
                      onClick={() => navigate('/panels')}
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} className={classes.cardContent}>
                    <Typography className={classes.inputLabel}>
                      Panel Name <span>*</span>
                    </Typography>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, value, name, ref }) => (
                        <div>
                          <OutlinedInput
                            fullWidth
                            autoFocus
                            inputRef={ref}
                            name={name}
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                              setPanelName(event.target.value);
                            }}
                            error={panelNameError !== undefined}
                            className={classes.nameInput}
                            placeholder="Enter Panel Name"
                            inputProps={{ maxLength: 40 }}
                            classes={outlinedInputClasses}
                          />
                          {panelNameError && (
                            <Typography
                              color="error"
                              className={classes.errorMsg}
                            >
                              panel name cannot be empty.
                            </Typography>
                          )}
                        </div>
                      )}
                    />
                    {props.errorMessage ? (
                      <Typography className={classes.errorLabel}>
                        {props.errorMessage}
                      </Typography>
                    ) : (
                      <Typography className={classes.subLabel}>
                        40 characters max
                      </Typography>
                    )}
                    <Typography className={classes.inputLabel}>
                      Description <span>*</span>
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, value, name, ref }) => (
                        <div>
                          <OutlinedInput
                            fullWidth
                            multiline
                            inputRef={ref}
                            name={name}
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                              setPanelDescription(event.target.value);
                            }}
                            className={classes.descriptionInput}
                            placeholder="Panel Description"
                            rows={'10'}
                            classes={outlinedInputClasses}
                            error={panelDescriptionError !== undefined}
                          />
                          {panelDescriptionError && (
                            <Typography
                              color="error"
                              className={classes.errorMsg}
                            >
                              {panelDescriptionError}
                            </Typography>
                          )}
                        </div>
                      )}
                    />

                    <Typography className={classes.subLabel}>
                      Enter description for your new panel
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} className={classes.cardFooter}>
                    {panelNameError !== undefined ||
                    panelDescriptionError !== undefined ? (
                      <Button className={classes.disabledNextButton}>
                        <span className={classes.disabledText}>Next</span>
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className={classes.nextButton}
                        disabled={false}
                      >
                        Next
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
