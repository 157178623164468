export interface HeadCell {
  id: any;
  label: string;
  disablePadding: boolean;
  align: 'left' | 'right' | 'center';
}

export function createHeaderCell(
  id: any,
  label: string,
  disablePadding: boolean,
  align: 'left' | 'right' | 'center'
): HeadCell {
  return {
    id,
    label,
    disablePadding,
    align,
  };
}
