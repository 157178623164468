import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: any;
}

export const LogoutIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M9 18H6V6L9 6V4H6C4.9 4 4 4.9 4 6V18C4 19.1 4.9 20 6 20H9V18ZM15 7L13.59 8.41L16.17 11H8V13H16.17L13.59 15.59L15 17L20 12L15 7Z"
  />
);
