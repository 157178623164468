export function dateFormatPipe(
  date: any,
  nullableString: string = '',
  showMinSec: boolean = true,
  commaAfterYear?: boolean,
  shortMonth?: boolean
) {
  const helper = (
    day: number,
    monthIndex: number,
    year: number,
    hours: number,
    originalMinutes: number
  ) => {
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour 0 should be 12
    let minutes =
      originalMinutes < 10 ? '0' + originalMinutes : originalMinutes;
    if (commaAfterYear) {
      return showMinSec
        ? (shortMonth
            ? `${monthShortNames[monthIndex]} `
            : `${monthNames[monthIndex]} `) +
            `${day} ${year}, ${hours}:${minutes} ${ampm}`
        : (shortMonth
            ? `${monthShortNames[monthIndex]} `
            : `${monthNames[monthIndex]} `) + `${day} ${year},`;
    }
    return showMinSec
      ? (shortMonth
          ? `${monthShortNames[monthIndex]} `
          : `${monthNames[monthIndex]} `) +
          `${day}, ${year} ${hours}:${minutes} ${ampm}`
      : (shortMonth
          ? `${monthShortNames[monthIndex]} `
          : `${monthNames[monthIndex]} `) + `${day}, ${year}`;
  };
  if (date === '' || date === null) {
    return nullableString;
  }
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthShortNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const newDate = new Date(date);
  if (date.includes('T') && date.includes('Z')) {
    // using ET if in ISO format
    return helper(
      newDate.getDate(),
      newDate.getMonth(),
      newDate.getFullYear(),
      newDate.getHours(),
      newDate.getMinutes()
    );
  } else {
    // using UTC
    return helper(
      newDate.getUTCDate(),
      newDate.getUTCMonth(),
      newDate.getUTCFullYear(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes()
    );
  }
}

export function moveCommaInDateFormat(dateTimeString: string) {
  // from "March 29, 2023 9:15 AM" to "March 29, 2023 9:15 AM"
  // index 012345678901234
  let newTmp = dateTimeString.replace(',', ' ');
  return newTmp.substring(0, 14) + ',' + newTmp.substring(14);
}
