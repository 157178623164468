import React from 'react';
import { SiteFollowUpFormView } from './View';
import { SiteFollowUpUser } from '../../../../../../../../../app.types.generated';
import { useSiteFollowUpDialog } from '../../../../hooks/useSiteFollowUpDialog';

export type InitStateType = {
  loading: boolean;
  defaultDesignee: SiteFollowUpUser | undefined;
  designeeOptions: SiteFollowUpUser[];
};

interface SiteFollowUpFormContainerProps {
  control: any;
  register: any;
  errors: any;
  handleSubmit: any;
  submitForm: (input?: any) => void;
  mpatientId: string;
  dialogId: string;
  setLoadingFormData: (loading: boolean) => void;
  setCompletionUser?: (user: SiteFollowUpUser | undefined) => void;
}

export const SiteFollowUpFormContainer: React.FC<SiteFollowUpFormContainerProps> = ({
  control,
  register,
  errors,
  handleSubmit,
  submitForm,
  mpatientId,
  dialogId,
  setLoadingFormData,
  setCompletionUser,
}) => {
  const { initState } = useSiteFollowUpDialog(dialogId, mpatientId);
  setLoadingFormData(initState.loading);
  if (setCompletionUser) {
    setCompletionUser(initState.defaultDesignee);
  }
  // console.log('initState', initState);

  return (
    <div id="SiteFollowUpFormContainer">
      <SiteFollowUpFormView
        initState={initState}
        control={control}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        dialogId={dialogId}
      />
    </div>
  );
};
