import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Button, Typography, Paper, OutlinedInput } from '@material-ui/core';
import { Forms } from '../../View';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { GlobalContext } from '../../../../components/GlobalContext';

interface Props {
  setRenderForm: React.Dispatch<React.SetStateAction<string>>;
  cognitoUser: object;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  newPwCard: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(5, 4, 5, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  instructions: {
    fontSize: 16,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 14,
    height: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['700'],
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    height: 48,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 8,
  },
  backToLoginButton: {
    width: 'fit-content',
    padding: 0,
    height: 'fit-content',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderRadius: 0,
  },
  backToLogin: {
    fontSize: 14,
    fontWeight: 600,
    height: 24,
    color: theme.palette.secondary.dark,
    borderBottom: '2px solid #434343',
    paddingBottom: theme.spacing(0.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(1.5),
  },
  email: {
    color: theme.palette.text.primary,
  },
  ruleRow: {
    display: 'flex',
    alignItems: 'center',
    height: 26,
  },
  ruleText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(1),
  },
  checkIcon: {
    fontSize: 18,
  },
  checkGreen: {
    color: theme.palette.success.main,
  },
  closeIcon: {
    fontSize: 18,
    color: theme.palette.error.main,
  },
}));

type NewPasswordFields = {
  user: object;
  newPassword: string;
};

export const NewPasswordForm = (props: Props) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, setPassword] = useState<string>('');
  const { setUserCredential } = React.useContext(GlobalContext);
  const { control, handleSubmit, register, errors } =
    useForm<NewPasswordFields>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {
        // newPassword: '',
      },
    });

  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordValidation({
      password: password,
    });

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const submitNewPassword = async ({
    user = props.cognitoUser,
    newPassword = password,
  }: NewPasswordFields) => {
    setError(null);
    try {
      setLoading(true);
      await Auth.completeNewPassword(user, newPassword);
      setUserCredential(newPassword!, user);
      props.setRenderForm(Forms.Login);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  const renderValidationRules = () => {
    return (
      <div style={{ marginTop: 12 }}>
        <div className={classes.ruleRow}>
          {password.length && !validLength ? (
            <CloseIcon className={classes.closeIcon} />
          ) : (
            <CheckIcon
              className={clsx(classes.checkIcon, {
                [classes.checkGreen]: password.length,
              })}
            />
          )}
          <Typography className={classes.ruleText}>
            At least 8 characters long
          </Typography>
        </div>
        <div className={classes.ruleRow}>
          {password.length && !upperCase ? (
            <CloseIcon className={classes.closeIcon} />
          ) : (
            <CheckIcon
              className={clsx(classes.checkIcon, {
                [classes.checkGreen]: password.length,
              })}
            />
          )}
          <Typography className={classes.ruleText}>
            One upper-case letter
          </Typography>
        </div>
        <div className={classes.ruleRow}>
          {password.length && !lowerCase ? (
            <CloseIcon className={classes.closeIcon} />
          ) : (
            <CheckIcon
              className={clsx(classes.checkIcon, {
                [classes.checkGreen]: password.length,
              })}
            />
          )}
          <Typography className={classes.ruleText}>
            One lower-case letter
          </Typography>
        </div>
        <div className={classes.ruleRow}>
          {password.length && !hasNumber ? (
            <CloseIcon className={classes.closeIcon} />
          ) : (
            <CheckIcon
              className={clsx(classes.checkIcon, {
                [classes.checkGreen]: password.length,
              })}
            />
          )}
          <Typography className={classes.ruleText}>One number</Typography>
        </div>
        <div className={classes.ruleRow}>
          {password.length && !specialChar ? (
            <CloseIcon className={classes.closeIcon} />
          ) : (
            <CheckIcon
              className={clsx(classes.checkIcon, {
                [classes.checkGreen]: password.length,
              })}
            />
          )}
          <Typography className={classes.ruleText}>
            One special character
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(submitNewPassword)} className={classes.root}>
      <Paper className={classes.newPwCard} elevation={8}>
        <Typography className={classes.inputLabel}>New Password</Typography>
        <Controller
          name="newPassword"
          control={control}
          render={({ onChange, value, name }) => (
            <div className={classes.marginBottom}>
              <OutlinedInput
                fullWidth
                inputRef={register({
                  required: true,
                  validate: {
                    custom: () =>
                      validLength &&
                      hasNumber &&
                      upperCase &&
                      lowerCase &&
                      specialChar,
                  },
                })}
                name={name}
                value={password}
                onChange={onPasswordChange}
                error={
                  !!errors.newPassword?.message || !!errors.newPassword?.type
                }
                type="password"
                className={classes.inputField}
                placeholder="Enter New Password"
                classes={outlinedInputClasses}
              />
              {renderValidationRules()}
              {error && (
                <Typography color="error" className={classes.errorMsg}>
                  {error?.message}
                </Typography>
              )}
            </div>
          )}
        />
        <Button type="submit" color="primary" className={classes.submitButton}>
          {loading ? 'Loading...' : 'Save new password'}
        </Button>
      </Paper>
    </form>
  );
};
