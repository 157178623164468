const AgeConstantMap = [
  { name: '0-9', value: '0,9' },
  { name: '10-19', value: '10,19' },
  { name: '20-29', value: '20,29' },
  { name: '30-39', value: '30,39' },
  { name: '40-49', value: '40,49' },
  { name: '50-59', value: '50,59' },
  { name: '60-64', value: '60,64' },
  { name: '65+', value: '65,1000' },
];
export function matchFilterValuePipe(value: string, attribute: string) {
  switch (attribute) {
    case 'Age':
      return (
        AgeConstantMap.find((age: any) => age.value == value)?.name || value
      );
  }
  return value;
}
