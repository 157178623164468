import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { theme } from '../../../themes/theme';

interface BulletPlotProps {
  code: string;
  numEligible: number;
  numComplaint: number;
  numNonComplaint: number;
  progress: string;
  // numExclusive: number;
}

export const BulletPlot = (props: BulletPlotProps) => {
  const calcProgress = ((props.numComplaint / props.numEligible) * 100).toFixed(
    0
  );

  const option = {
    tooltip: {
      show: false,
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: '', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    // legend: { left: 0, right: 0, top: 0, bottom: 0 },
    grid: {
      left: '-25%',
      right: 0,
      top: '100%',
      bottom: 0,
      containLabel: true,
      // show: false,
    },
    xAxis: {
      show: false,
      max: props.numEligible,
    },
    yAxis: {
      show: false,
      // if the text in data is too long it will occupy the space of bar chart
      data: ['code'],
      max: '100%',
    },
    backgroundColor: 'transparent',
    series: [
      {
        barMinHeight: 15,
        // cursor: 'default',
        name: 'Complaint',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontFamily: theme.typography.fontFamily,
          fontSize: 10,
          color: theme.palette.success.main,
          formatter: function (d: any) {
            // return props.progress;
            return `${props.progress}%`;
          },
        },
        emphasis: {
          label: { show: true },
          focus: 'series',
          blurScope: 'coordinateSystem',
        },
        data: [props.numComplaint], // complaint / elibible * 100%
        barWidth: '100%',
        itemStyle: {
          normal: { color: `${theme.palette.success.light}` },
          emphasis: {
            color: `${theme.palette.success.light}`,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // shadowBlur: 0,
            // shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
      {
        // cursor: 'default',
        name: 'Non Complaint',
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          fontFamily: theme.typography.fontFamily,
          fontSize: 10,
          color: theme.palette.error.main,
          formatter: function (d: any) {
            return `${100 - parseInt(props.progress)}%`;
          },
          rich: {
            cursor: 'default',
          },
        },
        emphasis: {
          label: { show: true },
          focus: 'series',
          blurScope: 'coordinateSystem',
        },
        data: [props.numNonComplaint], // non-complaint / elibible * 100%
        barWidth: '100%',
        itemStyle: {
          normal: { color: `${theme.palette.error.light}` },
          emphasis: { color: `${theme.palette.error.light}` },
        },
      },
      // {
      //   name: 'Exculsive',
      //   type: 'bar',
      //   stack: 'total',
      //   label: {
      //     show: false,
      //     fontFamily: theme.typography.fontFamily,
      //     fontSize: 10,
      //     color: theme.palette.text.primary,
      //     formatter: function (d: any) {
      //       return `${((props.numExclusive / props.numEligible) * 100).toFixed(
      //         0
      //       )}%`;
      //     },
      //   },
      //   emphasis: {
      //     label: { show: true },
      //     focus: 'series',
      //     blurScope: 'coordinateSystem',
      //   },
      //   data: [props.numExclusive], // exclusive / elibible * 100%
      //   barWidth: '100%',
      //   itemStyle: {
      //     normal: { color: `${theme.palette.text.disabled}` },
      //     emphasis: { color: `${theme.palette.text.disabled}` },
      //   },
      // },
    ],
  };
  return <ReactEcharts option={option} style={{ height: 24, width: 140 }} />;
};
