import React, {ChangeEvent, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  fade,
  makeStyles,
  Menu,
  MenuProps,
  styled,
  TableContainer,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../../components/GlobalContext';
import {
  tableUseStyles,
  tableUseToolbarStyles,
} from '../../../../Insurances/InsuranceListingPage/InsuranceListTable/TableStyle';

interface HeadCell {
  id: string;
  label: string;
  align: 'left' | 'right';
  disablePadding: boolean;
}

function createHeadCell(
  id: string,
  label: string,
  align: 'left' | 'right',
  disablePadding: boolean
): HeadCell {
  return { id, label, align, disablePadding };
}

const headCells: HeadCell[] = [
  createHeadCell('payerId', 'Payer ID', 'left', false),
  createHeadCell('payerName', 'Payer  Name', 'left', false),
];

const nonsortableHeads = ['payerId', 'payerName'];

type Order = 'asc' | 'desc';

const useStyles = makeStyles(() => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  // rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the more-options column */}
        {/* <TableCell></TableCell> */}
      </TableRow>
    </TableHead>
  );
}

interface ItemListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  rows: any;
  count: number;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  refetchDataFunc?: any;
  fileId: any;
}

export const NewPayerListTable = (props: ItemListTableViewProps) => {
  const classes = tableUseStyles();
  const altClasses = useStyles();
  const navigate = useNavigate();
  const { limit, rows } = props;
  const [isSorting, setIsSorting] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);
  const [searchInput, setSearchInput] = React.useState('');

  React.useEffect(()=>{
    setPage(props.skip/limit)
  },[props.skip])
  React.useEffect(()=>{
    setRowsPerPage(limit)
    setPage(0);
  },[limit])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { reloadUser } = React.useContext(GlobalContext);

  React.useEffect(() => {
    reloadUser();
  }, []);

  React.useEffect(() => {
    setFilteredRows(rows);
    setIsSorting(false);
  }, [rows]);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          request: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  // @ts-ignore
  return (
    <div className={`${classes.root} ${altClasses.root}`}>
      <Paper className={`${classes.paper} ${altClasses.rounded}`} elevation={1}>
        <div
          className={`${classes.lightBackground} ${altClasses.lightBackground}`}
        >
          <EnhancedTableToolbar
            tableTitle={`Payer ID(s) Listed in File`}
            //search bar
            enableSearch={false}
            searchPlaceHolder={'Search'}
            searchFunction={setSearchInput}
          />
        </div>

        <div style={{ position: 'relative' }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={data.count}
              />
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          key={`${index}`}
                          className={classes.hoverTableBodyRows}
                          onClick={() => {
                            navigate(`/payerFiles/${props.fileId}/careGap`, {
                              state: {
                                name: row.payerId + ' ' + row.payerName,
                                payerId: row.payerId,
                              },
                            });
                          }}
                        >
                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {row.payerId ?? '-'}
                            </span>
                          </TableCell>

                          <TableCell align="left">
                            <span className={classes.tableBodyRowCell}>
                              {/* {genderShortFormPipe(row.gender)} */}
                              {row.payerName ?? '-'}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {!filteredRows.length ? (
                  <TableRow>
                    <TableCell align={'left'}>
                    <span className={classes.noMoreDataDescription}>
                      No Payer ID Found.
                    </span>
                    </TableCell>
                  </TableRow>
                ) : null }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

interface EnhancedTableToolbarProps {
  tableTitle?: string;
  //search bar
  enableSearch?: boolean;
  searchPlaceHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  searchEnableFilter?: boolean;
  renderButton?: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = tableUseToolbarStyles();
  const altClasses = useStyles();
  return (
    <Paper
      className={`${classes.tableToolBarContainer} ${altClasses.tableToolBarContainer}`}
      elevation={0}
    >
      {props.tableTitle && (
        <div className={`${classes.header} ${altClasses.header}`}>
          {/* title */}
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {props.tableTitle}
          </Typography>
          {props.renderButton && (
            <div className={classes.headerButton}>{props.renderButton}</div>
          )}
        </div>
      )}
    </Paper>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: any;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = tableUseStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
