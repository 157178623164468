import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  styled,
  Theme,
  Typography,
} from '@material-ui/core';
import { theme } from '../../../../../../../../../themes/theme';

interface CareGapOptionDialogProps {
  dialogState: OpenCareGapOptionDialogStateType;
  dialogContent: CareGapDialogContentType;
}

export const CareGapOptionDialog: React.FC<CareGapOptionDialogProps> = ({
  dialogState,
  dialogContent,
}) => {
  const classes = useStyles();

  const getCareGapDialogTitle = () => {
    if (dialogContent.title === 'Closed') {
      return 'Closed Care Gap';
    } else {
      return dialogContent.title;
    }
  };

  return (
    <div role="CareGapOptionDialog" className={classes.root}>
      <BootstrapDialog
        aria-labelledby="DeListPatientDialog"
        open={dialogState.open}
        {...theme}
      >
        <div role="DeListPatientDialog-container">
          <Paper className={classes.paperContainer}>
            <Grid container>
              {/* section header */}
              <Grid item xs={12} className={classes.cardHeader}>
                {/* title */}
                <Typography className={classes.cardTitle}>
                  {getCareGapDialogTitle()}
                </Typography>
              </Grid>

              {/* main region */}
              <Grid item xs={12} className={classes.cardContent}>
                <Grid item xs={12}>
                  {dialogContent.description}
                </Grid>
              </Grid>

              {/* section footer */}
              <Grid item xs={12} className={classes.sectionFooter}>
                {/* cancel button */}
                {dialogContent.cancelButton}

                {/* confirm button */}
                {dialogContent.confirmButton}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </BootstrapDialog>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paperContainer: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3, 3, 4, 3),
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    padding: theme.spacing(2.5, 0),
  },
  sectionHeader: {
    height: 28,
    borderBottom: '1px solid #d1d1d1',
    color: theme.palette.grey[700],
    fontSize: 20,

    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.4,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.625),
    paddingLeft: theme.spacing(3),
  },
  sectionFooter: {
    position: 'relative',
    height: 72,
    textTransform: 'capitalize',
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BootstrapDialog = styled(Dialog)((theme: Theme) => ({
  '& .MuiPaper-root': {
    width: 640,
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));
