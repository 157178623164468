import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useDeListedPatientsTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableContentLoadingSpinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      height: '100%',
    },
  })
);
