import React from 'react';
import { AccountOptionType, User } from '../../../../../enum/Users';
import { useActivateUsersMutation } from './activateUsers.generated';
import { useDeactivateUsersMutation } from './deactivateUsers.generated';
import { ToggleAccountStatusDialogView } from './View';
import { ToggleStatusType } from '../../Tabs/AllUsers/View';

interface ToggleAccountStatusDialogContainerProps {
  optionCode: AccountOptionType;
  isOpenDialog: boolean;
  setIsOpenDialog: (input: boolean) => void;
  setUpdatedUsers: (input: User[]) => void;
  toBeUpdatedUsers: User[];
  setSelected: (input: User[]) => void;
  setTaskId: (input: string) => void;
  querying: undefined | boolean;
  setToggleStatus: (input: ToggleStatusType) => void;
}

export const ToggleAccountStatusDialogContainer = (
  props: ToggleAccountStatusDialogContainerProps
) => {
  // deactivate users api
  const [deactivateUsersMutation] = useDeactivateUsersMutation();
  async function disableUsers(users: User[]) {
    const { data } = await deactivateUsersMutation({
      variables: {
        input: {
          userIds: users.map((user) => user.userId),
        },
      },
    });

    if (data) {
      props.setTaskId(data.disableUser.taskIds || '');
      props.setToggleStatus({
        result: data.disableUser.result!,
        operationCode: AccountOptionType.DEACTIVATE,
      });
    } else {
      props.setToggleStatus({
        result: false,
        operationCode: AccountOptionType.DEACTIVATE,
      });
    }
  }
  //   activate users api
  const [activateUsersMutation] = useActivateUsersMutation();
  async function enableUsers(users: User[]) {
    const { data } = await activateUsersMutation({
      variables: {
        input: {
          userIds: users.map((user) => user.userId),
        },
      },
    });

    if (data) {
      props.setToggleStatus({
        result: data.enableUser.result!,
        operationCode: AccountOptionType.ACTIVATE,
      });
    } else {
      props.setToggleStatus({
        result: false,
        operationCode: AccountOptionType.ACTIVATE,
      });
    }
  }

  return (
    <ToggleAccountStatusDialogView
      optionCode={props.optionCode}
      enableUsers={enableUsers}
      disableUsers={disableUsers}
      isOpenDialog={props.isOpenDialog}
      toBeUpdatedUsers={props.toBeUpdatedUsers}
      setIsOpenDialog={props.setIsOpenDialog}
    />
  );
};
