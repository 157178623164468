import React from 'react';
import { UpdateUserClinicAndDivisionRequestPairInput, UserIdsInBatch } from '../../../../app.types.generated';
import { GlobalContext } from '../../../../components/GlobalContext';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ClinicIdAndDivisionIdPairsInput } from "../NewUsers/View";
import { EditUserView } from './View';
import {
  useGetRolesByAccountIdQuery,
  useResendTemporaryPasswordMutation,
  useUpdateUserMutation,
} from './index.generated';

export interface UpdateUserInput {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  roleName: string;
  phone: string;
  clinicAndDivisionPairs?: UpdateUserClinicAndDivisionRequestPairInput[];
}


export const EditUserViewContainer = () => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  const [updateUserMutation] = useUpdateUserMutation();
  const roles = useGetRolesByAccountIdQuery({ variables: {} });

  const [resendTemporaryPasswordMutation] =
    useResendTemporaryPasswordMutation();

  if (!roles.called || roles.loading) {
    return <LoadingSpinner style={{ height: '100%', width: '100%' }} />;
  }

  async function resendTemporaryPassword(
    userIds: UserIdsInBatch,
    userEmail: string
  ) {
    try {
      const { data } = await resendTemporaryPasswordMutation({
        variables: {
          input: userIds,
        },
      });

      if (data) {
        setToastMessage({
          isOpen: true,
          severity:
            data?.resendTemporaryPassword.result === true ? 'success' : 'error',
          snackbarMsg:
            data?.resendTemporaryPassword.result === true
              ? `Temporary Password has been sent to ${userEmail}`
              : `Temporary Password fail to send to ${userEmail}, please try again.`,
        });

        setIsOpenToast(true);
      }
    } catch (err: any) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: `Exceeded daily email limit. Temporary Password fails to send to ${userEmail}, please try again. `,
      });

      setIsOpenToast(true);
    }
  }
  const mapUserIntoUserClinicAndDivisionRequestPairInput = ({userId,
                                                              firstName,
                                                              lastName,
                                                              email,
                                                              roleId,
                                                              roleName,
                                                              phone,
                                                              clinicAndDivisionPairs
                                                            }: UpdateUserInput
  ) => {
    const temp: any[] = [];
    clinicAndDivisionPairs?.forEach((clinicIdAndDivisionIdPair: any) => {
          if (clinicIdAndDivisionIdPair.clinicId) {
            if (clinicIdAndDivisionIdPair.divisions.length > 0) {
              clinicIdAndDivisionIdPair.divisions.forEach((division: any) => {
                temp.push({
                  clinicID: clinicIdAndDivisionIdPair.clinicId,
                  divisionID: division.value,
                  divisionName: division.name,
                  email: email,
                  firstName: firstName,
                  isSiteDesignee: false,
                  lastName: lastName,
                  roleID: roleId,
                  userID: userId,
                })
              })
            } else {
              temp.push({
                clinicID: clinicIdAndDivisionIdPair.clinicId,
                divisionID: '',
                divisionName: '',
                email: email,
                firstName: firstName,
                isSiteDesignee: false,
                lastName: lastName,
                roleName: roleName,
                userID: userId,
              })
            }
          }
        }
    )
    return temp;
  }


  async function updateUserInfo({
    userId,
    roleId,
    roleName,
    firstName,
    lastName,
    email,
    phone,
    clinicAndDivisionPairs
  }: UpdateUserInput) {
    await updateUserMutation({
      variables: {
        input: {
          id: userId,
          role: roleId,
          lastName: lastName,
          firstName: firstName,
          phone: phone,
          clinicAndDivisionPairs: clinicAndDivisionPairs,
        },
      },
    });
  }

  return (
    <EditUserView
      updateUserInfo={updateUserInfo}
      roleOptions={roles.data?.getRolesByAccountId!}
      resendTemporaryPassword={resendTemporaryPassword}
    />
  );
};
