import * as React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  fade,
  Menu,
  MenuItem,
  MenuProps,
  styled,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {CloseIcon, FileDownloadIcon,} from '../../../../../assets/Icons/ExportPatients';
import {theme} from '../../../../../themes/theme';
import {ExportIcon} from '../../../../../assets/Icons/InsurancesExportPatinets/ExportIcon';
import {
  BeginExportPatientByCategoryQuery,
  useBeginExportPatientByCategoryQuery,
} from './index.generated';
import {GlobalContext} from '../../../../../components/GlobalContext';
import {
  useGetExportTaskStatusQuery
} from "../../../../Panels/ViewPanels/components/ExportFiles/index.generated";

type Category = 'activeinternal' | 'activeexternal' | 'inactive' | 'rejected' | any

interface ContentRegionProps {
  className?: string;
  format: string;
  category: Category,
  rejectedReasonCategoryList?: Array<string>
  setOpenDialog: (input: any) => void;
}

interface ExportDialogProps {
  formatText: string;
  openDialog: boolean;
  category: Category;
  rejectedReasonCategoryList?: Array<string>
  setOpenDialog: (input: boolean) => any;
}

interface ExportMenuProps {
  title?: string;
  category: Category
  rejectedReasonList?: Array<string>
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {

  },
  exportOption: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
  },
  exportIcon: {
    height: 20,
    width: 20,
    color: theme.palette.grey[600],
    paddingRight: 4,
  },
  dialogTitle: {
    margin: theme.spacing(0),
    width: '198px',
    height: '32px',
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0px',
    lineHeight: '32px',
    padding: theme.spacing(2.5, 'auto', 2.5, 3),
  },

  dialogContentRegion: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3.5),
    },

    '& .MuiTypography-root': {
      height: '24px',
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: '24px',
      fontSize: '16px',
    },

    '& .MuiTypography-root span': {
      display: 'block',
      height: '40px',
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '40px',
      marginBottom: theme.spacing(2.25),
    },

    '& img': {
      height: '62px',
      width: '96px',
      // marginTop: theme.spacing(10),
    },

    '& a': {
      display: 'block',
      width: '140px',
      height: '48px',
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      letterSpacing: 0,
      lineHeight: '48px',
      background: theme.palette.primary.main,
      borderRadius: '5px',
      marginTop: theme.spacing(3.9375),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(1.5), // -6.5 1.5
    width: '160px',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      width: '87px',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '640px',
    height: '468px',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle {...other} className={classes.dialogTitle}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '24px',
            color: theme.palette.text.hint,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

// component of the main content region in the dialog
const ContentRegion = (props: ContentRegionProps) => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const aElement = React.useRef<HTMLAnchorElement>(null);
  const {category, format, rejectedReasonCategoryList} = props

  const beginExportPatientByCategoryQuery = useBeginExportPatientByCategoryQuery({
    variables: {
      input: {
        category: category,
        fileFormat: format,
        rejectedReasonCategoryList: rejectedReasonCategoryList
      }
    }
  });

  const getTaskID = (query: BeginExportPatientByCategoryQuery | undefined) => {
    return query?.beginExportPatientByCategory.taskId;
  };

  const taskId = React.useMemo(() => {
    if (beginExportPatientByCategoryQuery.error) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something went wrong, please try again',
      });
      setIsOpenToast(true);
      props.setOpenDialog(false);
      return '';
    }
    return getTaskID(beginExportPatientByCategoryQuery.data);
  }, [beginExportPatientByCategoryQuery]);


  const getUrlQuery = useGetExportTaskStatusQuery({
    variables: {input: {taskId: taskId ?? ''}},
    skip: (!taskId || taskId==""),
  },)
  const getLinks = (query: any | undefined) => {
    return query?.getExportTaskStatus.url;
  };
  const exportLink = React.useMemo(() => {
    if (getUrlQuery.error) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something went wrong, please try again',
      });
      setIsOpenToast(true);
      props.setOpenDialog(false);
      return '';
    }
    return getLinks(getUrlQuery.data);
  }, [getUrlQuery]);

  React.useEffect(() => {
    if (taskId) {
      if (getUrlQuery?.data?.getExportTaskStatus?.url && getUrlQuery?.data?.getExportTaskStatus?.url !== "") {
        getUrlQuery.stopPolling();
      } else {
        getUrlQuery.startPolling(5000);
      }
    }
  }, [taskId, getUrlQuery]);

  React.useEffect(() => {
    const timer = setTimeout(() => aElement.current?.click(), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [exportLink, aElement]);

  if (!exportLink) {
    return (
      <div className={props.className}>
        <Box>
          <CircularProgress disableShrink thickness={4} />
        </Box>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 18,
          }}
        >
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            Exporting…
          </Typography>
          <Typography
            style={{
              color: theme.palette.grey[600],
              fontSize: 16,
            }}
          >
            Please wait while file is being prepared for download.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <FileDownloadIcon
        style={{
          width: 35,
          height: 43,
          color: `${theme.palette.primary.main}`,
        }}
      />

      <Typography
        style={{
          height: '48px',
          width: '320px',
          marginTop: '32px',
          color: theme.palette.grey[600],
          fontSize: 16,
        }}
      >
        Your download will start in a few seconds. Click button below to
        download manually.
      </Typography>

      <a
        href={exportLink}
        download
        ref={aElement}
      >{`Download .${props.format}`}</a>
    </div>
  );
};

// component of popup dialog
const ExportDialog = (props: ExportDialogProps) => {
  const { openDialog, setOpenDialog, formatText, category, rejectedReasonCategoryList } = props;

  const classes = useStyles();

  const handleClose = () => {
    // close dialog
    setOpenDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Export Patients
        </BootstrapDialogTitle>

        <DialogContent>
          <ContentRegion
            category={category}
            format={formatText}
            className={classes.dialogContentRegion}
            rejectedReasonCategoryList={rejectedReasonCategoryList}
            setOpenDialog={setOpenDialog}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default function ExportFileMenu(props: ExportMenuProps) {
  const { category, rejectedReasonList } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formatText, setFormatText] = React.useState('');
  const openMenu = Boolean(anchorEl);

  const classes = useStyles();

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    // click export menu button
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // close export menu
    setAnchorEl(null);
    setOpenDialog(false);
  };

  const handleOption = (event: any) => {
    // select options
    setFormatText(event.currentTarget.textContent.split('.').slice(-1)[0]);
    setAnchorEl(null);
    setOpenDialog(true);
  };

  return (
    <div className={classes.root}>
      <Button
        id="export-option-button"
        aria-controls="export-option-menu"
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        variant="contained"
        size={'small'}
        onClick={handleExportClick}
        disabled={false}
      >
        <ExportIcon className={classes.exportIcon} />
        <span className={classes.exportOption}>{props.title ? props.title : 'Export'}</span>
      </Button>

      <StyledMenu
        id="export-option-menu"
        MenuListProps={{
          'aria-labelledby': 'export-option-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOption} disableRipple>
          <span>Save as .xls</span>
          <FileDownloadIcon />
        </MenuItem>
        <MenuItem onClick={handleOption} disableRipple>
          <span>Save as .csv</span>
          <FileDownloadIcon />
        </MenuItem>
      </StyledMenu>

      <ExportDialog
        category={category}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        rejectedReasonCategoryList={rejectedReasonList}
        formatText={formatText}
      />
    </div>
  );
}
