import React from 'react';
import { makeStyles } from '@material-ui/core';
import { RolesAndPermissionsList } from '../../components/RolesAndPermissionsListTable';
import { permissionRow } from '../../../../../enum/Users';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  subClass: {
    flexGrow: 1,
  },
}));

interface RolesAndPermissionsViewProps {}

export const RolesAndPermissionsView = (
  props: RolesAndPermissionsViewProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.subClass}>
        <RolesAndPermissionsList permissionRow={permissionRow} />
      </div>
    </div>
  );
};
