import React, { useContext } from 'react';
import { GlobalContext } from '../GlobalContext';
import { Navigate, useLocation, useParams } from 'react-router';
import { Loading } from '../Loading';
import {
  CheckIsAdmin,
  CheckIsCareManager,
  CheckIsHudsonClinician,
} from '../CheckIsAdmin';
import { ErrorView } from '../ErrorBoundary/View';
import { useFeatures } from '../FeatureFlag';
export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  let paramsArray = Object.values(params);
  if (Object.values(params).length >= 1) {
    paramsArray = paramsArray.filter((param) => param != params['*']);
  }
  return paramsArray.reduce(
    (path, param) => path?.replace('/' + param, ''),
    location.pathname
  );
};
const AuthenticatedRouteWithAdminComponent: React.FC<{
  element: React.ReactElement;
  featureName?: string;
}> = ({ element, featureName }) => {
  const currentFeatures = useFeatures();
  const location = useLocation();
  const { loggedInUser, loading } = useContext(GlobalContext);
  if (loading) {
    return <Loading />;
  }
  if (!loggedInUser) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }
  if (CheckIsHudsonClinician(loggedInUser)) {
    if (location.pathname === '/dashboard') {
      return element;
    }
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }
  // if user is not allowed to access to the url based on feature list
  if (featureName) {
    const hasFeature = currentFeatures[featureName];
    if (hasFeature) {
      return element;
    }
    return (
      <ErrorView
        error={new Error('Forbidden')}
        resetErrorBoundary={() => {
          window.location.replace('/dashboard');
        }}
      />
    );
  }
  if (CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)) {
    return element;
  }
  return (
    <Navigate
      to={{
        pathname: '/dashboard',
      }}
    />
  );
};
const AuthenticatedRouteWithPermissionComponent: React.FC<{
  element: React.ReactElement;
  featureName?: string;
}> = ({ element, featureName }) => {
  const currentFeatures = useFeatures();
  const location = useLocation();
  const { loggedInUser, loading } = useContext(GlobalContext);
  const basePath = useBasePath();
  if (loading) {
    return <Loading />;
  }
  if (!loggedInUser) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }
  if (CheckIsHudsonClinician(loggedInUser)) {
    if (location.pathname === '/dashboard') {
      return element;
    }
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }
  // if user is not allowed to access to the url based on feature list
  if (featureName) {
    const hasFeature = currentFeatures[featureName];
    if (hasFeature) {
      return element;
    }
    return (
      <ErrorView
        error={new Error('Forbidden')}
        resetErrorBoundary={() => {
          window.location.replace('/dashboard');
        }}
      />
    );
  }
  const paths = basePath?.split('/').filter((res) => res != '');
  const permissions = loggedInUser.role?.urls?.map((url: any) => url.url) || [];
  for (let permission of permissions) {
    const permissionPath = permission.split('.');
    if (JSON.stringify(permissionPath) === JSON.stringify(paths)) {
      return element;
    }
  }
  return (
    <Navigate
      to={{
        pathname: '/dashboard',
      }}
    />
  );
};
export interface AuthRouteWithPermissionProps {
  path?: string;
  layout?: React.FunctionComponent<any>;
  element: React.ReactElement;
  checkAdmin?: boolean;
  featureName?: string;
}
export const AuthenticatedRouteWithPermission: React.FC<AuthRouteWithPermissionProps> = ({
  layout: Layout,
  checkAdmin: CheckAdmin,
  element,
  featureName,
  ...rest
}) => {
  if (Layout) {
    return CheckAdmin ? (
      <AuthenticatedRouteWithAdminComponent
        element={<Layout>{element}</Layout>}
        featureName={featureName}
      />
    ) : (
      <AuthenticatedRouteWithPermissionComponent
        element={<Layout>{element}</Layout>}
        featureName={featureName}
      />
    );
  } else {
    return CheckAdmin ? (
      <AuthenticatedRouteWithAdminComponent element={element} />
    ) : (
      <AuthenticatedRouteWithPermissionComponent element={element} />
    );
  }
};
