import React from 'react';
import { PatientInfoView } from './View';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { PanelPatient } from '../../../../../../../app.types.generated';

interface PatientInfoContainerProps {
  patientInfo: PatientFaceSheet;
  setClickedFamilyRows: (input: boolean) => void;
  originalPatientId: string;
  hardRefresh: () => void;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
  showLockIcon: boolean;
  handleSwitchNextPatient: () => void;
  disabledArrowForwardBtn: boolean;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => void;
}

export const PatientInfoContainer = (props: PatientInfoContainerProps) => {
  return <PatientInfoView {...props} />;
};
