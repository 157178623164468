import React from 'react';
import { NotesTabView } from './View';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';

export enum NoteOption {
  ADD = 'Add New',
  EDIT = 'Edit Note',
  DELETE = 'Delete',
}

interface NotesTabContainerProps {
  tabLabel: string;
  patientInfo: PatientFaceSheet;
  hardRefresh: () => void;
}

export const NotesTabContainer: React.FC<NotesTabContainerProps> = ({
  ...props
}) => {
  return (
    <div id="NotesTabContainer">
      <NotesTabView notesData={props.patientInfo.notes ?? []} {...props} />
    </div>
  );
};
