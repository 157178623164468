import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router';
import {MainRegion} from '../../../components/MainRegion';
import EligibilityTable from "./components/PatientTable/EligibilityPatientTable";
import {EligibilityCategory} from "../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import {BreadCrumbHeader} from "../../../components/BreadCrumbHeader";
import {PatientListTab} from "./components/PatientListTab";
import {Update} from "@material-ui/icons";
import moment from 'moment'
import {Grid, Tooltip} from "@material-ui/core";
import {ReasonFilter} from "./components/ReasonFilter";
import {
  useGetEligibilityRejectedReasonCategoryListQuery,
  useGetEligibilitySummaryInPatientQuery
} from './index.generated';
import EngagementBatchesTable from "./components/PatientEngagement/EngagementBatchesTable";
import {LoadingSpinner} from "../../../components/LoadingSpinner";
import useEligibility from "./hooks/useEligibility";
import {SubMainRegion} from "../../../components/SubMainRegion";
import PatientListViewSubContainer from "./SubContainer";


interface EligibilitySummaryInfo {
  activeExternal: number
  activeInternal: number
  inactive: number
  nextScheduledDate: string
  rejected: number
  smsBatchCount: number
  updatedDate: string
}

export default function PatientListViewContainer () {
  const classes = useStyles();
  const getEligibilitySummaryQuery = useGetEligibilitySummaryInPatientQuery()
  if (!getEligibilitySummaryQuery.called || getEligibilitySummaryQuery.loading) {
    return <LoadingSpinner style={{width: '100%', marginTop: 80}}/>
  }

  const summaryInfo: EligibilitySummaryInfo = getEligibilitySummaryQuery.data!.getEligibilitySummary!.summaryInfo
  const fUpdated = summaryInfo.updatedDate.length ? moment(new Date(summaryInfo.updatedDate)).format("MMMM D, HH:mm A") : ""
  const fNextSchedule = summaryInfo.nextScheduledDate.length ? moment(new Date(summaryInfo.nextScheduledDate)).format("MMMM Do") : ''

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={'Eligibility Check Results'}
          rootLink={`/eligibility`}
          rootName={'Eligibility'}
          isTitle={true}
          isButton={true}
          // removed due to NPH-2268
          // renderButton={<Tooltip
          //   placement={'bottom-end'}
          //   title={<span className={classes.nextScheduleDateTitle}>{`Next scheduled for ${fNextSchedule}`}</span>}
          // >
          //   <div className={classes.updatedDateContainer}>
          //     <span className={classes.updatedDateTitle}>{`Last updated ${fUpdated}`}</span>
          //     <Update className={classes.icon}/>
          //   </div>
          // </Tooltip>}
        />
      }
    >
      <PatientListViewSubContainer />
    </MainRegion>
    );
  };


  const useStyles = makeStyles((theme) => ({
    content: {
      width: '100%',
      flexGrow: 1
    },
    updatedDateContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    updatedDateTitle: {
      fontSize: 14,
      lineHeight: '24px',
      color: '#656565',
      marginRight: 8,
      fontWeight: 500
    },
    nextScheduleDateTitle: {
      fontSize: 12,
      lineHeight: '20px',
      color: '#FFF',
      fontWeight: 600,
      margin: '8px 8px 12px 16px'
    },
    icon: {
      color: theme.palette.grey[500],
      '&:hover': {
        cursor: 'text',
      },
    },
  }));
