import moment from 'moment';
import store, { useAppSelector } from '..';
import {
  FilterState,
  initialFilterState,
  initPatientFilter,
  onChange,
  resetPatientFilter,
  applyPatientFilter,
} from '../reducer/patientFilter';
import React from 'react';

interface tmpFiltersAction {
  type: keyof FilterState | 'reset';
  value?: any;
}

// Moved outside because it doesn't depend on hook's scope
const handleFilterValue = (value: any, isDate = false) => {
  if (Array.isArray(value)) {
    return value.includes('All') ? [] : value;
  } else if (isDate && value) {
    return moment.utc(value).format('YYYY-MM-DD');
  }
  return value;
};

const reducer = (state: FilterState, action: tmpFiltersAction) => {
  if (action.type === 'reset') {
    return initialFilterState;
  }
  const result: FilterState = { ...state };
  result[action.type] = action.value;
  return result;
};

export const usePatientFilterStore = (componentId: string) => {
  const state = useAppSelector((state) => state.patientFilter);

  const [selectedFilters, dispatchSelectedFilters] = React.useReducer(
    reducer,
    initialFilterState
  );

  const formattedFiltersForQuery = React.useMemo(() => {
    if (!state[componentId]) {
      return {} as FilterState;
    }
    return Object.keys(state[componentId]).reduce((acc, key) => {
      const value = state[componentId][key];
      switch (key) {
        case 'ldosFrom':
        case 'ldosTo':
        case 'dueDateFrom':
        case 'dueDateTo':
          acc[key] = handleFilterValue(value, true); // Handle dates differently
          break;
        case 'openGaps':
          acc[key] = value.find((item: any) => item.value === 'All')
            ? ['ALL']
            : value.map((item: any) => item.value);
          break;
        case 'npis':
          acc[key] = value.map((pcp: any) => pcp.id);
          break;
        default:
          acc[key] = handleFilterValue(value);
      }
      return acc;
    }, {} as FilterState);
  }, [state, componentId]);

  const getState = React.useCallback(() => state, [state]);

  const init = React.useCallback(() => {
    if (!state[componentId]) {
      store.dispatch(initPatientFilter({ componentId }));
    }
  }, [componentId]);

  const reset = React.useCallback(() => {
    store.dispatch(resetPatientFilter({ componentId }));
  }, [componentId]);

  const change = React.useCallback(
    (type: keyof FilterState, value: any) => {
      store.dispatch(onChange({ componentId, type, value }));
    },
    [componentId]
  );

  const apply = React.useCallback(() => {
    store.dispatch(
      applyPatientFilter({ componentId, filters: selectedFilters })
    );
  }, [componentId, selectedFilters]);

  const clear = React.useCallback(() => {
    dispatchSelectedFilters({ type: 'reset' });
    store.dispatch(resetPatientFilter({ componentId }));
  }, [componentId, dispatchSelectedFilters]);

  return {
    state,
    getState,
    init,
    reset,
    change,
    formattedFiltersForQuery,
    selectedFilters,
    dispatchSelectedFilters,
    apply,
    clear,
  };
};
