import { makeStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { theme } from '../../themes/theme';

const useCheckBoxStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 6,
    width: 24,
    height: 24,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.08),0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08);',
    backgroundColor: '#fff',
    'input:hover ~ &': {
      transform: 'scale(1.05)',
    },
    'input:checked ~ &': {
      boxShadow: 'none',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.grey['200'],
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23ABABAB'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  checkedAllIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  indeterminateCheckBox: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: '',
      content: '"--"',
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontSize: 32,
      lineHeight: 0.65,
    },
  },
});

interface StyledCheckboxProps extends CheckboxProps {
  checkedAll: boolean;
}

export function StyledCheckbox(props: StyledCheckboxProps) {
  const classes = useCheckBoxStyles();

  // avoid warning: React does not recognize the `checkedAll` prop on a DOM element
  const { checkedAll, ...rest } = props;

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span
          className={
            classes.icon +
            ' ' +
            (checkedAll ? classes.checkedAllIcon : classes.checkedIcon)
          }
        />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      indeterminateIcon={<span className={classes.indeterminateCheckBox} />}
      {...rest}
    />
  );
}
