import React from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { StyledCheckbox } from '../StyledCheckbox';
import { theme } from '../../themes/theme';
interface CustomSelectProps {
    state: any[];
    type: string;
    setState: any;
    isMultiple: boolean;
    inputTitle: string;
    inputHelper?: string;
    placeholder: string;
    items: any[];
    onChange?: (value: boolean, selectedValue: any[]) => void;
    width?: number;
    showValue?: boolean;
    fullWidth?: boolean;
    isObject?: boolean;
    showSelectedCount?: boolean;
    disable?: boolean;
    tooltips?: boolean;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': {
            borderRadius: 8,
        },
    },
    selectMenuContent: {
        display: 'block',
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: 50,
    },
    selectMenuContentValue: {
        display: 'block',
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        width: 60,
        paddingRight: 8,
    },
    selectMenuContentName: {
        display: 'block',
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '70%',
    },
    placeholderText: {
        color: theme.palette.grey[400],
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        paddingTop: theme.spacing(0.875),
        paddingBottom: theme.spacing(1),
    },
    placeholderTextSelected: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        paddingTop: theme.spacing(0.875),
        paddingBottom: theme.spacing(1),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    disabledPlaceholderTextSelected: {
        color: '#ABABAB',
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        paddingTop: theme.spacing(0.875),
        paddingBottom: theme.spacing(1),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    multipleSelect: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: 12,
    },
    selected: {
        backgroundColor: '#EDF6FF!important',
        color: '#0C77D8!important',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        marginBottom: 1,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    formControl: {
        width: '100%',

        '& .MuiInputBase-root': {
            height: 40,
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
            '& .Mui-disabled':{
                backgroundColor: '#EFEFEF',
                //height: 40,
                borderRadius: 6,
                boxShadow: 'none',
                paddingTop: 0,
                paddingBottom: 0
            }
        },
    },
    disabledFormControl: {
        width: '100%',

        '& .MuiInputBase-root': {
            height: 40,
            borderRadius: 6,
            backgroundColor: '#EFEFEF',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    },
    inputTitleNoCap: {
        height: 24,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.7,
        marginBottom: 4,
    },
    inputTitle: {
        height: 24,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.7,
        textTransform: 'capitalize',
        marginBottom: 4,
    },
    disabledInputTitle: {
        height: 24,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.7,
        textTransform: 'capitalize',
        marginBottom: 4,
        opacity: 0.5,
    },
    select: {
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    checkBoxPosition: {
        position: 'absolute',
        right: 20,
    },
    min40Height: {
        minHeight: 40,
    },
    minWidthAnd40height: {
        minWidth: 416,
        minHeight: 40,
    },
}));

export const DivisionSelect = (props: CustomSelectProps) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<any>(props.state);
    React.useEffect(() => {
        setValue(props.state)
    }, [props.state])
    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        // update selected items according to form type: Insurance, ProviderType and CareTeam
        let newValue = event.target.value;
        if (event.target.value.includes('All') && !value.includes('All')) {
            newValue = ['All'];
        } else if (value.includes('All')) {
            if (event.target.value.includes('All')) {
                newValue = [];
                props.items.forEach((value: any) => {
                    if (!event.target.value.includes(value?.value)) {
                        newValue.push(value.value);
                    }
                });
            } else {
                newValue = newValue.filter((value: any) => value != 'All');
            }
        } else if (event.target.value?.length == props.items?.length) {
            newValue = ['All'];
        }
        setValue(newValue);
        props.setState(props.type, newValue);
    };

    const getItemName = (value: string) => {
        if (!props.isObject) return value;
        else {
            return props.items.find((item) => item?.value == value)?.name || value;
        }
    };

    return (
        <div
            className={classes.multipleSelect}
            style={{ width: props.fullWidth ? '100%' : props.width || 203 }}
        >
            <FormControl
                className={
                    props.disable ? classes.disabledFormControl : classes.formControl
                }
            >
                <Typography
                    id={`${props.inputTitle}`}
                    variant="h5"
                    className={
                        props.disable
                            ? classes.disabledInputTitle
                            : props.inputTitle.includes('of')
                                ? classes.inputTitleNoCap
                                : classes.inputTitle
                    }
                >
                    {props.inputTitle}
                </Typography>

                <Select
                    disabled={props.disable}
                    multiple={props.isMultiple}
                    variant="outlined"
                    displayEmpty
                    value={value}
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDown}
                    onOpen={() => {
                        setIsOpen(true);
                    }}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    renderValue={(selected) => {
                        if (isOpen) {
                            return <div className={classes.placeholderText}>Select</div>;
                        } else if (
                            (selected as string[]).length === 0 ||
                            (selected as string[]).includes('All')
                        ) {
                            return (selected as string[]).includes('All') ? (
                                <div
                                    className={
                                        props.disable
                                            ? classes.disabledPlaceholderTextSelected
                                            : classes.placeholderTextSelected
                                    }
                                    style={{ color: theme.palette.grey[600] }}
                                >
                                    All
                                </div>
                            ) : (
                                <div
                                    className={classes.placeholderText}
                                    style={{ color: theme.palette.grey[400] }}
                                >
                                    {props.placeholder}
                                </div>
                            );
                        }

                        return (
                            <div className={classes.placeholderTextSelected}>
                                {`${(selected as string[]).length} selected`}
                            </div>
                        );
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        autoFocus: false,
                        elevation: 2,
                        getContentAnchorEl: null,
                        style: props.fullWidth
                            ? {
                                maxHeight: 68 * 5 + 8,
                                maxWidth: '100%',
                            }
                            : {
                                maxHeight: 68 * 5 + 8,
                                width: props.showValue ? 416 : 250,
                            },
                        PaperProps: {
                            style: props.fullWidth
                                ? {
                                    // reduce space between input and menu
                                    marginTop: -9,
                                    paddingLeft: 8,
                                    paddingRight: 7,
                                    maxWidth: 400,
                                }
                                : {
                                    // reduce space between input and menu
                                    minWidth: props.width ? props.width - 10 : 192,
                                    marginTop: -9,
                                    paddingLeft: 8,
                                    paddingRight: 7,
                                    maxWidth: 400,
                                },
                        },
                    }}
                    className={classes.select}
                >
                    <MenuItem
                    value="All"
                    classes={{
                        root: classes.listItem,
                        selected: classes.selected,
                    }}
                    className={
                        props.showValue
                            ? classes.minWidthAnd40height
                            : classes.min40Height
                    }
                >
                    <Typography variant="body1" className={classes.selectMenuContent}>
                        All
                    </Typography>
                    <StyledCheckbox
                        checked={value.includes('All')}
                        checkedAll={true}
                        className={classes.checkBoxPosition}
                    />
                </MenuItem>
                    {props.items.map((item, idx) => (
                        <MenuItem
                            key={idx}
                            value={typeof item == 'string' ? item : item.value}
                            classes={{
                                root: classes.listItem,
                                selected: classes.selected,
                            }}
                            className={
                                props.showValue
                                    ? classes.minWidthAnd40height
                                    : classes.min40Height
                            }
                        >
                            {props.showValue && (
                                <Typography
                                    variant="body1"
                                    className={classes.selectMenuContentValue}
                                >
                                    {typeof item == 'string' ? item : item.value}
                                </Typography>
                            )}
                            {props.tooltips &&
                            (typeof item == 'string' ? item : item.name).length > 32 ? (
                                <Tooltip title={typeof item == 'string' ? item : item.name}>
                                    <Typography
                                        variant="body1"
                                        className={
                                            props.showValue
                                                ? classes.selectMenuContentName
                                                : classes.selectMenuContent
                                        }
                                    >
                                        {typeof item == 'string' ? item : item.name}
                                    </Typography>
                                </Tooltip>
                            ) : (
                                <Typography
                                    variant="body1"
                                    className={
                                        props.showValue
                                            ? classes.selectMenuContentName
                                            : classes.selectMenuContent
                                    }
                                >
                                    {typeof item == 'string' ? item : item.name}
                                </Typography>
                            )}
                            <StyledCheckbox
                                checked={
                                    value.includes(
                                        typeof item == 'string' ? item : item.value
                                    ) || value.includes('All')
                                }
                                checkedAll={true}
                                className={classes.checkBoxPosition}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
