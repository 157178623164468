import React from 'react';
import {LoadingSpinner} from '../../../../components/LoadingSpinner';
import {BreadCrumbHeader} from '../../../../components/BreadCrumbHeader';
import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useGetPatientEngagementBatchItemsQuery} from './index.generated';
import EngagementLogTable from "./EngagementLogTable";
import moment from 'moment'
import {MainRegion} from "../../../../components/MainRegion";
import {EngagementLogStatus} from "../PanelPatients/components/PatientEngagementTable/Container";

export interface PatientEngagementLog {
  name: string,
  dob: string,
  gender: string,
  type: string,
  sendBy: string,
  shortName: string,
  lastUpdated: string
  status: EngagementLogStatus,
  statusDescription: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  tableRoot: {
    flexGrow: 1,
    padding: 24,
    backgroundColor: theme.palette.secondary.light,
  },
}));

interface EngagementLogViewProps {
  engagementLogStatus: EngagementLogStatus,
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelName: string;
  panelId: any;
  batchId: any;
  breadCrumbHeader: {
    isTitle: boolean;
    title: string;
    rootLink: string;
    rootName: string;
    childrenLink: string;
    childrenName: string;
    childrenChildrenName: string;
  };
}

export const EngagementLogView = (props: EngagementLogViewProps) => {
  const classes = useStyles();

  const engagements = useGetPatientEngagementBatchItemsQuery({
    variables: {
      input: {
        limit: props.limit,
        skip: props.skip,
        batchId: props.batchId,
        sortBy: [{field: "status", method: "desc"}],
      },
    },
  });
  //sortBy: { field: string; method: 'desc' | 'asc' };

  if (!engagements.called || engagements.loading) {
    return <LoadingSpinner />;
  }

  const getInitials = (input: string) => {
    if (!input.length) {
      return ""
    }
    let names = input.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const parseResultToViewModel = () => {
    let result
    let templateId
    let tName
    if (!engagements.error && engagements.data) {
      result = engagements.data!.getPatientEngagementBatchItems!.results
      templateId = engagements.data!.getPatientEngagementBatchItems!.message_template_id
      tName = engagements.data!.getPatientEngagementBatchItems!.message_template_name
    } else {
      result = engagements.previousData!.getPatientEngagementBatchItems!.results
      templateId = engagements.previousData!.getPatientEngagementBatchItems!.message_template_id
      tName = engagements.previousData!.getPatientEngagementBatchItems!.message_template_name
    }

    let templateName = ""
    if (templateId && templateId.length) {
      templateName = tName && tName.length ? tName : 'Template'
    } else {
      templateName = "Custom Message"
    }
    const data: Array<PatientEngagementLog> = result!.map((item: any) => {
      const {
        dob,
        gender,
        send_time,
        send_by,
        firstname,
        lastname,
      } = item

      const lastUpdated = moment(new Date(send_time)).format("MMMM DD, hh:mm A")
      const shortName = getInitials(send_by)

      const engagement_status = item.status
      let status = EngagementLogStatus.sending
      let statusDescription = engagement_status
      if (engagement_status === 'Sending') {
        status = EngagementLogStatus.sending
        statusDescription = "Sending"
      } else if (engagement_status === "Delivered") {
        status = EngagementLogStatus.delivered
        statusDescription = engagement_status
      } else if (engagement_status === "Fail to Deliver") {
        status = EngagementLogStatus.failed
        statusDescription = engagement_status
      }

      const d: PatientEngagementLog = {
        dob: dob,
        gender: gender,
        lastUpdated: lastUpdated,
        name: `${firstname} ${lastname}`,
        sendBy: send_by,
        shortName: shortName,
        status: status,
        statusDescription: statusDescription,
        type: templateName
      }
      return d
    })

    return data
  }

  const results = engagements.data!.getPatientEngagementBatchItems!.results
  const pageInfo = engagements.data!.getPatientEngagementBatchItems!.pagingInfo
  const infoLimit = results ? (pageInfo!.limit ?? 0) : 0
  const infoSkip = results ? (pageInfo!.skip ?? 0) : 0
  const infoCount = results ? (pageInfo!.count ?? 0) : 0
  const dataSource = parseResultToViewModel()
  const infoFailedCount = results ? (pageInfo!.failCount ?? 0) : 0

  const getPatientText = (count: number) => {
    return count > 1 ? 'Patients' : 'Patient'
  }
  let title = ""
  const {engagementLogStatus} = props
  if (engagementLogStatus === EngagementLogStatus.sending) {
    title = `Batch SMS to ${infoCount} ${getPatientText(infoCount)}`
  } else if (engagementLogStatus === EngagementLogStatus.partialDelivered) {
    title = `Batch SMS to ${infoCount} ${getPatientText(infoCount)}, ${infoFailedCount} ${getPatientText(infoFailedCount)} Failed`
  } if (engagementLogStatus === EngagementLogStatus.failed) {
    title = `Batch SMS to ${infoCount} ${getPatientText(infoCount)} Failed`
  } if (engagementLogStatus === EngagementLogStatus.delivered) {
    title = `Batch SMS to ${infoCount} ${getPatientText(infoCount)} Sent`
  }
  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          isTitle={props.breadCrumbHeader.isTitle}
          title={props.breadCrumbHeader.title}
          rootLink={props.breadCrumbHeader.rootLink}
          rootName={props.breadCrumbHeader.rootName}
          childrenLink={props.breadCrumbHeader.childrenLink}
          childrenName={props.breadCrumbHeader.childrenName}
          childrenChildrenName={props.breadCrumbHeader.childrenChildrenName}
        />
      }
    >
      <div className={classes.tableRoot}>
        <EngagementLogTable
          title={title}
          count={infoCount}
          setSkip={props.setSkip}
          setLimit={props.setLimit}
          skip={infoSkip}
          limit={infoLimit}
          dataSource={dataSource}
        />
      </div>
    </MainRegion>
  );
};
