import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { CircleAvatar } from '../CircleAvatar';

const AVATAR_DIAMETER = 24;

interface ProfileItemProps {
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  avatarUrl?: string | null;
  avatarBgColor?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 1, 3, 1),
    height: 40,
    borderRadius: 6,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    paddingLeft: theme.spacing(1.5),
    fontSize: 14,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
  },
}));

const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    })
    .join(' ');
};

export const ProfileItem: React.FC<ProfileItemProps> = (props) => {
  const classes = useStyles();
  const { firstName, lastName, title, avatarUrl } = props;

  const formatFullName = (
    firstName?: string | null,
    lastName?: string | null,
    title?: string | null
  ) => {
    return (
      titleCase(firstName + ' ' + lastName) +
      (title ? ', ' + title.toUpperCase() : '')
    );
  };

  return (
    <div className={classes.profileContainer}>
      <CircleAvatar
        avatarDiameter={AVATAR_DIAMETER}
        avatarUrl={avatarUrl}
        firstName={firstName}
        lastName={lastName}
        avatarBgColor={props.avatarBgColor ? props.avatarBgColor : ''}
      />
      <Typography className={classes.name}>
        {formatFullName(firstName, lastName, title)}
      </Typography>
    </div>
  );
};
