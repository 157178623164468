import React from 'react';
import {
  useGetPatientSiteFollowUpFromUserInRecentNotesLazyQuery,
  useGetPatientSiteFollowUpUsersLazyQuery,
} from '../components/CareGapsDrawer/index.generated';
import { SiteFollowUpUser } from '../../../../../../app.types.generated';
import { InitStateType } from '../components/CareGapDrawerHeader/components/SiteFollowUpForm/Container';

export const useSiteFollowUpDialog = (dialogId: string, mpatientId: string) => {
  if (!dialogId) {
    throw new Error('dialogId is required');
  }

  const [
    siteFollowUpUsers,
    siteFollowUpUsersResult,
  ] = useGetPatientSiteFollowUpUsersLazyQuery();
  const [
    completedSiteFollowUpUsers,
    completedSiteFollowUpUsersResult,
  ] = useGetPatientSiteFollowUpFromUserInRecentNotesLazyQuery();
  const [initState, setInitState] = React.useState<InitStateType>(
    {} as InitStateType
  );

  React.useEffect(() => {
    if (dialogId === 'Site Follow Up') {
      siteFollowUpUsers({
        variables: { input: { mpatientId } },
      });
    }
    if (dialogId === 'Completed Site Follow Up') {
      completedSiteFollowUpUsers({
        variables: { input: { mpatientId } },
      });
    }
  }, [dialogId, mpatientId]);

  React.useEffect(() => {
    if (!siteFollowUpUsersResult.called || siteFollowUpUsersResult.loading) {
      setInitState({
        loading: true,
        defaultDesignee: undefined,
        designeeOptions: [],
      });
    } else if (siteFollowUpUsersResult.data) {
      const defaultDesignees = siteFollowUpUsersResult?.data?.getPatientSiteFollowUpUsers?.users?.filter(
        (user) => user.isDefault
      ) as SiteFollowUpUser[];
      const designeeOptions =
        (siteFollowUpUsersResult.data.getPatientSiteFollowUpUsers
          ?.users as SiteFollowUpUser[]) || [];
      setInitState({
        loading: false,
        defaultDesignee:
          defaultDesignees.length > 0 ? defaultDesignees[0] : undefined,
        designeeOptions: designeeOptions,
      });
    }
  }, [siteFollowUpUsersResult]);

  React.useEffect(() => {
    if (
      !completedSiteFollowUpUsersResult.called ||
      completedSiteFollowUpUsersResult.loading
    ) {
      setInitState({
        loading: true,
        defaultDesignee: undefined,
        designeeOptions: [],
      });
    } else if (completedSiteFollowUpUsersResult.data) {
      const defaultDesignee = completedSiteFollowUpUsersResult.data
        .getPatientSiteFollowUpFromUserInRecentNotes?.user as SiteFollowUpUser;
      setInitState({
        loading: false,
        defaultDesignee: defaultDesignee,
        designeeOptions: [],
      });
    }
  }, [completedSiteFollowUpUsersResult]);

  return { initState };
};
