export enum UserDefinedRole {
  MSO_ADMIN = 'MSO Admin',
  APPLICATION_ADMIN = 'Application Admin',
  ADVANCE_USER = 'Advance User',
  USER = 'User',
  ADMIN = 'Admin',
  CARE_MANAGER = 'Care Manager',
  COORDINATOR = 'Coordinator',
  HUDSON_CLINICIAN = 'Clinician',
}
