import React, { useState } from 'react';
import { Step1PanelDetails } from './components/Step1';
import { Step2PatientFilters } from './components/Step2';
import { Step4CreateSuccess } from './components/Step4';
import { CreatePanelInput, PatientGender } from '../../../app.types.generated';

interface Props {
  onCreate: (input: CreatePanelInput) => any;
  panelId: string;
  taskId: string;
  options: any;
}

let defaultValues = {
  lob: [],
  clinicId: [],
  division: [],
  disease: [],
  openGap: [],
  careTeamUsers: [],
  description: '',
  name: '',
  patientRequirements: {
    hasAnyIcdCode: [],
    hasAnyCptCode: [],
  },
  pcp: [],
  age: [],
  gender: [],
  payerIds: [],
  lastDateOfServiceFrom: null,
  lastDateOfServiceTo: null,
  patientCount: 0,
};

export type FormState = {
  lob: string[];
  clinicId: any[];
  division: any[];
  disease: any[];
  openGap: any[];

  careTeamUsers: any[];
  description: string;
  // insurancePlans: any[];
  name: string;
  patientRequirements: {
    hasAnyIcdCode: string[];
    hasAnyCptCode: string[];
  };
  pcp: any[];
  age: string[];
  gender: PatientGender[];
  payerIds: string[];
  lastDateOfServiceFrom: Date | null;
  lastDateOfServiceTo: Date | null;
  patientCount: number;
};

export const MasterForm = (props: Props) => {
  const [formState, setFormState] = useState<FormState>(defaultValues);
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const updateStep1Form = (name: string, description: string) => {
    setFormState({ ...formState, name: name, description: description });
    nextStep();
  };

  const updateStep2Form = (
    lob: any[],
    clinicId: any[],
    division: any[],
    disease: any[],
    openGap: any[],
    patientRequirements: {
      hasAnyIcdCode: string[];
      hasAnyCptCode: string[];
    },
    patientCount: number,
    pcp: any[],
    age: string[],
    gender: PatientGender[],
    payerIds: any[],
    lastDateOfServiceFrom: Date | null,
    lastDateOfServiceTo: Date | null
  ) => {
    setFormState({
      ...formState,
      lob: lob,
      payerIds: payerIds,
      clinicId: clinicId,
      division: division,
      disease: disease,
      openGap: openGap,
      patientRequirements: patientRequirements,
      patientCount: patientCount,
      pcp: pcp,
      age: age,
      gender: gender,
      lastDateOfServiceFrom: lastDateOfServiceFrom,
      lastDateOfServiceTo: lastDateOfServiceTo,
    });
  };

  const onSubmitForm = () => {
    nextStep();
  };

  switch (step) {
    case 1:
      return <Step1PanelDetails onNext={updateStep1Form} step={step} />;
    case 2:
      return (
        <Step2PatientFilters
          onNext={updateStep2Form}
          onBack={prevStep}
          step={step}
          options={props.options}
          formState={formState}
          onCreate={props.onCreate}
          onSubmitForm={onSubmitForm}
        />
      );
    case 3:
      return (
        <Step4CreateSuccess
          panelId={props.panelId}
          taskId={props.taskId}
          step={step}
        />
      );
    default:
      return <div />;
  }
};
