export function getAge(dob: string): number {
  if (!dob) {
    return 0;
  }

  let dobDateObj = new Date(dob);
  let todayDateObj = new Date();

  let age = todayDateObj.getFullYear() - dobDateObj.getFullYear();
  let monthDiff = todayDateObj.getMonth() - dobDateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && todayDateObj.getDate() < dobDateObj.getDate())
  ) {
    return age - 1;
  } else {
    return age;
  }
}
