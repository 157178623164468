import React from 'react';
import {
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@material-ui/core';
import { NotRegisterIcon } from '../../../../../../../assets/Icons/FileUpload/NotRegisterIcon';
import { theme } from '../../../../../../../themes/theme';
import { ChangeEvent } from 'react';
import { useCareGapGlobalContext } from '../../context';
import { ProfileItem } from '../../../../../../../components/ProfileItem';
import { usePanelPatientsTableViewStyles } from '../../../../../../Panels/ViewPanels/PanelPatients/components/AssignedPatientsTable/Styles';
import { EmptyPlaceHolder } from '../../../../../../../components/EmptyPlaceHolder';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';
import { CoordinationStatusChip } from '../../../../../../../components/CoordinationStatusChip';
import { SubscriberIDCell } from './components/SubscriberIDCell';
import { usePatientPanelContext } from '../../../../../../Panels/LayoutBuilder/PatientPanelTabContext';
import { ExportMenu } from '../../../../../../Panels/ViewPanels/components/ExportFiles';
import { EligibilityBadge } from '../../../../../../../components/EligibilityBadge';
import { useFeature } from '../../../../../../../components/FeatureFlag';
import { PatientFilterComponentIDs } from '../../../../../../../enum/store';

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  defaultDirection?: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'eligibilityStatuses',
    numeric: false,
    disablePadding: false,
    label: 'Eligibility',
  },
  { id: 'dateOfBirth', numeric: false, disablePadding: false, label: 'DOB' },
  { id: 'gender', numeric: false, disablePadding: false, label: 'Gender' },
  {
    id: 'subscriberIDs',
    numeric: false,
    disablePadding: false,
    label: 'Subscriber ID',
  },
  {
    id: 'lastDos',
    numeric: false,
    disablePadding: false,
    label: 'Last Service Date',
  },
  {
    id: 'coveringPCPFullName',
    numeric: false,
    disablePadding: false,
    label: 'Covering Provider',
  },
  {
    id: 'caregapCount',
    numeric: false,
    disablePadding: false,
    label: 'Open Care Gaps',
    defaultDirection: true,
  },
  {
    id: 'careCoordinationStatus',
    numeric: false,
    disablePadding: false,
    label: 'Coordination Status',
  },
  {
    id: 'mostRecentDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Most Recent Due Date',
  },
];

const nonsortableHeads = ['subscriberIDs', 'eligibilityStatuses'];

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof usePanelPatientsTableViewStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: any,
    defaultDirection?: boolean
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (headCell: HeadCell) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, headCell.id, headCell.defaultDirection);
  };

  const getDirection = (headCell: HeadCell) => {
    if (orderBy === headCell.id) {
      return order;
    } else {
      return headCell.defaultDirection ? 'desc' : 'asc';
    }
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={getDirection(headCell)}
          onClick={createSortHandler(headCell)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };
  const hasFeature = useFeature('eligibilityStatusBadgeColumnInPatientTable');
  const useFeatureCoveringProvider = useFeature('coordination');

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === 'eligibilityStatuses') {
            if (!hasFeature) return null;
          } else if (headCell.id == 'coveringPCPFullName') {
            if (!useFeatureCoveringProvider) return null;
          }

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {pickSortingHeaders(headCell)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableRowProps {
  row: any;
  labelId: string;
  selected: string[];
  isItemSelected: boolean;
  setSelected: (input: string[]) => void;
}

const EnhancedTableRow = (props: EnhancedTableRowProps) => {
  const { drawerHandler, setValue } = useCareGapGlobalContext();

  const { row, labelId, isItemSelected, selected, setSelected } = props;

  const classes = usePanelPatientsTableViewStyles();
  const avatarPalette = theme.palette.avatars;
  const colors = Object.values(avatarPalette);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getOpenCareGapChipEle = (row: any) => {
    // measureCount === 0 => No Care Gaps , measureCount > 0 && caregapCount === 0 => All Gaps Closed
    let isNoCareGaps = row.measureCount === 0;
    let isAllGapsClosed = row.measureCount > 0 && row.caregapCount === 0;

    let labelText =
      (isNoCareGaps && 'No Care Gaps') ||
      (isAllGapsClosed && 'All Gaps Closed') ||
      `${row.caregapCount} Open Gaps`;

    let iconEle =
      isNoCareGaps || isAllGapsClosed ? undefined : (
        <NotRegisterIcon className={classes.warningIcon} />
      );
    let className =
      isNoCareGaps || isAllGapsClosed ? classes.closedGap : classes.openGap;

    return (
      <Chip
        icon={iconEle}
        label={labelText}
        className={className}
        onClick={(event) => {
          setValue(2);
          drawerHandler(event, row);
        }}
      />
    );
  };
  const hasFeature = useFeature('eligibilityStatusBadgeColumnInPatientTable');
  const useFeatureCoveringProvider = useFeature('coordination');
  return (
    <TableRow
      hover
      onClick={(event) => {
        setValue(0);
        handleClick(event, row.id);
        drawerHandler(event, row);
      }}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.patientId}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id &&
        row.id.toString().length > 10 &&
        row.id.toString().startsWith('9000') ? (
          <span
            style={{
              color: '#008479',
              backgroundColor: '#E8F6F2',
              fontSize: 10,
              padding: '2px 5px',
              marginRight: 5,
              fontWeight: 600,
            }}
          >
            NEW
          </span>
        ) : null}
        {row.lastName}, {row.firstName}
      </TableCell>
      {hasFeature && (
        <TableCell align="left">
          {row.eligibilityInfo && row.eligibilityInfo?.activeStatus ? (
            <EligibilityBadge patientInfo={row} pageToDisplay="PatientTable" />
          ) : (
            <EmptyPlaceHolder text={'No Data'} />
          )}
        </TableCell>
      )}
      <TableCell align="left">
        {row.dateOfBirth ?? <EmptyPlaceHolder text={'No DOB'} />}
      </TableCell>
      <TableCell align="left">
        {row.gender || <EmptyPlaceHolder text={'No Gender'} />}
      </TableCell>
      <TableCell align="left">
        <SubscriberIDCell subscriberIDs={row.subscriberIDs} />
      </TableCell>

      <TableCell align="left">
        {row.lastDos ? row.lastDos : <EmptyPlaceHolder text={'No DOS'} />}
      </TableCell>
      {useFeatureCoveringProvider && (
        <TableCell align="left">
          {row.coveringPCPFirstName && row.coveringPCPLastName ? (
            <span className={classes.pcp}>
              <ProfileItem
                avatarBgColor={colors[0]}
                firstName={row.coveringPCPLastName + ','}
                lastName={row.coveringPCPFirstName}
              />
            </span>
          ) : (
            <EmptyPlaceHolder text={'No Coverby'} />
          )}
        </TableCell>
      )}
      <TableCell align="left">{getOpenCareGapChipEle(row)}</TableCell>
      <TableCell align="left">
        <CoordinationStatusChip patientInfo={row} location="PatientTable" />
      </TableCell>

      <TableCell align="left">
        {row.mostRecentDueDate ? (
          row.mostRecentDueDate
        ) : (
          <EmptyPlaceHolder text={'No Date'} />
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pcp: {
    borderBottom: '2px solid rgba(67, 67, 67, 0.3)',
  },
  smsSent: {
    background: theme.palette.secondary.light,
    color: theme.palette.text.hint,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  openGap: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '&:hover': { background: 'rgba(183, 55, 62, 0.12)' },
    '&:focus': {
      background: theme.palette.error.light,
    },
  },
  closedGap: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[500],
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1),
    fontWeight: 600,
    height: 24,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:hover': { background: theme.palette.grey[200] },
    '&:focus': {
      background: theme.palette.grey[100],
    },
  },
  warningIcon: {
    color: '#B7373E',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    border: 'none',

    '& .MuiTableHead-root': {
      boxShadow: 'none',
      background: theme.palette.grey[50],
    },

    '& > thead > tr > th': {
      paddingLeft: theme.spacing(3),
      padding: 0,
      height: 32,
      fontSize: 12,
      color: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: 'none',
    },

    '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
      padding: theme.spacing(0, 0, 0, 1.5),
    },

    '& > tbody > tr > th': {
      padding: theme.spacing(0.75, 2),
    },

    '& > tbody > tr > *': {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[600],
      padding: theme.spacing(1.875, 3),
    },

    '& > tbody > tr:last-child': {
      borderBottom: '1px solid #D8D8D8',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    width: 24,
    height: 24,
    padding: 0,
    marginLeft: 12,
  },
  selectAll: {
    '& > .checkedIcon:before': {
      backgroundColor: 'red',
    },
  },
  commercialLine: {
    background: theme.palette.info.light,
    color: theme.palette.info.main,

    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  otherLines: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,

    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  iconWraper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 44,
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
      order: -1,
      marginRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold,
    },
    '& p[id]': {
      marginRight: theme.spacing(5.75),
    },
  },
}));

interface CareGapPatientTableViewProps {
  patients: any;
  limit: number;
  skip: number;
  count: number;
  selected: any[];
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  setSelected: (input: any) => any;
  setActionsFooter?: React.Dispatch<React.SetStateAction<any[]>>;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  refetchDataFunc?: any;
}

export const CareGapPatientTableView = (
  props: CareGapPatientTableViewProps
) => {
  const {
    patients,
    limit,
    skip,
    count,
    selected,
    setSelected,
    setSkip,
    setLimit,
  } = props;
  const classes = usePanelPatientsTableViewStyles();
  const { tabLabel } = usePatientPanelContext();

  const [isSorting, setIsSorting] = React.useState(false);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setFilteredRows(patients);

    setIsSorting(false);
  }, [patients]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
    defaultDirection?: boolean
  ) => {
    setIsSorting(true);
    const isAsc = orderBy === property && order === 'asc';
    if (defaultDirection) {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n: any) => n.patientId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  const showExportRow = () => {
    // show in My Patients and filters applied
    const isMyPatientsTab = tabLabel === 'My Patients';
    const isFiltersApplied =
      !props.refetchDataFunc.params.hasOwnProperty('andName') &&
      !props.refetchDataFunc.params.hasOwnProperty('andDob');
    return isMyPatientsTab && isFiltersApplied;
  };

  return (
    <div className={classes.root}>
      {/* {showExportRow() && ( */}
      <Paper className={`${classes.control} ${classes.tableTitleContainer}`}>
        <Typography variant="h4" className={classes.tableTitle}>
          {tabLabel}
        </Typography>

        <div className={classes.tableTitleRowButtons}>
          <ExportMenu
            rows={filteredRows}
            panelId={''}
            title={'Export'}
            showMyOwn={tabLabel === 'My Patients' ? 'YES' : ''}
            componentId={
              tabLabel === 'My Patients'
                ? PatientFilterComponentIDs.MY_PATIENTS
                : PatientFilterComponentIDs.ALL_PATIENTS
            }
          />
        </div>
      </Paper>
      {/* )} */}
      <Paper>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          {/* patients list table */}
          <TableContainer classes={{ root: classes.tableContainer }}>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredRows.length}
              />
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * limit, page * limit + limit)
                    .map((row: any, index: number) => {
                      return (
                        <EnhancedTableRow
                          key={`enhanced-table-checkbox-${index}-${row.patientId}`}
                          row={row}
                          labelId={`enhanced-table-checkbox-${index}`}
                          isItemSelected={isSelected(row.patientId)}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { limit, skip, count } = props;

  const classes = usePanelPatientsTableViewStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
