import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Check } from '@material-ui/icons';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiSnackbar-anchorOriginTopCenter': {
      top: 40,
    },
  },
  successAlert: {
    '&.MuiAlert-root': {
      width: 338,
      height: 48,
      padding: theme.spacing(0, 2),
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
    },
    '& .MuiAlert-message': {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiAlert-action': {
      color: theme.palette.success.light,
      opacity: '60%',
    },
  },
}));

export const SuccessSnackbar = (props: Props) => {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  };

  return (
    <Snackbar
      className={classes.root}
      open={props.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
    >
      <MuiAlert
        className={classes.successAlert}
        onClose={handleClose}
        severity="success"
        variant="filled"
        elevation={3}
        icon={<Check />}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};
