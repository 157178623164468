import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { BreadCrumbHeader } from '../../../components/BreadCrumbHeader';
import { CareGapsList } from '../../DashBoard/components/CareGapsList';
import { GlobalContext } from '../../../components/GlobalContext';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../components/CheckIsAdmin';
import { MainRegion } from '../../../components/MainRegion';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const AllCareGapsView = () => {
  const classes = useStyles();
  const { loggedInUser } = React.useContext(GlobalContext);

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={
            CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser)
              ? 'All Care gaps'
              : 'My Care Gaps'
          }
          rootLink={`/dashboard`}
          rootName={'Dashboard'}
          isTitle={true}
          isButton={false}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            <CareGapsList />
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
