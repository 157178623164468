import { LoadingSpinner } from '../LoadingSpinner';
import { PowerBIGeneralError } from '../PowerBIError';
import { useGetPowerBiReportUrlQuery } from './index.generated';
import styles from './page.module.css';

const Index = () => {
  const resp = useGetPowerBiReportUrlQuery();

  if (!resp.called || resp.loading) {
    return <LoadingSpinner />;
  }

  if (
    resp.error ||
    !resp.data ||
    !resp.data.getPowerBIReportURL ||
    !resp.data.getPowerBIReportURL.isSuccess
  ) {
    return <PowerBIGeneralError />;
  }

  const url = resp.data.getPowerBIReportURL.reportURL;

  return (
    <section className={styles.container}>
      <div className={styles.chartContainer}>
        {url && <iframe src={url} className={styles.iframeContainer} />}
      </div>
    </section>
  );
};

export default Index;
