import React from 'react';
import {
  Button,
  makeStyles,
  Theme,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { PanelsHeader } from '../../../components/PanelsHeader';
import { Link } from 'react-router-dom';
import { StepIndicator } from '../StepIndicator';
import { useNavigate } from 'react-router-dom';
import { PanelsIcon } from '../../../../../assets/Icons/Dashboard/PanelsIcon';
import { useGetTaskStatusQuery } from '../../index.generated';
import { MainRegion } from '../../../../../components/MainRegion';
import { theme } from '../../../../../themes/theme';

const useStyles = makeStyles((theme: Theme) => ({
  panelIconWrapper: {
    width: 60,
    height: 60,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  checkIconWrapper: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  root: {},
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
  },
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',
    padding: theme.spacing(1.5),
  },
  // reduce classes by using props in theme (master form state)
  stepsContainer: {
    height: '100%',
  },
  compelteCreation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  checkIcon: {
    width: 64,
    height: 64,
    color: '#0C77D8',
    // marginBottom: theme.spacing(3.5),
  },
  successTitle: {
    height: 40,
    color: theme.palette.grey[700],
    fontSize: 32,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.25, // => 40px / fontSize
  },
  successHint: {
    height: 24,
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    lineHeight: 1.5, // => 24px / fontSize
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(7.5),
  },
  aLink: {
    textDecoration: 'none',
  },
  loadingContainer: {
    display: 'flex',
  },
  viewPanelBtn: {
    width: 'fit-content',
    height: 48,
    background: theme.palette.primary.main,

    color: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    lineHeight: 1.5,

    '&:hover': {
      background: '#1b8cf3',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  createNewBtn: {
    width: 'fit-content',
    height: 48,
    background: theme.palette.grey[200],
    marginRight: 16,

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    lineHeight: 1.5,

    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  paperContainer: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
}));

interface Props {
  onNext?: () => any;
  step: number;
  panelId: string;
  taskId: string;
}

export function Step4CreateSuccess(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: props.taskId,
      },
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (props.panelId) {
      navigate(`/panels/${props.panelId}/summary`);
    }
  };

  React.useEffect(() => {
    if (!queryTask.loading) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [queryTask]);

  setTimeout(() => {
    if (loading) {
      queryTask.refetch();
    }
  }, 1000);

  return (
    <MainRegion header={<PanelsHeader title="New Panel" />}>
      <div className={classes.root}>
        {/* <PanelsHeader title="New Panel" /> */}
        <Grid container spacing={3} className={classes.mainGrid}>
          {/* step indicator */}
          <Grid item xs={2} container className={classes.stepsContainer}>
            <StepIndicator step={props.step} />
          </Grid>

          {/* spacing */}
          <Grid item xs={1} />

          {/* complete box */}
          <Grid item xs={9}>
            <Paper className={classes.paperContainer}>
              <Grid container>
                <Grid item xs={12} className={classes.compelteCreation}>
                  <Grid item xs={12}>
                    {loading ? (
                      <div className={classes.panelIconWrapper}>
                        <PanelsIcon className={classes.checkIcon} />
                      </div>
                    ) : (
                      <div className={classes.checkIconWrapper}>
                        <CheckIcon
                          className={classes.checkIcon}
                          style={{
                            color: theme.palette.success.dark,
                            width: 24,
                            height: 24,
                          }}
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {loading ? (
                      <Typography variant="h6" className={classes.successTitle}>
                        We are creating the Panel
                      </Typography>
                    ) : (
                      <Typography variant="h6" className={classes.successTitle}>
                        Panel has been created
                      </Typography>
                    )}
                    {loading ? (
                      <Typography
                        variant="body1"
                        className={classes.successHint}
                      >
                        Your panel will be ready in few seconds.
                        <br />
                        You can assign coordinator after panel is created.
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        className={classes.successHint}
                      >
                        You can view Panel Summary
                        <br />
                        or start Assign Coordinator to this panel
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} className={classes.loadingContainer}>
                    {loading ? (
                      <div>
                        <Button className={classes.viewPanelBtn}>
                          <div
                            className="loader"
                            style={{
                              width: 18,
                              height: 18,
                              border: '2px solid #f3f3f3',
                              borderTop: '2px solid #3498db',
                            }}
                          ></div>
                          <span style={{ paddingLeft: 4, opacity: 0.5 }}>
                            Creating
                          </span>
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Link
                          to={`/panels/${props.panelId}/summary`}
                          className={classes.aLink}
                          onClick={() => handleClick}
                        >
                          <Button className={classes.createNewBtn}>
                            View Panel Summary
                          </Button>
                        </Link>
                        <Link
                          to={`/panels/${props.panelId}/unassigned`}
                          className={classes.aLink}
                          state={{
                            origin: 'CREATE_PANEL_ASSIGN_COORDINATOR',
                            // NPH-2287 open manage care coordinator popup
                            // from the create panel - assign coordinator button
                            openManageCoordinatorPopup: true,
                          }}
                        >
                          <Button className={classes.viewPanelBtn}>
                            Assign Coordinator
                          </Button>
                        </Link>
                      </div>
                    )}

                    {/*<Link*/}
                    {/*  to={`/panels/${props.panelId}/summary`}*/}
                    {/*  className={classes.aLink}*/}
                    {/*  onClick={() => handleClick}*/}
                    {/*>*/}
                    {/*  <Button className={classes.viewPanelBtn}>View Panel</Button>*/}
                    {/*</Link>*/}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
}
