export const EnglishDefaultMessage =
  'Click the link to schedule an appointment: <mark>#AppointmentLink </mark>&nbsp;';

export const EnglishDefaultPlaceholder = 'Content Preview';

export const ChineseSimplifiedDefaultMessage =
  '点击链接和您的医生预约看诊:  <mark>#AppointmentLink</mark>&nbsp;';

export const ChineseSimplifiedDefaultPlaceholder = '内容预览';

export const ChineseTraditionalDefaultMessage =
  '你可以點擊以下鏈接預約: <mark>#AppointmentLink</mark>&nbsp;';

export const ChineseTraditionalDefaultPlaceholder = '內容預覽';

export const TooltipSmsMessage =
  "SMS will be sent in the patient's preferred languages, currently we only support English, Chinese(Traditional), Chinese(Simplified)";

export const SmsTagConstant = [
  { name: 'Patient Name', value: '#PatientName' },
  { name: 'Provider Name', value: '#ProviderName' },
  { name: 'Practice Name', value: '#PracticeName' },
  { name: 'Appointment Link', value: '#AppointmentLink' },
];
