import * as Types from '../../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetCareLogsQueryVariables = Types.Exact<{
  input: Types.GetCoordinationLogsRequest;
}>;


export type GetCareLogsQuery = (
  { __typename: 'Query' }
  & { getCoordinationLogs?: Types.Maybe<Array<(
    { __typename: 'CoordinationLog' }
    & Pick<Types.CoordinationLog, 'logType' | 'logDate' | 'logTitle' | 'logContent' | 'careCoordinationStatusText' | 'previousCareCoordinationStatusText' | 'extraInfo'>
    & { logBy?: Types.Maybe<(
      { __typename: 'PCP' }
      & Pick<Types.Pcp, 'fullName' | 'firstName' | 'lastName'>
    )> }
  )>> }
);


export const GetCareLogsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCareLogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetCoordinationLogsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCoordinationLogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logType"}},{"kind":"Field","name":{"kind":"Name","value":"logDate"}},{"kind":"Field","name":{"kind":"Name","value":"logTitle"}},{"kind":"Field","name":{"kind":"Name","value":"logContent"}},{"kind":"Field","name":{"kind":"Name","value":"careCoordinationStatusText"}},{"kind":"Field","name":{"kind":"Name","value":"previousCareCoordinationStatusText"}},{"kind":"Field","name":{"kind":"Name","value":"logBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"extraInfo"}}]}}]}}]};

/**
 * __useGetCareLogsQuery__
 *
 * To run a query within a React component, call `useGetCareLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCareLogsQuery(baseOptions: Apollo.QueryHookOptions<GetCareLogsQuery, GetCareLogsQueryVariables>) {
        return Apollo.useQuery<GetCareLogsQuery, GetCareLogsQueryVariables>(GetCareLogsDocument, baseOptions);
      }
export function useGetCareLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareLogsQuery, GetCareLogsQueryVariables>) {
          return Apollo.useLazyQuery<GetCareLogsQuery, GetCareLogsQueryVariables>(GetCareLogsDocument, baseOptions);
        }
export type GetCareLogsQueryHookResult = ReturnType<typeof useGetCareLogsQuery>;
export type GetCareLogsLazyQueryHookResult = ReturnType<typeof useGetCareLogsLazyQuery>;
export type GetCareLogsQueryResult = Apollo.QueryResult<GetCareLogsQuery, GetCareLogsQueryVariables>;
export function refetchGetCareLogsQuery(variables?: GetCareLogsQueryVariables) {
      return { query: GetCareLogsDocument, variables: variables }
    }