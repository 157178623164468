import React from 'react';
import { EligibleIcon } from '../assets/Icons/Eligibility/EligibleIcon';
import { RejectedIcon_ as RejectedIcon } from '../assets/Icons/Eligibility/RejectedIcon_';
import { EligibleExternalIcon_ as EligibleExternalIcon } from '../assets/Icons/Eligibility/EligibilityExternalIcon_';
import { TerminationIcon } from '../assets/Icons/Eligibility/TerminationIcon';
import { theme } from '../themes/theme';
import SvgIcon from '@material-ui/core/SvgIcon';
import activeIcon from './Active.svg'
import { ActiveExternalIcon20, ActiveExternalIcon24, ActiveExternalIcon32, ActiveInternalIcon20, ActiveInternalIcon24, ActiveInternalIcon32, InactiveIcon20, InactiveIcon24, InactiveIcon32, UnavailableIcon20, UnavailableIcon24, UnavailableIcon32, UnverifiedIcon20, UnverifiedIcon24, UnverifiedIcon32 } from '../assets/Icons/Eligibility/EligibilityCategoryIcons';
import styled from 'styled-components';

interface Props {
  className?: string;
  style?: object;
}



type EligibilityBadgeType = {
  lastCheck: string;
  status: string;
  rejectedReason?: string,
  icon: JSX.Element;
};

enum EligibilityBadgeStatusType {
  internalActive = 'ActiveInternal',
  externalActive = 'ActiveExternal',
  rejected = 'Rejected',
  inactive = 'Inactive',
  active = 'Active',
  unavailable= 'Unavailable'
}

export const useEligibilityBadge = (source: string, patientInfo: any) => {
  const [eligibilityBadge, setEligibilityBadge] = React.useState<
    EligibilityBadgeType | undefined
  >(undefined);

  const getIconSizeBySource = () => {
    switch (source) {
      case 'PatientFaceSheet':
        return 24;
      case 'PatientTable':
        return 20;
      default:
        return 24;
    }
  };

  const getInternalActionIconBySize = (size: number, style?: any) => {
      if (size === 32) {
        return <ActiveInternalIcon32 style={style}/>
      } else if (size === 20) {
        return <ActiveInternalIcon20  style={style} />
      } 
      return <ActiveInternalIcon24  style={style} />
  }

  const getExternalActionIconBySize = (size: number, style?: any) => {
    if (size === 32) {
      return <ActiveExternalIcon32 style={style}/>
    } else if (size === 20) {
      return <ActiveExternalIcon20  style={style} />
    } 
    return <ActiveExternalIcon24  style={style} />
  }

  const getUnverifiedIconBySize = (size: number, style?: any) => {
    if (size === 32) {
      return <UnverifiedIcon32 style={style}/>
    } else if (size === 20) {
      return <UnverifiedIcon20  style={style} />
    } 
    return <UnverifiedIcon24  style={style} />
  }

  const getInactiveIconBySize = (size: number, style?: any) => {
    if (size === 32) {
      return <InactiveIcon32 style={style}/>
    } else if (size === 20) {
      return <InactiveIcon20  style={style} />
    } 
    return <InactiveIcon24  style={style} />
  }

  const getUnavailableIconBySize = (size: number, style?: any) => {
    if (size === 32) {
      return <UnavailableIcon32 style={style}/>
    } else if (size === 20) {
      return <UnavailableIcon20  style={style} />
    } 
    return <UnavailableIcon24  style={style} />
  }


  const getEligibilityBadge = (
    status: string,
    lastCheck: string,
    rejectedReason: string,
  ): EligibilityBadgeType | undefined => {
    const opacityVal = lastCheck?.toLowerCase() === 'today' ? 1 : 0.5;
    switch (status) {
      case EligibilityBadgeStatusType.internalActive:
        return {
          lastCheck: `Last Check: ${lastCheck}`,
          status: 'Active (Internal) Eligibility',
          icon: getInternalActionIconBySize(getIconSizeBySource(), {opacity: opacityVal }),
        };
      case EligibilityBadgeStatusType.externalActive:
        return {
          lastCheck: `Last Check: ${lastCheck}`,
          status: 'Active (External) Eligibility',
          icon: getExternalActionIconBySize(getIconSizeBySource(), {opacity: opacityVal }),
        };
      case EligibilityBadgeStatusType.rejected:
        return {
          lastCheck: `Last Check: ${lastCheck}`,
          status: 'Unverified Eligibility',
          rejectedReason: rejectedReason,
          icon: getUnverifiedIconBySize(getIconSizeBySource(), {opacity: opacityVal }),
        };
      case EligibilityBadgeStatusType.inactive:
        return {
          lastCheck: `Last Check: ${lastCheck}`,
          status: 'Inactive Eligibility',
          icon: getInactiveIconBySize(getIconSizeBySource(), {opacity: opacityVal }),
        };
      case EligibilityBadgeStatusType.unavailable:
        return {
          lastCheck: `Last Check: ${lastCheck}`,
          status: 'Unavailable Eligibility',
          icon: getUnavailableIconBySize(getIconSizeBySource(), {opacity: opacityVal }),
        };
      default:
        return undefined;
    }
  };

  React.useEffect(() => {
    if (patientInfo && patientInfo.eligibilityInfo) {
      let eligibilityType = '';
      let rejectedReason = ''
      const activeStatus = patientInfo.eligibilityInfo.activeStatus;
      const pcpStatus = patientInfo.eligibilityInfo.pcpStatus;

      if (
        activeStatus === EligibilityBadgeStatusType.inactive ||
        activeStatus === EligibilityBadgeStatusType.rejected || 
        activeStatus === EligibilityBadgeStatusType.unavailable
      ) {
        eligibilityType = activeStatus;
        if (activeStatus === EligibilityBadgeStatusType.rejected) {
          rejectedReason = patientInfo.eligibilityInfo.statusReason
        }
      } else if (activeStatus === EligibilityBadgeStatusType.active) {
        eligibilityType = activeStatus + pcpStatus;
      }

      const lastCheck = patientInfo.eligibilityInfo.updateTimeExt!;

      setEligibilityBadge(getEligibilityBadge(eligibilityType, lastCheck, rejectedReason));
    }
  }, [patientInfo, patientInfo.eligibilityInfo]);

  return { eligibilityBadge };
};
