import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useProviderFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    container: {
      padding: theme.spacing(1.5, 5.375, 2.5, 3),
    },
    performanceSelect: {
      marginBottom: theme.spacing(2.5),
    },
    applyBtnAndResult: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    applyFilterBtnContainer: {},
    applyFilterBtn: {
      color: '#FFF',
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: 102,
      height: 32,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    badgeResultContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 21,
    },
    badge: {
      // '& .MuiBadge-badge': {
      padding: theme.spacing(0.25, 0.875),
      borderRadius: 6,
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      backgroundColor: theme.palette.primary.light,
      // },
    },
    resultText: {
      color: '#000000',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);
