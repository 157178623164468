import React from 'react';
import {useGetRolesByAccountIdQuery} from '../EditUsers/index.generated';
import {useCreateNewUserMutation} from './index.generated';
import {ClinicIdAndDivisionIdPairs, ClinicIdAndDivisionIdPairsInput, NewUsersView} from './View';
import {LoadingSpinner} from '../../../../components/LoadingSpinner';
import {GlobalContext} from '../../../../components/GlobalContext';

export type NewUserInput = {
    firstName: string;
    lastName: string;
    email: string;
    roleId: string;
    roleName: string;
    phone: string;
    clinicIdAndDivisionIdPairs?: ClinicIdAndDivisionIdPairsInput[];
};


export const NewUsersContainer = () => {
    const {setToastMessage, setIsOpenToast} = React.useContext(GlobalContext);
    const [existingEmailErr, setExistingEmailErr] = React.useState<
        boolean | undefined
    >(undefined);

    const [createUserMutation] = useCreateNewUserMutation();

    const roles = useGetRolesByAccountIdQuery({variables: {}});
    if (!roles.called || roles.loading) {
        return <LoadingSpinner style={{height: '100%', width: '100%'}}/>;
    }
    const mapUserIntoUserClinicAndDivisionRequestPairInput = ({
                                                                  firstName,
                                                                  lastName,
                                                                  email,
                                                                  roleId,
                                                                  roleName,
                                                                  phone,
                                                                  clinicIdAndDivisionIdPairs
                                                              }: NewUserInput
    ) => {
        const temp: any[] = [];
        clinicIdAndDivisionIdPairs?.map((clinicIdAndDivisionIdPair: any) => {
                if (clinicIdAndDivisionIdPair.clinicId) {
                    if (clinicIdAndDivisionIdPair.divisions.length > 0) {
                        clinicIdAndDivisionIdPair.divisions.forEach((division: any) => {
                            temp.push({
                                clinicID: clinicIdAndDivisionIdPair.clinicId,
                                address: "",
                                divisionID: division.value,
                                divisionName: division.name,
                                userID: '',
                                email: email,
                                firstName: firstName,
                                isSiteDesignee: false,
                                lastName: lastName,
                                roleID: roleId,
                            })
                        })
                    } else {
                        temp.push({
                            clinicID: clinicIdAndDivisionIdPair.clinicId,
                            address: '',
                            divisionID: '',
                            divisionName: '',
                            email: email,
                            firstName: firstName,
                            isSiteDesignee: false,
                            lastName: lastName,
                            roleID: roleId,
                            userID: '',
                        })
                    }
                }
            }
        )
        return temp;
    }

    async function createNewUser({
                                     firstName,
                                     lastName,
                                     email,
                                     roleId,
                                     roleName,
                                     phone,
                                     clinicIdAndDivisionIdPairs
                                 }: NewUserInput) {
        console.log('clinicIdAndDivisionIdPairs', clinicIdAndDivisionIdPairs);
        const {data, errors} = await createUserMutation({
            variables: {
                input: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    role: roleId,
                    phone: phone,
                    clinicAndDivisionPairs: mapUserIntoUserClinicAndDivisionRequestPairInput({
                        firstName,
                        lastName,
                        email,
                        roleId,
                        roleName,
                        phone,
                        clinicIdAndDivisionIdPairs
                    }),
                },
            }});

        if (data) {
            if (
                data.createUser.errorType &&
                data.createUser.errorMessage ===
                'This email is already registered, please try another email address.'
            ) {
                setExistingEmailErr(true);
            } else {
                setExistingEmailErr(false);
                setToastMessage({
                    snackbarMsg: `New ${roleName} has been created!`,
                    isOpen: true,
                    severity: 'success',
                });
                setIsOpenToast(true);
            }
        }

        if (errors) {
            if (
                errors[0].message ===
                'This email is already registered, please try another email address.'
            ) {
                setExistingEmailErr(true);
            } else {
                setExistingEmailErr(false);
                setToastMessage({
                    snackbarMsg: `Failed to create user, please try again.`,
                    severity: 'error',
                    isOpen: true,
                });
                setIsOpenToast(true);
            }
        }
    }

        return (
            <NewUsersView
                createNewUser={createNewUser}
                roleOptions={roles.data?.getRolesByAccountId!}
                existingEmailErr={existingEmailErr}
                setExistingEmailErr={setExistingEmailErr}
            />
        );
    };
