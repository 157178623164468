import { makeStyles } from '@material-ui/core/styles';
import { Typography, Chip } from '@material-ui/core';
import { FlashingDot } from '../FlashingDot';
import { formatNumberPipe } from '../../sharePipe/formatNumberPipe';

interface DynamicBadgeProps {
  loading: boolean;
  prefixText: string | undefined;
  displayedCount: number;
  suffixText: string | undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '24px',
    color: '#000000',
  },
  chip: {
    height: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    '& .MuiChip-label': {
      padding: theme.spacing(0.25, 0.875),
      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  },
}));

export const DynamicBadge = (props: DynamicBadgeProps) => {
  const { prefixText, suffixText, loading, displayedCount } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {prefixText && (
        <Typography className={classes.text} component="p">
          {prefixText}
        </Typography>
      )}

      {loading ? (
        <FlashingDot />
      ) : (
        <Chip
          className={classes.chip}
          label={formatNumberPipe(displayedCount, '-')}
        />
      )}

      {suffixText && (
        <Typography className={classes.text} component="p">
          {suffixText}
        </Typography>
      )}
    </div>
  );
};
