import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Paper,
  Theme,
  OutlinedInput,
  IconButton,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { BreadCrumbHeader } from '../../../../components/BreadCrumbHeader';
import { NewUserInput } from './Container';
import { UserRole } from '../../../../enum/Users';
import { RoleSelect } from '../components/RoleSelect';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { NewUserForm } from '../EditUsers/View';
import { handlePhoneNumber } from '../../../../sharePipe/normalizePhoneNumberInput';
import { MainRegion } from '../../../../components/MainRegion';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../../components/DialogBox';
import { useNewRoleOptions } from '../../../../hooks/useNewRoleOptions';
import { useGetClinicsAndDivisionQuery } from './index.generated';
import { CustomSingleSelect } from '../../../../components/CustomSingleSelect';
import { AddCircleIcon } from '../../../../assets/Icons/Users/AddCircleIcon';
import { DivisionSelect } from '../../../../components/DivisionSelect';
import { CloseIcon } from '../../../../assets/Icons/ExportPatients';
import { useFeature } from '../../../../components/FeatureFlag';

type Props = {
  setCreateUserForm?: (input: NewUserInput) => void;
  createNewUser: (input: NewUserInput) => Promise<void>;
  roleOptions: any[];
  existingEmailErr: boolean | undefined;
  setExistingEmailErr: (input: boolean | undefined) => void;
};

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const ITEM_NUM = 5;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
  },
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',
  },
  numberCircle: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(0),
  },
  cardFooter: {
    height: 72,
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  errorLabel: {
    fontSize: 12,
    color: '#B7373E',
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    height: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
      fontSize: 16,
      paddingLeft: theme.spacing(1),
    },
  },

  // MULTI-SELECT STYLES
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',
    width: '100%',
  },
  formControl: {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 48,
      borderRadius: 6,
      boxShadow:
        '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    marginBottom: theme.spacing(1),
  },
  inputHelper: {
    height: 20,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.67,
    marginTop: theme.spacing(0.5),
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.grey['500'],
  },
  // insurance select styles
  insuranceSelect: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
  },
  insurancePlaceholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  insuranceMenuItem: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  insuranceMenuItemText: {
    height: 24,
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.71,
  },
  insuranceList: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

    '& .MuiListItem-root': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    '& li .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  insuranceListItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  insuranceListItemText: {
    height: 24,
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  insuranceListitemHelper: {
    height: 16,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
  },
  subLabelError: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0',
    margin: 'auto 0',
  },
}));

export interface Division {
  name: string;
  value: string;
}

export interface ClinicIdAndDivisionIdPairsInput {
  clinicId: string;
  divisions: Division[];
}

export interface ClinicIdAndDivisionIdPairs {
  clinicId: string | undefined;
  divisions: string[];
}

export const NewUsersView = (props: Props) => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog
  );

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const navigate = useNavigate();
  const [role, setRole] = useState<UserRole>();
  const clinicsAndDivisionQuery = useGetClinicsAndDivisionQuery({
    variables: {},
  });
  const [clinicIdOptions, setClinicIdOptions] = useState<any[]>([]);
  const [divisionIdOptions, setDivisionIdOptions] = useState<any[]>([]);
  const [
    selectedClinicIdAndDivisionIdPairs,
    setSelectedClinicIdAndDivisionIdPairs,
  ] = useState<ClinicIdAndDivisionIdPairs[]>([
    {
      clinicId: undefined,
      divisions: [],
    },
  ]);
  const [selectedClinicId, setSelectedClinicId] = useState<string>('');
  const [numberOfPairs, setNumberOfPairs] = useState<number>(1);
  const [disabledDivision, setDisabledDivision] = useState<boolean[]>([]);
  const [
    disableDivisionSelection,
    setDisableDivisionSelection,
  ] = useState<string>('');
  React.useEffect(() => {
    const tmp = [];
    if (selectedClinicIdAndDivisionIdPairs.length > 0) {
      // let string = '';
      for (let i = 0; i < selectedClinicIdAndDivisionIdPairs.length; i++) {
        if (selectedClinicIdAndDivisionIdPairs[i].clinicId != undefined) {
          // string = string + i + ',';
          tmp.push(false);
        } else {
          tmp.push(true);
        }
      }
      setDisabledDivision(tmp);
      // setDisableDivisionSelection(string);
    }
  }, [JSON.stringify(selectedClinicIdAndDivisionIdPairs)]);
  const handleClinicIdOrDivisionChange = (type: string, newValue: any) => {
    const idxString = type.split(',')[0];
    const idx = parseInt(idxString, 10);
    const idType = type.split(',')[1];
    let temp: any = [];
    if (idType == 'ClinicId') {
      temp = selectedClinicIdAndDivisionIdPairs;
      temp[idx].clinicId = newValue;
      temp[idx].divisions = [];
      setSelectedClinicIdAndDivisionIdPairs(temp);
      let string = '';
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].clinicId === '9000') {
          string = string + i + ',';
        }
      }
      setDisableDivisionSelection(string);
      let string2 = '';
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].clinicId) {
          string2 = string2 + temp[i].clinicId + ',';
        }
      }
      setSelectedClinicId(string2);
    } else if (idType == 'DivisionId') {
      temp = selectedClinicIdAndDivisionIdPairs;
      temp[idx].divisions = newValue;
      setSelectedClinicIdAndDivisionIdPairs(temp);
    }
  };
  React.useEffect(() => {
    if (props.existingEmailErr === false) {
      // if it's not the existing email error, go to the user list page
      navigate('/users/all');
    }
  }, [props.existingEmailErr]);
  React.useEffect(() => {
    if (clinicsAndDivisionQuery?.data?.getCareGapFilterOptions) {
      setClinicIdOptions(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions?.Clinics ?? []
      );
      setDivisionIdOptions(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions?.Divisions ?? []
      );
    }
  }, [clinicsAndDivisionQuery?.data?.getCareGapFilterOptions]);

  const {
    control,
    register,
    errors,
    formState,
    handleSubmit,
  } = useForm<NewUserForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      roleId: '',
      phone: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const checkSelectedClinicIdAndDivisionIdPairs = () => {
    let result: ClinicIdAndDivisionIdPairsInput[] = [];
    if (selectedClinicIdAndDivisionIdPairs.length > 0) {
      for (let i = 0; i < selectedClinicIdAndDivisionIdPairs.length; i++) {
        if (selectedClinicIdAndDivisionIdPairs[i].clinicId) {
          const temp: ClinicIdAndDivisionIdPairsInput = {
            clinicId: selectedClinicIdAndDivisionIdPairs[i].clinicId!,
            divisions:
              selectedClinicIdAndDivisionIdPairs[i].divisions?.length > 0
                ? selectedClinicIdAndDivisionIdPairs[i].divisions.includes(
                    'All'
                  )
                  ? divisionIdOptions
                  : selectedClinicIdAndDivisionIdPairs[i].divisions.map(
                      (division) => {
                        return divisionIdOptions.find(
                          (divisionId) => divisionId.value === division
                        );
                      }
                    )
                : [],
          };
          result.push(temp);
        }
      }
    }
    return result;
  };
  const onSubmit = async (input: NewUserForm) => {
    // call api
    setShowDialog(false);
    await props.createNewUser({
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
      roleId: role!.roleId,
      roleName: role!.roleName,
      phone: phoneNumber.replaceAll('-', ''),
      clinicIdAndDivisionIdPairs: checkSelectedClinicIdAndDivisionIdPairs(),
    });
  };

  const [phoneNumber, setPhoneNumber] = React.useState('');

  React.useEffect(() => {
    if ((formState.isDirty || role || phoneNumber.length > 0) && !showDialog) {
      setShowDialog(true);
    }
  }, [formState.isDirty, role, phoneNumber]);

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(handlePhoneNumber(e.target.value));
  };

  const newRoleOptions = useNewRoleOptions(props.roleOptions);
  const hasFeature = useFeature('division');

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={'New User'}
          rootLink={`/users/all`}
          rootName={'User & Permission'}
          isTitle={true}
          isButton={true}
          renderButton={
            <Button
              onClick={() => navigate('/users/all')}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          }
        />
      }
    >
      <LeaveEditDialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className={classes.root}>
        <Grid container spacing={8} className={classes.mainGrid}>
          {/* spacing */}
          <Grid item xs={2} />
          {/* Patient details form */}
          <Grid item xs={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper className={classes.card} elevation={1}>
                <Grid container>
                  <Grid item xs={12} className={classes.cardHeader}>
                    <Typography className={classes.cardTitle}>
                      New User
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={3}
                    className={classes.cardContent}
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.inputLabel}>
                        First name
                      </Typography>
                      <Controller
                        name="firstName"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value, name }) => (
                          <>
                            <OutlinedInput
                              fullWidth
                              // autoFocus
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: 'You must enter your name',
                                },
                                minLength: {
                                  value: 2,
                                  message: 'Name must be at least 2 characters',
                                },
                                maxLength: {
                                  value: 26,
                                  message:
                                    'Name must be less than 26 characters',
                                },
                              })}
                              name={name}
                              onChange={onChange}
                              error={
                                !!errors.firstName?.message ||
                                !!errors.firstName?.type
                              }
                              className={classes.nameInput}
                              placeholder="First Name"
                              classes={outlinedInputClasses}
                            />
                            {errors.firstName &&
                              errors.firstName.type === 'required' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                            {errors.firstName &&
                              errors.firstName.type === 'minLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                            {errors.firstName &&
                              errors.firstName.type === 'maxLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.inputLabel}>
                        Last name
                      </Typography>
                      <Controller
                        name="lastName"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value, name }) => (
                          <>
                            <OutlinedInput
                              fullWidth
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: 'You must enter your name',
                                },
                                minLength: {
                                  value: 2,
                                  message: 'Name must be at least 2 characters',
                                },
                                maxLength: {
                                  value: 26,
                                  message:
                                    'Name must be less than 26 characters',
                                },
                              })}
                              name={name}
                              onChange={onChange}
                              error={
                                !!errors.lastName?.message ||
                                !!errors.lastName?.type
                              }
                              className={classes.nameInput}
                              placeholder="Last Name"
                              classes={outlinedInputClasses}
                            />
                            {errors.lastName &&
                              errors.lastName.type === 'required' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                            {errors.lastName &&
                              errors.lastName.type === 'minLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                            {errors.lastName &&
                              errors.lastName.type === 'maxLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabel}>
                        Email address
                      </Typography>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value, name }) => (
                          <OutlinedInput
                            onFocus={() => {
                              props.setExistingEmailErr(undefined);
                            }}
                            fullWidth
                            inputRef={register({
                              required: true,
                              minLength: 1,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            })}
                            name={name}
                            onChange={onChange}
                            error={
                              !!errors.email?.message ||
                              !!errors.email?.type ||
                              props.existingEmailErr
                            }
                            className={classes.nameInput}
                            placeholder="Enter Email Address"
                            classes={outlinedInputClasses}
                          />
                        )}
                      />
                      {props.existingEmailErr ? (
                        <Typography className={classes.errorLabel}>
                          This email is already registered, please try another
                          email address.
                        </Typography>
                      ) : (
                        <Typography className={classes.subLabel}>
                          Temporary password will be sent to this email address
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <RoleSelect
                        inputRef={register({ required: true })}
                        state={role}
                        setState={setRole}
                        isMultiple={false}
                        formType={'UserRole'}
                        itemsTypes={'Item'}
                        inputTitle={'Role(s)'}
                        placeholder={'Select Title(s)'}
                        droplistAttr={{
                          maxHeight: ITEM_HEIGHT * ITEM_NUM + ITEM_PADDING_TOP,
                        }}
                        items={newRoleOptions}
                        classes={{
                          multipleSelect: classes.multipleSelect,
                          formControl: classes.formControl,
                          inputTitle: classes.inputTitle,
                          placeholder: classes.placeholderText,
                          placeholderSelected:
                            classes.insurancePlaceholderTextSelected,
                          select: classes.insuranceSelect,
                          list: classes.insuranceList,
                          listItem: classes.insuranceListItem,
                          itemText: classes.insuranceListItemText,
                          itemHelper: classes.insuranceListitemHelper,
                          selected: classes.selected,
                          menuItem: classes.insuranceMenuItem,
                          menuItemText: classes.insuranceMenuItemText,
                          selectMenuContent: classes.selectMenuContent,
                          inputHelper: classes.inputHelper,
                        }}
                        hasAvatar={false}
                      />
                    </Grid>
                    {[...Array.from(Array(numberOfPairs).keys())].map(
                      (num, i) => {
                        return (
                          <>
                            <Grid item xs={6}>
                              <CustomSingleSelect
                                selectedPairs={selectedClinicId}
                                state={
                                  selectedClinicIdAndDivisionIdPairs[i]
                                    .clinicId ?? ''
                                }
                                type={i + ',ClinicId'}
                                setState={handleClinicIdOrDivisionChange}
                                isMultiple={true}
                                inputTitle={'Clinic ID'}
                                placeholder={'Select'}
                                fullWidth={true}
                                items={clinicIdOptions}
                              />
                            </Grid>
                            {hasFeature && (
                              <Grid item xs={i == numberOfPairs - 1 ? 5 : 6}>
                                <DivisionSelect
                                  // disable={
                                  //   !disableDivisionSelection
                                  //     .split(',')
                                  //     ?.includes(i.toString())
                                  // }
                                  disable={
                                    disabledDivision[i] ||
                                    selectedClinicIdAndDivisionIdPairs[i]
                                      .clinicId !== '9000' // important: hard code for now to filter out the 9000 clinic only NPH-2643
                                  }
                                  state={
                                    selectedClinicIdAndDivisionIdPairs[i]
                                      .divisions ?? []
                                  }
                                  type={i + ',DivisionId'}
                                  setState={handleClinicIdOrDivisionChange}
                                  isMultiple={true}
                                  fullWidth={true}
                                  inputTitle={'Division'}
                                  placeholder={'Select'}
                                  items={divisionIdOptions}
                                />
                              </Grid>
                            )}
                            {i == numberOfPairs - 1 && (
                              <Grid item xs={1}>
                                <IconButton
                                  style={{ marginTop: 35 }}
                                  aria-label="close"
                                  onClick={() => {
                                    setNumberOfPairs(numberOfPairs - 1);
                                    let temp = selectedClinicIdAndDivisionIdPairs.slice(
                                      0,
                                      -1
                                    );
                                    setSelectedClinicIdAndDivisionIdPairs(temp);
                                    setSelectedClinicId(
                                      temp
                                        .map((item) => item.clinicId)
                                        .join(',')
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </>
                        );
                      }
                    )}
                    <Grid container xs={12}>
                      <IconButton
                        onClick={() => {
                          setNumberOfPairs(numberOfPairs + 1);
                          setSelectedClinicIdAndDivisionIdPairs([
                            ...selectedClinicIdAndDivisionIdPairs,
                            {
                              clinicId: undefined,
                              divisions: [],
                            },
                          ]);
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                      <div className={classes.buttonText}>Add Clinic ID</div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabel}>
                        Phone
                      </Typography>
                      <OutlinedInput
                        fullWidth
                        inputProps={{
                          required: true,
                        }}
                        name={'phone'}
                        defaultValue=""
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        error={
                          phoneNumber?.length > 0 &&
                          phoneNumber.match(
                            /^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i
                          ) == null
                        }
                        className={classes.nameInput}
                        placeholder="Enter Phone Number(s): e.g. 234-456-7890"
                        classes={outlinedInputClasses}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} className={classes.cardFooter}>
                    <Button
                      type="submit"
                      className={classes.nextButton}
                      disabled={
                        !formState.isValid ||
                        !(
                          phoneNumber?.match(
                            /^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i
                          ) != null
                        ) ||
                        !role
                      }
                    >
                      Create User
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
