import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import {createHeaderCell} from "../../../../../sharePipe/createHeaderCellPipe";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {DivisionTable} from "./DivisionTable";

const useStyles = makeStyles((theme) => ({
    emptyName: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: '#ABABAB',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    card: {
        width: '100%',
        display: 'flex',
        borderRadius: 8,

        '& .MuiPaper-rounded': {
            borderRadius: 8,
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
        },
    },
    cardHeader: {
        height: 63,
        paddingLeft: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid #D1D1D1`,
        background: '#F8F8F8',
    },
    cardSubHeader: {
        height: 40,
        paddingLeft: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        background: '#F8F8F8',
        // paddingBottom: 8,

        '& > span:first-child': {
            padding: '10px 10px 10px 14px',
        },
    },
    cardTitle: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: theme.typography.fontWeightBold,
    },
    cardContent: {
        width: '100%',
        padding: theme.spacing(3),
        paddingRight: theme.spacing(0),
    },
    cardMidSection: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        padding: theme.spacing(3),
    },
    cardFooter: {
        height: 72,
        padding: theme.spacing(2, 3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    createButton: {
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '24px',
        background: theme.palette.primary.main,
        borderRadius: '8px',
        width: '148px',
        height: '32px',
        fontSize: 14,
        textAlign: 'center',
        padding: '4px 0',
        '&:hover': {
            backgroundColor: '#1b8cf3',
        },
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 6,
        width: 24,
        height: 24,
        boxShadow:
            '0px 0px 0px 1px rgba(0, 0, 0, 0.08),0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08);',
        backgroundColor: '#fff',
        'input:hover ~ &': {
            transform: 'scale(1.05)',
        },
        'input:checked ~ &': {
            boxShadow: 'none',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.grey['200'],
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 24,
            height: 24,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23ABABAB'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
    checkedAllIcon: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 24,
            height: 24,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
    subTitle: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: '#656565',
    },
    addIconClasses: {
        fontSize: 16,
    },
}));

interface Props{
    data: any[],
    onRefresh: () => void
}

export const DivisionContainer = (props: Props) => {
    const classes = useStyles();
    const location = useLocation();
    const [sortBy, setSortBy] = React.useState({
        field: 'division',
        method: 'desc' as const,
    });
    let navigate = useNavigate();
    const { search } = location;
    const [skip, limit] = useMemo(() => {
        const params = new URLSearchParams(search);
        const skip =
            params.has('skip') && params.get('skip') !== ''
                ? parseInt(params.get('skip')!)
                : undefined;
        const limit =
            params.has('limit') && params.get('limit') !== ''
                ? parseInt(params.get('limit')!)
                : undefined;
        return [skip, limit];
    }, [search]);

    function updateQps({
                           skip,
                           limit,
                       }: {
        skip: number | undefined;
        limit: number | undefined;
    }) {
        const params = new URLSearchParams();

        if (limit !== undefined) {
            params.set('limit', limit.toString());
        }
        if (skip !== undefined) {
            params.set('skip', skip.toString());
        }

        navigate(`?${params.toString()}`, { replace: true });
    }

    // Change limit size and then reload list from index 0
    function onSetLimit(limit: number) {
        updateQps({ limit: limit, skip: 0 });
    }

    function onSetSkip(skip: number) {
        updateQps({ limit, skip });
    }

    return (
        <Paper className={classes.card} elevation={1}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    className={classes.cardHeader}
                    style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                >
                    <Typography className={classes.cardTitle}>Division</Typography>
                </Grid>
                {props.data?.length === 0 ? <Typography className={classes.emptyName}>No Division</Typography> : (
                    <DivisionTable
                    onRefresh={props.onRefresh}
                    list={props.data??[]}
                    skip={skip || 0}
                    limit={limit || 10}
                    setSkip={onSetSkip}
                    setLimit={onSetLimit}
                    count={props.data?.length??0}
                    headCells={[
                        {
                            id: 'division',
                            align: 'left' as const,
                            disablePadding: false,
                            label: 'Division Name',
                        },
                        {
                            id: 'siteDesignee',
                            align: 'left' as const,
                            disablePadding: false,
                            label: 'Site Designee',
                        },
                        {
                            id: 'assigned',
                            align: 'left' as const,
                            disablePadding: false,
                            label: 'Assigned',
                        },
                        {
                            id: 'address',
                            align: 'left' as const,
                            disablePadding: false,
                            label: 'Address',
                        },
                    ].map((item) =>
                        createHeaderCell(
                            item.id,
                            item.label,
                            item.disablePadding,
                            item.align
                        )
                    )}
                    unSortOrderBy={['siteDesignee','assigned','address']}
                    sortBy={{ ...sortBy }}
                    setSortBy={setSortBy}
                    setOnEditTemplate={()=>{}}
                    setOnCreateTemplate={()=>{}}
                    changeStatus={()=>{}}
                />
                )}
                
            </Grid>
        </Paper>
    );
};
