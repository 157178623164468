import React, {ChangeEvent} from 'react';
import Paper from '@material-ui/core/Paper';
import {TablePagination, Button} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {HeadCell} from "../../../../../sharePipe/createHeaderCellPipe";
import {theme} from "../../../../../themes/theme";
import {ProfileItem} from "../../../../../components/ProfileItem";
import {useStickyTableStyle} from "../../../../../components/TabBar/Styles";
import {AssignDesigneeDialogView} from "./AssignDesigneeDialog";

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: Order
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, 'divisionName' as any)
        : (a, b) => -descendingComparator(a, b, 'divisionName' as any);
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },

        paper: {
            width: '100%',
            // marginBottom: theme.spacing(2),
        },
        aLink: {
            textDecoration: 'none',
        },
        newPatientText: {
            width: 'fit-content',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            // color: `${theme.palette.primary.main}`,
            fontWeight: 600,
            fontSize: 12,
            cursor: 'pointer',
        },
        noTemplateText: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '24px',
            color: '#ABABAB',
            paddingLeft: 24,
            paddingTop: 18,
            paddingBottom: 16,
        },
        icon: {
            width: 25,
            height: 25,
        },
        button: {
            padding: theme.spacing(0),
            borderRadius: 8,
            background: '#0C77D8',
            width: 70,
            height: 40,
            color: '#FFFFFF',
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 700,
            textAlign: 'center',
            '&:hover': {
                backgroundColor: '#1b8cf3',
            },
        },
        active: {
            background: '#EDF6FF',
            color: '#0C77D8',
            borderRadius: 6,
            fontSize: 12,
            padding: theme.spacing(0.25, 1.125),
            fontWeight: 600,
            height: 24,

            '& .MuiChip-label': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        inActive: {
            background: '#EFEFEF',
            color: '#888888',
            borderRadius: 6,
            fontSize: 12,
            padding: theme.spacing(0.25, 1.125),
            fontWeight: 600,
            height: 24,
            '& .MuiChip-label': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        tableBodyRowCell: {
            height: '24px',
            lineHeight: '24px',
            color: theme.palette.grey[600],
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightMedium,
            letterSpacing: '0px',
        },
        assignedColumn:{
            color: '#0C77D8',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            borderBottom: '1px solid #0C77D8',
            width: 'fit-content',
            '&:hover': {
                cursor: 'pointer',
                color: '#1b8cf3',
            }
        },
        table: {
            minWidth: 750,
            border: 'none',

            '& .MuiTableHead-root': {
                boxShadow:
                    '0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 3px 6px 0px rgb(0 0 0 / 8%)',
                background: theme.palette.grey[50],
            },

            '& > thead > tr > th': {
                background: '#F8F8F8',
                paddingLeft: theme.spacing(3),
                padding: 0,
                height: 32,
                fontSize: 12,
                color: theme.palette.grey[500],
                fontWeight: theme.typography.fontWeightBold,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                backgroundColor: theme.palette.grey[50],
            },

            '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
                padding: theme.spacing(0, 0, 0, 1.5),
            },

            '& > tbody > tr > th': {
                padding: theme.spacing(0.75, 2),
            },

            '& > tbody > tr': {
                height: 56,
            },

            '& > tbody > tr > *': {
                fontSize: 14,
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.grey[600],
                paddingTop: theme.spacing(1.875),
                paddingBottom: theme.spacing(1.875),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                lineHeight: '24px',
                height: 24,
                borderBottom: '1px solid #D1D1D1',
            },
            // Role column
            '& > tbody > tr > td:nth-child(4)': {
                width: 120,
            },
            // Status column
            '& > tbody > tr > td:nth-child(5)': {
                width: 130,
            },
            '& > tbody > tr > td:last-child': {
                width: 65,
            },
            '& > tbody > tr:last-child': {
                // borderBottom: '1px solid #D8D8D8',
            },
        },
        tableRow: {
            background: '#FFFFFF',
            // '&.MuiTableRow-hover:hover': {
            //   cursor: 'pointer',
            // },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        checkbox: {
            width: 24,
            height: 24,
            padding: 0,
            marginLeft: 12,
        },
        selectAll: {
            '& > .checkedIcon:before': {
                backgroundColor: 'red',
            },
        },
        roleChip: {
            fontSize: 12,
            padding: theme.spacing(0.25, 1.125),
            fontWeight: 600,
            height: 24,

            '& .MuiChip-label': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        roleChipMSOAdmin: {
            background: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        roleChipAdmin: {
            background: theme.palette.success.light,
            color: theme.palette.success.main,
        },
        roleChipUser: {
            background: theme.palette.info.light,
            color: theme.palette.info.main,
        },
        iconWraper: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 44,
        },
        pagination: {
            display: 'flex',
            justifyContent: 'flex-end',
            '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
                {
                    order: -1,
                    marginRight: theme.spacing(1),
                    fontWeight: theme.typography.fontWeightBold,
                },
            '& p[id]': {
                marginRight: theme.spacing(5.75),
            },
        },
    })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    order: Order;
    orderBy: string;
    headCells: HeadCell[];
    setOrder: any;
    setOrderBy: any;
    unSortOrderBy?: string[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {classes, order, orderBy, headCells, unSortOrderBy} = props;

    const isDisableSort = (id: any) => {
        return (
            unSortOrderBy &&
            unSortOrderBy.findIndex((_orderBy) => {
                return _orderBy === id;
            }) !== -1
        );
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={
                                isDisableSort(headCell.id) ? false : orderBy === headCell.id
                            }
                            hideSortIcon={isDisableSort(headCell.id)}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            disabled={isDisableSort(headCell.id)}
                            onClick={() => {
                                if (isDisableSort(headCell.id)) {
                                    return;
                                }
                                props.setOrder(props.order == 'asc' ? 'desc' : 'asc')
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableRowProps {
    row: any;
    labelId: string;
    setOnEditTemplate: any;
    changeStatus: any;
    setOnOpenAssignDialog: any;
    setSelectedDivision: any;
}

function EnhancedTableRow(props: EnhancedTableRowProps) {
    const {row, setOnOpenAssignDialog, setSelectedDivision} = props;
    const classes = useStyles();
    const avatarPalette = theme.palette.avatars;
    const colors = Object.values(avatarPalette);
    const siteAssignee = row.pairs.find((item: any)=>item.isSiteDesignee)

    return (
        <TableRow
            onClick={() => {
            }}
            className={classes.tableRow}
            role="checkbox"
            tabIndex={-1}
            key={row.id}
        >
            {/* panel name */}
            <TableCell align="left">
                {/* <Link to={`/panels/${row.id}/summary`} className={classes.aLink}> */}
                <span className={classes.tableBodyRowCell}>{row.divisionName}</span>
                {/* </Link> */}
            </TableCell>
            {/* care team avatars (if no avatars show initials), and the limited number of care team users */}

            <TableCell align="left">
                {siteAssignee?.firstName && siteAssignee?.lastName ? (
                        <ProfileItem
                            avatarBgColor={colors[0]}
                            firstName={siteAssignee?.firstName ?? ''}
                            lastName={siteAssignee?.lastName ?? ''}
                        />) :
                    <div style={{display: 'flex', height: '100%', alignItems: 'center', color: '#ABABAB',}}>No
                        Assignees</div>}
            </TableCell>
            <TableCell align="left">
                {row.pairs.length>0?
                    <div className={classes.assignedColumn}
                         onClick={() => {
                        setOnOpenAssignDialog(true);
                        setSelectedDivision(row)
                    }}>
                        {row.pairs.length} User{row.pairs.length>1?'s':''}</div>
                    :
                    <Button
                        autoFocus
                        className={classes.button}
                        onClick={() => {
                            setOnOpenAssignDialog(true);
                            setSelectedDivision(row)
                        }}>
                        Assign
                    </Button>
                }
            </TableCell>
            <TableCell align="left">
                <div style={{display: 'flex', height: '100%', alignItems: 'center', color: '#ABABAB',}}>
                    N/A
                </div>
            </TableCell>
        </TableRow>
    );
}

interface TemplateListTableViewProps {
    onRefresh: () => void,
    list: any;
    limit: number;
    skip: number;
    setLimit: (input: number) => any;
    setSkip: (input: number) => any;
    count: number;
    headCells: HeadCell[];
    refetchDataFunc?: any;
    sortBy: { field: string; method: 'desc' | 'asc' };
    setSortBy: (input: any) => void;
    setOnCreateTemplate: any;
    setOnEditTemplate: any;
    changeStatus: any;
    unSortOrderBy?: string[];
}

export const DivisionTable = (props: TemplateListTableViewProps) => {
    const [isSorting, setIsSorting] = React.useState(false);

    const classes = useStyles();
    const stickyTableClasses = useStickyTableStyle();
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [order, setOrder] = React.useState<Order>(props.sortBy.method);
    const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
    const [refresh, setRefresh] = React.useState(0);
    const [onOpenAssignDialog, setOnOpenAssignDialog] = React.useState(false);
    const [selectedDivision, setSelectedDivision] = React.useState<any>(null);
    React.useEffect(() => {
        setFilteredRows(props.list.sort((a: any, b: any) => a?.divisionName.toLowerCase().localeCompare(b?.divisionName.toLowerCase())));
        setIsSorting(false);
    }, [props.list]);
    // @ts-ignore
    return (
        <div className={classes.root}>
            {onOpenAssignDialog &&
                <AssignDesigneeDialogView
                    setShowAssignDesigneeDialog={setOnOpenAssignDialog}
                    assignedDesignee={selectedDivision?.pairs}
                    assignDesignee={() => {
                        props.onRefresh();
                    }}
                    selectedDivision={selectedDivision}
                    loading={false}/>}
            <Paper className={classes.paper}>
                <div style={{position: 'relative'}}>
                    {/* users list table */}
                    <TableContainer
                        style={{
                            borderBottom:
                                filteredRows && filteredRows.length > 0
                                    ? 'none'
                                    : `1px solid #D1D1D1`,
                            maxHeight: 'calc(100vh - 350px)',
                            overflow: 'auto'
                        }}
                        classes={{root: stickyTableClasses.customTableContainer}}
                    >
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            stickyHeader
                        >
                            {/* table header */}
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                unSortOrderBy={props.unSortOrderBy}
                                //   rowCount={filteredRows.length}
                                headCells={props.headCells}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            {/* tables rows */}
                            <TableBody>
                                {filteredRows && filteredRows.length > 0 ? (stableSort(
                                        filteredRows || [],
                                        getComparator(order)
                                    )
                                        .slice((Math.floor(props.skip / props.limit)) * props.limit, (Math.floor(props.skip / props.limit)) * props.limit + props.limit)
                                        .map((row: any, index: number) => {
                                            return (
                                                <EnhancedTableRow
                                                    key={row.value}
                                                    row={row}
                                                    labelId={`${index}`}
                                                    setOnEditTemplate={props.setOnEditTemplate}
                                                    changeStatus={props.changeStatus}
                                                    setOnOpenAssignDialog={setOnOpenAssignDialog}
                                                    setSelectedDivision={setSelectedDivision}
                                                />
                                            );
                                        })
                                ) : (
                                    <div className={classes.noTemplateText}>No Division</div>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Pagination
                        limit={props.limit}
                        skip={props.skip}
                        setLimit={props.setLimit}
                        setSkip={props.setSkip}
                        count={props.count}
                        setSortBy={props.setSortBy}
                        order={order}
                        orderBy={orderBy}
                    />
                </div>
            </Paper>
        </div>
    );
};

interface PaginationProps {
    limit: number;
    skip: number;
    setLimit: (input: number) => any;
    setSkip: (input: number) => any;
    count: number;
    setSortBy: (input: any) => void;
    orderBy: string;
    order: string;
}

const Pagination = (props: PaginationProps) => {
    const {limit, skip, count} = props;

    const classes = useStyles();

    const currentPage = Math.floor(skip / limit);

    const handleChangePage = (event: unknown, newPage: number) => {
        props.setSkip(newPage * limit);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        props.setSkip(0);
        props.setLimit(parseInt(event.target.value));
    };

    return (
        <Paper>
            <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="items per page"
                className={classes.pagination}
            />
        </Paper>
    );
};
