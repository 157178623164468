import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { StyledCheckbox } from '../StyledCheckbox';
import { ProfileItem } from '../ProfileItem';
import { theme } from '../../themes/theme';

const avatarPalette = theme.palette.avatars;
const colors = Object.values(avatarPalette);
interface CustomSelectProps {
  state: any[];
  type: string;
  setState: any;
  isMultiple: boolean;
  inputTitle: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  showValue?: boolean;
  fullWidth?: boolean;
  isObject?: boolean;
  showSelectedCount?: boolean;
  disable?: boolean;
  avatarItem?: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      borderRadius: 8,
    },
  },
  checkBox: {
    padding: 0,
  },
  countAndCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
  },
  avatarRowItemText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatarRowItem: {
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,

    '& > div:first-child > p': {
      maxWidth: 166,
    },
  },
  itemRow: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 50,
  },
  selectMenuContentValue: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    width: 50,
    paddingRight: 8,
  },
  selectMenuContentName: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: 269,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  placeholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  disabledPlaceholderTextSelected: {
    color: '#ABABAB',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',

    width: '100%',

    marginTop: 12,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  disabledFormControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#EFEFEF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
  disabledInputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
    opacity: 0.5,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  checkBoxPosition: {
    position: 'absolute',
    right: 8,
    padding: 0,
  },
  min40Height: {
    minHeight: 40,
  },
  minWidthAnd40height: {
    minWidth: 416,
    minHeight: 40,
  },
}));

export const CustomSelectWithCheckBoxAvatar = (props: CustomSelectProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, ProviderType and CareTeam
    let newValue = event.target.value;
    if (event.target.value.includes('All') && !props.state.includes('All')) {
      newValue = ['All'];
    } else if (props.state.includes('All')) {
      if (event.target.value.includes('All')) {
        newValue = [];
        props.items.forEach((value: any) => {
          if (!event.target.value.includes(value?.value)) {
            newValue.push(value.value);
          }
        });
      } else {
        newValue = newValue.filter((value: any) => value != 'All');
      }
    } else if (event.target.value?.length == props.items?.length) {
      newValue = ['All'];
    }
    props.setState(props.type, newValue);
  };

  const getItemName = (value: string) => {
    if (!props.isObject) return value;
    else {
      return props.items.find((item) => item?.value == value)?.name || value;
    }
  };

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.fullWidth ? '100%' : props.width || 203 }}
    >
      <FormControl
        className={
          props.disable ? classes.disabledFormControl : classes.formControl
        }
      >
        <Typography
          id={`${props.inputTitle}`}
          variant="h5"
          className={
            props.disable ? classes.disabledInputTitle : classes.inputTitle
          }
        >
          {props.inputTitle}
        </Typography>

        <Select
          disabled={props.disable}
          multiple={props.isMultiple}
          variant="outlined"
          displayEmpty
          value={props.state}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          renderValue={(selected) => {
            if (isOpen) {
              return <div className={classes.placeholderText}>Select</div>;
            } else if (
              (selected as string[]).length === 0 ||
              (selected as string[]).includes('All')
            ) {
              return (selected as string[]).includes('All') ? (
                <div
                  className={
                    props.disable
                      ? classes.disabledPlaceholderTextSelected
                      : classes.placeholderTextSelected
                  }
                  style={{ color: theme.palette.grey[600] }}
                >
                  All
                </div>
              ) : (
                <div className={classes.placeholderText}>
                  {props.placeholder}
                </div>
              );
            }

            return (
              <div className={classes.placeholderTextSelected}>
                {props.showSelectedCount
                  ? `${(selected as string[]).length} selected`
                  : (selected as string[])
                      .map((item) => getItemName(item))
                      .join(', ')}
              </div>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            autoFocus: false,
            elevation: 2,
            getContentAnchorEl: null,
            style: props.fullWidth
              ? {
                  maxHeight: 68 * 5 + 8,
                  maxWidth: '100%',
                }
              : {
                  maxHeight: 68 * 5 + 8,
                  width: props.showValue ? 416 : 250,
                },
            PaperProps: {
              style: props.fullWidth
                ? {
                    // reduce space between input and menu
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 7,
                    maxWidth: 400,
                  }
                : {
                    // reduce space between input and menu
                    minWidth: props.width ? 'fit-content' : 192,
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 12,
                    maxWidth: 300,
                  },
            },
          }}
          className={classes.select}
        >
          <MenuItem
            value="All"
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
            className={
              props.showValue
                ? classes.minWidthAnd40height
                : classes.min40Height + ' ' + classes.itemRow
            }
          >
            <Typography variant="body1" className={classes.selectMenuContent}>
              All
            </Typography>
            <StyledCheckbox
              checked={props.state.includes('All')}
              checkedAll={true}
              className={classes.checkBoxPosition}
            />
          </MenuItem>
          {props.items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={typeof item == 'string' ? item : item.value}
              classes={{
                root: classes.listItem,
                selected: classes.selected,
              }}
              className={
                props.showValue
                  ? classes.minWidthAnd40height
                  : classes.min40Height + ' ' + classes.itemRow
              }
            >
              {props.avatarItem ? (
                <div className={classes.avatarRowItem}>
                  <ProfileItem
                    avatarBgColor={colors[idx % colors.length]}
                    firstName={
                      item?.user ? item?.user?.firstName : item?.firstName || ''
                    }
                    lastName={
                      item?.user ? item?.user?.lastName : item?.lastName || ''
                    }
                  />
                  <div className={classes.countAndCheckbox}>
                    <Typography className={classes.avatarRowItemText}>
                      {item.user.count}
                    </Typography>

                    <StyledCheckbox
                      checked={
                        props.state.includes(
                          typeof item == 'string' ? item : item.value
                        ) || props.state.includes('All')
                      }
                      checkedAll={true}
                      className={classes.checkBox}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {props.showValue && (
                    <Typography
                      variant="body1"
                      className={classes.selectMenuContentValue}
                    >
                      {typeof item == 'string' ? item : item.value}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className={
                      props.showValue
                        ? classes.selectMenuContentName
                        : classes.selectMenuContent
                    }
                  >
                    {typeof item == 'string' ? item : item.name}
                  </Typography>
                  <StyledCheckbox
                    checked={
                      props.state.includes(
                        typeof item == 'string' ? item : item.value
                      ) || props.state.includes('All')
                    }
                    checkedAll={true}
                    className={classes.checkBoxPosition}
                  />
                </>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
