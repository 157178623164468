import React from 'react';
import { PatientFaceInfo } from '../../../../../../app.types.generated';

export type CareCoordinationOptionType = {
  name: string;
  value: string;
};

const CareCoordinationOptionsByDefault: CareCoordinationOptionType[] = [
  {
    name: 'Site Follow Up',
    value: 'Site Follow Up',
  },
  {
    name: 'Out of Country',
    value: 'Out of Country',
  },
  {
    name: 'Changed PCP',
    value: 'Changed PCP',
  },
  {
    name: 'Inactive Insurance',
    value: 'Inactive Insurance',
  },
  {
    name: 'Patient Deceased',
    value: 'Patient Deceased',
  },
];
export const useCareCoordinationOption = (patientInfo: PatientFaceInfo) => {
  const [
    showCallAttemptButtonByStatus,
    setShowCallAttemptButtonByStatus,
  ] = React.useState<boolean>(true);
  const [careCoordinationOptions, setCareCoordinationOptions] = React.useState<
    CareCoordinationOptionType[]
  >([]);

  React.useEffect(() => {
    if (patientInfo?.careCoordinationStatus === 'Snoozed') {
      setCareCoordinationOptions([
        ...CareCoordinationOptionsByDefault,
        {
          name: 'Edit Snooze',
          value: 'Edit Snooze',
        },
      ]);
    } else {
      if (patientInfo?.careCoordinationStatus === 'Site Follow Up') {
        setCareCoordinationOptions([
          {
            name: 'Completed Site Follow Up',
            value: 'Completed Site Follow Up',
          },
        ]);
      } else {
        setCareCoordinationOptions([
          ...CareCoordinationOptionsByDefault,
          {
            name: 'Snooze',
            value: 'Snooze',
          },
        ]);
      }
    }

    if (
      patientInfo?.careCoordinationStatus === 'Deceased' ||
      patientInfo?.careCoordinationStatus === 'Outreach Closed'
    ) {
      // Do not show call attempt button and the manual care coordination dropdown
      setShowCallAttemptButtonByStatus(false);
    } else {
      setShowCallAttemptButtonByStatus(true);
    }
  }, [patientInfo]);

  return { careCoordinationOptions, showCallAttemptButtonByStatus };
};
