import React from 'react';
import { Input } from '@material-ui/core';

export const TestWindowPost = () => {
  const domain = 'https://d3gj6nz6yccb4z.cloudfront.net';
  const path = '/iclinic/login';
  const targetUrl = domain + path;
  const [token, setToken] = React.useState('');
  const [targetUser, setTargetUser] = React.useState('');
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        placeholder="Token"
        onChange={(event: any) => {
          setToken(event.target.value ?? '');
        }}
      />
      <Input
        placeholder="user"
        onChange={(event: any) => {
          setTargetUser(event.target.value ?? '');
        }}
      />
      <button
        onClick={() => {
          let popUp = window.open(targetUrl, '');
          const message = {
            token: token,
            user: targetUser,
          };
          const json = JSON.stringify(message);
          setTimeout(() => {
            popUp?.postMessage(json, targetUrl);
          }, 2000);
        }}
      >
        Send
      </button>
    </div>
  );
};
