import React from 'react';
import { TextareaAutosize, Typography, makeStyles } from '@material-ui/core';
import {
  ButtonMediumPrimaryText,
  ButtonMediumSecondaryText,
} from '../../../../../../../../../components/Buttons';
import { theme } from '../../../../../../../../../themes/theme';
import { Controller, useForm } from 'react-hook-form';
import { useOverLimit } from '../../hooks/useOverLimit';
import { useTextAreaBorder } from '../../hooks/useTextAreaBorder';

export const MAX_TEXT_LENGTH = 500;

export type EditNodesForm = {
  notes: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  textarea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 0, 2),

    '&:focus': {
      outline: 'none !important',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textareaNormal: {
    outline: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  textareaError: {
    outline: 'none !important',
    border: `1px solid ${theme.palette.error.main}`,
  },
  actionBtnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBtnText: {
    fontSize: 16,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
  },
  addNotesBtnText: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
});

interface EditNotesProps {
  closeEditNotes: () => void;
  submitNotes: (input: EditNodesForm) => void;
}

export const EditNotes = (props: EditNotesProps) => {
  const { closeEditNotes, submitNotes } = props;
  const classes = useStyles();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditNodesForm>({
    defaultValues: {
      notes: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { textLength, overLimit } = useOverLimit({ control });
  useTextAreaBorder({ overLimit, errors });

  const getErrorMessageElement = () => {
    if (overLimit.isOverLimit) {
      return (
        <Typography className={classes.errorMsg}>
          {`${overLimit.overLimitMsg} (${textLength.length}/${MAX_TEXT_LENGTH})`}
        </Typography>
      );
    } else if (errors.notes?.type !== 'maxLength') {
      return (
        <>
          {errors.notes && (
            <Typography className={classes.errorMsg}>
              {errors.notes.message}
            </Typography>
          )}
        </>
      );
    }
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(submitNotes)}>
        <Controller
          name="notes"
          control={control}
          render={({ onChange, value, name }) => (
            <TextareaAutosize
              autoFocus
              ref={register({
                required: {
                  value: true,
                  message: 'Please type some notes.',
                },
                maxLength: {
                  value: MAX_TEXT_LENGTH,
                  message: `The notes cannot exceed ${MAX_TEXT_LENGTH} characters.`,
                },
                pattern: {
                  value: /^(?!\s*$).+/g,
                  message:
                    'The notes cannot contain only white spacing.',
                },
              })}
              name={name}
              defaultValue={value}
              placeholder="Type notes"
              rowsMax={5}
              style={{ width: 750, height: 112, overflow: 'auto' }}
              className={
                errors.notes
                  ? classes.textarea + ' ' + classes.textareaError
                  : classes.textarea + ' ' + classes.textareaNormal
              }
              onChange={onChange}
            />
          )}
        />
        {getErrorMessageElement()}
        <div className={classes.actionBtnRow}>
          <ButtonMediumSecondaryText type="button" onClick={closeEditNotes}>
            <Typography className={classes.cancelBtnText}>Cancel</Typography>
          </ButtonMediumSecondaryText>
          <ButtonMediumPrimaryText type="submit" disabled={errors?.notes!=undefined}>
            <Typography className={classes.addNotesBtnText}>
              Add Notes
            </Typography>
          </ButtonMediumPrimaryText>
        </div>
      </form>
    </div>
  );
};
