export enum CareGapMeasureStatus {
  CLOSE = 'Close',
  CLOSED = 'Closed',
  PENDING = 'Pending',
  OPEN = 'Open',
}

export enum CareGapExtendedStatusEnum {
  Open = "Open",
  Close = "Close",
  Open_Manually = 'Open_Manually',

  //Intermediate status
  Get_Supporting_Documentation = 'Get_Supporting_Documentation',
  Submit_Submittal = 'Submit_Submittal',

  Assessed_And_Not_Applicable = 'Assessed_And_Not_Applicable',
  Closed_Manually = 'Closed_Manually',
}
