import * as Types from '../../../../../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientEligibilityRealTimeQueryVariables = Types.Exact<{
  mpatientId: Types.Scalars['Float'];
}>;


export type GetPatientEligibilityRealTimeQuery = (
  { __typename: 'Query' }
  & { getPatientEligibilityRealTime?: Types.Maybe<(
    { __typename: 'EligibilityInfoRealtime' }
    & Pick<Types.EligibilityInfoRealtime, 'activeStatus' | 'failReason' | 'isSuccess' | 'payerId' | 'pcpStatus' | 'rejectionReason' | 'updateTime' | 'updateTimeExt' | 'pcpExternal'>
  )> }
);


export const GetPatientEligibilityRealTimeDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPatientEligibilityRealTime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientEligibilityRealTime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mpatientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mpatientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeStatus"}},{"kind":"Field","name":{"kind":"Name","value":"failReason"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"payerId"}},{"kind":"Field","name":{"kind":"Name","value":"pcpStatus"}},{"kind":"Field","name":{"kind":"Name","value":"rejectionReason"}},{"kind":"Field","name":{"kind":"Name","value":"updateTime"}},{"kind":"Field","name":{"kind":"Name","value":"updateTimeExt"}},{"kind":"Field","name":{"kind":"Name","value":"pcpExternal"}}]}}]}}]};

/**
 * __useGetPatientEligibilityRealTimeQuery__
 *
 * To run a query within a React component, call `useGetPatientEligibilityRealTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientEligibilityRealTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientEligibilityRealTimeQuery({
 *   variables: {
 *      mpatientId: // value for 'mpatientId'
 *   },
 * });
 */
export function useGetPatientEligibilityRealTimeQuery(baseOptions: Apollo.QueryHookOptions<GetPatientEligibilityRealTimeQuery, GetPatientEligibilityRealTimeQueryVariables>) {
        return Apollo.useQuery<GetPatientEligibilityRealTimeQuery, GetPatientEligibilityRealTimeQueryVariables>(GetPatientEligibilityRealTimeDocument, baseOptions);
      }
export function useGetPatientEligibilityRealTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientEligibilityRealTimeQuery, GetPatientEligibilityRealTimeQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientEligibilityRealTimeQuery, GetPatientEligibilityRealTimeQueryVariables>(GetPatientEligibilityRealTimeDocument, baseOptions);
        }
export type GetPatientEligibilityRealTimeQueryHookResult = ReturnType<typeof useGetPatientEligibilityRealTimeQuery>;
export type GetPatientEligibilityRealTimeLazyQueryHookResult = ReturnType<typeof useGetPatientEligibilityRealTimeLazyQuery>;
export type GetPatientEligibilityRealTimeQueryResult = Apollo.QueryResult<GetPatientEligibilityRealTimeQuery, GetPatientEligibilityRealTimeQueryVariables>;
export function refetchGetPatientEligibilityRealTimeQuery(variables?: GetPatientEligibilityRealTimeQueryVariables) {
      return { query: GetPatientEligibilityRealTimeDocument, variables: variables }
    }