import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, List, ListItem, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { UsersIcon } from '../../../../assets/Icons/SideNavigation';
import { EligibleIcon } from '../../../../assets/Icons/Eligibility/EligibleIcon';
import { DashBoardIcon } from '../../../../assets/Icons/SideNavigation/DashBoardIcon';
import { GlobalContext, User } from '../../../../components/GlobalContext';
import {
  CheckIsAdmin,
  CheckIsCareManager,
  CheckIsHudsonClinician,
} from '../../../../components/CheckIsAdmin';
import { NavigateNextIcon } from '../../../../assets/Icons/CareGaps';
import { NotificationDot } from '../../../../components/NotificationDot';
import { UserInfoMenu } from './components/UserInfoMenu';
import { ConfigurationsIcon } from '../../../../assets/Icons/SideNavigation/ConfigurationsIcon';
import { ReportIcon } from '../../../../assets/Icons/SideNavigation/ReportIcon';
import LogoWithAppNameColored from '../../../../assets/Login/LogoWithAppNameColored.svg';
import { useFeature } from '../../../../components/FeatureFlag';
import {
  BellIcon,
  BellWithDotIcon,
} from '../../../../assets/Icons/Notification';

interface SideBarProps {
  width: number;
  user?: User;
}

const useStyles = makeStyles((theme) => ({
  sidebar: (props: SideBarProps) => ({
    width: props.width,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: props.width,
      boxSizing: 'border-box',
      top: 0,
    },
  }),
  sidebarWithAlert: (props: SideBarProps) => ({
    width: props.width,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: props.width,
      boxSizing: 'border-box',
      top: 32,
    },
  }),
  logoContainer: {
    padding: theme.spacing(3.25, 1, 2, 1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rendrChip: {
    background: '#005150',
    color: 'white',
    height: 20,
    borderRadius: 8,
    fontSize: 14,
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  hundsonChip: {
    background: '#FF7A00',
    color: 'white',
    height: 20,
    borderRadius: 8,
    fontSize: 14,
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  logoMenuBtn: {
    minWidth: 32,
    height: 32,
    backgroundColor: theme.palette.grey[50],
    padding: 0,
  },
  logoMenuIcon: {
    width: 20,
    height: 20,
    color: theme.palette.grey[500],
  },
  newLogo: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },

  logo: {
    padding: theme.spacing(0, 5.75, 0, 5.75),
    display: 'flex',
    gap: theme.spacing(1),
    '&:hover': {
      cursor: 'default',
    },
    userSelect: 'none',
  },
  logoImg: {
    width: 148,
    height: 32,
  },
  logoAlt: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.grey[700],
    fontSize: 14,
  },
  appSwitchButton: {
    height: 32,
    width: 32,
    backgroundColor: '#f8f8f8',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    height: '100%',
    padding: theme.spacing(2.5, 1, 3.5, 1),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  listItem: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    height: 40,
    marginBottom: theme.spacing(1),
    borderRadius: 12,
    '&.MuiListItem-gutters': {
      paddingLeft: theme.spacing(1.5),
    },
    '&.MuiListItem-root:hover': {
      backgroundColor: theme.palette.grey[50],

      fontWeight: theme.typography.fontWeightBold,
      '&:hover $navIcon': {
        color: theme.palette.grey[400],
      },
      '&:hover $listItemText': {
        color: theme.palette.grey[700],
      },
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  disableListItem: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginBottom: theme.spacing(1),
    borderRadius: 12,
    '&.MuiListItem-gutters': {
      paddingLeft: theme.spacing(1.5),
    },
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  navIcon: {
    color: theme.palette.grey[300],
  },
  navIconSelected: {
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    // paddingLeft: theme.spacing(2),
    display: 'flex',
    alignSelf: 'baseline',
    width: '100%',
    justifyContent: 'space-between',
  },
  listItemTextSelected: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    // paddingLeft: theme.spacing(2),
    display: 'flex',
    alignSelf: 'baseline',
    width: '100%',
    justifyContent: 'space-between',
  },
  subListItemText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 43.42,
    display: 'flex',
    alignSelf: 'baseline',
    width: '100%',
    justifyContent: 'space-between',
  },
  subListItemTextSelected: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 43.42,
    display: 'flex',
    alignSelf: 'baseline',
    width: '100%',
    justifyContent: 'space-between',
  },
  disabledListItemText: {
    fontSize: 14,
    color: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 43.42,
    display: 'flex',
    alignSelf: 'baseline',
    width: '100%',
    justifyContent: 'space-between',
  },
  navigateNext: {
    width: 24,
    height: 24,
    // color: theme.palette.grey[500],
    transform: `rotate(90deg)`,
  },
  rotateNavigateNext: {
    transform: `rotate(270deg)`,
  },
  bellIcon: {
    width: 24,
    height: 24,
    color: theme.palette.grey[300],
  },
}));

export const SideBarView: React.FC<SideBarProps> = (props) => {
  const classes = useStyles(props);
  const {
    openIntegration,
    setOpenIntegration,
    openConfiguration,
    setOpenConfiguration,
    handleNotificationCenter,
  } = React.useContext(GlobalContext);
  const { user } = props;

  const location = useLocation();

  const urlCheck = (url: string) => {
    let currentUrl = location.pathname;
    return url === currentUrl.split('/')[1];
  };

  const urlsCheck = (urls: string[]) => {
    let currentUrl = location.pathname;

    let flag = false;
    for (const s of urls) {
      if (s === currentUrl.split('/')[1]) {
        flag = true;
      }
    }
    return flag;
  };

  const { showNotificationDot, isChrome } = React.useContext(GlobalContext);

  const closeAllTabs = () => {
    setOpenConfiguration(false);
    setOpenIntegration(false);
  };

  const ProtectedDashboard = () => {
    return (
      <ListItem
        // key
        button
        className={classes.listItem}
        selected={urlCheck('dashboard')}
        component={Link}
        to="/dashboard"
        onClick={() => {
          closeAllTabs();
        }}
      >
        <DashBoardIcon
          className={
            urlCheck('dashboard') ? classes.navIconSelected : classes.navIcon
          }
        />
        <Typography
          className={
            urlCheck('dashboard')
              ? classes.listItemTextSelected
              : classes.listItemText
          }
        >
          Dashboard
        </Typography>
      </ListItem>
    );
  };
  const hasFeature = useFeature('eligibilitySidebar');
  const hasReportFeature = useFeature('reportSidebar');

  return (
    <Drawer
      className={isChrome ? classes.sidebar : classes.sidebarWithAlert}
      variant="permanent"
      anchor="left"
    >
      <div className={classes.logoContainer}>
        {/*<Button*/}
        {/*  id="menu-button"*/}
        {/*  variant="contained"*/}
        {/*  disableElevation*/}
        {/*  className={classes.logoMenuBtn}*/}
        {/*  // onClick={}*/}
        {/*>*/}
        {/*  <MenuIcon className={classes.logoMenuIcon} />*/}
        {/*</Button>*/}
        <div className={classes.logo}>
          <img
            src={LogoWithAppNameColored}
            alt="iPopHealth"
            className={classes.logoImg}
          />
        </div>
      </div>
      {user ? <UserInfoMenu user={user} /> : <div />}
      <List className={classes.list}>
        <div>
          {/*{CheckIsAdmin(user) && <ProtectedDashboard />}*/}
          <ProtectedDashboard />
          {CheckIsHudsonClinician(user) ? null : (
            <>
              <ListItem
                // key
                button
                className={classes.listItem}
                selected={urlCheck('panels')}
                component={Link}
                to="/panels"
                onClick={() => {
                  closeAllTabs();
                }}
              >
                <UsersIcon
                  className={
                    urlCheck('panels')
                      ? classes.navIconSelected
                      : classes.navIcon
                  }
                />
                {CheckIsAdmin(user) || CheckIsCareManager(user) ? (
                  <Typography
                    className={
                      urlCheck('panels')
                        ? classes.listItemTextSelected
                        : classes.listItemText
                    }
                  >
                    Patient Panels
                  </Typography>
                ) : (
                  <Typography
                    className={
                      urlCheck('panels')
                        ? classes.listItemTextSelected
                        : classes.listItemText
                    }
                  >
                    My Patient Panels
                  </Typography>
                )}
              </ListItem>
              {CheckIsAdmin(user) && (
                <ListItem
                  // key
                  button
                  className={classes.listItem}
                  selected={urlsCheck(['payerID', 'eligibility', 'payerFiles'])}
                  onClick={() => {
                    setOpenIntegration(!openIntegration);
                  }}
                >
                  <EligibleIcon
                    className={
                      urlsCheck(['payerID', 'eligibility', 'payerFiles'])
                        ? classes.navIconSelected
                        : classes.navIcon
                    }
                  />
                  <Typography
                    className={
                      urlsCheck(['payerID', 'eligibility', 'payerFiles'])
                        ? classes.listItemTextSelected
                        : classes.listItemText
                    }
                  >
                    <span>Integrations</span>
                    <>
                      <NavigateNextIcon
                        className={
                          openIntegration
                            ? `${classes.navigateNext} ${classes.rotateNavigateNext}`
                            : `${classes.navigateNext}`
                        }
                      />
                    </>
                  </Typography>
                </ListItem>
              )}
              {openIntegration && (
                <div>
                  <ListItem
                    // key
                    button
                    className={classes.listItem}
                    selected={urlsCheck(['payerID', 'payerFiles'])}
                    component={Link}
                    to="/payerFiles"
                    onClick={() => {
                      setOpenConfiguration(false);
                    }}
                  >
                    <Typography
                      className={
                        urlsCheck(['payerID', 'payerFiles'])
                          ? classes.subListItemTextSelected
                          : classes.subListItemText
                      }
                    >
                      Payer Files
                    </Typography>
                    {showNotificationDot && (
                      <NotificationDot style={{ marginLeft: '8px' }} />
                    )}
                  </ListItem>
                  {hasFeature && (
                    <ListItem
                      // key
                      button
                      className={classes.listItem}
                      selected={urlCheck('eligibility')}
                      disabled={false}
                      component={Link}
                      to="/eligibility"
                      onClick={() => {
                        setOpenConfiguration(false);
                      }}
                    >
                      <Typography
                        className={
                          urlCheck('eligibility')
                            ? classes.subListItemTextSelected
                            : classes.subListItemText
                        }
                      >
                        Eligibility
                      </Typography>
                    </ListItem>
                  )}
                </div>
              )}

              {(CheckIsAdmin(user) || CheckIsCareManager(user)) && (
                <ListItem
                  // key
                  button
                  className={classes.listItem}
                  selected={urlsCheck(['users', 'settings'])}
                  onClick={() => {
                    setOpenConfiguration(!openConfiguration);
                  }}
                >
                  <ConfigurationsIcon
                    className={
                      urlsCheck(['users', 'settings'])
                        ? classes.navIconSelected
                        : classes.navIcon
                    }
                  />
                  <Typography
                    className={
                      urlsCheck(['users', 'settings'])
                        ? classes.listItemTextSelected
                        : classes.listItemText
                    }
                  >
                    <span>Configurations</span>
                    <>
                      <NavigateNextIcon
                        className={
                          openConfiguration
                            ? `${classes.navigateNext} ${classes.rotateNavigateNext}`
                            : `${classes.navigateNext}`
                        }
                      />
                    </>
                  </Typography>
                </ListItem>
              )}

              {openConfiguration && (
                <div>
                  <ListItem
                    // key
                    button
                    className={classes.listItem}
                    selected={urlCheck('users')}
                    component={Link}
                    to="/users/all"
                    onClick={() => {
                      setOpenIntegration(false);
                    }}
                  >
                    <Typography
                      className={
                        urlCheck('users')
                          ? classes.subListItemTextSelected
                          : classes.subListItemText
                      }
                    >
                      User & Permission
                    </Typography>
                  </ListItem>
                  <ListItem
                    // key
                    disabled={false}
                    button
                    className={classes.listItem}
                    selected={urlCheck('settings')}
                    component={Link}
                    to="/settings/sms"
                    onClick={() => {
                      setOpenIntegration(false);
                    }}
                  >
                    <Typography
                      className={
                        urlCheck('settings')
                          ? classes.subListItemTextSelected
                          : classes.subListItemText
                      }
                    >
                      Settings
                    </Typography>
                  </ListItem>
                  <ListItem
                    // key
                    disabled={true}
                    button
                    className={classes.listItem}
                  >
                    <Typography className={classes.subListItemText}>
                      Providers
                    </Typography>
                  </ListItem>
                  <ListItem
                    // key
                    disabled={true}
                    button
                    className={classes.listItem}
                  >
                    <Typography className={classes.subListItemText}>
                      Locations
                    </Typography>
                  </ListItem>
                </div>
              )}
            {
                hasReportFeature &&
                (CheckIsAdmin(user) || CheckIsCareManager(user)) && (
                    <ListItem
                        // key
                        button
                        className={classes.listItem}
                        selected={urlCheck('report')}
                        component={Link}
                        to="/report"
                        onClick={() => {
                            closeAllTabs();
                        }}
                    >
                        <ReportIcon className={classes.navIcon} />
                        <Typography className={classes.listItemText}>Reports</Typography>
                    </ListItem>
                )}
            </>
          )}
        </div>

        {CheckIsHudsonClinician(user) ? null : (
          <div>
            <ListItem
              // key
              button
              className={classes.listItem}
              style={{ marginBottom: 0 }}
              onClick={handleNotificationCenter}
            >
              {showNotificationDot ? (
                <BellWithDotIcon style={{ width: 24, Height: 24 }} />
              ) : (
                <BellIcon className={classes.bellIcon} />
              )}

              <Typography className={classes.listItemText}>
                Notification
              </Typography>
            </ListItem>
          </div>
        )}
      </List>
    </Drawer>
  );
};
