import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import patientFilterReducer from './reducer/patientFilter';

const store = configureStore({
  reducer: {
    patientFilter: patientFilterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; // This is a hook that allows you to extract data from the Redux store state
export const useAppDispatch: () => AppDispatch = useDispatch; // This is a hook returns the dispatch function from the Redux store

export default store;
