import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const MeIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M12 5C10.2818 5 8.88889 6.39289 8.88889 8.11111C8.88889 9.82933 10.2818 11.2222 12 11.2222C13.7182 11.2222 15.1111 9.82933 15.1111 8.11111C15.1111 6.39289 13.7182 5 12 5ZM9.78819 13.8275C9.72445 13.8244 9.65924 13.8289 9.59527 13.8427C7.38249 14.3194 5 15.3919 5 17.0556V18.2222C5 18.6516 5.34844 19 5.77778 19H18.2222C18.6516 19 19 18.6516 19 18.2222V17.0556C19 15.3919 16.6167 14.3194 14.4047 13.8427C14.1481 13.7874 13.8835 13.8717 13.6984 14.0569L12.5499 15.2053C12.2458 15.5094 11.7534 15.5094 11.4501 15.2053L10.3016 14.0569C10.1628 13.918 9.97942 13.8367 9.78819 13.8275Z"
  />
);
