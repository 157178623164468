import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const MoreHorizIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M6,10 C4.9,10 4,10.9 4,12 C4,13.1 4.9,14 6,14 C7.1,14 8,13.1 8,12 C8,10.9 7.1,10 6,10 Z M18,10 C16.9,10 16,10.9 16,12 C16,13.1 16.9,14 18,14 C19.1,14 20,13.1 20,12 C20,10.9 19.1,10 18,10 Z M12,10 C10.9,10 10,10.9 10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,10.9 13.1,10 12,10 Z"
    />
  );
};
