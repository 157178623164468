import React from 'react';

export const useCheckFilterChanges = (initialState: any, state: any) => {
  const [isAddAtLeastOneFilter, setIsAddAtLeastOneFilter] = React.useState(
    false
  );

  React.useEffect(() => {
    for (let [key, value] of Object.entries(initialState)) {
      // if the value is array type
      if (Array.isArray(value)) {
        if (state[key].length === value.length && value.length === 0) {
          // empty array
          setIsAddAtLeastOneFilter(false);
        } else if (state[key].length === value.length && value.length !== 0) {
          // check each element is equal or not
          for (let itemV of value) {
            for (let itemS of state[key]) {
              if (itemS !== itemV) {
                setIsAddAtLeastOneFilter(true);
                break;
              }
            }
          }
        } else {
          setIsAddAtLeastOneFilter(true);
          break;
        }
      } else {
        // other types
        if (state[key] !== value) {
          setIsAddAtLeastOneFilter(true);
          break;
        }
      }
    }
  }, [state, initialState]);

  return {
    isAddAtLeastOneFilter,
    setIsAddAtLeastOneFilter,
  };
};
