import {SendSmsDialog} from "../../../../Panels/ViewPanels/components/EngagementDialog/SendSmsDialog";
import {SendingSmsDialog} from "../../../../Panels/ViewPanels/components/EngagementDialog/SendingSmdDialog";
import React, {useState} from "react";
import {EngagementIcon} from "../../../../../assets/Icons/PanelsList/EngagementIcon";
import {Button, makeStyles, Typography} from "@material-ui/core";
import {theme} from "../../../../../themes/theme";
import {
  SendPanelSmsForm,
} from "../../../../Panels/ViewPanels/components/PanelPatientsListActionsFooter/PanelPatientActionsFooterFunctions";
import {useSendSmsToPatientListByCateoryMutation} from "./index.generated";
import {EligibilitySendCategorySmsRequest} from "../../../../../app.types.generated";
import {EligibilityCategory} from "../../../Dashboard/components/EligibilityCategory/EligibilityCategoryList";
import {useLocation, useNavigate} from 'react-router';
import useEligibility from "../../hooks/useEligibility";
import mixpanel from "mixpanel-browser";
import { MixPanelEvents } from "../../../../../mixpanel/events";

interface IProps {
  patientCount: number,
  category: EligibilityCategory,
  rejectedReasonList?: Array<string>
}

export default function BatchSmsButton(props: IProps) {
  const {patientCount, category, rejectedReasonList} = props
  const classes = useStyles()
  const [sending, setSending] = useState(false)
  const [show, setShow] = useState(false)
  const [sendPatientSms] = useSendSmsToPatientListByCateoryMutation();
  const navigate = useNavigate()
  const {hardRefresh} = useEligibility()

  const removeNBSP = (input: string) => {
    return input.replace(/&nbsp/g, " ").replace(/\u00A0/g, " ");
  }

  async function onSendPanelSMS(input: EligibilitySendCategorySmsRequest) {
    try {
      const { data } = await sendPatientSms({
        variables: { input },
      });
      setShow(false)
      setSending(true);
      MixPanelEvents.track(MixPanelEvents.userActivityAndJourneyMapping.SendOutBatchSmsProcessTime.name)
    } catch (err) {
      //
    }
  }

  const handleSendBatchSms = async (form: SendPanelSmsForm) => {
    const { type, messageList, templateName, templateId } = form;
    const input: EligibilitySendCategorySmsRequest = {
      messageType: type,
      message_template_id: templateId,
      message_template_name: templateName,
      messageText: '',
      category: category,
      rejectedReasonCategoryList: rejectedReasonList,
      messageList: messageList.map((item) => {
        item.content = removeNBSP(item.content)
        return item
      })
    };

    await onSendPanelSMS(input);
  }
  return (
    <div>
      <Button
        variant={'contained'}
        color="primary"
        size="small"
        onClick={() => {
          setShow(true)
        }}
      >
        <EngagementIcon className={classes.icon} />
        <Typography className={classes.title}>Batch SMS</Typography>
      </Button>
      {show ? (
        <SendSmsDialog
          onClose={() => {
            setShow(false)
          }}
          onSubmit={handleSendBatchSms}
          patientCount={patientCount}
        />
      ) : null}
      {sending && (
        <SendingSmsDialog
          onClose={() => {
            setSending(false)
            hardRefresh()
          }}
          link={"/eligibility/patients/engagement"}
          hardFresh={() => {
            hardRefresh()
          }}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  icon: {
    height: 20,
    width: 20,
    color: '#FFF',
    paddingRight: theme.spacing(0.5),
  },
  title: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
  }
}))
