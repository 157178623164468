import React, { useRef } from 'react';
import { GlobalContext } from '../GlobalContext';
import { useLocation } from 'react-router-dom';

export const SubMainRegion = (props: {
  children:
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
  header: any;
  overFlowHidden?: boolean;
  headerChange?: boolean;
  footer?: boolean;
}) => {
  const tag = useRef<null | HTMLDivElement>(null);
  React.useEffect(() => {
    const header = document.getElementById('sub-main-header');
    const content = document.getElementById('sub-fixed-main-content');

    if (header && content) {
      content.style.top = header.offsetTop + header.offsetHeight + 'px';
    }
    if (content) {
      if (props?.footer) {
        content.style.bottom = '84px';
      } else {
        content.style.bottom = '20px';
      }
    }
  }, [tag?.current?.offsetHeight, props.headerChange, props.footer]);

  React.useEffect(() => {
    // reset top offset if window size changed
    const header = document.getElementById('sub-main-header');
    const content = document.getElementById('sub-fixed-main-content');

    function handleWindowResize() {
      if (header && content) {
        content.style.top = header.offsetTop + header.offsetHeight + 'px';
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const { isSticky } = React.useContext(GlobalContext);
  let location = useLocation();

  const setStickySubMainRegion = (
    id: string
  ): React.CSSProperties | undefined => {
    switch (id) {
      case 'sub-main-header': {
        if (location.pathname.indexOf('/pcp') === 0) {
          // pcp pages will not involve the fix behavior
          return {
            position: 'fixed',
            maxWidth: '100%',
            right: 0,
            left: 250,
          };
        } else if (isSticky) {
          return {
            position: 'fixed',
            maxWidth: '100%',
            right: 0,
            left: 250,
          };
        } else {
          return {};
        }
      }
      case 'sub-fixed-main-content': {
        if (location.pathname.indexOf('/pcp') === 0) {
          // pcp pages will not involve the fix behavior
          return {
            position: 'fixed',
            maxWidth: '100%',
            bottom: 20,
            overflowY: props.overFlowHidden ? 'hidden' : 'auto',
            right: 0,
            left: 250,
          };
        } else if (isSticky) {
          return {
            position: 'fixed',
            maxWidth: '100%',
            bottom: 20,
            overflowY: props.overFlowHidden ? 'hidden' : 'auto',
            right: 0,
            left: 250,
          };
        } else {
          return {};
        }
      }
    }
  };

  return (
    <div>
      <div
        ref={tag}
        id="sub-main-header"
        style={setStickySubMainRegion('sub-main-header')}
      >
        {props.header}
      </div>
      <div
        id="sub-fixed-main-content"
        style={setStickySubMainRegion('sub-fixed-main-content')}
      >
        {props.children}
      </div>
    </div>
  );
};
