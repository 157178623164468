import { Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons';
import { formatNumberPipe } from '../../sharePipe/formatNumberPipe';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  header: {
    width: 'inherit',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  breadCrumb: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  backTitle: {
    fontSize: 12,
    color: theme.palette.secondary.dark,
    '&:hover': {
      color: theme.palette.grey['700'],
      cursor: 'pointer',
    },
  },
  arrowIcon: {
    fontSize: 12,
    color: theme.palette.grey['300'],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  navLink: {
    textUnderlineOffset: '4px',
    textDecorationThickness: 2,
    textDecorationColor: '#b3b1b1',
  },
  navTitle: {
    fontSize: 12,
    color: theme.palette.grey['600'],
    userSelect: 'none',
  },
  headerTitle: {
    fontSize: 32,
    height: 40,
    paddingTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  titleBadge: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline-block',
    borderRadius: 100,
    margin: theme.spacing(0, 0, 0, 1),
    padding: theme.spacing(0, 0.625),
    color: '#FFF!important',
    backgroundColor: theme.palette.info.main,
    verticalAlign: 'middle',
  },
  headerButton: {
    position: 'absolute',
    top: 60,
    right: 24,
  },
}));

interface breadCrumbElementProps {
  name: string;
  isLink: boolean;
  link: string;
  state?: any;
}

const BreadCrumbElement = (props: breadCrumbElementProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  if (props.isLink) {
    return props.state ? (
      <div
        className={classes.navLink}
        style={{
          textUnderlineOffset: '4px',
          textDecorationThickness: '2px',
          textDecoration: 'underline',
          textDecorationColor: '#b3b1b1',
        }}
        onClick={() => {
          navigate(props.link, {
            state: { payerName: props.state },
          });
        }}
      >
        <Typography
          className={classes.backTitle}
          style={{ textUnderlineOffset: '4px', textDecorationThickness: '2px' }}
        >
          {props.name}
        </Typography>
      </div>
    ) : (
      <NavLink className={classes.navLink} to={props.link}>
        <Typography className={classes.backTitle}>{props.name}</Typography>
      </NavLink>
    );
  } else {
    return <Typography className={classes.navTitle}>{props.name}</Typography>;
  }
};

interface BreadCrumbHeaderProps {
  rootLink: string;
  rootName: string;
  badgeCount?: number;
  childrenName: string;
  isTitle?: boolean;
  isButton?: boolean;
  renderButton?: any;
  childrenChildrenName?: string;
  childrenLink?: string;
  title?: string;
  childrenState?: string;
}

export const BreadCrumbHeader = (props: BreadCrumbHeaderProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.header}
      style={{
        height: props.isTitle ? 128 : 'auto',
      }}
    >
      <div className={classes.breadCrumb}>
        <BreadCrumbElement
          name={props.rootName}
          link={props.rootLink}
          isLink={true}
        />
        <ArrowForwardIcon className={classes.arrowIcon} />
        <BreadCrumbElement
          name={props.childrenName}
          link={props.childrenLink ? props.childrenLink : ''}
          isLink={!!props.childrenChildrenName}
          state={props.childrenState ?? undefined}
        />
        {props.childrenChildrenName && (
          <>
            <ArrowForwardIcon className={classes.arrowIcon} />
            <BreadCrumbElement
              name={props.childrenChildrenName}
              link={props.childrenLink ? props.childrenLink : ''}
              isLink={false}
            />
          </>
        )}
      </div>
      {props.isTitle && (
        <Typography className={classes.headerTitle}>
          {props.title ? props.title : props.childrenName}
          {props.badgeCount != null && props.badgeCount > 0 && (
            <span className={classes.titleBadge}>
              {formatNumberPipe(props.badgeCount, '0')}
            </span>
          )}
        </Typography>
      )}

      {props.isButton && (
        <div className={classes.headerButton}>{props.renderButton}</div>
      )}
    </div>
  );
};
