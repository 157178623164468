import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useCoordinationLogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      margin: theme.spacing(2.5, 3),
    },
    emptyContentText: {
      fontSize: 20,
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
      padding: theme.spacing(26, 15, 0, 15),
      textAlign: 'center',
    },
    sectionNameAndOptionSelectContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sectionName: {
      fontSize: 20,
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
    },
    optionSelect: {
      // '& div[class*="multipleSelect"]': {
      //   marginTop: 0,
      //   minWidth: '109px !important',
      //   width: 'fit-content',
      //   outline: 'none',
      // },
      // '& div[class*="placeholderText"]': {
      //   color: theme.palette.grey[600],
      // },
      '& div.MuiInputBase-root': {
        outline: 'none',
        boxShadow:
          '0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0,0,0,0.12)',
      },
      '& div.MuiInputBase-root:focus': {
        outline: 'none',
      },
      '& div.MuiInputBase-root:hover': {
        outline: 'none',
        border: `1px solid ${theme.palette.info.main}`,
      },
    },
    itemContainer: {
      border: `1px solid rgba(0, 0, 0, 0.12)`,
      borderRadius: 8,
      height: 'fit-content',
      marginTop: theme.spacing(1.5),
    },
    item: {
      padding: theme.spacing(2),
    },
    itemHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    generalInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1.5),
    },
    avatarIcon: {
      width: 40,
      height: 40,
      backgroundColor: theme.palette.avatars.cyan,

      '& svg': { width: 20, height: 20 },
    },
    avatarText: {},
    textTitle: {
      fontSize: 16,
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px',
    },
    textTime: {
      fontSize: 12,
      color: theme.palette.grey[500],
      fontWeight: 600,
      lineHeight: '20px',
    },
    operatorText: {
      fontSize: 14,
      color: theme.palette.grey[600],
      fontWeight: 500,
      lineHeight: '24px',
    },
    itemBody: {
      marginTop: theme.spacing(1.125),
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      width: '100%',
    },
    mainContentAttempts: {
      fontSize: 16,
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '24px',
      padding: theme.spacing(1, 1.5),
      backgroundColor: theme.palette.grey[50],
      borderRadius: 6,
      width: '100%',
      wordBreak: 'break-word',
    },
    mainContentSMS: {
      fontSize: 16,
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '24px',
      paddingLeft: theme.spacing(2),
      borderLeft: `6px solid ${theme.palette.grey[200]}`,
      marginLeft: theme.spacing(3),
    },
    mainContentStatusChange: {
      fontSize: 14,
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '24px',
    },
    snoozedLabel: {
      fontSize: '12px!important',
      color: '#D46829!important',
      backgroundColor: '#FCF3E5!important',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '2px 8px !important',
      borderRadius: '6px!important',
      '& span.MuiChip-label': {
        padding: 0,
      },
      '& svg': {
        width: '16px!important',
        height: '16px!important',
        marginRight: theme.spacing(1),
        color: theme.palette.warning.main,
      },
      '& g path, rect': {
        fill: theme.palette.warning.main,
      },
    },
    otherLabel: {
      fontSize: '12px!important',
      color: '#0C77D8!important',
      backgroundColor: '#EDF6FF!important',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '2px 8px !important',
      borderRadius: '6px!important',
      '& span.MuiChip-label': {
        padding: 0,
      },
      '& svg': {
        width: '16px !important',
        height: '16px !important',
        marginRight: theme.spacing(1),
        color: '#0C77D8!important',
      },
      '& g path, rect': {
        fill: '#0C77D8!important',
      },
    },
    greyStatusOtherLabel: {
      fontSize: '12px!important',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '2px 8px !important',
      borderRadius: '6px!important',
      '& span.MuiChip-label': {
        padding: 0,
      },
      backgroundColor: '#EFEFEF!important',
      color: '#888888!important', // theme.palette.secondary.main,
    },
    itemFooter: {},
    panelName: {
      fontSize: 14,
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px',
    },
    smsStatus: {
      backgroundColor: theme.palette.primary.light,
      '& svg': {
        width: 16,
        height: 16,
        color: theme.palette.primary.main,
      },
      '& p': {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    greyStatus: {
      backgroundColor: theme.palette.secondary.light,
      color: '#888888', // theme.palette.secondary.main,
    },
    mainContentReassignedContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,

      '& > div > p': {
        fontSize: 14,
        color: '#656565',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '24px',
      },
    },
    mainContentReassigned: {
      fontSize: 14,
      color: '#656565',
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '24px',
    },
    statusChangeFooterContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: 8,

      width: '100%',
    },
    statusChangeFooterChangeToContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
    },
    statusChangeFooterNoteContainer: {
      width: '96%',
      height: 'fit-content',
      backgroundColor: '#F8F8F8',
      padding: theme.spacing(1, 2),
      borderRadius: 6,
    },
    statusChangeFooterNote: {
      fontSize: 14,
      color: '#656565',
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '24px',
    },
  })
);
