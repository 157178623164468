import React, {useEffect, useState} from "react";
import PatientListViewContainer from "./Container";
import EligibilityProvider from "./hooks/eligibilityProvider";

export function PatientListView() {
  return (
    <EligibilityProvider>
      <PatientListViewContainer/>
    </EligibilityProvider>
  )
}
