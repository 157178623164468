import React from 'react';
import { createStyles, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { UserIdsInBatch } from '../../../../../../../app.types.generated';
import { ResendPasswordIcon } from '../../../../../../../assets/Icons/MsoAdmin';
import { AccountOptionType, User } from '../../../../../../../enum/Users';

const useMoreOptionsMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreHoriz: {
      color: theme.palette.grey[500],
    },
    menu: {
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded':
        {
          marginTop: 0,
          // height: 122,
          width: 200,
        },
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul':
        {
          width: 184,
          // height: 112,
          padding: theme.spacing(0.5, 1),
          position: 'relative',
        },

      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after':
        {
          content: '""',
          position: 'absolute',
          bottom: 43,
          left: 0,
          display: 'block',
          width: 200,
          height: 1,
          background: theme.palette.grey[300],
        },

      '& li[role="menuitem"]': {
        width: 184,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 35,
        borderRadius: 8,
        // margin: theme.spacing(0.5, 0),
      },

      '& li[role="menuitem"]:first-child': {
        marginBottom: theme.spacing(0.5),
      },
      '& li[role="menuitem"]:nth-type-of(2)': {
        marginBottom: theme.spacing(0.25),
      },
      '& li[role="menuitem"]:last-child': {
        margin: theme.spacing(0.5, 0),
      },
    },
    menuItem: {
      // marginTop: theme.spacing(1, 2),
    },
  })
);

interface MenuItemResendPasswordViewProps {
  currentUser: User;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setToBeUpdatedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  resendTemporaryPassword: (input: UserIdsInBatch) => Promise<void>;
}

export const MenuItemResendPasswordView = (
  props: MenuItemResendPasswordViewProps
) => {
  const classes = useMoreOptionsMenuStyles();

  const handleOption = async (
    event: React.MouseEvent<HTMLElement>,
    reason: string
  ) => {
    // select options
    event.stopPropagation();

    if (reason === AccountOptionType.RESEND_PASSWORD) {
      // call api to resend password
      await props.resendTemporaryPassword({
        userIdsInBatch: [
          {
            userId: props.currentUser.userId,
          },
        ],
      });
      props.setToBeUpdatedUsers([props.currentUser]);
    }

    props.setAnchorEl(null);
  };

  return (
    <MenuItem
      onClick={(e) => handleOption(e, AccountOptionType.RESEND_PASSWORD)}
      disableRipple
      className={classes.menuItem}
    >
      <span>Resend Password</span>
      <ResendPasswordIcon style={{ height: 24, width: 24 }} />
    </MenuItem>
  );
};
