import { Grid } from '@material-ui/core';
import React from 'react';
import { MainRegion } from '../../components/MainRegion';
import { HomePageHeader } from '../../components/HomePageHeader';
import { useMyProfileTabsStyles } from '../MyProfile/Home/Styles';
import { SettingOptionTabs } from './components/SettingOptionTabs/SettingOptionTabs';
import { SMSTemplateView } from './SMSTemplates/View';

interface MyProfileHomeViewProps {
  selectedTab: number;
}

export const SettingHomeView = (props: MyProfileHomeViewProps) => {
  const { selectedTab } = props;
  const classes = useMyProfileTabsStyles();

  const [value, setValue] = React.useState(selectedTab);

  React.useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    // setValue(newValue);
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `profile-option-tabs-${index}`,
    };
  }

  return (
    <MainRegion
      header={
        <HomePageHeader
          headerTitle={`Settings`}
          headerActionElement={<div id="create-button-container"></div>}
          privilege={{
            checkPrivilege: false,
            neededPrivileges: '',
          }}
        />
      }
    >
      <Grid item xs={12}>
        <Grid container spacing={8} className={classes.mainGrid}>
          {/* profile option tabs */}
          <Grid item xs={2} container>
            <SettingOptionTabs
              value={value}
              handleTabChange={handleTabChange}
              a11yProps={a11yProps}
            />
          </Grid>
          {/* spacing */}
          <Grid item xs={1} />

          {/* forms */}
          <Grid item xs={9}>
            {value === 0 && <SMSTemplateView />}
          </Grid>
        </Grid>
      </Grid>
    </MainRegion>
  );
};
