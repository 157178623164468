import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStickyTableStyle = makeStyles({
  customTableContainer: {
    overflowX: 'initial',
  },
});

export const useTabBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .MuiTabs-root': {
        borderBottom: 'none',
      },
    },
    tabBar: {
      backgroundColor: theme.palette.background.default,
      color: 'black',
      minHeight: 44,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderBottom: '1px solid #d1d1d1',
      // borderTop: '1px solid #d1d1d1',
    },
    indicator: {
      backgroundColor: '#0C77D8!important',
      height: '3px!important',
    },
    deactiveTab: {
      color: theme.palette.grey[600],
    },
    activeTab: {
      color: theme.palette.info.main,
    },
    tabText: {
      height: '24px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: 'Manrope',
      textTransform: 'capitalize',
    },
    tabNum: {
      display: 'inline-block',
      borderRadius: 100,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 0.625),
    },
    activeNum: {
      color: '#FFF!important',
      backgroundColor: theme.palette.info.main,
    },
    deactiveNum: {
      color: theme.palette.grey[500],
      backgroundColor: '#efefef',
    },
    tabPanel: {
      backgroundColor: theme.palette.grey[100],
    },
  })
);

export const useStickyTabBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .MuiTabs-root': {
        borderBottom: 'none',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    tabBar: {
      backgroundColor: theme.palette.background.default,
      color: 'black',
      minHeight: 44,
      borderBottom: '1px solid #d1d1d1',
      position: 'absolute',
      top: '0',
      width: '100%',
      height: 48,
      // borderTop: '1px solid #d1d1d1',
    },
    indicator: {
      backgroundColor: '#0C77D8!important',
      height: '3px!important',
    },
    deactiveTab: {
      color: theme.palette.grey[600],
    },
    activeTab: {
      color: theme.palette.info.main,
    },
    tabText: {
      height: '24px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: 'Manrope',
      textTransform: 'capitalize',
    },
    tabNum: {
      display: 'inline-block',
      borderRadius: 100,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 0.625),
    },
    activeNum: {
      color: '#FFF!important',
      backgroundColor: theme.palette.info.main,
    },
    deactiveNum: {
      color: theme.palette.grey[500],
      backgroundColor: '#efefef',
    },
    tabPanel: {
      backgroundColor: theme.palette.grey[100],
      top: 49,
      position: 'absolute',
      width: '100%',
      overflowY: 'scroll',
      bottom: 0,
    },
  })
);

export const useTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  indicator: {
    backgroundColor: '#0C77D8!important',
    height: '3px!important',
    borderRadius: 1.5,
  },
}));

export const useStickyTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
  },
  indicator: {
    backgroundColor: '#0C77D8!important',
    height: '3px!important',
    borderRadius: 1.5,
  },
}));

export const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '6px 0px 6px 0px!important',
    marginRight: 24,
    '&:hover': {
      '& .MuiTab-label': {
        color: theme.palette.primary.main,
      },
    },
    '&$selected': {
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
      },
    },
    minWidth: 60,
  },
  selected: {},
  wrapper: {
    textAlign: 'center',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['600'],
  },
}));
