import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { ChangeEvent } from 'react';
import { NotRegisterIcon } from '../../../../../../assets/Icons/FileUpload/NotRegisterIcon';
import { ChartIcon } from '../../../../../../assets/Icons/PanelsList';
import { ProfileItem } from '../../../../../../components/ProfileItem';
import { theme } from '../../../../../../themes/theme';
import { EmptyTabContent } from '../../../../components/EmptyTabContent';
import { usePanelPatientsTableViewStyles } from '../AssignedPatientsTable/Styles';
import { useUnassignedPatientViewStyles } from './Styles';
import { EmptyPlaceHolder } from '../../../../../../components/EmptyPlaceHolder';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { CoordinationStatusChip } from '../../../../../../components/CoordinationStatusChip';
import { SubscriberIDCell } from '../../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTable/components/SubscriberIDCell';
import { StyledCheckbox } from '../../../../../../components/StyledCheckbox';
import { compareObjectsPipe } from '../../../../../../sharePipe/compareObjectsPipe';
import { EligibilityBadge } from '../../../../../../components/EligibilityBadge';
import { useFeature } from '../../../../../../components/FeatureFlag';

// table
interface UnassignedPatientsTableViewProps {
  panelId: string;
  title: string;
  patients: any;
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient: any
  ) => any;
  unassignedPatientCount: number;
  setShowAssignCoordinatorDialog?: (input: boolean) => void;
  setShowManageCoordinatorDialog?: (input: boolean) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  refetchDataFunc?: any;
  setResults: (input: any) => void;
  setValue: (input: any) => void;
  setSelected: any;
  selected: any[];
  allSelected: any;
  setAllSelected: any;
  excluded: any[];
  setExcluded: any;
  setActionsFooter: React.Dispatch<React.SetStateAction<any[]>>;
}

export const UnassignedPatientsTableView = (
  props: UnassignedPatientsTableViewProps
) => {
  const classes = useUnassignedPatientViewStyles();

  const showContentComponent = (content: any[]) => {
    if (content.length === 0) {
      return (
        <EmptyTabContent
          hintIcon={<ChartIcon className={classes.hintIcon} />}
          hintTitle={'No unassigned patients'}
          hintDesc={
            <div>
              <Typography>
                Congratulations! All patients in this panel are assigned.
              </Typography>
              <Typography>
                Click the button below to view all patients.
              </Typography>
            </div>
          }
          btnUrl={`/panels/${props.panelId}/assigned`}
          btnText={'View Assigned Patients'}
        />
      );
    } else {
      return <UnassignedPatientsTableContent {...props} />;
    }
  };

  return (
    <div role="UnassignedPatientsTableView">
      {showContentComponent(props.patients)}
    </div>
  );
};

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  defaultDirection?: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'eligibilityStatuses',
    numeric: false,
    disablePadding: false,
    label: 'Eligibility',
  },
  { id: 'dateOfBirth', numeric: false, disablePadding: false, label: 'DOB' },
  { id: 'gender', numeric: false, disablePadding: false, label: 'Gender' },
  {
    id: 'subscriberIDs',
    numeric: false,
    disablePadding: false,
    label: 'Subscriber ID',
  },
  {
    id: 'lastDos',
    numeric: false,
    disablePadding: false,
    label: 'Last Service Date',
  },
  {
    id: 'coveringPCPFullName',
    numeric: false,
    disablePadding: false,
    label: 'Covering Provider',
  },
  {
    id: 'caregapCount',
    numeric: false,
    disablePadding: false,
    label: 'Open Care Gaps',
    defaultDirection: true,
  },
  {
    id: 'careCoordinationStatus',
    numeric: false,
    disablePadding: false,
    label: 'Coordination Status',
  },
  {
    id: 'mostRecentDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Most Recent Due Date',
  },
];

const nonsortableHeads = ['subscriberIDs', 'eligibilityStatuses'];

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof usePanelPatientsTableViewStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: any,
    defaultDirection?: boolean
  ) => void;
  order: Order;
  orderBy: string;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  allSelected: any;
  excluded: any[];
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
  } = props;
  const createSortHandler = (headCell: HeadCell) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, headCell.id, headCell.defaultDirection);
  };

  const getDirection = (headCell: HeadCell) => {
    if (orderBy === headCell.id) {
      return order;
    } else {
      return headCell.defaultDirection ? 'desc' : 'asc';
    }
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={getDirection(headCell)}
          onClick={createSortHandler(headCell)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  const hasFeature = useFeature('eligibilityStatusBadgeColumnInPatientTable');
  const useFeatureCoveringProvider = useFeature('coordination');
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <StyledCheckbox
            indeterminate={
              (numSelected > 0 && !props.allSelected) ||
              (props.allSelected && props.excluded.length > 0)
            }
            checked={props.allSelected && props.excluded.length == 0}
            checkedAll={props.allSelected && props.excluded.length == 0}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all patients' }}
            className={classes.checkbox}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.id === 'eligibilityStatuses') {
            if (!hasFeature) return null;
          } else if (headCell.id == 'coveringPCPFullName') {
            if (!useFeatureCoveringProvider) return null;
          }

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {pickSortingHeaders(headCell)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableRowProps {
  row: any;
  labelId: string;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient: any
  ) => any;
  setValue: (input: any) => void;
  selected: any[];
  isItemSelected: boolean;
  setSelected: (input: any[]) => void;
  allSelected: boolean;
  excluded: any[];
  setExcluded: (input: any[]) => void;
}

function EnhancedTableRow(props: EnhancedTableRowProps) {
  const {
    row,
    labelId,
    isItemSelected,
    selected,
    setSelected,
    excluded,
    setValue,
  } = props;

  const classes = usePanelPatientsTableViewStyles();
  const avatarPalette = theme.palette.avatars;
  const colors = Object.values(avatarPalette);

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.indexOf(row);
    if (!props.allSelected) {
      let newSelected: any[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    } else {
      const selectedIndex = excluded.indexOf(row);
      let newExcluded: any[] = [];
      if (selectedIndex === -1) {
        newExcluded = newExcluded.concat(excluded, row);
      } else if (selectedIndex === 0) {
        newExcluded = newExcluded.concat(excluded.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newExcluded = newExcluded.concat(excluded.slice(0, -1));
      } else if (selectedIndex > 0) {
        newExcluded = newExcluded.concat(
          excluded.slice(0, selectedIndex),
          excluded.slice(selectedIndex + 1)
        );
      }

      props.setExcluded(newExcluded);
    }
  };

  const getOpenCareGapChipEle = (row: any) => {
    // measureCount === 0 => No Care Gaps , measureCount > 0 && caregapCount === 0 => All Gaps Closed
    let isNoCareGaps = row.measureCount === 0;
    let isAllGapsClosed = row.measureCount > 0 && row.caregapCount === 0;

    let labelText =
      (isNoCareGaps && 'No Care Gaps') ||
      (isAllGapsClosed && 'All Gaps Closed') ||
      `${row.caregapCount} Open Gaps`;

    let iconEle =
      isNoCareGaps || isAllGapsClosed ? undefined : (
        <NotRegisterIcon className={classes.warningIcon} />
      );
    let className =
      isNoCareGaps || isAllGapsClosed ? classes.closedGap : classes.openGap;

    return (
      <Chip
        icon={iconEle}
        label={labelText}
        className={className}
        onClick={(event) => {
          props.setValue(2);
          props.drawerHandler(event, row);
        }}
      />
    );
  };
  const hasFeature = useFeature('eligibilityStatusBadgeColumnInPatientTable');
  const useFeatureCoveringProvider = useFeature('coordination');

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={(event) => {
        props.setValue(0);
        props.drawerHandler(event, row);
      }}
    >
      {/* checkbox column */}
      <TableCell
        padding="checkbox"
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event, row);
        }}
      >
        <StyledCheckbox
          checkedAll={false}
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          className={classes.checkbox}
        />
      </TableCell>
      {/* fullName column */}
      <TableCell align="left">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}>
          {(row.id && row.id.toString().length > 10 && row.id.toString().startsWith('9000')) ? (
              <span style={{
                color: '#008479',
                backgroundColor: '#E8F6F2',
                fontSize: 10,
                padding: '2px 2px',
                fontWeight: 600,
                position: 'absolute',
                left: -32,
                top: 0,
              }}>
            NEW
          </span>
          ) : null}
          {`${row.lastName}, ${row.firstName}`}
        </div>
        </TableCell>
      {hasFeature && (
        <TableCell align="left">
          {row.eligibilityInfo && row.eligibilityInfo?.activeStatus ? (
            <EligibilityBadge patientInfo={row} pageToDisplay="PatientTable" />
          ) : (
            <EmptyPlaceHolder text={'No Data'} />
          )}
        </TableCell>
      )}
      <TableCell align="left">
        {row.dateOfBirth || <EmptyPlaceHolder text={'No DOB'} />}
      </TableCell>
      <TableCell align="left">
        {row.gender || <EmptyPlaceHolder text={'No Gender'} />}
      </TableCell>
      <TableCell align="left">
        <SubscriberIDCell subscriberIDs={row.subscriberIDs} />
      </TableCell>
      <TableCell align="left">
        {row.lastDos ? row.lastDos : <EmptyPlaceHolder text={'No DOS'} />}
      </TableCell>
      {useFeatureCoveringProvider && (
        <TableCell align="left">
          {row.coveringPCPFirstName && row.coveringPCPLastName ? (
            <span className={classes.pcp}>
              <ProfileItem
                avatarBgColor={colors[0]}
                firstName={row.coveringPCPLastName + ','}
                lastName={row.coveringPCPFirstName}
              />
            </span>
          ) : (
            <EmptyPlaceHolder text={'No Coverby'} />
          )}
        </TableCell>
      )}
      <TableCell align="left">{getOpenCareGapChipEle(row)}</TableCell>
      <TableCell>
        <CoordinationStatusChip patientInfo={row} location="PatientTable" />
      </TableCell>
      <TableCell align="left">
        {row.mostRecentDueDate ? (
          row.mostRecentDueDate
        ) : (
          <EmptyPlaceHolder text={'No Date'} />
        )}
      </TableCell>
    </TableRow>
  );
}

// table head

const UnassignedPatientsTableContent = (
  props: UnassignedPatientsTableViewProps
) => {
  const { patients, setSelected, selected, setActionsFooter } = props;
  const classes = usePanelPatientsTableViewStyles();
  const [isSorting, setIsSorting] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<any>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<any>(props.sortBy.field);

  React.useEffect(() => {
    setFilteredRows(patients);
    props.setResults(patients);

    setIsSorting(false);
  }, [patients]);

  React.useEffect(() => {
    setActionsFooter(selected);
  }, [selected, setActionsFooter]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setExcluded([]);
    if (event.target.checked) {
      props.setAllSelected(true);
      setSelected(filteredRows);
      return;
    }
    props.setAllSelected(false);
    setSelected([]);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
    defaultDirection?: boolean
  ) => {
    setIsSorting(true);
    const isAsc = orderBy === property && order === 'asc';
    if (defaultDirection) {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
  };
  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  const isSelected = (id: any) => selected.find((patient) => patient.id === id);
  const isExcluded = (id: any) =>
    props.excluded.find((patient) => patient.id === id);

  return (
    <div>
      <Paper>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer classes={{ root: classes.tableContainer }}>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              {/* table header */}
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={
                  compareObjectsPipe(filteredRows, selected, 'id').length
                }
                allSelected={props.allSelected}
                excluded={props.excluded}
              />
              {/*tables rows */}
              <TableBody>
                {filteredRows.length > 0 &&
                  filteredRows
                    .slice(page * props.limit, page * props.limit + props.limit)
                    .map((row: any, index: number) => {
                      return (
                        <EnhancedTableRow
                          key={`my-patients-table-row-${index}`}
                          row={row}
                          labelId={`my-patients-table-checkbox-${index}`}
                          selected={selected}
                          isItemSelected={
                            isExcluded(row.id)
                              ? false
                              : !!(props.allSelected || isSelected(row.id))
                          }
                          setSelected={setSelected}
                          allSelected={props.allSelected}
                          excluded={props.excluded}
                          setExcluded={props.setExcluded}
                          drawerHandler={props.drawerHandler}
                          setValue={props.setValue}
                        />
                      );
                    })}
              </TableBody>
            </Table>
            <Pagination
              limit={props.limit}
              skip={props.skip}
              setLimit={props.setLimit}
              setSkip={props.setSkip}
              unassignedPatientCount={props.unassignedPatientCount}
              setSortBy={props.setSortBy}
              order={order}
              orderBy={orderBy}
            />
          </TableContainer>
        </div>
      </Paper>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  unassignedPatientCount: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { unassignedPatientCount, limit, skip } = props;

  const classes = usePanelPatientsTableViewStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={unassignedPatientCount}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
