// component of popup dialog
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  styled,
} from '@material-ui/core';
// import { ExportPanelFormat } from '../../../../app.types.generated';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { theme } from '../../../../themes/theme';
import {
  CloseIcon,
  FileDownloadIcon,
} from '../../../../assets/Icons/ExportPatients';
import Typography from '@material-ui/core/Typography';
import ExportDone from '../../../../assets/ExportPatients/Export-Done-Icon@2x.png';
import { GlobalContext } from '../../../../components/GlobalContext';
import { useGetNewPatientsExportLinkQuery } from './index.generated';

const useStyles = makeStyles((theme) => ({
  smallPrimarybtn: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '83px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '& .MuiButton-label .MuiSvgIcon-root': {
      fontSize: '14px',
      padding: theme.spacing(0),
      marginLeft: theme.spacing(-1),
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
    },
  },

  dialogTitle: {
    margin: theme.spacing(0),
    width: '198px',
    height: '32px',
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0px',
    lineHeight: '32px',
    padding: theme.spacing(2.5, 'auto', 2.5, 3),
  },

  dialogContentRegion: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3.5),
    },

    '& .MuiTypography-root': {
      height: '24px',
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: '24px',
      fontSize: '16px',
    },

    '& .MuiTypography-root span': {
      display: 'block',
      height: '40px',
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '40px',
      marginBottom: theme.spacing(2.25),
    },

    '& img': {
      height: '62px',
      width: '96px',
      marginTop: theme.spacing(10),
    },

    '& a': {
      display: 'block',
      width: '140px',
      height: '48px',
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      letterSpacing: 0,
      lineHeight: '48px',
      background: theme.palette.primary.main,
      borderRadius: '5px',
      marginTop: theme.spacing(3.9375),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '640px',
    height: '468px',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle {...other} className={classes.dialogTitle}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '24px',
            color: theme.palette.text.hint,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

interface ContentRegionProps {
  className?: string;
  fileId: string;
  format: string;
  setOpenDialog: (input: any) => void;
}

const ContentRegion = (props: ContentRegionProps) => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const aElement = React.useRef<HTMLAnchorElement>(null);

  const exportNewPatientsLinkQuery = useGetNewPatientsExportLinkQuery({
    variables: {
      request: {
        fileFormat: props.format,
        fileId: props.fileId,
        skip: 0,
        limit: 0,
        sortBy: [{ field: 'lastUpdate', method: 'desc' }],
      },
    },
  });

  const getLinks = (query: any | undefined) => {
    return query?.getNewPatientsExportLink?.link;
  };

  const exportLink = React.useMemo(() => {
    if (exportNewPatientsLinkQuery.error) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something went wrong, please try again',
      });
      setIsOpenToast(true);
      props.setOpenDialog(false);
      return '';
    }
    return getLinks(exportNewPatientsLinkQuery.data);
  }, [exportNewPatientsLinkQuery]);

  React.useEffect(() => {
    const timer = setTimeout(() => aElement.current?.click(), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [exportLink, aElement]);

  if (!exportLink) {
    return (
      <div className={props.className}>
        <Box>
          <CircularProgress disableShrink thickness={4} />
        </Box>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 18,
          }}
        >
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            Exporting…
          </Typography>
          <Typography
            style={{
              color: theme.palette.grey[600],
              fontSize: 16,
            }}
          >
            Please wait while file is being prepared for download.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={props.className}>
      {/* <img src={ExportDone} alt="Export Done" /> */}
      <FileDownloadIcon
        style={{
          width: 35,
          height: 43,
          color: `${theme.palette.primary.main}`,
        }}
      />

      <Typography
        style={{
          height: '48px',
          width: '320px',
          marginTop: '32px',
          color: theme.palette.grey[600],
          fontSize: 16,
        }}
      >
        Your download will start in a few seconds. Click button below to
        download manually.
      </Typography>

      <a
        href={exportLink}
        download
        ref={aElement}
      >{`Download .${props.format}`}</a>
    </div>
  );
};

interface EmailContentRegionProps {
  className?: string;
}

const EmailContentRegion = (props: EmailContentRegionProps) => {
  return (
    <div className={props.className}>
      <img src={ExportDone} alt="Export Done" />

      <Typography
        style={{
          height: '48px',
          width: '320px',
          marginTop: '32px',
          whiteSpace: 'nowrap',
        }}
      >
        Your Email app will be open in a few seconds.
        <br />
        Click button below to send Email manually.
      </Typography>

      <a href={''}>Open Email App</a>
    </div>
  );
};

interface ExportDialogProps {
  fileId: string;
  formatText: string;
  openDialog: boolean;
  setOpenDialog: (input: boolean) => any;
  dialogTitle: string;
}

export const ExportInsurancesPatientsDialog = (props: ExportDialogProps) => {
  const { openDialog, setOpenDialog, formatText, fileId, dialogTitle } = props;

  const classes = useStyles();

  const handleClose = () => {
    // close dialog
    setOpenDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {dialogTitle}
        </BootstrapDialogTitle>

        <DialogContent>
          {/* {formatText === 'xls' && (
            <ContentRegion
              format={ExportPanelFormat.XLSX}
              insuranceId={insuranceId}
              rosterId={rosterId}
              className={classes.dialogContentRegion}
            />
          )}
          {formatText === 'csv' && (
            <ContentRegion
              format={ExportPanelFormat.CSV}
              insuranceId={insuranceId}
              rosterId={rosterId}
              className={classes.dialogContentRegion}
            />
          )} */}
          {/* {formatText == 'email' && (
              <EmailContentRegion className={classes.dialogContentRegion} />
            )} */}

          <ContentRegion
            format={formatText}
            fileId={fileId}
            className={classes.dialogContentRegion}
            setOpenDialog={setOpenDialog}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
