import React, { ChangeEvent } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  Chip,
  Fade,
  fade,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  tableUseStyles,
  tableUseToolbarStyles,
} from '../../../../../Insurances/InsuranceListingPage/InsuranceListTable/TableStyle';
import { SearchIcon } from '../../../../../../assets/Icons/PanelsList/SearchIcon';
import { FiltersIcon } from '../../../../../../assets/Icons/Eligibility/FiltersIcon';
import { NotRegisterIcon } from '../../../../../../assets/Icons/FileUpload/NotRegisterIcon';
import { ErrorRecordIcon } from '../../../../../../assets/Icons/FileUpload/ErrorRecordIcon';
import { RosterFileStatus } from '../../../../../Insurances/components/SharedDataType/SharedDataType';
import { theme } from '../../../../../../themes/theme';
import { formatNumberPipe } from '../../../../../../sharePipe/formatNumberPipe';
import { dateFormatPipe } from '../../../../../../sharePipe/dateFormatPipe';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { UnknownIdDialog } from '../../../components/dialogs/UnknownIdDialog';
import { UnCareGapDialog } from '../../../components/dialogs/UnknownCareGapDialog';
import { ExportErrorFilesDialog } from '../../../../../Insurances/components/ExportErrorFilesDialog/ExportErrorFilesDialog';
import { FileDownloadIcon } from '../../../../../../assets/Icons/ExportPatients';

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: '#434343',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '7px 10px 7px 8px',
  },
  popper: {
    // top: '110px !important',
    // left: '-50px !important',
    top: '110px !important',
    left: '130px !important',
  },
})(Tooltip);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(0.5), // -6.5 1.5
    width: 164,
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      borderRadius: 8,
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root:hover svg': {
      color: theme.palette.grey[600],
    },
    '& .MuiListItem-root:hover span': {
      color: theme.palette.grey[700],
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      width: '87px',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

function descendingComparator(a: any, b: any, orderBy: keyof any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  align: 'left' | 'right';
}

const headCells: HeadCell[] = [
  {
    id: 'fileName',
    align: 'left',
    disablePadding: false,
    label: 'File Name',
  },

  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'File Status',
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'File Type',
  },

  {
    id: 'filePatients',
    align: 'left',
    disablePadding: false,
    label: 'Patients',
  },
  {
    id: 'newPatients',
    align: 'left',
    disablePadding: false,
    label: 'New Patients',
  },
  {
    id: 'processedTime',
    align: 'left',
    disablePadding: false,
    label: 'Processed Date',
  },
];

const nonsortableHeads = ['type', 'status', 'filePatients'];

const toolTipStyles = makeStyles(() => ({
  emptySpace: {
    marginRight: 13,
  },
  confirmedCircle: {
    borderRadius: '50%',
    width: 7,
    height: 7,
    background: '#49BFA9',
    display: 'inline-block',
    marginRight: 6,
  },
  deduplicatedCircle: {
    borderRadius: '50%',
    width: 7,
    height: 7,
    background: '#F5CA32',
    display: 'inline-block',
    marginRight: 6,
  },
  pendingCircle: {
    borderRadius: '50%',
    width: 7,
    height: 7,
    background: '#1B8CF3',
    display: 'inline-block',
    marginRight: 6,
  },
}));

const useStyles = makeStyles(() => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  tableTitle?: string;
  //searchbar
  enableSearch?: boolean;
  searchPlaceHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  searchEnableFilter?: boolean;
  renderButton?: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = tableUseToolbarStyles();
  const altClasses = useStyles();
  return (
    <Paper
      className={`${classes.tableToolBarContainer} ${altClasses.tableToolBarContainer}`}
      elevation={0}
    >
      {props.tableTitle && (
        <div className={`${classes.header} ${altClasses.header}`}>
          {/* title */}
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {props.tableTitle}
          </Typography>
          {props.renderButton && (
            <div className={classes.headerButton}>{props.renderButton}</div>
          )}
        </div>
      )}
      {/* SearchBanner */}
      {props.enableSearch && (
        <div className={classes.searchBanner}>
          <SearchBanner
            placeHolder={props.searchPlaceHolder}
            searchFunction={props.searchFunction}
          />
        </div>
      )}
    </Paper>
  );
};

interface SearchBannerProps {
  placeHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  enableFilter?: boolean;
}

const SearchBanner = (props: SearchBannerProps) => {
  const classes = tableUseToolbarStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={props.placeHolder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
      {props.enableFilter && (
        <Button className={classes.filtersBtn}>
          <FiltersIcon className={classes.filtersIcon} />
          <Typography variant="body1" className={classes.filtersText}>
            Filters
          </Typography>
        </Button>
      )}
    </div>
  );
};

interface ItemListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  results: any;
  count: number;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  id: any;
}

export const PayerPayerFileListingTableView = (
  props: ItemListTableViewProps
) => {
  const classes = tableUseStyles();
  const altClasses = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const toolTipsClasses = toolTipStyles();
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.limit ?? 10);
  const [selectedCareGapFile, setSelectedCareGapFile] = React.useState<any>(
    null
  );
  const [selectedPayerFile, setSelectedPayerFile] = React.useState<any>(null);

  const [isSorting, setIsSorting] = React.useState(false);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          request: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  React.useEffect(() => {
    setFilteredRows(props.results);

    setIsSorting(false);
  }, [props.results]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /*
                to check if current file is in bold :
                - must be unread file and
                  - status === complete and having new patients
                  - status === error no mater the new patient count
                */
  function isBold(file: any) {
    return file.isRead === false;
  }

  // to get status icon according to different status
  function getStatusIcon(status: string) {
    switch (status) {
      case 'unknown': {
        return <NotRegisterIcon className={classes.unknownIcon} />;
      }
      case RosterFileStatus.error: {
        return <ErrorRecordIcon className={classes.warningIcon} />;
      }
      default: {
        return <></>;
      }
    }
  }

  // to get stylings according to different status
  function getStatusClass(status: string) {
    switch (status) {
      case 'caregap': {
        return classes.careGapErrorsChips;
      }
      case RosterFileStatus.scheduled: {
        return classes.scheduledChips;
      }
      case RosterFileStatus.processing: {
        return classes.processingChips;
      }
      case RosterFileStatus.newPatients: {
        return classes.newPatientsChips;
      }
      case RosterFileStatus.error: {
        return classes.openGap;
      }
      default: {
        return classes.completeChips;
      }
    }
  }

  const getFileType = (row: any) => {
    const type = row?.fileType ?? '';
    if (type == 'SUSPECT_CONDITION') {
      return 'Suspect Conditions';
    }
    if (type == 'ROSTER_FILE') {
      return 'Roster';
    }
    if (type == 'PAYER_CARE_GAP_FILE') {
      return 'Care Gap';
    }
    if (type == 'ECW_ROSTER') {
      return 'ECW Roster';
    }
    if (type == 'ECW_CARE_GAP') {
      return 'ECW Care gap';
    }
    return type;
  };
  const getNewPatientColumn = (row: any) => {
    if (row.newPatientStatus == 'Pending') {
      return (
        <StyledTooltip
          placement="top"
          disableFocusListener
          disableTouchListener
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
              <div>
                <div>
                  <span className={toolTipsClasses.emptySpace} />
                  {row.confirmedNewPatient + row.duplicated + row.pending} Total
                  New Patients
                </div>
                <div>
                  <span className={toolTipsClasses.confirmedCircle} />
                  {row.confirmedNewPatient} Confirmed New Patient
                </div>
                <div>
                  <span className={toolTipsClasses.deduplicatedCircle} />
                  {row.duplicated} Deduplicated
                </div>
                <div>
                  <span className={toolTipsClasses.pendingCircle} />
                  {row.pending} Pending
                </div>
              </div>
            </React.Fragment>
          }
        >
          <Chip
            onClick={(event: any) => {
              handleNewPatientColumnClick(event, row);
            }}
            label={'Pending'}
            className={classes.processingChips}
          />
        </StyledTooltip>
      );
    }
    //todo
    else if (
      row.fileType == 'SUSPECT_CONDITION' ||
      row.fileType == 'ECW_ROSTER' ||
      row.fileType == 'ECW_CARE_GAP'
    ) {
      return <span className={classes.newPatientTextNone}>{'N/A'}</span>;
    } else if (row.newPatientStatus == 'Fail') {
      return (
        <Tooltip
          title={'Please reach out to MDLand Customer support for assistance'}
          placement="right-end"
        >
          <Chip label={`Fail to send to iClinic`} className={classes.openGap} />
        </Tooltip>
      );
    } else {
      return formatNumberPipe(row.newPatients, '-') === '-' ? (
        '-'
      ) : row.newPatients > 0 ? (
        <span
          className={classes.newPatientText}
          style={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
          }}
          onClick={(event: any) => {
            handleNewPatientColumnClick(event, row);
          }}
        >
          {formatNumberPipe(row.newPatients, '-')}
        </span>
      ) : (
        <span className={classes.newPatientTextNone}>
          {formatNumberPipe(row.newPatients, '-')}
        </span>
      );
    }
  };

  const [checkedErrorFile, setCheckedErrorFile] = React.useState({
    fileId: '',
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [exportOption, setExportOption] = React.useState('');

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openExportDialog = (format: string) => {
    setExportOption(format);
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleErrorFileClick = (event: any, file: any) => {
    // show download menu
    setAnchorEl(event.currentTarget);
    setCheckedErrorFile({
      fileId: file.fileId,
    });
    event.stopPropagation();
    event.preventDefault();
  };

  const handleNewPatientColumnClick = (event: any, row: any) => {
    if (row?.newPatients > 0) {
      navigate(`/payerId/${props.id}/${row.fileId}/newPatient`, {
        state: { name: location?.state?.payerName },
      });
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const handleTableRowClick = (event: any, type: any, value: any, row: any) => {
    if (type == 'chip') {
      if (value == 'Unknown Payer ID(s)') {
        setSelectedPayerFile(row);
      } else {
        setSelectedCareGapFile(row);
      }
      event.stopPropagation();
      event.preventDefault();
    } else {
      if (
        row.fileType == 'SUSPECT_CONDITION' ||
        row.fileType == 'ECW_ROSTER' ||
        row.fileType == 'ECW_CARE_GAP'
      ) {
        event.stopPropagation();
        event.preventDefault();
      } else if (row.fileType == 'ROSTER_FILE') {
        if (row?.newPatients > 0) {
          navigate(`/payerId/${props.id}/${row.fileId}`, {
            state: { name: location?.state?.payerName },
          });
          event.stopPropagation();
          event.preventDefault();
        }
      } else {
        navigate(`/payerId/${props.id}/${row.fileId}/careGap`, {
          state: { payerId: props.id, name: location?.state?.payerName ?? '' },
        });
      }
    }
  };

  // @ts-ignore
  return (
    <div className={`${classes.root} ${altClasses.root}`}>
      <Paper className={`${classes.paper} ${altClasses.paper}`} elevation={1}>
        <div
          className={`${classes.lightBackground} ${altClasses.lightBackground}`}
        >
          <EnhancedTableToolbar
            tableTitle={`Files`}
            //search bar
            enableSearch={false}
            searchPlaceHolder={'Search'}
            searchFunction={() => {}}
          />
        </div>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {selectedPayerFile != null && (
                  <UnknownIdDialog
                    showDialog={true}
                    setShowDialog={setSelectedPayerFile}
                    unknownPayers={selectedPayerFile.unknownPayers ?? []}
                  />
                )}
                {selectedCareGapFile != null && (
                  <UnCareGapDialog
                    showDialog={true}
                    setShowDialog={setSelectedCareGapFile}
                    unknownCaregaps={selectedCareGapFile.unknownCaregaps ?? []}
                  />
                )}
                {filteredRows &&
                  stableSort(filteredRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={`${index}`}
                          style={{ height: row?.errors?.length > 1 ? 80 : 56 }}
                          className={
                            (row.fileType == 'ROSTER_FILE' &&
                              row.newPatients < 1) ||
                            row.fileType == 'SUSPECT_CONDITION'
                              ? classes.nonHoverRow
                              : classes.hoverRow
                          }
                          onClick={(event) => {
                            handleTableRowClick(event, 'row', null, row);
                          }}
                        >
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {row.fileName?.length > 25 ? (
                              <Tooltip title={row.fileName} placement="bottom">
                                <div className={classes.filename}>
                                  <span className={classes.filenameName}>
                                    {row.fileName
                                      .split('.')
                                      .slice(0, -1)
                                      .join('.')}
                                  </span>
                                  <span>
                                    .
                                    {row.fileName?.substring(
                                      row.fileName?.lastIndexOf('.') + 1
                                    ) ?? ''}
                                  </span>
                                </div>
                              </Tooltip>
                            ) : (
                              <div className={classes.filename}>
                                <span className={classes.filenameName}>
                                  {row.fileName
                                    .split('.')
                                    .slice(0, -1)
                                    .join('.')}
                                </span>
                                <span>
                                  .
                                  {row.fileName?.substring(
                                    row.fileName?.lastIndexOf('.') + 1
                                  ) ?? ''}
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              paddingBottom: row?.errors?.length > 1 ? 10 : 15,
                            }}
                            className={classes.chipsRow}
                          >
                            {/* error status */}
                            {row?.unknownCaregaps?.length >= 1 && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  rowGap: '5px',
                                }}
                              >
                                <Chip
                                  icon={getStatusIcon('unknown')}
                                  label={'Unknown Care Gap(s)'}
                                  className={getStatusClass('caregap')}
                                  onClick={(event) => {
                                    handleTableRowClick(
                                      event,
                                      'chip',
                                      'Unknown Care Gap(s)',
                                      row
                                    );
                                  }}
                                />
                              </div>
                            )}
                            {row?.unknownPayers?.length >= 1 && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  rowGap: '5px',
                                }}
                              >
                                <Chip
                                  icon={getStatusIcon('unknown')}
                                  label={'Unknown Payer ID(s)'}
                                  className={getStatusClass('caregap')}
                                  onClick={(event) => {
                                    handleTableRowClick(
                                      event,
                                      'chip',
                                      'Unknown Payer ID(s)',
                                      row
                                    );
                                  }}
                                />
                              </div>
                            )}
                            {row.status === RosterFileStatus.error && (
                              <Chip
                                icon={getStatusIcon(row.status)}
                                label={`${row.badRecordCount} Error Records`}
                                className={getStatusClass(row.status)}
                                onClick={(event) =>
                                  handleErrorFileClick(event, row)
                                }
                              />
                            )}
                            {/* complete status */}
                            {row?.unknownPayers?.length == 0 &&
                              row?.unknownCaregaps?.length == 0 &&
                              row.status === RosterFileStatus.complete && (
                                <Chip
                                  icon={getStatusIcon(row.status)}
                                  label={row.status}
                                  className={getStatusClass(row.status)}
                                />
                              )}
                            {/* no status */}
                            {!row.status && '-'}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {getFileType(row)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {formatNumberPipe(row.filePatients, 'No')}
                          </TableCell>
                          <TableCell align="left">
                            {getNewPatientColumn(row)}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={
                              isBold(row)
                                ? {
                                    color: theme.palette.grey[700],
                                    fontWeight: theme.typography.fontWeightBold,
                                  }
                                : {}
                            }
                          >
                            {dateFormatPipe(row.processedTime, '-', true, true)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>

      {checkedErrorFile.fileId && (
        <ExportErrorFilesDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formatText={exportOption}
          fileId={checkedErrorFile.fileId}
          dialogTitle={'Export Error List'}
        />
      )}
      <StyledMenu
        id="export-bad-records-menu"
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'export-patients-menu',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            openExportDialog('xls');
          }}
          disableRipple
        >
          <span style={{ display: 'block', width: 80 }}>Save as .xls</span>
          <FileDownloadIcon />
        </MenuItem>

        <MenuItem
          onClick={() => {
            openExportDialog('csv');
          }}
          disableRipple
        >
          <span style={{ display: 'block', width: 80 }}>Save as .csv</span>
          <FileDownloadIcon />
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: any;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = tableUseStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
