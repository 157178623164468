import React from 'react';
import { useGetClinicAndDivisionPairsByUserIdQuery } from '../EditUsers/index.generated';

export const useClinicDivisionPairs = (userId: string) => {
  const [pairs, setPairs] = React.useState<any>([]);
  const getClinicAndDivisionByUserIDQuery = useGetClinicAndDivisionPairsByUserIdQuery(
    {
      variables: {
        input: {
          userID: userId,
        },
      },
    }
  );

  React.useEffect(() => {
    if (
      getClinicAndDivisionByUserIDQuery?.data?.getClinicAndDivisionPairsByUserID
    ) {
      const list =
        getClinicAndDivisionByUserIDQuery?.data
          ?.getClinicAndDivisionPairsByUserID;
      const data = list
        .filter((item) => item.clinicID === '9000') // important: hard code for now to filter out the 9000 clinic only NPH-2643
        .map((item) => {
          return {
            clinicId: item.clinicID,
            divisions: item.divisions,
          };
        });
      // console.log('setSelectedClinicIdAndDivisionIdPairs', data);
      //   setNumberOfPairs(data.length);
      //   setSelectedClinicIdAndDivisionIdPairs(data);
      setPairs(data);
    }
  }, [
    getClinicAndDivisionByUserIDQuery?.data?.getClinicAndDivisionPairsByUserID,
  ]);

  return { pairs };
};
