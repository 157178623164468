import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AllUsersViewContainer } from './Container';

type Props = {
  setTotalNumUsers: (input: number) => void;
  actionsFooter: any[];
  setActionsFooter: any;
  isSearching: any;
  searchInput: any;
  searchName: any;
  setSearchName: any;
  isSearchingCheck: any;
  setIsSearchingCheck: any;
};

export const RoutedAllUsers = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const validateSearchInput: (input: string) => boolean = (
    searchInputString: string
  ) => {
    // valid searchName => XXX XXX or XXXX
    let removeLeadingTrailingSpace = searchInputString.trim();
    let netLength = removeLeadingTrailingSpace.replaceAll(' ', '');

    if (removeLeadingTrailingSpace.length === 0 || netLength.length === 0) {
      return false;
    }
    props.setSearchName(searchInputString);
    return true;
  };

  React.useEffect(() => {
    let searchOk = false;
    if (props.isSearching) {
      searchOk = validateSearchInput(props.searchInput);
      props.setIsSearchingCheck(props.isSearching && searchOk);
      if (searchOk && skip !== undefined) {
        onSetSkip(0);
      }
    }
  }, [props.searchInput, props.isSearching, skip]);

  return (
    <AllUsersViewContainer
      limit={limit}
      skip={skip}
      setLimit={onSetLimit}
      setSkip={onSetSkip}
      setTotalNumUsers={props.setTotalNumUsers}
      actionsFooter={props.actionsFooter}
      setActionsFooter={props.setActionsFooter}
      isSearching={props.isSearchingCheck === true ? true : false}
      searchName={props.searchName}
    />
  );
};
