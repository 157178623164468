import React, { useState } from 'react';
import { Step1ResetPw } from './components/Step1';
import { Step2ResetPw } from './components/Step2';

interface Props {
  setRenderForm: React.Dispatch<React.SetStateAction<string>>;
  setSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetPasswordForm = (props: Props) => {
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>('');

  switch (step) {
    case 1:
      return (
        <Step1ResetPw
          setEmail={setEmail}
          setRenderForm={props.setRenderForm}
          setStep={setStep}
        />
      );
    case 2:
      return (
        <Step2ResetPw
          email={email}
          setRenderForm={props.setRenderForm}
          setSnackbar={props.setSnackbar}
          setStep={setStep}
        />
      );
    default:
      return <div />;
  }
};
