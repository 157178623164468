import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { GroupByParent } from '../../../../../../components/CustomSelectWithLayer/GroupByParent';
import { GlobalContext } from '../../../../../../components/GlobalContext';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { SubMainRegion } from '../../../../../../components/SubMainRegion';
import {
  ageOptions,
  careGapStatusOptions,
  engagementStatusOptions,
  futureAppointmentOptions,
  genderOptions,
} from '../../../../../../enum/Panel/assignedPatientFilterOptions';
import { PatientFilterComponentIDs } from '../../../../../../enum/store';
import { useLanguageFilter } from '../../../../../../hooks/useLanguageFilter';
import { useMovePatients } from '../../../../../../hooks/useMovePatients';
import { sortArrayObjectPipe } from '../../../../../../sharePipe/sortArrayObjectPipe';
import { usePatientFilterStore } from '../../../../../../store/features';
import {
  GetCareGapFilterOptionsQuery,
  useGetCareGapFilterOptionsQuery,
} from '../../../../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { AllPatientFilters } from '../../../../PanelsHome/components/AllPatient/components/AllPatientFilters';
import { AllPatientHeaderContainer } from '../../../../PanelsHome/components/AllPatient/components/AllPatientHeader';
import { ExportMenu } from '../../../components/ExportFiles';
import { PanelSummaryContext } from '../../../PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { AssignedPatientsTable } from '../AssignedPatientsTable';
import { usePanelPatientsTableViewStyles } from '../AssignedPatientsTable/Styles';
import { CheckIsCoordinator } from '../../../../../../components/CheckIsAdmin';

interface AssignedPatientsTabContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  actionsFooter: any[];
  setActionsFooter: any;
  userId?: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  hardFresh: any;
  setAssignedPatientCount?: (input: any) => any;
  setMyAssignedPatientCount?: (input: any) => any;
  notEngagedCount?: number;
  nofutureApptCount?: number;
  careTeamUsers?: any;
  coordinators?: any;
  assignedPatientCount?: number;
}

export const AssignedPatientsTabContainer = (
  props: AssignedPatientsTabContainerProps
) => {
  const classes = usePanelPatientsTableViewStyles();

  const [openFilter, setOpenFilter] = React.useState(false);
  const [enableTable, setEnableTable] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);

  const [applyFilterBtnIsClicked, setApplyFilterBtnIsClicked] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(true);
  const [displayedCount, setDisplayedCount] = React.useState(0);
  // option care gap options
  const openCareGapOptionsQuery = useGetCareGapFilterOptionsQuery();

  const getOpenCareGapOptions = (
    query: GetCareGapFilterOptionsQuery | undefined
  ) => {
    return query?.getCareGapFilterOptions;
  };

  const openCareGapOptions = React.useMemo(
    () => getOpenCareGapOptions(openCareGapOptionsQuery?.data),
    [openCareGapOptionsQuery.data]
  );

  const { languages } = useLanguageFilter(openCareGapOptions);

  const assignedPatientsFilterOptions = {
    // using hard coding options for Gender, Age, EngagementStatus and FutureAppointment
    Gender: genderOptions,
    Age: ageOptions,
    EngagementStatus: engagementStatusOptions,
    FutureAppointment: futureAppointmentOptions,
    CareGap: openCareGapOptions?.CareGap || [],
    Division:
      sortArrayObjectPipe(openCareGapOptions?.Divisions || [], 'asc', 'name') ||
      [],
    Clinics:
      sortArrayObjectPipe(openCareGapOptions?.Clinics || [], 'asc', 'name') ||
      [],
    Languages: languages || [],
    Payers: openCareGapOptions?.Payers || [],
    LineOfBusiness: GroupByParent(
      sortArrayObjectPipe(
        openCareGapOptions?.LineOfBusiness || [],
        'asc',
        'name'
      ) || []
    ),
    CareGapStatus: careGapStatusOptions,
    CallAttemptCount: openCareGapOptions?.CallAttemptCount || [],
  };

  const { loggedInUser } = React.useContext(GlobalContext);
  const [patientTableQuery, setPatientTableQuery] = React.useState<any>();
  const [searchTextAndFilters, setSearchTextAndFilters] = React.useState<
    | {
        andName: string;
        andDob: string;
      }
    | undefined
  >(undefined);

  const [resultRows, setResultRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({
    field: 'careCoordinationStatusForAssignedPatients',
    method: 'asc' as const,
  });

  const [
    enableChangeCoordinatorDialog,
    setEnableChangeCoordinatorDialog,
  ] = React.useState(false);

  const patientFilterStore = usePatientFilterStore(
    PatientFilterComponentIDs.ASSIGNED_PATIENTS
  );
  const tmpFilters = patientFilterStore.formattedFiltersForQuery;

  const { polling, changeCoordinator, setPolling } = useMovePatients(
    props.panelId,
    props.allSelected,
    props.excluded,
    props.actionsFooter,
    // props.hardFresh,
    setEnableChangeCoordinatorDialog,
    setLoading,
    { ...searchTextAndFilters, ...tmpFilters }
  );
  const { setNeedToRefetch } = React.useContext(PanelSummaryContext);

  React.useEffect(() => {
    if (polling === false) {
      // props.hardFresh();
      // background refetch the panel data as patients have been moved to assigned patient list
      setNeedToRefetch(true);
      props.setSelected([]);
      setPolling(undefined);
    }
    if (polling === true) {
      props.setAllSelected(false);
      setOpenFilter(false);
    }

    props.setActionsFooter([]);
  }, [polling]);

  return (
    <SubMainRegion
      footer={props.actionsFooter.length > 0}
      header={
        props.assignedPatientCount === 0 ? null : (
          <>
            <div className={classes.lightBackground}>
              <AllPatientHeaderContainer
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                setIsSearching={setIsSearching}
                setEnableTable={setEnableTable}
                isSearching={isSearching}
                setSearchTextAndFilters={setSearchTextAndFilters}
              />

              <div
                className={
                  openFilter ? classes.openedDrawer : classes.closedDrawer
                }
              >
                <AllPatientFilters
                  defaultSortBy={{
                    field: 'careCoordinationStatusForAssignedPatients',
                    method: 'asc' as const,
                  }}
                  setSortBy={setSortBy}
                  setEnableTable={setEnableTable}
                  setIsSearching={setIsSearching}
                  applyFilterBtnIsClicked={applyFilterBtnIsClicked}
                  setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
                  loading={loading}
                  displayedCount={displayedCount}
                  options={assignedPatientsFilterOptions}
                  careTeamUsers={props.careTeamUsers}
                  componentId={PatientFilterComponentIDs.ASSIGNED_PATIENTS}
                />
              </div>
            </div>
            <Paper
              className={`${classes.control} ${classes.tableTitleContainer}`}
            >
              <Typography variant="h4" className={classes.tableTitle}>
                {props.title}
              </Typography>

              <div className={classes.tableTitleRowButtons}>
                <ExportMenu
                  rows={resultRows}
                  panelId={props.panelId}
                  title={'Export'}
                  componentId={PatientFilterComponentIDs.ASSIGNED_PATIENTS}
                  showMyOwn={CheckIsCoordinator(loggedInUser) ? 'YES' : 'NO'}
                />
              </div>
            </Paper>
          </>
        )
      }
      headerChange={openFilter}
    >
      {polling ? (
        <LoadingSpinner />
      ) : (
        <AssignedPatientsTable
          isSearching={isSearching}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setResultRows={setResultRows}
          limit={props.limit}
          skip={props.skip}
          panelId={props.panelId}
          setLimit={props.setLimit}
          setSkip={props.setSkip}
          title={props.title}
          tabIndex={props.tabIndex}
          availableActions={props.availableActions}
          selected={props.selected}
          setSelected={props.setSelected}
          actionsFooter={props.actionsFooter}
          setActionsFooter={props.setActionsFooter}
          allSelected={props.allSelected}
          setAllSelected={props.setAllSelected}
          excluded={props.excluded}
          setExcluded={props.setExcluded}
          hardFresh={props.hardFresh}
          setAssignedPatientCount={props.setAssignedPatientCount}
          setMyAssignedPatientCount={props.setMyAssignedPatientCount}
          disableNewEngagementBtn={openFilter}
          notEngagedCount={props.notEngagedCount}
          nofutureApptCount={props.nofutureApptCount}
          enableTable={enableTable}
          setLoading={setLoading}
          setDisplayedCount={setDisplayedCount}
          setSearchTextAndFilters={setSearchTextAndFilters}
          setPatientTableQuery={setPatientTableQuery}
          coordinators={props.coordinators}
          changeCoordinator={changeCoordinator}
          enableChangeCoordinatorDialog={enableChangeCoordinatorDialog}
          setEnableChangeCoordinatorDialog={setEnableChangeCoordinatorDialog}
        />
      )}
    </SubMainRegion>
  );
};
