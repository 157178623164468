import React, { useContext } from 'react';
import { GlobalContext } from '../GlobalContext';
import { Navigate, useLocation } from 'react-router';
import { Loading } from '../Loading';
import { RouteGuard } from '../RouteGuard';
import { useFeatures } from '../FeatureFlag';
import { ErrorView } from '../ErrorBoundary/View';
import { CheckIsHudsonClinician } from '../CheckIsAdmin';
const AuthenticatedComponent: React.FC<{
  element: React.ReactElement;
  featureName?: string;
}> = ({ element, featureName }) => {
  const currentFeatures = useFeatures();
  const location = useLocation();
  const { loggedInUser, loading } = useContext(GlobalContext);
  if (loading) {
    return <Loading />;
  }
  if (!loggedInUser) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }
  if (CheckIsHudsonClinician(loggedInUser)) {
    if (
      location.pathname === '/dashboard' ||
      // location.pathname === '/profile/edit' ||
      location.pathname === '/profile/change_password'
    ) {
      return element;
    }
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }
  // if user is not allowed to access to the url based on feature list
  if (featureName) {
    const hasFeature = currentFeatures[featureName];
    if (hasFeature) {
      return element;
    }
    return (
      <ErrorView
        error={new Error('Forbidden')}
        resetErrorBoundary={() => {
          window.location.replace('/dashboard');
        }}
      />
    );
  }
  return element;
};
export interface AuthRouteProps {
  path?: string;
  layout?: React.FunctionComponent<any>;
  element: React.ReactElement;
  webURL?: string;
  featureName?: string;
}
export const AuthenticatedRoute: React.FC<AuthRouteProps> = ({
  layout: Layout,
  element,
  webURL,
  featureName,
  ...rest
}) => {
  if (Layout) {
    if (webURL) {
      return (
        <RouteGuard element={<Layout>{element}</Layout>} webURL={webURL} />
      );
    } else {
      return (
        <AuthenticatedComponent
          element={<Layout>{element}</Layout>}
          featureName={featureName}
        />
      );
    }
  } else {
    if (webURL) {
      return <RouteGuard element={element} webURL={webURL} />;
    } else {
      return <AuthenticatedComponent element={element} />;
    }
  }
};
