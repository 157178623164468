import React, { ChangeEvent } from 'react';
import {
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { useProviderPatientTableStyles } from './Styles';
import { ProfileItem } from '../../../../../components/ProfileItem';
import { NotRegisterIcon } from '../../../../../assets/Icons/FileUpload/NotRegisterIcon';
import { theme } from '../../../../../themes/theme';
import {
  createHeaderCell,
  HeadCell,
} from '../../../../../sharePipe/createHeaderCellPipe';
import { EmptyPlaceHolder } from '../../../../../components/EmptyPlaceHolder';
import { useStickyTableStyle } from '../../../../../components/TabBar/Styles';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { SubscriberIDCell } from '../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTable/components/SubscriberIDCell';

interface EnhancedTableHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    if (isDisableSort(property)) {
      return;
    }
    onRequestSort(event, property);
  };

  const isDisableSort = (id: any) => {
    return (
      unSortOrderBy &&
      unSortOrderBy.findIndex((_orderBy) => {
        return _orderBy === id;
      }) !== -1
    );
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={
                isDisableSort(headCell.id) ? false : orderBy === headCell.id
              }
              hideSortIcon={isDisableSort(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
              disabled={isDisableSort(headCell.id)}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: number;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = useProviderPatientTableStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  {
    id: 'fullName',
    label: 'Name',
    disablePadding: false,
    align: 'left' as const,
  },
  {
    id: 'dateOfBirth',
    label: 'DOB',
    align: 'left' as const,
    disablePadding: false,
  },
  {
    id: 'gender',
    label: 'Gender',
    disablePadding: false,
    align: 'left' as const,
  },
  {
    id: 'subscriberIDs',
    numeric: false,
    disablePadding: false,
    label: 'Subscriber ID',
    align: 'left' as const,
  },
  {
    id: 'lastDos',
    label: 'Last Service Date',
    disablePadding: false,
    align: 'left' as const,
  },
  {
    id: 'coveringPCPFullName',
    label: 'Covering Provider',
    disablePadding: false,
    align: 'left' as const,
  },
  {
    id: 'caregapCount',
    label: 'Open Care Gaps',
    disablePadding: false,
    align: 'left' as const,
  },
].map((item) =>
  createHeaderCell(item.id, item.label, item.disablePadding, item.align)
);

const unSortOrderBy: string[] = ['coveringPCPFullName', 'subscriberIDs'];

interface ProviderPatientTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  patientRows: any;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient: any
  ) => any;
  count: number;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  refetchDataFunc?: any;
  setValue: (input: any) => void;
}

export const ProviderPatientTableView = (
  props: ProviderPatientTableViewProps
) => {
  const classes = useProviderPatientTableStyles();
  const stickyTableClasses = useStickyTableStyle();
  const [isSorting, setIsSorting] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([] as any[]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<string>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const avatarPalette = theme.palette.avatars;

  const colors = Object.values(avatarPalette);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setIsSorting(true);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    setFilteredRows(props.patientRows);

    setIsSorting(false);
  }, [props.patientRows]);

  React.useEffect(() => {
    if (isSorting) {
      if (props.refetchDataFunc) {
        props.refetchDataFunc.func({
          input: {
            ...props.refetchDataFunc.params,
            skip: 0,
            sortBy: [{ field: orderBy, method: order }],
          },
        });

        setTimeout(() => {
          setIsSorting(false);
        }, 5000);
      }
    }
  }, [isSorting]);

  const getOpenCareGapChipEle = (row: any) => {
    // measureCount === 0 => No Care Gaps , measureCount > 0 && caregapCount === 0 => All Gaps Closed
    let isNoCareGaps = row.measureCount === 0;
    let isAllGapsClosed = row.measureCount > 0 && row.caregapCount === 0;

    let labelText =
      (isNoCareGaps && 'No Care Gaps') ||
      (isAllGapsClosed && 'All Gaps Closed') ||
      `${row.caregapCount} Open Gaps`;

    let iconEle =
      isNoCareGaps || isAllGapsClosed ? undefined : (
        <NotRegisterIcon className={classes.warningIcon} />
      );
    let className =
      isNoCareGaps || isAllGapsClosed ? classes.closedGap : classes.openGap;

    return (
      <Chip
        icon={iconEle}
        label={labelText}
        className={className}
        onClick={(event) => {
          props.setValue(2);
          props.drawerHandler(event, row);
        }}
      />
    );
  };
  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} ${classes.rounded}`}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer
            classes={{ root: stickyTableClasses.customTableContainer }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredRows &&
                  filteredRows
                    .slice(page * props.limit, page * props.limit + props.limit)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={`${index}-${row.id}`}
                          hover
                          onClick={(event) => {
                            props.setValue(0);
                            props.drawerHandler(event, row);
                          }}
                        >
                          <TableCell
                            align="left"
                            className={classes.tableCellName}
                          >
                            {row.lastName}, {row.firstName}
                          </TableCell>
                          <TableCell align="left">
                            {row.dateOfBirth || (
                              <EmptyPlaceHolder text={'No DOB'} />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row.gender || (
                              <EmptyPlaceHolder text={'No Gender'} />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <SubscriberIDCell
                              subscriberIDs={row.subscriberIDs}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {row.lastDos ? (
                              row.lastDos
                            ) : (
                              <EmptyPlaceHolder text={'No DOS'} />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row.coveringPCPFirstName &&
                            row.coveringPCPLastName ? (
                              <>
                                <ProfileItem
                                  key={`${index}-${row.coveringPCPFullName}`}
                                  avatarBgColor={colors[0]}
                                  firstName={row.coveringPCPLastName + ','}
                                  lastName={row.coveringPCPFirstName}
                                />
                              </>
                            ) : (
                              <EmptyPlaceHolder text={'No Coverby'} />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getOpenCareGapChipEle(row)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};
