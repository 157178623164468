import React from 'react';
import { ListItem, Theme, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { RoleType } from '../../../../../../../../../enum/Users';
import { User } from '../../../../../../../../../app.types.generated';
import { LabelAvatar } from '../../../../../../../../../components/Avatar';

interface DesigneeItemProps {
  item: User;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const DesigneeItem: React.FC<DesigneeItemProps> = ({
  item,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ListItem button onClick={handleClick}>
        <div className={classes.listItem}>
          <LabelAvatar
            firstName={item.firstName}
            lastName={item.lastName}
            title={
              item?.role?.roleName === RoleType.ADMIN
                ? RoleType.CARE_MANAGER
                : item?.role?.roleName ||
                  item?.title ||
                  item.roleName ||
                  'Unknown'
            }
            styles={classes}
          />

          <KeyboardArrowDown className={classes.iconArrowDown} />
        </div>
      </ListItem>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiListItem-root': {
      padding: theme.spacing(0, 2),
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  avatarContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${theme.palette.avatars.blue} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.hint,
  },
  iconArrowDown: {
    width: 24,
    height: 24,
    color: theme.palette.grey[500],
  },
}));
