import React, { useEffect } from 'react';
import {
  CareTeamMember,
  PanelPatient,
  User,
} from '../../../../../../app.types.generated';
import { GlobalContext } from '../../../../../../components/GlobalContext';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { PatientFilterComponentIDs } from '../../../../../../enum/store';
import { useStartCoordination } from '../../../../../../hooks/useStartCoordination';
import { MixPanelEvents } from '../../../../../../mixpanel/events';
import { getDiffsAsObject } from '../../../../../../sharePipe/getDiffsAsObject';
import { usePatientFilterStore } from '../../../../../../store/features/patientFilter';
import { CareGapPatientNotFound } from '../../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTableNotFound';
import { CareGapsDrawer } from '../../../../../CareGaps/Home/tabs/Population/components/CareGapsDrawer';
import {
  CareGapContext,
  useCareGapGlobalContext,
} from '../../../../../CareGaps/Home/tabs/Population/context';
import { IState } from '../../../../PanelsHome/components/AllPatient/components/AllPatientFilters';
import { ChangeCoordinatorDialog } from '../../../components/ChangeCoordinatorDialog';
import {
  useGetTaskStatusQuery,
  useMovePatientBetweenCareTeamUsersMutation,
} from '../../../components/PanelPatientsListActionsFooter/index.generated';
import { PanelPatientsListActionsFooter } from '../../../components/PanelPatientsListActionsFooter/PanelPatientsListActionsFooter';
import { PanelSummaryContext } from '../../../PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { useKeyPatients } from '../../hooks/useKeyPatients';
import { useSwitchPatientWithPagination } from '../../hooks/useSwitchPatientWithPagination';
import { useUnlockPatientClosingPFS } from '../../hooks/useUnlockPatientClosingPFS';
import {
  GetAssignedPatientsQuery,
  useGetAssignedPatientsQuery,
  useGetUsersByAccountIdQuery,
} from './index.generated';
import { AssignedPatientsTableView } from './View';
import { CheckIsHudsonClinician } from '../../../../../../components/CheckIsAdmin';

const initialState: IState = {
  gender: [],
  age: [],
  fromDate: null,
  toDate: null,
  risk: [],
  careGap: [],
  gapStatus: '',
  engagementStatus: '',
  diseaseAndTags: [],
  futureAppointment: null,
  provider: [],
  clinicId: [],
  divisionId: [],
  coordinationStatus: [],
  dueDateFrom: null,
  dueDateTo: null,
  userIds: [],
  languages: [],
  healthPlan: [],
  eligibilityStatuses: [],
  careGapStatus: [],
  lineOfBusiness: [],
  payerIds: [],
  callAttemptCount: [],
  newPatientOnly: false,
};

interface AssignedPatientsTableContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  actionsFooter: any[];
  setActionsFooter: any;
  userId?: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  hardFresh: any;
  setAssignedPatientCount?: (input: any) => any;
  setMyAssignedPatientCount?: (input: any) => any;
  disableNewEngagementBtn: boolean;
  notEngagedCount?: number;
  nofutureApptCount?: number;
  enableTable: boolean;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  setPatientTableQuery: (input: any) => void;
  setSearchTextAndFilters: (input: any) => void;
  setResultRows: (input: any) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  isSearching: boolean;
  coordinators?: any;
  changeCoordinator: any;
  enableChangeCoordinatorDialog: any;
  setEnableChangeCoordinatorDialog: any;
}

export const AssignedPatientsTableContainer = (
  props: AssignedPatientsTableContainerProps
) => {
  const [fromCoordinators, setFromCoordinators] = React.useState<
    CareTeamMember[]
  >([]);
  const [newEngmtOptionName, setNewEngmtOptionName] = React.useState('');
  const [enableEngagementDialog, setEnableEngagementDialog] = React.useState(
    false
  );
  // const [
  //   enableChangeCoordinatorDialog,
  //   setEnableChangeCoordinatorDialog,
  // ] = React.useState(false);
  const [
    enableChangeCoordinatorDialogFromPatientSheet,
    setEnableChangeCoordinatorDialogFromPatientSheet,
  ] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [results, setResults] = React.useState<PanelPatient[]>([]);
  const [checkedPatient, setCheckedPatient] = React.useState<PanelPatient>(
    {} as PanelPatient
  );
  const [value, setValue] = React.useState(0); // 0 patient info, 1 Engagement, 2 Care Gaps

  const [taskId, setTaskId] = React.useState('');
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [fromUserId, setFromUserId] = React.useState<string>('');
  const [patientFaceInfo, setPatientFaceInfo] = React.useState<any>();
  const [refresher, setRefresher] = React.useState(0);

  const { deListPatientState, setSwitchToNextPatient } = React.useContext(
    CareGapContext
  );
  // // lock patient is done in getPatientFaceInfo calling by backend

  // // release patient when PFS is closed
  const { pfsOpenAndClosed } = useUnlockPatientClosingPFS(
    showDrawer,
    patientFaceInfo,
    props.panelId
  );

  const { setOpenCareGaps } = React.useContext(CareGapContext);
  React.useEffect(() => {
    if (pfsOpenAndClosed) {
      // reset the care gap count as the patient is released
      setOpenCareGaps([]);
    }
  }, [pfsOpenAndClosed]);

  const drawerHandler = (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    // setState({ ...state, [anchor]: open });
    event.stopPropagation();
    setShowDrawer(!showDrawer);

    if (patient) {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.openPatientFaceSheet.name,
        { patientID: patient.id, panelID: patient.thisPanelId }
      );
      setCheckedPatient(patient);
    } else {
      setCheckedPatient({} as PanelPatient);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    }
  }, [showDrawer]);

  const switchTab = (event: React.MouseEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const { searchName, searchDob } = useCareGapGlobalContext();

  React.useEffect(() => {
    if (props.skip !== undefined) {
      props.setSkip(0);
    }
  }, [props.enableTable, props.limit]);

  const defaultFilters = {
    // by default
    panelId: props.panelId,
    limit: props.limit ?? 10,
    skip: props.skip ?? 0,
    sortBy: [{ ...props.sortBy }],
  };

  const patientFilterStore = usePatientFilterStore(
    PatientFilterComponentIDs.ASSIGNED_PATIENTS
  );

  const tmpFilters = patientFilterStore.formattedFiltersForQuery;
  const enableTableFilters = {
    ...tmpFilters,
    ...defaultFilters,
    andName: searchName,
    andDob: searchDob,
  };

  React.useEffect(() => {
    props.setSkip(0);
  }, [tmpFilters, props.limit]);

  const assignedPatientsQuery = useGetAssignedPatientsQuery({
    variables: {
      input: props.enableTable ? enableTableFilters : defaultFilters,
    },
  });

  React.useEffect(() => {
    if (!assignedPatientsQuery.called || assignedPatientsQuery.loading) {
      props.setLoading(true);
      props.setPatientTableQuery(assignedPatientsQuery);
    } else {
      props.setLoading(false);
      props.setDisplayedCount(
        assignedPatientsQuery.data?.getPatientByMultipleUserIds.count ?? 0
      );
    }
  }, [assignedPatientsQuery]);

  React.useEffect(() => {
    props.setSearchTextAndFilters({
      andName: searchName,
      andDob: searchDob,
    });
  }, [searchName, searchDob]);

  const getCount = (query: GetAssignedPatientsQuery | undefined) => {
    return query?.getPatientByMultipleUserIds.count!;
  };

  // get users as coordinators
  const userQuery = useGetUsersByAccountIdQuery({
    variables: {
      getUsersByAccountIdRequest: {
        limit: 0,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
  });

  const count = React.useMemo(() => getCount(assignedPatientsQuery.data), [
    assignedPatientsQuery,
  ]);

  React.useEffect(() => {
    setNewEngmtOptionName('');
  }, [props.actionsFooter.length]);

  const coordinatorOptions = React.useMemo(() => {
    return userQuery.data?.getUsersByAccountId?.results.filter(
      (item) => !CheckIsHudsonClinician(item)
    );
  }, [userQuery.data]);
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  // update panel with new coordinators
  const [
    movePatientBetweenCareTeamUsersMutation,
  ] = useMovePatientBetweenCareTeamUsersMutation();

  const changeCoordinatorFromPatientSheetFace = async (
    fromUsers: any,
    toUsers: any
  ) => {
    const input = {
      panelId: props.panelId,
      excludePatientIds: [],
      selectedPatientIds: [patientFaceInfo.id],
      fromUserId: fromUsers.map((item: any) => item.user.id),
      toUserId: toUsers.map((item: any) => {
        return {
          count: item.assignedPatients,
          userId: item.id,
        };
      }),
    };
    // console.log('selectedPatientIds', patientFaceInfo);
    try {
      const { data, errors } = await movePatientBetweenCareTeamUsersMutation({
        variables: {
          input: { ...input },
        },
      });
      if (data) {
        setTaskId(data.movePatientBetweenCareTeamUsers.taskId);
      } else if (errors) {
        // error response
        setToastMessage({
          snackbarMsg: `Fail to assign patients. Please try again.`,
          severity: 'error',
          isOpen: true,
        });
        setIsOpenToast(true);
      }
    } catch (error) {
      setToastMessage({
        snackbarMsg: `Fail to assign patients. Please try again.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }

    props.setEnableChangeCoordinatorDialog(false);
  };

  // watch for updating status
  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId, // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (taskId) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        queryTask.stopPolling();
        setToastMessage({
          snackbarMsg: `Care Coordinator assignment updated.`,
          severity: 'success',
          isOpen: true,
        });
        setIsOpenToast(true);
        setTimeout(() => {
          props.setLoading(false);
          setSwitchToNextPatient(true);
        }, 1000);
      } else {
        props.setLoading(true);
        queryTask.startPolling(1000);
        setSwitchToNextPatient(false);
      }
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  useStartCoordination({
    checkedPatient,
    results,
    setCheckedPatient,
    setShowDrawer,
  });

  React.useEffect(() => {
    if (!showDrawer) {
      assignedPatientsQuery.refetch();
    }
  }, [showDrawer]);
  React.useEffect(() => {
    if (!showDrawer) {
      setSwitchToNextPatient(false);
    }
  }, [showDrawer]);

  React.useEffect(() => {
    if (deListPatientState.patientDeListedFromCurrentPanel) {
      // update all patients data once de-listed current patient from patient face sheet is triggered
      assignedPatientsQuery.refetch();
    }
  }, [deListPatientState.patientDeListedFromCurrentPanel]);

  const { currentPage } = useSwitchPatientWithPagination(
    props.skip || 0,
    props.limit || 10,
    results,
    setCheckedPatient,
    checkedPatient
  );

  React.useEffect(() => {
    // multi-select patient coordinator
    // select all
    if (props.allSelected) {
      if (props.coordinators) {
        // set from coordinators === panel's assigned coordinators
        const tmpCoordinators = props.coordinators.map((cc: any) => {
          const tmpUser = coordinatorOptions?.find(
            (item) => item.id === cc.user.id
          );
          return {
            count: cc.count,
            user: { ...tmpUser },
          };
        });
        setFromCoordinators(tmpCoordinators);
      }
    } else {
      if (props.actionsFooter && props.actionsFooter.length > 0) {
        const tmp: CareTeamMember[] = [];
        props.actionsFooter.forEach((item: any) => {
          // new coordinator
          if (
            tmp.findIndex(
              (cc) => cc.user.id === item.coordinators[0].userId
            ) === -1
          ) {
            tmp.push({
              user: {
                firstName: item.coordinators[0].firstName,
                lastName: item.coordinators[0].lastName,
                id: item.coordinators[0].userId,
                role: {
                  roleName: item.coordinators[0].roleName,
                },
              } as User,
              count: 1,
            } as CareTeamMember);
          } else {
            // existed coordinator
            tmp.forEach((cc) => {
              if (cc.user.id === item.coordinators[0].userId) {
                cc.count += 1;
              }
            });
          }
        });
        setFromCoordinators(tmp);
      }
    }
  }, [props.allSelected, props.actionsFooter, props.coordinators]);
  const { keyPatients, setKeyPatients } = useKeyPatients(checkedPatient);
  React.useEffect(() => {
    // patient face sheet coordinator
    if (
      keyPatients &&
      keyPatients.cur &&
      keyPatients.cur.id &&
      keyPatients.cur.coordinators
    ) {
      const tmp = keyPatients.cur!.coordinators!;
      setFromCoordinators([
        {
          count: 1,
          user: {
            id: tmp[0].userId,
            firstName: tmp[0].firstName,
            lastName: tmp[0].lastName,
            role: { roleName: tmp[0].roleName },
          },
        },
      ] as CareTeamMember[]);
    }
  }, [keyPatients]);

  const { isSticky } = React.useContext(GlobalContext);

  const { refetchPatientTable, setRefetchPatientTable } = React.useContext(
    PanelSummaryContext
  );
  React.useEffect(() => {
    if (refetchPatientTable) {
      assignedPatientsQuery.refetch();
      setRefetchPatientTable(false);
    }
  }, [refetchPatientTable]);

  if (!assignedPatientsQuery.called || assignedPatientsQuery.loading) {
    return <LoadingSpinner style={isSticky ? {} : { marginTop: 335 }} />;
  }

  return (
    <div style={{ width: '100%' }}>
      {props.enableTable &&
      assignedPatientsQuery.data?.getPatientByMultipleUserIds.count == 0 ? (
        <CareGapPatientNotFound isSearching={props.isSearching} />
      ) : (
        <>
          {!assignedPatientsQuery.data ? null : (
            <AssignedPatientsTableView
              refetchDataFunc={{
                func: assignedPatientsQuery.refetch,
                params: props.enableTable ? enableTableFilters : defaultFilters,
              }}
              setSortBy={props.setSortBy}
              sortBy={props.sortBy}
              setResultRows={props.setResultRows}
              panelId={props.panelId}
              title={props.title}
              patients={
                assignedPatientsQuery.data.getPatientByMultipleUserIds
                  .results ?? []
              }
              limit={props.limit ?? 10}
              skip={props.skip ?? 0}
              setLimit={props.setLimit}
              setSkip={props.setSkip}
              setSelected={props.setSelected}
              selected={props.selected}
              setActionsFooter={props.setActionsFooter}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              drawerHandler={drawerHandler}
              setEnableEngagementDialog={setEnableEngagementDialog}
              count={count ?? 0}
              setAssignedPatientCount={props.setAssignedPatientCount}
              setMyAssignedPatientCount={props.setMyAssignedPatientCount}
              disableNewEngagementBtn={props.disableNewEngagementBtn}
              setNewEngmtOptionName={setNewEngmtOptionName}
              patientTableQuery={assignedPatientsQuery}
              setResults={setResults}
              setValue={setValue}
            />
          )}
          {props.actionsFooter.length > 0 && (
            <PanelPatientsListActionsFooter
              itemName={'Patients'}
              selectedItems={props.actionsFooter}
              setSelected={props.setSelected}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              tabs={props.availableActions}
              panelId={props.panelId}
              count={count}
              hardFresh={props.hardFresh}
              enableEngagementDialog={enableEngagementDialog}
              setEnableEngagementDialog={setEnableEngagementDialog}
              coordinatorOptions={coordinatorOptions ?? []}
              enableChangeCoordinatorDialog={
                props.enableChangeCoordinatorDialog
              }
              setEnableChangeCoordinatorDialog={
                props.setEnableChangeCoordinatorDialog
              }
              loading={loading}
              setLoading={setLoading}
              clicked={clicked}
              setClicked={setClicked}
              fromCoordinators={fromCoordinators}
              filters={
                props.enableTable
                  ? getDiffsAsObject(enableTableFilters, defaultFilters)
                  : null
              }
              changeCoordinator={props.changeCoordinator}
            />
          )}
          {showDrawer && (
            <CareGapsDrawer
              allPatients={results}
              drawerHandler={drawerHandler}
              checkedPatient={checkedPatient}
              tabIndex={value}
              switchTab={switchTab}
              setEnableChangeCoordinatorDialog={
                setEnableChangeCoordinatorDialogFromPatientSheet
              }
              setFromUserId={setFromUserId}
              panelId={props.panelId}
              currentPage={currentPage}
              totalCount={count}
              limit={props.limit ?? 10}
              skip={props.skip ?? 0}
              setSkip={props.setSkip}
              fromCoordinators={fromCoordinators}
              setPatientFaceInfo={setPatientFaceInfo}
              refresher={refresher}
              setRefresher={setRefresher}
              keyPatients={keyPatients}
              setKeyPatients={setKeyPatients}
            />
          )}
          {/* for Patient Face Sheet use */}
          {enableChangeCoordinatorDialogFromPatientSheet && (
            <ChangeCoordinatorDialog
              source="PatientFaceSheet"
              fromCoordinators={fromCoordinators}
              setShowChangeCoordinatorDialog={
                setEnableChangeCoordinatorDialogFromPatientSheet
              }
              firstQueryLoading={userQuery.loading}
              coordinatorOptions={coordinatorOptions ?? []}
              allPatientCount={1}
              changeCoordinator={changeCoordinatorFromPatientSheetFace}
              loading={loading}
              clicked={clicked}
              setClicked={setClicked}
            />
          )}
        </>
      )}
    </div>
  );
};
