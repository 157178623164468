import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BreadCrumbHeader } from '../../../components/BreadCrumbHeader';
import { MainRegion } from '../../../components/MainRegion';
import { useMyProfileTabsStyles } from '../../MyProfile/Home/Styles';
import { EditPanelOptionTabs } from './EditPanelOptionTabs';
import {
  useEditPanelMutation,
  useGetPanelSummaryQuery,
} from '../ViewPanels/PanelSummary/index.generated';
import { EditPanelDetailTab } from './tabs/PanelDetailTab';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useGetCareGapFilterOptionsQuery } from '../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { checkDate, EditPanelFilterTab } from './tabs/PanelFiltersTab';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../components/DialogBox';
import { GlobalContext } from '../../../components/GlobalContext';
import moment from 'moment/moment';
import { EditCoordinatorConfirmSubmitDialog } from './tabs/ConfirmUpdateDialog';
import { useGetTaskStatusQuery } from '../components/ManageCoordinatorDialog/index.generated';
import { PatientCount } from '../CreatePanel/components/Step2/components/PatientCount';

type PanelStateType = {
  panelName: string;
  panelDescription: string;
  clinicId: any[];
  division: any[];
  lob: any[];
  disease: any[];
  openGap: any[];
  icd: any[];
  cpt: any[];
  patientCount: number;
  pcp: any[];
  age: any[];
  gender: any[];
  lastDateOfServiceFrom: Date | null;
  lastDateOfServiceTo: Date | null;
  payerIds: string[];
};

const defaultPanelState = {
  panelName: '',
  panelDescription: '',
  clinicId: [],
  division: [],
  lob: [],
  payerIds: [],
  disease: [],
  openGap: [],
  icd: [],
  cpt: [],
  patientCount: 0,
  pcp: [],
  age: [],
  gender: [],
  lastDateOfServiceFrom: null,
  lastDateOfServiceTo: null,
};

export const EditPanelView = () => {
  const classes = useMyProfileTabsStyles();
  const navigate = useNavigate();
  const { setIsOpenToast, setToastMessage } = React.useContext(GlobalContext);
  const { id } = useParams();
  const [patientCount, setPatientCount] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [careGapFilterOption, setCareGapFilterOption] = React.useState<any>([]);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog
  );
  const [editPanel] = useEditPanelMutation();
  const [loading, setLoading] = useState(false);
  const [inValid, setInValid] = React.useState(false);
  const [taskId, setTaskId] = React.useState('');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [panelState, setPanelState] = React.useState<PanelStateType>(
    defaultPanelState
  );
  const [originPanelName, setOriginPanelName] = React.useState('');
  const [onConfirm, setOnConfirm] = React.useState(false);
  const [panelNameError, setPanelNameError] = useState<string | null>(null);
  const [panelDescriptionError, setPanelDescriptionError] = useState<
    string | null
  >(null);

  const changePanelDetail = (
    value: string,
    type: 'panelName' | 'panelDescription'
  ) => {
    switch (type) {
      case 'panelName':
        setPanelState({ ...panelState, panelName: value });
        break;
      case 'panelDescription':
        setPanelState({ ...panelState, panelDescription: value });
    }
  };

  const panel = useGetPanelSummaryQuery({
    variables: {
      input: {
        id: id || '',
      },
    },
  });

  const getCareGapFilterOptions = useGetCareGapFilterOptionsQuery();

  React.useEffect(() => {
    if (getCareGapFilterOptions?.data?.getCareGapFilterOptions) {
      const allOption = {
        value: 'All',
        name: 'All',
      };
      const temp: any[] = [];
      const caregap =
        getCareGapFilterOptions!.data!.getCareGapFilterOptions.CareGap || [];
      temp.push(...caregap);
      temp.push(allOption);
      setCareGapFilterOption(temp);
    }
  }, [getCareGapFilterOptions?.data?.getCareGapFilterOptions]);

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `profile-option-tabs-${index}`,
    };
  }

  const matchCareGapFromCode = (code: string) => {
    let hardCodeCode = code == 'ALL' ? 'All' : code;
    return (
      careGapFilterOption?.find((item: any) => item?.value == hardCodeCode) ??
      code
    );
  };

  const calculateUnassignedCount = () => {
    return patientCount - (panel?.data?.getPanel?.assignedPatientCount || 0) < 0
      ? 0
      : patientCount - (panel?.data?.getPanel?.assignedPatientCount || 0);
  };

  React.useEffect(() => {
    if (panel?.data?.getPanel) {
      setPatientCount(panel?.data?.getPanel.patientCount || 0);
      const tempPcp = panel?.data?.getPanel?.filters?.pcpId ?? [];
      const tempPcps = panel?.data?.getPanel?.pcps ?? [];
      const matchedPcps = tempPcps.filter((item: any) =>
        tempPcp.find((id: any) => item.id == id)
      );
      setOriginPanelName(panel?.data?.getPanel?.name || '');
      setPanelState({
        ...panelState,
        panelName: panel?.data?.getPanel?.name || '',
        panelDescription: panel?.data?.getPanel?.description || '',
        lob:
          panel?.data?.getPanel?.filters?.patientRequirements?.lineOfBusiness ??
          [],
        payerIds:
          panel?.data?.getPanel?.filters?.patientRequirements?.payerIds ?? [],
        clinicId:
          panel?.data?.getPanel?.filters?.patientRequirements?.clinicId ?? [],
        division:
          panel?.data?.getPanel?.filters?.patientRequirements?.division ?? [],
        disease:
          panel?.data?.getPanel?.filters?.patientRequirements?.chronicDisease ??
          [],
        openGap:
          (
            panel?.data?.getPanel?.filters?.patientRequirements?.openMeasures ??
            []
          ).map((code: string) => matchCareGapFromCode(code)) ?? [],
        icd: panel?.data?.getPanel?.filters?.icdlist ?? [],
        cpt: panel?.data?.getPanel?.filters?.cptlist ?? [],
        patientCount: 0,
        pcp: matchedPcps ?? [],
        age:
          panel?.data?.getPanel?.filters?.patientRequirements?.ageRanges ?? [],
        gender:
          panel?.data?.getPanel?.filters?.patientRequirements?.gender ?? [],
        lastDateOfServiceFrom: checkDate(
          panel?.data?.getPanel?.filters?.patientRequirements?.lastEncounterFrom
        ),
        lastDateOfServiceTo: checkDate(
          panel?.data?.getPanel?.filters?.patientRequirements?.lastEncounterTo
        ),
      });
    }
  }, [panel, careGapFilterOption]);

  useEffect(() => {
    if (!panelState.panelName || !panelState.panelName.length) {
      setPanelNameError('Panel name is empty');
    } else {
      setPanelNameError(null);
    }
    if (!panelState.panelDescription || !panelState.panelDescription.length) {
      setPanelDescriptionError('Panel description is empty');
    } else if (
      panelState.panelDescription &&
      panelState.panelDescription.length > 255
    ) {
      setPanelDescriptionError(
        `Panel description cannot exceed 255 characters (${panelState.panelDescription.length}/255)`
      );
    } else {
      setPanelDescriptionError(null);
    }
  }, [panelState]);

  React.useEffect(() => {
    if (patientCount <= 0 || panelNameError || panelDescriptionError) {
      setInValid(true);
    } else {
      setInValid(false);
    }
  }, [panelNameError, panelDescriptionError, patientCount]);

  const handleViewOtherPanel = () => {
    navigate('/panels/' + id);
  };

  const getRefinedInput = () => {
    const cptCode = panelState.cpt.map(
      (item: any) => item?.code?.trim() ?? item.trim()
    );
    const icdCode = panelState.icd.map(
      (item: any) => item?.code?.trim() ?? item.trim()
    );
    const input = {
      panelId: id!,
      name: panelState.panelName!,
      description: panelState.panelDescription!,
      criteria: {
        pcpId: panelState.pcp.length
          ? panelState.pcp.map((pcp: any) => pcp.id)
          : [],
        patientRequirements: {
          division: panelState.division?.includes('All')
            ? []
            : panelState.division,
          clinicId: panelState.clinicId?.includes('All')
            ? []
            : panelState.clinicId,
          lineOfBusiness: panelState.lob?.includes('All')
            ? []
            : panelState.lob.filter((l: string) => !l.startsWith('parent,')),
          payerIds: panelState.payerIds?.includes('All')
            ? []
            : panelState.payerIds,
          ageRanges: panelState.age?.includes('All') ? [] : panelState.age,
          chronicDisease: panelState.disease?.includes('All')
            ? []
            : panelState.disease,
          gender: panelState.gender?.includes('All') ? [] : panelState.gender,
          hasAnyCptCode: cptCode,
          hasAnyIcdCode: icdCode,
          openMeasures: panelState.openGap?.find(
            (item: any) => item.value == 'All'
          )
            ? ['ALL']
            : panelState.openGap.map((item: any) => item.value),
          lastEncounterFrom: panelState.lastDateOfServiceFrom
            ? moment.utc(panelState.lastDateOfServiceFrom).format('YYYY-MM-DD')
            : '',
          lastEncounterTo: panelState.lastDateOfServiceTo
            ? moment.utc(panelState.lastDateOfServiceTo).format('YYYY-MM-DD')
            : '',
        },
      },
    };
    return input;
  };
  const onUpdatePanelDetails = async () => {
    setShowDialog(false);
    const input = getRefinedInput();
    setLoading(true);
    const { data } = await editPanel({
      variables: { input },
    });
    setLoading(false);
    if (data && data.editPanel.entityId && data.editPanel.taskId) {
      setTaskId(data.editPanel.taskId);
    } else {
      setShowDialog(true);
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Fail to Edit Panel, Please try again',
      });
      setIsOpenToast(true);
    }
  };

  const queryTask = useGetTaskStatusQuery({
    variables: {
      input: {
        taskId: taskId,
      },
    },
    skip: !taskId, // only clicked and taskId is not empty string to check the status
  });

  React.useEffect(() => {
    if (taskId) {
      if (queryTask?.data?.getTaskStatus.status == true) {
        queryTask.stopPolling();
        setTimeout(() => {
          setLoading(false);
          setToastMessage({
            severity: 'success',
            snackbarMsg: 'Panel information has been updated.',
            isOpen: true,
          });
          setIsOpenToast(true);
          navigate('/panels/' + id, {
            state: { preUrl: 'editPanel' },
          });
        }, 2000);
      } else {
        setLoading(true);
        queryTask.startPolling(1000);
      }
    }
  }, [taskId, queryTask?.data?.getTaskStatus.status]);

  const renderComponent = () => {
    switch (value) {
      case 0:
        return null;
      case 1:
        return (
          <PatientCount
            lob={panelState.lob}
            payerIds={panelState.payerIds}
            clinicId={panelState.clinicId}
            division={panelState.division}
            diseases={panelState.disease}
            openGap={panelState.openGap}
            age={panelState.age}
            gender={panelState.gender}
            lastDateOfServiceFrom={panelState.lastDateOfServiceFrom}
            lastDateOfServiceTo={panelState.lastDateOfServiceTo}
            icd={panelState.icd.map(
              (item: any) => item?.code?.trim() ?? item.trim()
            )}
            cpt={panelState.cpt.map(
              (item: any) => item?.code?.trim() ?? item.trim()
            )}
            setPatientCount={setPatientCount}
            pcp={panelState.pcp}
          />
        );
      default:
        return null;
    }
  };

  // make loading spinner in vertical centre
  // React.useLayoutEffect(() => {
  //   const content = document.getElementById(
  //     'fixed-main-content'
  //   ) as HTMLElement;
  //   if (content) {
  //     const firstChildDiv = content.childNodes[0] as HTMLElement;
  //     firstChildDiv.style.height = '100%';
  //   }
  // }, []);
  return (
    <MainRegion
      header={
        <Grid container className={classes.root}>
          <BreadCrumbHeader
            rootLink={'/panels'}
            rootName={'All Panels'}
            childrenName={panel?.data?.getPanel?.name ?? 'loading'}
            childrenLink={'/panels/' + id}
            childrenChildrenName={'Edit Panel'}
            isTitle={true}
            title={'Edit Panel'}
            isButton={true}
            renderButton={renderComponent()}
          />
        </Grid>
      }
    >
      <Grid item xs={12}>
        <LeaveEditDialogBox
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />

        <Grid container spacing={8} className={classes.mainGrid}>
          {/* profile option tabs */}
          <Grid item xs={2} container>
            <EditPanelOptionTabs
              value={value}
              handleTabChange={handleTabChange}
              a11yProps={a11yProps}
            />
          </Grid>
          {/* spacing */}
          <Grid item xs={1} />
          {onConfirm && (
            <EditCoordinatorConfirmSubmitDialog
              onSubmit={() => {
                onUpdatePanelDetails();
              }}
              onClose={() => setOnConfirm(false)}
              selectedItems={panel?.data?.getPanel?.careTeamUsers}
              unAssignedCount={calculateUnassignedCount()}
              clicked={loading}
              loading={loading}
              handleViewOtherPanel={handleViewOtherPanel}
              panelName={panelState.panelName}
              totalPatients={patientCount}
              originPanelName={originPanelName}
              refinedInput={getRefinedInput()}
              delistedPatientCount={
                panel?.data?.getPanel?.delistedPatientCount || 0
              }
            />
          )}
          {/* forms */}
          <Grid item xs={9}>
            {(!panel.called ||
              panel.loading ||
              !getCareGapFilterOptions.called ||
              getCareGapFilterOptions.loading) && <LoadingSpinner />}
            {!(!panel.called || panel.loading) && value == 0 && (
              <EditPanelDetailTab
                panel={panel?.data?.getPanel}
                panelState={panelState}
                changePanelDetail={changePanelDetail}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleViewOtherPanel={handleViewOtherPanel}
                onUpdatePanelDetails={onUpdatePanelDetails}
                setOnConfirm={setOnConfirm}
                inValid={inValid}
                panelNameError={panelNameError}
                panelDescriptionError={panelDescriptionError}
              />
            )}
            {!(!panel.called || panel.loading) &&
              !(
                !getCareGapFilterOptions.called ||
                getCareGapFilterOptions.loading
              ) &&
              value == 1 && (
                <EditPanelFilterTab
                  options={
                    getCareGapFilterOptions?.data?.getCareGapFilterOptions
                  }
                  careGapFilterOption={careGapFilterOption}
                  providers={[]}
                  panel={panel.data}
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                  panelState={panelState}
                  setPanelState={setPanelState}
                  patientCount={patientCount}
                  setPatientCount={setPatientCount}
                  setOnConfirm={setOnConfirm}
                  onUpdatePanelDetails={onUpdatePanelDetails}
                  inValid={inValid}
                />
              )}
          </Grid>
        </Grid>
      </Grid>
    </MainRegion>
  );
};
