import React from 'react';
import { ProviderPatientTableView } from './View';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { useGetPatientsByProviderIdAndFiltersQuery } from './index.generated';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { CareGapPatientNotFound } from '../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTableNotFound';

interface ProviderPatientTableContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient: any
  ) => any;
  providerNPI: string;
  selectedFilter: any | undefined;
  isSearching: boolean;
  searchName: any;
  searchDob: any;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  setResults: (input: any) => void;
  setValue: (input: any) => void;
  applyFilterBtnIsClicked: boolean;
}

export const ProviderPatientTableContainer = (
  props: ProviderPatientTableContainerProps
) => {
  const filteredProviderPatientsQuery = useGetPatientsByProviderIdAndFiltersQuery(
    {
      variables: {
        input: props.isSearching
          ? {
              limit: props.limit ?? 10,
              skip: props.skip,
              andName: props.searchName,
              andDob: props.searchDob,
              npis: props.providerNPI ? [props.providerNPI] : [],
              sortBy: [{ ...props.sortBy }],
            }
          : {
              limit: props.limit ?? 10,
              skip: props.skip,
              gender: props.selectedFilter.info.Gender ?? [],
              age: props.selectedFilter.info.Age ?? [],
              ldosFrom: props.selectedFilter.info.LastDateOfServiceFrom ?? '',
              ldosTo: props.selectedFilter.info.LastDateOfServiceTo ?? '',
              openGaps: props.selectedFilter.info.CareGaps ?? [],
              npis: props.providerNPI ? [props.providerNPI] : [],
              sortBy: [{ ...props.sortBy }],
            },
      },
    }
  );
  React.useEffect(() => {
    props.setSkip(0);
  }, [props.limit, props.selectedFilter, props.searchName, props.searchDob]);

  React.useEffect(() => {
    if (
      !filteredProviderPatientsQuery.called ||
      filteredProviderPatientsQuery.loading
    ) {
      props.setLoading(true);
    } else {
      props.setLoading(false);
      props.setDisplayedCount(
        filteredProviderPatientsQuery.data?.getPatientByCareTeamUserId.count ??
          0
      );

      props.setResults(
        filteredProviderPatientsQuery.data?.getPatientByCareTeamUserId
          .results || []
      );
    }
  }, [filteredProviderPatientsQuery]);

  const queryResult = useFetchTableData(filteredProviderPatientsQuery);

  if (
    !filteredProviderPatientsQuery.called ||
    filteredProviderPatientsQuery.loading
  ) {
    return <LoadingSpinner style={{ height: `calc(100vh - 335px)` }} />;
  }

  return (
    <>
      {(props.applyFilterBtnIsClicked || props.isSearching) &&
      filteredProviderPatientsQuery.data?.getPatientByCareTeamUserId.count ==
        0 ? (
        <CareGapPatientNotFound isSearching={props.isSearching} />
      ) : (
        <ProviderPatientTableView
          refetchDataFunc={{
            func: filteredProviderPatientsQuery.refetch,
            params: props.isSearching
              ? {
                  limit: props.limit ?? 10,
                  skip: props.skip,
                  andName: props.searchName,
                  andDob: props.searchDob,
                  npis: props.providerNPI ? [props.providerNPI] : [],
                  sortBy: [{ ...props.sortBy }],
                }
              : {
                  limit: props.limit ?? 10,
                  skip: props.skip,
                  gender: props.selectedFilter.info.Gender ?? [],
                  age: props.selectedFilter.info.Age ?? [],
                  ldosFrom:
                    props.selectedFilter.info.LastDateOfServiceFrom ?? '',
                  ldosTo: props.selectedFilter.info.LastDateOfServiceTo ?? '',
                  openGaps: props.selectedFilter.info.CareGaps ?? [],
                  npis: props.providerNPI ? [props.providerNPI] : [],
                  sortBy: [{ ...props.sortBy }],
                },
          }}
          sortBy={props.sortBy}
          setSortBy={props.setSortBy}
          patientRows={
            queryResult ? queryResult.getPatientByCareTeamUserId.results : []
          }
          limit={props.limit ?? 10}
          skip={props.skip ?? 0}
          setLimit={props.setLimit}
          setSkip={props.setSkip}
          drawerHandler={props.drawerHandler}
          count={queryResult ? queryResult.getPatientByCareTeamUserId.count : 0}
          setValue={props.setValue}
        />
      )}
    </>
  );
};
