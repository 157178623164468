import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  Box,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  fade,
  DialogContent,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  CloseIcon,
  FileDownloadIcon,
} from '../../../../../assets/Icons/ExportPatients';
import { theme } from '../../../../../themes/theme';
import { ExportIcon } from '../../../../../assets/Icons/InsurancesExportPatinets/ExportIcon';
import {
  useGetExportTaskStatusQuery,
  useGetPatientExportLinkUtilityQuery,
  GetPatientExportLinkUtilityQuery,
} from './index.generated';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { useCareGapGlobalContext } from '../../../../CareGaps/Home/tabs/Population/context';
import moment from 'moment';
import { usePatientFilterStore } from '../../../../../store/features/patientFilter';
import { CheckIsCoordinator } from '../../../../../components/CheckIsAdmin';

interface ContentRegionProps {
  className?: string;
  panelId: string;
  format: string;
  setOpenDialog: (input: any) => void;
  showMyOwn?: string;
  componentId: string;
}

interface ExportDialogProps {
  panelId: string;
  formatText: string;
  openDialog: boolean;
  setOpenDialog: (input: boolean) => any;
  showMyOwn?: string;
  componentId: string;
}

interface ExportMenuProps {
  rows: any[];
  panelId: string;
  title?: string;
  showMyOwn?: string;
  componentId: string;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
  },
  smallPrimaryBtn: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.75, 1),
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: 'fit-content',
    height: 'fit-content',
    lineHeight: '24px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '& .MuiButton-label .MuiSvgIcon-root': {
      fontSize: '14px',
      marginLeft: theme.spacing(-0.25),
    },

    '& .MuiButton-label span': {
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
      marginRight: theme.spacing(0.75),
    },
  },
  exportIcon: {
    width: 20,
    height: 20,
  },
  dialogTitle: {
    margin: 0,
    width: 198,
    height: 32,
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: '32px',
    padding: theme.spacing(2.5, 'auto', 2.5, 3),
  },

  dialogContentRegion: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3.5),
    },

    '& .MuiTypography-root': {
      height: '24px',
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: '24px',
      fontSize: '16px',
    },

    '& .MuiTypography-root span': {
      display: 'block',
      height: '40px',
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '40px',
      marginBottom: theme.spacing(2.25),
    },

    '& img': {
      height: '62px',
      width: '96px',
      // marginTop: theme.spacing(10),
    },

    '& a': {
      display: 'block',
      width: '140px',
      height: '48px',
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      letterSpacing: 0,
      lineHeight: '48px',
      background: theme.palette.primary.main,
      borderRadius: '5px',
      marginTop: theme.spacing(3.9375),
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(1.5), // -6.5 1.5
    width: '160px',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      width: '87px',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '640px',
    height: '468px',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle {...other} className={classes.dialogTitle}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '24px',
            color: theme.palette.text.hint,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

// component of the main content region in the dialog
const ContentRegion = (props: ContentRegionProps) => {
  const { setToastMessage, setIsOpenToast, loggedInUser } = React.useContext(
    GlobalContext
  );
  const { careGapFilters } = useCareGapGlobalContext();
  const aElement = React.useRef<HTMLAnchorElement>(null);

  const patientFilterStore = usePatientFilterStore(props.componentId);

  const filters = patientFilterStore.formattedFiltersForQuery;

  const [coordinatorPatientRange, setCoordinatorPatientRange] = React.useState(
    ''
  );
  React.useEffect(() => {
    if (props.componentId === 'allPatients') {
      if (CheckIsCoordinator(loggedInUser)) {
        setCoordinatorPatientRange('ALL');
      }
    }
  }, [props.componentId, loggedInUser?.role?.roleName]);

  const exportPatientsQuery = useGetPatientExportLinkUtilityQuery({
    variables: {
      format: props.format,
      filters: {
        ...filters,
        showMyOwn: props.showMyOwn,
        panelId: props.panelId,
        sortBy: [{ field: 'fullName', method: 'asc' }],
        coordinatorPatientRange: coordinatorPatientRange,
        // userIds: careGapFilters.userIds.includes('All')
        //   ? []
        //   : careGapFilters.userIds,
        // gender: careGapFilters.gender?.includes('All')
        //   ? []
        //   : careGapFilters.gender,
        // age: careGapFilters.age?.includes('All') ? [] : careGapFilters.age,
        // ldosFrom: careGapFilters.ldosFrom
        //   ? moment.utc(careGapFilters.ldosFrom).format('YYYY-MM-DD')
        //   : '',
        // ldosTo: careGapFilters.ldosTo
        //   ? moment.utc(careGapFilters.ldosTo).format('YYYY-MM-DD')
        //   : '',
        // npis: careGapFilters.npis.map((pcp: any) => pcp.id) ?? [],
        // openGaps: careGapFilters.openGaps?.includes('All')
        //   ? []
        //   : careGapFilters.openGaps,
        // hasEngagement: careGapFilters.hasEngagement,
        // hasFutureAppointment: careGapFilters.hasFutureAppointment,
        // dueDateFrom: careGapFilters.dueDateFrom,
        // dueDateTo: careGapFilters.dueDateTo,
        // coordinationStatus: careGapFilters.coordinationStatus,
        // divisionId: careGapFilters.divisionId,
        // clinicId: careGapFilters.clinicId,
        // languages: careGapFilters.languages.includes('All')
        //   ? []
        //   : careGapFilters.languages,
        // payerIds:careGapFilters.payerIds,
        // lineOfBusiness:careGapFilters.lineOfBusiness,
        // eligibilityStatuses: careGapFilters.eligibilityStatuses,
        // careGapStatus: careGapFilters.careGapStatus,
        // callAttemptCount: careGapFilters.callAttemptCount?.includes('All')
        //     ? []
        //     : careGapFilters.callAttemptCount,
        // newPatientOnly: careGapFilters.newPatientOnly
      },
    },
    skip: props.format === '',
  });

  const getTaskID = (query: GetPatientExportLinkUtilityQuery | undefined) => {
    return query?.getPatientExportLinkUtility.taskID;
  };

  const taskId = React.useMemo(() => {
    if (exportPatientsQuery.error) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something went wrong, please try again',
      });
      setIsOpenToast(true);
      props.setOpenDialog(false);
      return '';
    }
    return getTaskID(exportPatientsQuery.data);
  }, [exportPatientsQuery]);

  const getUrlQuery = useGetExportTaskStatusQuery({
    variables: { input: { taskId: taskId ?? '' } },
    skip: !taskId || taskId == '',
  });
  const getLinks = (query: any | undefined) => {
    return query?.getExportTaskStatus.url;
  };
  const exportLink = React.useMemo(() => {
    if (getUrlQuery.error) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: 'Something went wrong, please try again',
      });
      setIsOpenToast(true);
      props.setOpenDialog(false);
      return '';
    }
    return getLinks(getUrlQuery.data);
  }, [getUrlQuery]);

  React.useEffect(() => {
    if (taskId) {
      if (
        getUrlQuery?.data?.getExportTaskStatus?.url &&
        getUrlQuery?.data?.getExportTaskStatus?.url !== ''
      ) {
        getUrlQuery.stopPolling();
      } else {
        getUrlQuery.startPolling(5000);
      }
    }
  }, [taskId, getUrlQuery]);

  React.useEffect(() => {
    const timer = setTimeout(() => aElement.current?.click(), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [exportLink, aElement]);

  if (!exportLink) {
    return (
      <div className={props.className}>
        <Box>
          <CircularProgress disableShrink thickness={4} />
        </Box>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 18,
          }}
        >
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            Exporting…
          </Typography>
          <Typography
            style={{
              color: theme.palette.grey[600],
              fontSize: 16,
            }}
          >
            Please wait while file is being prepared for download.
          </Typography>
        </div>
      </div>
    );
  }

  // const url = data?.exportPanel.url;
  // const extension = data?.exportPanel.format.toLocaleLowerCase();

  return (
    <div className={props.className}>
      <FileDownloadIcon
        style={{
          width: 35,
          height: 43,
          color: `${theme.palette.primary.main}`,
        }}
      />

      <Typography
        style={{
          height: '48px',
          width: '320px',
          marginTop: '32px',
          color: theme.palette.grey[600],
          fontSize: 16,
        }}
      >
        Your download will start in a few seconds. Click button below to
        download manually.
      </Typography>

      <a
        href={exportLink}
        download
        ref={aElement}
      >{`Download .${props.format}`}</a>
    </div>
  );
};

// component of popup dialog
const ExportDialog = (props: ExportDialogProps) => {
  const { openDialog, setOpenDialog, formatText, panelId, showMyOwn } = props;

  const classes = useStyles();

  const handleClose = () => {
    // close dialog
    setOpenDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Export Patients
        </BootstrapDialogTitle>

        <DialogContent>
          <ContentRegion
            format={formatText}
            panelId={panelId}
            className={classes.dialogContentRegion}
            setOpenDialog={setOpenDialog}
            showMyOwn={showMyOwn}
            componentId={props.componentId}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

// component of export menu
export function ExportMenu(props: ExportMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formatText, setFormatText] = React.useState('');
  const openMenu = Boolean(anchorEl);

  const classes = useStyles();

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    // click export menu button
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // close export menu
    setAnchorEl(null);
    setOpenDialog(false);
  };

  const handleOption = (event: any) => {
    // select options
    setFormatText(event.currentTarget.textContent.split('.').slice(-1)[0]);
    setAnchorEl(null);
    setOpenDialog(true);
  };

  return (
    <div className={classes.root}>
      <Button
        id="export-option-button"
        aria-controls="export-option-menu"
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleExportClick}
        className={classes.smallPrimaryBtn}
        disabled={!props.rows.length}
      >
        <ExportIcon className={classes.exportIcon} />
        <span>{props.title ? props.title : 'export'}</span>
      </Button>

      <StyledMenu
        id="export-option-menu"
        MenuListProps={{
          'aria-labelledby': 'export-option-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOption} disableRipple>
          <span>Save as .xls</span>
          <FileDownloadIcon />
        </MenuItem>
        <MenuItem onClick={handleOption} disableRipple>
          <span>Save as .csv</span>
          <FileDownloadIcon />
        </MenuItem>
      </StyledMenu>

      <ExportDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formatText={formatText}
        panelId={props.panelId}
        showMyOwn={props.showMyOwn}
        componentId={props.componentId}
      ></ExportDialog>
    </div>
  );
}
