import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { AddIcon } from '../../../../../../../../../assets/Icons/PanelsList';
import { theme } from '../../../../../../../../../themes/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  addNewNoteBtn: {
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 10,
    },
    padding: 0,
    height: 0,
  },
  addNewIcon: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addNewText: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    fontSize: 14,
    lineHeight: '24px',
  },
});

interface AddNewNoteButtonProps {
  handleClick: (event: any) => void;
}

export const AddNewNoteButton = (props: AddNewNoteButtonProps) => {
  const { handleClick } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        variant="text"
        className={classes.addNewNoteBtn}
        onClick={handleClick}
      >
        <AddIcon className={classes.addNewIcon} />
        <Typography className={classes.addNewText}>Add New</Typography>
      </Button>
    </div>
  );
};
