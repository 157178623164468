import {Checkbox, IconButton, ListItem, Theme, Typography} from '@material-ui/core';
import {theme} from "../../../../../themes/theme";
import {createStyles, makeStyles} from "@material-ui/styles";
import {LabelAvatar} from "../../../../../components/Avatar";
import {RoleType, User} from "../../../../../enum/Users";
import {CloseIcon} from "../../../../../assets/Icons/ExportPatients";
import React from "react";
import {RadioButtonChecked, RadioButtonUnchecked} from '@material-ui/icons';

interface AssignedUserViewProps {
    assignedCoordinatorMember: any;
    siteDesignee: boolean;
    removeSelectedSiteDesignee: any;
    setDefaultDesignee: any;
}

const AVATAR_BG = Object.values(theme.palette.avatars);

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        avatarContainer: {
            height: 56,
            display: 'flex',
            alignItems: 'center',
        },
        avatarStyles: {
            backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
            marginRight: `12px !important`,
            width: `40px !important`,
            height: `40px !important`,
        },
        avatarContent: {
            fontSize: 16,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        listItem: {
            borderBottom: '1px solid #d1d1d1',
            width: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),

            '& .MuiIconButton-root': {
                padding: theme.spacing(0),
            },
        },
        mainContent: {
            display: 'block',
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
        },
        subContent: {
            display: 'block',
            fontSize: 12,
            fontWeight: 600,
            color: theme.palette.text.hint,
        },
        outlineInput: {
            maxWidth: 150,
            color: theme.palette.secondary.dark,
            marginRight: theme.spacing(2),
            fontSize: 16,
        },
        rightContainer: {
            display: 'flex',
            fontSize: 12,
            alignItems: 'center',
        },
        assignedContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(0.66),
            paddingRight: 100
        },
        assignedNumber: {
            color: '#656565',
            fontWeight: 500,
            fontSize: 16,
        },
        assignedText: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            paddingRight: theme.spacing(3),
            color: theme.palette.secondary.dark,
        },
    })
);
export const AssignedUserRow = (
    props: AssignedUserViewProps
) => {
    const classes = useStyle();

    const handleChange = (event: any) => {
        console.log(event.target.checked)
        props.setDefaultDesignee(event.target.checked?props.assignedCoordinatorMember:null);
    };
    return (
        <ListItem>
            <div className={classes.listItem}>
                <LabelAvatar
                    firstName={props.assignedCoordinatorMember.firstName}
                    lastName={props.assignedCoordinatorMember.lastName}
                    title={props.assignedCoordinatorMember?.role?.roleName === RoleType.ADMIN
                        ? RoleType.CARE_MANAGER
                        : props.assignedCoordinatorMember?.role?.roleName ||
                        props.assignedCoordinatorMember?.title}
                    styles={classes}
                />
                <div className={classes.rightContainer}>
                    <div className={classes.assignedContainer}>
                        <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<RadioButtonChecked />}
                            style ={{
                                color: "#0C77D8",
                            }}
                            checked={props.siteDesignee}
                            onChange={handleChange}
                        />
                        <Typography className={classes.assignedNumber}>
                            Site Designee
                        </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            props.removeSelectedSiteDesignee()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </ListItem>
    );
};
