import { makeStyles } from '@material-ui/core';
import { theme } from '../../../../../../../themes/theme';

export const useCareGapDrawerHeaderStyles = makeStyles({
  nameAndEligibilityBadgeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  statusInfoIcon: {
    width: 32,
    height: 32,
  },
  readOnlyChip: {
    backgroundColor: theme.palette.error.light,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main,
    lineHeight: '24px',
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    height: 40,
    gap: 4,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '&:hover': {
      background: theme.palette.error.light,
      cursor: 'text',
      userSelect: 'text',
    },
  },
  readOnlyChipIcon: {
    color: theme.palette.error.main,
    marginLeft: 0,
    marginRight: 0,
    width: 24,
    height: 24,
  },
  iconStyleMenu: {
    height: 24,
    width: 24,
    color: theme.palette.grey[500],
  },
  menu: {
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded': {
      marginTop: 0,
      width: 200,
    },
    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul': {
      padding: theme.spacing(0.5, 1),
      position: 'relative',
    },

    '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul::after': {
      content: '""',
      position: 'absolute',
      bottom: 43,
      left: 0,
      display: 'block',
      width: 200,
      height: 1,
      background: theme.palette.grey[300],
    },

    '& li[role="menuitem"]': {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 35,
      borderRadius: 8,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 4,
    },

    '& li[role="menuitem"]:first-child': {
      marginTop: 8,
    },
    '& li[role="menuitem"]:last-child': {
      marginBottom: 8,
    },
  },
  moreHorizonIcon: { color: theme.palette.grey[500] },
  editButton: {
    position: 'relative',
    backgroundColor: theme.palette.grey['50'],
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
  },
  coordinatorNameButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    '& > .MuiPaper-root': {
      width: 800,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  drawerHeaderContainer: {
    height: 'fit-content',
    width: '100%',
    marginTop: theme.spacing(2.5),
    position: 'relative',
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  drawerHeaderTopContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3, 0, 3),
  },
  drawerHeaderTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 32,
    color: theme.palette.grey[700],
  },
  drawerHeaderSubscript: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    padding: theme.spacing(0, 3),
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
  },
  dobContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4
  },
  icon: {
    '& svg': {
      color: '#26ACE2',
      fontSize: 16,
    },
  },


  clinicPatientIDPairContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    flexWrap: 'wrap',
    paddingLeft: 24,
    marginTop: 8,
  },
  clinicPatientIDPairItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  clinicPatientIDPairItemClinicIDTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 8
  },

  clinicPatientIDPairItemPatientIDContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  drawerHeaderArrows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 48,
  },
  arrowBtn: {
    width: 32,
    height: 32,
    borderRadius: '50%',
  },
  arrowIcon: {
    fontSize: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  arrowIconDisabled: {
    color: theme.palette.grey['300'],
    // backgroundColor: 'transparent',
  },
  arrowIconEnabled: {
    color: theme.palette.primary.main,
  },
  drawerHeaderAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3, 3, 3),
  },
  drawerHeaderActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
  },
  attemptBtnText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  outReachCloseButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.grey[500]}!important`,
    background: `${theme.palette.secondary.light}!important`,
  },
  attemptIcon: {
    backgroundColor: '#0C77D8',
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    fontSize: 16,
  },
  phoneIcon: {
    width: 24,
    height: 24,
    paddingTop: 4,
  },
  openInNewTab: {
    color: theme.palette.grey[400],
  },
  meAndOpenNewContainer: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      width: 'fit-content',
      maxWidth: 180,
    },
    '& p:last-child': {
      fontWeight: 600,
    },
  },
  navigateNext: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  rotateNavigateNext: {
    transform: `rotate(90deg)`,
  },
  radio: {
    '&$checked': {
      color: '#0C77D8',
    },
  },
  iconButton: {
    color: theme.palette.primary.dark,
    width: 40,
    height: 40,
    fontSize: 24,
    '&:hover': {
      borderRadius: 8,
    },
  },
  disabledIconButton: {
    color: theme.palette.grey[500],
    width: 40,
    height: 40,
    fontSize: 24,
  },
  reopenButton:{
    color: '#FFFFFF',
    backgroundColor: '#0C77D8',
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  dropDownContainer: {},
});

export const useECWCopyLabelStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    width: 'fit-content',
    height: 24,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
    boxSizing: 'border-box',
  },
  label: {
    '& span': {
      color: '#0F4A6B',
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  icon: {
    '& svg': {
      color: '#0F4A6B',
      fontSize: 16,
    },
  },
});
