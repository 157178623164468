import React from 'react';
import {
  TextareaAutosize,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { MAX_TEXT_LENGTH } from '../../../PatientInfo/components/EditNotes';
import { useOverLimit } from '../../../PatientInfo/hooks/useOverLimit';
import { useTextAreaBorder } from '../../../PatientInfo/hooks/useTextAreaBorder';

interface NoteFormProps {
  title: string;
  control: any;
  register: any;
  errors: any;
  handleSubmit: any;
  submitForm: (input: any, action: string) => void;
}

export const NoteForm: React.FC<NoteFormProps> = ({
  title,
  control,
  register,
  errors,
  handleSubmit,
  submitForm,
}) => {
  const classes = useStyles();

  const { textLength, overLimit } = useOverLimit({ control });
  useTextAreaBorder({ overLimit, errors });

  const getErrorMessageElement = () => {
    if (overLimit.isOverLimit) {
      return (
        <Typography className={classes.errorMsg}>
          {`${overLimit.overLimitMsg} (${textLength.length}/${MAX_TEXT_LENGTH})`}
        </Typography>
      );
    } else if (errors.notes?.type !== 'maxLength') {
      return (
        <>
          {errors.notes && (
            <Typography className={classes.errorMsg}>
              {errors.notes.message}
            </Typography>
          )}
        </>
      );
    }
  };

  return (
    <div className={classes.root}>
      <form
        onSubmit={handleSubmit(submitForm)}
        className={classes.siteFollowUpForm}
      >
        <div className={classes.noteSection}>
          <Typography className={classes.textareaTitle}>{title}</Typography>
          <Controller
            name="notes"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <TextareaAutosize
                  autoFocus
                  ref={register({
                    required: {
                      value: true,
                      message: 'Please type some notes.',
                    },
                    maxLength: {
                      value: MAX_TEXT_LENGTH,
                      message: `The notes cannot exceed ${MAX_TEXT_LENGTH} characters.`,
                    },
                    pattern: {
                      value: /^(?!\s*$).+/g,
                      message: 'The notes cannot contain only white spacing.',
                    },
                  })}
                  name={name}
                  defaultValue={value}
                  placeholder="Type notes"
                  rowsMax={5}
                  style={{ width: '100%', height: 112, overflow: 'auto' }}
                  className={
                    errors.notes
                      ? classes.textarea + ' ' + classes.textareaError
                      : classes.textarea + ' ' + classes.textareaNormal
                  }
                  onChange={onChange}
                />
              );
            }}
          />
          {getErrorMessageElement()}
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
  },
  siteFollowUpForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 24,

    width: '100%',
  },
  noteSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 4,

    width: '100%',
  },
  textareaTitle: {
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',

    '&::after': {
      content: '*',
      color: theme.palette.error.light,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
    },
  },
  textarea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 0, 2),
    resize: 'none',
    color: theme.palette.grey[600],

    '&:focus': {
      outline: 'none !important',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textareaNormal: {
    outline: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  textareaError: {
    outline: 'none !important',
    border: `1px solid ${theme.palette.error.main}`,
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
}));
