import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { theme } from '../../../../themes/theme';

interface Props {
  type: string;
  numerator?: number;
  numeratorText?: any;
  denominator?: number;
  denominatorText?: any;
  currentPct?: number;
  previousPct?: number;
  subtitle: string;
  icon?: any;
  topPadding?: boolean;
  displayPctChip?: boolean;
  iconText?: any;
  isUserCards?: boolean;
  averageCompliance?: String;
  ratioDisable?: boolean;
  stayHeight?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    paddingTop: props.topPadding ? theme.spacing(1.5) : 0,
    alignItems: 'center',
    textAlign: 'center',
  }),
  contentRow: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: (props: Props) => ({
    fontSize: 16,
    color: theme.palette.grey[400],

    '&::after': props.stayHeight
      ? {
          content: '" "',
          width: 12,
          height: 24,
          display: 'block',
        }
      : undefined,
  }),
  subtitleDisable: {
    fontSize: 16,
    color: theme.palette.grey[400],
    opacity: 0.5,
  },
  ratioText: (props: Props) => ({
    fontSize: 48,
    fontWeight: 700,
    color:
      props.denominator &&
      props.numerator &&
      props.numerator > props.denominator
        ? theme.palette.warning.main
        : theme.palette.success.main,
  }),
  icon: (props: Props) => ({
    color:
      props.denominator &&
      props.numerator &&
      props.numerator > props.denominator
        ? theme.palette.warning.main
        : theme.palette.success.main,
    fontSize: 48,
    marginRight: theme.spacing(1.25),
  }),
  span: {
    position: 'relative',
    fontSize: 16,
    color: theme.palette.grey[700],
  },
  chip: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    height: 20,
    backgroundColor: theme.palette.secondary.light,
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.grey[500],
    },
  },

  percentText: {
    fontSize: 48,
    fontWeight: 700,
    color: theme.palette.grey[700],
  },
  pctSpanGreen: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.success.main,
  },
  pctSpanRed: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.error.main,
  },
  arrowIcon: {
    fontSize: 18,
  },

  iconRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RatioView = (props: Props) => {
  const classes = useStyles(props);
  const Icon = props.icon;
  let currentPct = 0;
  if (props.numerator && props.denominator)
    currentPct = Math.round((props.numerator / props.denominator) * 100);
  if (props.currentPct) currentPct = props.currentPct;

  return (
    <div>
      <div className={classes.contentRow}>
        <Icon className={classes.icon} />
        <Typography className={classes.ratioText}>
          {props.numeratorText || props.numerator}
          <span className={classes.span}>
            {props.displayPctChip && (
              <Chip className={classes.chip} label={`${currentPct}%`} />
            )}
            {props.denominatorText || props.denominator
              ? `/${props.denominatorText || props.denominator}`
              : ''}
          </span>
        </Typography>
      </div>
      <Typography
        className={
          props.ratioDisable ? classes.subtitleDisable : classes.subtitle
        }
      >
        {props.subtitle}
      </Typography>
    </div>
  );
};

const PercentageView = (props: Props) => {
  const classes = useStyles(props);
  const Icon = props.icon;
  let currentPct = 0;
  if (props.numerator && props.denominator)
    currentPct = Math.round((props.numerator / props.denominator) * 100);
  if (props.currentPct) currentPct = props.currentPct;

  const renderPctChange = (current: number, prev: number) => {
    let green = true;
    if (prev > current) green = false;

    return (
      <div className={green ? classes.pctSpanGreen : classes.pctSpanRed}>
        {green ? (
          <ArrowUpward className={classes.arrowIcon} />
        ) : (
          <ArrowDownward className={classes.arrowIcon} />
        )}
        <span>{Math.abs(current - prev)}%</span>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.contentRow}>
        <Icon className={classes.icon} />
        {props.averageCompliance ? (
          <Typography className={classes.percentText}>
            {props.averageCompliance}
          </Typography>
        ) : (
          <Typography className={classes.percentText}>{currentPct}%</Typography>
        )}
        {props.previousPct && renderPctChange(currentPct, props.previousPct)}
      </div>
      <Typography className={classes.subtitle}>{props.subtitle}</Typography>
    </div>
  );
};

const IconView = (props: Props) => {
  const classes = useStyles(props);
  const Icon = props.icon;

  return (
    <div>
      <div className={classes.iconRow}>
        <Icon
          className={classes.icon}
          style={{
            color: props.isUserCards ? theme.palette.warning.main : '',
          }}
        />
        <Typography className={classes.percentText}>
          {props.iconText}
        </Typography>
      </div>
      <Typography className={classes.subtitle}>{props.subtitle}</Typography>
    </div>
  );
};

export const CardRatioView = (props: Props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {props.type === 'ratio' && <RatioView {...props} />}
      {props.type === 'percentage' && <PercentageView {...props} />}
      {props.type === 'icon' && <IconView {...props} />}
    </div>
  );
};
