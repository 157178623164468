import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

interface EmptyPlaceHolderProps {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 1, 3, 1),
    height: 40,
    borderRadius: 6,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  name: {
    paddingLeft: theme.spacing(1.5),
    fontSize: 14,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
  },
  emptyName: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#ABABAB',
  },
}));

export const EmptyPlaceHolder = (props: EmptyPlaceHolderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.profileContainer}>
      <Typography className={classes.emptyName}>{props.text}</Typography>
    </div>
  );
};
