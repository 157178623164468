import {Button, Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect} from "react";

export enum EligibilityTab {
  ipopHealthBatch,
  iClinic,
}

interface IProps {
  selectedTab: EligibilityTab,
  onSelect: (tab: EligibilityTab) => void,
}

export function BatchTab(props: IProps) {
  const {selectedTab, onSelect} = props
  const classes = useStyles()
  const renderTabItem = (title: string, type: EligibilityTab, idx: number) => {
    const isSelected = type === selectedTab
    return <Button key={idx} onClick={() => {
      onSelect && onSelect(type)
    }} variant={'text'} className={classes.itemContainer}>
      <div className={isSelected ? classes.itemSelected : classes.item}>
        <div className={isSelected ? classes.titleSelected : classes.title}>
          {title}
        </div>
      </div>
    </Button>
  }

  return (
    <div className={classes.root}>
      {renderTabItem("iPopHealth Batch", EligibilityTab.ipopHealthBatch, 0)}
      {renderTabItem("iClinic Batch", EligibilityTab.iClinic,1)}
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #d1d1d1',
    backgroundColor: '#F8F8F8',
    paddingLeft: 16,
    height: 48,
  },
  itemContainer: {
    padding: '0px 8px',
    marginRight: 16,
    borderRadius: 0,
    maxWidth: 'content',
    height: 48,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '3px solid #0C77D800',
    height: 48,
  },
  itemSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '3px solid #0C77D8',
    height: 48,
  },
  title: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    color: '#656565',
  },
  titleSelected: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.primary.main,
  }
}));
