import React from 'react';
import { useUnlockCareAttemptMutation } from '../../../../CareGaps/Home/tabs/Population/components/CareGapsDrawer/index.generated';
import { getEnvName } from '../../../../../sharePipe/getEnvName';

export const useUnlockPatientClosingPFS = (
  showDrawer: boolean,
  patientFaceInfo: any,
  panelId: string
) => {
  const [pfsOpenAndClosed, setPfsOpenAndClosed] = React.useState(false);
  // release patient when PFS is closed
  const [unlockCareAttemptMutation] = useUnlockCareAttemptMutation();
  const unlockCurrentPatient = async (panelId: string, patientId: string) => {
    const { data, errors } = await unlockCareAttemptMutation({
      variables: {
        input: {
          panelId: panelId,
          patientId: patientId,
        },
      },
    });
    if (errors || !data) {
      if (getEnvName() === 'dev' || getEnvName() === 'qa') {
        console.log(`Failed to call unlock handler, please try again.`);
      }
      setPfsOpenAndClosed(true);
    }
    if (data?.unlockCareAttempt) {
      if (getEnvName() === 'dev' || getEnvName() === 'qa') {
        console.log(
          `Code: ${data.unlockCareAttempt.code}, Message:${data.unlockCareAttempt.message}`
        );
      }
      setPfsOpenAndClosed(true);
    }
  };

  React.useEffect(() => {
    if (showDrawer === false && patientFaceInfo) {
      unlockCurrentPatient(panelId, patientFaceInfo.id);
    }
  }, [showDrawer, patientFaceInfo, panelId]);

  return { pfsOpenAndClosed };
};
