import React from 'react';
import { EmptyPlaceHolder } from '../../../../../../../../../components/EmptyPlaceHolder';
import { Chip, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { useSubscriberIDCellStyles } from './Styles';

interface SubscriberIDCellProps {
  // pass the subscriberID array
  subscriberIDs: string[] | null | undefined;
}

export const SubscriberIDCell = (props: SubscriberIDCellProps) => {
  const { subscriberIDs } = props;
  const classes = useSubscriberIDCellStyles();
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const handlePropagationAndDefault = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const handleClick = (event: React.MouseEvent) => {
    handlePropagationAndDefault(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    handlePropagationAndDefault(event);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'subscriberIDList' : undefined;

  if (!subscriberIDs || subscriberIDs.length === 0) {
    // if the subscriber id is nil, show the default
    return <EmptyPlaceHolder text={'No Subscriber ID'} />;
  } else if (subscriberIDs.length === 1) {
    // if there is only one item,
    return <span>{subscriberIDs[0]}</span>;
  } else {
    // if there are multiple ids, show a popup to list all
    const newSubscriberIDs = ['Subscriber ID', ...subscriberIDs];
    return (
      <>
        <div className={classes.subscriberIDsItem} onClick={handleClick}>
          <span>{subscriberIDs[0]}</span>
          <Chip
            className={classes.chip}
            label={`+${subscriberIDs.length - 1}`}
          />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={2}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClick={handlePropagationAndDefault}
          className={classes.popover}
        >
          <List className={classes.list}>
            {newSubscriberIDs.map((item, index) => {
              return (
                <ListItem
                  key={item}
                  className={
                    index === 0 ? classes.listHeader : classes.listItem
                  }
                >
                  <ListItemText primary={item} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </>
    );
  }
};
