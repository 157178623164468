import { Avatar, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Maybe } from 'graphql/jsutils/Maybe';
import { theme } from '../../themes/theme';

const SPACE: number = 20;
const AVATAR_BG = Object.values(theme.palette.avatars);

type LabelAvatarProps = {
  firstName: string;
  lastName: string;
  title?: string;
  avatarUrl?: Maybe<string> | undefined;
  styles?: ClassNameMap<
    | 'avatarContainer'
    | 'avatarStyles'
    | 'avatarContent'
    | 'mainContent'
    | 'subContent'
  >;
  careTeamSelectInputValue?: string;
};

type GroupAvatarProps = {
  data: any[];
  maxNum: number;
};

const useStyles = makeStyles({
  matchedText: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  pcpContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pcpAvatarStyles: {
    backgroundColor: AVATAR_BG[AVATAR_BG.length - 1],
    marginRight: theme.spacing(1.5),
  },
  pcpAvatarContent: {
    fontSize: '10px',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0px',
  },
  pcpContent: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '24px',
    color: theme.palette.secondary.contrastText,
    whiteSpace: 'nowrap',
  },
  careTeamContainer: {
    width: 'fit-content',
    height: 30,
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  careTeamAvatarStyles: {
    position: 'absolute',
    top: 'auto',
    border: `${theme.palette.primary.contrastText} solid 3px`,
  },
  careTeamAvatarContentStyles: {
    fontSize: '10px',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0px',
  },
  careTamBadgeStyles: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0px',
  },
});

export function GroupAvatar(props: GroupAvatarProps) {
  const { data, maxNum } = props;

  const classes = useStyles();

  return (
    <div className={classes.careTeamContainer}>
      {data.map((item: any, idx: any) => {
        if (idx < maxNum) {
          return (
            <Avatar
              key={idx}
              className={classes.careTeamAvatarStyles}
              alt={`${item.firstName} ${item.lastName}`}
              src={item.avatarUrl ? item.avatarUrl : ''}
              style={{
                left: `${idx * SPACE}px`,
                zIndex: maxNum - idx,
                backgroundColor: AVATAR_BG[idx],
              }}
            >
              <span className={classes.careTeamAvatarContentStyles}>
                {item.avatarUrl
                  ? ''
                  : item?.user
                  ? `${item.user.firstName ? item?.user.firstName[0] : ''}${
                      item.user.lastName ? item.user.lastName[0] : ''
                    }`
                  : `${item.firstName ? item?.firstName[0] : ''}${
                      item.lastName ? item.lastName[0] : ''
                    }`}
              </span>
            </Avatar>
          );
        } else {
          return null;
        }
      })}
      {data.length > maxNum ? (
        <Avatar
          key={maxNum}
          className={classes.careTeamAvatarStyles}
          alt={`+${maxNum}`}
          style={{
            left: `${(data.length - maxNum > 9 ? 3.5 : 3) * SPACE}px`,
            background: theme.palette.primary.light,
          }}
        >
          <span
            className={`${classes.careTeamAvatarContentStyles} ${classes.careTamBadgeStyles}`}
          >{`+${data.length - maxNum}`}</span>
        </Avatar>
      ) : null}
    </div>
  );
}

export function LabelAvatar(props: LabelAvatarProps) {
  const {
    firstName,
    lastName,
    title,
    avatarUrl,
    styles,
    careTeamSelectInputValue,
  } = props;

  const classes = useStyles();

  /* partial match typed keyword */
  const escapeRegExp = (str = '') =>
    str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  const regex = new RegExp(`(${escapeRegExp(careTeamSelectInputValue)})`, 'gi');
  const firstNamePart = firstName.split(regex);
  const lastNamePart = lastName.split(regex);

  return (
    <div className={(styles && styles.avatarContainer) || classes.pcpContainer}>
      <Avatar
        className={(styles && styles.avatarStyles) || classes.pcpAvatarStyles}
        alt={`${firstName} ${lastName}`}
        src={avatarUrl ? avatarUrl : ''}
      >
        <span
          className={
            (styles && styles.avatarContent) || classes.pcpAvatarContent
          }
        >
          {avatarUrl ? null : `${firstName[0]}${lastName[0]}`}
        </span>
      </Avatar>

      {/* partial match typed keyword */}
      <span className={(styles && styles.mainContent) || classes.pcpContent}>
        {careTeamSelectInputValue ? (
          <span>
            {firstNamePart
              .filter((part: string) => part)
              .map((part: string, idx: number) =>
                regex.test(part) ? (
                  <span className={classes.matchedText} key={idx}>
                    {part}
                  </span>
                ) : (
                  <span key={idx}>{part}</span>
                )
              )}
            (
            {lastNamePart
              .filter((part: string) => part)
              .map((part: string, idx: number) =>
                regex.test(part) ? (
                  <span className={classes.matchedText} key={idx}>
                    {part}
                  </span>
                ) : (
                  <span key={idx}>{part}</span>
                )
              )}
            )
          </span>
        ) : (
          <span>{`${firstName} ${lastName}`}</span>
        )}
        {title ? (
          <span className={(styles && styles.subContent) || classes.pcpContent}>
            {title}
          </span>
        ) : (
          <span
            className={(styles && styles.subContent) || classes.pcpContent}
          ></span>
        )}
      </span>
    </div>
  );
}
