import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGetPayerFilesQuery } from '../../../Files/PayerList/index.generated';
import { useFetchTableData } from '../../../../../../hooks/useFetchTableData';
import { MainRegion } from '../../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../../components/BreadCrumbHeader';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { PayerPayerFileListingTableView } from './PayerPayerFileListingTable';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const PayerFiles = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [fileName, setFileName] = React.useState('');
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {});
  const payerName = location?.state?.payerName ?? '';
  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { state: { payerName: payerName } });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  const [sortBy, setSortBy] = React.useState({
    field: 'fileName',
    method: 'asc' as const,
  });

  const patients = useGetPayerFilesQuery({
    variables: {
      request: {
        fileName: fileName,
        payerId: id,
        limit: limit ?? 10,
        skip: skip ?? 0,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  const queryResult = useFetchTableData(patients);

  React.useEffect(() => {
    try {
      setTitle(payerName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [payerName]);

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={title}
          rootLink={`/payerID`}
          rootName={'Payer ID'}
          isTitle={true}
          title={title}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            {!patients.called || patients.loading ? (
              <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />
            ) : (
              <div style={{ width: '100%' }}>
                <PayerPayerFileListingTableView
                  refetchDataFunc={{
                    fileName: fileName,
                    func: patients.refetch,
                    params: {
                      payerId: id,
                      limit: limit ?? 10,
                      skip: skip ?? 0,
                      sortBy: [{ ...sortBy }],
                    },
                  }}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  results={
                    queryResult ? queryResult.getPayerFiles?.results : []
                  }
                  count={queryResult ? queryResult.getPayerFiles?.count : 0}
                  limit={limit ?? 10}
                  skip={skip ?? 0}
                  setLimit={onSetLimit}
                  setSkip={onSetSkip}
                  id={id}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
