import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import { StyledRadio } from '../../../../../../../../../../components/StyledRadio';
import moment from 'moment';
import { CalendarIcon } from '../../../../../../../../../../assets/Icons/CareGaps';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  listItemLarge:{
    paddingTop: 16,
    paddingBottom: 16
  },
  listItem:{
    paddingTop: 8,
    paddingBottom: 8
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: '#434343',
  },
  list: {
    borderStyle: 'solid',
    border: 1,
    borderColor: '#0000001F',
    borderRadius: '8px',
    marginTop: 4,
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& li div.MuiListItem-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  selectDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  selectDateTitle: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
  },
  itemText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
  },
  calendarIcon: {
    color: '#0C77D8',
  },
  icon: {
    color: '#888888',
  },
}));

const datePickerTheme = createMuiTheme({
  typography: {
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 'bold',
  },
  palette: {
    primary: {
      main: '#0C77D8',
      light: '#EDF6FF',
      dark: '#0761B3',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#E5E5E5',
      light: '#EFEFEF',
      dark: '#656565',
      contrastText: '#656565',
    },
    text: {
      primary: 'rgba(67, 67, 67, 100)',
      secondary: 'rgba(101, 101, 101, 100)',
      disabled: 'rgba(209, 209, 209, 100)',
      hint: 'rgba(136, 136, 136, 100)',
    },
    grey: {
      '50': '#F8F8F8',
      '100': '#EFEFEF',
      '200': '#E5E5E5',
      '300': '#D1D1D1',
      '400': '#ABABAB',
      '500': '#888888',
      '600': '#656565',
      '700': '#434343',
    },
    info: {
      light: '#EDF6FF',
      main: '#0C77D8',
      dark: '#0761B3',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#E8F6F2',
      main: '#008479',
      dark: '#105751',
      contrastText: '#fff',
    },
    warning: {
      light: '#FCF3E5',
      main: '#D46829',
      dark: '#913C00',
      contrastText: '#fff',
    },
    error: {
      light: '#FFEAEB',
      main: '#B7373E',
      dark: '#A02C32',
      contrastText: '#fff',
    },
    avatars: {
      yellow: '#E1A230',
      voilet: '#BC62CD',
      teal: '#49BFA9',
      red: '#E66556',
      purple: '#8061D7',
      pink: '#D26EAA',
      orange: '#E4814B',
      green: '#8AC55C',
      cyan: '#41C3E8',
      blue: '#586EFF',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0C77D8',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFF',
        fontSize: 20,
        // textDecoration: 'underline'
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        fontSize: '30px!important',
        color: '#0C77D8!important',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      // day: {
      //     color: '#0C77D8',
      // },
      // daySelected: {
      //     backgroundColor: '#0C77D8',
      // },
      // dayDisabled: {
      //     color: '#0C77D8',
      // },
      // current: {
      //     color: '#0C77D8',
      // },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#0C77D8',
      },
    },
    MuiTextField: {
      root: {
        marginTop: 0,
        // height: 40,
        borderRadius: 6,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    MuiInputBase: {
      input: {
        height: 3,
        fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
  } as any,
});

enum SnoozeDateType {
  doNotSnooze,
  day1,
  days3,
  days7,
  custom,
}

interface SnoozeDatePickerProps {
  onSelectedDate: (snoozeDate: string) => void;
  snoozeOnly?: boolean;
  setDisabled?: any
}

export const SnoozeDatePicker = ({ onSelectedDate,snoozeOnly,setDisabled }: SnoozeDatePickerProps) => {
  const [snoozeDateType, setSnoozeDateType] = useState(SnoozeDateType.days3);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let snoozeDate = '';
    if(setDisabled!=undefined){
      setDisabled(snoozeDateType==SnoozeDateType.doNotSnooze)
    }
    switch (snoozeDateType) {
      case SnoozeDateType.doNotSnooze:
        snoozeDate = '';
        break;
      case SnoozeDateType.day1:
        snoozeDate = moment().add(1, 'day').format('YYYY-MM-DD');
        break;
      case SnoozeDateType.days3:
        snoozeDate = moment().add(3, 'day').format('YYYY-MM-DD');
        break;
      case SnoozeDateType.days7:
        snoozeDate = moment().add(7, 'day').format('YYYY-MM-DD');
        break;
      case SnoozeDateType.custom:
        if (customDate) {
          snoozeDate = moment(customDate).format('YYYY-MM-DD');
        }
        break;
      default:
    }
    onSelectedDate(snoozeDate);
  }, [snoozeDateType, onSelectedDate, customDate]);

  // useEffect(() => {
  //   if (snoozeDateType === SnoozeDateType.custom) {
  //     setCustomDate(new Date());
  //   } else {
  //     setCustomDate(null);
  //   }
  // }, [snoozeDateType]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Snooze patient for how long? (Optional)
      </Typography>
      <List className={classes.list}>
        <ListItem
          button
          onClick={() => {
            setSnoozeDateType(SnoozeDateType.doNotSnooze);
          }}
          className={snoozeOnly?classes.listItemLarge: classes.listItem}
        >
          <div className={classes.itemText}>Do not snooze</div>
          <ListItemSecondaryAction>
            <StyledRadio
              checked={snoozeDateType === SnoozeDateType.doNotSnooze}
              color="primary"
              style={{}}
              onClick={() => {
                setSnoozeDateType(SnoozeDateType.doNotSnooze);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="middle" />
        <ListItem
          button
          onClick={() => {
            setSnoozeDateType(SnoozeDateType.day1);
          }}
          className={snoozeOnly?classes.listItemLarge: classes.listItem}
        >
          <div className={classes.itemText}>1 Day</div>
          <ListItemSecondaryAction>
            <StyledRadio
              checked={snoozeDateType === SnoozeDateType.day1}
              color="primary"
              onClick={() => {
                setSnoozeDateType(SnoozeDateType.day1);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="middle" />
        <ListItem
          button
          onClick={() => {
            setSnoozeDateType(SnoozeDateType.days3);
          }}
          className={snoozeOnly?classes.listItemLarge: classes.listItem}
        >
          <div className={classes.itemText}>3 Days</div>
          <ListItemSecondaryAction>
            <StyledRadio
              checked={snoozeDateType === SnoozeDateType.days3}
              color="primary"
              onClick={() => {
                setSnoozeDateType(SnoozeDateType.days3);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="middle" />
        <ListItem
          button
          onClick={() => {
            setSnoozeDateType(SnoozeDateType.days7);
          }}
          className={snoozeOnly?classes.listItemLarge: classes.listItem}
        >
          <div className={classes.itemText}>7 Days</div>
          <ListItemSecondaryAction>
            <StyledRadio
              checked={snoozeDateType === SnoozeDateType.days7}
              color="primary"
              onClick={() => {
                setSnoozeDateType(SnoozeDateType.days7);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="middle" />
        <ListItem
          button
          onClick={() => {
            setSnoozeDateType(SnoozeDateType.custom);
            setOpenDatePicker(true);
          }}
          className={snoozeOnly?classes.listItemLarge: classes.listItem}
        >
          <div className={classes.itemText}>
            <div className={classes.selectDateContainer}>
              <CalendarIcon
                className={
                  snoozeDateType === SnoozeDateType.custom
                    ? classes.calendarIcon
                    : classes.icon
                }
              />
              <span className={classes.selectDateTitle}>
                {customDate
                  ? moment(customDate).format('[Until] MMM DD, YYYY')
                  : 'Select Date'}
              </span>
            </div>
          </div>
          <ListItemSecondaryAction>
            <StyledRadio
              checked={snoozeDateType === SnoozeDateType.custom}
              color="primary"
              onClick={() => {
                setSnoozeDateType(SnoozeDateType.custom);
                setOpenDatePicker(true);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {openDatePicker ? (
        <DatePickerDialog
          open={openDatePicker}
          setOpen={setOpenDatePicker}
          selectDate={customDate}
          onSelectDate={(date) => {
            setOpenDatePicker(false);
            setCustomDate(date);
          }}
          setSnoozeDateType={setSnoozeDateType}
        />
      ) : null}
    </div>
  );
};

interface DatePickerDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectDate: Date | null;
  onSelectDate: (date: Date| null) => void;
  setSnoozeDateType: (type: SnoozeDateType)=> void
}

const DatePickerDialog = ({
  open,
  setOpen,
  selectDate,
  onSelectDate,
  setSnoozeDateType
}: DatePickerDialogProps) => {
  const handleDateChange = (date: Date | null) => {
    onSelectDate(date);
  };
  const handleBackDropClick =(reason: string)=>{
    if(reason=='backdropClick'){
      if(selectDate==null){
        setSnoozeDateType(SnoozeDateType.doNotSnooze)
      }
      setOpen(false);
    }
  }
  return (
    <Dialog open={open} onClose={(event, reason)=>{handleBackDropClick(reason)}}>
      <MuiThemeProvider theme={datePickerTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            disablePast
            clearable
            value={selectDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Dialog>
  );
};
