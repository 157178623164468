import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPredictedTeamUsersWithFilterQueryVariables = Types.Exact<{
  input: Types.EditPanelInput;
}>;


export type GetPredictedTeamUsersWithFilterQuery = (
  { __typename: 'Query' }
  & { getPredictedTeamUsersWithFilter: Array<(
    { __typename: 'CareTeamMember' }
    & Pick<Types.CareTeamMember, 'count'>
  )> }
);


export const GetPredictedTeamUsersWithFilterDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPredictedTeamUsersWithFilter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditPanelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPredictedTeamUsersWithFilter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPredictedTeamUsersWithFilterQuery__
 *
 * To run a query within a React component, call `useGetPredictedTeamUsersWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPredictedTeamUsersWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPredictedTeamUsersWithFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPredictedTeamUsersWithFilterQuery(baseOptions: Apollo.QueryHookOptions<GetPredictedTeamUsersWithFilterQuery, GetPredictedTeamUsersWithFilterQueryVariables>) {
        return Apollo.useQuery<GetPredictedTeamUsersWithFilterQuery, GetPredictedTeamUsersWithFilterQueryVariables>(GetPredictedTeamUsersWithFilterDocument, baseOptions);
      }
export function useGetPredictedTeamUsersWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPredictedTeamUsersWithFilterQuery, GetPredictedTeamUsersWithFilterQueryVariables>) {
          return Apollo.useLazyQuery<GetPredictedTeamUsersWithFilterQuery, GetPredictedTeamUsersWithFilterQueryVariables>(GetPredictedTeamUsersWithFilterDocument, baseOptions);
        }
export type GetPredictedTeamUsersWithFilterQueryHookResult = ReturnType<typeof useGetPredictedTeamUsersWithFilterQuery>;
export type GetPredictedTeamUsersWithFilterLazyQueryHookResult = ReturnType<typeof useGetPredictedTeamUsersWithFilterLazyQuery>;
export type GetPredictedTeamUsersWithFilterQueryResult = Apollo.QueryResult<GetPredictedTeamUsersWithFilterQuery, GetPredictedTeamUsersWithFilterQueryVariables>;
export function refetchGetPredictedTeamUsersWithFilterQuery(variables?: GetPredictedTeamUsersWithFilterQueryVariables) {
      return { query: GetPredictedTeamUsersWithFilterDocument, variables: variables }
    }