import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../components/GlobalContext';
import Auth from '@aws-amplify/auth';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import {MixPanelEvents} from "../../mixpanel/events";

type PropTypes = {
  isLoading: boolean;
  loggedInUser?: User;
};

const LoggedInView = ({ loggedInUser }: { loggedInUser: User }) => (
  <>
    <h1>Hello {loggedInUser?.firstName}</h1>
    <div>
      <Link to="/profile">View your profile</Link>
    </div>
    <div>
      <Link to="/panels">View panels</Link>
    </div>
    <button onClick={() => { MixPanelEvents.reset(); Auth.signOut(); }}>Logout</button>
  </>
);

const AnonymousView = () => (
  <>
    <h1>
      <Link to="/login">Login</Link>
    </h1>
  </>
);

export function View(props: PropTypes) {
  const { isLoading, loggedInUser } = props;

  if (isLoading) {
    return <LoadingSpinner style={{ width: '100%' }} />;
  }

  return (
    <div>
      <h1>Welcome</h1>

      {loggedInUser ? (
        <LoggedInView loggedInUser={loggedInUser} />
      ) : (
        <AnonymousView />
      )}
    </div>
  );
}
