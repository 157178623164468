import React from 'react';
import { useGetPatientCountByUserIdQuery } from '../index.generated';
import { useGetPanelsListQuery } from './index.generated';

export const usePanelPatientCount = (showMyOwn: string) => {
  const [fetching, setFetching] = React.useState(true);
  const [numOfPanels, setNumOfPanels] = React.useState(0);
  const [numOfPatients, setNumOfPatients] = React.useState(0);

  const patientCountQuery = useGetPatientCountByUserIdQuery({
    variables: {
      input: {
        showMyOwn: showMyOwn,
        limit: 10,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
  });
  const panelListsQuery = useGetPanelsListQuery({
    variables: {
      input: {
        showMyOwn: showMyOwn,
        limit: 10,
        skip: 0,
        sortBy: [{ field: 'createdAt', method: 'desc' }],
      },
    },
  });

  React.useEffect(() => {
    if (
      !panelListsQuery.called ||
      panelListsQuery.loading ||
      !patientCountQuery.called ||
      patientCountQuery.loading
    ) {
      setFetching(true);
    } else {
      setFetching(false);
      setNumOfPanels(panelListsQuery.data?.getPanels.count ?? 0);
      setNumOfPatients(
        patientCountQuery.data?.getPatientByCareTeamUserId.count ?? 0
      );
    }
  }, [panelListsQuery, patientCountQuery]);

  return {
    fetching,
    numOfPanels,
    setNumOfPanels,
    numOfPatients,
    setNumOfPatients,
  };
};
