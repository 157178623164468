import React from 'react';
import { useCreatePanelMutation } from './index.generated';
import { MasterForm } from './MasterForm';
import { useGetCareGapFilterOptionsQuery } from '../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../components/DialogBox';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { CreatePanelInput } from '../../../app.types.generated';

type Props = {
  onCreate: (panelId: string) => any;
};

export const CreatePanelContainer = (props: Props) => {
  const [createPanel] = useCreatePanelMutation();
  const [panelId, setPanelId] = React.useState('');
  const [taskId, setTaskId] = React.useState('');
  const [showDialog, setShowDialog] = React.useState<boolean>(true);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog
  );

  const getCareGapFilterOptions = useGetCareGapFilterOptionsQuery();
  if (getCareGapFilterOptions.loading) {
    return <LoadingSpinner />;
  }

  // todo
  async function onCreatePanel(input: CreatePanelInput) {
    const { data } = await createPanel({
      variables: { input },
    });
    setShowDialog(false);
    setPanelId(data!.createPanel.entityId);
    setTaskId(data!.createPanel.taskId);
    return true;
  }

  return (
    <>
      <LeaveEditDialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <MasterForm
        onCreate={onCreatePanel}
        panelId={panelId}
        taskId={taskId}
        options={getCareGapFilterOptions?.data?.getCareGapFilterOptions}
      />
    </>
  );
};
