import React from 'react';
import { SMeasureListQuery, useSMeasureListQuery } from './index.generated';
import { CareGapsListView } from './View';


export const CareGapsListContainer = () => {
  const careGapsListQuery = useSMeasureListQuery();

  const fetchCareGapsListData = (
    queryResult: SMeasureListQuery | undefined
  ) => {
    return queryResult?.sMeasureList;
  };

  const careGapsList = React.useMemo(() => {
    if (careGapsListQuery.error) {
      return [];
    } else if (careGapsListQuery.data) {
      return fetchCareGapsListData(careGapsListQuery.data);
    }
  }, [careGapsListQuery]);

  return (
    <>
      <CareGapsListView careGapsList={careGapsList ?? []} />
    </>
  );
};
