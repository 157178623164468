import React from 'react';
import {
  ageOptions,
  genderOptions,
} from '../../../../../enum/Dashboard/providerPatientFilterOptions';
import { ProviderPatientFilterView } from './View';
import {
  GetCareGapFilterOptionsQuery,
  useGetCareGapFilterOptionsQuery,
} from '../../../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';

interface ProviderPatientFilterContainerProps {
  selectedFilter: any | undefined;
  setSelectedFilter: any;
  setIsSearching?: any;
  applyFilterBtnIsClicked: boolean;
  setApplyFilterBtnIsClicked: (input: boolean) => void;
  loading: boolean;
  displayedCount: number;
  setSortBy: (input: any) => void;
}

export const ProviderPatientFilterContainer = (
  props: ProviderPatientFilterContainerProps
) => {
  const {
    selectedFilter,
    setSelectedFilter,
    setIsSearching,
    applyFilterBtnIsClicked,
    setApplyFilterBtnIsClicked,
    loading,
    displayedCount,
  } = props;

  const openCareGapOptionsQuery = useGetCareGapFilterOptionsQuery();

  const getOpenCareGapOptions = (
    query: GetCareGapFilterOptionsQuery | undefined
  ) => {
    return query?.getCareGapFilterOptions;
  };

  const openCareGapOptions = React.useMemo(
    () => getOpenCareGapOptions(openCareGapOptionsQuery?.data),
    [openCareGapOptionsQuery.data]
  );

  return (
    <ProviderPatientFilterView
      setSortBy={props.setSortBy}
      options={{
        Age: ageOptions,
        Gender: genderOptions,
        CareGap: openCareGapOptions?.CareGap || [],
      }}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      setIsSearching={setIsSearching}
      applyFilterBtnIsClicked={applyFilterBtnIsClicked}
      setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
      loading={loading}
      displayedCount={displayedCount}
    />
  );
};
