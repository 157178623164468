import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ProviderListTableContainer } from './Container';

type Props = {
  setSelectedPerformanceOptions: (input: string[]) => void;
  selectedPerformanceOptions: string[];
  tableType: string;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
};

export const RoutedProviderListTable = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  return (
    <ProviderListTableContainer
      limit={limit}
      skip={skip}
      setLimit={onSetLimit}
      setSkip={onSetSkip}
      setSelectedPerformanceOptions={props.setSelectedPerformanceOptions}
      selectedPerformanceOptions={props.selectedPerformanceOptions}
      tableType={props.tableType}
      setLoading={props.setLoading}
      setDisplayedCount={props.setDisplayedCount}
    />
  );
};
