
interface IProps {
    style?: any,
}

// 35px

export const ActiveInternalIcon32 = (props: IProps) => {
    return (
        <svg {...props} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3421 17.9999L15.4484 22.1046L23.6577 13.8953M17.5 2C26.3355 2 33.5 9.16281 33.5 18C33.5 26.8355 26.3355 34 17.5 34C8.66281 34 1.5 26.8355 1.5 18C1.5 9.16281 8.66281 2 17.5 2Z" stroke="#519548" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ActiveExternalIcon32 = (props: IProps) => {
    return (
        <svg {...props} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3421 17.9999L15.4484 22.1046L23.6577 13.8953M17.5 2C26.3355 2 33.5 9.16281 33.5 18C33.5 26.8355 26.3355 34 17.5 34C8.66281 34 1.5 26.8355 1.5 18C1.5 9.16281 8.66281 2 17.5 2Z" stroke="#41DAAF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UnverifiedIcon32 = (props: IProps) => {
    return (
        <svg {...props} width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4949 18.2843V12.9253M18.482 23.6176H18.4993M6.06819 30.4232H30.932C33.6668 30.4232 35.3834 27.4671 34.0247 25.0919L21.6023 3.37078C20.2349 0.979996 16.7878 0.978267 15.4187 3.36906L2.97555 25.0901C1.61679 27.4654 3.33166 30.4232 6.06819 30.4232Z" stroke="#3B89C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const InactiveIcon32 = (props: IProps) => {
    return (
        <svg {...props} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6454 22.132L13.3496 13.8345M21.6427 13.8378L13.3538 22.1267M24.9966 2H10.0016C4.77605 2 1.49994 5.69989 1.49994 10.9358V25.0642C1.49994 30.3001 4.76048 34 10.0016 34H24.9949C30.2377 34 33.4999 30.3001 33.4999 25.0642V10.9358C33.4999 5.69989 30.2377 2 24.9966 2Z" stroke="#FA2A00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UnavailableIcon32 = (props: IProps) => {
    return (
        <svg {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5 2C27.3355 2 34.5 9.16281 34.5 18C34.5 26.8355 27.3355 34 18.5 34C9.66281 34 2.5 26.8355 2.5 18C2.5 9.16281 9.66281 2 18.5 2Z" stroke="#707070" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.9829 15.0686C14.2746 15.0686 13.9204 14.686 13.9204 13.9208C13.9204 12.7887 14.3369 11.8875 15.1698 11.2171C15.9897 10.5593 17.0456 10.2305 18.3377 10.2305C19.8986 10.2305 21.0956 10.6447 21.9285 11.4732C22.6959 12.2385 23.0796 13.282 23.0796 14.6038C23.0796 15.9572 22.5516 17.2094 21.4956 18.3605C20.5774 19.3471 19.4133 20.125 18.0032 20.6942C17.8982 20.7385 17.8097 20.7606 17.7375 20.7606C17.672 20.7606 17.6096 20.7511 17.5506 20.7321C17.4981 20.7132 17.4391 20.6721 17.3735 20.6088C17.2161 20.457 17.085 20.2641 16.98 20.0301C16.8751 19.7961 16.8226 19.6159 16.8226 19.4894C16.8226 19.3566 16.898 19.2301 17.0489 19.1099C17.1997 18.9834 17.3965 18.8475 17.6392 18.702C18.3868 18.253 18.9181 17.8672 19.2329 17.5446C19.5543 17.2158 19.7937 16.928 19.9511 16.6814C20.115 16.4284 20.2429 16.1849 20.3348 15.9509C20.5053 15.5335 20.5905 15.1414 20.5905 14.7746C20.5905 14.4014 20.5479 14.0694 20.4627 13.7785C20.3774 13.4812 20.2429 13.2156 20.0593 12.9816C19.6395 12.4503 19.0821 12.1847 18.3868 12.1847C16.9374 12.1847 16.2127 12.8709 16.2127 14.2433C16.2127 14.5342 16.1897 14.724 16.1438 14.8125C16.052 14.9833 15.8716 15.0686 15.6027 15.0686H14.9829ZM16.3701 24.0999C16.3701 23.6509 16.5373 23.2619 16.8718 22.933C17.1932 22.6105 17.59 22.4492 18.0622 22.4492C18.5279 22.4492 18.9214 22.6105 19.2427 22.933C19.5707 23.2619 19.7346 23.6509 19.7346 24.0999C19.7346 24.5552 19.5707 24.9474 19.2427 25.2762C18.9148 25.6051 18.5213 25.7695 18.0622 25.7695C17.5965 25.7695 17.1997 25.6051 16.8718 25.2762C16.5373 24.9474 16.3701 24.5552 16.3701 24.0999Z" fill="#707070"/>
        </svg>
    )
}

// // 24px

export const ActiveInternalIcon24 = (props: IProps) => {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3C17.108 3 21.25 7.141 21.25 12.25C21.25 17.358 17.108 21.5 12 21.5C6.891 21.5 2.75 17.358 2.75 12.25C2.75 7.141 6.891 3 12 3Z" fill="white" stroke="#519548" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.43994 12.25L10.8139 14.623L15.5599 9.87695" stroke="#519548" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ActiveExternalIcon24 = (props: IProps) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C17.108 2.75 21.25 6.891 21.25 12C21.25 17.108 17.108 21.25 12 21.25C6.891 21.25 2.75 17.108 2.75 12C2.75 6.891 6.891 2.75 12 2.75Z" fill="white" stroke="#41DAAF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.43994 12L10.8139 14.373L15.5599 9.62695" stroke="#41DAAF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UnverifiedIcon24 = (props: IProps) => {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.81409 20.6868H19.1971C20.7791 20.6868 21.7721 18.9767 20.9861 17.6027L13.8001 5.03775C13.0091 3.65475 11.0151 3.65375 10.2231 5.03675L3.02509 17.6018C2.23909 18.9757 3.23109 20.6868 4.81409 20.6868Z" fill="white" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0024 13.6649V10.5649" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9951 16.75H12.0051" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export const InactiveIcon24 = (props: IProps) => {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.3345 3H7.66549C4.64449 3 2.75049 5.139 2.75049 8.166V16.334C2.75049 19.361 4.63549 21.5 7.66549 21.5H16.3335C19.3645 21.5 21.2505 19.361 21.2505 16.334V8.166C21.2505 5.139 19.3645 3 16.3345 3Z" fill="white" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.3971 14.6388L9.60107 9.8418" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.3955 9.84375L9.60352 14.6357" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export const UnavailableIcon24 = (props: IProps) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C17.108 2.75 21.25 6.891 21.25 12C21.25 17.108 17.108 21.25 12 21.25C6.891 21.25 2.75 17.108 2.75 12C2.75 6.891 6.891 2.75 12 2.75Z" fill="white" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.0562 9.63306C9.60791 9.63306 9.38379 9.38196 9.38379 8.87976C9.38379 8.13684 9.64734 7.54541 10.1744 7.10547C10.6932 6.67383 11.3615 6.45801 12.1791 6.45801C13.1669 6.45801 13.9243 6.72986 14.4514 7.27356C14.937 7.77576 15.1798 8.46057 15.1798 9.328C15.1798 10.2162 14.8457 11.038 14.1775 11.7933C13.5964 12.4408 12.8597 12.9513 11.9674 13.3248C11.901 13.3539 11.845 13.3684 11.7993 13.3684C11.7578 13.3684 11.7184 13.3622 11.681 13.3497C11.6478 13.3373 11.6105 13.3103 11.569 13.2688C11.4694 13.1692 11.3864 13.0426 11.3199 12.889C11.2535 12.7355 11.2203 12.6172 11.2203 12.5342C11.2203 12.447 11.2681 12.364 11.3635 12.2852C11.459 12.2021 11.5835 12.1129 11.7371 12.0175C12.2102 11.7228 12.5464 11.4696 12.7456 11.2579C12.949 11.0421 13.1005 10.8533 13.2001 10.6914C13.3038 10.5254 13.3848 10.3656 13.4429 10.212C13.5508 9.93811 13.6047 9.68079 13.6047 9.44006C13.6047 9.19519 13.5778 8.97729 13.5238 8.78638C13.4698 8.59131 13.3848 8.41699 13.2686 8.26343C13.0029 7.91479 12.6501 7.74048 12.2102 7.74048C11.293 7.74048 10.8344 8.1908 10.8344 9.09143C10.8344 9.28235 10.8198 9.40686 10.7908 9.46497C10.7327 9.57703 10.6185 9.63306 10.4484 9.63306H10.0562ZM10.934 15.5598C10.934 15.2651 11.0398 15.0099 11.2515 14.7941C11.4548 14.5824 11.7059 14.4766 12.0048 14.4766C12.2994 14.4766 12.5485 14.5824 12.7518 14.7941C12.9594 15.0099 13.0631 15.2651 13.0631 15.5598C13.0631 15.8586 12.9594 16.116 12.7518 16.3318C12.5443 16.5476 12.2953 16.6555 12.0048 16.6555C11.7101 16.6555 11.459 16.5476 11.2515 16.3318C11.0398 16.116 10.934 15.8586 10.934 15.5598Z" fill="#707070"/>
        </svg>
    )
}

// 20px

export const ActiveInternalIcon20 = (props: IProps) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 2.29175C14.2567 2.29175 17.7084 5.74258 17.7084 10.0001C17.7084 14.2567 14.2567 17.7084 10.0001 17.7084C5.74258 17.7084 2.29175 14.2567 2.29175 10.0001C2.29175 5.74258 5.74258 2.29175 10.0001 2.29175Z" fill="white" stroke="#519548" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.03336 9.99996L9.01169 11.9775L12.9667 8.02246" stroke="#519548" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>    
    )
}

export const ActiveExternalIcon20 = (props: IProps) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 2.29175C14.2567 2.29175 17.7084 5.74258 17.7084 10.0001C17.7084 14.2567 14.2567 17.7084 10.0001 17.7084C5.74258 17.7084 2.29175 14.2567 2.29175 10.0001C2.29175 5.74258 5.74258 2.29175 10.0001 2.29175Z" fill="white" stroke="#41DAAF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.03336 9.99996L9.01169 11.9775L12.9667 8.02246" stroke="#41DAAF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UnverifiedIcon20 = (props: IProps) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.01167 17.0306H15.9975C17.3158 17.0306 18.1433 15.6056 17.4883 14.4606L11.5 3.98979C10.8408 2.83729 9.17917 2.83646 8.51917 3.98896L2.52084 14.4598C1.86584 15.6048 2.6925 17.0306 4.01167 17.0306Z" fill="white" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.0021 11.179V8.5957" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.99585 13.75H10.0042" stroke="#3B89C9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const InactiveIcon20 = (props: IProps) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6121 2.29175H6.38792C3.87042 2.29175 2.29208 4.07425 2.29208 6.59675V13.4034C2.29208 15.9259 3.86292 17.7084 6.38792 17.7084H13.6112C16.1371 17.7084 17.7087 15.9259 17.7087 13.4034V6.59675C17.7087 4.07425 16.1371 2.29175 13.6121 2.29175Z" fill="white" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9976 11.9907L8.00092 7.99316" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9963 7.99487L8.00293 11.9882" stroke="#FA2A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UnavailableIcon20 = (props: IProps) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 2.29175C14.2567 2.29175 17.7084 5.74258 17.7084 10.0001C17.7084 14.2567 14.2567 17.7084 10.0001 17.7084C5.74258 17.7084 2.29175 14.2567 2.29175 10.0001C2.29175 5.74258 5.74258 2.29175 10.0001 2.29175Z" fill="white" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.38037 8.02747C8.00684 8.02747 7.82007 7.81822 7.82007 7.39972C7.82007 6.78062 8.03969 6.28776 8.47894 5.92114C8.91128 5.56144 9.46812 5.38159 10.1495 5.38159C10.9726 5.38159 11.6038 5.60813 12.0431 6.06122C12.4478 6.47972 12.6501 7.05039 12.6501 7.77325C12.6501 8.51341 12.3717 9.19822 11.8148 9.8277C11.3306 10.3672 10.7167 10.7927 9.97308 11.1039C9.91774 11.1282 9.87105 11.1403 9.83301 11.1403C9.79842 11.1403 9.76556 11.1351 9.73444 11.1247C9.70677 11.1143 9.67564 11.0918 9.64105 11.0573C9.55804 10.9742 9.48887 10.8688 9.43353 10.7408C9.37819 10.6128 9.35052 10.5142 9.35052 10.4451C9.35052 10.3724 9.3903 10.3033 9.46985 10.2375C9.5494 10.1684 9.65316 10.094 9.78113 10.0145C10.1754 9.7689 10.4556 9.55792 10.6216 9.38153C10.7911 9.20168 10.9173 9.04431 11.0003 8.90942C11.0868 8.77108 11.1542 8.63792 11.2026 8.50995C11.2926 8.28168 11.3375 8.06724 11.3375 7.86664C11.3375 7.66258 11.315 7.481 11.2701 7.3219C11.2251 7.15934 11.1542 7.01408 11.0574 6.88611C10.836 6.59558 10.542 6.45032 10.1754 6.45032C9.41105 6.45032 9.02887 6.82558 9.02887 7.57611C9.02887 7.73521 9.01676 7.83897 8.99255 7.88739C8.94413 7.98077 8.84902 8.02747 8.70721 8.02747H8.38037ZM9.11188 12.9664C9.11188 12.7209 9.20007 12.5082 9.37646 12.3283C9.54594 12.1519 9.75519 12.0637 10.0042 12.0637C10.2498 12.0637 10.4573 12.1519 10.6268 12.3283C10.7997 12.5082 10.8862 12.7209 10.8862 12.9664C10.8862 13.2155 10.7997 13.4299 10.6268 13.6097C10.4538 13.7896 10.2463 13.8795 10.0042 13.8795C9.75865 13.8795 9.5494 13.7896 9.37646 13.6097C9.20007 13.4299 9.11188 13.2155 9.11188 12.9664Z" fill="#707070"/>
        </svg>
    )
}