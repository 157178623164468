import React from 'react';
import { Button } from '@material-ui/core';
import { useProviderFilterStyles } from './Styles';
import { PerformanceSelectWitCheckBox } from './components/PerformanceSelectWitCheckBox/PerformanceSelectWitCheckBox';
import { DynamicBadge } from '../../../../../components/DynamicBadge';

interface IState {
  performance: any;
}

interface IAction {
  type: keyof IState | 'reset';
  value?: any;
}

const initialState: IState = {
  performance: [],
};

const reducer = (state: IState, action: IAction) => {
  if (action.type === 'reset') {
    return initialState;
  }

  return { performance: action.value };
};

interface ProviderPerformanceFilterViewProps {
  optionItems: any;
  selectedPerformanceOptions: string[];
  setSelectedPerformanceOptions: (input: string[]) => void;
  applyFilterBtnIsClicked: boolean;
  setApplyFilterBtnIsClicked: (input: boolean) => void;
  loading: boolean;
  displayedCount: number;
}

export const ProviderPerformanceFilterView = (
  props: ProviderPerformanceFilterViewProps
) => {
  const {
    optionItems,
    selectedPerformanceOptions,
    setSelectedPerformanceOptions,
    applyFilterBtnIsClicked,
    setApplyFilterBtnIsClicked,
    loading,
    displayedCount,
  } = props;

  const classes = useProviderFilterStyles();

  const [state, dispatch] = React.useReducer(reducer, {
    performance: selectedPerformanceOptions,
  });
  const onChange = (name: any, value: any) => {
    dispatch({ type: name, value });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.performanceSelect}>
          <PerformanceSelectWitCheckBox
            state={state.performance}
            type={'performance'}
            setState={onChange}
            isMultiple={true}
            inputTitle={'Performance'}
            placeholder={'All'}
            items={optionItems}
          />
        </div>

        <div className={classes.applyBtnAndResult}>
          <div className={classes.applyFilterBtnContainer}>
            <Button
              className={classes.applyFilterBtn}
              id="apply-button"
              variant="contained"
              // disabled={true}
              disableElevation
              onClick={() => {
                if (state.performance.includes('All')) {
                  setSelectedPerformanceOptions([]);
                } else {
                  setSelectedPerformanceOptions(state.performance);
                }

                setApplyFilterBtnIsClicked(true);
              }}
            >
              <span>Apply Filters</span>
            </Button>
          </div>

          {applyFilterBtnIsClicked && (
            <DynamicBadge
              loading={loading}
              displayedCount={displayedCount}
              prefixText=""
              suffixText="Provider(s)"
            />
          )}
        </div>
      </div>
    </div>
  );
};
