import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    fade,
    IconButton,
    Menu,
    MenuProps,
    styled,
    TextField,
    Typography,
} from '@material-ui/core';
import {SearchIcon} from "../../../../../assets/Icons/PanelsList/SearchIcon";
import {CloseIcon} from "../../../../../assets/Icons/ExportPatients";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        background: theme.palette.background.default,
    },
    searchRow: {
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        borderBottom: '1px solid #d1d1d1',
        alignItems: 'center',
        justifyContent: 'center',
        height: 39,
    },
    muiRoot: {
        width: '100%',
    },
    inputText: {
        fontSize: theme.typography.fontSize,
    },
    dobButton: {
        backgroundColor: 'transparent',
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        color: theme.palette.secondary.dark,
        height: 32,
    },
    savedFilterButton: {
        background: 'transparent!important',
        marginLeft: 12,
        marginRight: 12,
    },
    filterButtonText: {
        fontSize: 14,
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        fontWeight: theme.typography.fontWeightBold,
    },
    button: {
        padding: theme.spacing(0, 0, 0, 0),
        color: theme.palette.grey[400],
        width: 24,
        height: 24,
        marginRight: 11,
    },
    cancelSearchButton: {
        background: '#EFEFEF',
        width: 24,
        height: 24,
        borderRadius: '50%',
    },
    row: {
        display: 'flex',
        marginLeft: 30,
        marginRight: 30,
        '& #creation-button': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            lineHeight: '24px',
            background: theme.palette.primary.main,
            borderRadius: '8px',
            width: 102,
            height: 32,
            marginTop: 20,
            fontSize: 14,
            textAlign: 'center',
            padding: '0',
            marginRight: 24,
            '&:hover': {
                backgroundColor: '#1b8cf3',
            },
        },
        '& #manage-button': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 'bold',
            lineHeight: '24px',
            background: theme.palette.secondary.main,
            borderRadius: '8px',
            width: 165,
            height: 32,
            marginTop: 20,
            fontSize: 14,
            textAlign: 'center',
            padding: '0',

            '&:hover': {
                backgroundColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.main,
            },
        },
    },
    selectContainer: {
        width: 203,
        paddingLeft: 26,
        '&:first-child': {
            paddingLeft: 0,
        },
    },
    divider: {
        width: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dividerText: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey['500'],
        lineHeight: '16px',
        fontSize: 12,
        paddingTop: 30,
    },
    clearFilterContainer: {
        display: 'flex',
        alignItems: 'center',
        width: 106,
        paddingTop: 40,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterText: {
        lineHeight: '24px',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey['600'],
        fontSize: 14,
    },
    closeIcon: {
        fontSize: 18,
        color: '#434343',
    },
    selected: {
        backgroundColor: '#EDF6FF!important',
        color: '#0C77D8!important',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        marginBottom: 1,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    disabledNextButton: {
        height: 32,
        width: 72,
        backgroundColor: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        marginRight: 17,
        position: 'relative',
    },
    disabledText: {
        // opacity: '0.5',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '24px',
    },
    errorMsg: {
        fontSize: 12,
        fontWeight: 600,
        textAlign: 'center',
        position: 'absolute',
        right: '-210px',
        cursor: 'text',
        userSelect: 'text',
    },
}));

interface FileSearchBarProps {
    setIsSearching: any;
    isSearching: boolean;
    searchText: string;
    setSearchText: (input: any) => void;
}

export const FileSearchBar = (props: FileSearchBarProps) => {
    const classes = useStyles();
    const [searchInput, setSearchInput] = React.useState(props.searchText);
    const [inputError, setInputError] = React.useState(false);
    const [inputWidth, setInputWidth] = React.useState('100%');
    const handleChange = (e: any) => {
        if (!props.isSearching) {
            setSearchInput(e.target.value);
            if (e.target.value.length == 0) {
                props.setIsSearching(false);
            }
        }
    };

    const onClickSearch = () => {
        if (searchInput?.length > 2 && props.setSearchText) {
            props.setSearchText(searchInput!);
                props.setIsSearching(true);
        }
    };

    const onClickCancelSearch = () => {
        setInputError(false);
        props.setIsSearching(false);
        setSearchInput('');
        props.setSearchText &&
        props.setSearchText('');
    };

    React.useEffect(() => {
        if (searchInput?.length == 0) {
            setInputWidth('100%');
            setInputError(false);
        } else if (searchInput.length * 16 + 125 > 200) {
            setInputWidth((searchInput.length + 1) * 16 + 125 + 'px');
        } else {
            setInputWidth('200px');
        }
    }, [searchInput]);

    const [clickSearch, setClickSearch] = React.useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.searchRow}>
                <TextField
                    variant="standard"
                    id="standard"
                    placeholder={'Search by File Name'}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onClickSearch();
                            ev.preventDefault();
                        }
                    }}
                    onChange={(e) => handleChange(e)}
                    classes={{ root: classes.muiRoot }}
                    value={searchInput}
                    InputProps={{
                        classes: {
                            input: classes.inputText,
                        },
                        style: { width: inputWidth, maxWidth: '100%' },
                        disableUnderline: true,
                        startAdornment: (
                            <IconButton className={classes.button}>
                                <SearchIcon />
                            </IconButton>
                        ),
                        endAdornment: props.isSearching ? (
                            <IconButton
                                onClick={() => {
                                    onClickCancelSearch();
                                }}
                                className={classes.cancelSearchButton}
                            >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        ) : (
                            <Button
                                disableRipple={!clickSearch}
                                className={classes.disabledNextButton}
                                style={searchInput?.length > 0 ? {} : { display: 'none' }}
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                                ) => {
                                    let target = e.target as HTMLElement;
                                    if (target.textContent === 'Search') {
                                        setClickSearch(true);
                                        onClickSearch();
                                    } else {
                                        setClickSearch(false);
                                        e.stopPropagation();
                                    }
                                }}
                            >
                <span
                    className={classes.disabledText}
                    style={{ opacity: searchInput.length <= 2 ? 0.5 : 1 }}
                >
                  Search
                </span>
                                {inputError && (
                                    <Typography color="error" className={classes.errorMsg}>
                                        You have entered an invalid search
                                    </Typography>
                                )}
                            </Button>
                        ),
                    }}
                />
            </div>
        </div>
    );
};