export function dobFormatPipe(date: any, nullableString: string = '') {
  if (date == null) {
    return nullableString;
  }
  const newDate = new Date(date);
  let day =
    newDate.getDate() + 1 < 10
      ? '0' + (newDate.getDate() + 1)
      : newDate.getDate() + 1;
  let monthIndex =
    newDate.getMonth() + 1 < 10
      ? '0' + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return monthIndex + '/' + day + '/' + year;
}

export function isoDobFormatPipe(isoDobString: string) {
  // isoDob: 1976-09-23T04:00:00.000Z => 09/23/1976
  if (!isoDobString) {
    return '';
  }

  return new Date(isoDobString).toLocaleDateString('en-us', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export const hardcodingDateFormat = (rawDateString: string, type: string) => {
  // for Payer's files new patient DOB column
  if (!rawDateString) return 'No Date';
  // rawDateString "1970-02-28T00:00:00.000Z"

  let yyyymmdd = rawDateString.split('T')[0].trim();
  let [yyyy, mm, dd] = yyyymmdd.split('-').map((item: string) => item.trim());
  switch (type) {
    case 'DOB':
      return `${mm}/${dd}/${yyyy}`;
    case 'CareGapDueDateAndClosedDate':
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      return `${monthNames[Number(mm) - 1]} ${dd}, ${yyyy}`;
    default:
      return `${yyyy}-${mm}-${dd}`;
  }
};
