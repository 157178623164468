import React from 'react';
import { PanelPatient } from '../../../../../app.types.generated';
import { useCurrentPagePatients } from './useCurrentPagePatients';

type TurningPageType = {
  forward: undefined | boolean;
  backward: undefined | boolean;
};

export const useSwitchPatientWithPagination = (
  skip: number,
  limit: number,
  results: PanelPatient[],
  setCheckedPatient: (value: React.SetStateAction<PanelPatient>) => void,
  checkedPatient: PanelPatient
) => {
  const [turningPage, setTurningPage] = React.useState({} as TurningPageType);
  const [currentPage, setCurrentPage] = React.useState(0);

  const { currentPageFirstAndLastPatient } = useCurrentPagePatients(results);

  React.useEffect(() => {
    setCurrentPage((prevState) => {
      let newState = Math.floor(skip / limit);
      if (newState > prevState) {
        // moving to next page
        setTurningPage({ forward: true, backward: false });
      } else if (newState < prevState) {
        // moving to previous page
        setTurningPage({ forward: false, backward: true });
      }

      return newState;
    });
  }, [skip, limit]);

  React.useEffect(() => {
    if (turningPage.forward && checkedPatient.id) {
      // go to next page, set the first as default
      setCheckedPatient(currentPageFirstAndLastPatient[0]);
    }
    if (turningPage.backward && checkedPatient.id) {
      // go to previous page, set the last as default
      setCheckedPatient(currentPageFirstAndLastPatient[1]);
    }
  }, [currentPage, currentPageFirstAndLastPatient]);

  return { currentPage };
};
