import React from 'react';
import { CareGapProvider } from '../../../CareGaps/Home/tabs/Population/context';
import { AssignedPatientsTab } from './components/AssignedPatientsTab';
import { UnassignedPatientsTab } from './components/UnassignedPatientsTab';
import { PatientEngagementTable } from './components/PatientEngagementTable';
import { DeListPatientsTab } from './components/DeListPatientsTab';

type Props = {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  hardRefresh: any;
  unassignedPatientCount?: number;
  setAssignedPatientCount?: (input: any) => any;
  setMyAssignedPatientCount?: (input: any) => any;
  notEngagedCount?: number;
  nofutureApptCount?: number;
  setShowAssignCoordinatorDialog?: (input: boolean) => void;
  setShowManageCoordinatorDialog?: (input: boolean) => void;
  careTeamUsers?: any;
  assignedPatientCount?: number;
};

export const PanelPatientsContainer = (props: Props) => {
  const [actionsFooter, setActionsFooter] = React.useState<any[]>([]);
  const getTableComponentByTitle = (title: string) => {
    switch (title) {
      case 'De-Listed Patients': {
        return (
          <CareGapProvider>
            <DeListPatientsTab
              limit={props.limit}
              skip={props.skip}
              setLimit={props.setLimit}
              setSkip={props.setSkip}
              panelId={props.panelId}
              title={props.title}
              tabIndex={props.tabIndex}
              availableActions={props.availableActions}
              selected={props.selected}
              setSelected={props.setSelected}
              actionsFooter={actionsFooter}
              setActionsFooter={setActionsFooter}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              hardFresh={props.hardRefresh}
              careTeamUsers={props.careTeamUsers.map((user: any) => {
                return {
                  user: { ...user.user, count: user.count },
                  value: user?.user?.id,
                };
              })}
            />
          </CareGapProvider>
        );
      }
      case 'Engagement Patients': {
        return (
          <PatientEngagementTable
            limit={props.limit}
            skip={props.skip}
            panelId={props.panelId}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
          />
        );
      }
      // for Coordinator
      case 'My Patients':
      case 'Assigned Patients': {
        return (
          <CareGapProvider>
            <AssignedPatientsTab
              limit={props.limit}
              skip={props.skip}
              panelId={props.panelId}
              setLimit={props.setLimit}
              setSkip={props.setSkip}
              title={props.title}
              tabIndex={props.tabIndex}
              availableActions={props.availableActions}
              selected={props.selected}
              setSelected={props.setSelected}
              actionsFooter={actionsFooter}
              setActionsFooter={setActionsFooter}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              hardFresh={props.hardRefresh}
              setAssignedPatientCount={props.setAssignedPatientCount}
              setMyAssignedPatientCount={props.setMyAssignedPatientCount}
              notEngagedCount={props.notEngagedCount}
              nofutureApptCount={props.nofutureApptCount}
              careTeamUsers={props.careTeamUsers.map((user: any) => {
                return {
                  user: { ...user.user, count: user.count },
                  value: user?.user?.id,
                };
              })}
              assignedPatientCount={props.assignedPatientCount}
            />
          </CareGapProvider>
        );
      }
      case 'Unassigned Patients': {
        return (
          <CareGapProvider>
            <UnassignedPatientsTab
              limit={props.limit}
              skip={props.skip}
              panelId={props.panelId}
              setLimit={props.setLimit}
              setSkip={props.setSkip}
              title={props.title}
              tabIndex={props.tabIndex}
              availableActions={props.availableActions}
              selected={props.selected}
              setSelected={props.setSelected}
              actionsFooter={actionsFooter}
              setActionsFooter={setActionsFooter}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              hardFresh={props.hardRefresh}
              unassignedPatientCount={props.unassignedPatientCount}
              setShowAssignCoordinatorDialog={
                props.setShowAssignCoordinatorDialog
              }
              setShowManageCoordinatorDialog={
                props.setShowManageCoordinatorDialog
              }
              careTeamUsers={props.careTeamUsers.map((user: any) => {
                return {
                  user: { ...user.user, count: user.count },
                  value: user?.user?.id,
                };
              })}
            />
          </CareGapProvider>
        );
      }
    }
  };

  return <>{getTableComponentByTitle(props.title)}</>;
};
