import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 512,
      maxWidth: '80%',
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
  },
  dialogContent: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    paddingBottom: 24,
  },
  editBtn: {
    width: 97,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  dimissBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 93,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 16px',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
  },
  subContent: {
    paddingTop: 24,
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#434343',
    paddingBottom: 5,
  },
  container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  row: {
    display: 'flex',
    height: 55,
    marginLeft: 16,
    marginRight: 16,
    borderBottom: '1px solid #D1D1D1',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rowTitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
  },
  rowContent: {
    paddingLeft: 10,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
  },
}));

interface DialogProps {
  showDialog: boolean;
  setShowDialog: any;
  unknownCaregaps: any[];
}

export const UnCareGapDialog = (props: DialogProps) => {
  const classes = useStyles();
  const onSubmit = async () => {
    props.setShowDialog(null);
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open={props.showDialog}
      onClose={() => {
        props.setShowDialog(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" style={{ paddingBottom: 6 }}>
        {'Unknown Care Gap(s)'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        We found unknown Care Gap(s) in the file. Please make sure the
        information is updated in the next file.
        <div className={classes.subContent}>
          <div className={classes.subTitle}>Unknown Care Gap(s)</div>
          <div className={classes.container}>
            {props.unknownCaregaps.map((unknownCaregap: any, index: number) => {
              return (
                <div className={classes.row}>
                  <div className={classes.rowTitle}>
                    {unknownCaregap.measureCode}
                  </div>
                  <div className={classes.rowContent}>
                    {unknownCaregap.measureName ?? 'unknown'}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
      <div className={classes.divider} />
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            onClick={() => {
              onSubmit();
            }}
            className={classes.dimissBtn}
            autoFocus
          >
            Dismiss
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
