import React from 'react';
import { useDelistPatientFromPanelMutation } from '../../CareGapsDrawer/index.generated';
import { GlobalContext } from '../../../../../../../../components/GlobalContext';

export const useDeListPatient = () => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  const [delistPatientFromPanelMutation] = useDelistPatientFromPanelMutation();

  const delistPatientFromPanel = async (patientId: string, panelId: string) => {
    const { data, errors } = await delistPatientFromPanelMutation({
      variables: {
        input: {
          patientId: patientId,
          panelId: panelId,
        },
      },
    });

    if (errors || !data || !data.delistPatientFromPanel.isSuccess) {
      console.error(errors);
      console.error(data);
      setToastMessage({
        snackbarMsg: `Something Went Wrong, Please Try Again Later.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
    if (data?.delistPatientFromPanel.isSuccess) {
      setToastMessage({
        snackbarMsg: `Patient is de-listed from panel.`,
        severity: 'success',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  };

  return { delistPatientFromPanel };
};
