import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HomePageHeader } from '../../../components/HomePageHeader';
import { MainRegion } from '../../../components/MainRegion';
import { useNavigate, useLocation } from 'react-router-dom';
import { TabBar } from '../../../components/TabBar';
import { PayerFileListingView } from './Files/View';
import { PayerIdListingView } from './PayerId/View';
import { useGetPayersCountQuery } from './Files/PayerList/index.generated';
import { useFetchTableData } from '../../../hooks/useFetchTableData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    height: 20,
    width: 20,
  },
  headerMainRow: {
    height: 88,
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: 'bold',
      height: '40px',
      letterSpacing: '0',
      lineHeight: '40px',
      // margin: '18.68px 24px',
      margin: '24px',
      display: 'inline-block',
      textTransform: 'capitalize',
    },

    '& #create-button-container': {
      marginLeft: 'auto',
      marginRight: theme.spacing(3),
    },

    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: '166px',
      height: '40px',
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0',
      textTransform: 'capitalize',
      marginLeft: '3.33px',
    },
  },
}));

interface PayerHomeTapProp {
  tab?: number;
}
export const PayerFileHome = (props: PayerHomeTapProp) => {
  const [value, setValue] = React.useState(props.tab ?? 0);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;
  const [payerCount, setPayerCount] = React.useState(0);

  const resp = useGetPayersCountQuery({
    variables: {
      request: {
        insuranceName: '',
        skip: 0,
        limit: 10,
        sortBy: [
          {
            field: 'payerName',
            method: 'asc' as const,
          },
        ],
      },
    },
  });

  const queryResult = useFetchTableData(resp);

  React.useEffect(() => {
    setPayerCount(queryResult?.getPayers?.count ?? 0);
  }, [queryResult]);

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  const switchTab = (event: any, newValue: number) => {
    onSetSkip(0);
    setValue(newValue);
  };

  return (
    <MainRegion
      header={
        <HomePageHeader
          headerTitle={`Payer Files`}
          headerActionElement={<div id="create-button-container"></div>}
          privilege={{
            checkPrivilege: false,
            neededPrivileges: '',
          }}
        />
      }
    >
      <Grid container className={classes.root}>
        <TabBar
          sticky={true}
          activeTabIndex={value}
          switchTab={switchTab}
          tabObjects={[
            {
              tabIndex: 0,
              tabLabel: 'Files',
              tabUrl: '',
              tabBadgeCount: 0,
              disabled: false,
            },
            {
              tabIndex: 1,
              tabLabel: 'Payer ID',
              tabUrl: '',
              tabBadgeCount: payerCount,
              disabled: false,
            },
          ]}
        >
          {value == 0 && (
            <Grid item xs={12}>
              <PayerFileListingView
                limit={limit}
                skip={skip}
                setLimit={onSetLimit}
                setSkip={onSetSkip}
              />
            </Grid>
          )}
          {value == 1 && (
            <Grid item xs={12}>
              <PayerIdListingView
                limit={limit}
                skip={skip}
                setLimit={onSetLimit}
                setSkip={onSetSkip}
              />
            </Grid>
          )}
        </TabBar>
      </Grid>
    </MainRegion>
  );
};
