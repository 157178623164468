import { Chip, Fade, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { useReadOnly } from '../../../../hooks/useReadOnly';
import { PatientFaceInfo } from '../../../../../../../../../app.types.generated';
import {
  CallIcon,
  LockIcon,
} from '../../../../../../../../../assets/Icons/CareGaps';
import { ButtonMediumPrimaryText } from '../../../../../../../../../components/Buttons';
import { useCareGapDrawerHeaderStyles } from '../../Styles';
import { Feature } from '../../../../../../../../../components/FeatureFlag';
import { convertRoleName } from '../../../../../../../../../sharePipe/convertRoleNamePipe';

interface CallAttemptComponentProps {
  inAssignedPatientTable: boolean;
  showCoordinator: boolean;
  patientInfo: PatientFaceInfo;
  actionTitle: string;
  onOpenDialog: boolean;
  setOnOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CallAttemptComponent = (props: CallAttemptComponentProps) => {
  const classes = useCareGapDrawerHeaderStyles();
  const {
    inAssignedPatientTable,
    showCoordinator,
    patientInfo,
    onOpenDialog,
    setOnOpenDialog,
    actionTitle,
  } = props;
  const { readOnly } = useReadOnly(patientInfo);

  const tooltipText = [
    `${convertRoleName(patientInfo.careLockInfo?.updatedBy!.roleName!)} ${
      patientInfo.careLockInfo?.updatedBy!.firstName
    } ${
      patientInfo.careLockInfo?.updatedBy!.lastName
    } is working on this patient at the moment.`,
    'To avoid duplicate outreach, we limited your access.',
  ];
  return (
    <Feature name="callAttempts">
      {(hasFeature) =>
        hasFeature ? (
            inAssignedPatientTable &&
              showCoordinator &&
              (readOnly ? (
                <div id="read-only-region">
                  <StyledTooltip
                    placement="bottom-start"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={tooltipText.join('\n')}
                  >
                    <Chip
                      className={classes.readOnlyChip}
                      icon={<LockIcon className={classes.readOnlyChipIcon} />}
                      label="Outreach Locked"
                    />
                  </StyledTooltip>
                </div>
              ) : (
                <ButtonMediumPrimaryText
                  onClick={() => {
                    setOnOpenDialog(!onOpenDialog);
                  }}
                  startIcon={<CallIcon />}
                >
                  <span className={classes.attemptBtnText}>{actionTitle}</span>
                </ButtonMediumPrimaryText>
              ))
        ) : null
      }
    </Feature>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 360,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '6px 12px',
  },
  popper: {
    top: '-20px !important',
  },
})(Tooltip);
