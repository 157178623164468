import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  OutlinedInput,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { EditCoordinatorConfirmSubmitDialog } from './ConfirmUpdateDialog';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      width: '100%',
      display: 'flex',
      borderRadius: 8,
    },
    cardHeader: {
      height: 72,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    newPwCard: {
      boxSizing: 'border-box',
      width: '100%',
      borderRadius: 8,
      padding: theme.spacing(5, 4, 5, 4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    instructions: {
      fontSize: 16,
      color: theme.palette.secondary.dark,
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(3),
    },
    inputLabel: {
      fontSize: 14,
      height: 24,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['700'],
    },
    inputField: {
      height: 48,
      borderRadius: 8,
      fontSize: 16,
    },
    descriptionInput: {
      fontSize: 16,
      width: '100%',
      height: 120,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'start',

      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        maxHeight: '100%',
      },
    },
    submitButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      height: 48,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: 8,
    },
    backToLoginButton: {
      width: 'fit-content',
      padding: 0,
      height: 'fit-content',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: 0,
    },
    backToLogin: {
      fontSize: 14,
      fontWeight: 600,
      height: 24,
      color: theme.palette.secondary.dark,
      borderBottom: '2px solid #434343',
      paddingBottom: theme.spacing(0.5),
    },
    errorMsg: {
      fontSize: 12,
      fontWeight: 600,
      paddingTop: theme.spacing(1.5),
    },
    email: {
      color: theme.palette.text.primary,
    },
    ruleRow: {
      display: 'flex',
      alignItems: 'center',
      height: 26,
    },
    ruleText: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.dark,
      paddingLeft: theme.spacing(1),
    },
    checkIcon: {
      fontSize: 18,
    },
    checkGreen: {
      color: theme.palette.success.main,
    },
    closeIcon: {
      fontSize: 18,
      color: theme.palette.error.main,
    },
    cardContent: {
      width: '100%',
      padding: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
    cardFooter: {
      height: 72,
      padding: theme.spacing(2, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    nextButton: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },

      '&.MuiButton-contained.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: theme.palette.primary.main,
      },
    },
    subLabel: {
      fontSize: 12,
      color: theme.palette.text.hint,
      fontWeight: 600,
      paddingTop: theme.spacing(0.5),
    },
    cancelButton: {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.dark,

      '& .MuiButton-label': {},
    },
  })
);

type PanelDetailFields = {
  panelName: string;
  panelDescription: string;
};
interface PanelDetailTabProps {
  panel: any;
  panelState: any;
  changePanelDetail: any;
  showDialog: any;
  setShowDialog: any;
  handleViewOtherPanel: any;
  onUpdatePanelDetails: any;
  setOnConfirm: any;
  panelNameError: any;
  panelDescriptionError: any;
  inValid: boolean;
}

export const EditPanelDetailTab = (props: PanelDetailTabProps) => {
  const navigate = useNavigate();
  const { showDialog, setShowDialog } = props;
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  async function onSubmit() {
    //
  }

  const { control, handleSubmit } = useForm<PanelDetailFields>({
    defaultValues: {
      panelName: '',
      panelDescription: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onPanelNameChange = (event: any) => {
    props.changePanelDetail(event.target.value, 'panelName');
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const onPanelDescriptionChange = (event: any) => {
    props.changePanelDetail(event.target.value, 'panelDescription');
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Paper className={classes.card} elevation={1}>
        <Grid container>
          <Grid item xs={12} className={classes.cardHeader}>
            <Typography className={classes.cardTitle}>Panel Details</Typography>

            <Button
              onClick={() => navigate('/panels')}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={3}
            className={classes.cardContent}
          >
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>Panel Name</Typography>
              <Controller
                name="panelName"
                rules={{ required: true }}
                control={control}
                render={({ onChange, value, name, ref }) => (
                  <div>
                    <OutlinedInput
                      fullWidth
                      inputRef={ref}
                      name={name}
                      value={props.panelState.panelName}
                      onChange={onPanelNameChange}
                      error={props.panelNameError !== null}
                      className={classes.inputField}
                      placeholder="Enter Panel Name"
                      classes={outlinedInputClasses}
                      inputProps={{ maxLength: 40 }}
                    />
                    {props.panelNameError && (
                      <Typography color="error" className={classes.errorMsg}>
                        panel name cannot be empty.
                      </Typography>
                    )}
                  </div>
                )}
              />
              <Typography className={classes.subLabel}>
                40 characters max
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>
                Description
              </Typography>
              <Controller
                name="panelDescription"
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, name, ref }) => (
                  <div>
                    <OutlinedInput
                      fullWidth
                      inputRef={ref}
                      name={name}
                      value={props.panelState.panelDescription}
                      onChange={onPanelDescriptionChange}
                      error={props.panelDescriptionError !== null}
                      rows={'10'}
                      multiline={true}
                      className={classes.descriptionInput}
                      placeholder="Panel Description"
                      classes={outlinedInputClasses}
                    />
                    {props.panelDescriptionError && (
                      <Typography color="error" className={classes.errorMsg}>
                        {props.panelDescriptionError}
                      </Typography>
                    )}
                  </div>
                )}
              />
              <Typography className={classes.subLabel}>
                Enter description for your new panel
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.cardFooter}>
            <Button
              type="submit"
              className={classes.nextButton}
              disabled={props.inValid}
              onClick={() => {
                props.setOnConfirm(true);
              }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
