export function sortArrayBaseOnObjectArrayPipe(
  arr: any[],
  objArr: any,
  order: string
) {
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const objA = objArr.find((item: any) => item.value == a);
    const objB = objArr.find((item: any) => item.value == b);
    if (objB[orderBy] < objA[orderBy]) {
      return -1;
    }
    if (objB[orderBy] > objA[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    orderBy: Key
  ): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return stableSort(arr, getComparator('index'));
}
