import React, {ChangeEvent} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  makeStyles,
  Theme,
} from '@material-ui/core';

import { BulletPlot } from '../../../../../components/Charts/BulletPlot';
import {
  tableUseStyles,
  tableUseToolbarStyles,
} from '../../../../Insurances/InsuranceListingPage/InsuranceListTable/TableStyle';
import { formatNumberPipe } from '../../../../../sharePipe/formatNumberPipe';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

function descendingComparator(a: any, b: any, orderBy: keyof any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof any;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'measureAbbr',
    numeric: false,
    disablePadding: true,
    label: 'Code',
  },
  {
    id: 'measureName',
    numeric: false,
    disablePadding: false,
    label: 'Measurement',
  },
  { id: 'eligible', numeric: false, disablePadding: false, label: 'Eligible' },
  {
    id: 'numerator',
    numeric: false,
    disablePadding: false,
    label: 'Numerator',
  },
  {
    id: 'denominator',
    numeric: false,
    disablePadding: false,
    label: 'Denominator',
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: 'Compliance Rate',
  },
];

const nonsortableHeads = ['eligible', 'numerator', 'denominator', 'progress'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  paper: {
    borderRadius: 8,
  },
  lightBackground: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableToolBarContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface EnhancedTableHeadProps {
  classes: any;
  sortBy: any;
  setSortBy: any;
  setIsSorting: any;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, sortBy, setIsSorting } = props;

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={sortBy.field === headCell.id}
          direction={sortBy.field === headCell.id ? sortBy.method : 'asc'}
          onClick={() => {
            props.setSortBy({
              field: headCell.id,
              method:
                sortBy.field === headCell.id
                  ? sortBy.method == 'asc'
                    ? 'desc'
                    : 'asc'
                  : ('asc' as const),
            });
            setIsSorting(true);
          }}
        >
          {headCell.label}
          {sortBy.field === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {sortBy.method === 'desc'
                ? 'sorted descending'
                : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={sortBy.field === headCell.id ? sortBy.method : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the more-options column */}
        {/* <TableCell></TableCell> */}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  tableTitle?: string;
  //searchbar
  enableSearch?: boolean;
  searchPlaceHolder: string;
  searchConstriction?: any;
  searchFunction: any;
  searchEnableFilter?: boolean;
  renderButton?: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = tableUseToolbarStyles();
  const altClasses = useStyles();
  return (
    <Paper
      className={`${classes.tableToolBarContainer} ${altClasses.tableToolBarContainer}`}
      elevation={0}
    >
      {props.tableTitle && (
        <div className={`${classes.header} ${altClasses.header}`}>
          {/* title */}
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {props.tableTitle}
          </Typography>
          {props.renderButton && (
            <div className={classes.headerButton}>{props.renderButton}</div>
          )}
        </div>
      )}
    </Paper>
  );
};

interface ItemListTableViewProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  results: any;
  count: number;
  refetchDataFunc?: any;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
}

export const PayerFileListingTableView = (props: ItemListTableViewProps) => {
  const classes = tableUseStyles();
  const altClasses = useStyles();
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const [order, setOrder] = React.useState<Order>(props.sortBy.method);
  const [orderBy, setOrderBy] = React.useState<any>(props.sortBy.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.limit ?? 10);
  const [isSorting, setIsSorting] = React.useState(false);

  React.useEffect(() => {
    if (isSorting) {
      setTimeout(() => {
        setIsSorting(false);
      }, 5000);
    }
  }, [isSorting]);

  React.useEffect(() => {
    setFilteredRows(props.results);
    setIsSorting(false);
  }, [props.results]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // @ts-ignore
  return (
    <div className={`${classes.root} ${altClasses.root}`}>
      <Paper className={`${classes.paper} ${altClasses.paper}`} elevation={1}>
        <div style={{ position: 'relative' }}>
          {isSorting && (
            <LoadingSpinner
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#EFEFEF',
                opacity: 0.7,
                zIndex: 9999,
              }}
            />
          )}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sortBy={props.sortBy}
                setSortBy={props.setSortBy}
                setIsSorting={setIsSorting}
              />
              <TableBody>
                {filteredRows
                  .slice(page * props.limit, page * props.limit + props.limit)
                  .map((row: any, index: number) => {
                    return (
                      <TableRow tabIndex={-1} key={`${index}`}>
                        <TableCell align="left">{row.measureAbbr}</TableCell>
                        <TableCell align="left">{row.measureName}</TableCell>
                        <TableCell align="left">-</TableCell>
                        <TableCell align="left">
                          {formatNumberPipe(row.numerator, '-')}
                        </TableCell>
                        <TableCell align="left">
                          {formatNumberPipe(row.demoninator, '-')}
                        </TableCell>
                        {/* progress */}
                        <TableCell align="left">
                          <BulletPlot
                            code={row.measureCode ?? ''}
                            numEligible={100}
                            numComplaint={row.progress}
                            numNonComplaint={100 - row.progress}
                            progress={row.progress}
                            // numExclusive={row.exclusive}
                          />
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: 56 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                {!props.refetchDataFunc.loading && !filteredRows.length ? (
                  <TableRow>
                    <TableCell align={'left'}>
                    <span className={classes.noMoreDataDescription}>
                      No Payer ID Found
                    </span>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={props.limit}
            skip={props.skip}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            count={props.count}
            setSortBy={props.setSortBy}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Paper>
    </div>
  );
};

interface PaginationProps {
  limit: number;
  skip: number;
  setLimit: (input: number) => any;
  setSkip: (input: number) => any;
  count: any;
  setSortBy: (input: any) => void;
  orderBy: string;
  order: string;
}

const Pagination = (props: PaginationProps) => {
  const { count, limit, skip } = props;

  const classes = tableUseStyles();
  const altClasses = useStyles();

  const currentPage = Math.floor(skip / limit);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setSkip(newPage * limit);
    props.setSortBy({ field: props.orderBy, method: props.order });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.setLimit(parseInt(event.target.value));
  };

  return (
    <Paper className={`${altClasses.rounded}`}>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="items per page"
        className={classes.pagination}
      />
    </Paper>
  );
};
