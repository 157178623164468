import React, { useContext } from 'react';
import {
  Button,
  Divider,
  fade,
  Menu,
  MenuItem,
  MenuProps,
  styled,
} from '@material-ui/core';
import { useUserInfoMenuStyles } from './styles';
import { EditIcon } from '../../../../../../assets/Icons/MsoAdmin';
import { NavigateNextIcon } from '../../../../../../assets/Icons/CareGaps';
import { LogoutIcon } from '../../../../../../assets/Icons/SideNavigation/LogoutIcon';
import Auth from '@aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router';
import { SideBarProfileItem } from '../../../../../../components/SideBarProfileItem';
import { preUrlHandler } from '../../../../../../sharePipe/getPreviousUrlPip';
import { RoleType } from '../../../../../../enum/Users/roleType';
import { MixPanelEvents } from '../../../../../../mixpanel/events';
import { CheckIsHudsonClinician } from '../../../../../../components/CheckIsAdmin';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      style: {
        transform: 'translateX(-8px)',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1, 2),
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      lineHeight: '24px',
      display: 'inline-block',
      // width: 87,
      height: 24,
      // color: 'theme.palette.secondary.dark',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      // letterSpacing: 0,
      // marginRight: theme.spacing(2),
    },
  },
}));

interface UserInfoMenuViewPros {
  user: {
    firstName?: string | null;
    lastName?: string | null;
    role?: any;
    title?: string | null;
    avatarUrl?: string | null;
    availableAccountIds?: string[] | null;
  };
}

export const UserInfoMenuView = (props: UserInfoMenuViewPros) => {
  const { user } = props;
  const classes = useUserInfoMenuStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [rotateIcon, setRotateIcon] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setRotateIcon(!rotateIcon);
    setIsClicked(!isClicked);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(null);
    setRotateIcon(!rotateIcon);
  }

  function handleOptionClick(
    event: React.MouseEvent<HTMLElement>,
    optionCode: string
  ) {
    setRotateIcon(!rotateIcon);
    setAnchorEl(null);

    switch (optionCode) {
      case 'edit':
        navigate('/profile/edit', {
          state: { preUrl: preUrlHandler(location.pathname) },
        });
        break;
      case 'change_password':
        navigate('/profile/change_password', {
          state: { preUrl: preUrlHandler(location.pathname) },
        });
        break;
      default:
        break;
    }
  }
  return (
    <div className={classes.root}>
      <Button
        aria-controls="user-info"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
        className={classes.userInfoButton}
      >
        <SideBarProfileItem
          firstName={user.firstName}
          lastName={user.lastName}
          title={
            user.role?.roleName === 'Admin'
              ? RoleType.CARE_MANAGER
              : user.role?.roleName
          }
          avatarUrl={user.avatarUrl}
        />
        <NavigateNextIcon
          className={
            rotateIcon
              ? `${classes.navigateNext} ${classes.rotateNavigateNext}`
              : `${classes.navigateNext}`
          }
        />
      </Button>
      <StyledMenu
        id="user-info-menu"
        MenuListProps={{
          'aria-labelledby': 'user-info-options',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        elevation={2}
        className={classes.menu}
      >
        {CheckIsHudsonClinician(user) ? (
          <>
            <MenuItem
              onClick={(event) => handleOptionClick(event, 'change_password')}
              disableRipple
              className={classes.menuItem}
            >
              <span>Change Password</span>
            </MenuItem>
            <Divider
              style={{
                position: 'absolute',
                left: 0,
                width: '100%',
              }}
            />
          </>
        ) : (
          <>
            <MenuItem
              onClick={(event) => handleOptionClick(event, 'edit')}
              disableRipple
              className={classes.menuItem}
            >
              <span>Edit Profile</span>
              <EditIcon />
            </MenuItem>

            <MenuItem
              onClick={(event) => handleOptionClick(event, 'change_password')}
              disableRipple
              className={classes.menuItem}
            >
              <span>Change Password</span>
            </MenuItem>

            <Divider
              style={{
                position: 'absolute',
                left: 0,
                width: '100%',
              }}
            />
          </>
        )}

        <MenuItem
          onClick={() => {
            MixPanelEvents.reset();
            Auth.signOut();
          }}
          disableRipple
          className={classes.menuItem}
        >
          <span>Logout</span>
          <LogoutIcon />
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
