import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {GetApp} from "@material-ui/icons";
import React from 'react';
import styles from "./simpleMenu.module.css";
import {Format} from "./types";
import IconButton from '@material-ui/core/IconButton';


interface IProps {
    onSelected: (format: Format) => void
}

export default function SimpleMenu(props: IProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton aria-controls="simple-menu" aria-haspopup="true"
                    onClick={handleClick}>
                <GetApp className={styles.downloadIcon}/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    props.onSelected("csv")
                }}>
                    <Typography className={styles.item}>csv</Typography>
                </MenuItem>

                <MenuItem onClick={() => {
                    handleClose()
                    props.onSelected('xlsx')
                }}>
                    <Typography className={styles.item}>xls</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}
