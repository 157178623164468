import * as Types from '../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPowerBiReportUrlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPowerBiReportUrlQuery = (
  { __typename: 'Query' }
  & { getPowerBIReportURL: (
    { __typename: 'PowerBIResponse' }
    & Pick<Types.PowerBiResponse, 'isSuccess' | 'message' | 'reportURL'>
  ) }
);


export const GetPowerBiReportUrlDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPowerBIReportURL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPowerBIReportURL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"reportURL"}}]}}]}}]};

/**
 * __useGetPowerBiReportUrlQuery__
 *
 * To run a query within a React component, call `useGetPowerBiReportUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerBiReportUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerBiReportUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPowerBiReportUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetPowerBiReportUrlQuery, GetPowerBiReportUrlQueryVariables>) {
        return Apollo.useQuery<GetPowerBiReportUrlQuery, GetPowerBiReportUrlQueryVariables>(GetPowerBiReportUrlDocument, baseOptions);
      }
export function useGetPowerBiReportUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPowerBiReportUrlQuery, GetPowerBiReportUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetPowerBiReportUrlQuery, GetPowerBiReportUrlQueryVariables>(GetPowerBiReportUrlDocument, baseOptions);
        }
export type GetPowerBiReportUrlQueryHookResult = ReturnType<typeof useGetPowerBiReportUrlQuery>;
export type GetPowerBiReportUrlLazyQueryHookResult = ReturnType<typeof useGetPowerBiReportUrlLazyQuery>;
export type GetPowerBiReportUrlQueryResult = Apollo.QueryResult<GetPowerBiReportUrlQuery, GetPowerBiReportUrlQueryVariables>;
export function refetchGetPowerBiReportUrlQuery(variables?: GetPowerBiReportUrlQueryVariables) {
      return { query: GetPowerBiReportUrlDocument, variables: variables }
    }