import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const CallIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M8.512 20.023C8.527 19.393 8.564 18.6 8.652 18.099C8.748 17.557 8.934 16.905 9.077 16.446C9.189 16.086 9.09 15.694 8.824 15.427L6.62 13.21C7.427 11.678 8.367 10.451 9.412 9.412C10.451 8.367 11.678 7.427 13.21 6.62L15.428 8.824C15.696 9.09 16.087 9.189 16.447 9.077C16.905 8.935 17.558 8.748 18.1 8.652C18.601 8.564 19.393 8.527 20.024 8.512C20.568 8.499 21 8.055 21 7.51V4C21 3.562 20.672 3 20 3C15.461 3 11.126 4.84 7.966 7.966C4.84 11.126 3 15.461 3 20C3 20.672 3.562 21 4 21H7.51C8.055 21 8.499 20.568 8.512 20.023Z"
    />
  );
};
