import React, { useContext } from 'react';
import { GlobalContext } from '../GlobalContext';

interface CheckPrivilegesForDisplayProps {
  neededPrivileges: string;
  element: React.ReactElement;
}

export const CheckPrivilegesForDisplay = (
  props: CheckPrivilegesForDisplayProps
) => {
  const { loggedInUser, loading } = useContext(GlobalContext);
  if (loading) {
    return null;
  }
  const permissions =
    loggedInUser?.role?.urls?.map((url: any) => url.url) || [];
  if (permissions.includes(props.neededPrivileges)) {
    return props.element;
  } else {
    return null;
  }
};
