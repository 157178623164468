import { Button, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { CloseIcon } from '../../../../../assets/Icons/ExportPatients';
import { CustomSelect } from '../../../../../components/CustomSelect';
import { CustomSelectWithCheckBox } from '../../../../../components/CustomSelectWithCheckBox';
import DateRangeCustomInput from '../../../../../components/DateRangeCustomInput';
import { useProviderPatientFilterStyles } from './Styles';
import { getFormattedDate } from '../../../../../sharePipe/getFormattedDatePipe';
import { DynamicBadge } from '../../../../../components/DynamicBadge';
import { useCheckFilterChanges } from '../../../../../hooks/useCheckFilterChanges';

interface IState {
  gender: any;
  age: any;
  fromDate: any;
  toDate: any;
  careGap: any;
}

interface IAction {
  type: keyof IState | 'reset';
  value?: any;
}

const initialState: IState = {
  gender: null,
  age: null,
  fromDate: null,
  toDate: null,
  careGap: [],
};

const reducer = (prevState: IState, action: IAction) => {
  if (action.type === 'reset') {
    return initialState;
  }
  const result: IState = { ...prevState };
  result[action.type] = action.value;
  return result;
};

interface ProviderPatientFilterViewProps {
  options: any;
  selectedFilter: any | undefined;
  setSelectedFilter: any;
  setIsSearching?: any;
  applyFilterBtnIsClicked: boolean;
  setApplyFilterBtnIsClicked: (input: boolean) => void;
  loading: boolean;
  displayedCount: number;
  setSortBy: (input: any) => void;
}

export const ProviderPatientFilterView = (
  props: ProviderPatientFilterViewProps
) => {
  const {
    options,
    selectedFilter,
    setSelectedFilter,
    applyFilterBtnIsClicked,
    setApplyFilterBtnIsClicked,
    loading,
    displayedCount,
    setSortBy,
  } = props;

  const classes = useProviderPatientFilterStyles();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const onChange = (name: any, value: any) => {
    dispatch({ type: name, value });
  };

  const convertToArray = (para: any) => {
    if (Array.isArray(para)) {
      return para;
    } else if (para === '' || para === null) {
      return [];
    } else {
      return [para];
    }
  };

  const {
    isAddAtLeastOneFilter,
    setIsAddAtLeastOneFilter,
  } = useCheckFilterChanges(initialState, state);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.selectAndButton}>
          <div className={classes.selectRow}>
            <div className={classes.selectContainer}>
              <CustomSelect
                state={state.gender}
                type={'gender'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Gender'}
                placeholder={'Select'}
                items={options.Gender || []}
              />
            </div>

            <div className={classes.selectContainer}>
              <CustomSelect
                state={state.age}
                type={'age'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Age'}
                placeholder={'Select'}
                items={options.Age || []}
              />
            </div>

            <div className={classes.daysSelectContainer}>
              <DateRangeCustomInput
                inputTitle={'Last Date of Service'}
                emptyLabel={'Last DOS From'}
                type={'fromDate'}
                state={state.fromDate || null}
                setState={onChange}
                maxDate={null}
              />
              <div className={classes.divider}>
                <span className={classes.dividerText}>To</span>
              </div>
              <DateRangeCustomInput
                inputTitle={''}
                emptyLabel={'Today'}
                type={'toDate'}
                state={state.toDate || null}
                setState={onChange}
                minDate={state.fromDate || new Date('1970-01-02')}
              />
            </div>

            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                state={state.careGap || []}
                type={'careGap'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Open Care Gaps'}
                placeholder={'Select'}
                items={options.CareGap || []}
              />
            </div>
          </div>
          <div className={classes.ButtonRow}>
            <div className={classes.applyFilterBtnAndClearFilterBtnContainer}>
              <StyledTooltip
                title="Please add at least 1 filter to apply."
                disableHoverListener={isAddAtLeastOneFilter}
                placement="bottom-start"
              >
                <span>
                  <Button
                    classes={{
                      disabled: classes.disabledApplyFilterBtn,
                    }}
                    className={classes.applyFilterBtn}
                    id="apply-button"
                    variant="contained"
                    disabled={!isAddAtLeastOneFilter}
                    disableElevation
                    onClick={() => {
                      // set filters into selected
                      setSelectedFilter({
                        info: {
                          Gender: convertToArray(state.gender),
                          Age: convertToArray(state.age),
                          LastDateOfServiceFrom: state.fromDate
                            ? getFormattedDate(state.fromDate)
                            : '',
                          LastDateOfServiceTo: state.toDate
                            ? getFormattedDate(state.toDate)
                            : '',
                          CareGaps: state.careGap.includes('All')
                            ? ['ALL']
                            : state.careGap,
                        },
                      });
                      setSortBy({ field: 'fullName', method: 'asc' as const });
                      if (props.setIsSearching) props.setIsSearching(false);
                      setApplyFilterBtnIsClicked(true);
                    }}
                  >
                    <span>Apply Filters</span>
                  </Button>
                </span>
              </StyledTooltip>
              <div
                className={classes.clearFilterContainer}
                onClick={() => {
                  setIsAddAtLeastOneFilter(false);
                  // set filters into default
                  setSelectedFilter({
                    info: {
                      Gender: null,
                      Age: null,
                      LastDateOfServiceFrom: null,
                      LastDateOfServiceTo: null,
                      CareGaps: [],
                    },
                  });
                  dispatch({ type: 'reset' });
                  props.setIsSearching(false);
                  setApplyFilterBtnIsClicked(false);
                }}
              >
                <CloseIcon className={classes.closeIcon} />
                <span className={classes.filterText}>Clear Filters</span>
              </div>
            </div>

            {applyFilterBtnIsClicked && (
              <DynamicBadge
                loading={loading}
                displayedCount={displayedCount}
                prefixText=""
                suffixText="Patient(s) Filtered"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledTooltip = withStyles({
  tooltip: {
    width: 'fit-content',
    maxWidth: 430,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    padding: '8px 12px 8px 12px',
  },
  popper: {
    top: '-34px !important',
    left: '78px !important',
  },
})(Tooltip);
