import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Chip } from '@material-ui/core';
import { formatNumberPipe } from '../../../../../../sharePipe/formatNumberPipe';
import { theme } from '../../../../../../themes/theme';

interface Props {
  patientCount: number;
  noChipStyle: boolean;
  preFix?: string;
  suffix?: string;
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  chip: {
    height: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    '& .MuiChip-label': {
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.primary.main,
      lineHeight: '20px',
    },
  },
  noChipStyle: {
    height: 16,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
  },
}));

export const StaticPatientCount = (props: Props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.noChipStyle} component="span">
      {props.noChipStyle ? (
        <>
          {props.patientCount}
          <span
            style={{
              fontWeight: theme.typography.fontWeightBold,
              color: '#000000',
            }}
          >
            patients
          </span>
        </>
      ) : (
        <>
          {props.preFix ? props.preFix : ''}
          <Chip
            className={classes.chip}
            label={formatNumberPipe(props.patientCount)}
          />
          {props.suffix ? (
            props.suffix
          ) : (
            <>
              <span
                style={{
                  fontWeight: theme.typography.fontWeightBold,
                  color: '#000000',
                  fontSize: 14,
                  lineHeight: '24px',
                }}
              >
                patient(s)
              </span>
            </>
          )}
        </>
      )}
    </Typography>
  );
};
