import React, { useState } from 'react';
import {
  FormControl,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  List,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useChangeCoordinatorSelectStyles } from './Styles';
import { CareTeamSelectItem } from '../../../CreatePanel/components/CareTeamSelect/CareTeamSelectItem';
import { LabelAvatar } from '../../../../../components/Avatar';
import { ButtonMediumPrimaryText } from '../../../../../components/Buttons';
import { AddIcon } from '../../../../../assets/Icons/PanelsList';
import { StyledCheckbox } from '../../../../../components/StyledCheckbox';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { currentlyAssignedFromActionFooterType } from '../ChangeCoordinatorDialog/Container';
import { RoleType } from '../../../../../enum/Users';
interface ChangeCoordinatorSelectViewProps {
  droplistAttr: {
    maxHeight: number;
    width?: number | string;
  };
  careTeamUsers: any[];
  numPatients: number;
  selectedCareTeam: any[];
  setSelectedCareTeam: any;
  assignedPatients: number;
  setAssignedPatients: any;
  classes: {
    multipleSelect: string;
    formControl: string;
    inputTitle: string;
    placeholder: string;
    unassignedRow?: string;
    unassignedCircle?: string;
    unassignedText?: string;
    numPatients?: string;
    patientsText?: string;
    addCareTeamProvider?: string;
    addCareTeamProviderBtn?: string;
    addCareTeamProviderText?: string;
  };
  fromCoordinators: any;
  source: string;
  currentlyAssignedFromActionFooter?: currentlyAssignedFromActionFooterType;
  currentlyAssigned?: any;
  setCurrentlyAssigned?: any;
  fromUnAssign?: boolean;
}

export const ChangeCoordinatorSelectView = (
  props: ChangeCoordinatorSelectViewProps
) => {
  const theme = useTheme();
  const classes = useChangeCoordinatorSelectStyles();
  const [selectedItems, setSelectedItems]: any[] = useState([]);
  const [isClickedAdd, setIsClickedAdd] = useState(false);
  React.useEffect(() => {
    setSelectedItems([]);
    props.setSelectedCareTeam([]);
    props.setAssignedPatients(0);
    setIsClickedAdd(false);
  }, [props.careTeamUsers]);

  const matchSelectedCareTeam = (values: any) => {
    if (!values) return;
    let fullNameList = Array.isArray(values) ? values : [values];
    // check is any member is removed from select
    if (fullNameList.length < props.selectedCareTeam.length) {
      let removed: any = props.selectedCareTeam.filter(function (objOne) {
        return !fullNameList.some(function (objTwo: any) {
          return objOne.id === objTwo.id;
        });
      });
      removed = removed.length > 0 ? removed[0] : removed;
      const count =
        removed.assignedPatients === undefined ? 0 : removed.assignedPatients;
      if (count !== 0) {
        props.setAssignedPatients(props.assignedPatients - count);
      }
    }
    fullNameList.forEach((item) => {
      const match = props.selectedCareTeam.find((user) => user.id === item.id);
      item.assignedPatients =
        match?.assignedPatients === undefined ? 0 : match.assignedPatients;
    });
    setSelectedItems(fullNameList);
    props.setSelectedCareTeam(fullNameList);
    // setIsClickedAdd(false);
    // props.updateMasterForm(fullNameList);

    setCareTeamSelectInputValue('');
  };

  const removeSelectedCareTeam = (targetCareTeam: any) => {
    const newSelectedItems = selectedItems.filter(
      (user: any) => user.id !== targetCareTeam.id
    );
    setSelectedItems(newSelectedItems);
    const count =
      targetCareTeam.assignedPatients === undefined
        ? 0
        : targetCareTeam.assignedPatients;
    const newSelectedCareTeam = props.selectedCareTeam.filter(
      (user: any) => user.id !== targetCareTeam.id
    );
    if (newSelectedCareTeam.length === 0) {
      props.setAssignedPatients(0);
    } else if (count !== 0) {
      props.setAssignedPatients(props.assignedPatients - count);
    }
    props.setSelectedCareTeam(newSelectedCareTeam);
    // props.updateMasterForm(newSelectedCareTeam);
  };

  const assignSelectedCareTeam = (
    targetCareTeam: any,
    assignedPatients: number
  ) => {
    const newSelectedCareTeam = props.selectedCareTeam.map((item: any) => {
      if (item.id === targetCareTeam.id) {
        return {
          ...item,
          assignedPatients: assignedPatients,
        };
      }

      return item;
    });
    props.setSelectedCareTeam(newSelectedCareTeam);
    let count = 0;
    newSelectedCareTeam.forEach((item: any) => {
      if (item.assignedPatients !== undefined) {
        count += item.assignedPatients;
      }
    });
    props.setAssignedPatients(count);
  };

  const filterOptions = (options: any, state: any) => {
    const injectOptions: any[] = [];
    const isSearch = careTeamSelectInputValue.length > 0;
    const inputValueLower = careTeamSelectInputValue.toLowerCase();
    for (const option of options) {
      if (!selectedItems.find((item: any) => item.id == option.id)) {
        if (isSearch) {
          if (
            (option?.firstName + ' ' + option?.lastName)
              .toLowerCase()
              .includes(inputValueLower)
          ) {
            injectOptions.push(option);
          }
        } else {
          injectOptions.push(option);
        }
      }
    }
    return injectOptions;
  };

  const [careTeamSelectInputValue, setCareTeamSelectInputValue] =
    React.useState('');
  const changeOptionBaseOnValue = (newValue: string, type: string) => {
    setCareTeamSelectInputValue(newValue);
  };

  const [openOptions, setOpenOptions] = React.useState(true);
  React.useEffect(() => {
    if (props.currentlyAssignedFromActionFooter) {
      // from panels list action footer
      props.setCurrentlyAssigned(
        props.currentlyAssignedFromActionFooter.data
          ?.getTeamMembersBySelectedPatients.results ?? []
      );
    } else {
      props.setCurrentlyAssigned(props.fromCoordinators);
    }
  }, [props.fromCoordinators, props.currentlyAssignedFromActionFooter]);

  const [
    removeCurrentlyAssignedCoordinators,
    setRemoveCurrentlyAssignedCoordinators,
  ] = React.useState([] as any[]);

  React.useEffect(() => {
    // remove the coordinators in the from list
    if (props.careTeamUsers && props.currentlyAssigned) {
      const tmp = props.careTeamUsers.filter(
        (user) =>
          props.currentlyAssigned.findIndex(
            (from: any) => from.user.id === user.id
          ) === -1
      );
      setRemoveCurrentlyAssignedCoordinators(tmp);
    }
  }, [props.careTeamUsers, props.currentlyAssigned]);

  const getPromptText = () => {
    if (props.source === 'PatientFaceSheet') {
      return props.selectedCareTeam.length === 0
        ? 'Search Coordinator and Assign Patient'
        : 'Switch to Another Care Coordinator';
    } else if (props.source === 'PanelPatientActionFooter') {
      return props.numPatients > 1
        ? 'Search Coordinator and Assign Patients'
        : 'Search Coordinator and Assign Patient';
    }
  };

  const getRoleName = (item: any) => {
    return item.user.role?.roleName == RoleType.ADMIN || item.user.roleName == RoleType.ADMIN
      ? RoleType.CARE_MANAGER
      : item.user.role?.roleName || item.user.roleName || 'Unknown';
  };

  return (
    <div className={props.classes.multipleSelect}>
      {props.currentlyAssignedFromActionFooter?.loading ? (
        <LoadingSpinner />
      ) : (
        <>
        {!props.fromUnAssign &&<>
          <Typography className={classes.title}>Currently Assigned</Typography>
          <List className={classes.currentlyAssigned + ' ' + classes.list}>
            {props.currentlyAssigned.map((item: any, idx: number) => {
              return (
                <CareTeamSelectItem
                  careTeamMember={{
                    firstName: item.user.firstName,
                    lastName: item.user.lastName,
                    role: getRoleName(item),
                    title: getRoleName(item),
                    assignedPatients: item.count,
                  }}
                  removeSelectedCareTeam={() => {}}
                  maxNumPatients={item.count}
                  isBorder={idx !== props.currentlyAssigned.length - 1}
                  setAssignedPatientToMember={() => {}}
                  key={item.user.id}
                  disableInput={true}
                  disableSuffixText={true}
                  source={props.source}
                  section={'CurrentlyAssigned'}
                />
              );
            })}
          </List>
        </>
        }
          <Typography className={classes.title}>{props.fromUnAssign?'Assign to':'Change to'}</Typography>

          <FormControl className={props.classes.formControl}>
            {/*selectedCareTeam*/}
            {props.selectedCareTeam.length > 0 && (
              <List className={classes.list}>
                {props.selectedCareTeam.map((item: any, idx: any) => (
                  <CareTeamSelectItem
                    careTeamMember={item}
                    removeSelectedCareTeam={removeSelectedCareTeam}
                    maxNumPatients={props.numPatients}
                    isBorder={idx !== props.selectedCareTeam.length - 1}
                    setAssignedPatientToMember={assignSelectedCareTeam}
                    key={item.id}
                    disableInput={true}
                    disableSuffixText={true}
                    source={props.source}
                    section={'ChangeTo'}
                  />
                ))}
              </List>
            )}
            {/* Error / Hint messages */}
            {props.source === 'PanelPatientActionFooter' &&
              props.selectedCareTeam.length > 1 && (
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: theme.palette.grey[500],
                    marginTop: theme.spacing(-1.5),
                  }}
                >
                  If you added multiple Coordinators, selected patients will be
                  assigned randomly.
                </Typography>
              )}
            {props.numPatients - props.assignedPatients < 0 && (
              <div className={classes.patientOverFlowErrorMessage}>
                You can only assign {props.numPatients} patient(s) in total.
              </div>
            )}
            {props.assignedPatients >= 1 &&
              props.numPatients - props.assignedPatients > 0 && (
                <div className={classes.patientOverFlowErrorMessage}>
                  Please assign all selected patient to new coordinator.
                </div>
              )}
            {/* add care team provider button */}
            {!isClickedAdd && (
              <div className={props.classes.addCareTeamProvider}>
                <ButtonMediumPrimaryText
                  onClick={() => setIsClickedAdd(!isClickedAdd)}
                  className={classes.addIconBtn}
                  startIcon={<AddIcon className={classes.addIcon} />}
                />
                <Typography
                  variant="body1"
                  className={props.classes.addCareTeamProviderText}
                  onClick={() => setIsClickedAdd(!isClickedAdd)}
                >
                  {getPromptText()}
                </Typography>
              </div>
            )}
            {/* add care team provider */}
            {removeCurrentlyAssignedCoordinators.length >= 0 &&
              isClickedAdd && (
                <div className={props.classes.addCareTeamProvider}>
                  <Autocomplete
                    disableCloseOnSelect={
                      // keep droplist on if not in Patient Face Sheet
                      props.source === 'PatientFaceSheet' ? false : true
                    }
                    open={openOptions}
                    onOpen={() => setOpenOptions(true)}
                    onClose={() => {
                      setOpenOptions(false);
                      setIsClickedAdd(false);
                    }}
                    inputValue={careTeamSelectInputValue}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === 'input') {
                        changeOptionBaseOnValue(value, 'lineOfBusiness');
                      }
                    }}
                    className={classes.autoComplete}
                    size="small"
                    multiple={
                      props.source === 'PanelPatientActionFooter' ? true : false
                    }
                    options={removeCurrentlyAssignedCoordinators}
                    getOptionLabel={(option) =>
                      option?.firstName + option?.lastName ?? ''
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={selectedItems}
                    onChange={(e, v) => {
                      setOpenOptions(true);
                      matchSelectedCareTeam(v);
                    }}
                    // filterOptions={filterOptions}
                    popupIcon={<KeyboardArrowDown />}
                    closeIcon={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        onFocus={() => setOpenOptions(true)}
                        variant="outlined"
                        placeholder={'Search by Name'}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <ButtonMediumPrimaryText
                              disableRipple
                              className={classes.addIconBtn}
                              startIcon={
                                <AddIcon className={classes.addIcon} />
                              }
                            />
                          ),
                        }}
                      />
                    )}
                    noOptionsText="No matching providers"
                    classes={{
                      tagSizeSmall: classes.chip,
                      option: classes.acOption,
                      noOptions: classes.noAcOption,
                    }}
                    renderOption={(option, { selected }) => (
                      <MenuItem
                        component="div"
                        selected={
                          selectedItems.find(
                            (item: any) => item.id === option.id
                          ) !== undefined
                        }
                        classes={{
                          root: classes.acMenuRoot,
                          selected: classes.selected,
                        }}
                      >
                        <LabelAvatar
                          firstName={option.firstName}
                          lastName={option.lastName}
                          title={option.role?.roleName ?? option.title}
                          styles={classes}
                          careTeamSelectInputValue={careTeamSelectInputValue}
                        />
                        {props.source === 'PatientFaceSheet' ? (
                          <>
                            {selectedItems.find(
                              (item: any) => item.id === option.id
                            ) && (
                              <CheckIcon
                                style={{
                                  height: 25,
                                  position: 'absolute',
                                  right: '15px',
                                  color: '#0C77D8',
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <StyledCheckbox
                            checkedAll={true}
                            checked={selected}
                            color="primary"
                            style={{
                              height: 25,
                              position: 'absolute',
                              right: '30px',
                            }}
                          />
                        )}
                      </MenuItem>
                    )}
                  />
                </div>
              )}
          </FormControl>
        </>
      )}
    </div>
  );
};
