import { createStyles, makeStyles } from '@material-ui/styles';

export const useErrorViewStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 24,
    },
    errorTitle: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 700,
    },
    errorContent: {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: '24px',
      color: '#656565',
      fontWeight: 500,
    },
    backToDashboardBtn: {
      color: '#FFF',
      background: '#0C77D8',
      borderRadius: 8,
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
  })
);
