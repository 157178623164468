import React from 'react';
import { Role } from '../app.types.generated';
import { RoleType } from '../enum/Users';

const roleNameOrder = ['MSO Admin', 'Care Manager', 'Coordinator', 'Clinician'];

export const useNewRoleOptions = (roleOptions: any) => {
  const [newRoleOptions, setNewRoleOptions] = React.useState<Role[]>([]);
  React.useEffect(() => {
    if (roleOptions && roleOptions.length > 0) {
      const tmpRoleOptions = [];

      for (let role of roleOptions) {
        if (role.roleName === RoleType.ADMIN) {
          let newRoleName = RoleType.CARE_MANAGER;
          tmpRoleOptions.push({
            ...role,
            roleName: newRoleName,
          });
        } else {
          tmpRoleOptions.push(role);
        }
      }

      tmpRoleOptions.sort((a, b) => {
        return (
          roleNameOrder.indexOf(a.roleName) - roleNameOrder.indexOf(b.roleName)
        );
      });
      setNewRoleOptions([...tmpRoleOptions]);
    }
  }, [roleOptions]);

  return newRoleOptions;
};
