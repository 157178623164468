import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router';
import { CloseIcon } from '../../../../../../assets/Icons/ExportPatients';
import { AutoCompleteMultipleSelect } from '../../../../../../components/AutoCompleteMultipleSelect';
import { CareGapAutoCompleteSelectorNoChips } from '../../../../../../components/CareGapAutoCompleteSelector/CareGapAutoCompleteSelectorNoChips';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../../../../components/CheckIsAdmin';
import { CustomSelect } from '../../../../../../components/CustomSelect';
import { CustomSelectWithCheckBox } from '../../../../../../components/CustomSelectWithCheckBox';
import { CustomSelectWithCheckBoxAvatar } from '../../../../../../components/CustomSelectWithCheckBoxAvatar';
import { CustomSelectWithLayer } from '../../../../../../components/CustomSelectWithLayer';
import DateRangeCustomInput from '../../../../../../components/DateRangeCustomInput';
import { DynamicBadge } from '../../../../../../components/DynamicBadge';
import { useFeature } from '../../../../../../components/FeatureFlag';
import { GlobalContext } from '../../../../../../components/GlobalContext';
import { StyledCheckbox } from '../../../../../../components/StyledCheckbox';
import { useCheckFilterChanges } from '../../../../../../hooks/useCheckFilterChanges';
import { sortArrayObjectPipe } from '../../../../../../sharePipe/sortArrayObjectPipe';
import { usePatientFilterStore } from '../../../../../../store/features/patientFilter';
import { initialFilterState } from '../../../../../../store/reducer/patientFilter';
import { useGetPcpsByNameQuery } from '../../../../CreatePanel/components/Step2/queryICDCPT.generated';

const eligibilityStatusOptions = [
  {
    name: 'Active (Internal)',
    value: 'ActiveInternal',
  },
  {
    name: 'Active (External)',
    value: 'ActiveExternal',
  },
  {
    name: 'Inactive',
    value: 'Inactive',
  },
  {
    name: 'Unverified',
    value: 'Rejected',
  },
  {
    name: 'Unavailable',
    value: 'Unavailable',
  },
];

const careCoordinationStatusOption = [
  {
    name: 'Appt Needed',
    value: 'Appt Needed',
  },
  {
    name: 'Site Follow Up',
    value: 'Site Follow Up',
  },
  {
    name: 'No Show',
    value: 'No Show',
  },
  {
    name: 'Appt Canceled',
    value: 'Appt Canceled',
  },
  {
    name: 'Snoozed',
    value: 'Snoozed',
  },
  {
    name: 'Planned to Walk-in',
    value: 'Planned to Walk-in',
  },
  {
    name: 'Appt Booked',
    value: 'Appt Booked',
  },
  {
    name: 'Visit Completed',
    value: 'Visit Completed',
  },
  {
    name: 'Outreach Closed',
    value: 'Outreach Closed',
  },
  {
    name: 'No Status',
    value: 'No Status',
  },
  {
    name: 'Deceased',
    value: 'Deceased',
  },
];

const genderOption = [
  { name: 'MALE', value: 'M', __typename: 'NameValue' },
  { name: 'FEMALE', value: 'F', __typename: 'NameValue' },
  { name: 'UNKNOWN', value: 'X', __typename: 'NameValue' },
];

const ageRangeOption = [
  { name: '65+', value: '65-1000', __typename: 'NameValue' },
  { name: '60-64', value: '60-64', __typename: 'NameValue' },
  { name: '50-59', value: '50-59', __typename: 'NameValue' },
  { name: '40-49', value: '40-49', __typename: 'NameValue' },
  { name: '30-39', value: '30-39', __typename: 'NameValue' },
  { name: '20-29', value: '20-29', __typename: 'NameValue' },
  { name: '10-19', value: '10-19', __typename: 'NameValue' },
  { name: '0-9', value: '0-9', __typename: 'NameValue' },
];

const hasFutureAppointmentOption = [
  { name: 'Has Future Appt.', value: 'yes', __typename: 'NameValue' },
  { name: 'No Future Appt.', value: 'no', __typename: 'NameValue' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  container: {
    padding: theme.spacing(1.5, 3.75),
  },
  selectAndButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 12,
  },
  searchRow: {
    paddingLeft: 24,
    display: 'flex',
    borderBottom: '1px solid #d1d1d1',
    alignItems: 'center',
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  dobButton: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    color: theme.palette.secondary.dark,
    height: 24,
  },
  savedFilterButton: {
    background: 'transparent!important',
    marginLeft: 12,
    marginRight: 12,
  },
  filterButtonText: {
    fontSize: 14,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    padding: theme.spacing(0, 0, 0, 0),
    color: theme.palette.grey[400],
    width: 24,
    height: 24,
    marginRight: 11,
  },
  row: {
    display: 'flex',
    marginLeft: 30,
    marginRight: 30,
    flexWrap: 'wrap',
    '& #apply-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: 102,
      height: 32,
      marginTop: 20,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',
      marginRight: 24,
      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },
    '& #creation-button': {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.secondary.main,
      borderRadius: '8px',
      width: 102,
      height: 32,
      marginTop: 20,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',
      marginRight: 24,
      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
      },
    },
    '& #manage-button': {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.secondary.main,
      borderRadius: '8px',
      width: 165,
      height: 32,
      marginTop: 20,
      fontSize: 14,
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
      },
    },
  },
  selectRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    rowGap: 12,
  },
  ButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
    width: '100%',
  },
  applyFilterBtn: {
    color: '#FFF',
    fontWeight: 'bold',
    lineHeight: '24px',
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: 102,
    height: 32,
    fontSize: 14,
    textAlign: 'center',
    padding: '0',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
  },
  disabledApplyFilterBtn: {
    color: 'rgba(255,255,255,0.5)!important',
    background: `${theme.palette.primary.main}!important`,
  },
  applyFilterBtnAndClearFilterBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 24,
    flexGrow: 1,
  },
  divider: {
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['500'],
    lineHeight: '16px',
    fontSize: 12,
    paddingTop: 30,
  },
  clearFilterBtn: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filterText: {
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['600'],
    fontSize: 14,
  },
  closeIcon: {
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  selectContainer: {
    width: 229,
    '& > div': {
      marginTop: '0',
    },
    '& div[class*="MuiFormControl-root"] > div:first-child': {
      marginBottom: '0',
    },
    cursor: 'pointer',
  },
  newPatientOnlyText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey['600'],
  },
  provideContainer: {
    width: 229,
    '& > div': {
      marginTop: '0',
    },
    '& div[class*="MuiFormControl-root"] > div:first-child': {
      marginBottom: '0',
    },
  },
  careGapContainer: {
    width: 549,
  },
  daysSelectContainer: {
    width: 458,
    display: 'flex',
    '& > div': {
      marginTop: '0',
    },
    '& h5 + div': { marginBottom: 0 },
  },
}));

export interface IState {
  gender: any;
  age: any;
  fromDate: any;
  toDate: any;
  risk: any;
  careGap: any;
  gapStatus: any;
  engagementStatus: any;
  diseaseAndTags: any;
  futureAppointment: any;
  provider: any;
  clinicId: any[];
  divisionId: any[];
  coordinationStatus: any[];
  dueDateFrom: any;
  dueDateTo: any;
  userIds: any[];
  languages: string[];
  healthPlan: string[];
  eligibilityStatuses: string[];
  careGapStatus: string[];
  lineOfBusiness: string[];
  payerIds: string[];
  callAttemptCount: string[];
  newPatientOnly: boolean;
}

interface AllPatientFilterOptionProps {
  defaultSortBy: any;
  setEnableTable: any;
  setIsSearching?: any;
  applyFilterBtnIsClicked: boolean;
  setApplyFilterBtnIsClicked: (input: boolean) => void;
  loading: boolean;
  displayedCount: number;
  options: any;
  setSortBy: (input: any) => void;
  hideCoordinationFilter?: boolean;
  careTeamUsers?: any;
  componentId: string;
}

const noCoveringProviderOption = {
  id: 'NoCoveringProvider',
};

export const AllPatientFilters = (props: AllPatientFilterOptionProps) => {
  const { loggedInUser } = useContext(GlobalContext);
  const classes = useStyles();
  const useFeatureDivision = useFeature('division');
  const useFeatureCoveringProvider = useFeature('coordination');

  const [pcpsOptions, setPcpsOptions] = React.useState<any[]>([]);
  const [pcpsLoading, setPcpsLoading] = React.useState<boolean | undefined>(
    undefined
  );
  const queryPcps = useGetPcpsByNameQuery({
    variables: {
      input: {
        limit: 1000,
        skip: 0,
      },
    },
  });

  React.useEffect(() => {
    if (!queryPcps.called || queryPcps.loading) {
      setPcpsLoading(true);
      return undefined;
    }

    setPcpsLoading(false);
    const providerOptions = [noCoveringProviderOption];
    const data = queryPcps.data?.pcps?.result ?? [];
    setPcpsOptions(providerOptions.concat(data));
  }, [queryPcps.data]);
  const location = useLocation();
  const paths = location.pathname.split('/');

  const patientFilterStore = usePatientFilterStore(props.componentId);

  patientFilterStore.init();
  const {
    selectedFilters,
    dispatchSelectedFilters,
    apply,
    clear,
  } = patientFilterStore;

  const onChange = (name: any, value: any) => {
    dispatchSelectedFilters({ type: name, value });
  };

  const {
    isAddAtLeastOneFilter,
    setIsAddAtLeastOneFilter,
  } = useCheckFilterChanges(initialFilterState, selectedFilters);

  const hasFeature = useFeature('eligibilityStatusFilter');

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.selectAndButton}>
          <div className={classes.selectRow}>
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                type={'gender'}
                state={selectedFilters.gender}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Gender'}
                placeholder={'Select'}
                items={genderOption}
                isObject={true}
                width={205}
              />
            </div>
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                type={'age'}
                state={selectedFilters.age}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Age'}
                placeholder={'Select'}
                items={ageRangeOption}
                isObject={true}
                width={205}
              />
            </div>
            <div className={classes.daysSelectContainer}>
              <DateRangeCustomInput
                inputTitle={'Last Date of Service'}
                emptyLabel={'Last DOS From'}
                type={'ldosFrom'}
                state={selectedFilters.ldosFrom}
                maxDate={selectedFilters.ldosTo}
                setState={onChange}
                width={205}
              />
              <div className={classes.divider}>
                <span className={classes.dividerText}>To</span>
              </div>
              <DateRangeCustomInput
                inputTitle={''}
                emptyLabel={'Today'}
                type={'ldosTo'}
                state={selectedFilters.ldosTo}
                minDate={selectedFilters.ldosFrom}
                setState={onChange}
                width={205}
              />
            </div>
            {useFeatureCoveringProvider ? (
              <div className={classes.selectContainer}>
                <AutoCompleteMultipleSelect
                  options={pcpsOptions}
                  type={'npis'}
                  selectedOptions={selectedFilters.npis}
                  title={'Covering Providers'}
                  inputPlaceholder={'Search or Select'}
                  noOptionsText={
                    pcpsLoading ? 'Loading...' : 'No matching providers'
                  }
                  onChangedOptions={onChange}
                  width={205}
                />
              </div>
            ) : null}
            <div className={classes.selectContainer}>
              <CareGapAutoCompleteSelectorNoChips
                setState={onChange}
                type={'openGaps'}
                state={selectedFilters.openGaps}
                isMultiple={true}
                inputTitle={'Open Care Gaps'}
                width={205}
                placeholder={'Search or Select '}
                items={props?.options?.CareGap ?? []}
                source={'All Patients Filters'}
              />
            </div>
            <div className={classes.daysSelectContainer}>
              <DateRangeCustomInput
                inputTitle={'Care Gap Due Date'}
                emptyLabel={'Select Date From'}
                type={'dueDateFrom'}
                state={selectedFilters.dueDateFrom}
                setState={onChange}
                maxDate={new Date('2099-01-01')}
                width={205}
              />
              <div className={classes.divider}>
                <span className={classes.dividerText}>To</span>
              </div>
              <DateRangeCustomInput
                inputTitle={''}
                emptyLabel={'Select a Date'}
                type={'dueDateTo'}
                state={selectedFilters.dueDateTo}
                setState={onChange}
                maxDate={new Date('2099-01-01')}
                minDate={selectedFilters.dueDateFrom || new Date()}
                width={205}
              />
            </div>
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                state={selectedFilters.careGapStatus}
                type={'careGapStatus'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Care Gap Status'}
                width={205}
                placeholder={'Select'}
                items={props?.options?.CareGapStatus ?? []}
                isObject={true}
                showSelectedCount={true}
              />
            </div>
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                state={selectedFilters.payerIds}
                type={'payerIds'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Payer'}
                width={205}
                placeholder={'Select'}
                items={sortArrayObjectPipe(
                  props.options?.Payers ?? [],
                  'asc',
                  'name'
                )}
                isObject={true}
                showSelectedCount={true}
                showValue={true}
                tooltips={true}
              />
            </div>
            <div className={classes.selectContainer}>
              <CustomSelectWithLayer
                state={selectedFilters.lineOfBusiness}
                type={'lineOfBusiness'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Line of Business'}
                width={205}
                placeholder={'Select'}
                items={props?.options?.LineOfBusiness ?? []}
                isObject={true}
                showSelectedCount={true}
              />
            </div>
            <div className={classes.selectContainer}>
              <CustomSelect
                setState={onChange}
                type={'hasFutureAppointment'}
                state={selectedFilters.hasFutureAppointment}
                isMultiple={true}
                inputTitle={'Future Appointment'}
                placeholder={'Select'}
                items={hasFutureAppointmentOption}
              />
            </div>
            {/* to do */}
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                state={selectedFilters.clinicId}
                showValue={true}
                type={'clinicId'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Clinic ID'}
                width={205}
                placeholder={'Select'}
                items={props?.options?.Clinics ?? []}
                isObject={true}
                showSelectedCount={true}
              />
            </div>
            {useFeatureDivision ? (
              <div className={classes.selectContainer}>
                <CustomSelectWithCheckBox
                  // disable={true}
                  state={selectedFilters.divisionId}
                  type={'divisionId'}
                  setState={onChange}
                  isMultiple={true}
                  inputTitle={'Division'}
                  width={205}
                  placeholder={'Select'}
                  items={props?.options?.Division ?? []}
                  isObject={true}
                  showSelectedCount={true}
                />
              </div>
            ) : null}
            <div className={classes.selectContainer}>
              <CustomSelectWithCheckBox
                state={selectedFilters.callAttemptCount}
                type={'callAttemptCount'}
                setState={onChange}
                isMultiple={true}
                inputTitle={'Call Attempts'}
                width={205}
                placeholder={'Select'}
                items={props?.options?.CallAttemptCount ?? []}
                isObject={true}
                showSelectedCount={true}
              />
            </div>
            {!props.hideCoordinationFilter && (
              <div className={classes.selectContainer}>
                <CustomSelectWithCheckBox
                  state={selectedFilters.coordinationStatus}
                  type={'coordinationStatus'}
                  setState={onChange}
                  isMultiple={true}
                  inputTitle={'Coordination Status'}
                  width={205}
                  placeholder={'Select'}
                  items={careCoordinationStatusOption}
                  isObject={true}
                  showSelectedCount={true}
                />
              </div>
            )}
            {props.careTeamUsers &&
              (CheckIsAdmin(loggedInUser) ||
                CheckIsCareManager(loggedInUser)) && (
                <div className={classes.selectContainer}>
                  <CustomSelectWithCheckBoxAvatar
                    state={selectedFilters.userIds}
                    type={'userIds'} // Patient Status (Coordination)
                    setState={onChange}
                    isMultiple={true}
                    inputTitle={'Care Coordinators'}
                    width={205}
                    placeholder={'Select'}
                    items={props.careTeamUsers}
                    isObject={true}
                    showSelectedCount={true}
                    avatarItem={true}
                  />
                </div>
              )}

            {/* only show the Preferred Language in assigned patient*/}
            {(paths.lastIndexOf('assigned') === paths.length - 1 ||
              paths.lastIndexOf('unassigned') === paths.length - 1) && (
              <div className={classes.selectContainer}>
                <CustomSelectWithCheckBox
                  state={selectedFilters.languages}
                  type={'languages'}
                  setState={onChange}
                  isMultiple={true}
                  inputTitle={'Preferred Language'}
                  width={205}
                  placeholder={'Select'}
                  items={props.options.Languages}
                  isObject={true}
                  showSelectedCount={true}
                />
              </div>
            )}
            {/* Eligibility Status */}
            {hasFeature && (
              <div className={classes.selectContainer}>
                <CustomSelectWithCheckBox
                  state={selectedFilters.eligibilityStatuses}
                  type={'eligibilityStatuses'}
                  setState={onChange}
                  isMultiple={true}
                  inputTitle={'Eligibility Status'}
                  width={205}
                  placeholder={'Select'}
                  items={eligibilityStatusOptions}
                  isObject={true}
                  showSelectedCount={true}
                />
              </div>
            )}

            {/*For new patient*/}
            <div
              className={classes.selectContainer}
              onClick={() => {
                onChange('newPatientOnly', !selectedFilters.newPatientOnly);
              }}
            >
              <div
                style={{ marginTop: 24, display: 'flex', flexDirection: 'row' }}
              >
                <StyledCheckbox
                  checked={selectedFilters.newPatientOnly}
                  color="primary"
                  checkedAll={true}
                />
                <p className={classes.newPatientOnlyText}>New Patient Only</p>
              </div>
            </div>

            <div className={classes.ButtonRow}>
              <div className={classes.applyFilterBtnAndClearFilterBtnContainer}>
                <StyledTooltip
                  title="Please add at least 1 filter to apply."
                  disableHoverListener={isAddAtLeastOneFilter}
                  placement="bottom-start"
                >
                  <span>
                    <Button
                      disabled={!isAddAtLeastOneFilter}
                      className={classes.applyFilterBtn}
                      classes={{
                        disabled: classes.disabledApplyFilterBtn,
                      }}
                      id="apply-button"
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        apply();
                        props.setApplyFilterBtnIsClicked(true);
                        props.setSortBy({ ...props.defaultSortBy }); // each time reset the sortBy after clicking  Apply Filters
                        props.setEnableTable(true);
                        if (props.setIsSearching) props.setIsSearching(false);
                      }}
                    >
                      <span>Apply Filters</span>
                    </Button>
                  </span>
                </StyledTooltip>
                <Button
                  disableRipple
                  disableElevation
                  variant="text"
                  startIcon={<CloseIcon className={classes.closeIcon} />}
                  className={classes.clearFilterBtn}
                  onClick={() => {
                    setIsAddAtLeastOneFilter(false);
                    clear();
                    props.setEnableTable(false);
                    props.setApplyFilterBtnIsClicked(false);
                  }}
                >
                  <span className={classes.filterText}>Clear Filters</span>
                </Button>
              </div>

              {props.applyFilterBtnIsClicked && (
                <DynamicBadge
                  loading={props.loading}
                  displayedCount={props.displayedCount}
                  prefixText=""
                  suffixText="Patient(s) Filtered"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    width: 'fit-content',
    maxWidth: 430,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    padding: '8px 12px 8px 12px',
  },
  popper: {
    top: '-34px !important',
    left: '78px !important',
  },
})(Tooltip);
