import React, { useEffect } from 'react';
import { PanelPatient } from '../../../../../../app.types.generated';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { getDiffsAsObject } from '../../../../../../sharePipe/getDiffsAsObject';
import { CareGapPatientNotFound } from '../../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTableNotFound';
import { CareGapsDrawer } from '../../../../../CareGaps/Home/tabs/Population/components/CareGapsDrawer';
import {
  CareGapContext,
  useCareGapGlobalContext,
} from '../../../../../CareGaps/Home/tabs/Population/context';
import { PanelPatientsListActionsFooter } from '../../../components/PanelPatientsListActionsFooter/PanelPatientsListActionsFooter';
import { useKeyPatients } from '../../hooks/useKeyPatients';
import { useSwitchPatientWithPagination } from '../../hooks/useSwitchPatientWithPagination';
import { useUnlockPatientClosingPFS } from '../../hooks/useUnlockPatientClosingPFS';
import { useGetUsersByAccountIdQuery } from '../AssignedPatientsTable/index.generated';
import { useGetUnassignedPatientsQuery } from './index.generated';
import { UnassignedPatientsTableView } from './View';

import { GlobalContext } from '../../../../../../components/GlobalContext';
import { PatientFilterComponentIDs } from '../../../../../../enum/store';
import { MixPanelEvents } from '../../../../../../mixpanel/events';
import { usePatientFilterStore } from '../../../../../../store/features/patientFilter';
import { PanelSummaryContext } from '../../../PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { CheckIsHudsonClinician } from '../../../../../../components/CheckIsAdmin';

interface UnassignedPatientsTableContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  actionsFooter: any[];
  setActionsFooter: any;
  hardFresh: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  setShowAssignCoordinatorDialog?: (input: boolean) => void;
  setShowManageCoordinatorDialog?: (input: boolean) => void;
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSortBy: (input: any) => void;
  setResultRows: (input: any) => void;
  enableTable: boolean;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
  setPatientTableQuery: (input: any) => void;
  setSearchTextAndFilters: (input: any) => void;
  isSearching: boolean;
  assignCoordinator: any;
  enableChangeCoordinatorDialog: any;
  setEnableChangeCoordinatorDialog: any;
}

export const UnassignedPatientsTableContainer = (
  props: UnassignedPatientsTableContainerProps
) => {
  const [results, setResults] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [checkedPatient, setCheckedPatient] = React.useState<PanelPatient>(
    {} as PanelPatient
  );
  const [value, setValue] = React.useState(0); // 0 patient info, 1 Engagement, 2 Care Gaps
  const drawerHandler = (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    event.stopPropagation();
    setShowDrawer(!showDrawer);
    if (patient) {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.openPatientFaceSheet.name,
        { patientID: patient.id, panelID: patient.thisPanelId }
      );
      setCheckedPatient(patient);
    } else {
      setCheckedPatient({} as PanelPatient);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      MixPanelEvents.timeEvent(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    } else {
      MixPanelEvents.track(
        MixPanelEvents.userActivityAndJourneyMapping.patientFaceSheetPageTime
          .name
      );
    }
  }, [showDrawer]);

  const switchTab = (event: any, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const { searchName, searchDob } = useCareGapGlobalContext();

  const defaultFilters = {
    // by default
    panelId: props.panelId,
    limit: props.limit ?? 10,
    skip: props.skip ?? 0,
    sortBy: [{ ...props.sortBy }],
  };

  const patientFilterStore = usePatientFilterStore(
    PatientFilterComponentIDs.UNASSIGNED_PATIENTS
  );

  const tmpFilters = patientFilterStore.formattedFiltersForQuery;
  const enableTableFilters = {
    ...tmpFilters,
    ...defaultFilters,
    andName: searchName,
    andDob: searchDob,
  };

  React.useEffect(() => {
    props.setSkip(0);
  }, [tmpFilters, props.limit]);

  const panelUnassignedPatientQuery = useGetUnassignedPatientsQuery({
    variables: {
      input: props.enableTable ? enableTableFilters : defaultFilters,
    },
  });

  const userQuery = useGetUsersByAccountIdQuery({
    variables: {
      getUsersByAccountIdRequest: {
        limit: 0,
        skip: 0,
        sortBy: [{ field: 'fullName', method: 'asc' }],
      },
    },
  });

  const coordinatorOptions = React.useMemo(() => {
    return userQuery.data?.getUsersByAccountId?.results.filter(
      (item) => !CheckIsHudsonClinician(item)
    );
  }, [userQuery.data]);

  React.useEffect(() => {
    if (
      !panelUnassignedPatientQuery.called ||
      panelUnassignedPatientQuery.loading
    ) {
      props.setLoading(true);
      props.setPatientTableQuery(panelUnassignedPatientQuery);
    } else {
      props.setLoading(false);
      props.setDisplayedCount(
        panelUnassignedPatientQuery.data?.getPanelUnassignedPatients.count ?? 0
      );
    }
  }, [panelUnassignedPatientQuery]);

  React.useEffect(() => {
    props.setSearchTextAndFilters({
      andName: searchName,
      andDob: searchDob,
    });
  }, [searchName, searchDob]);

  React.useEffect(() => {
    if (props.skip !== undefined) {
      props.setSkip(0);
    }
  }, [props.limit]);

  const { currentPage } = useSwitchPatientWithPagination(
    props.skip || 0,
    props.limit || 10,
    results,
    setCheckedPatient,
    checkedPatient
  );
  const [refresher, setRefresher] = React.useState(0);
  const { keyPatients, setKeyPatients } = useKeyPatients(checkedPatient);
  const [patientFaceInfo, setPatientFaceInfo] = React.useState<any>();
  const { pfsOpenAndClosed } = useUnlockPatientClosingPFS(
    showDrawer,
    patientFaceInfo,
    props.panelId
  );

  const { isSticky } = React.useContext(GlobalContext);

  const { refetchPatientTable, setRefetchPatientTable } = React.useContext(
    PanelSummaryContext
  );
  React.useEffect(() => {
    if (refetchPatientTable) {
      panelUnassignedPatientQuery.refetch();
      setRefetchPatientTable(false);
    }
  }, [refetchPatientTable]);

  const { openCareGaps, setOpenCareGaps } = React.useContext(CareGapContext);
  const prevCareGapCount = React.useRef(openCareGaps.length);
  React.useEffect(() => {
    if (prevCareGapCount.current !== openCareGaps.length) {
      panelUnassignedPatientQuery.refetch();
      prevCareGapCount.current = openCareGaps.length;
    }
  }, [openCareGaps]);

  React.useEffect(() => {
    if (pfsOpenAndClosed) {
      // reset the care gap count as the patient is released
      setOpenCareGaps([]);
    }
  }, [pfsOpenAndClosed]);

  if (
    !panelUnassignedPatientQuery.called ||
    panelUnassignedPatientQuery.loading
  ) {
    return <LoadingSpinner style={isSticky ? {} : { marginTop: 335 }} />;
  }

  return (
    <div style={{ width: '100%' }}>
      {props.enableTable &&
      panelUnassignedPatientQuery.data?.getPanelUnassignedPatients.count ==
        0 ? (
        <CareGapPatientNotFound isSearching={props.isSearching} />
      ) : (
        <>
          <UnassignedPatientsTableView
            refetchDataFunc={{
              func: panelUnassignedPatientQuery.refetch,
              params: props.enableTable ? enableTableFilters : defaultFilters,
            }}
            sortBy={props.sortBy}
            setSortBy={props.setSortBy}
            panelId={props.panelId}
            title={props.title}
            patients={
              panelUnassignedPatientQuery.data?.getPanelUnassignedPatients
                .results ?? []
            }
            limit={props.limit ?? 10}
            skip={props.skip ?? 0}
            setLimit={props.setLimit}
            setSkip={props.setSkip}
            setSelected={props.setSelected}
            selected={props.selected}
            setActionsFooter={props.setActionsFooter}
            allSelected={props.allSelected}
            setAllSelected={props.setAllSelected}
            excluded={props.excluded}
            setExcluded={props.setExcluded}
            drawerHandler={drawerHandler}
            unassignedPatientCount={
              panelUnassignedPatientQuery.data?.getPanelUnassignedPatients
                .count ?? 0
            }
            setShowAssignCoordinatorDialog={
              props.setShowAssignCoordinatorDialog
            }
            setShowManageCoordinatorDialog={
              props.setShowManageCoordinatorDialog
            }
            setResults={setResults}
            setValue={setValue}
          />
          {props.actionsFooter.length > 0 && (
            <PanelPatientsListActionsFooter
              itemName={'Patients'}
              selectedItems={props.actionsFooter}
              setSelected={props.setSelected}
              allSelected={props.allSelected}
              setAllSelected={props.setAllSelected}
              excluded={props.excluded}
              setExcluded={props.setExcluded}
              tabs={props.availableActions}
              panelId={props.panelId}
              count={
                panelUnassignedPatientQuery.data?.getPanelUnassignedPatients
                  .count ?? 0
              }
              hardFresh={props.hardFresh}
              coordinatorOptions={coordinatorOptions ?? []}
              enableChangeCoordinatorDialog={
                props.enableChangeCoordinatorDialog
              }
              setEnableChangeCoordinatorDialog={
                props.setEnableChangeCoordinatorDialog
              }
              loading={loading}
              setLoading={setLoading}
              clicked={clicked}
              setClicked={setClicked}
              filters={
                props.enableTable
                  ? getDiffsAsObject(enableTableFilters, defaultFilters)
                  : null
              }
              changeCoordinator={props.assignCoordinator}
            />
          )}
          {showDrawer && (
            <CareGapsDrawer
              allPatients={results}
              drawerHandler={drawerHandler}
              checkedPatient={checkedPatient}
              tabIndex={value}
              switchTab={switchTab}
              currentPage={currentPage}
              totalCount={
                panelUnassignedPatientQuery.data?.getPanelUnassignedPatients
                  .count ?? 0
              }
              limit={props.limit ?? 10}
              skip={props.skip ?? 0}
              setSkip={props.setSkip}
              refresher={refresher}
              setRefresher={setRefresher}
              setPatientFaceInfo={setPatientFaceInfo}
              keyPatients={keyPatients}
              setKeyPatients={setKeyPatients}
            />
          )}
        </>
      )}
    </div>
  );
};
