import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetIcdcptCodesQueryVariables = Types.Exact<{
  input: Types.GetIcdcptCodesRequest;
}>;


export type GetIcdcptCodesQuery = (
  { __typename: 'Query' }
  & { getICDCPTCodes: (
    { __typename: 'GetICDCPTCodesResponse' }
    & { codes: Array<(
      { __typename: 'ICDCPTCode' }
      & Pick<Types.IcdcptCode, 'code' | 'name' | 'desc'>
    )> }
  ) }
);

export type GetPcpsByNameQueryVariables = Types.Exact<{
  input: Types.PcpNameInput;
}>;


export type GetPcpsByNameQuery = (
  { __typename: 'Query' }
  & { pcps: (
    { __typename: 'PCPLIstResponse' }
    & { result: Array<(
      { __typename: 'PCP' }
      & Pick<Types.Pcp, 'fullName' | 'firstName' | 'id' | 'lastName'>
    )> }
  ) }
);


export const GetIcdcptCodesDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getICDCPTCodes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetICDCPTCodesRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getICDCPTCodes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"codes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"desc"}}]}}]}}]}}]};

/**
 * __useGetIcdcptCodesQuery__
 *
 * To run a query within a React component, call `useGetIcdcptCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIcdcptCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIcdcptCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIcdcptCodesQuery(baseOptions: Apollo.QueryHookOptions<GetIcdcptCodesQuery, GetIcdcptCodesQueryVariables>) {
        return Apollo.useQuery<GetIcdcptCodesQuery, GetIcdcptCodesQueryVariables>(GetIcdcptCodesDocument, baseOptions);
      }
export function useGetIcdcptCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIcdcptCodesQuery, GetIcdcptCodesQueryVariables>) {
          return Apollo.useLazyQuery<GetIcdcptCodesQuery, GetIcdcptCodesQueryVariables>(GetIcdcptCodesDocument, baseOptions);
        }
export type GetIcdcptCodesQueryHookResult = ReturnType<typeof useGetIcdcptCodesQuery>;
export type GetIcdcptCodesLazyQueryHookResult = ReturnType<typeof useGetIcdcptCodesLazyQuery>;
export type GetIcdcptCodesQueryResult = Apollo.QueryResult<GetIcdcptCodesQuery, GetIcdcptCodesQueryVariables>;
export function refetchGetIcdcptCodesQuery(variables?: GetIcdcptCodesQueryVariables) {
      return { query: GetIcdcptCodesDocument, variables: variables }
    }
export const GetPcpsByNameDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPcpsByName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PCPNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pcps"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]};

/**
 * __useGetPcpsByNameQuery__
 *
 * To run a query within a React component, call `useGetPcpsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPcpsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPcpsByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPcpsByNameQuery(baseOptions: Apollo.QueryHookOptions<GetPcpsByNameQuery, GetPcpsByNameQueryVariables>) {
        return Apollo.useQuery<GetPcpsByNameQuery, GetPcpsByNameQueryVariables>(GetPcpsByNameDocument, baseOptions);
      }
export function useGetPcpsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPcpsByNameQuery, GetPcpsByNameQueryVariables>) {
          return Apollo.useLazyQuery<GetPcpsByNameQuery, GetPcpsByNameQueryVariables>(GetPcpsByNameDocument, baseOptions);
        }
export type GetPcpsByNameQueryHookResult = ReturnType<typeof useGetPcpsByNameQuery>;
export type GetPcpsByNameLazyQueryHookResult = ReturnType<typeof useGetPcpsByNameLazyQuery>;
export type GetPcpsByNameQueryResult = Apollo.QueryResult<GetPcpsByNameQuery, GetPcpsByNameQueryVariables>;
export function refetchGetPcpsByNameQuery(variables?: GetPcpsByNameQueryVariables) {
      return { query: GetPcpsByNameDocument, variables: variables }
    }