import { Maybe } from '../app.types.generated';

export function getFullGender(genderAbbr: Maybe<string> | undefined): string {
  if (genderAbbr === 'F' || genderAbbr === 'f') {
    return 'Female';
  } else if (genderAbbr === 'M' || genderAbbr === 'm') {
    return 'Male';
  } else {
    return 'Unknown';
  }
}
