import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetPatientCountByUserIdQueryVariables = Types.Exact<{
  input: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetPatientCountByUserIdQuery = (
  { __typename: 'Query' }
  & { getPatientByCareTeamUserId: (
    { __typename: 'GetPatientByCareTeamUserIdResponse' }
    & Pick<Types.GetPatientByCareTeamUserIdResponse, 'count'>
  ) }
);


export const GetPatientCountByUserIdDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientCountByUserId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientByCareTeamUserId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]};

/**
 * __useGetPatientCountByUserIdQuery__
 *
 * To run a query within a React component, call `useGetPatientCountByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCountByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCountByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientCountByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>) {
        return Apollo.useQuery<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>(GetPatientCountByUserIdDocument, baseOptions);
      }
export function useGetPatientCountByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>) {
          return Apollo.useLazyQuery<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>(GetPatientCountByUserIdDocument, baseOptions);
        }
export type GetPatientCountByUserIdQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdQuery>;
export type GetPatientCountByUserIdLazyQueryHookResult = ReturnType<typeof useGetPatientCountByUserIdLazyQuery>;
export type GetPatientCountByUserIdQueryResult = Apollo.QueryResult<GetPatientCountByUserIdQuery, GetPatientCountByUserIdQueryVariables>;
export function refetchGetPatientCountByUserIdQuery(variables?: GetPatientCountByUserIdQueryVariables) {
      return { query: GetPatientCountByUserIdDocument, variables: variables }
    }