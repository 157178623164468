import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SendSmsToPatientListByCateoryMutationVariables = Types.Exact<{
  input: Types.EligibilitySendCategorySmsRequest;
}>;


export type SendSmsToPatientListByCateoryMutation = (
  { __typename: 'Mutation' }
  & { sendSmsToPatientListByCateory: (
    { __typename: 'EligibilitySendSmsResponse' }
    & Pick<Types.EligibilitySendSmsResponse, 'batchId' | 'isSuccess' | 'message'>
  ) }
);


export const SendSmsToPatientListByCateoryDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendSmsToPatientListByCateory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EligibilitySendCategorySmsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendSmsToPatientListByCateory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"batchId"}},{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]};
export type SendSmsToPatientListByCateoryMutationFn = Apollo.MutationFunction<SendSmsToPatientListByCateoryMutation, SendSmsToPatientListByCateoryMutationVariables>;

/**
 * __useSendSmsToPatientListByCateoryMutation__
 *
 * To run a mutation, you first call `useSendSmsToPatientListByCateoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsToPatientListByCateoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsToPatientListByCateoryMutation, { data, loading, error }] = useSendSmsToPatientListByCateoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSmsToPatientListByCateoryMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsToPatientListByCateoryMutation, SendSmsToPatientListByCateoryMutationVariables>) {
        return Apollo.useMutation<SendSmsToPatientListByCateoryMutation, SendSmsToPatientListByCateoryMutationVariables>(SendSmsToPatientListByCateoryDocument, baseOptions);
      }
export type SendSmsToPatientListByCateoryMutationHookResult = ReturnType<typeof useSendSmsToPatientListByCateoryMutation>;
export type SendSmsToPatientListByCateoryMutationResult = Apollo.MutationResult<SendSmsToPatientListByCateoryMutation>;
export type SendSmsToPatientListByCateoryMutationOptions = Apollo.BaseMutationOptions<SendSmsToPatientListByCateoryMutation, SendSmsToPatientListByCateoryMutationVariables>;