import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { ErrorScreenIcon } from '../../assets/Icons/ErrorScreens';
import { useErrorViewStyles } from './Styles';

export type PropTypes = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorView: React.FC<PropTypes> = ({ resetErrorBoundary }) => {
  const classes = useErrorViewStyles();

  return (
    <div className={classes.root}>
      <section className={classes.mainContent}>
        <ErrorScreenIcon height={311} width={400} fill={'none'} />
        <Typography variant="h4" className={classes.errorTitle}>
          Something's wrong here...
        </Typography>
        <div>
          <Typography variant="body1" className={classes.errorContent}>
            We can't find the page you're looking for.
          </Typography>
          <Typography variant="body1" className={classes.errorContent}>
            You can go back to dashboard or Try it again.
          </Typography>
        </div>
        <Button
          onClick={() => window.location.replace('/dashboard')}
          color="primary"
          className={classes.backToDashboardBtn}
        >
          Back to Dashboard
        </Button>
      </section>
    </div>
  );
};
