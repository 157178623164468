import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { CheckIcon } from '../../assets/Icons/CreatePanel/CheckIcon';
import { theme } from '../../themes/theme';


interface CustomSingleSelectProps {
    state: any;
    type: string;
    setState: any;
    isMultiple: boolean;
    inputTitle?: string;
    inputHelper?: string;
    placeholder: string;
    items: any[];
    onChange?: (value: boolean, selectedValue: any[]) => void;
    width?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    selectedPairs: string;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': {
            borderRadius: 8,
        },
    },
    selectMenuContent: {
        display: 'block',
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
    },
    placeholderText: {
        color: theme.palette.grey[400],
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        paddingTop: theme.spacing(0.875),
        paddingBottom: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    placeholderTextSelected: {
        color: theme.palette.grey[600],
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        paddingTop: theme.spacing(0.875),
        paddingBottom: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    multipleSelect: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'top',

        width: '100%',

        marginTop: 12,
    },
    selected: {
        backgroundColor: '#EDF6FF!important',
        color: '#0C77D8!important',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        marginBottom: 1,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    formControl: {
        width: '100%',

        '& .MuiInputBase-root': {
            height: 40,
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
            '& .Mui-disabled':{
                backgroundColor: '#EFEFEF',
                //height: 40,
                borderRadius: 6,
                boxShadow: 'none',
                paddingTop: 0,
                paddingBottom: 0
            }
        },
    },
    inputTitle: {
        height: 24,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.7,
        textTransform: 'capitalize',
        marginBottom: 4,
    },
    select: {
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    mainContent: {
        display: 'block',
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
    },
    subContent: {
        display: 'block',
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.text.hint,
    },
    flexContainer: {
        display: 'flex',
    },
}));

export const CustomSingleSelect = (props: CustomSingleSelectProps) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<any>(props.state);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [previousSelect, setPreviousSelect] = React.useState<any>(props.selectedPairs);
    React.useEffect(() => {
        setValue(props.state)
    }, [props.state])
    React.useEffect(() => {
        if (props.selectedPairs !== previousSelect) {
            setPreviousSelect(props.selectedPairs);
        }
        console.log(props.selectedPairs)
    }, [props.selectedPairs]);

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        // update selected items according to form type: Insurance, ProviderType and CareTeam
        let newValue = event.target.value;
        setValue(newValue);
        props.setState(props.type, newValue);
    };
    function findObject(string2: any, newValue: string) {
        let arr = string2.split(',');
        if(newValue==value){
            return false
        }
        if (Array.isArray(arr)) {
            return arr.find((clinicId) => clinicId == newValue
            );
        }
        return true
    }

    return (
        <div
            className={classes.multipleSelect}
            style={{ width: props.fullWidth ? '100%' : 205 }}
        >
            <FormControl className={classes.formControl}>
                {props.inputTitle && (
                    <Typography
                        id={`${props.inputTitle}`}
                        variant="h5"
                        className={classes.inputTitle}
                    >
                        {props.inputTitle}
                    </Typography>
                )}

                <Select
                    multiple={false}
                    variant="outlined"
                    disabled={props.disabled}
                    displayEmpty
                    value={value}
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDown}
                    onOpen={() => {
                        setIsOpen(true);
                    }}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    renderValue={(selected: any) => {
                        if (isOpen) {
                            return <div className={classes.placeholderText}>Select</div>;
                        }

                        const selectedString = props.items.find((item: any) => item.value === value) ||
                            props.placeholder;

                        return (
                            <div
                                className={classes.placeholderTextSelected}
                                style={
                                    props.placeholder === selectedString
                                        ? {
                                            color: theme.palette.grey[400],
                                        }
                                        : undefined
                                }
                            >
                                {typeof selectedString !== 'string'
                                    ? selectedString.name
                                    : selectedString}
                            </div>
                        );
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        elevation: 2,
                        getContentAnchorEl: null,
                        style: props.fullWidth
                            ? {
                                maxHeight: 68 * 5 + 8,
                                maxWidth: '100%',
                            }
                            : {
                                maxHeight: 68 * 5 + 8,
                                width: props.width || 250,
                                maxWidth: '100%',
                            },
                        PaperProps: {
                            style: props.fullWidth
                                ? {
                                    // reduce space between input and menu
                                    marginTop: -9,
                                    paddingLeft: 8,
                                    paddingRight: 7,
                                }
                                : {
                                    // reduce space between input and menu
                                    minWidth: 192,
                                    marginTop: -9,
                                    paddingLeft: 8,
                                    paddingRight: 7,
                                },
                        },
                    }}
                    className={classes.select}
                >
                    {props.items.filter((clinic: any)=>  !findObject(previousSelect, clinic.value)).map((item: any, idx: any) => (
                        <MenuItem
                            key={idx}
                            value={item.value}
                            classes={{
                                root: classes.listItem,
                                selected: classes.selected,
                            }}
                        >

                                <div className={classes.flexContainer}>
                                    <Typography
                                        variant="body1"
                                        className={classes.selectMenuContent}
                                    >
                                        {item.name}
                                    </Typography>
                                    {(value == item || value == item.value) && (
                                        <CheckIcon
                                            style={{
                                                height: 25,
                                                position: 'absolute',
                                                right: '15px',
                                            }}
                                        />
                                    )}
                                </div>

                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
