import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
    className?: string;
    style?: object;
}

export const CallAttemptCountIcon = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.67467 13.3487C5.68467 12.9287 5.70933 12.4 5.768 12.066C5.832 11.7047 5.956 11.27 6.05133 10.964C6.126 10.724 6.06 10.4627 5.88267 10.2847L4.41333 8.80667C4.95133 7.78533 5.578 6.96733 6.27467 6.27467C6.96733 5.578 7.78533 4.95133 8.80667 4.41333L10.2853 5.88267C10.464 6.06 10.7247 6.126 10.9647 6.05133C11.27 5.95667 11.7053 5.832 12.0667 5.768C12.4007 5.70933 12.9287 5.68467 13.3493 5.67467C13.712 5.666 14 5.37 14 5.00667V2.66667C14 2.37467 13.7813 2 13.3333 2C10.3073 2 7.41733 3.22667 5.31067 5.31067C3.22667 7.41733 2 10.3073 2 13.3333C2 13.7813 2.37467 14 2.66667 14H5.00667C5.37 14 5.666 13.712 5.67467 13.3487Z"
                  fill="#0C77D8"/>
        </svg>
    );
};
