import { Button, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { useEmptyTabContentStyles } from './Styles';

interface EmptyTabContentViewProps {
  hintIcon: ReactElement;
  hintTitle: string;
  hintDesc: string | any;
  btnUrl: string;
  btnText: string;
}

export const EmptyTabContentView = (props: EmptyTabContentViewProps) => {
  const { hintIcon, hintTitle, hintDesc, btnUrl, btnText } = props;
  const classes = useEmptyTabContentStyles();
  const navigate = useNavigate();

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    navigate(btnUrl);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.mainContainer}
      >
        <Grid item>
          <div
            role="CCPanelEngagementTableViewHint"
            className={classes.hintContainer}
          >
            {hintIcon}
            <Typography variant="h4" role="hintTitle">
              {hintTitle}
            </Typography>
            <Typography variant="body1" role="hintDesc">
              {hintDesc}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Button
            id="view-my-patients-button"
            variant="contained"
            disableElevation
            onClick={clickHandler}
            className={classes.smallPrimarybtn}
          >
            <span>{btnText}</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
