import { QueryResult, ServerError } from '@apollo/client';
import React from 'react';
import { GlobalContext } from '../components/GlobalContext';

export const useFetchTableData = (query: QueryResult) => {
  const [isError, setIsError] = React.useState(false);
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [queryData, setQueryData] = React.useState<any>(null);

  React.useEffect(() => {
    if (query.error) {
      // NPH-843
      if (query.error.networkError) {
        const errors = (query.error.networkError as ServerError).result?.errors;
        if (
          errors.find(
            (e: any) =>
              e?.message ==
              'Context creation failed: Please be adviced that current user is disabled.'
          )
        ) {
          return;
        } else {
          setIsError(true);
          setToastMessage({
            isOpen: true,
            severity: 'error',
            snackbarMsg: 'Something went wrong, please try again',
          });
          setIsOpenToast(true);
        }
      }
    } else if (query.data) {
      setQueryData({
        ...query.data,
      });
    }

    if (isError) {
      if (query.previousData) {
        setQueryData({
          ...query.previousData,
        });
      } else {
        setQueryData(null);
      }
    }
  }, [query, isError]);

  return queryData;
};
