import React, {createRef, useEffect} from 'react';
import {InputLabel, makeStyles, TextField, Typography} from '@material-ui/core';
import Mark from 'mark.js';
import { SmsTagConstant } from '../../scenes/Panels/ViewPanels/components/EngagementDialog/SmsDefaultMessageConstant';
import { SuggestionPopUpSelect } from '../SuggestionPopUpSelect';
import { useDisabledTextFieldStyles } from '../../styles/outlineInputStyle';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    position: 'absolute',
    right: 17.5,
    top: 10,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#B7373E',
  },
  counterMessage: {
    position: 'absolute',
    right: 17.5,
    top: 10,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
  dummyMessage: {
    position: 'absolute',
    top: 36,
    color: '#656565',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    left: 0,
    right: 0,
    paddingLeft: 12,
    paddingRight: 12,
    wordWrap: 'break-word',
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
    borderBottom: '1px solid #D1D1D1',
    paddingBottom: 16,
  },
  dummyMessageLast: {
    position: 'absolute',
    top: 36,
    color: '#656565',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    left: 0,
    right: 0,
    paddingLeft: 12,
    paddingRight: 12,
    wordWrap: 'break-word',
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
  },
  messageContainer: {
    position: 'relative',
    width: '100%',
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
    paddingBottom: 16,
  },
  errLange: {
    color: '#B7373E',
    fontSize: 20,
    lineHeight: '24px',
  },
  defaultLange: {
    color: '#434343',
    fontSize: 20,
    lineHeight: '24px',
  }
}));

export const SmsMaxLength = 640

interface CustomSmsTextFieldProps {
  title: any;
  defaultText: any;
  rawText: any;
  setRawText: any;
  id: any;
  isLast: boolean;
  placeHolder: any;
  selectedSmsType: any;
  customPlaceHolder: any;
  onPreview: boolean;
  selectedFilterName?: string;
  onFocusStatusChange?: (isFocus: boolean) => void,
  languages?: string[]
}


export const CustomSmsTextField = (props: CustomSmsTextFieldProps) => {
  const classes = useStyles();
  const textFieldRef: React.RefObject<HTMLInputElement> = createRef();
  const outlineTextFieldClasses = useDisabledTextFieldStyles();
  const [customMessage, setCustomMessage] = React.useState(props.rawText);
  const [hasSuggestion, setHasSuggestions] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function isError(): boolean {
    return !props.onPreview && (getSmsMessageLength(customMessage) > SmsMaxLength || customMessage.length == 0);
  }

  React.useEffect(() => {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      dummyMessage.onpaste=function(e){
        if (e?.clipboardData?.files.length) {
          e.preventDefault();
        }
      }
      setAnchorEl(dummyMessage);
      if (props.selectedSmsType == 'Custom Message') {
        dummyMessage!.innerHTML = props.rawText;
      }
    }
  }, [props.selectedSmsType]);

  React.useEffect(() => {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      dummyMessage.onpaste=function(e){
        if (e?.clipboardData?.files.length) {
          if (e?.clipboardData?.files.length) {
            e.preventDefault();
          }
        }
      }
      setAnchorEl(dummyMessage);
      if (props.selectedSmsType == 'Template') {
        dummyMessage!.innerHTML = props.rawText;
        setCustomMessage(props.rawText)
      } else {
        dummyMessage!.innerHTML = props.rawText;
        setCustomMessage(props.rawText)
      }
    }
  }, [props.selectedFilterName, props.languages]);

  React.useEffect(() => {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      dummyMessage.onpaste=function(e){
        if (e?.clipboardData?.files.length) {
          e.preventDefault();
        }
      }
    }
    if (dummyMessage && props.selectedSmsType == 'Custom Message') {
      dummyMessage!.innerHTML = props.rawText;
    }
  }, [props.onPreview, props.selectedSmsType]);

  function callBackOnSuggestion(selectedConstant: string) {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      let text = dummyMessage.innerText;
      const length = text.length;
      for (let i = 0; i < length; i++) {
        if (text[i] === '#') {
          let flag = true;
          for (const item of SmsTagConstant) {
            if (i + item.value.length < length) {
              if (text.slice(i, i + item.value.length) == item.value) {
                flag = false;
              }
            }
          }
          if (flag) {
            if (selectedConstant == '') {
              text =
                text.slice(0, i) + selectedConstant + text.slice(i + 1, length);
              handleOnSuggestionChange(text);
              return;
            } else {
              text =
                i == 0 || (i != 0 && text[i] != ' ')
                  ? text.slice(0, i) +
                    '&nbsp' +
                    selectedConstant +
                    '&nbsp' +
                    text.slice(i + 1, length)
                  : text.slice(0, i) +
                    selectedConstant +
                    '&nbsp' +
                    text.slice(i + 1, length);
              handleOnSuggestionChange(text);
              return;
            }
          }
        }
      }
    }
  }

  function handleOnInputChange(e: any) {
    if (e?.nativeEvent?.data?.match(/#$/)) {
      setHasSuggestions(true);
    }
    if (!hasSuggestion) {
      setCustomMessage(e.currentTarget.textContent ?? '');
      props.setRawText(e.currentTarget.textContent ?? '');
    }
  }

  function handleOnSuggestionChange(text: string) {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      setCustomMessage(text);

      dummyMessage!.innerHTML = text;
      setCustomMessage(dummyMessage.textContent ?? '');
      props.setRawText(dummyMessage.textContent ?? '');
    }
  }

  React.useEffect(() => {
    const dummyMessage = document.getElementById(props.id);
    if (dummyMessage) {
      const markInstance = new Mark(dummyMessage!);
      markInstance.unmark();
      for (const keyWord of SmsTagConstant.map((item) => item.value)) {
        markInstance.mark(keyWord, {
          each: function (node) {
            node.setAttribute('contentEditable', 'false');
          },
        });
      }
    }
  });

  const count = getSmsMessageLength(customMessage)
  const smsCount = Math.ceil(count / 160.0)
  const maxMsgLength = smsCount * 160
  const smsDescription = `${count}/${Math.min(maxMsgLength, SmsMaxLength)}, ${smsCount} SMS`

  return (
    <div className={classes.messageContainer} key={props.id}>
      <TextField
        id={'TextField' + props.id}
        fullWidth
        key={props.id}
        InputProps={{
          value: customMessage,
          disableUnderline: true
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={<div className={isError() ? classes.errLange : classes.defaultLange}>{props.title}</div>}
        variant="filled"
        multiline
        error={isError()}
        className={classes.descriptionInput}
        classes={outlineTextFieldClasses}
        disabled={true}
      />
      <SuggestionPopUpSelect
        field={textFieldRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        callBackOnSuggestion={callBackOnSuggestion}
        hasSuggestions={hasSuggestion}
        setHasSuggestions={setHasSuggestions}
      />
      <div className={count > SmsMaxLength ? classes.errorMessage : classes.counterMessage}>{smsDescription}</div>
      {props.selectedSmsType == 'Custom Message' ||
      props.selectedSmsType == 'Template' ? (
        <div
          id={props.id}
          ref={textFieldRef}
          className={
            props.isLast ? classes.dummyMessageLast : classes.dummyMessage
          }
          contentEditable={
            (!props.onPreview) && !hasSuggestion
          }
          // onKeyUp={(e) => handleOnKeyUp(e)}
          onInput={(e) => handleOnInputChange(e)}
          placeholder={props.customPlaceHolder}
          onFocus={() => props.onFocusStatusChange && props.onFocusStatusChange(true)}
          onBlur={() => props.onFocusStatusChange && props.onFocusStatusChange(false)}
        />
      ) : props.selectedSmsType == '' ? (
        <div
          className={
            props.isLast ? classes.dummyMessageLast : classes.dummyMessage
          }
        >
          {props.placeHolder}
        </div>
      ) : (
        <div
          className={
            props.isLast ? classes.dummyMessageLast : classes.dummyMessage
          }
          dangerouslySetInnerHTML={{ __html: props.defaultText }}
        />
      )}
    </div>
  );
};

const str2UTF8 = (str: string) => {
  let bytes = [];
  let len, c;
  len = str.length;
  for (let i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if (c >= 0x010000 && c <= 0x10FFFF) {
      bytes.push(((c >> 18) & 0x07) | 0xF0);
      bytes.push(((c >> 12) & 0x3F) | 0x80);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000800 && c <= 0x00FFFF) {
      bytes.push(((c >> 12) & 0x0F) | 0xE0);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000080 && c <= 0x0007FF) {
      bytes.push(((c >> 6) & 0x1F) | 0xC0);
      bytes.push((c & 0x3F) | 0x80);
    } else {
      bytes.push(c & 0xFF);
    }
  }
  return bytes;
}

export const getSmsMessageLength = (message: string): number => {
  if (!message) {
    return 0
  }
  const asciiLength = message.length
  const utf8Length = str2UTF8(message).length
  return Math.floor(asciiLength + (utf8Length - asciiLength) / 2)
}
