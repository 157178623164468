import React from 'react';
import { GlobalContext } from '../../../../../../components/GlobalContext';
import { User } from '../../../../../../enum/Users';
import { RoleType } from '../../../../../../enum/Users';
import {
  CheckIsAdmin,
  CheckIsCareManager,
} from '../../../../../../components/CheckIsAdmin';

export const useToggleAccountStatus = (checkedUsers: User[]) => {
  const [isMyself, setIsMyself] = React.useState(false);
  const [privileged, setPrivileged] = React.useState(false);

  const { loggedInUser } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (loggedInUser) {
      // check if selected user is logged-in user
      if (
        checkedUsers.length === 1 &&
        checkedUsers[0].userId === loggedInUser.id
      ) {
        setIsMyself(true);
      }

      // check if current user has permission to do action on all selected users
      if (CheckIsAdmin(loggedInUser)) {
        // For MSO Admin role, everyone has permissions
        setPrivileged(true);
      } else {
        if (CheckIsCareManager(loggedInUser)) {
          // For Care Manager role, no one can operate on MSO Admin or Care Manager
          if (
            checkedUsers.find(
              (user: User) =>
                user.roleName === RoleType.MSO_ADMIN ||
                user.roleName === RoleType.CARE_MANAGER ||
                user.roleName === RoleType.ADMIN
            ) !== undefined
          ) {
            setPrivileged(false);
          } else {
            setPrivileged(true);
          }
        } else {
          // For Coordinator role, no permissions
          setPrivileged(false);
        }
      }
    }
  }, [loggedInUser, checkedUsers]);

  return {
    isMyself,
    privileged,
  };
};
