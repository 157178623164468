import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetTotalPatientCountQueryVariables = Types.Exact<{
  input: Types.GetPatientByCareTeamUserIdsRequest;
}>;


export type GetTotalPatientCountQuery = (
  { __typename: 'Query' }
  & { getPatientByCareTeamUserId: (
    { __typename: 'GetPatientByCareTeamUserIdResponse' }
    & Pick<Types.GetPatientByCareTeamUserIdResponse, 'count'>
    & { results: Array<(
      { __typename: 'PanelPatient' }
      & Pick<Types.PanelPatient, 'coveringPCPFullName'>
    )> }
  ) }
);


export const GetTotalPatientCountDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTotalPatientCount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPatientByCareTeamUserIdsRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientByCareTeamUserId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coveringPCPFullName"}}]}}]}}]}}]};

/**
 * __useGetTotalPatientCountQuery__
 *
 * To run a query within a React component, call `useGetTotalPatientCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalPatientCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalPatientCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTotalPatientCountQuery(baseOptions: Apollo.QueryHookOptions<GetTotalPatientCountQuery, GetTotalPatientCountQueryVariables>) {
        return Apollo.useQuery<GetTotalPatientCountQuery, GetTotalPatientCountQueryVariables>(GetTotalPatientCountDocument, baseOptions);
      }
export function useGetTotalPatientCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalPatientCountQuery, GetTotalPatientCountQueryVariables>) {
          return Apollo.useLazyQuery<GetTotalPatientCountQuery, GetTotalPatientCountQueryVariables>(GetTotalPatientCountDocument, baseOptions);
        }
export type GetTotalPatientCountQueryHookResult = ReturnType<typeof useGetTotalPatientCountQuery>;
export type GetTotalPatientCountLazyQueryHookResult = ReturnType<typeof useGetTotalPatientCountLazyQuery>;
export type GetTotalPatientCountQueryResult = Apollo.QueryResult<GetTotalPatientCountQuery, GetTotalPatientCountQueryVariables>;
export function refetchGetTotalPatientCountQuery(variables?: GetTotalPatientCountQueryVariables) {
      return { query: GetTotalPatientCountDocument, variables: variables }
    }