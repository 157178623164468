import React from 'react';

interface PatientPanelContextType {
  isViewAllClicked: boolean;
  setIsViewAllClicked: any;
  tabLabel: string;
  setTabLabel: React.Dispatch<React.SetStateAction<string>>;
}

const PatientPanelContext = React.createContext<PatientPanelContextType>(
  {} as PatientPanelContextType
);

const PatientPanelProvider: React.FC = ({ children }) => {
  const [isViewAllClicked, setIsViewAllClicked] = React.useState(false);
  const [tabLabel, setTabLabel] = React.useState('');

  return (
    <PatientPanelContext.Provider
      value={{
        isViewAllClicked,
        setIsViewAllClicked,
        tabLabel,
        setTabLabel,
      }}
    >
      {children}
    </PatientPanelContext.Provider>
  );
};

const usePatientPanelContext = () => {
  return React.useContext(PatientPanelContext);
};

export { PatientPanelContext, PatientPanelProvider, usePatientPanelContext };
