import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetProviderPerformanceListsQueryVariables = Types.Exact<{
  input: Types.PcpNameInput;
}>;


export type GetProviderPerformanceListsQuery = (
  { __typename: 'Query' }
  & { pcps: (
    { __typename: 'PCPLIstResponse' }
    & Pick<Types.PcplIstResponse, 'limit' | 'skip' | 'count'>
    & { result: Array<(
      { __typename: 'PCP' }
      & Pick<Types.Pcp, 'id' | 'fullName' | 'title' | 'email' | 'workPhone' | 'coveringCount' | 'coveringPerformance'>
    )> }
  ) }
);


export const GetProviderPerformanceListsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getProviderPerformanceLists"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PCPNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pcps"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"skip"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"workPhone"}},{"kind":"Field","name":{"kind":"Name","value":"coveringCount"}},{"kind":"Field","name":{"kind":"Name","value":"coveringPerformance"}}]}}]}}]}}]};

/**
 * __useGetProviderPerformanceListsQuery__
 *
 * To run a query within a React component, call `useGetProviderPerformanceListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderPerformanceListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderPerformanceListsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProviderPerformanceListsQuery(baseOptions: Apollo.QueryHookOptions<GetProviderPerformanceListsQuery, GetProviderPerformanceListsQueryVariables>) {
        return Apollo.useQuery<GetProviderPerformanceListsQuery, GetProviderPerformanceListsQueryVariables>(GetProviderPerformanceListsDocument, baseOptions);
      }
export function useGetProviderPerformanceListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderPerformanceListsQuery, GetProviderPerformanceListsQueryVariables>) {
          return Apollo.useLazyQuery<GetProviderPerformanceListsQuery, GetProviderPerformanceListsQueryVariables>(GetProviderPerformanceListsDocument, baseOptions);
        }
export type GetProviderPerformanceListsQueryHookResult = ReturnType<typeof useGetProviderPerformanceListsQuery>;
export type GetProviderPerformanceListsLazyQueryHookResult = ReturnType<typeof useGetProviderPerformanceListsLazyQuery>;
export type GetProviderPerformanceListsQueryResult = Apollo.QueryResult<GetProviderPerformanceListsQuery, GetProviderPerformanceListsQueryVariables>;
export function refetchGetProviderPerformanceListsQuery(variables?: GetProviderPerformanceListsQueryVariables) {
      return { query: GetProviderPerformanceListsDocument, variables: variables }
    }