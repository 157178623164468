import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { CheckIcon } from '../../assets/Icons/CreatePanel/CheckIcon';
import { theme } from '../../themes/theme';
import { LabelAvatarSelectItem } from '../AvatarSelectItem';

const AVATAR_BG = Object.values(theme.palette.avatars);

interface CustomSelectProps {
  state: any;
  type: string;
  setState: any;
  isMultiple: boolean;
  inputTitle?: string;
  inputHelper?: string;
  placeholder: string;
  items: any[];
  onChange?: (value: boolean, selectedValue: any[]) => void;
  width?: number;
  hasAvatar?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      borderRadius: 8,
    },
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',

    width: '100%',

    marginTop: 12,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formControl: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 6,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    textTransform: 'capitalize',
    marginBottom: 4,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  avatarContainer: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  avatarStyles: {
    backgroundColor: `${AVATAR_BG[AVATAR_BG.length - 1]} !important`,
    marginRight: `12px !important`,
    width: `40px !important`,
    height: `40px !important`,
  },
  avatarContent: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mainContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  subContent: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.hint,
  },
  flexContainer: {
    display: 'flex',
  },
}));

export const CustomSelect = (props: CustomSelectProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    // update selected items according to form type: Insurance, ProviderType and CareTeam
    let newValue = event.target.value;
    props.setState(props.type, newValue);
  };

  return (
    <div
      className={classes.multipleSelect}
      style={{ width: props.fullWidth ? '100%' : 205 }}
    >
      <FormControl className={classes.formControl}>
        {props.inputTitle && (
          <Typography
            id={`${props.inputTitle}`}
            variant="h5"
            className={classes.inputTitle}
          >
            {props.inputTitle}
          </Typography>
        )}

        <Select
          multiple={false}
          variant="outlined"
          disabled={props.disabled}
          displayEmpty
          value={props.state}
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          renderValue={(selected: any) => {
            if (isOpen) {
              return <div className={classes.placeholderText}>Select</div>;
            } else if (selected == '') {
              return <div className={classes.placeholderTextSelected}>All</div>;
            }

            const selectedString = props.hasAvatar
              ? props.items.find((item: any) => item.id == props.state) || ''
              : props.items.find((item: any) => item.value === props.state) ||
                props.placeholder;

            return (
              <div
                className={classes.placeholderTextSelected}
                style={
                  props.placeholder === selectedString
                    ? {
                        color: theme.palette.grey[400],
                      }
                    : undefined
                }
              >
                {typeof selectedString !== 'string'
                  ? props.hasAvatar
                    ? selectedString?.firstName + ' ' + selectedString?.lastName
                    : selectedString.name
                  : selectedString}
              </div>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            elevation: 2,
            getContentAnchorEl: null,
            style: props.fullWidth
              ? {
                  maxHeight: 68 * 5 + 8,
                  maxWidth: '100%',
                }
              : {
                  maxHeight: 68 * 5 + 8,
                  width: props.width || 250,
                  maxWidth: '100%',
                },
            PaperProps: {
              style: props.fullWidth
                ? {
                    // reduce space between input and menu
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 7,
                  }
                : {
                    // reduce space between input and menu
                    minWidth: 192,
                    marginTop: -9,
                    paddingLeft: 8,
                    paddingRight: 7,
                  },
            },
          }}
          className={classes.select}
        >
          <MenuItem
            value=""
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
          >
            <div className={classes.flexContainer}>
              <Typography variant="body1" className={classes.selectMenuContent}>
                All
              </Typography>
              {props.state === '' && (
                <CheckIcon
                  style={{
                    height: 25,
                    position: 'absolute',
                    right: '15px',
                  }}
                />
              )}
            </div>
          </MenuItem>
          {props.items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={props.hasAvatar ? item.id : item.value}
              classes={{
                root: classes.listItem,
                selected: classes.selected,
              }}
            >
              {props.hasAvatar ? (
                <LabelAvatarSelectItem
                  item={item}
                  firstName={item.firstName}
                  lastName={item.lastName}
                  title={item.title}
                  selected={props.state === item.id}
                  hasAvatar={props.hasAvatar}
                />
              ) : (
                <div className={classes.flexContainer}>
                  <Typography
                    variant="body1"
                    className={classes.selectMenuContent}
                  >
                    {item.name}
                  </Typography>
                  {(props.state === item.value || props.state === item) && (
                    <CheckIcon
                      style={{
                        height: 25,
                        position: 'absolute',
                        right: '15px',
                      }}
                    />
                  )}
                </div>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
