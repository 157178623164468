import React from 'react';
import { PanelPatient } from '../../../../../app.types.generated';

export type KeyPatientsType = {
  ori: PanelPatient; // used for family members to trace back
  pre: undefined | PanelPatient;
  cur: PanelPatient;
};

export const useKeyPatients = (checkedPatient: PanelPatient) => {
  const [keyPatients, setKeyPatients] = React.useState<KeyPatientsType>(
    {} as KeyPatientsType
  );

  React.useEffect(() => {
    setKeyPatients({
      ori: checkedPatient,
      pre: undefined,
      cur: checkedPatient,
    });
  }, [checkedPatient]);

  return { keyPatients, setKeyPatients };
};
