import React from 'react';
import { GlobalContext } from '../components/GlobalContext';

export const useAccountId = () => {
  const { loggedInUser } = React.useContext(GlobalContext);
  const [accountId, setAccountId] = React.useState('9000');
  React.useEffect(() => {
    if (loggedInUser) {
      setAccountId(loggedInUser.accountId);
    }
  }, [loggedInUser]);

  return accountId;
};
