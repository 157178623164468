import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { StaticPatientCount } from '../../../CreatePanel/components/Step2/components/StaticPatientCount';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDisabledOutlinedInputStyles } from '../../../../../styles/outlineInputStyle';
import { theme } from '../../../../../themes/theme';
import { SmsLanguage, SmsType } from './SmsOptions';
import { CheckIcon } from '../../../../../assets/Icons/CreatePanel/CheckIcon';
import { KeyboardArrowDown } from '@material-ui/icons';
import { CustomSelectWithChips } from '../../../../../components/CustomSelectWithChips';
import {
  ChineseSimplifiedDefaultMessage,
  ChineseSimplifiedDefaultPlaceholder,
  ChineseTraditionalDefaultMessage,
  ChineseTraditionalDefaultPlaceholder,
  EnglishDefaultMessage,
  EnglishDefaultPlaceholder,
} from './SmsDefaultMessageConstant';
import {CustomSmsTextField, getSmsMessageLength, SmsMaxLength} from '../../../../../components/CustomSmsTextField';
import { useGetTemplateDetailListQuery } from '../../../../Settings/SMSTemplates/index.generated';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { sortArrayBaseOnObjectArrayPipe } from '../../../../../sharePipe/sortArrayBaseOnObjectArrayPipe';
import {
  SendPanelSmsForm,
  SendPanelSmsLanguage,
} from '../PanelPatientsListActionsFooter/PanelPatientActionsFooterFunctions';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: 549,
      maxWidth: '80%',
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D1D1D1',
    },
    '& .MuiTypography-root.MuiTypography-h6': {
      color: '#434343',
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiPaper-elevation24 ': {
      boxShadow:
        '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
    },
  },

  editBtn: {
    width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  createNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 125,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  previewNowBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 166,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  conFirmAndSendBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 171,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
  },
  divider: {
    height: 1,
    background: '#d8d8d8',
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
    paddingTop: 25,
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
  },
  errorLabel: {
    fontSize: 12,
    color: '#B7373E',
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
  },
  descriptionInput: {
    fontSize: 16,
    width: '100%',
    maxHeight: 120,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      maxHeight: '100%',
    },
    '& .MuiInputBase-root &.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    // color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  selectSubMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 20,
  },
  placeholderTextSelected: {
    // color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  initialTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#EFEFEF',
    borderRadius: '8px',
  },
  defaultTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#FFF',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#D1D1D1'
  },
  editTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#FFF',
    borderRadius: '8px',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#0C77D8'
  },
  errorTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#FFF',
    borderRadius: '8px',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#B7373E',
  },
  onPreviewTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 4px',
    /* Surface/Default */
    background: '#F8F8F8',
    borderRadius: '8px',
  },
  dummyMessage: {
    position: 'absolute',
    top: 30,
    color: '#656565',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    width: 450,
    left: 12,
    '& mark': {
      color: '#0761B3',
      backgroundColor: 'transparent',
    },
  },
  messageContainer: {
    position: 'relative',
    width: '100%',
  },
  subListHeader: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
    color: '#888888',
    height: 27,
    paddingTop: 7,
    paddingBottom: 4,
    position: 'initial',
  },
}));

enum EditorStatus {
  initial,
  default,
  edit,
  error
}

interface DialogProps {
  onClose: any;
  onSubmit: (form: SendPanelSmsForm) => void;
  patientCount: number;
}

export const SendSmsDialog = (props: DialogProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useDisabledOutlinedInputStyles();
  const [selectedLanguages, setSelectLanguages] = React.useState(['English']);
  const [customEngMessage, setCustomEngMessage] = React.useState('');
  const [customChnSmpMessage, setCustomChnSmpMessage] = React.useState('');
  const [customChnTradMessage, setCustomChnTradMessage] = React.useState('');
  const [selectedSmsType, setSelectedSmsType] = React.useState('');
  const [onPreview, setOnPreview] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [selectedSmsTemplate, setSelectedSmsTemplate] =
    React.useState<any>(null);
  const [editorStatus, setEditorStatus] = useState<EditorStatus>(EditorStatus.initial)

  const [sortBy, setSortBy] = React.useState({
    field: 'name',
    method: 'asc' as const,
  });


  const getTemplatesQuery = useGetTemplateDetailListQuery({
    variables: {
      input: {
        status: 'active',
        limit: 1000,
        skip: 0,
        sortBy: [{ ...sortBy }],
        type: 'SMS',
      },
    },
  });

  React.useEffect(() => {
    setData(getTemplatesQuery?.data?.getTemplates?.result ?? []);
  }, [getTemplatesQuery]);

  const onSelectSmsType = (event: React.ChangeEvent<{ value: any }>) => {
    if (event.target.value?.id !== undefined) {
      setSelectedSmsType('Template');
      setSelectedSmsTemplate(event.target.value);
      const temp: any = [];
      event.target.value?.languages?.forEach((lan: any) => {
        if (lan?.language == 'en') {
          temp.push('English');
          setCustomEngMessage(decodeURIComponent(atob(lan?.content)));
        }
        if (lan?.language == 'cns') {
          temp.push('Chinese (S)');
          setCustomChnSmpMessage(decodeURIComponent(atob(lan?.content)));
        }
        if (lan?.language == 'cnt') {
          temp.push('Chinese (T)');
          setCustomChnTradMessage(decodeURIComponent(atob(lan?.content)));
        }
      });
      setSelectLanguages(temp);
    } else {
      setSelectedSmsType(event.target.value);
      setSelectedSmsTemplate(null);
      setSelectLanguages(['English']);
      setCustomEngMessage('');
      setCustomChnSmpMessage('');
      setCustomChnTradMessage('');
    }
    editorStatus === EditorStatus.initial && setEditorStatus(EditorStatus.default)
  };

  function getLangZeroError(): boolean {
    for (const lang of selectedLanguages) {
      switch (lang) {
        case 'English':
          if (customEngMessage.length == 0) {
            return true;
          }
          continue;
        case 'Chinese (S)':
          if (customChnSmpMessage.length == 0) {
            return true;
          }
          continue;
        case 'Chinese (T)':
          if (customChnTradMessage.length == 0) {
            return true;
          }
      }
    }
    return false;
  }

  function getOverFlowError(): boolean {
    for (const lang of selectedLanguages) {
      switch (lang) {
        case 'English':
          if (getSmsMessageLength(customEngMessage) > SmsMaxLength ) {
            return true
          }
          continue;
        case 'Chinese (S)':
          if (getSmsMessageLength(customChnSmpMessage) > SmsMaxLength ) {
            return true
          }
          continue;
        case 'Chinese (T)':
          if (getSmsMessageLength(customChnTradMessage) > SmsMaxLength ) {
            return true
          }
      }
    }
    return false;
  }

  const [disabledButton, setDisabledButton] = React.useState(false);

  const onSubmit = async () => {
    const form: SendPanelSmsForm = {
      type: selectedSmsType,
      templateId: selectedSmsTemplate && selectedSmsTemplate.id ? selectedSmsTemplate.id : "",
      templateName: selectedSmsTemplate && selectedSmsTemplate.name ? selectedSmsTemplate.name : "",
      messageList: [
        { language: SendPanelSmsLanguage.english, content: customEngMessage },
        {
          language: SendPanelSmsLanguage.simpleChinese,
          content: customChnSmpMessage,
        },
        {
          language: SendPanelSmsLanguage.traditionalChinese,
          content: customChnTradMessage,
        },
      ],
    };
    props.onSubmit(form);
  };

  const getCustomTextField = (language: any, isLast: boolean) => {
    switch (language) {
      case 'English':
        return (
          <CustomSmsTextField
            title={'English-Default:'}
            defaultText={EnglishDefaultMessage}
            rawText={customEngMessage}
            setRawText={setCustomEngMessage}
            id={'engTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={EnglishDefaultPlaceholder}
            customPlaceHolder={'Place make an appointment with your provider.'}
            selectedFilterName={selectedSmsTemplate?.name ?? null}
            onPreview={onPreview || editorStatus === EditorStatus.initial}
            languages={selectedLanguages}
            onFocusStatusChange={(isFocus) => {
              setEditorStatus(isFocus ? EditorStatus.edit : EditorStatus.default)
            }}
          />
        );
      case 'Chinese (S)':
        return (
          <CustomSmsTextField
            title={'Chinese(Simplified):'}
            defaultText={ChineseSimplifiedDefaultMessage}
            rawText={customChnSmpMessage}
            setRawText={setCustomChnSmpMessage}
            id={'chnSmpTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={ChineseSimplifiedDefaultPlaceholder}
            customPlaceHolder={'编辑信息内容'}
            selectedFilterName={selectedSmsTemplate?.name ?? null}
            onPreview={onPreview || editorStatus === EditorStatus.initial}
            languages={selectedLanguages}
            onFocusStatusChange={(isFocus) => {
              setEditorStatus(isFocus ? EditorStatus.edit : EditorStatus.default)
            }}
          />
        );
      case 'Chinese (T)':
        return (
          <CustomSmsTextField
            title={'Chinese(Traditional):'}
            defaultText={ChineseTraditionalDefaultMessage}
            rawText={customChnTradMessage}
            setRawText={setCustomChnTradMessage}
            id={'chnTradTextField'}
            isLast={isLast}
            selectedSmsType={selectedSmsType}
            placeHolder={ChineseTraditionalDefaultPlaceholder}
            customPlaceHolder={'編輯信息內容'}
            selectedFilterName={selectedSmsTemplate?.name ?? null}
            onPreview={onPreview || editorStatus === EditorStatus.initial}
            languages={selectedLanguages}
            onFocusStatusChange={(isFocus) => {
              setEditorStatus(isFocus ? EditorStatus.edit : EditorStatus.default)
            }}
          />
        );
    }
  };

  const getEditorContainerStyle = () => {
    if (editorStatus === EditorStatus.initial) {
      return classes.initialTextAreaContainer
    }
    if (getOverFlowError() || getLangZeroError()) {
      return classes.errorTextAreaContainer
    } else if (editorStatus === EditorStatus.edit) {
      return classes.editTextAreaContainer
    } else {
      return classes.defaultTextAreaContainer
    }
  }

  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={(event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
          return;
        props.onClose(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Send Batch SMS'}
        <span>
          <StaticPatientCount
            patientCount={props.patientCount}
            noChipStyle={false}
            preFix={'To'}
            suffix={'Patient'}
          />
        </span>
      </DialogTitle>
      {!getTemplatesQuery.called || getTemplatesQuery.loading ? (
        <LoadingSpinner style={{ height: 384 }} />
      ) : (
        <DialogContent>
          {!onPreview && (
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>
                Select SMS Type
              </Typography>
              <Select
                variant="outlined"
                fullWidth
                classes={outlinedInputClasses}
                displayEmpty
                placeholder={'Customize or SMS template'}
                value={
                  selectedSmsTemplate != null
                    ? selectedSmsTemplate
                    : selectedSmsType
                }
                IconComponent={KeyboardArrowDown}
                onChange={onSelectSmsType}
                className={classes.select}
                renderValue={(selected: any) => {
                  const selectedItem =
                    SmsType.find((item) => item?.value == selected) ||
                    data.find((item: any) => item?.id == selected?.id);
                  return selectedItem ? (
                    <div className={classes.placeholderTextSelected}>
                      {selectedItem.name}
                    </div>
                  ) : (
                    <div className={classes.placeholderText}>
                      Customize or SMS template
                    </div>
                  );
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  elevation: 2,
                  getContentAnchorEl: null,
                  style: {
                    maxHeight: 68 * 5 + 8,
                    maxWidth: '100%',
                  },
                  PaperProps: {
                    style:
                      data?.length > 0
                        ? {
                            marginTop: theme.spacing(-1.5),
                            paddingLeft: 8,
                            paddingRight: 7,
                            maxHeight:
                              36 * Math.min(data?.length ?? 0, 4) + 8 + 36 + 27,
                          }
                        : {
                            marginTop: theme.spacing(-1.5),
                            paddingLeft: 8,
                            paddingRight: 7,
                          },
                  },
                }}
              >
                {SmsType.map((item: any, idx: any) => (
                  <MenuItem
                    key={idx}
                    value={item.value}
                    classes={{
                      selected: classes.selected,
                    }}
                  >
                    <div className={classes.flexContainer}>
                      <Typography
                        variant="body1"
                        className={classes.selectMenuContent}
                      >
                        {item.name}
                      </Typography>
                      {selectedSmsTemplate == null &&
                        selectedSmsType == item.value && (
                          <CheckIcon
                            style={{
                              height: 25,
                              position: 'absolute',
                              right: '15px',
                            }}
                          />
                        )}
                    </div>
                  </MenuItem>
                ))}
                {data?.length > 0 && (
                  <ListSubheader className={classes.subListHeader}>
                    Templates
                  </ListSubheader>
                )}
                {data
                  .sort(function (a: any, b: any) {
                    try {
                      if (a?.name.toLowerCase() < b?.name.toLowerCase())
                        return -1;
                      if (a?.name.toLowerCase() > b?.name.toLowerCase())
                        return 1;
                    } catch (e) {
                      return 0;
                    }
                    return 0;
                  })
                  .map((item: any, idx: any) => (
                    <MenuItem
                      key={item.id}
                      value={item}
                      classes={{
                        selected: classes.selected,
                      }}
                    >
                      <div className={classes.flexContainer}>
                        <Typography
                          variant="body1"
                          className={classes.selectSubMenuContent}
                        >
                          {item.name}
                        </Typography>
                        {selectedSmsTemplate?.id == item.id && (
                          <CheckIcon
                            style={{
                              height: 25,
                              position: 'absolute',
                              right: '15px',
                            }}
                          />
                        )}
                      </div>
                    </MenuItem>
                  ))}
              </Select>
              <CustomSelectWithChips
                state={selectedLanguages}
                setState={(languages: string[]) => {
                  const diffLanguages = selectedLanguages.filter((x) => languages.indexOf(x) === -1)
                  if (diffLanguages && diffLanguages.length) { // reduce
                    const isCustomType = selectedSmsType === 'Custom Message'
                    diffLanguages.forEach((l) => {
                      if (isCustomType) {
                        if (l === 'English') {
                          setCustomEngMessage("")
                        }
                        if (l === 'Chinese (S)') {
                          setCustomChnSmpMessage("")
                        }
                        if (l === 'Chinese (T)') {
                          setCustomChnTradMessage("")
                        }
                      } else {
                        selectedSmsTemplate?.languages?.forEach((tl: any) => {
                          if (l === 'English' && tl?.language === 'en') {
                            isCustomType ? setCustomEngMessage("") : setCustomEngMessage(decodeURIComponent(atob(tl?.content)));
                          }
                          if (l === 'Chinese (S)' && tl?.language == 'cns') {
                            isCustomType ? setCustomChnSmpMessage("") : setCustomChnSmpMessage(decodeURIComponent(atob(tl?.content)));
                          }
                          if (l === 'Chinese (T)' && tl?.language == 'cnt') {
                            isCustomType ? setCustomChnTradMessage("") : setCustomChnTradMessage(decodeURIComponent(atob(tl?.content)));
                          }
                        })
                      }
                    })
                  }
                  setSelectLanguages(languages)
                  editorStatus === EditorStatus.initial && setEditorStatus(EditorStatus.default)
                }}
                isMultiple={true}
                inputTitle={'Select Languages'}
                placeholder={'All'}
                fullWidth={true}
                items={SmsLanguage}
                isObject={true}
              />
              <Typography className={classes.subLabel}>
                SMS will be sent in the patient's preferred languages or default
                in English.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className={classes.inputLabel}>
              {selectedSmsTemplate != null
                ? 'Template'
                : selectedSmsType == 'Custom Message'
                ? 'Custom Message'
                : 'SMS Preview'}
            </Typography>
            {selectedSmsType == 'Custom Message' && !onPreview && (
              <Typography className={classes.subLabel}>
                Use <mark>#</mark> to insert names or links.
              </Typography>
            )}
            <div
              className={
                onPreview
                  ? classes.onPreviewTextAreaContainer
                  : getEditorContainerStyle()
              }
            >
              {sortArrayBaseOnObjectArrayPipe(
                selectedLanguages,
                SmsLanguage,
                'asc'
              ).map((item, idx) =>
                getCustomTextField(item, idx == selectedLanguages.length - 1)
              )}
            </div>
            {onPreview && (
              <Typography className={classes.subLabel}>
                SMS will be sent in the patient's preferred languages or default
                in English.
              </Typography>
            )}
            {editorStatus !== EditorStatus.initial && getLangZeroError() && (
              <Typography className={classes.errorLabel}>
                Please add message content for all languages you selected.
              </Typography>
            )}
            {getOverFlowError() && (
              <Typography className={classes.errorLabel}>
                {`SMS Message cannot exceed ${SmsMaxLength} characters(4 SMS).`}
              </Typography>
            )}
          </Grid>
        </DialogContent>
      )}
      <div className={classes.divider} />
      <DialogActions>
        <div className={classes.buttonRow}>
          {onPreview ? (
            <Button
              autoFocus
              onClick={() => {
                setOnPreview(false);
              }}
              className={classes.editBtn}
            >
              Edit
            </Button>
          ) : (
            <Button
              autoFocus
              onClick={() => {
                props.onClose(false);
              }}
              className={classes.editBtn}
            >
              Cancel
            </Button>
          )}
          {selectedSmsType == 'Custom Message' ||
          selectedSmsType == 'Template' ? (
            onPreview ? (
              <Button
                onClick={() => {
                  onSubmit();
                  setDisabledButton(true);
                }}
                disabled={disabledButton}
                className={classes.conFirmAndSendBtn}
                autoFocus
              >
                Confirm and Send
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setOnPreview(true);
                }}
                className={classes.previewNowBtn}
                autoFocus
                disabled={getOverFlowError() || getLangZeroError()}
              >
                Preview Message
              </Button>
            )
          ) : (
            <Button
              onClick={() => {
                onSubmit();
              }}
              className={classes.createNowBtn}
              autoFocus
              disabled={selectedSmsType == ''}
            >
              Send Message
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};
