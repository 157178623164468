import React from 'react';
import { CoordinationLogView } from './View';
import { useGetCareLogsQuery } from './index.generated';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';

export enum LogTypeEnum {
  All = 'All',
  Notes = 'Notes',
  StatusChange = 'Status Change',
  CoordinationActions = 'Coordination Actions',
  BatchSMS = 'Batch SMS',
  PatientReassigned = 'Patient Reassigned',
}
export const staticCoordinationLogOptions = [
  { name: LogTypeEnum.All, value: LogTypeEnum.All },
  // { name: LogTypeEnum.Notes, value: LogTypeEnum.Notes },
  { name: LogTypeEnum.StatusChange, value: LogTypeEnum.StatusChange },
  {
    name: LogTypeEnum.CoordinationActions,
    value: LogTypeEnum.CoordinationActions,
  },
  {
    name: LogTypeEnum.PatientReassigned,
    value: LogTypeEnum.PatientReassigned,
  },
  // { name: LogTypeEnum.BatchSMS, value: LogTypeEnum.BatchSMS },
];

interface CoordinationLogContainerProps {
  patientId: string;
  refresher: number;
}

export const CoordinationLogContainer = (
  props: CoordinationLogContainerProps
) => {
  const { patientId, refresher } = props;
  const [loading, setLoading] = React.useState(false);
  const [coordinationLogs, setCoordinationLogs] = React.useState<any>([]);

  const coordinationLogsQuery = useGetCareLogsQuery({
    variables: {
      input: {
        mpatientId: patientId,
        logType: LogTypeEnum.All,
      },
    },
  });
  const formatCoordinationLogs = (data: any) => {
    let lastOutreachReason = '';
    let temp = data.reverse();
    const formattedData = temp.map((item: any) => {
      if (item.logContent?.includes('Closed reason:')) {
        lastOutreachReason = item.logContent?.replace('Closed reason: ', '');
        item.logTitle = item.logTitle?.replace(
          'Close Outreach',
          'Outreach Closed'
        );
      } else if (item.careCoordinationStatusText == 'Outreach Closed') {
        item.careCoordinationStatusText =
          item.careCoordinationStatusText + ` (${lastOutreachReason})`;
      }
      return item;
    });
    if (formattedData.length > 1) {
      const d1 = formattedData[0].logDate;
      const d2 = formattedData[formattedData.length - 1].logDate;
      if (d2 > d1) {
        return formattedData.reverse();
      }
    }
    return formattedData;
  };

  React.useEffect(() => {
    if (!coordinationLogsQuery.called || coordinationLogsQuery.loading) {
      setLoading(true);
    } else {
      const filteredLogs = coordinationLogsQuery.data?.getCoordinationLogs?.filter(
        (item) => !item.logTitle?.includes('Notes')
      );
      setCoordinationLogs(filteredLogs);
      setLoading(false);
    }
  }, [coordinationLogsQuery, patientId]);

  React.useEffect(() => {
    coordinationLogsQuery.refetch();
  }, [refresher]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner style={{ height: 'calc(100vh - 245px)' }} />
      ) : (
        <CoordinationLogView
          coordinationLogData={coordinationLogs}
          coordinationLogOptions={staticCoordinationLogOptions}
        />
      )}
    </div>
  );
};
