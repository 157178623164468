import { useNavigate } from 'react-router';
import { CreatePanelContainer } from './Container';

export const RoutedCreatePanel = () => {
  const navigate = useNavigate();

  return (
    <CreatePanelContainer
      onCreate={(panelId: string) => {
        navigate(`/panels/${panelId}`);
      }}
    />
  );
};
