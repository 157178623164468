import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  path: string;
  fillRule?: "evenodd" | "inherit" | "nonzero" | undefined;
  clipRule?: "evenodd" | "inherit" | "nonzero" | undefined;
}

const useStyles = makeStyles(() => ({
  svgIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const CustomSvgIcon = (props: Props) => {
  const { path, ...rest } = props;
  const classes = useStyles(props);

  return (
    <SvgIcon className={classes.svgIcon} {...rest}>
      <path d={path} />
    </SvgIcon>
  );
};
