import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  styled,
} from '@material-ui/core';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NextArrowIcon } from '../../../../../../../../../../assets/Icons/CareGaps/NextArrowIcon';
import { SnoozeDatePicker } from './SnoozeDatePicker';
import { useEffect, useState } from 'react';
import { StyledRadio } from '../../../../../../../../../../components/StyledRadio';
import { CloseOutreachOption } from './CloseOutreachOption';
import { WakeInDatePicker } from './WakeInDatePicker';

const useStyles = makeStyles((theme) => ({
  smallPrimarybtn: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '83px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '& .MuiButton-label .MuiSvgIcon-root': {
      fontSize: '14px',
      padding: theme.spacing(0),
      marginLeft: theme.spacing(-1),
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginLeft: theme.spacing(0.41625),
    },
  },

  dialogTitle: {
    color: '#434343',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
  },
  formLabel: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    color: '#434343',
    marginBottom: theme.spacing(0.5),
  },
  radioGroupContainer: {
    marginLeft: 6,
    gap: theme.spacing(1.5),
  },
  formControlLabel: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#656565',
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px 24px',
    borderTop: '1px solid #D1D1D1',
  },
  doneBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#0C77D8',
    borderRadius: 8,
    width: 198,
    height: 40,
    marginLeft: 7.5,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  nextBtn: {
    textTransform: 'capitalize',
    color: '#656565',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#E5E5E5',
    borderRadius: 8,
    width: 73,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },

    '&:disabled': {
      color: theme.palette.grey[600],
      background: theme.palette.grey[200],
      opacity: 0.5,
    },
  },
  dimissBtn: {
    textTransform: 'capitalize',
    color: '#656565',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,
    background: '#E5E5E5',
    borderRadius: 8,
    width: 86,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  radio: {
    padding: 4,
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
    '&$checked': {
      color: '#0C77D8',
    },
  },
  checked: {},
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 512,
    height: 'auto',
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
  },
  '& .MuiDialogTitle-root': {
    padding: '20px 24px',
  },
  '& .MuiDialogContent-root': {
    padding: '0 25px',
    paddingBottom: 24,
  },
  '& .MuiDialogActions-root': {
    padding: 0,
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 'none',
  },
}));

export enum OutComeOfCallsActionType {
  LEFT_MESSAGE = 'Left Message',
  UNABLE_TO_LEAVE_MESSAGE = 'Unable to leave message',
  NO_RESPONSE = 'No Response',
  APPOINTMENT_BOOKED = 'Appointment Booked',
  PLAN_TO_WALKIN = 'Plan to Walk In',
  PATIENT_WILL_CALL_BACK = 'Patient Will Call Back',
  CALLING_FOR_SPECIALTY = 'Calling for Specialty',
  CLOSE_OUTREACH = 'Close Outreach',
}

interface OutComeOfCallsDialogProps {
  openDialog: boolean;
  setOpenDialog: (input: boolean) => any;
  dialogTitle: string;
  selectedAction: any;
  setSelectedAction: any;
  onSubmit: any;
  disabledArrowForwardBtn: boolean;
}

export const OutComeOfCallsDialog = (props: OutComeOfCallsDialogProps) => {
  const {
    openDialog,
    setOpenDialog,
    dialogTitle,
    disabledArrowForwardBtn,
  } = props;
  const [snoozeDate, setSnoozeDate] = useState('');
  const [wakeInDate, setWakeInDate] = useState('');
  const [outReachReason, setOutReachReason] = useState('Letter Sent');
  const [customMessage, setCustomMessage] = useState<string>('');
  const [customMessageErr, setCustomMessageErr] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (outReachReason !== 'Other') {
      setCustomMessageErr(undefined);
      setCustomMessage('');
      return;
    }
    if (!customMessage.length) {
      setCustomMessageErr('Reason is empty');
    } else if (customMessage.length > 80) {
      setCustomMessageErr(
        `Reason cannot exceed 80 characters (${customMessage.length}/80)`
      );
    } else {
      setCustomMessageErr(undefined);
    }
  }, [customMessage, outReachReason]);

  const handleChange = (newValue: any) => {
    props.setSelectedAction(newValue);
  };

  const classes = useStyles();

  const handleClose = () => {
    // close dialog
    setOpenDialog(false);
  };

  const handleSelectedSnoozeDate = (date: string) => {
    setSnoozeDate(date);
  };

  const handleSelectedWakeInDate = (date: string) => {
    setWakeInDate(date);
  };

  const [disabledDone, setDisabledDone] = React.useState(false);
  const [disabledDoneNext, setDisabledDoneNext] = React.useState(false);
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>{dialogTitle}</div>
      </DialogTitle>

      <DialogContent>
        <FormControl>
          <div className={classes.formLabel}>Outcome of Call(s)</div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={props.selectedAction}
            name="radio-buttons-group"
            className={classes.radioGroupContainer}
          >
            <FormControlLabel
              value={OutComeOfCallsActionType.LEFT_MESSAGE}
              control={
                <StyledRadio
                  checked={
                    props.selectedAction ==
                    OutComeOfCallsActionType.LEFT_MESSAGE
                  }
                  onChange={() => {
                    handleChange(OutComeOfCallsActionType.LEFT_MESSAGE);
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="Left Message"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={OutComeOfCallsActionType.UNABLE_TO_LEAVE_MESSAGE}
              control={
                <StyledRadio
                  checked={
                    props.selectedAction ==
                    OutComeOfCallsActionType.UNABLE_TO_LEAVE_MESSAGE
                  }
                  onChange={() => {
                    handleChange(
                      OutComeOfCallsActionType.UNABLE_TO_LEAVE_MESSAGE
                    );
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="Unable to leave message"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={OutComeOfCallsActionType.NO_RESPONSE}
              control={
                <StyledRadio
                  checked={
                    props.selectedAction == OutComeOfCallsActionType.NO_RESPONSE
                  }
                  onChange={() => {
                    handleChange(OutComeOfCallsActionType.NO_RESPONSE);
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="No Response"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={OutComeOfCallsActionType.APPOINTMENT_BOOKED}
              color="success"
              control={
                <StyledRadio
                  checked={
                    props.selectedAction ==
                    OutComeOfCallsActionType.APPOINTMENT_BOOKED
                  }
                  onChange={() => {
                    handleChange(OutComeOfCallsActionType.APPOINTMENT_BOOKED);
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="Appointment Booked"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={OutComeOfCallsActionType.PLAN_TO_WALKIN}
              control={
                <StyledRadio
                  checked={
                    props.selectedAction ==
                    OutComeOfCallsActionType.PLAN_TO_WALKIN
                  }
                  onChange={() => {
                    handleChange(OutComeOfCallsActionType.PLAN_TO_WALKIN);
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="Plan to Walk In"
              className={classes.formControlLabel}
            />
            <FormControlLabel
                value={OutComeOfCallsActionType.PATIENT_WILL_CALL_BACK}
                control={
                  <StyledRadio
                      checked={
                          props.selectedAction ==
                          OutComeOfCallsActionType.PATIENT_WILL_CALL_BACK
                      }
                      onChange={() => {
                        handleChange(OutComeOfCallsActionType.PATIENT_WILL_CALL_BACK);
                      }}
                      classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label="Patient Will Call Back"
                className={classes.formControlLabel}
            />
            <FormControlLabel
                value={OutComeOfCallsActionType.CALLING_FOR_SPECIALTY}
                control={
                  <StyledRadio
                      checked={
                          props.selectedAction ==
                          OutComeOfCallsActionType.CALLING_FOR_SPECIALTY
                      }
                      onChange={() => {
                        handleChange(OutComeOfCallsActionType.CALLING_FOR_SPECIALTY);
                      }}
                      classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label="Calling for Specialty"
                className={classes.formControlLabel}
            />
            <FormControlLabel
              value={OutComeOfCallsActionType.CLOSE_OUTREACH}
              control={
                <StyledRadio
                  checked={
                    props.selectedAction ==
                    OutComeOfCallsActionType.CLOSE_OUTREACH
                  }
                  onChange={() => {
                    handleChange(OutComeOfCallsActionType.CLOSE_OUTREACH);
                  }}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label="Close Outreach"
              className={classes.formControlLabel}
            />
          </RadioGroup>
        </FormControl>

        {props.selectedAction === OutComeOfCallsActionType.LEFT_MESSAGE ||
        props.selectedAction ===
          OutComeOfCallsActionType.UNABLE_TO_LEAVE_MESSAGE ||
        props.selectedAction === OutComeOfCallsActionType.NO_RESPONSE ||
        props.selectedAction == OutComeOfCallsActionType.PATIENT_WILL_CALL_BACK ? (
          <SnoozeDatePicker onSelectedDate={handleSelectedSnoozeDate} />
        ) : null}
        {props.selectedAction === OutComeOfCallsActionType.PLAN_TO_WALKIN ? (
          <WakeInDatePicker onSelectedDate={handleSelectedWakeInDate} />
        ) : null}
        {props.selectedAction === OutComeOfCallsActionType.CLOSE_OUTREACH ? (
          <CloseOutreachOption
            outReachReason={outReachReason}
            setOutReachReason={setOutReachReason}
            customMessage={customMessage}
            setCustomMessage={setCustomMessage}
            customMessageErr={customMessageErr}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonRow}>
          <Button
            onClick={() => {
              props.setOpenDialog(false);
            }}
            className={classes.dimissBtn}
            autoFocus
          >
            Cancel
          </Button>
          <div>
            <Button
              disabled={disabledDone || customMessageErr !== undefined}
              onClick={() => {
                props.onSubmit(
                  false,
                  snoozeDate,
                  wakeInDate,
                  outReachReason,
                  customMessage
                );
                setDisabledDone(true);
              }}
              className={classes.nextBtn}
              autoFocus
            >
              Done
            </Button>
            {disabledArrowForwardBtn ? null : (
              <Button
                disabled={disabledDoneNext || customMessageErr !== undefined}
                onClick={() => {
                  props.onSubmit(
                    true,
                    snoozeDate,
                    wakeInDate,
                    outReachReason,
                    customMessage
                  );
                  setDisabledDoneNext(true);
                }}
                className={classes.doneBtn}
                autoFocus
                endIcon={<NextArrowIcon />}
              >
                Done, Next Patient
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
};
