import React from 'react';
import { makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { InfoIcon } from '../../assets/Icons/PanelsList/InfoIcon';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import { useNotificationMessageStatus } from '../../hooks/useNotificationMessageStatus';

export type NotificationAlertMessageType = {
  isOpen: boolean;
  //   snackbarMsg: string;
  notificationType: 'Panel';
  msgContent: {
    notificationId: string;
    panelId: string;
    patientCount: number;
  };
};

interface NotificationAlertMessageProps {
  open: boolean;
  setOpen: (input: boolean) => void;
  alertMessage: NotificationAlertMessageType;
}

export const NotificationAlertMessage: React.FC<NotificationAlertMessageProps> = ({
  open,
  setOpen,
  alertMessage,
}) => {
  const { handler: readNotificationsHandler } = useNotificationMessageStatus(
    alertMessage.msgContent.notificationId as 'read' | 'clear',
    'read',
  );

  const classes = useStyles();
  const { setShowNotificationDot } = React.useContext(GlobalContext);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'click_away') {
      return;
    }
    setOpen(false);
    setShowNotificationDot(false);
  };

  const getAlertMessageByType = () => {
    switch (alertMessage.notificationType) {
      case 'Panel': {
        return (
          <div style={{ width: '100%' }}>
            <Typography className={classes.panelMsgRegular}>
              {alertMessage.msgContent.patientCount} Patients have been assigned
              to you.{' '}
              <Link
                className={classes.panelMsgLink}
                to={`/panels/${alertMessage.msgContent.panelId}/assigned`}
                onClick={() => {
                  setShowNotificationDot(false);
                  readNotificationsHandler([]);
                }}
              >
                Check Now
              </Link>
            </Typography>
          </div>
        );
      }
    }
  };

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          icon={<InfoIcon className={classes.infoIcon} />}
          className={classes.messageContainer}
        >
          {getAlertMessageByType()}
        </Alert>
      </Snackbar>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    '& > div.MuiSnackbar-root': {
      bottom: 72,
      left: 16,
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiAlert-action .MuiIconButton-label': {
      opacity: 0.5,
    },
    '& div[role="alert"]': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: 8,
      padding: theme.spacing(1.5, 2),

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
    },

    '& div.MuiAlert-icon': {
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& div.MuiAlert-message': {
      padding: 0,
      margin: 0,
      fontSize: 14,
    },
    '& div.MuiAlert-action': {
      padding: 0,
      margin: 0,
    },
  },
  infoIcon: {
    fontSize: 24,
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  messageContainer: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.primary.main,

    width: 'fit-content',
    height: 54,
    boxSizing: 'border-box',
  },
  panelMsgRegular: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.primary.main,
  },
  panelMsgLink: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));
