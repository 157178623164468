/* 
Dialog for the manual care coordination option: 
- Out of Country , Changed PCP, Inactive Insurance, Patient Deceased
*/

import {
  Button,
  Dialog,
  Grid,
  Paper,
  Theme,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import React from 'react';
import { theme } from '../../../../../../../../../../themes/theme';
import { SiteFollowUpForm } from '../../SiteFollowUpForm';
import { useForm } from 'react-hook-form';
import { SiteFollowUpUser } from '../../../../../../../../../../app.types.generated';

type DialogContentItem = {
  id: string;
  title: string;
  description: JSX.Element;
};

interface ManualCareCoordinationDialogProps {
  selectedOption: string;
  handleClose: () => void;
  onConfirm: (input?: any) => void;
  mpatientId: string;
  setCompletionUser?: (user: SiteFollowUpUser | undefined) => void;
  completionUser?: SiteFollowUpUser | undefined;
}

export const ManualCareCoordinationDialog = (
  props: ManualCareCoordinationDialogProps
) => {
  const { selectedOption, handleClose, onConfirm, mpatientId } = props;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      notes: '',
      designee: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [loadingFormData, setLoadingFormData] = React.useState<boolean>(false);
  const DialogContentItems = [
    {
      id: 'Reopen',
      title: 'Are you sure?',
      description: (
          <Typography
              style={{
                color: '#656565',
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '24px' /* 150% */,
              }}
          >
            Are you sure you want to reopen outreach? Once reopened, we will restore the previous call attempts counts and the latest patient status.
          </Typography>
      ),
    },
    {
      id: 'Completed Site Follow Up',
      title: 'Completed Site Follow Up?',
      description: (
        <SiteFollowUpForm
          control={control}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          submitForm={onConfirm}
          mpatientId={mpatientId}
          dialogId="Completed Site Follow Up"
          setLoadingFormData={setLoadingFormData}
          setCompletionUser={props.setCompletionUser}
        />
      ),
    },
    {
      id: 'Site Follow Up',
      title: 'Site Follow Up Confirmation',
      description: (
        <SiteFollowUpForm
          control={control}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          submitForm={onConfirm}
          mpatientId={mpatientId}
          dialogId="Site Follow Up"
          setLoadingFormData={setLoadingFormData}
        />
      ),
    },
    {
      id: 'Out of Country',
      title: 'Patient Out of Country?',
      description: (
        <Typography
          style={{
            color: '#656565',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px' /* 150% */,
          }}
        >
          Once you confirm update, this patient will be marked as{' '}
          <span style={{ fontWeight: 700 }}>Outreach Closed</span>, and you can
          not reopen this case.
        </Typography>
      ),
    },
    {
      id: 'Changed PCP',
      title: 'Patient Changed PCP?',
      description: (
        <Typography
          style={{
            color: '#656565',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px' /* 150% */,
          }}
        >
          Once you confirm update, this patient will be marked as{' '}
          <span style={{ fontWeight: 700 }}>Outreach Closed</span>, and you can
          not reopen this case.
        </Typography>
      ),
    },
    {
      id: 'Inactive Insurance',
      title: 'Patient Has Inactive Insurance?',
      description: (
        <Typography
          style={{
            color: '#656565',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px' /* 150% */,
          }}
        >
          Once you confirm update, this patient will be marked as{' '}
          <span style={{ fontWeight: 700 }}>Outreach Closed</span>, and you can
          not reopen this case.
        </Typography>
      ),
    },
    {
      id: 'Patient Deceased',
      title: 'Patient Deceased?',
      description: (
        <Typography
          style={{
            color: '#656565',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px' /* 150% */,
          }}
        >
          Once you confirm update, this patient will be marked as{' '}
          <span style={{ fontWeight: 700 }}>Deceased</span>, and you can not
          reopen this case.
        </Typography>
      ),
    },
  ];

  const classes = useStyles();

  const [dialogContent, setDialogContent] = React.useState<DialogContentItem>();
  React.useEffect(() => {
    if (selectedOption) {
      setDialogContent(
        DialogContentItems.find((item) => item.id === selectedOption)
      );
    }
  }, [selectedOption, errors]);

  // console.log('errors', errors);
  // console.log('form values:', watch('notes'), watch('designee'));

  const disableSubmitButton = () => {
    switch (dialogContent?.id) {
      case 'Site Follow Up':
        return (
          errors.notes != undefined ||
          !(watch('notes') !== '' && watch('designee') !== undefined)
        );
      case 'Completed Site Follow Up':
        return (
          errors.notes != undefined || loadingFormData || !props.completionUser
        );
      default:
        return false;
    }
  };

  return (
    <>
      {dialogContent ? (
        <BootstrapDialog
          aria-labelledby="ManualCareCoordinationDialog"
          open={true}
          {...props}
        >
          <div role="ManualCareCoordinationDialog-container">
            <Paper className={classes.paperContainer}>
              <Grid container>
                {/* section header */}
                <Grid item xs={12} className={classes.cardHeader}>
                  {/* title */}
                  <Typography className={classes.cardTitle}>
                    {dialogContent.title}
                  </Typography>
                </Grid>

                {/* main region */}
                <Grid item xs={12} className={classes.cardContent}>
                  <Grid item xs={12}>
                    {dialogContent.description}
                  </Grid>
                </Grid>

                {/* section footer */}
                <Grid item xs={12} className={classes.sectionFooter}>
                  <Button
                    id="cancel-btn"
                    variant="contained"
                    style={{ width: 71 }}
                    className={classes.backBtn}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    id="confirm-button"
                    variant="contained"
                    disableElevation
                    className={classes.updateBtn}
                    disabled={disableSubmitButton()}
                    type={
                      dialogContent.id === 'Site Follow Up' ||
                      dialogContent.id === 'Completed Site Follow Up'
                        ? 'submit'
                        : 'button'
                    }
                    onClick={
                      dialogContent.id === 'Site Follow Up' ||
                      dialogContent.id === 'Completed Site Follow Up'
                        ? handleSubmit(onConfirm)
                        : onConfirm
                    }
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </BootstrapDialog>
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  root: {},
  paperContainer: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3, 3, 4, 3),
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    padding: theme.spacing(2.5, 0),
  },
  sectionHeader: {
    height: 28,
    borderBottom: '1px solid #d1d1d1',
    color: theme.palette.grey[700],
    fontSize: 20,

    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.4,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.625),
    paddingLeft: theme.spacing(3),
  },
  sectionFooter: {
    position: 'relative',
    height: 72,
    textTransform: 'capitalize',
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backBtn: {
    // width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    margin: theme.spacing(1.875, 'auto', 2, 3),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  updateBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 89,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),
    marginRight: theme.spacing(2.875),
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
});

const BootstrapDialog = styled(Dialog)(
  ({
    theme,
    ...props
  }: { theme: Theme } & ManualCareCoordinationDialogProps) => ({
    '& .MuiPaper-root': {
      width:
        props.selectedOption === 'Site Follow Up' ||
        props.selectedOption === 'Completed Site Follow Up'
          ? 680
          : 450,
      height: 'auto',
      background: theme.palette.primary.contrastText,
      boxShadow:
        '0px 1px 0px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.16)',
      borderRadius: '8px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  })
);
