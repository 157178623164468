import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const LockIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M17 7.75C17 4.98858 14.7614 2.75 12 2.75C9.23858 2.75 7 4.98858 7 7.75V9.42071C5.83481 9.83254 5 10.9438 5 12.25V18.25C5 19.9069 6.34315 21.25 8 21.25H16C17.6569 21.25 19 19.9069 19 18.25V12.25C19 10.9438 18.1652 9.83254 17 9.42071V7.75ZM15 9.25V7.75C15 6.15232 13.7511 4.84634 12.1763 4.75509L12 4.75C10.4023 4.75 9.09634 5.99892 9.00509 7.57373L9 7.75V9.25H15ZM13 15.9824C13.5978 15.6366 14 14.9903 14 14.25C14 13.1454 13.1046 12.25 12 12.25C10.8954 12.25 10 13.1454 10 14.25C10 14.9903 10.4022 15.6366 11 15.9824V17.25C11 17.8023 11.4477 18.25 12 18.25C12.5523 18.25 13 17.8023 13 17.25V15.9824Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  );
};
