import React from 'react';
import { useGetPanelDelistedPatientsQuery } from '../index.generated';
import {
  GetPatientByMultipleUserIdsRequest,
  PanelPatient,
} from '../../../../../app.types.generated';

export type DeListedPatientQueryType = {
  loading: boolean | undefined;
  count: number | undefined;
  errorMessage: string | undefined;
  results: PanelPatient[] | undefined;
  refetch: any;
  input: GetPatientByMultipleUserIdsRequest | undefined;
};

export const useDeListedPatients = (
  input: GetPatientByMultipleUserIdsRequest
) => {
  const defaultDeListedPatientQueryState: DeListedPatientQueryType = {
    loading: undefined,
    count: undefined,
    errorMessage: undefined,
    results: undefined,
    refetch: undefined,
    input: undefined,
  };

  const [
    deListedPatientQueryState,
    setDeListedPatientQueryState,
  ] = React.useState(defaultDeListedPatientQueryState);

  const deListedPatientQuery = useGetPanelDelistedPatientsQuery({
    variables: {
      input: input,
    },
  });

  React.useEffect(() => {
    if (!deListedPatientQuery.called || deListedPatientQuery.loading) {
      setDeListedPatientQueryState({
        ...defaultDeListedPatientQueryState,
        loading: true,
      });
    } else if (deListedPatientQuery.data) {
      setDeListedPatientQueryState({
        loading: false,
        count: deListedPatientQuery.data?.getPanelDelistedPatients.count || 0,
        errorMessage:
          deListedPatientQuery.data?.getPanelDelistedPatients.errorMessage ||
          '',
        results:
          deListedPatientQuery.data?.getPanelDelistedPatients.results || [],
        refetch: deListedPatientQuery.refetch,
        input: input,
      });
    } else if (deListedPatientQuery.error) {
      setDeListedPatientQueryState({
        loading: false,
        count: 0,
        errorMessage: deListedPatientQuery.error.message,
        results: [],
        refetch: undefined,
        input: input,
      });
    }
  }, [deListedPatientQuery]);

  return { deListedPatientQueryState };
};
