import {
  Button,
  createStyles,
  Divider,
  fade,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Theme,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { MoreHorizIcon } from '../../../../../assets/Icons/Eligibility/MoreHorizIcon';
import {
  ActivateUserIcon,
  DeactivateUserIcon,
  EditIcon,
} from '../../../../../assets/Icons/MsoAdmin';
import { useNavigate } from 'react-router-dom';
import { MenuItemResendPassword } from './components/MenuItemResendPassword';
import {
  AccountOptionType,
  AccountStatus,
  User,
} from '../../../../../enum/Users';
import { useToggleAccountStatus } from './hooks/useToggleAccountStatus';

const useMoreOptionsMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemDivider: {
      width: 200,
      height: 1,
      background: theme.palette.grey[300],
      position: 'absolute',
      left: 0,
      top: 40,
    },
    moreHoriz: {
      color: theme.palette.grey[500],
    },
    menu: {
      position: 'relative',
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded':
        {
          marginTop: 0,
          // height: 122,
          width: 200,
        },
      '& > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > ul':
        {
          width: 184,
          // height: 112,
          padding: theme.spacing(0.5, 1),
          position: 'relative',
        },

      '& li[role="menuitem"]': {
        width: 184,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 35,
        borderRadius: 8,
        // margin: theme.spacing(0.5, 0),
      },

      '& li[role="menuitem"]:first-child': {
        marginBottom: theme.spacing(0.5),
      },
      '& li[role="menuitem"]:nth-type-of(2)': {
        marginBottom: theme.spacing(0.25),
      },
      '& li[role="menuitem"]:last-child': {
        margin: theme.spacing(0.5, 0),
      },
    },
    menuItem: {
      // marginTop: theme.spacing(1, 2),
    },
  })
);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1.5), // -6.5 1.5
    width: 160,
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1, 2),
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      lineHeight: '24px',
      display: 'inline-block',
      // width: 87,
      height: 24,
      // color: 'theme.palette.secondary.dark',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      // letterSpacing: 0,
      // marginRight: theme.spacing(2),
    },
  },
}));

interface UserOptionsMenuProps {
  currentUser: User;
  setToBeUpdatedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setIsOpenDialog: (input: boolean) => void;
  setOptionCode: (input: AccountOptionType) => void;
}

export const UserOptionsMenu = (props: UserOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  let navigate = useNavigate();
  const classes = useMoreOptionsMenuStyles(props);
  // const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // click export menu button
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    // close menu
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOption = (
    event: React.MouseEvent<HTMLElement>,
    reason: AccountOptionType
  ) => {
    // select options
    event.stopPropagation();

    switch (reason) {
      case AccountOptionType.EDIT:
        navigate('/users/' + props.currentUser.userId + '/update', {
          // state: props.currentUser,
          state: {
            userData: props.currentUser,
            urlParams: window.location.search,
          },
        });
        break;
      case AccountOptionType.DEACTIVATE:
        // show toggle account dialog
        props.setOptionCode(AccountOptionType.DEACTIVATE);
        props.setToBeUpdatedUsers([props.currentUser]);
        props.setIsOpenDialog(true);
        break;
      case AccountOptionType.ACTIVATE:
        // show toggle account dialog
        props.setOptionCode(AccountOptionType.ACTIVATE);
        props.setToBeUpdatedUsers([props.currentUser]);
        props.setIsOpenDialog(true);
        break;
    }
    setAnchorEl(null);
  };

  const { isMyself, privileged } = useToggleAccountStatus([props.currentUser]);

  const getMenuOptions = (currentUserStatus: string) => {
    switch (currentUserStatus) {
      case AccountStatus.ACTIVE:
        return isMyself ? null : privileged ? (
          <MenuItem
            onClick={(e) => handleOption(e, AccountOptionType.DEACTIVATE)}
            disableRipple
            className={classes.menuItem}
          >
            <span>Deactivate</span>
            <DeactivateUserIcon style={{ height: 24, width: 24 }} />
          </MenuItem>
        ) : null;
      case AccountStatus.DEACTIVATED:
        return isMyself ? null : privileged ? (
          <MenuItem
            onClick={(e) => handleOption(e, AccountOptionType.ACTIVATE)}
            disableRipple
            className={classes.menuItem}
          >
            <span>Activate</span>
            <ActivateUserIcon style={{ height: 24, width: 24 }} />
          </MenuItem>
        ) : null;
      case AccountStatus.PENDING:
        return (
          <MenuItemResendPassword
            setAnchorEl={setAnchorEl}
            currentUser={props.currentUser}
            setToBeUpdatedUsers={props.setToBeUpdatedUsers}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Button
        id="more-option-button"
        aria-controls="more-option-menu"
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{
          border: 'none',
          background: 'inherit',
          padding: 0,
          height: 'auto',
        }}
      >
        <MoreHorizIcon className={classes.moreHoriz} />
      </Button>
      <StyledMenu
        id="more-option-menu"
        MenuListProps={{
          'aria-labelledby': 'more-option-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        elevation={2}
        className={classes.menu}
      >
        <MenuItem
          onClick={(e) => handleOption(e, AccountOptionType.EDIT)}
          disableRipple
          className={classes.menuItem}
        >
          <span>Edit</span>
          <EditIcon style={{ height: 24, width: 24 }} />
        </MenuItem>
        {getMenuOptions(props.currentUser.status) !== null && (
          <Divider className={classes.menuItemDivider} />
        )}
        {getMenuOptions(props.currentUser.status)}
      </StyledMenu>
    </div>
  );
};
