import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Grid,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import { theme } from '../../../../themes/theme';
import { CareGapMeasurementTable } from '../MeasurementTable';
import { TabBar } from '../../../../components/TabBar';

const MainRegion = styled(Container)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: 'none',
  maxWidth: 'none',

  '@media (minWidth: 600px)': {
    maxWidth: 'fit-content',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  control: {
    margin: 0,
    padding: 0,
    // boxShadow: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.grey[50],
    '& .MuiTabs-root': {
      borderBottom: 'none',
    },
    // borderRadius: 0,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTop: 'none',

    '& > .MuiTabs-root': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    '& div[role="tabBar"]': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  headerMainRow: {
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.grey[50],
    // borderBottom: `1.93px solid ${theme.palette.text.disabled}`,
    display: 'flex',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      height: 28,
      lineHeight: '28px',
      margin: theme.spacing(1.75, 3),
      display: 'inline-block',
      textTransform: 'capitalize',
    },

    '& #last-update-container': {
      marginLeft: 'auto',
      marginRight: '24px',
      display: 'flex',
      flexDirection: 'row',
    },

    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: '125px',
      height: '40px',
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0',
      textTransform: 'capitalize',
      marginLeft: '3.33px',
    },
  },
}));

interface CareGapsListViewProps {
  careGapsList?: any;
}

export const CareGapsListView = (props: CareGapsListViewProps) => {
  const { careGapsList } = props;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const switchTab = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainRegion>
      <Grid container className={classes.root}>
        {/* region header */}
        <Grid item xs={12} className={classes.headerMainRow}>
          {/* title */}
          <Typography variant={'h2'}>Care Gaps</Typography>
        </Grid>

        {/* Tabs for showing panels */}
        <Box style={{ width: '100%' }}>
          <Paper className={classes.control}>
            <TabBar
              activeTabIndex={value}
              switchTab={switchTab}
              tabObjects={[
                {
                  tabIndex: 0,
                  tabLabel: 'Quality Measurements',
                  tabUrl: '',
                  tabBadgeCount: 0,
                  disabled: false,
                },
                // {
                //   tabIndex: 1,
                //   tabLabel: 'HEDIS',
                //   tabUrl: '',
                //   tabBadgeCount: 0,
                //   disabled: true,
                // },
                // {
                //   tabIndex: 2,
                //   tabLabel: 'CMS Stars',
                //   tabUrl: '',
                //   tabBadgeCount: 0,
                //   disabled: true,
                // },
              ]}
            >
              {/* Values for different tabs */}
              {value === 0 && (
                <Grid item xs={12}>
                  <CareGapMeasurementTable filteredRows={careGapsList} />
                </Grid>
              )}
              {value === 1 && (
                <Grid item xs={12}>
                  {/* <CareGapMeasurementTable filteredRows={fakeHedisRows} /> */}
                </Grid>
              )}
              {value === 2 && (
                <Grid item xs={12}>
                  {/* <CareGapMeasurementTable filteredRows={fakeCMSStarsRows} /> */}
                </Grid>
              )}
            </TabBar>
          </Paper>
        </Box>
      </Grid>
    </MainRegion>
  );
};
