export enum PatientPanelFilterTypes {
  ALL_PATIENTS = 'All Patients',
  MY_PATIENTS = 'My Patients',
  ASSIGNED_PATIENTS = 'Assigned Patients',
  UNASSIGNED_PATIENTS = 'Unassigned Patients',
  PROVIDER_PATIENTS = 'Provider Patients',
  ALL_PROVIDERS = 'All Providers',
}

export enum PatientFilterComponentIDs {
  ALL_PATIENTS = 'allPatients',
  MY_PATIENTS = 'myPatients',
  ASSIGNED_PATIENTS = 'assignedPatients',
  UNASSIGNED_PATIENTS = 'unassignedPatients',
  PROVIDER_PATIENTS = 'providerPatients',
  ALL_PROVIDERS = 'allProviders',
}
