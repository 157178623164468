import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { Button, Typography } from '@material-ui/core';
import { SIDEBAR_WIDTH } from '../../../../../layouts/MainLayout';
import {
  ActivateUserIcon,
  DeactivateUserIcon,
  ResendPasswordIcon,
} from '../../../../../assets/Icons/MsoAdmin';
import {
  AccountOptionType,
  AccountStatus,
  User,
} from '../../../../../enum/Users';
import { useResendTemporaryPasswordMutation } from '../../EditUsers/index.generated';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { useToggleAccountStatus } from '../UserOptionsMenu/hooks/useToggleAccountStatus';

interface Props {
  itemName: string;
  selectedItems: User[];
  setOptionCode: (input: AccountOptionType) => void;
  setIsOpenDialog: (input: boolean) => void;
  setUpdatedUsers: (input: User[]) => void;
  setToBeUpdatedUsers: (input: User[]) => void;
  setSelected: (input: User[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'fixed',
    bottom: 20,
    left: SIDEBAR_WIDTH,
    right: 0,
    height: 64,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ptCountContainer: {
    position: 'absolute',
    left: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    height: 20,
    backgroundColor: theme.palette.secondary.light,
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.grey[500],
    },
  },
  countText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    height: 32,
    '&.MuiButton-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: 0,
    },
  },
  icon: {
    height: 20,
    width: 20,
    color: theme.palette.secondary.dark,
    paddingRight: theme.spacing(0.5),
  },
  option: {
    display: 'inline-block',
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 24,
    width: 1,
    backgroundColor: theme.palette.grey[300],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

export const UsersListActionsFooterView = (props: Props) => {
  const [showResendPasswordButton, setShowResendPasswordButton] =
    React.useState(false);
  const [showActivateButton, setShowActivateButton] = React.useState(false);
  const [showDeactivateButton, setShowDeactivateButton] = React.useState(false);

  // resend password
  const [resendTemporaryPasswordMutation] =
    useResendTemporaryPasswordMutation();

  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  async function resendTemporaryPassword(users: User[]) {
    try {
      const { data } = await resendTemporaryPasswordMutation({
        variables: {
          input: {
            userIdsInBatch: users.map((user) => {
              return { userId: user.userId };
            }),
          },
        },
      });

      if (data) {
        if (users.length === 1) {
          //// one user
          setToastMessage({
            isOpen: true,
            severity:
              data?.resendTemporaryPassword.result === true
                ? 'success'
                : 'error',
            snackbarMsg:
              data?.resendTemporaryPassword.result === true
                ? `Temporary Password has been sent to ${users[0].email}`
                : `Temporary Password fails to send to ${users[0].email}, please try again.`,
          });
        } else if (users.length >= 2) {
          setToastMessage({
            isOpen: true,
            severity:
              data?.resendTemporaryPassword.result === true
                ? 'success'
                : 'error',
            snackbarMsg:
              data?.resendTemporaryPassword.result === true
                ? `Temporary Password has been sent to ${users.length} users`
                : `Temporary Password fail to send to ${users.length} users, please try again.`,
          });
        }

        setIsOpenToast(true);
        props.setSelected([]);
      }
    } catch (err: any) {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: `Exceeded daily email limit. Temporary Password fails to send to ${
          users.length === 1 ? users[0].email : users.length + ' users'
        }, please try again.`,
      });

      setIsOpenToast(true);
    }
  }

  const classes = useStyles();

  const displayMenuOptionButtons = (status: string | unknown) => {
    switch (props.selectedItems[0].status) {
      case AccountStatus.PENDING:
        // show resend password button
        setShowResendPasswordButton(privileged);
        break;

      case AccountStatus.ACTIVE:
        // show deactivate button
        setShowDeactivateButton(!isMyself && privileged);
        break;

      case AccountStatus.DEACTIVATED:
        // show activate button
        setShowActivateButton(privileged);
        break;
    }
  };

  const removedDuplicatedAccountStatus = new Set();
  const { isMyself, privileged } = useToggleAccountStatus([
    ...props.selectedItems,
  ]);

  React.useEffect(() => {
    if (props.selectedItems.length === 1) {
      // cannot deactivate myself if only one selected
      displayMenuOptionButtons(props.selectedItems[0].status);
    } else {
      // check selected users' account status
      props.selectedItems.forEach((item) =>
        removedDuplicatedAccountStatus.add(item.status)
      );
      const selectedItemAccountStatus = [...removedDuplicatedAccountStatus];

      if (selectedItemAccountStatus.length === 1) {
        // only one accountStatus in selectedItems, show corresponding option button
        displayMenuOptionButtons(selectedItemAccountStatus[0]);
      } else {
        setShowResendPasswordButton(false);
        setShowDeactivateButton(false);
        setShowActivateButton(false);
      }
    }
  }, [props.selectedItems, privileged, isMyself]);

  const handleClick = async (
    event: React.MouseEvent<HTMLElement>,
    reason: AccountOptionType
  ) => {
    event.stopPropagation();

    switch (reason) {
      case AccountOptionType.RESEND_PASSWORD:
        // call resend password api
        await resendTemporaryPassword(props.selectedItems);
        break;
      case AccountOptionType.DEACTIVATE:
        // show toggle account dialog
        props.setOptionCode(AccountOptionType.DEACTIVATE);
        props.setToBeUpdatedUsers(props.selectedItems);
        props.setIsOpenDialog(true);
        break;
      case AccountOptionType.ACTIVATE:
        // show toggle account dialog
        props.setOptionCode(AccountOptionType.ACTIVATE);
        props.setToBeUpdatedUsers(props.selectedItems);
        props.setIsOpenDialog(true);
        break;
    }
  };

  return (
    <div className={classes.root} id={'MainActionFooter'}>
      <div className={classes.ptCountContainer}>
        <Chip className={classes.chip} label={props.selectedItems.length} />
        <Typography
          className={classes.countText}
        >{`${props.itemName} Selected`}</Typography>
      </div>

      <div className={classes.buttonsContainer}>
        {/* resend password */}
        {showResendPasswordButton && (
          <Button
            className={classes.button}
            onClick={(e) => handleClick(e, AccountOptionType.RESEND_PASSWORD)}
          >
            <ResendPasswordIcon className={classes.icon} />
            <Typography className={classes.option}>Resend Password</Typography>
          </Button>
        )}
        {/* enable */}
        {showActivateButton && (
          <Button
            className={classes.button}
            onClick={(e) => handleClick(e, AccountOptionType.ACTIVATE)}
          >
            <ActivateUserIcon className={classes.icon} />
            <Typography className={classes.option}>Activate</Typography>
          </Button>
        )}
        {/* disable */}
        {showDeactivateButton && (
          <Button
            className={classes.button}
            onClick={(e) => handleClick(e, AccountOptionType.DEACTIVATE)}
          >
            <DeactivateUserIcon className={classes.icon} />
            <Typography className={classes.option}>Deactivate</Typography>
          </Button>
        )}
      </div>
    </div>
  );
};
