import React from 'react';
import { Drawer } from '@material-ui/core';
import { CareGapsDrawerTabs } from '../CareGapsDrawerTabs';
import { useCareGapDrawerStyles } from './styles';
import { CareGapDrawerHeader } from '../CareGapDrawerHeader';
import {
  CareTeamMember,
  PanelPatient,
} from '../../../../../../../app.types.generated';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';
import { PatientFaceSheet } from './Container';
import { useDisabledSwitchArrowButton } from '../../hooks/useDisabledSwitchArrowButton';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { CareGapContext } from '../../context';

interface CareGapsDrawerViewProps {
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => void;
  patientInfo: PatientFaceSheet;
  allPatients: PanelPatient[];
  tabIndex: number;
  switchTab: (event: any, newValue: number) => void;
  setEnableChangeCoordinatorDialog?: (input: boolean) => void;
  loading: boolean;
  originalPatient: PanelPatient;
  setFromUserId?: (input: string) => void;
  hardRefresh: () => void;
  refresher: number;
  currentPage: number;
  totalCount: number;
  limit: number;
  skip: number;
  setSkip: (input: number) => void;
  fromCoordinators?: CareTeamMember[] | null | undefined;
  panelId?: any;
  currentPatient: PanelPatient;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
}

export const CareGapsDrawerView = (props: CareGapsDrawerViewProps) => {
  const {
    drawerHandler,
    patientInfo,
    allPatients,
    tabIndex,
    switchTab,
    setEnableChangeCoordinatorDialog,
    loading,
    originalPatient,
    setFromUserId,
    hardRefresh,
    refresher,
    currentPage,
    totalCount,
    limit,
    skip,
    setSkip,
    fromCoordinators,
    setKeyPatients,
  } = props;
  const { switchToNextPatient } = React.useContext(CareGapContext);

  const classes = useCareGapDrawerStyles();

  const [clickedFamilyRows, setClickedFamilyRows] = React.useState(false);

  const {
    handleSwitchNextPatient,
    disabledArrowForwardBtn,
  } = useDisabledSwitchArrowButton(
    allPatients,
    patientInfo.id,
    currentPage,
    skip,
    setSkip,
    limit,
    totalCount,
    setKeyPatients
  );

  const [hasNext, setHasNext] = React.useState(true);

  // // release lock is done by passing preMpatientId when calling getPatientFaceInfo API
  const enhancedHandleSwitchNextPatient = () => {
    handleSwitchNextPatient();
  };

  React.useEffect(() => {
    if (switchToNextPatient) {
      if (disabledArrowForwardBtn === false) {
        enhancedHandleSwitchNextPatient();
      } else {
        drawerHandler((new Event('click') as unknown) as React.MouseEvent);
      }
    }
  }, [switchToNextPatient]);

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={true}
        onClose={(event: React.KeyboardEvent | React.MouseEvent) => {
          drawerHandler(event);
        }}
        className={classes.drawer}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <CareGapDrawerHeader
              patientInfo={patientInfo}
              setKeyPatients={setKeyPatients}
              allPatients={allPatients}
              setEnableChangeCoordinatorDialog={
                setEnableChangeCoordinatorDialog
              }
              clickedFamilyRows={clickedFamilyRows}
              setClickedFamilyRows={setClickedFamilyRows}
              originalPatient={originalPatient}
              setFromUserId={setFromUserId}
              hardRefresh={hardRefresh}
              currentPage={currentPage}
              totalCount={totalCount}
              limit={limit}
              skip={skip}
              setSkip={setSkip}
              setHasNext={setHasNext}
              fromCoordinators={fromCoordinators}
            />

            <CareGapsDrawerTabs
              value={tabIndex}
              switchTab={switchTab}
              patientInfo={patientInfo}
              setKeyPatients={setKeyPatients}
              setClickedFamilyRows={setClickedFamilyRows}
              originalPatientId={originalPatient.id}
              hardRefresh={hardRefresh}
              refresher={refresher}
              hasNext={hasNext}
              handleSwitchNextPatient={enhancedHandleSwitchNextPatient}
              disabledArrowForwardBtn={disabledArrowForwardBtn}
              drawerHandler={drawerHandler}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};
