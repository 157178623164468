import React from 'react';
import { Loading } from '../../../../../components/Loading';
import { useGetProviderPerformanceFilterOptionsQuery } from './index.generated';
import { ProviderPerformanceFilterView } from './View';

interface ProviderPerformanceFilterContainerProps {
  selectedPerformanceOptions: string[];
  setSelectedPerformanceOptions: (input: string[]) => void;
  applyFilterBtnIsClicked: boolean;
  setApplyFilterBtnIsClicked: (input: boolean) => void;
  loading: boolean;
  displayedCount: number;
}

export const ProviderPerformanceFilterContainer = (
  props: ProviderPerformanceFilterContainerProps
) => {
  const {
    selectedPerformanceOptions,
    setSelectedPerformanceOptions,
    applyFilterBtnIsClicked,
    setApplyFilterBtnIsClicked,
    loading,
    displayedCount,
  } = props;

  const providerPerformanceFilterOptionsQuery =
    useGetProviderPerformanceFilterOptionsQuery({
      variables: {},
    });

  if (
    !providerPerformanceFilterOptionsQuery.called ||
    providerPerformanceFilterOptionsQuery.loading
  ) {
    return <Loading />;
  }

  return (
    <ProviderPerformanceFilterView
      optionItems={
        providerPerformanceFilterOptionsQuery.data?.getCareGapFilterOptions
          ?.Performance ?? []
      }
      selectedPerformanceOptions={selectedPerformanceOptions}
      setSelectedPerformanceOptions={setSelectedPerformanceOptions}
      applyFilterBtnIsClicked={applyFilterBtnIsClicked}
      setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
      loading={loading}
      displayedCount={displayedCount}
    />
  );
};
