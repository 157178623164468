import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useHomePageHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    control: {
      margin: 0,
      padding: 0,
      boxShadow: 'none',
    },
    headerMainRow: {
      height: 88,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2.335, 3),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,

      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontSize: 32,
        fontWeight: theme.typography.fontWeightBold,
        height: 40,
        letterSpacing: 0,
        lineHeight: '40px',
      },

      '& #last-update-container': {
        marginLeft: 'auto',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'row',
      },

      '& #creation-button': {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        lineHeight: '24px',
        background: theme.palette.primary.main,
        borderRadius: 8,
        width: 125,
        height: 40,
        textAlign: 'center',
        padding: '0',

        '&:hover': {
          backgroundColor: '#1b8cf3',
        },
      },

      '& .MuiButton-label span': {
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0',
        textTransform: 'capitalize',
        marginLeft: '3.33px',
      },
    },
  })
);
