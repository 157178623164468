import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProfileItem } from '../../../../components/ProfileItem';
import { theme } from '../../../../themes/theme';
import { PatientsIcon } from '../../../../assets/Icons/Dashboard/PatientsIcon';
import { formatNumberPipe } from '../../../../sharePipe/formatNumberPipe';

interface Props {
  containerWidth: number;
  list: any[];
  listType: 'CARE_TEAM' | 'PCP';
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.secondary.dark,
    padding: theme.spacing(0, 2, 2, 2),
    height: '100%',
    marginTop: theme.spacing(4),
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div:last-child': {
      marginBottom: window.location.href.includes('dashboard') ? 0 : 40,
    },
  },
  userRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    fontSize: 14,

    '& p': {
      maxWidth: (props: Props) =>
        props.containerWidth ? Math.floor(props.containerWidth * 0.6) : 220,
    },
  },
  footer: {
    height: 99999,
    backgroundColor: 'red',
  },
  patientsIcon: {
    width: 24,
    height: 24,
    color: theme.palette.grey[500],
  },
  countAndIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
}));

function comparePCPPerformance(a: any, b: any) {
  if (a?.coveringPerformance > b?.coveringPerformance) {
    return -1;
  }
  if (a?.coveringPerformance < b?.coveringPerformance) {
    return 1;
  }
  if (a?.patientCount > b?.patientCount) {
    return -1;
  }
  if (a?.patientCount < b?.patientCount) {
    return 1;
  }

  return 0;
}

export const CardPCPListView = (props: Props) => {
  const classes = useStyles(props);
  const avatarPalette = theme.palette.avatars;
  const colors = Object.values(avatarPalette);

  const getPcpPerformanceStr = (pcp: any) => {
    if (pcp.coveringPerformance >= 0) {
      return `${pcp.coveringPerformance}%`;
    }
    if (pcp.performance >= 0) {
      return `${pcp.performance}%`;
    }
    return `--`;
  };

  const listComponent = React.useMemo(() => {
    const tmpList = [...props.list];
    return (
      <>
        {tmpList.sort(comparePCPPerformance).map((item: any, i: number) => {
          return (
            <div className={classes.userRow} key={i}>
              <ProfileItem
                avatarBgColor={colors[i % colors.length]}
                firstName={
                  item.user ? item.user?.firstName : item.firstName || ''
                }
                lastName={item.user ? item.user?.lastName : item.lastName || ''}
              />
              <div className={classes.countAndIconContainer}>
                {props.listType === 'PCP' && getPcpPerformanceStr(item)}
                {props.listType === 'CARE_TEAM' && (
                  <>
                    {formatNumberPipe(item.count, '0')}
                    <PatientsIcon className={classes.patientsIcon} />
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  }, [props.list, props.listType]);

  return (
    <div className={classes.root}>
      {listComponent}
      {/* <div className={classes.footer}/> */}
    </div>
  );
};
