import React from 'react';
import { usePatientPanelContext } from '../../LayoutBuilder/PatientPanelTabContext';

export type tabObjectType = {
  tabIndex: number;
  tabLabel: string;
  tabUrl: string;
  tabBadgeCount: number;
  disabled: boolean;
};

export const useTabLabel = (tabObjects: tabObjectType[], value: number) => {
  const { setTabLabel } = usePatientPanelContext();

  React.useEffect(() => {
    setTabLabel(tabObjects[value].tabLabel);
  }, [tabObjects, value]);
};
