import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Card, Chip, TableSortLabel, Tooltip, Typography} from "@material-ui/core";
import {theme} from "../../../../../themes/theme";
import {ProcessingIcon} from "../../../../../assets/Icons/FileUpload/ProcessingIcon";
import {useGetEligibilityCheckLogsQuery} from "../../index.generated";
import {LoadingSpinner} from "../../../../../components/LoadingSpinner";
import moment from 'moment'
import {BatchTab, EligibilityTab} from "./BatchTab";

enum CheckLogStatus {
  processing = "Processing",
  failed = "Eligibility Check Fail",
  completed = "Complete"
}

interface PatientEligibilityCheckLog {
  name: string,
  activeInternal: number | string | undefined,
  activeExternal: number | string  | undefined,
  rejected: number | string  | undefined,
  inactive: number | string  | undefined,
  unavailable: number | string  | undefined,
  checkDate: string,
  status: CheckLogStatus,
  statusDescription: string,
}

type ColumnID = 'name' | 'activeInternal' | 'activeExternal' | 'rejected' | 'inactive' | 'unavailable' | 'checkDate' | 'status'

interface Column {
  id: ColumnID;
  label: string;
  align?: 'right' | 'left';
}

const columns: Column[] = [
  { id: 'name', label: 'Name', align: 'left'},
  { id: 'activeInternal', label: 'Active (Internal)', align: 'left'},
  { id: 'activeExternal', label: 'Active (External)', align: 'left'},
  { id: 'inactive', label: 'Inactive',  align: 'left'},
  { id: 'rejected', label: 'Unverified',  align: 'left'},
  { id: 'unavailable', label: 'Unavailable',  align: 'left'},
  { id: 'checkDate', label: 'Check Date',  align: 'left'},
  { id: 'status', label: 'Status',  align: 'left'},
];

interface IProps {
  skip: number,
  limit: number,
  tab: EligibilityTab,
  setTab: (tab: EligibilityTab) => void,
  sortBy: { field: string; method: 'desc' | 'asc' };
  setSkip: (input: number) => void,
  setLimit: (input: number) => void,
  setSortBy: (input: any) => void;
}

const iClinicBatchCheck = "iClinic Batch Check";
const scheduledCheck = "Scheduled Check";

export default function EligibilityTable(props: IProps) {
  const {skip, limit, setLimit, setSkip, sortBy, setSortBy, tab, setTab} = props
  const classes = useStyles();

  const getEligibilityCheckLogsQuery = useGetEligibilityCheckLogsQuery({
    variables: {
     input: {
       skip: skip,
       limit: limit,
       logType: tab === EligibilityTab.iClinic ? iClinicBatchCheck : scheduledCheck,
       sortBy: [{ ...sortBy }],
     }
    }
  })


  if (!getEligibilityCheckLogsQuery.called || getEligibilityCheckLogsQuery.loading) {
    return <LoadingSpinner style={{width: '100%', padding: 80}}/>
  }

  const count = getEligibilityCheckLogsQuery!.data!.getEligibilityCheckLogs!.pagingInfo!.count ?? 0
  const dataSource = getEligibilityCheckLogsQuery!.data!.getEligibilityCheckLogs!.results!.map((item) => {
    const {
      activeInternal,
      activeExternal,
      rejected,
      inactive,
      unavailable,
      checkDate,
      status,
      logType,
    } = item
    let statusDescription = ""
    if (status === CheckLogStatus.failed) {
      statusDescription = "Eligibility Check Fail"
    } else if (status === CheckLogStatus.completed) {
      statusDescription = "Completed"
    } else if (status === CheckLogStatus.processing) {
      statusDescription = "Processing"
    }
    const batchCount = activeInternal + activeExternal + rejected + inactive + unavailable
    const logTypeName = `${batchCount ? batchCount.toLocaleString("en-US") + " " : ""}${logType}`
    const fUpdated = moment(new Date(checkDate)).format("MMMM D YYYY, HH:mm A")
    const log: PatientEligibilityCheckLog = {
      activeExternal: activeExternal?.toLocaleString("en-US"),
      activeInternal: activeInternal?.toLocaleString("en-US"),
      checkDate: fUpdated,
      inactive: inactive?.toLocaleString("en-US"),
      name: logTypeName,
      rejected: rejected?.toLocaleString("en-US"),
      unavailable: unavailable?.toLocaleString("en-US"),
      status: status as CheckLogStatus,
      statusDescription: statusDescription,
    }
    return log
  })


  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
  };

  const getStatusIcon = (status: CheckLogStatus) => {
    switch (status) {
      case CheckLogStatus.processing: {
        return <ProcessingIcon className={classes.sendingIcon} />;
      }
      default: {
        return <></>;
      }
    }
  }

  const getStyleByStatus = (status: CheckLogStatus) => {
    switch (status) {
      case CheckLogStatus.processing: {
        return classes.chipStatusSending
      }
      case CheckLogStatus.failed: {
        return classes.chipStatusFailed
      }
      case CheckLogStatus.completed:
        return classes.chipStatusDelivered
      default: {
        return classes.chipStatusSending;
      }
    }
  }

  const renderCellContentByColumnId = (cid: ColumnID, log: PatientEligibilityCheckLog) => {
    if (cid === 'name' || cid === 'checkDate') {
      const value = log[cid]
      return <Typography className={classes.cellText}>{value}</Typography>
    } else if (cid === 'activeExternal' || cid === 'activeInternal' ||
    cid === 'rejected' || cid === 'inactive' || cid === 'unavailable') {
      const value = log[cid]
      return <div className={classes.cellTextContainer}>
        <Typography className={classes.cellText}>{value ? value : '-'}</Typography>
      </div>
    } else if (cid === 'status') {
      const icon = getStatusIcon(log.status)
      const style = getStyleByStatus(log.status)
      return (
          log.status === CheckLogStatus.failed ?
          <Tooltip
              classes={{ tooltip: classes.toolTips }}
              title={'Please reach out to MDLand Customer Support for assistance.'}
              placement="bottom-end"
          >
            <Chip icon={icon} label={log.statusDescription} className={style}/>
          </Tooltip>
          : <Chip icon={icon} label={log.statusDescription} className={style}/>
      )
    }
  }

  const currentPage = Math.floor(skip / limit);
  return (
      <div className={classes.root}>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead >
              <TableRow className={classes.header}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.headerCell}
                    style={{ minWidth: 170 }}
                  >
                    <TableSortLabel
                      active={column.id === 'checkDate'}
                      hideSortIcon={column.id !== 'checkDate'}
                      direction={sortBy.method}
                      disabled={column.id !== 'checkDate'}
                      onClick={() => {
                        setSortBy({ field: 'checkDate', method: sortBy.method === "asc" ? 'desc' : 'asc'});
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource.map((row, idx) => {
                return (
                  <TableRow className={classes.row} hover role="checkbox" tabIndex={-1} key={idx}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align} className={classes.cell}>
                          {renderCellContentByColumnId(column.id, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="items per page"
          className={classes.pagination}
        />
      </div>
  );
}


const useStyles = makeStyles({
  root: {

  },
  container: {
    flexGrow: 1,
    borderRadius: 8,
    minWidth: 1152,
  },
  header: {
    backgroundColor: '#F8F8F8',
  },
  headerCell: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    color: '#888888',
  },
  row: {
    height: 56,
  },
  cell: {
    paddingLeft: 24,
  },
  cellText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500,
  },
  cellTextContainer: {

  },
  sendByContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center'
  },
  sendByShorNameContainer: {
    backgroundColor: '#F5CA32',
    borderRadius: 12,
    height: 24,
    width: 24,
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  sendByShorName: {
    fontSize: 10,
    lineHeight: '14px',
    color: '#FFF',
    padding: 0,
    fontWeight: 700
  },
  chip: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,

    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sendingIcon: {
    color: '#0C77D8',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  warningIcon: {
    color: '#C05314',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  failIcon: {
    color: '#B7373E',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  chipStatusSending: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDelivered: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#888888',
    fontWeight: 600,
    backgroundColor: '#EFEFEF',
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusDeliveredWithFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#C05314',
    fontWeight: 600,
    backgroundColor: '#FCF3E5',
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  chipStatusFailed: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#B7373E',
    fontWeight: 600,
    backgroundColor: '#FFEAEB',
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
      {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
    '& p[id]': {
      marginRight: theme.spacing(5.75),
    },
  },
  toolTips: {
    maxWidth: 300,
    padding: '4px 8px'
  }
})
