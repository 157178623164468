import * as Types from '../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type CreatePanelMutationVariables = Types.Exact<{
  input: Types.CreatePanelInput;
}>;


export type CreatePanelMutation = (
  { __typename: 'Mutation' }
  & { createPanel: (
    { __typename: 'OpenSearchTask' }
    & Pick<Types.OpenSearchTask, 'entityId' | 'taskId'>
  ) }
);

export type CheckPanelNameQueryVariables = Types.Exact<{
  input: Types.PanelNameCheckInput;
}>;


export type CheckPanelNameQuery = (
  { __typename: 'Query' }
  & { checkPanelName: (
    { __typename: 'PanelNameCheckResponse' }
    & Pick<Types.PanelNameCheckResponse, 'result'>
  ) }
);

export type GetTaskStatusQueryVariables = Types.Exact<{
  input: Types.TaskStatusQueryInput;
}>;


export type GetTaskStatusQuery = (
  { __typename: 'Query' }
  & { getTaskStatus: (
    { __typename: 'TaskStatusResponse' }
    & Pick<Types.TaskStatusResponse, 'status'>
  ) }
);


export const CreatePanelDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePanel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePanelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPanel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entityId"}},{"kind":"Field","name":{"kind":"Name","value":"taskId"}}]}}]}}]};
export type CreatePanelMutationFn = Apollo.MutationFunction<CreatePanelMutation, CreatePanelMutationVariables>;

/**
 * __useCreatePanelMutation__
 *
 * To run a mutation, you first call `useCreatePanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPanelMutation, { data, loading, error }] = useCreatePanelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePanelMutation(baseOptions?: Apollo.MutationHookOptions<CreatePanelMutation, CreatePanelMutationVariables>) {
        return Apollo.useMutation<CreatePanelMutation, CreatePanelMutationVariables>(CreatePanelDocument, baseOptions);
      }
export type CreatePanelMutationHookResult = ReturnType<typeof useCreatePanelMutation>;
export type CreatePanelMutationResult = Apollo.MutationResult<CreatePanelMutation>;
export type CreatePanelMutationOptions = Apollo.BaseMutationOptions<CreatePanelMutation, CreatePanelMutationVariables>;
export const CheckPanelNameDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CheckPanelName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PanelNameCheckInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkPanelName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}}]}}]};

/**
 * __useCheckPanelNameQuery__
 *
 * To run a query within a React component, call `useCheckPanelNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPanelNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPanelNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckPanelNameQuery(baseOptions: Apollo.QueryHookOptions<CheckPanelNameQuery, CheckPanelNameQueryVariables>) {
        return Apollo.useQuery<CheckPanelNameQuery, CheckPanelNameQueryVariables>(CheckPanelNameDocument, baseOptions);
      }
export function useCheckPanelNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPanelNameQuery, CheckPanelNameQueryVariables>) {
          return Apollo.useLazyQuery<CheckPanelNameQuery, CheckPanelNameQueryVariables>(CheckPanelNameDocument, baseOptions);
        }
export type CheckPanelNameQueryHookResult = ReturnType<typeof useCheckPanelNameQuery>;
export type CheckPanelNameLazyQueryHookResult = ReturnType<typeof useCheckPanelNameLazyQuery>;
export type CheckPanelNameQueryResult = Apollo.QueryResult<CheckPanelNameQuery, CheckPanelNameQueryVariables>;
export function refetchCheckPanelNameQuery(variables?: CheckPanelNameQueryVariables) {
      return { query: CheckPanelNameDocument, variables: variables }
    }
export const GetTaskStatusDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTaskStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TaskStatusQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTaskStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]};

/**
 * __useGetTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
        return Apollo.useQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
      }
export function useGetTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, baseOptions);
        }
export type GetTaskStatusQueryHookResult = ReturnType<typeof useGetTaskStatusQuery>;
export type GetTaskStatusLazyQueryHookResult = ReturnType<typeof useGetTaskStatusLazyQuery>;
export type GetTaskStatusQueryResult = Apollo.QueryResult<GetTaskStatusQuery, GetTaskStatusQueryVariables>;
export function refetchGetTaskStatusQuery(variables?: GetTaskStatusQueryVariables) {
      return { query: GetTaskStatusDocument, variables: variables }
    }