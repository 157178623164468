import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
}

export const AddIcon = (props: Props) => (
  <CustomSvgIcon
    {...props}
    path="M18,13 L13,13 L13,18 C13,18.55 12.55,19 12,19 C11.45,19 11,18.55 11,18 L11,13 L6,13 C5.45,13 5,12.55 5,12 C5,11.45 5.45,11 6,11 L11,11 L11,6 C11,5.45 11.45,5 12,5 C12.55,5 13,5.45 13,6 L13,11 L18,11 C18.55,11 19,11.45 19,12 C19,12.55 18.55,13 18,13 Z"
  />
);
