import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { BulletPlot } from '../../../../components/Charts/BulletPlot';
import { formatNumberPipe } from '../../../../sharePipe/formatNumberPipe';

function descendingComparator(a: any, b: any, orderBy: keyof any) {
  if (typeof a[orderBy] !== 'object') {
    return b[orderBy]
      .toString()
      .localeCompare(a[orderBy], 'en-US', { numeric: true });
  } else {
    try {
      if (b[orderBy].id < a[orderBy].id) {
        return -1;
      }
      if (b[orderBy].id > a[orderBy].id) {
        return 1;
      }
    } catch (e) {
      return 0;
    }
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof any;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'measureCode',
    numeric: false,
    disablePadding: true,
    label: 'Code',
  },
  {
    id: 'measureName',
    numeric: false,
    disablePadding: false,
    label: 'Measurement',
  },
  { id: 'eligible', numeric: false, disablePadding: false, label: 'Eligible' },
  {
    id: 'complaint',
    numeric: false,
    disablePadding: false,
    label: 'Complaint',
  },
  // {
  //   id: 'exclusive',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Exclusive',
  // },
  {
    id: 'nonComplaint',
    numeric: false,
    disablePadding: false,
    label: 'Non Complaint',
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: 'Progress',
  },
];

const nonsortableHeads = ['eligible', 'complaint', 'nonComplaint'];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const pickSortingHeaders = (headCell: HeadCell) => {
    if (nonsortableHeads.find((property) => property === headCell.id)) {
      return headCell.label;
    } else {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {/* checkbox column */}
        {/* <TableCell padding="checkbox">
          <StyledCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            checkedAll={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all patients' }}
            className={classes.checkbox}
          />
        </TableCell> */}
        {/* headers for each column */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {pickSortingHeaders(headCell)}
          </TableCell>
        ))}
        {/* placeholder for the progress column */}
        {/* <TableCell>Progress</TableCell> */}
      </TableRow>
    </TableHead>
  );
}

// const useToolbarStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(1),
//     },
//     highlight:
//       theme.palette.type === 'light'
//         ? {
//             color: theme.palette.secondary.main,
//             backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//           }
//         : {
//             color: theme.palette.text.primary,
//             backgroundColor: theme.palette.secondary.dark,
//           },
//     tableToolBarContainer: {
//       backgroundColor: theme.palette.grey[50],
//       width: '100%',
//       border: 'none',
//     },
//     header: {
//       height: 56,
//       borderRadius: '0px',
//       padding: theme.spacing(0),
//       margin: theme.spacing(0),
//       display: 'flex',
//       alignItems: 'center',
//       border: 'none',
//     },
//     title: {
//       display: 'inline-block',
//       fontSize: 20,
//       fontWeight: theme.typography.fontWeightBold,
//       lineHeight: 1.4,
//       height: 24,
//       color: theme.palette.text.primary,
//       paddingLeft: theme.spacing(3),
//     },
//     chip: {
//       marginLeft: theme.spacing(1),
//       padding: theme.spacing(0, 0.5),
//       height: 20,
//       background: theme.palette.grey[100],

//       '& .MuiChip-label': {
//         paddingLeft: theme.spacing(0.5),
//         paddingRight: theme.spacing(0.5),
//         fontWeight: 'bold',
//         fontSize: 12,
//         color: theme.palette.grey[500],
//       },
//     },
//     eligibilityTabsContainer: {
//       display: 'inline-block',
//       margin: theme.spacing(1.5, 3, 1.5, 'auto'),
//       height: 32,
//       borderRadius: 6,
//       border: '1px solid rgba(0, 0, 0, 0.12)',

//       '& .MuiTabs-root': {
//         borderBottom: 'none',
//       },

//       '& .MuiTabs-flexContainer': {
//         height: 32,
//       },

//       '& .MuiTab-root': {
//         fontSize: 14,
//         minHeight: 'fit-content',
//         lineHeight: 'normal',
//         textTransform: 'none',
//       },

//       '& button.MuiButtonBase-root': {
//         borderRight: `1px solid ${theme.palette.grey[300]}`,
//       },

//       '& .MuiTab-labelIcon': {
//         paddingTop: theme.spacing(0.75),
//       },

//       '& span.MuiTab-wrapper': {
//         flexDirection: 'row',
//       },

//       '& span.MuiTab-wrapper > *:first-child': {
//         marginRight: theme.spacing(0.5),
//         marginBottom: 0,
//       },
//     },
//     searchBanner: {},
//     search: {
//       position: 'relative',
//       borderRadius: theme.shape.borderRadius,
//       display: 'flex',

//       marginRight: theme.spacing(2),
//       marginLeft: 0,
//       width: '100%',
//       [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(3),
//         width: 'auto',
//       },
//     },
//     searchIcon: {
//       padding: theme.spacing(0, 2, 0, 0),
//       height: '100%',
//       position: 'absolute',
//       pointerEvents: 'none',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       color: theme.palette.grey[400],
//     },
//     inputRoot: {
//       color: 'inherit',
//     },
//     inputInput: {
//       fontSize: 16,
//       padding: theme.spacing(1, 1, 1, 0),
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
//       transition: theme.transitions.create('width'),
//       width: '100%',
//       [theme.breakpoints.up('md')]: {
//         width: '30ch',
//       },
//     },
//     filtersBtn: {
//       height: 'fit-content',
//       paddingTop: 0,
//       paddingBottom: 0,
//       border: 'none',
//       background: 'inherit',
//       display: 'flex',
//       margin: theme.spacing(1.5, 2, 1.5, 'auto'),
//       color: theme.palette.secondary.contrastText,
//       '&:hover': {
//         background: 'inherit',
//       },
//     },
//     filtersIcon: {
//       color: theme.palette.grey[500],
//       marginRight: theme.spacing(0.5),
//       fontSize: 14,
//     },
//     filtersText: {
//       fontSize: 14,
//     },
//     updatedTime: {
//       padding: theme.spacing(0, 0, 2.25, 3),
//     },
//     tabIconsNormal: {
//       width: 20,
//       height: 20,
//       display: 'inline-block',
//       color: theme.palette.grey[500],
//     },
//     tabIconsActive: {
//       width: 20,
//       height: 20,
//       display: 'inline-block',
//       color: theme.palette.primary.main,
//     },
//     tabTextActive: {
//       fontWeight: theme.typography.fontWeightBold,
//       color: '#000',
//     },
//     tabTextNormal: {
//       fontWeight: theme.typography.fontWeightBold,
//       color: theme.palette.grey[500],
//     },
//   })
// );

// interface EligibilityTabsProps {
//   value: number;
//   setValue: React.Dispatch<React.SetStateAction<number>>;
//   switchTab: (event: any, newValue: number) => void;
// }

// const EligibilityTabs = (props: EligibilityTabsProps) => {
//   const classes = useToolbarStyles();

//   return (
//     <Paper className={classes.eligibilityTabsContainer} elevation={0}>
//       <Tabs
//         value={props.value}
//         onChange={props.switchTab}
//         indicatorColor="primary"
//         textColor="primary"
//         centered
//         TabIndicatorProps={{
//           style: {
//             height: 0,
//           },
//         }}
//       >
//         <Tab
//           icon={
//             <AllIcon
//               className={
//                 props.value === 0
//                   ? classes.tabIconsActive
//                   : classes.tabIconsNormal
//               }
//             />
//           }
//           label={
//             <span
//               className={
//                 props.value === 0
//                   ? classes.tabTextActive
//                   : classes.tabTextNormal
//               }
//             >
//               {Eligibility.All}
//             </span>
//           }
//         />
//         <Tab
//           icon={
//             <EligibleIcon
//               className={
//                 props.value === 1
//                   ? classes.tabIconsActive
//                   : classes.tabIconsNormal
//               }
//             />
//           }
//           label={
//             <span
//               className={
//                 props.value === 1
//                   ? classes.tabTextActive
//                   : classes.tabTextNormal
//               }
//             >
//               {Eligibility.Eligible}
//             </span>
//           }
//         />
//         <Tab
//           icon={
//             <NeedsReviewIcon
//               className={
//                 props.value === 2
//                   ? classes.tabIconsActive
//                   : classes.tabIconsNormal
//               }
//             />
//           }
//           label={
//             <span
//               className={
//                 props.value === 2
//                   ? classes.tabTextActive
//                   : classes.tabTextNormal
//               }
//             >
//               {Eligibility.NeedsReview}
//             </span>
//           }
//         />
//         <Tab
//           icon={
//             <NewIcon
//               className={
//                 props.value === 3
//                   ? classes.tabIconsActive
//                   : classes.tabIconsNormal
//               }
//             />
//           }
//           label={
//             <span
//               className={
//                 props.value === 3
//                   ? classes.tabTextActive
//                   : classes.tabTextNormal
//               }
//             >
//               {Eligibility.New}
//             </span>
//           }
//         />
//         <Tab
//           icon={
//             <TerminationIcon
//               className={
//                 props.value === 4
//                   ? classes.tabIconsActive
//                   : classes.tabIconsNormal
//               }
//             />
//           }
//           label={
//             <span
//               className={
//                 props.value === 4
//                   ? classes.tabTextActive
//                   : classes.tabTextNormal
//               }
//             >
//               {Eligibility.Termination}
//             </span>
//           }
//         />
//       </Tabs>
//     </Paper>
//   );
// };

// const SearchBanner = () => {
//   const classes = useToolbarStyles();
//   return (
//     <div className={classes.search}>
//       <div className={classes.searchIcon}>
//         <SearchIcon />
//       </div>
//       <InputBase
//         placeholder="Search by patient ID or name"
//         classes={{
//           root: classes.inputRoot,
//           input: classes.inputInput,
//         }}
//         inputProps={{ 'aria-label': 'search' }}
//       />
//       <Button className={classes.filtersBtn}>
//         <FiltersIcon className={classes.filtersIcon} />
//         <Typography variant="body1" className={classes.filtersText}>
//           Filters
//         </Typography>
//       </Button>
//     </div>
//   );
// };

// interface EnhancedTableToolbarProps {
//   tableTitle: string;
//   hasSearchBanner: boolean;
//   hasEligibilityTabs: boolean;
//   hasUpdatedTime: boolean;
//   displayCount?: number;
//   numSelected?: number;
//   filter?: any;
//   value?: number;
//   setValue?: React.Dispatch<React.SetStateAction<number>>;
//   switchTab?: (event: any, newValue: number) => void;
//   updatedTime?: string;
// }

// const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
//   const classes = useToolbarStyles();
//   return (
//     <Paper className={classes.tableToolBarContainer} elevation={0}>
//       <div className={classes.header}>
//         {/* title */}
//         <Typography
//           className={classes.title}
//           variant="h4"
//           id="tableTitle"
//           component="div"
//         >
//           {props.tableTitle}
//         </Typography>
//         {/* count */}
//         {props.displayCount && (
//           <Chip className={classes.chip} label={props.displayCount} />
//         )}
//         {/* eligibility tabs */}
//         {/* {props.hasEligibilityTabs && (
//           <EligibilityTabs
//             value={props.value!}
//             setValue={props.setValue!}
//             switchTab={props.switchTab!}
//           />
//         )} */}
//       </div>
//       {/* search bar, filters button */}
//       {props.hasSearchBanner && (
//         <div className={classes.searchBanner}>
//           <SearchBanner />
//         </div>
//       )}
//       {/* updated time */}
//       {props.hasUpdatedTime && (
//         <div className={classes.updatedTime}>
//         </div>
//       )}
//     </Paper>
//   );
// };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    table: {
      minWidth: 750,
      border: 'none',
      // borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderCollapse: 'separate',

      '& .MuiTableHead-root': {
        boxShadow:
          '0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 3px 6px 0px rgb(0 0 0 / 8%)',
        background: theme.palette.grey[50],
      },

      '& > thead > tr > th': {
        paddingLeft: theme.spacing(3),
        padding: 0,
        height: 32,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: 'none',
      },

      '& > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 1.5),
      },

      '& > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
      },
      '& > tbody > tr > td': {
        padding: theme.spacing(2, 3),
      },

      '& > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
      '& > tbody > tr:last-child > td:first-child': {
        borderBottomLeftRadius: 8,
      },
      '& > tbody > tr:last-child > td:last-child': {
        borderBottomRightRadius: 8,
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    selectAll: {
      '& > .checkedIcon:before': {
        backgroundColor: 'red',
      },
    },
    commercialLine: {
      background: theme.palette.info.light,
      color: theme.palette.info.main,

      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    otherLines: {
      background: theme.palette.success.light,
      color: theme.palette.success.main,

      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    iconWraper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 44,
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: theme.spacing(1),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
        {
          order: -1,
          marginRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightBold,
        },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    careGapsNumUnderline: {
      display: 'inline-block',
      height: 22,
      lineHeight: '22px',
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
  })
);

interface CareGapMeasurementTableViewProps {
  enableRouting?: boolean;
  status?: string;
  setActionsFooter?: React.Dispatch<React.SetStateAction<number>>;
  currentTab?: string;
  filteredRows: any[];
}

export const CareGapMeasurementTableView = (
  props: CareGapMeasurementTableViewProps
) => {
  const classes = useStyles();
  const getSelectedStatusTabValue = () => {
    switch (props.status) {
      case 'active':
        return 1;
      case 'review':
        return 2;
      case 'new':
        return 3;
      case 'termination':
        return 4;
      default:
        return 0;
    }
  };

  const getSelectedStatusTabType = () => {
    switch (props.status) {
      case 'active':
        return;
      case 'review':
        return;
      case 'new':
        return;
      case 'termination':
        return;
      default:
        return;
    }
  };

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('measureCode');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredRows, setFilteredRows] = React.useState<any[]>(
    props.filteredRows
  );

  React.useEffect(() => {
    if (props.filteredRows) {
      setFilteredRows(props.filteredRows);
    }
  }, [props.filteredRows]);

  //   const { setActionsFooter } = props;
  //   React.useEffect(() => {
  //     setActionsFooter(selected.length);
  //   }, [selected, setActionsFooter]);

  const [value, setValue] = React.useState(
    props.enableRouting ? getSelectedStatusTabValue() : 0
  );
  const [filter, setFilter] = React.useState(
    props.enableRouting ? getSelectedStatusTabType() : 'All'
  );

  const switchTab = (event: any, newValue: number) => {
    setValue(newValue);
    setPage(0);
    // switch to different filters
    if (newValue === 0) {
      setFilter('All');
    }
    if (newValue === 1) {
      setFilter('Eligible');
    }
    if (newValue === 2) {
      setFilter('NeedsReview');
    }
    if (newValue === 3) {
      setFilter('New');
    }
    if (newValue === 4) {
      setFilter('Termination');
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  //   filter tabes if need
  //   if (filter === 'All') {
  //     filteredRows = fakePatientRow;
  //   } else {
  //     filteredRows = fakePatientRow.filter((row) => row.eligibility === filter);
  //   }

  // @ts-ignore
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* table header: title, count, eligibility tabs, search bar, filters button */}
        {/* {(props.currentTab === 'summary' && (
          <EnhancedTableToolbar
            tableTitle={'Recent'}
            hasSearchBanner={false}
            hasEligibilityTabs={false}
            hasUpdatedTime={true}
          />
        )) ||
          (props.currentTab === 'patients' && (
            <EnhancedTableToolbar
              tableTitle={filter}
              hasSearchBanner={true}
              hasEligibilityTabs={true}
              hasUpdatedTime={false}
              displayCount={filteredRows.length}
              filter={filter}
              value={value}
              setValue={setValue}
              switchTab={switchTab}
            />
          ))} */}
        {/* patients list table */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="cc-care-gaps-list-table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              // rowCount={filteredRows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.label)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      // selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <StyledCheckbox
                          checkedAll={false}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          className={classes.checkbox}
                        />
                      </TableCell> */}
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id}
                      </TableCell> */}
                      <TableCell align="left">{row.measureCode}</TableCell>
                      <TableCell align="left">{row.measureName}</TableCell>
                      <TableCell align="left">
                        <span className={classes.careGapsNumUnderline}>
                          {formatNumberPipe(row.eligible, '-')}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span className={classes.careGapsNumUnderline}>
                          {formatNumberPipe(row.complaint, '-')}
                        </span>
                      </TableCell>
                      {/* <TableCell align="left">
                        <span className={classes.careGapsNumUnderline}>
                          {row.exclusive.toLocaleString('en-US')}
                        </span>
                      </TableCell> */}
                      <TableCell align="left">
                        <span className={classes.careGapsNumUnderline}>
                          {formatNumberPipe(row.nonComplaint, '-')}
                        </span>
                      </TableCell>
                      {/* progress */}
                      <TableCell align="left">
                        {/* {row.progress === '0%' ? null : ( */}
                        <BulletPlot
                          code={row.measureCode ?? ''}
                          numEligible={row.eligible}
                          numComplaint={row.complaint}
                          numNonComplaint={row.nonComplaint}
                          progress={row.progress}
                          // numExclusive={row.exclusive}
                        />
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 56 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="items per page"
          className={classes.pagination}
          component="div"
        /> */}
      </Paper>
    </div>
  );
};
