
const insert = (arr: any[], index: number, newItem: any) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
]
export function GroupByParent(data: any[]): any[]{
    let temp: any[] = data;
    temp = temp.filter((item: any)=>item.parent=="");
    for(let item of data){
        if(item.parent!=""){
            const index = temp.findIndex(parent=>parent.value==item.parent);
            for (let i=index+1; i<data.length;i++){
                if(temp[i]==undefined || temp[i].parent==""){
                    temp = insert(temp, i, item);
                    break;
                }
            }
            //temp = insert(temp, index+1, item);
        }
        // else{
        //     if(data.filter((d: any)=>d.parent==item.value).length==0){
        //         temp = temp.filter((t:any)=>t!=item);
        //     }
        // }
    }
    return temp
}
