import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { useContext } from 'react';
import { useProfileOptionTabsStyles } from './Styles';
import { CheckIsHudsonClinician } from '../../../../components/CheckIsAdmin';
import { GlobalContext } from '../../../../components/GlobalContext';

interface ProfileOptionTabsViewProps {
  value: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  a11yProps: (index: number) => any;
}

export const ProfileOptionTabsView = (props: ProfileOptionTabsViewProps) => {
  const { loggedInUser: user } = useContext(GlobalContext);
  const { value, handleTabChange, a11yProps } = props;
  const classes = useProfileOptionTabsStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={1} component="div" className={classes.paper}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          // centered
          TabIndicatorProps={{
            style: {
              height: 0,
            },
          }}
        >
          {CheckIsHudsonClinician(user) ? null : (
            <Tab
              label={
                <span
                  className={
                    value === 0
                      ? `${classes.activeTab} ${classes.tabText}`
                      : `${classes.deactiveTab} ${classes.tabText}`
                  }
                >
                  Edit Profile
                </span>
              }
              {...a11yProps(value)}
              className={classes.tab}
            />
          )}
          <Tab
            label={
              <span
                className={
                  value === 1
                    ? `${classes.activeTab} ${classes.tabText}`
                    : `${classes.deactiveTab} ${classes.tabText}`
                }
              >
                Change Password
              </span>
            }
            {...a11yProps(value)}
            className={classes.tab}
          />
        </Tabs>
      </Paper>
    </div>
  );
};
