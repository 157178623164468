import React from 'react';
import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
    className?: string;
    style?: object;
}

export const AddCircleIcon = (props: Props) => (
    (<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.939453" width="24" height="24" rx="12" fill="white"/>
        <mask id="mask0_3900_11110"  maskUnits="userSpaceOnUse" x="0" y="0" width="25"
              height="25">
            <rect x="0.5" y="0.939453" width="24" height="24" rx="12" fill="white"/>
        </mask>
        <g mask="url(#mask0_3900_11110)">
            <rect x="0.5" y="0.939453" width="24" height="24" fill="#0C77D8"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.5 13.4395H13V15.9395C13 16.2145 12.775 16.4395 12.5 16.4395C12.225 16.4395 12 16.2145 12 15.9395V13.4395H9.5C9.225 13.4395 9 13.2145 9 12.9395C9 12.6645 9.225 12.4395 9.5 12.4395H12V9.93945C12 9.66445 12.225 9.43945 12.5 9.43945C12.775 9.43945 13 9.66445 13 9.93945V12.4395H15.5C15.775 12.4395 16 12.6645 16 12.9395C16 13.2145 15.775 13.4395 15.5 13.4395Z"
              fill="white"/>
    </svg>)
);
