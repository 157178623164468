import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SMeasureListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SMeasureListQuery = (
  { __typename: 'Query' }
  & { sMeasureList: Array<(
    { __typename: 'CareGapResponse' }
    & Pick<Types.CareGapResponse, 'measureCode' | 'measureName' | 'eligible' | 'complaint' | 'nonComplaint' | 'progress'>
  )> }
);


export const SMeasureListDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"sMeasureList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sMeasureList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measureCode"}},{"kind":"Field","name":{"kind":"Name","value":"measureName"}},{"kind":"Field","name":{"kind":"Name","value":"eligible"}},{"kind":"Field","name":{"kind":"Name","value":"complaint"}},{"kind":"Field","name":{"kind":"Name","value":"nonComplaint"}},{"kind":"Field","name":{"kind":"Name","value":"progress"}}]}}]}}]};

/**
 * __useSMeasureListQuery__
 *
 * To run a query within a React component, call `useSMeasureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSMeasureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSMeasureListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSMeasureListQuery(baseOptions?: Apollo.QueryHookOptions<SMeasureListQuery, SMeasureListQueryVariables>) {
        return Apollo.useQuery<SMeasureListQuery, SMeasureListQueryVariables>(SMeasureListDocument, baseOptions);
      }
export function useSMeasureListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SMeasureListQuery, SMeasureListQueryVariables>) {
          return Apollo.useLazyQuery<SMeasureListQuery, SMeasureListQueryVariables>(SMeasureListDocument, baseOptions);
        }
export type SMeasureListQueryHookResult = ReturnType<typeof useSMeasureListQuery>;
export type SMeasureListLazyQueryHookResult = ReturnType<typeof useSMeasureListLazyQuery>;
export type SMeasureListQueryResult = Apollo.QueryResult<SMeasureListQuery, SMeasureListQueryVariables>;
export function refetchSMeasureListQuery(variables?: SMeasureListQueryVariables) {
      return { query: SMeasureListDocument, variables: variables }
    }