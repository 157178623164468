import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  useCareGapsPanelStyles,
  useCollapseButtonStyles,
} from '../CareGapsDrawer/styles';
import { NavigateNextIcon } from '../../../../../../../assets/Icons/CareGaps';
import { CareGapsPanel } from '../CareGapsPanel';
import {
  CareGapExtendedStatusEnum,
  CareGapMeasureStatus,
} from '../../../../../../../enum/Dashboard/careGapMeasureStatus';
import { KeyboardArrowDown } from '@material-ui/icons';
import { CheckIcon } from '../../../../../../../assets/Icons/CreatePanel/CheckIcon';
import { useSetPatientCareGapExtendedStatusMutation } from './index.generated';
import { GlobalContext } from '../../../../../../../components/GlobalContext';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';
import { useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation } from '../CareGapsPanel/index.generated';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { CareGapContext } from '../../context';

interface CareGapsGroupViewProps {
  hardRefresh: () => void;
  careGaps: any[];
  mpid: string;
  refetchDataFunc: any;
  refetchDataFunc2: any;
  selectedYear: number;
  setSelectedYear: any;
  panelId: string;
  setOpenCareGapCount: any;
}

const StyleAccordion = withStyles({
  root: {
    width: '100%',
    margin: '0 0 16px 0',
    '&$expanded': {
      margin: '0 0 24px 0',
    },
  },
  expanded: {
    //
  },
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    // '&:hover': {
    //   background: theme.palette.grey[100],
    // },
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 8,
    minHeight: 28,
    maxHeight: 28,
  },
  content: {
    '&$expanded': {
      marginBottom: 0,
      marginTop: 0,
      minHeight: 28,
      maxHeight: 28,
    },
  },
  expanded: {},
})(AccordionSummary);

const careGapYearOption = { name: 'All Measure Years', value: 0 };

export const CareGapsGroupView = (props: CareGapsGroupViewProps) => {
  const { openCareGaps, setOpenCareGaps } = React.useContext(CareGapContext);
  const { careGaps, selectedYear, setSelectedYear, hardRefresh } = props;
  const { setIsOpenToast, setToastMessage } = React.useContext(GlobalContext);
  const [expandAll, setExpandAll] = React.useState(true);
  const [pendingCareGaps, setPendingCareGaps] = React.useState<any[]>([]);
  const [closedCareGaps, setClosedCareGaps] = React.useState<any[]>([]);
  const [isExpandOpenGaps, setIsExpandOpenGaps] = useState(true);
  const [isExpandClosedGaps, setIsExpandClosedGaps] = useState(true);
  const [isExpandPendingGaps, setIsExpandPendingGaps] = useState(true);
  const [items, setItems] = useState([careGapYearOption]);
  const [loading, setLoading] = React.useState(false);
  const classes = useCareGapsPanelStyles();

  const [
    setPatientCareGapExtendedStatus,
  ] = useSetPatientCareGapExtendedStatusMutation();

  const [
    setPatientCareGapExtendedStatusAndSaveCareAttempt,
  ] = useSetPatientCareGapExtendedStatusAndSaveCareAttemptMutation();
  const { id } = useParams();

  const onUpdatePatientCareGapExtendedStatus = async (
    notes: string,
    measure: string,
    measureEventDate: string,
    measureExtendedStatus: string,
    addCareAttempt?: boolean // if true, add care attempt, otherwise, don't add care attempt
  ) => {
    const input = {
      callAttempt: addCareAttempt ? 1 : 0,
      measure: measure,
      measureEventDate: measureEventDate,
      measureExtendedStatus: measureExtendedStatus,
      mpid: props.mpid,
      notes: notes,
    };
    setLoading(true);
    const { data } = await setPatientCareGapExtendedStatus({
      variables: { input },
    });
    setLoading(false);
    if (data?.setPatientCareGapExtendedStatus?.isSuccess) {
      setToastMessage({
        isOpen: true,
        severity: 'success',
        snackbarMsg: measure + ' is updated',
      });
      props.refetchDataFunc.func({
        input: {
          ...props.refetchDataFunc.params,
        },
      });
      props.refetchDataFunc2.func({
        input: {
          ...props.refetchDataFunc2.params,
        },
      });
    } else {
      setToastMessage({
        isOpen: true,
        severity: 'error',
        snackbarMsg: `Failed to update, please try again.`,
      });
    }
    setIsOpenToast(true);
    hardRefresh && hardRefresh();
  };

  React.useEffect(() => {
    const thisYear = new Date().getFullYear();
    let years: string[] = [];
    years.push(`${thisYear}`);
    if (items.length == 1) {
      setSelectedYear(thisYear);
    }
    props.careGaps.forEach((item: any) => {
      if (item.measureYear) {
        let year = `${item.measureYear}`;
        if (!years.find((item: string) => item == year)) {
          years.push(year);
        }
      }
    });
    let newItems = [careGapYearOption];
    years.forEach((year: string) => {
      let newOption = {
        name: 'Year ' + year,
        value: parseInt(year),
      };
      newItems.push(newOption);
    });
    setItems(newItems);
  }, [props.careGaps]);

  function compareCareGap(a: any, b: any) {
    if (a?.dueDate < b?.dueDate) {
      return -1;
    }
    return 0;
  }

  React.useEffect(() => {
    if (!careGaps.length) {
      setOpenCareGaps([]);
      setPendingCareGaps([]);
      setClosedCareGaps([]);
    }
    // set open and closed care gaps
    if (careGaps.length) {
      setOpenCareGaps(
        careGaps
          .filter(
            (careGap) =>
              careGap.measureStatusCategory === CareGapMeasureStatus.OPEN &&
              (selectedYear == 0 || careGap.measureYear == selectedYear)
          )
          .sort(compareCareGap)
      );
      setPendingCareGaps(
        careGaps
          .filter(
            (careGap) =>
              careGap.measureStatusCategory === CareGapMeasureStatus.PENDING &&
              (selectedYear == 0 || careGap.measureYear == selectedYear)
          )
          .sort(compareCareGap)
      );
      const assessedGaps = [];
      const closedManuallyGaps = [];
      const closedByPayerGaps = [];
      const totalClosedGaps = careGaps
        .filter(
          (careGap) =>
            careGap.measureStatusCategory === CareGapMeasureStatus.CLOSED &&
            (selectedYear == 0 || careGap.measureYear == selectedYear)
        )
        .sort(compareCareGap);
      for (let i = 0; i < totalClosedGaps.length; i++) {
        const gap = totalClosedGaps[i];
        if (
          gap.measureExtendedStatus ===
          CareGapExtendedStatusEnum.Assessed_And_Not_Applicable
        ) {
          assessedGaps.push(gap);
        } else if (
          gap.measureExtendedStatus ===
          CareGapExtendedStatusEnum.Closed_Manually
        ) {
          closedManuallyGaps.push(gap);
        } else {
          closedByPayerGaps.push(gap);
        }
      }
      setClosedCareGaps([
        ...assessedGaps,
        ...closedManuallyGaps,
        ...closedByPayerGaps,
      ]);
    }
  }, [selectedYear, JSON.stringify(props.careGaps)]);

  React.useEffect(() => {
    // toggle expandAll button
    setExpandAll(isExpandClosedGaps && isExpandOpenGaps);
  }, [isExpandOpenGaps, isExpandClosedGaps]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsExpandClosedGaps(!expandAll);
    setIsExpandOpenGaps(!expandAll);
    setIsExpandPendingGaps(!expandAll);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    event.preventDefault();
    let newValue = event.target.value;
    setSelectedYear(newValue);
  };

  if (!careGaps.length) {
    return (
      <div className={classes.root}>
        <Typography className={classes.noDataText}>No Care Gaps</Typography>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      role="presentation"
      // onClick={drawerHandler}
      // onKeyDown={drawerHandler}
    >
      <div className={classes.expandButtonContainer}>
        <CollapseButton expandAll={expandAll} handleClick={handleClick} />
        <Select
          variant="outlined"
          displayEmpty
          IconComponent={KeyboardArrowDown}
          className={classes.select}
          value={selectedYear}
          onChange={handleChange}
          renderValue={(selected: any) => {
            const item = items.find((item: any) => item.value == selected);
            return <span>{item?.name || selected}</span>;
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            elevation: 2,
            getContentAnchorEl: null,
            style: {
              maxHeight: 68 * 5 + 8,
              width: 178,
              maxWidth: '100%',
            },
            PaperProps: {
              style: {
                // reduce space between input and menu
                minWidth: 192,
                marginTop: -9,
                paddingLeft: 8,
                paddingRight: 7,
                borderRadius: 8,
              },
            },
          }}
        >
          {items.map((item, idx) => (
            <MenuItem
              key={idx}
              value={item.value}
              classes={{
                selected: classes.selected,
              }}
            >
              <div className={classes.selectMenuContent}>{item.name}</div>
              {selectedYear == item.value && (
                <CheckIcon
                  style={{
                    height: 25,
                    position: 'absolute',
                    right: '15px',
                  }}
                />
              )}
            </MenuItem>
          ))}
        </Select>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <StyleAccordion
            key={`open-index-open`}
            elevation={0}
            expanded={isExpandOpenGaps}
          >
            {/*open*/}
            <StyledAccordionSummary
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className={classes.titleContainer}>
                <div
                  className={classes.titleTextContainer}
                  onClick={(event) => {
                    setIsExpandOpenGaps(!isExpandOpenGaps);
                  }}
                >
                  <Typography className={classes.titleText}>
                    Open Care Gaps
                  </Typography>
                  <NavigateNextIcon
                    className={
                      isExpandOpenGaps
                        ? classes.navigateNext
                        : classes.rotateNavigateNext
                    }
                  />
                </div>
                <Typography className={classes.countText}>
                  {`${openCareGaps.length} Care Gaps`}
                </Typography>
              </div>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.panelItems}>
              <CareGapsPanel
                filteredItems={openCareGaps}
                updateCareGapStatus={onUpdatePatientCareGapExtendedStatus}
              />
            </AccordionDetails>
          </StyleAccordion>
          {/*pending*/}
          <StyleAccordion
            key={`pending-index-pending`}
            elevation={0}
            expanded={isExpandPendingGaps}
          >
            <StyledAccordionSummary
              aria-controls="panel-content"
              id="panel-header"
              onClick={(event) => {
                setIsExpandPendingGaps(!isExpandPendingGaps);
              }}
            >
              <div className={classes.titleContainer}>
                <div className={classes.titleTextContainer}>
                  <Typography className={classes.titleText}>Pending</Typography>
                  <NavigateNextIcon
                    className={
                      isExpandPendingGaps
                        ? classes.navigateNext
                        : classes.rotateNavigateNext
                    }
                  />
                </div>
                <Typography className={classes.countText}>
                  {`${pendingCareGaps.length} Care Gaps`}
                </Typography>
              </div>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.panelItems}>
              <CareGapsPanel
                filteredItems={pendingCareGaps}
                updateCareGapStatus={onUpdatePatientCareGapExtendedStatus}
              />
            </AccordionDetails>
          </StyleAccordion>
          {/*closed*/}
          <StyleAccordion
            key={`close-index-closed`}
            elevation={0}
            expanded={isExpandClosedGaps}
          >
            <StyledAccordionSummary
              aria-controls="panel-content"
              id="panel-header"
              onClick={(event) => {
                setIsExpandClosedGaps(!isExpandClosedGaps);
              }}
            >
              <div className={classes.titleContainer}>
                <div className={classes.titleTextContainer}>
                  <Typography className={classes.titleText}>Closed</Typography>
                  <NavigateNextIcon
                    className={
                      isExpandClosedGaps
                        ? classes.navigateNext
                        : classes.rotateNavigateNext
                    }
                  />
                </div>
                <Typography className={classes.countText}>
                  {`${closedCareGaps.length} Care Gaps`}
                </Typography>
              </div>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.panelItems}>
              <CareGapsPanel
                filteredItems={closedCareGaps}
                updateCareGapStatus={onUpdatePatientCareGapExtendedStatus}
              />
            </AccordionDetails>
          </StyleAccordion>
        </>
      )}
    </div>
  );
};

// - collapse / expand button
interface CollapseButtonProps {
  expandAll: boolean;
  handleClick: (event: any) => any;
}

const CollapseButton = (props: CollapseButtonProps) => {
  const { expandAll, handleClick } = props;
  const classes = useCollapseButtonStyles();

  return (
    <Button
      className={classes.root}
      variant="text"
      onClick={handleClick}
      endIcon={
        <NavigateNextIcon
          className={
            expandAll
              ? `${classes.navigateNext} ${classes.rotateNavigateNext}`
              : `${classes.navigateNext}`
          }
        />
      }
    >
      <Typography className={classes.text}>
        {expandAll ? 'Collapse All' : 'Expand All'}
      </Typography>
    </Button>
  );
};
