import React from 'react';
import { makeStyles, Snackbar, Theme } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ToastMessageType } from './Type';
import { CheckIcon } from '../../assets/Icons/CreatePanel/CheckIcon';
import { ErrorIcon } from '../../assets/Icons/CreatePanel/ErrorIcon';

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      icon={<CheckIcon style={{ height: 24, width: 24 }} />}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiAlert-action .MuiIconButton-label': {
      opacity: 0.5,
    },
    '& div[role="alert"]': {
      borderRadius: 8,
    },

    '& div.MuiAlert-message': {
      fontSize: 14,
    },
  },
}));

interface ToasterViewProps {
  open: boolean;
  setOpen: (input: boolean) => void;
  alertMessage: ToastMessageType;
}

export const ToasterView = (props: ToasterViewProps) => {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'click_away') {
      return;
    }
    props.setOpen(false);
  };

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          icon={
            props.alertMessage.severity === 'error' ? (
              <ErrorIcon style={{ height: 24, width: 24 }} />
            ) : (
              <CheckIcon style={{ height: 24, width: 24 }} />
            )
          }
          severity={props.alertMessage.severity}
        >
          {props.alertMessage.snackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
