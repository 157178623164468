import React from 'react';
import { useGetPatientFaceInfoQuery } from './index.generated';
import {
  CareTeamMember,
  PanelCoordinator,
  PanelPatient,
  PatientFaceInfo,
} from '../../../../../../../app.types.generated';
import { CareGapsDrawerView } from './View';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { CareGapContext } from '../../context';

export type PatientFaceSheet = PatientFaceInfo & { caregapCount: number };
interface CareGapsDrawerContainerProps {
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => void;
  checkedPatient: PanelPatient;
  allPatients: PanelPatient[];
  tabIndex: number;
  switchTab: (event: React.MouseEvent, newValue: number) => void;
  setEnableChangeCoordinatorDialog?: (input: boolean) => void;
  setFromUserId?: (input: string) => void;
  panelId?: any;
  currentPage: number;
  totalCount: number;
  limit: number;
  skip: number;
  setSkip: (input: number) => void;
  fromCoordinators?: CareTeamMember[] | null | undefined;
  setPatientFaceInfo?: any;
  refresher: any;
  setRefresher: any;
  keyPatients: KeyPatientsType;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
}

export const CareGapsDrawerContainer = (
  props: CareGapsDrawerContainerProps
) => {
  const { setDeListPatientState, setSwitchToNextPatient } = React.useContext(
    CareGapContext
  );
  const { refresher, setRefresher, keyPatients } = props;
  const [patientInfo, setPatientInfo] = React.useState({} as PatientFaceSheet);
  const [loading, setLoading] = React.useState(false);
  function hardRefresh() {
    patientFaceInfo.refetch();
    let newRefresher = refresher + 1;
    setRefresher(newRefresher);
  }

  React.useEffect(() => {
    patientFaceInfo.refetch();
  }, [refresher]);

  const inputs = keyPatients.pre
    ? {
        mpatientId: keyPatients.cur.id ?? '',
        preMpatientId: keyPatients.pre.id,
      }
    : {
        mpatientId: keyPatients.cur.id ?? '',
      };

  const patientFaceInfo = useGetPatientFaceInfoQuery({
    variables: inputs,
    skip: inputs.mpatientId === '',
  });

  React.useEffect(() => {
    if (!patientFaceInfo.called || patientFaceInfo.loading) {
      setLoading(true);
    } else if (
      patientFaceInfo.data &&
      patientFaceInfo.data.getPatientFaceInfo
    ) {
      setPatientInfo(
        patientFaceInfo.data!.getPatientFaceInfo as PatientFaceSheet
      );

      if (props.panelId) {
        const currentPanelCoordinator = patientFaceInfo!.data!.getPatientFaceInfo!.panels?.find(
          (panel: PanelCoordinator) => panel.panelId === props.panelId
        );
        // console.log(currentPanelCoordinator);
        if (currentPanelCoordinator?.coordinatorId && props.setFromUserId) {
          props.setFromUserId(currentPanelCoordinator?.coordinatorId);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      setPatientInfo({} as PatientFaceSheet);
    }
  }, [patientFaceInfo]);

  React.useEffect(() => {
    if (props.setPatientFaceInfo) {
      props.setPatientFaceInfo(patientInfo);
    }
  }, [patientInfo]);

  React.useEffect(() => {
    if (setDeListPatientState) {
      setDeListPatientState((prevState) => ({
        ...prevState,
        patientDeListedFromCurrentPanel: false,
      }));
    }
  }, [patientInfo, setDeListPatientState]);

  React.useEffect(() => {
    if (setSwitchToNextPatient) {
      setSwitchToNextPatient(false);
    }
  }, [patientInfo, setSwitchToNextPatient]);

  return (
    <div>
      <CareGapsDrawerView
        patientInfo={{
          ...patientInfo,
          caregapCount: keyPatients.cur.caregapCount || 0,
        }}
        loading={loading}
        originalPatient={keyPatients.ori}
        hardRefresh={hardRefresh}
        currentPatient={keyPatients.cur}
        {...props}
      />
    </div>
  );
};
