import React  from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { useFetchTableData } from '../../../../hooks/useFetchTableData';
import { PayerListingTableView } from './PayerListingTable';
import { makeStyles } from '@material-ui/core/styles';
import { useGetPayersQuery } from '../Files/PayerList/index.generated';


type ItemListTableContainerProps = {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
};
export const PayerIdListingView = (props: ItemListTableContainerProps) => {
  const [sortBy, setSortBy] = React.useState({
    field: 'payerName',
    method: 'asc' as const,
  });

  const resp = useGetPayersQuery({
    variables: {
      request: {
        insuranceName: '',
        skip: props.skip ?? 0,
        limit: props.limit ?? 10,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  const queryResult = useFetchTableData(resp);

  if (!resp.called || resp.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
  }

  return (
    <div style={{ width: '100%' }}>
      <PayerListingTableView
        refetchDataFunc={{
          func: resp.refetch,
          params: {
            insuranceName: '',
            skip: props.skip ?? 0,
            limit: props.limit ?? 10,
            sortBy: [{ ...sortBy }],
          },
        }}
        sortBy={{ ...sortBy }}
        setSortBy={setSortBy}
        results={queryResult?.getPayers?.results ?? []}
        count={queryResult?.getPayers?.count ?? 0}
        limit={props.limit ?? 10}
        skip={props.skip ?? 0}
        setLimit={props.setLimit}
        setSkip={props.setSkip}
      />
    </div>
  );
};
