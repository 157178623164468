import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const NotesIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M19 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H15L21 15V5C21 3.895 20.105 3 19 3ZM8 7H16C16.552 7 17 7.448 17 8C17 8.552 16.552 9 16 9H8C7.448 9 7 8.552 7 8C7 7.448 7.448 7 8 7ZM11 13H8C7.448 13 7 12.552 7 12C7 11.448 7.448 11 8 11H11C11.552 11 12 11.448 12 12C12 12.552 11.552 13 11 13ZM14 19.5V14H19.5L14 19.5Z"
    />
  );
};
