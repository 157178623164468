import React from 'react';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { RoleType } from '../../../../../enum/Users';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { createHeaderCell } from '../../../../../sharePipe/createHeaderCellPipe';
import { useGetProviderPerformanceListsQuery } from './index.generated';
import { ProviderListTableView } from './View';

interface ProviderListTableContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  setSelectedPerformanceOptions: (input: string[]) => void;
  selectedPerformanceOptions: string[];
  tableType: string;
  setLoading: (input: boolean) => void;
  setDisplayedCount: (input: number) => void;
}

export const ProviderListTableContainer = (
  props: ProviderListTableContainerProps
) => {
  const {
    limit,
    skip,
    setLimit,
    setSkip,
    setSelectedPerformanceOptions,
    selectedPerformanceOptions,
    tableType,
    setLoading,
    setDisplayedCount,
  } = props;

  const getTableHeader = (tableType: string) => {
    switch (tableType) {
      case RoleType.MSO_ADMIN: {
        return [
          {
            id: 'fullName',
            align: 'left' as const,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'title',
            align: 'left' as const,
            disablePadding: false,
            label: 'Title',
          },
          {
            id: 'email',
            align: 'left' as const,
            disablePadding: false,
            label: 'Email',
          },
          {
            id: 'workPhone',
            align: 'left' as const,
            disablePadding: false,
            label: 'Primary Phone',
          },
          {
            id: 'coveringCount',
            align: 'left' as const,
            disablePadding: false,
            label: 'Patients',
          },
          {
            id: 'coveringPerformance',
            align: 'left' as const,
            disablePadding: false,
            label: 'Performance',
          },
        ].map((item) =>
          createHeaderCell(item.id, item.label, item.disablePadding, item.align)
        );
      }
      case RoleType.CARE_COORDINATOR: {
        return [
          {
            id: 'fullName',
            align: 'left' as const,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'title',
            align: 'left' as const,
            disablePadding: false,
            label: 'Title',
          },
          {
            id: 'email',
            align: 'left' as const,
            disablePadding: false,
            label: 'Email',
          },
          {
            id: 'workPhone',
            align: 'left' as const,
            disablePadding: false,
            label: 'Primary Phone',
          },
          {
            id: 'coveringPerformance',
            align: 'left' as const,
            disablePadding: false,
            label: 'Performance',
          },
        ].map((item) =>
          createHeaderCell(item.id, item.label, item.disablePadding, item.align)
        );
      }
      default: {
        return [
          {
            id: 'fullName',
            align: 'left' as const,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'title',
            align: 'left' as const,
            disablePadding: false,
            label: 'Title',
          },
          {
            id: 'email',
            align: 'left' as const,
            disablePadding: false,
            label: 'Email',
          },
          {
            id: 'workPhone',
            align: 'left' as const,
            disablePadding: false,
            label: 'Primary Phone',
          },
          {
            id: 'coveringCount',
            align: 'left' as const,
            disablePadding: false,
            label: 'Patients',
          },
          {
            id: 'coveringPerformance',
            align: 'left' as const,
            disablePadding: false,
            label: 'Performance',
          },
        ].map((item) =>
          createHeaderCell(item.id, item.label, item.disablePadding, item.align)
        );
      }
    }
  };

  React.useEffect(() => {
    setSkip(0);
  }, [selectedPerformanceOptions, limit]);

  const [sortBy, setSortBy] = React.useState({
    field: 'coveringPerformance',
    method: 'desc' as const,
  });

  const providersQuery = useGetProviderPerformanceListsQuery({
    variables: {
      input: {
        limit: limit ?? 10,
        skip: skip ?? 0,
        performance: selectedPerformanceOptions,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  React.useEffect(() => {
    if (!providersQuery.called || providersQuery.loading) {
      setLoading(true);
    } else {
      setLoading(false);
      setDisplayedCount(providersQuery.data?.pcps?.count ?? 0);
    }
  }, [providersQuery]);

  const queryResult = useFetchTableData(providersQuery);

  if (!providersQuery.called || providersQuery.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 335px)` }} />;
  }

  return (
    <ProviderListTableView
      refetchDataFunc={{
        func: providersQuery.refetch,
        params: {
          limit: limit ?? 10,
          skip: skip ?? 0,
          performance: selectedPerformanceOptions,
          sortBy: [{ ...sortBy }],
        },
      }}
      sortBy={{ ...sortBy }}
      setSortBy={setSortBy}
      providerRows={queryResult ? queryResult.pcps.result : []}
      limit={limit ?? 10}
      skip={skip ?? 0}
      setLimit={setLimit}
      setSkip={setSkip}
      count={queryResult ? queryResult.pcps?.count : 0}
      setSelectedPerformanceOptions={setSelectedPerformanceOptions}
      selectedPerformanceOptions={selectedPerformanceOptions}
      tableType={tableType}
      headerCells={getTableHeader(tableType)}
    />
  );
};
