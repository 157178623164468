import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    makeStyles, RadioGroup
} from "@material-ui/core";
import {StyledRadio} from "../../../../../../../components/StyledRadio";
import {
    OutComeOfCallsActionType
} from "../CareGapDrawerHeader/components/CareCoordination/Dialogs/OutComeOfCallsDialog";
interface Props{
    updateStatus: any;
    setOpen: any;
    onClickCancel: any;
}
const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        '& .MuiDialogTitle-root':{
            padding: '20px 24px',
            borderBottom: '1px solid #d8d8d8'
        },
        '& .MuiDialog-paperWidthSm': {
            width: 512,
            maxWidth: '80%',
            borderRadius: 8,
        },
        '& .MuiTypography-body1': {
            color: '#656565!important',
            fontSize: 16,
        },
        '& .MuiTypography-root.MuiTypography-h6': {
            color: '#434343',
            fontSize: 24,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            lineHeight: '32px'
        },
    },
    content:{
      paddingTop: 16,
        paddingBottom: 24
    },
    subText: {
        fontWeight: 700,
        color: '#434343',
        fontSize: 14,
        lineHeight: '24px'
    },
    radioGroupContainer:{
        marginLeft: 6,
        gap: theme.spacing(3),
    },
    formControlLabel: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
        color: '#656565',
        '& .MuiTypography-body1': {
            marginLeft: 12,
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '24px',
            color: '#656565',
        },
    },
    radio: {
        padding: 4,
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
        },
        '&$checked': {
            color: '#0C77D8',
        },
    },
    checked: {},
    editBtn: {
        width: 86,
        height: 40,
        background: theme.palette.grey[200],

        color: theme.palette.grey[600],
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.5,

        textTransform: 'capitalize',

        '&:hover': {
            color: theme.palette.grey[700],
            background: theme.palette.grey[100],
        },
    },
    createNowBtn: {
        textTransform: 'capitalize',
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        lineHeight: 1.5,

        background: theme.palette.primary.main,
        borderRadius: 8,
        width: 96,
        height: 40,
        textAlign: 'center',
        padding: theme.spacing(0),

        '&:hover': {
            backgroundColor: '#1b8cf3',
        },

        '&:disabled': {
            color: 'rgba(255, 255, 255, 0.6)',
            background: theme.palette.primary.main,
        },
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '8px 24px',
    },
    divider: {
        height: 1,
        background: '#d8d8d8',
        marginTop: theme.spacing(2),
    },
}));
export const GetSupportingDocumentConfirmPopUp = (props: Props) =>{
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    return (
        <Dialog
            className={classes.dialogContainer}
            open={true}
            onClose={() => {
                props.onClickCancel();
            }}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {'Confirm Get Supporting Document'}
            </DialogTitle>
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.subText}>
                        Did you call to specialty?<br/>Please select yes or no to confirm Update Care Gap Status.
                    </div>
                </div>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={value}
                    name="radio-buttons-group"
                    className={classes.radioGroupContainer}
                >
                    <FormControlLabel
                        value={0}
                        control={
                            <StyledRadio
                                checked={
                                    value==0
                                }
                                onChange={() => {
                                    setValue(0)
                                }}
                                classes={{ root: classes.radio, checked: classes.checked }}
                            />
                        }
                        label="Yes, I contacted Specialty Clinic."
                        className={classes.formControlLabel}
                    />
                    <FormControlLabel
                        value={1}
                        control={
                            <StyledRadio
                                checked={
                                    value==1
                                }
                                onChange={() => {
                                    setValue(1)
                                }}
                                classes={{ root: classes.radio, checked: classes.checked }}
                            />
                        }
                        label="No, I want to change status only."
                        className={classes.formControlLabel}
                    />
                </RadioGroup>
            </DialogContent>
            <div className={classes.divider} />
            <DialogActions>
                <div className={classes.buttonRow}>
                    <Button
                        autoFocus
                        onClick={() => {
                            props.onClickCancel();
                        }}
                        className={classes.editBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={()=>{props.updateStatus(value==0)}}
                        className={classes.createNowBtn}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};