import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetNotificationsQueryVariables = Types.Exact<{
  input: Types.NotificationMessageQueryInput;
}>;


export type GetNotificationsQuery = (
  { __typename: 'Query' }
  & { getNotifications?: Types.Maybe<(
    { __typename: 'NotificationMessageResponse' }
    & { result: Array<(
      { __typename: 'NotificationMessage' }
      & Pick<Types.NotificationMessage, 'id' | 'notificationType' | 'notificationName' | 'createdAt'>
      & { content: (
        { __typename: 'NotificationMessageTypeContentPanel' }
        & Pick<Types.NotificationMessageTypeContentPanel, 'panelId' | 'panelName' | 'patientCount'>
      ), createdBy: (
        { __typename: 'NotificationUser' }
        & Pick<Types.NotificationUser, 'userId' | 'firstName' | 'lastName'>
      ), assignee: (
        { __typename: 'NotificationUser' }
        & Pick<Types.NotificationUser, 'userId'>
      ) }
    )> }
  )> }
);

export type ClearNotificationMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationMessageStatusInput;
}>;


export type ClearNotificationMutation = (
  { __typename: 'Mutation' }
  & { clearNotificationMessages: (
    { __typename: 'UpdateNotificationMessageStatusResponse' }
    & Pick<Types.UpdateNotificationMessageStatusResponse, 'isSuccess'>
  ) }
);

export type ReadNotificationMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationMessageStatusInput;
}>;


export type ReadNotificationMutation = (
  { __typename: 'Mutation' }
  & { readNotificationMessages: (
    { __typename: 'UpdateNotificationMessageStatusResponse' }
    & Pick<Types.UpdateNotificationMessageStatusResponse, 'isSuccess'>
  ) }
);


export const GetNotificationsDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getNotifications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationMessageQueryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNotifications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"notificationType"}},{"kind":"Field","name":{"kind":"Name","value":"notificationName"}},{"kind":"Field","name":{"kind":"Name","value":"content"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"panelId"}},{"kind":"Field","name":{"kind":"Name","value":"panelName"}},{"kind":"Field","name":{"kind":"Name","value":"patientCount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"assignee"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]}}]};

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: GetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const ClearNotificationDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"clearNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateNotificationMessageStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clearNotificationMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type ClearNotificationMutationFn = Apollo.MutationFunction<ClearNotificationMutation, ClearNotificationMutationVariables>;

/**
 * __useClearNotificationMutation__
 *
 * To run a mutation, you first call `useClearNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearNotificationMutation, { data, loading, error }] = useClearNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ClearNotificationMutation, ClearNotificationMutationVariables>) {
        return Apollo.useMutation<ClearNotificationMutation, ClearNotificationMutationVariables>(ClearNotificationDocument, baseOptions);
      }
export type ClearNotificationMutationHookResult = ReturnType<typeof useClearNotificationMutation>;
export type ClearNotificationMutationResult = Apollo.MutationResult<ClearNotificationMutation>;
export type ClearNotificationMutationOptions = Apollo.BaseMutationOptions<ClearNotificationMutation, ClearNotificationMutationVariables>;
export const ReadNotificationDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"readNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateNotificationMessageStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readNotificationMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, baseOptions);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;