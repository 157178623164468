import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import { MainRegion } from '../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../components/BreadCrumbHeader';
import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { NewPayerListTable } from '../PayerList/NewPayerListTable';
import { useGetPayerFileByIdQuery } from '../index.generated';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const FileNewPayerListView = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {});

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  const [sortBy, setSortBy] = React.useState({
    field: 'payerName',
    method: 'asc' as const,
  });

  const resp = useGetPayerFileByIdQuery({
    variables: {
      request: {
        fileId: id ?? '',
      },
    },
  });

  const queryResult = useFetchTableData(resp);

  React.useEffect(() => {
    try {
      setTitle(queryResult?.getPayerFileById?.fileName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [queryResult]);

  if (!resp.called || resp.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />;
  }

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={title}
          rootLink={`/payerFiles`}
          rootName={'Payer Files'}
          isTitle={true}
          childrenLink={`/payerFiles`}
          title={title}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <NewPayerListTable
                setSortBy={setSortBy}
                sortBy={sortBy}
                fileId={id}
                rows={queryResult?.getPayerFileById?.payers ?? []}
                count={queryResult?.getPayerFileById?.payers.length ?? 0}
                limit={limit ?? 10}
                skip={skip ?? 0}
                setLimit={onSetLimit}
                setSkip={onSetSkip}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
