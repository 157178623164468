import {
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ErrorScreenIcon } from '../../assets/Icons/ErrorScreens';
import { UndrawIcon } from '../../assets/Icons/PowerBI';

const useStyles = makeStyles((theme) => ({
  description: {
    paddingLeft: '8px',

    '& > span': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: '14px',
      lineHeight: '24px',
    },

    '&::before': {
      content: '"•"',
      display: 'inline-block',
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '10px',
      left: '-6px',
      fontSize: '20px',
    },
  },
  email: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  retryButton: {
    color: '#FFF',
    background: '#0C77D8',
    borderRadius: 8,
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(5),
  },
  errorTitle: {
    fontSize: 32,
    lineHeight: '40px',
    fontWeight: 700,
  },
  errorContent: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '24px',
    color: '#656565',
    fontWeight: 500,
  },
  errorMessage: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '40px',
    textAlign: 'center',
  },
  listSubheader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  },
  listItemPadding: {
    padding: '8px 0px 0px 0px',
  },
}));

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '420px',
  height: 'fit-content',
  padding: '40px',
  borderRadius: '8px',
  background: theme.palette.background.paper,
  boxShadow:
    '0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
}));

const StyledList = styled(List)(({ theme }) => ({
  paddingTop: '8px',
  paddingBottom: '8px',
}));

export const PowerBIUnavailableError: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <StyledContainer>
        <UndrawIcon />
        <Typography variant="h4" className={classes.errorMessage}>
          Dashboard is unavailable at the moment.
        </Typography>
        <StyledPaper>
          <StyledList
            subheader={
              <Typography className={classes.listSubheader}>
                Why I can't see the dashboard?
              </Typography>
            }
          >
            <ListItem className={classes.listItemPadding}>
              <ListItemText
                className={classes.description}
                primary={
                  <>
                    Missing provider's <strong>NPI</strong> number
                  </>
                }
              />
            </ListItem>
            <ListItem className={classes.listItemPadding}>
              <ListItemText
                className={classes.description}
                primary={
                  <>
                    Missing <strong>ClinicID</strong>
                  </>
                }
              />
            </ListItem>
            <ListItem className={classes.listItemPadding}>
              <ListItemText
                className={classes.description}
                primary={
                  <>
                    We are working on the data issue. Please email MDLand
                    Customer Support for assistance:{' '}
                    <a
                      href="mailto:support@mdland.com"
                      className={classes.email}
                    >
                      support@mdland.com
                    </a>
                  </>
                }
              />
            </ListItem>
          </StyledList>
        </StyledPaper>
      </StyledContainer>
    </div>
  );
};

export const PowerBIGeneralError: React.FC = () => {
  const classes = useStyles();

  const handleRetry = () => {
    window.location.replace('/dashboard');
  };

  return (
    <div className={classes.container}>
      <section className={classes.mainContent}>
        <ErrorScreenIcon height={311} width={400} fill={'none'} />
        <Typography variant="h4" className={classes.errorTitle}>
          Something's wrong here...
        </Typography>
        <div>
          <Typography variant="body1" className={classes.errorContent}>
            We can't find the page you're looking for.
          </Typography>
          <Typography variant="body1" className={classes.errorContent}>
            You can go back to dashboard or Try it again.
          </Typography>
        </div>
        <Button
          onClick={handleRetry}
          color="primary"
          className={classes.retryButton}
        >
          Retry
        </Button>
      </section>
    </div>
  );
};
