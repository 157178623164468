import * as Types from '../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetNewPatientsExportLinkQueryVariables = Types.Exact<{
  request: Types.PayerFilePatientsExportRequest;
}>;


export type GetNewPatientsExportLinkQuery = (
  { __typename: 'Query' }
  & { getNewPatientsExportLink?: Types.Maybe<(
    { __typename: 'PayerFileExportResponse' }
    & Pick<Types.PayerFileExportResponse, 'link'>
  )> }
);

export type GetErrorPatientsExportLinkQueryVariables = Types.Exact<{
  request: Types.PayerFilePatientsExportRequest;
}>;


export type GetErrorPatientsExportLinkQuery = (
  { __typename: 'Query' }
  & { getErrorPatientsExportLink?: Types.Maybe<(
    { __typename: 'PayerFileExportResponse' }
    & Pick<Types.PayerFileExportResponse, 'link'>
  )> }
);


export const GetNewPatientsExportLinkDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getNewPatientsExportLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PayerFilePatientsExportRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNewPatientsExportLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"link"}}]}}]}}]};

/**
 * __useGetNewPatientsExportLinkQuery__
 *
 * To run a query within a React component, call `useGetNewPatientsExportLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewPatientsExportLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewPatientsExportLinkQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetNewPatientsExportLinkQuery(baseOptions: Apollo.QueryHookOptions<GetNewPatientsExportLinkQuery, GetNewPatientsExportLinkQueryVariables>) {
        return Apollo.useQuery<GetNewPatientsExportLinkQuery, GetNewPatientsExportLinkQueryVariables>(GetNewPatientsExportLinkDocument, baseOptions);
      }
export function useGetNewPatientsExportLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewPatientsExportLinkQuery, GetNewPatientsExportLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetNewPatientsExportLinkQuery, GetNewPatientsExportLinkQueryVariables>(GetNewPatientsExportLinkDocument, baseOptions);
        }
export type GetNewPatientsExportLinkQueryHookResult = ReturnType<typeof useGetNewPatientsExportLinkQuery>;
export type GetNewPatientsExportLinkLazyQueryHookResult = ReturnType<typeof useGetNewPatientsExportLinkLazyQuery>;
export type GetNewPatientsExportLinkQueryResult = Apollo.QueryResult<GetNewPatientsExportLinkQuery, GetNewPatientsExportLinkQueryVariables>;
export function refetchGetNewPatientsExportLinkQuery(variables?: GetNewPatientsExportLinkQueryVariables) {
      return { query: GetNewPatientsExportLinkDocument, variables: variables }
    }
export const GetErrorPatientsExportLinkDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getErrorPatientsExportLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PayerFilePatientsExportRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getErrorPatientsExportLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"link"}}]}}]}}]};

/**
 * __useGetErrorPatientsExportLinkQuery__
 *
 * To run a query within a React component, call `useGetErrorPatientsExportLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorPatientsExportLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorPatientsExportLinkQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetErrorPatientsExportLinkQuery(baseOptions: Apollo.QueryHookOptions<GetErrorPatientsExportLinkQuery, GetErrorPatientsExportLinkQueryVariables>) {
        return Apollo.useQuery<GetErrorPatientsExportLinkQuery, GetErrorPatientsExportLinkQueryVariables>(GetErrorPatientsExportLinkDocument, baseOptions);
      }
export function useGetErrorPatientsExportLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetErrorPatientsExportLinkQuery, GetErrorPatientsExportLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetErrorPatientsExportLinkQuery, GetErrorPatientsExportLinkQueryVariables>(GetErrorPatientsExportLinkDocument, baseOptions);
        }
export type GetErrorPatientsExportLinkQueryHookResult = ReturnType<typeof useGetErrorPatientsExportLinkQuery>;
export type GetErrorPatientsExportLinkLazyQueryHookResult = ReturnType<typeof useGetErrorPatientsExportLinkLazyQuery>;
export type GetErrorPatientsExportLinkQueryResult = Apollo.QueryResult<GetErrorPatientsExportLinkQuery, GetErrorPatientsExportLinkQueryVariables>;
export function refetchGetErrorPatientsExportLinkQuery(variables?: GetErrorPatientsExportLinkQueryVariables) {
      return { query: GetErrorPatientsExportLinkDocument, variables: variables }
    }