import React from 'react';
import { useLocation } from 'react-router-dom';
import { BasePanel } from '../../../../../../app.types.generated';
import { useGetPanelSummaryLazyQuery } from '../../index.generated';

type CurrentPanelType = {
  panelId: string;
  samePanel: boolean | undefined; // undefined is used to indicate that the panel id has not been set
};

type ErrorType = {
  error: boolean;
  errorMessage: string;
};

enum PanelSummaryActionEnum {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  GET_PANEL_SUMMARY = 'GET_PANEL_SUMMARY',
}

type PanelSummaryStateType = {
  loading: boolean;
  errors: ErrorType;
  panelInfo: BasePanel;
};

type PanelSummaryActionType = {
  type: string;
  payload: PanelSummaryStateType;
};

type PanelSummaryContextType = {
  panelSummaryState: PanelSummaryStateType;
  setNeedToRefetch: (value: boolean) => void;
  refetchPatientTable: boolean;
  setRefetchPatientTable: (value: boolean) => void;
};

const PanelSummaryContext = React.createContext<PanelSummaryContextType>(
  {} as PanelSummaryContextType
);

const initialState: PanelSummaryStateType = {
  loading: true,
  errors: {} as ErrorType,
  panelInfo: {} as BasePanel,
};

const panelSummaryReducer = (
  state: PanelSummaryStateType,
  action: PanelSummaryActionType
) => {
  switch (action.type) {
    case PanelSummaryActionEnum.LOADING:
      return {
        ...initialState,
        loading: action.payload.loading,
      };
    case PanelSummaryActionEnum.ERROR:
      return {
        ...initialState,
        loading: action.payload.loading,
        errors: action.payload.errors,
      };
    case PanelSummaryActionEnum.GET_PANEL_SUMMARY:
      return {
        loading: action.payload.loading,
        errors: action.payload.errors,
        panelInfo: action.payload.panelInfo,
      };
    default:
      return state;
  }
};

/**
 * PanelSummaryContextContainer is a container component that provides the PanelSummaryContext to its children.
 * It manages the state and logic related to fetching and updating the panel summary.
 */
const PanelSummaryContextContainer: React.FC = ({ children }) => {
  const [
    getPanelSummary,
    getPanelSummaryResponse,
  ] = useGetPanelSummaryLazyQuery();

  const [currentPanel, setCurrentPanel] = React.useState<CurrentPanelType>({
    panelId: '',
    samePanel: undefined,
  });
  // refetch the panel summary when needed
  const [needToRefetch, setNeedToRefetch] = React.useState<boolean>(false);

  const [panelSummaryState, panelSummaryDispatch] = React.useReducer(
    panelSummaryReducer,
    initialState
  );

  // Refetch the patient table when needed: assigned patients, unassigned patients, etc.
  const [refetchPatientTable, setRefetchPatientTable] = React.useState<boolean>(
    false
  );

  /* 
  the useEffect hook workflow will call getPanelSummary when viewed panel changed
  E.g.
  - the two urls are being counted as same panel: /panels/:id{1}/summary and /panels/:id{1}/assigned
  - the two urls are being counted as different panels: /panels/:id{1}/summary and /panels/:id{2}/summary
  
  For the first case, we need to call getPanelSummary once. Updates to the panel will be handled by the refetch function.
  For the second case, the getPanelSummary function will be call as long as the panel id changes.
  */
  const location = useLocation();
  const lastPanelIdRef = React.useRef<string | null>(null);
  // console.log('lastPanelIdRef =>', lastPanelIdRef);

  React.useEffect(() => {
    // Get the panel summary based on the current path: /panels/{panelId}/
    const callGetPanelSummary = () => {
      const currentPath = location.pathname;
      const regex = /\/panels\/([^\/]+)\//;
      const match = regex.exec(currentPath);

      // If the panel id is found in the path
      if (match !== null) {
        // The panel id is in the first capture group
        const currentPanelId = match[1];

        if (lastPanelIdRef.current !== currentPanelId) {
          // The panel id has changed
          // console.log('panelId has changed =>', currentPanelId);
          getPanelSummary({ variables: { input: { id: currentPanelId } } });
          // Update the last panel id
          lastPanelIdRef.current = currentPanelId;

          if (lastPanelIdRef.current == null) {
            setCurrentPanel({ panelId: currentPanelId, samePanel: undefined });
          } else {
            setCurrentPanel({ panelId: currentPanelId, samePanel: false });
          }
        } else {
          // The panel id has not changed, refetch if needed
          // console.log('panelId has not changed =>', currentPanelId);
          setCurrentPanel({ panelId: currentPanelId, samePanel: true });
        }
      }
    };

    callGetPanelSummary();
  }, [location, lastPanelIdRef.current]);

  /* 
  the useEffect hook will update the panelSummaryState based on the getPanelSummaryResponse
  */
  React.useEffect(() => {
    if (!getPanelSummaryResponse.called || getPanelSummaryResponse.loading) {
      panelSummaryDispatch({
        type: PanelSummaryActionEnum.LOADING,
        payload: { ...initialState, loading: true },
      });
    } else if (
      getPanelSummaryResponse.data &&
      getPanelSummaryResponse.data.getPanel
    ) {
      panelSummaryDispatch({
        type: PanelSummaryActionEnum.GET_PANEL_SUMMARY,
        payload: {
          loading: false,
          errors: {} as ErrorType,
          panelInfo: getPanelSummaryResponse.data.getPanel as BasePanel,
        },
      });
    } else {
      panelSummaryDispatch({
        type: PanelSummaryActionEnum.ERROR,
        payload: {
          ...initialState,
          loading: false,
          errors: {
            error: true,
            errorMessage: `Error: ${getPanelSummaryResponse.error} or Invalid Data: ${getPanelSummaryResponse.data}.`,
          },
        },
      });
    }
  }, [getPanelSummaryResponse]);

  /* 
  the useEffect hook will refetch panelSummaryState when needToRefetch is true
  */
  React.useEffect(() => {
    const refetchPanelSummaryState = () => {
      // the refetchPanelSummaryState function will refetch panelSummaryState when the panel id has not changed
      if (currentPanel.samePanel || currentPanel.samePanel === undefined) {
        if (
          getPanelSummaryResponse.called &&
          !getPanelSummaryResponse.loading
        ) {
          getPanelSummaryResponse.refetch();
        } else {
          getPanelSummary({
            variables: { input: { id: currentPanel.panelId } },
          });
        }
      } else {
        // The panel id has changed
        // console.log(
        //   'panelId has changed => ',
        //   currentPanel.panelId,
        //   '. Calling getPanelSummary.'
        // );
        getPanelSummary({
          variables: { input: { id: currentPanel.panelId } },
        });
      }
    };

    if (needToRefetch) {
      refetchPanelSummaryState();
      setNeedToRefetch(false);
    }
  }, [needToRefetch]);

  return (
    <PanelSummaryContext.Provider
      value={{
        panelSummaryState,
        setNeedToRefetch,
        refetchPatientTable,
        setRefetchPatientTable,
      }}
    >
      {children}
    </PanelSummaryContext.Provider>
  );
};

export { PanelSummaryContext, PanelSummaryContextContainer };
