import { CustomSvgIcon } from '../../CustomSvgIcon';

interface Props {
  className?: string;
  style?: object;
}

export const NavigateNextIcon = (props: Props) => {
  return (
    <CustomSvgIcon
      {...props}
      path="M9.30999 6.71001C8.91999 7.10001 8.91999 7.73001 9.30999 8.12001L13.19 12L9.30999 15.88C8.91999 16.27 8.91999 16.9 9.30999 17.29C9.69999 17.68 10.33 17.68 10.72 17.29L15.31 12.7C15.7 12.31 15.7 11.68 15.31 11.29L10.72 6.70001C10.34 6.32001 9.69999 6.32001 9.30999 6.71001Z"
    />
  );
};
