import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import { MainRegion } from '../../../../../components/MainRegion';
import { BreadCrumbHeader } from '../../../../../components/BreadCrumbHeader';

import { useFetchTableData } from '../../../../../hooks/useFetchTableData';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { ItemListTableView } from '../../../../Insurances/components/InsuranceFileNewPatientListTable/View';
import { useGetNewPatientsQuery } from './index.generated';
import { useGetPayerFileByIdQuery } from '../index.generated';

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',

    '& > .MuiGrid-item': {
      padding: theme.spacing(3, 3.125),
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export const FileNewPatientListView = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { fileId } = useParams();
  const [title, setTitle] = React.useState('');

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : undefined;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : undefined;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }
  const [sortBy, setSortBy] = React.useState({
    field: 'fullname',
    method: 'asc' as const,
  });

  const patients = useGetNewPatientsQuery({
    variables: {
      request: {
        fileId: fileId,
        limit: limit ?? 10,
        skip: skip ?? 0,
        sortBy: [{ ...sortBy }],
      },
    },
  });

  const queryResult = useFetchTableData(patients);

  const resp = useGetPayerFileByIdQuery({
    variables: {
      request: {
        fileId: fileId ?? '',
      },
    },
  });

  const fileQueryResult = useFetchTableData(resp);

  React.useEffect(() => {
    try {
      setTitle(fileQueryResult?.getPayerFileById?.fileName);
    } catch (e: any) {
      //
    } finally {
    }
  }, [fileQueryResult]);

  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={title}
          rootLink={`/payerFiles`}
          rootName={'Payer Files'}
          isTitle={true}
          childrenLink={`/payerFiles`}
          title={title}
        />
      }
    >
      <div style={{ minWidth: 1240 }}>
        <Grid container spacing={8} className={classes.mainGrid}>
          <Grid item xs={12}>
            {!patients.called || patients.loading ? (
              <LoadingSpinner style={{ height: `calc(100vh - 135px)` }} />
            ) : (
              <div style={{ width: '100%' }}>
                <ItemListTableView
                  refetchDataFunc={{
                    func: patients.refetch,
                    loading: patients.loading,
                    params: {
                      fileId: fileId,
                      limit: limit ?? 10,
                      skip: skip ?? 0,
                      sortBy: [{ ...sortBy }],
                    },
                  }}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  rows={queryResult ? queryResult.getNewPatients?.results : []}
                  count={queryResult ? queryResult.getNewPatients?.count : 0}
                  fileId={fileId ?? ''}
                  limit={limit ?? 10}
                  skip={skip ?? 0}
                  setLimit={onSetLimit}
                  setSkip={onSetSkip}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
